import React, { useState, useMemo, useEffect } from 'react'
import { SelectSucursal, SelectTipo } from '../../components/Selects/SelectsPrincipales'
import { ToastContainer } from 'react-toastify';
import { notify_warning, notify_error, notify_valido, newDateF,


  getDateD,
getDateMA,
getDateYM,
getDateA,
newDateFValor,
notify_warning_fijo
 } from '../../components/utils';
import { AButton } from '../../components/Button';
import { Box, createMuiTheme, ThemeProvider } from '@mui/material';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import $ from 'jquery'

import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MaterialReactTable } from 'material-react-table';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
const globalTheme = window.templateCustomizer.settings.style;
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const dtUser_tipoUsu = cookies.get('dtUser_tipoUsu');
const moment = require('moment')
const LIQNotasCredito = () => {

  //const [disabled, setdisabled] = useState(false);
  const [disabledb1, setdisabledb1] = useState(false);
  const [disabledb2, setdisabledb2] = useState(false);
  const [disabledb3, setdisabledb3] = useState(false);
  const [disabledbCo, setdisabledbCo] = useState(false);
  const [disabledbFe, setdisabledbFe] = useState(true);
  const [pending, setpending] = useState(false);
  const [btnVisible2, setBtnVisible2] = useState(false);
  //const [sorting, setSorting] = useState([]);
  //const rowVirtualizerInstanceRef = useRef(null);
  const [txtTotales, setTxtTotales] = useState({r1:0, r2:0, r3:0});


  const [lst, setlst] = useState([]);
  // const [lst2, setlst2] = useState([]);
  // const [lst3, setlst3] = useState([]);
  const [lstModal, setlstModal] = useState([]);
  const [lstModal2, setlstModal2] = useState([]);
  const [sl_t_suc, setSl_t_suc] = useState('');
  const [sl_t_con, setSl_t_con] = useState('');
  const [text_fechaD, setText_fechaD] = useState(new Date());
  const [text_fechaC, setText_fechaC] = useState(new Date());
  const changeSl_t_suc = (event) => { setSl_t_suc(event.target.value); }
  const changeSl_t_con = (event) => { setSl_t_con(event.target.value); }

  const columns = useMemo(() => [
    // { header: 'Clave', accessorKey: 'Clave' },
    // { header: 'Relacionado', accessorKey: 'Relacionado' },
    { header: 'Numero', accessorKey: 'Numero' },
    { header: 'Estado', accessorKey: 'Estado' },
    { header: 'F.Emision', accessorKey: 'FechaEmision' },
    { header: 'F.Contabilizacion', accessorKey: 'FechaConta', },
    { header: 'CodCliente', accessorKey: 'CodCliente' },
    { header: 'NomCliente', accessorKey: 'NomCliente' },
    { header: 'CodVende', accessorKey: 'CodVendedor' },
    { header: 'Vendedor', accessorKey: 'Vendedor' },
    { header: 'TipoDocOrigen', accessorKey: 'TipoOrig' },
    { header: 'SerieOrigen', accessorKey: 'SerieOrig' },
    { header: 'CorrelativoOrigen', accessorKey: 'CorrelaOrig' },
    { header: 'F.Origen', accessorKey: 'FechaOrig' },
    { header: 'MotivoDevolucion', accessorKey: 'Motivo' },
    { header: 'TipoSunat (tabla 9)', accessorKey: 'MotivoSunat' },
    //{ header: 'DocEntryOrigen', accessorKey: 'Clave' },
    { header: 'ValorVenta', accessorKey: 'SubTotal' },
    { header: 'IGV', accessorKey: 'Impuesto' },
    { header: 'Total', accessorKey: 'Total',footer: `Total : ${txtTotales.r1}` },
  ], [txtTotales]);
  const handleActulizarTotalesColumna = async(nameFile, Cant) => {
    console.log("nameFile", nameFile);
    console.log("Cant", Cant);
    
    setTxtTotales(prevState => ({
      ...prevState,
      [nameFile]: Cant,
    }));
  }
  useEffect(() => {
    
    

    let totalSum1 = 0;
    let totalSum2 = 0;
    let total= 0;
    lst.forEach(obj => {
      //console.log("obj.txtPago=====",obj.txtPago);
      totalSum1 += parseFloat(obj.Total);
      //totalSum2 += parseFloat(obj.txtRechazo);
    }); 
    //console.log("totalSum2", totalSum2);
    //total = parseFloat(totalSum1) + parseFloat(totalSum2)
    
    
    handleActulizarTotalesColumna('r1',totalSum1.toFixed(6))
    // handleActulizarTotalesColumna('r2',totalSum2.toFixed(3))
    // handleActulizarTotalesColumna('r3',total.toFixed(3))
    
    
    
  }, [lst]);
  const onClickListar = async () => {
    try {
      setBtnHabilitar(false)
      if (!sl_t_suc || sl_t_suc === "9999") {
        notify_warning(' Debes seleccionar una sucursal.');
        // Si se coloca solo aparecerá en la vista de mantenimiento 
      } else {
        $("#btn_new").removeClass("d-none")
        const url = '/apiConsulNC?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + sl_t_con;
        const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' }}

        //const columns = lst_veh_columns
        setpending(true)
        setdisabledb1(true)
        const response = await fetch(url, opPe);
        const data = await response.json();
        if (data.length) {
          setdisabledbFe(false)
          setdisabledbCo(true)
          setlst(data)
          setpending(false)
          setdisabledb1(false)
          setTimeout(() => {
            $("#btn1").attr("disabled", true)
            $("#veh_slc_suc").attr("disabled", true)
            $("#btn3").attr("disabled", false)
          }, 1000);
        } else {
          setlst([])
          setpending(false)
          setdisabledb1(false)
        }

      }
    } catch (error) {
      setdisabledb1(false)
      setpending(false)
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }
  const arrDataProcesada = async(cook_idEmp,sl_t_suc,validarUActivos,text_fechaC) =>{
    const batchSize = 10;
    const promises = []
    for (let i = 0; i < validarUActivos.length; i += batchSize) {
        const batch = validarUActivos.slice(i, i + batchSize);
        let respd = await fuEnvioAct(cook_idEmp,sl_t_suc,batch, text_fechaC)
        promises.push(respd);
    }
    const resultados = await Promise.all(promises);
    return resultados.flat();
  }
  const fuEnvioAct = async(cook_idEmp,sl_t_suc,arrDataListaSec,text_fechaC) => {
    const url = '/apiNCInsertMasivos'
    const body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, arrDataListaSec: arrDataListaSec, text_fechaC:text_fechaC };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const resdatos = await response.json();
    return resdatos;
  }
  const onClickEnviarSap = async () => {
    if (lst.length) {
      Swal.fire({
        title: 'ADVERTENCIA',
        //text: '¿Desea crear para '+newDateFValor(text_fechaC)+ '?',
        text: '¿Desea Anular?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        customClass: { container: 'my-swal' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let arrDataListaSec =  lst;
          setdisabledb3(true)
          setpending(true)
          
          //const resdatos = await arrDataProcesada(cook_idEmp,sl_t_suc,arrDataListaSec,newDateFValor(text_fechaC))
          Swal.fire({
            title: 'Procesando...',
            html: 'Por favor esperar un momento, gracias.',
            didOpen: () => { Swal.showLoading() },
            customClass: { container: 'my-swal' },
            allowOutsideClick: false
          })
          const resdatos = await arrDataProcesada(cook_idEmp,sl_t_suc,arrDataListaSec,newDateF())
          console.log("onClickEnviarSap resdatos => ",resdatos);
          setlst([])
          setpending(false)
          setdisabledb1(false)
          setdisabledb3(false)
          

          setdisabledbCo(false);
          setdisabledbFe(true);
          setTimeout(() => {
            Swal.close()
            notify_valido('Se proceso correctamente, consulte nuevamente para verficar.');
            $("#veh_slc_suc").attr("disabled", false)
            $("#btn1").attr("disabled", false)
            $("#btn3").attr("disabled", true)
            
            console.log("resdatos[0].vl_estructura1.arrNFec", resdatos[0].vl_estructura1.arrNFec);
            console.log("resdatos[0].vl_estructura1.arrNFec.length", resdatos[0].vl_estructura1.arrNFec.length);

            let arrNoAnu = resdatos[0].vl_estructura1.arrNFec
            console.log("arrNoAnu.length", arrNoAnu.length);

            if (arrNoAnu.length) {
              setdisabledbCo(true)
              $("#veh_slc_suc").attr("disabled", true)
              $("#btn1").attr("disabled", true)
              $("#btn3").attr("disabled", false)
              resdatos[0].vl_estructura1.arrNFec?.map((key) => {
                notify_warning_fijo('Este documento '+key.Numero+' esta fuera de los 2 dias habilies para poder Anual.');
              })
              setlst(resdatos[0].vl_estructura1.arrNFec)

            }
            
          }, 2000);
         
          
        }
      })
    }else{
      notify_warning("¡No hay filas.!");
    }
  }

  const onClickMidalDetalle = async (row) => {
    try {


      const newLineDatoa = row.original.Lineas
      console.log("newLineDatoa", newLineDatoa);
      setlstModal(newLineDatoa)

    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }
 
  const onClickLimpiar = async (row) => {
    try {
      setlst([])
      setlstModal([])
      setlstModal2([])
      setdisabledb1(false)
      //setdisabledb2(false)
      setdisabledb3(false)
      setBtnVisible2(false)
      $("#veh_slc_suc").attr("disabled", false)
      $("#btn1").attr("disabled", false)
      $("#btn2").attr("disabled", true)
      $("#btn3").attr("disabled", true)
      setpending(false)
      setdisabledbFe(true)
      setdisabledbCo(false)
    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }

  useEffect(() => {
    $("#btn2").attr("disabled", true)
    $("#btn3").attr("disabled", true)
    // setdisabledb2(true)
    // setdisabledb3(true)

    // console.log("newDateF()", newDateF());
    // console.log("getDateD()", getDateD('-'));
    // console.log("getDateMA()", getDateMA('-'));
    // console.log("getDateYM()", getDateYM('-'));
    // console.log("getDateA()", getDateA());
    



  }, []);


  


  /** =============================================================== Modal =============================================================== */
  const     columns2 = useMemo(() => [
    { header: 'Linea', accessorKey: 'Linea' },
    { header: 'LineaBase', accessorKey: 'LineaBase' },
    { header: 'ClaveBase', accessorKey: 'ClaveBase' },
    { header: 'Articulo', accessorKey: 'Articulo' },
    { header: 'Descripcion', accessorKey: 'Descripcion' },
    { header: 'UnidadMedida', accessorKey: 'UnidadMedida' },
    { header: 'Almacen', accessorKey: 'Almacen' },
    { header: 'Cantidad contable ', accessorKey: 'Cantidad' },
    { header: 'PrecioUnitario', accessorKey: 'PrecioUnitario' },
    { header: 'PorcentajeDescuento', accessorKey: 'PorcentajeDescuento' },
    { header: 'Impuesto', accessorKey: 'Impuesto' },
    { header: 'TotalLinea', accessorKey: 'TotalLinea' },
    { header: 'MontoImpuesto', accessorKey: 'MontoImpuesto' },
    { header: 'Cuenta', accessorKey: 'Cuenta' },
    //{ header: 'Lotes', accessorKey: 'Lotes' }
  ], []);
  
  const tableTheme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [],
  );

  const [btnHabilitar, setBtnHabilitar] = useState(false);
  /** Cancelar orden */
  const onClickCancelar = async(row) =>{
    try {
      console.log("onClickCancelar");
      console.log("row.original", row.original);
      const ordenCancelar = row.original.Clave;
      const ordenCodRelacionadoFacturas = row.original.RelacionadoFacturas;
      
      //const ordenCancelar = row.original.clave;
      Swal.fire({
        title: 'Cancelar Orden N°: '+ordenCancelar,
        text: '¿Desea confirmar?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: "SI",
        customClass: {
          container: 'my-swal'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const url = '/apiCancelFactura'
          const body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, vcodOrder:ordenCancelar, ordenCodRelacionadoFacturas:ordenCodRelacionadoFacturas };
          const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
          const response = await fetch(url, opPe);
          const resdatos = await response.json();
          console.log("onClickCancelar resdatos", resdatos);
          const lstNew = lst.filter((item) => item.Clave !== ordenCancelar)
          setlst(lstNew)

        }
      })
    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }







  return (
    <ThemeProvider theme={tableTheme}>
      <div>
        <h6 className="text-muted">Notas de Credito</h6>
        <div className="card card-action">
          {/* <div className="card-header ">
                <div className="card-action-title">Lista </div>
            </div> */}
          <div className="card-body ">
            <div className="row">
              <div className="col-lg-2">Sucursal:</div>
              <div className="col-lg-2">
                <SelectSucursal id="veh_slc_suc" value={sl_t_suc} onChange={changeSl_t_suc} />
              </div>
              <div className="col-md-2">Fecha Consultar:</div>
              <div className="col-md-2" style={{ position: 'relative', zIndex: '4' }}>
              <DatePicker
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaD}
                  onChange={date => setText_fechaD(date)}
                  dateFormat="yyyy-MM-dd"
                  disabled={disabledbCo}
                />
              </div>
              
            </div>
            <div className="row">
              <div className="col-lg-2">Consolidado:</div>
              <div className="col-lg-2">
                {/* <SelectSucursal id="veh_slc_suc" value={sl_t_con} onChange={changeSl_t_con} /> */}
                <SelectTipo id="slc_t_tga" url={`/apiConsulCON?vcodEmpresa=${cook_idEmp}&vcodSucursal=${sl_t_suc}&vFecha=${newDateFValor(text_fechaD)}`} value={sl_t_con} onChange={changeSl_t_con}  />
              </div>
              {/*<div className="col-md-2 "  >Fecha Creación:</div>
              <div className="col-md-2 "  style={{ position: 'relative', zIndex: '3' }}>
                <DatePicker
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaC}
                  onChange={date => setText_fechaC(date)}
                  dateFormat="yyyy-MM-dd"
                  disabled={disabledbFe}
                />
              </div>*/}
              <div className="col-lg-8 text-end ">
                <AButton 
                  tp_tilde="Consultar información" 
                  id="btn1" tp_posit="top" bt_class="btn btn-label-youtube btn-sm" 
                  bt_funct={onClickListar} disabled={disabledb1} ic_class="bx bx-cloud-download bx-ms" vl_tpbtn={2} 
                />&nbsp;
                <AButton 
                  tp_tilde="Insertar Notas Credito" 
                  id="btn3" tp_posit="top" bt_class="btn btn-label-success btn-sm" 
                  bt_funct={onClickEnviarSap} disabled={disabledb3} ic_class="bx bx-upload bx-ms" vl_tpbtn={2} 
                />&nbsp;
                <AButton 
                  tp_tilde="Limpiar y consultar de nuevo" 
                  id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" 
                  bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} 
                />

              </div>
            </div>
            <div className="card-datatable table-responsive">
              <MaterialReactTable
                columns={columns}
                data={lst}
                state={{ isLoading: pending }}
                initialState={{
                  density: 'compact', showColumnFilters: false,
                  showGlobalFilter: false,
                  //columnVisibility: { clave: false, vlSema: false, },
                  columnVisibility: { vlSema: false, },
                  columnPinning: { left: ['mrt-row-actions','CodCliente','NomCliente'],right: ['Total'] }
                }}
                enableGrouping
                enableStickyHeader enableColumnResizing
                muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                localization={MRT_Localization_ES}
                enablePagination={true}
                muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                enableGlobalFilterModes
                rowVirtualizerProps={{ overscan: 150 }}
                enableRowVirtualization
                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    header: 'Options', //change header text
                    size: 80, //make actions column wider
                  },
                }}
                enableRowActions
                renderRowActions={({ row }) => (
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                    <AButton tp_tilde="Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_nmodal="#modalLotes" bt_funct={() => onClickMidalDetalle(row)} ic_class="bx bx-ms bx-list-ul" vl_tpbtn={1} />
                    <AButton tp_tilde="Cancelar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_vmodal={2}  bt_funct={() => onClickCancelar(row)} ic_class="bx bx-ms bx-layer-minus" vl_tpbtn={1} />
                  </Box>
                )}
                muiCircularProgressProps={{ color: 'error' }}
                muiSkeletonProps={{
                  animation: 'pulse',
                  height: 28,
                }}
                // muiTableBodyRowProps={({ row }) => ({
                //   sx: { //row.getValue('Glotes') === "Y" &&
                //     backgroundColor: (row.getValue('Glotes') === "Y" && row.getValue('vlSema') === 0) ? 'rgba(255, 99, 71, 0.2)' : '',
                //   }
                // })}


              />
            </div>
          </div>
        </div>
        {/** Lineas  */}
        <div className="modal fade" id="modalLotes" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog  modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel4">Detalle Comprobante</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body" >

                <MaterialReactTable
                  columns={columns2}
                  data={lstModal}
                  state={{ isLoading: pending }}
                  initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
                  enableGrouping
                  enableStickyHeader enableColumnResizing
                  muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                  localization={MRT_Localization_ES}
                  enablePagination={true}
                  muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                  enableGlobalFilterModes
                  rowVirtualizerProps={{ overscan: 8 }}
                  enableRowVirtualization
                  muiTableContainerProps={{ sx: { maxHeight: 300 } }}
                  //enableEditing

                  displayColumnDefOptions={{
                    'mrt-row-actions': {
                      header: 'Options', //change header text
                      size: 100, //make actions column wider
                    },
                  }}
                  muiCircularProgressProps={{ color: 'error' }}
                  muiSkeletonProps={{
                    animation: 'pulse',
                    height: 28,
                  }}
                

                />

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-label-secondary" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
        
        <ToastContainer />
      </div>
      
      
      {/* {
        (dtUser_tipoUsu === 'Administrador') && (
          <>
          <br/>
            <br/>
            <small className="text-light fw-medium"> Extras solo Admin </small>
            <div className="row">
              <div className="col-md mb-4 mb-md-2">
                <div className="accordion mt-3" id="accordionExample">
                  <div className="card accordion-item ">
                    <h2 className="accordion-header d-flex align-items-center" id="headingOne">
                      <button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="true" aria-controls="accordionOne">
                        <i class="bx bx-bar-chart-alt-2 me-2"></i>
                        Liquidación Transportista
                      </button>
                    </h2>
                    <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                      <div className="accordion-body mt-2 mb-2">
                        
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </>
        )

      } */}

      











    </ThemeProvider>

  )
}

export default LIQNotasCredito

