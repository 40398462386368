/* eslint-disable array-callback-return */
import React, { useState, useMemo, useEffect } from 'react'
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales'
import { ToastContainer } from 'react-toastify';
import { notify_warning, notify_error, notify_valido } from '../../components/utils';
import { AButton } from '../../components/Button';
import { Box, createMuiTheme, ThemeProvider } from '@mui/material';
//import addNotification from 'react-push-notification';


import $ from 'jquery'

import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MaterialReactTable } from 'material-react-table';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
const globalTheme = window.templateCustomizer.settings.style;
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');

const REPAsigLotes = () => {

  //const [disabled, setdisabled] = useState(false);
  const [disabledb1, setdisabledb1] = useState(false);
  const [disabledb2, setdisabledb2] = useState(false);
  const [disabledb3, setdisabledb3] = useState(false);
  const [pending, setpending] = useState(false);
  const [btnVisible2, setBtnVisible2] = useState(false);
  //const [sorting, setSorting] = useState([]);
  //const rowVirtualizerInstanceRef = useRef(null);



  const [lst, setlst] = useState([]);
  // const [lst2, setlst2] = useState([]);
  // const [lst3, setlst3] = useState([]);
  const [lstModal, setlstModal] = useState([]);
  const [lstModal2, setlstModal2] = useState([]);
  const [sl_t_suc, setSl_t_suc] = useState('');
  const changeSl_t_suc = (event) => { setSl_t_suc(event.target.value); }

  const columns = useMemo(() => [
    { header: 'Sucursal', accessorKey: 'sucursal' },
    { header: 'DocEntri', accessorKey: 'clave', },
    { header: 'DocNum', accessorKey: 'claveSAP' },
    { header: 'CodClient', accessorKey: 'codCliente' },
    { header: 'NomClient', accessorKey: 'cliente' },
    { header: 'FContabilización', accessorKey: 'Fecha' },
    { header: 'FVencimiento', accessorKey: 'vencimiento' },
    { header: 'TotalOrden', accessorKey: 'total' },
    { header: 'Comentario', accessorKey: 'Comments' },
    { header: 'CodVendedor', accessorKey: 'vendedor' },
    { header: 'Ruta', accessorKey: 'ruta' },
    { header: 'HGeneración', accessorKey: 'hora' },
    // { header: 'Fletes', accessorKey: 'Fletes' },
    // { header: 'CAdicionales', accessorKey: 'otrosCargos' },
    { header: 'Glotes', accessorKey: 'Glotes' },
    { header: 'vlSema', accessorKey: 'vlSema' },
    { header: 'codCliEst', accessorKey: 'codCliEst',
    Cell: ({ row }) => (
        (row.original.codCliEst === "Y") ? (
          <b> ACTIVO </b>
        ) : <b> INACTIVO </b>
      )
    },
    
  ], []);
  const [rowSelection, setRowSelection] = useState([]);
  const [disabledbCM, setDisabledbCM] = useState(false);
  const [enableRowSelection, setEnableRowSelection] = useState(true);
 
  const onClickListar = async () => {
    //buttonClick()
    try {
      setEnableRowSelection(true)
      setBtnHabilitar(false)
      if (!sl_t_suc || sl_t_suc === "9999") {
        notify_warning(' Debes seleccionar una sucursal.');
        // Si se coloca solo aparecerá en la vista de mantenimiento 
      } else {
        $("#btn_new").removeClass("d-none")
        const url = '/apiListAsigancionLotes'
        const body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc };
        const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

        //const columns = lst_veh_columns
        setpending(true)
        setdisabledb1(true)
        const response = await fetch(url, opPe);
        const data = await response.json();
        if (data.length) {
          setlst(data)
          setpending(false)
          setdisabledb1(false)
          setTimeout(() => {
            $("#btn1").attr("disabled", true)
            $("#veh_slc_suc").attr("disabled", true)
            $("#btn2").attr("disabled", false)
          }, 1000);
        } else {
          setlst([])
          setpending(false)
          setdisabledb1(false)
        }

      }
    } catch (error) {
      setdisabledb1(false)
      setpending(false)
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }
  const onClickActMasiva = async () => {
    try {
      //console.log("rowSelection", rowSelection);
      if (Object.keys(rowSelection).length > 0) {
        let arrDataListaPri =  lst.filter((item, index) => rowSelection[index]);
        let validarUActivos = arrDataListaPri.filter((item) => item.codCliEst === 'Y')
        setlst(prevList => {return [];});
        setdisabledb2(true)
        setpending(true)
        const resdatos = await arrDataProcesada(cook_idEmp,sl_t_suc,validarUActivos)
        setlst(resdatos)
        setBtnVisible2(true)
        setdisabledb2(false)
        setpending(false)
        //console.log("Nueva data", lst);
        setTimeout(() => {
          $("#btn2").attr("disabled", true)
          $("#btn3").attr("disabled", false)
        }, 1000);
        setEnableRowSelection(false)
        setRowSelection({})
      }else{
        notify_warning("Seleccione uno o todas las filas que quiera procesar.")
      }
    
    } catch (error) {
      setdisabledb2(false)
      setpending(false)
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
    
  }
  const arrDataProcesada = async(cook_idEmp,sl_t_suc,validarUActivos) =>{
    const batchSize = 50;
    const promises = []
    for (let i = 0; i < validarUActivos.length; i += batchSize) {
        const batch = validarUActivos.slice(i, i + batchSize);
        let respd = fuEnvioAct(cook_idEmp,sl_t_suc,batch)
        promises.push(respd);
    }
    const resultados = await Promise.all(promises);
    return resultados.flat();
  }
  const fuEnvioAct = async(cook_idEmp,sl_t_suc,validarUActivos) => {
    const url = '/apiActualizaconMasiva'
    const body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, arrDataListaPri: validarUActivos };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const resdatos = await response.json();
    return resdatos;
  }


  const onClickEnviarSap = async () => {
    //try {
      setBtnHabilitar(false)
      let arrDataListaSec =  lst;
      setlst([])
      setdisabledb3(true)
      setpending(true)
      // const url = '/apiOrderInsertMasivos'
      // const body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, arrDataListaSec:arrDataListaSec };
      // const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
      // const response = await fetch(url, opPe);
      // const resdatos = await response.json();
      const resdato = await arrDataProcesadaSap(cook_idEmp,sl_t_suc,arrDataListaSec)
      console.log("arrDataProcesadaSap - resdato -> ", resdato);

      if (resdato.length) {
        setpending(false)
        setdisabledb3(false)
        notify_valido('Se proceso correctamente, consulte nuevamente para verficar.');

        var V1 = 0;
        let arrDatosR = []
        resdato.map((key) => {
          if (key.vl_dataAnalizar.length > 0) {
            V1 = 1
            key.vl_dataAnalizar.map((key1) => {
              arrDatosR.push(key1)
            })
          }
        })
        console.log("arrDatosR", arrDatosR);

        setlst(arrDatosR)

        if (V1 === 1) {
          console.log("entraa");
          setBtnHabilitar(true)
          notify_warning('Ordenes no se insertaron, por favor validar.');
          setBtnVisible2(true)
          setTimeout(() => {
            $("#veh_slc_suc").attr("disabled", true)
            $("#btn1").attr("disabled", true)
            $("#btn2").attr("disabled", true)
            $("#btn3").attr("disabled", false)
          }, 1000);
          
        }else{
          setBtnVisible2(false)
          setTimeout(() => {
            $("#veh_slc_suc").attr("disabled", false)
            $("#btn1").attr("disabled", false)
            $("#btn2").attr("disabled", true)
            $("#btn3").attr("disabled", true)
            //onClickListar()
          }, 1000);

        }
        
      }
      //const resdatos = resdato[0];


      
  }
  const arrDataProcesadaSap = async(cook_idEmp,sl_t_suc,arrDataListaSec) =>{
    const batchSize = 25;
    const promises = []
    for (let i = 0; i < arrDataListaSec.length; i += batchSize) {
        const batch = arrDataListaSec.slice(i, i + batchSize);
        let respd = fuEnvioSap(cook_idEmp,sl_t_suc,batch)
        promises.push(respd);
    }
    const resultados = await Promise.all(promises);
    return resultados.flat();
  }
  const fuEnvioSap = async(cook_idEmp,sl_t_suc,arrDataListaSec) => {
    const url = '/apiOrderInsertMasivos'
    const body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, arrDataListaSec:arrDataListaSec };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const resdatos = await response.json();
    return resdatos;
  }
  
  
  const onClickCanMasiva = async (table) => {
    
    const selectedRFil = [];
    const selectedRows = table.getSelectedRowModel().rows;
    selectedRows.map((row) => { return selectedRFil.push(row.original.clave) })
    if (selectedRows.length) {
      
      Swal.fire({
        title: 'ADVERTENCIA',
        text: '¿Cancelar todas las ordenes seleccionadas?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: "Aceptar",
        customClass: {
          container: 'my-swal'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          setDisabledbCM(true)
          const url = '/apiCancelOrderMasivo'
          const body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, vlistaOrders:selectedRFil };
          const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
          const response = await fetch(url, opPe);
          const resdatos = await response.json();
          //console.log("onClickCanMasiva resdatos", resdatos)
          
          if (resdatos.vl_resultOC.length) {
            const filaRes = resdatos.vl_resultOC;
            notify_valido("Las ordenes "+filaRes+" se han cerrado correctamente ")
          }
          if (resdatos.vl_resultONC.length) {
            const filaRes = resdatos.vl_resultONC;
            notify_error("Las ordenes "+filaRes+" NO se han cerrado correctamente ")
          }
          onClickListar()
          Swal.close()

          setRowSelection({});
          setDisabledbCM(false)
        }
      })
      
    } else {
      notify_warning("Seleccione uno o todas las filas que quiera cancelar.")
    }
    
  }

  const onClickMidalDetalle = async (row) => {
    try {


      const newLineDatoa = row.original.Lineas
      setlstModal(newLineDatoa)

    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }
  const onClickMidalLotes = async (row) => {
    try {


      const newLineDatoa = row?.original?.Lotes
      setlstModal2(newLineDatoa)

    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }
  // const onClickEliminar = async (row) => {
  //   try {
  //     alert("onClickEliminar")
  //   } catch (error) {
  //     notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
  //   }
  // }
  const onClickLimpiar = async (row) => {
    try {
      setlst([])
      setlstModal([])
      setlstModal2([])
      setdisabledb1(false)
      setdisabledb2(false)
      setdisabledb3(false)
      setDisabledbCM(false)
      setBtnVisible2(false)
      $("#veh_slc_suc").attr("disabled", false)
      $("#btn1").attr("disabled", false)
      $("#btn2").attr("disabled", true)
      $("#btn3").attr("disabled", true)
      setpending(false)
      setEnableRowSelection(true)
    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }

  useEffect(() => {
    $("#btn2").attr("disabled", true)
    $("#btn3").attr("disabled", true)
    // setdisabledb2(true)
    // setdisabledb3(true)
  }, []);


  


  /** =============================================================== Modal =============================================================== */
  const columns2 = useMemo(() => [
    { header: 'CodArticulo', accessorKey: 'codArticulo' },
    { header: 'Descripción', accessorKey: 'descripcion' },
    { header: 'Cantidad', accessorKey: 'cantidad' },
    { header: 'Almacen', accessorKey: 'Almacen' },
    { header: 'Precio', accessorKey: 'precio' },
    { header: 'Descuento', accessorKey: 'descuento' },
    { header: 'Impuesto', accessorKey: 'impuesto' },
    { header: 'Total', accessorKey: 'total' },
    { header: 'UnidadMedida', accessorKey: 'unidad' },
    { header: 'ListaPrecios', accessorKey: 'lprecio' },
    //{ header: 'LoteAsignado', accessorKey: 'LoteAsignado'},
    { header: 'Area', accessorKey: 'area' },
    { header: 'Linea', accessorKey: 'linea' },
    { header: 'Clinea', accessorKey: 'Clinea' },
    { header: 'CentroCosto', accessorKey: 'centroCosto', enableEditing: false },
    { header: 'Cant.Inv.', accessorKey: 'CantidadInventario', enableEditing: false },
    { header: 'GLotes', accessorKey: 'Glotes', enableEditing: false },
  ], []);

  const columns3 = useMemo(() => [
    { header: 'linea', accessorKey: 'linea' },
    { header: 'CodArticulo', accessorKey: 'codArticulo' },
    { header: 'Descripción', accessorKey: 'descripcion' },
    { header: 'Lote', accessorKey: 'Lotes' },
    { header: 'Cantidad', accessorKey: 'Cantidad' },
    //{ header: 'Saldo', accessorKey: 'Saldo' },
    { header: 'GLotes', accessorKey: 'Glotes', enableEditing: false },
  ], []);

  
  
  

  const tableTheme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [],
  );

  const [btnHabilitar, setBtnHabilitar] = useState(false);
  /** Cancelar orden */
  const onClickCancelarOrden = async(row) =>{
    try {
      console.log("onClickCancelarOrden");
      console.log("row.original", row.original.clave);
      const ordenCancelar = row.original.clave;
      Swal.fire({
        title: 'Cancelar Orden N°: '+ordenCancelar,
        text: '¿Desea confirmar?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: "SI",
        customClass: {
          container: 'my-swal'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const url = '/apiCancelOrder'
          const body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, vcodOrder:ordenCancelar };
          const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
          const response = await fetch(url, opPe);
          const resdatos = await response.json();
          console.log("onClickCancelarOrden resdatos", resdatos);
          const lstNew = lst.filter((item) => item.clave !== ordenCancelar)
          setlst(lstNew)

        }
      })
    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }
  const newOrdenItem = async(
    cook_idEmp,
    sl_t_suc,
    eliminarClave,
    eliminarLinea
  ) =>{
    const url = '/apiNewOrderItem'
    const body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, vcodOrder: eliminarClave, vcodItem:eliminarLinea};
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const resdatos = await response.json();
    return resdatos;
  }


  /** Eliminar Fila Lote */
  const onClickEliminarFilaLote = async(row) =>{
    console.log("row.original", row.original.linea);
    let eliminarLinea = row.original.linea;
    console.log("eliminarLinea => ",eliminarLinea);
    const eliminarLiAnt = row.original.lianeAnt;
    console.log("eliminarLiAnt => ",eliminarLiAnt);
    const eliminarClave = row.original.clave;
    console.log("eliminarClave => ",eliminarClave);
    try {
      Swal.fire({
        title: 'Eliminar fila Lote°: ',
        text: '¿Desea confirmar?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: "SI",
        customClass: {
          container: 'my-swal'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let lstNew = []
          /** Eliminar filas */
          if (eliminarLinea < 999) {
            console.log("es menor que 999");
            lstNew = lst.map((item)=>{
                console.log("=>elimina eliminarLinea ", eliminarLinea);
                if (item.clave === eliminarClave) {
                  item.Lineas = item.Lineas.filter(linea => linea.linea !== eliminarLinea );
                  item.Lotes = item.Lotes.filter(lote => lote.linea !== eliminarLinea );
                }
                return item;
              })
            console.log("entrada solo a eliminar y crear linea", eliminarLinea);
            const resdatos = 
              await newOrdenItem(
                cook_idEmp,
                sl_t_suc,
                eliminarClave,
                eliminarLinea
              )  
            console.log("resdatos", resdatos);
            console.log("resdatos.vl_rspDocEntry", resdatos.vl_rspDocEntry);
            lstNew  = cambiarValorClave(lstNew, eliminarClave,resdatos.vl_rspDocEntry)
          }


          
          if (eliminarLiAnt !== undefined) {
            // console.log("entrada solo a eliminar y crear linea", eliminarLinea);
            // console.log("entrada solo a eliminar y crear eliminarLiAnt", eliminarLiAnt);
            lstNew = lst.map((item)=>{
              // console.log("=>elimina eliminarLiAnt ", eliminarLiAnt);
              if (item.clave === eliminarClave) {
                //item.Lineas = item.Lineas.filter(linea => linea.linea !== eliminarLinea);
                item.Lineas = item.Lineas.filter(linea => !(linea.linea === eliminarLiAnt && linea.lianeAnt === eliminarLiAnt));
                item.Lineas = item.Lineas.filter(linea => linea.linea !== eliminarLiAnt  && linea.lianeAnt !== eliminarLiAnt );

                //item.Lotes = item.Lotes.filter(lote => lote.linea !== eliminarLinea);
                item.Lotes = item.Lotes.filter(lote => !(lote.linea === eliminarLiAnt && lote.lianeAnt === eliminarLiAnt));
                item.Lotes = item.Lotes.filter(lote => lote.linea !== eliminarLiAnt  && lote.lianeAnt !== eliminarLiAnt );
              }
              return item;
            })
            console.log("lstNew", lstNew);
            
            const resdatos2 = 
              await newOrdenItem(
                cook_idEmp,
                sl_t_suc,
                eliminarClave,
                eliminarLiAnt
              )
              console.log("resdatos2", resdatos2);
              console.log("resdatos2.vl_rspDocEntry", resdatos2.vl_rspDocEntry);
            lstNew  = cambiarValorClave(
              lstNew, 
              eliminarClave,
              resdatos2.vl_rspDocEntry
            )
            
          }

          setlst(lstNew)
          
          setTimeout(() => {
            $('.btn-close').click()
          }, 1000)
          
        }
      })
    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
   
    
  }



  const cambiarValorClave = (array, claveBuscada, nuevoValor) => {
    return array.map(objeto => {
        // Si la clave del objeto es igual a la clave buscada, se cambia su valor
        if (objeto.clave === claveBuscada) {
            return { ...objeto, clave: nuevoValor };
        } else {
            return objeto;
        }
    });
}



  return (
    <ThemeProvider theme={tableTheme}>
      <div>
        <h6 className="text-muted">Asignación de lotes</h6>
        <div className="card card-action">
          {/* <div className="card-header ">
                <div className="card-action-title">Lista </div>
            </div> */}
          <div className="card-body ">
            <div className="row">
              <div className="col-lg-2">Sucursal:</div>
              <div className="col-lg-2">
                <SelectSucursal id="veh_slc_suc" value={sl_t_suc} onChange={changeSl_t_suc} /> {/* disabled={disabled} */}
              </div>
              <div className="col-lg-4 text-center ">
                <AButton tp_tilde="Consultar información" id="btn1" tp_posit="top" bt_class="btn btn-label-youtube btn-sm" bt_funct={onClickListar} disabled={disabledb1} ic_class="bx bx-cloud-download bx-ms" vl_tpbtn={2} />&nbsp;
                <AButton tp_tilde="Actualización masiva de lotes" id="btn2" tp_posit="top" bt_class="btn btn-label-success btn-sm" bt_funct={onClickActMasiva} disabled={disabledb2} ic_class="bx bx-revision bx-ms" vl_tpbtn={2} />&nbsp;
                <AButton tp_tilde="Insertar masiva lotes" id="btn3" tp_posit="top" bt_class="btn btn-label-success btn-sm" bt_funct={onClickEnviarSap} disabled={disabledb3} ic_class="bx bx-upload bx-ms" vl_tpbtn={2} />&nbsp;
                <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />

              </div>
            </div>
            <div className="card-datatable table-responsive">
              <MaterialReactTable
                columns={columns}
                data={lst}
                state={{ rowSelection, isLoading: pending }}
                initialState={{
                  density: 'compact', showColumnFilters: false,
                  showGlobalFilter: false,
                  //columnVisibility: { clave: false, vlSema: false, },
                  columnVisibility: { vlSema: false, },
                }}
                enableGrouping 
                enableRowSelection = {enableRowSelection}
                positionToolbarAlertBanner="bottom"
                enableStickyHeader enableColumnResizing
                onRowSelectionChange={setRowSelection}
                muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                localization={MRT_Localization_ES}
                enablePagination={true}
                muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                enableGlobalFilterModes
                rowVirtualizerProps={{ overscan: 150 }}
                enableRowVirtualization
                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    header: 'Options', //change header text
                    size: 120, //make actions column wider
                  },
                }}
                enableRowActions
                renderRowActions={({ row }) => (
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                    <AButton 
                      tp_tilde="Detalle orden venta" tp_posit="top" 
                      bt_class="btn btn-sm btn-icon btn-label-info " 
                      bt_vmodal={1} bt_nmodal="#modalLotes" 
                      bt_funct={() => onClickMidalDetalle(row)} 
                      ic_class="bx bx-ms bx-list-ul" vl_tpbtn={1} 
                    />
                    {btnVisible2 && (
                      <AButton 
                        tp_tilde="Detalle de lotes" tp_posit="top" 
                        bt_class="btn btn-sm btn-icon btn-label-warning " 
                        bt_vmodal={1} bt_nmodal="#modalLotes2" 
                        bt_funct={() => onClickMidalLotes(row)} 
                        ic_class="bx bx-ms bx-list-plus" vl_tpbtn={1} 
                      />)}
                    <AButton 
                      tp_tilde="Cancelar Orden" tp_posit="top" 
                      bt_class="btn btn-sm btn-icon btn-label-danger " 
                      bt_vmodal={2}  
                      bt_funct={() => onClickCancelarOrden(row)} 
                      ic_class="bx bx-ms bx-layer-minus" 
                      vl_tpbtn={1} 
                    />
                   
                  </Box>
                )}
                muiTableBodyRowProps={({ row }) => ({
                  sx: { //row.getValue('Glotes') === "Y" &&
                    backgroundColor: (row.getValue('Glotes') === "Y" && row.getValue('vlSema') === 0) ? 'rgba(255, 99, 71, 0.2)' : '',
                  }
                })}
                renderTopToolbarCustomActions={({ table, row }) => (
                  <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                    <AButton 
                      id="btnMasivo" 
                      tp_tilde="Cancelar todos las Ordenes seleccionadas"  
                      bt_descr='Cancelar Masivo' tp_posit="top" 
                      bt_class="btn btn-label-youtube btn-sm" 
                      bt_funct={() => { onClickCanMasiva(table)}}  
                      disabled={disabledbCM} 
                      ic_class="bx bx-cloud-download bx-ms" vl_tpbtn={2} 
                    />
                  </Box>
                )}
                muiCircularProgressProps={{ color: 'error' }}
                muiSkeletonProps={{
                  animation: 'pulse',
                  height: 28,
                }}
              />
            </div>
          </div>
        </div>
        {/** Lineas  */}
        <div className="modal fade" id="modalLotes" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog  modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel4">Detalle Comprobante</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body" >

                <MaterialReactTable
                  columns={columns2}
                  data={lstModal}
                  state={{ isLoading: pending }}
                  initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
                  enableGrouping
                  enableStickyHeader enableColumnResizing
                  muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                  localization={MRT_Localization_ES}
                  enablePagination={true}
                  muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                  enableGlobalFilterModes
                  rowVirtualizerProps={{ overscan: 8 }}
                  enableRowVirtualization
                  muiTableContainerProps={{ sx: { maxHeight: 300 } }}
                  //enableEditing

                  displayColumnDefOptions={{
                    'mrt-row-actions': {
                      header: 'Options', //change header text
                      size: 100, //make actions column wider
                    },
                  }}
                  muiCircularProgressProps={{ color: 'error' }}
                  muiSkeletonProps={{
                    animation: 'pulse',
                    height: 28,
                  }}
                // enableRowActions
                // renderRowActions={({ row, table, cell }) => (
                //   <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                //     {/* <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={table.setEditingCell(row)} ic_class="bx bxs-edit-alt bx-ms" vl_tpbtn={1} /> */}
                //     <AButton tp_tilde="Eliminar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger" bt_funct={onClickMEliminar} ic_class="bx bx-x-circle bx-ms" vl_tpbtn={1} />
                //   </Box>
                // )}
                // editingMode="cell" enableEditing muiTableBodyCellEditTextFieldProps={({
                // cell
                // }) => ({
                //   onBlur: event => {
                //     handleSaveCell(cell, event.target.value);
                //   }
                // })}

                />

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-label-secondary" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
        {/** Lotes  */}
        <div className="modal fade" id="modalLotes2" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog  modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel4">Lotes</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body" >

                <MaterialReactTable
                  columns={columns3}
                  data={lstModal2}
                  state={{ isLoading: pending }}
                  initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
                  enableGrouping
                  enableStickyHeader enableColumnResizing
                  muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                  localization={MRT_Localization_ES}
                  enablePagination={true}
                  muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                  enableGlobalFilterModes
                  rowVirtualizerProps={{ overscan: 8 }}
                  enableRowVirtualization
                  muiTableContainerProps={{ sx: { maxHeight: 300 } }}
                  muiTableBodyRowProps={({ row }) => ({
                    sx: {
                      backgroundColor: (row?.getValue('Glotes') === "Y" && row.getValue('Lotes') === "") ? 'rgba(255, 99, 71, 0.2)' : '',
                      fontStyle: 'italic'
                    }
                  })}
                  enableRowActions
                  renderRowActions={({ row }) => (
                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                      <AButton tp_tilde="Eliminar Item" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_vmodal={2} bt_funct={() => onClickEliminarFilaLote(row)} ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
                    </Box>
                  )}
                  muiCircularProgressProps={{ color: 'error' }}
                  muiSkeletonProps={{
                    animation: 'pulse',
                    height: 28,
                  }}
                />

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-label-secondary" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </ThemeProvider>

  )
}

export default REPAsigLotes

