import React from 'react'
//import {Page, Text, Document, StyleSheet, View } from "@react-pdf/renderer"
import {Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer"
import logo_ab from '../../assets/img/logo/logo_ab.png';
import moment from 'moment';

const styles = StyleSheet.create({
    page: { padding: 30 },
    title: { fontSize: 14, marginBottom: 10 },
    subtitle: { fontSize: 12, marginTop: 15, marginBottom: 5 },

    table: { width: '100%', fontSize: 7, border: '1px solid #000000', borderRadius: 3,borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 },
    row: { flexDirection: 'row' },
    rowEven: { backgroundColor: '#f0f0f0' },
    rowHeader: { backgroundColor: '#ffffff', fontFamily: 'Helvetica-Bold',fontSize: 7 },
    rowFooter: { backgroundColor: '#c0c0c0', fontStyle: 'italic' },
    column: { borderStyle: 'solid', borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    cell: { padding: 5, fontSize: 6  },
    cellHeader: { fontWeight: '900' },
    cellCenter: { textAlign: 'center' },
    cellRight: { textAlign: 'right' },

});

const TableCell = ({ children, style = {}, rowSpan = 1, colSpan = 1 }) => (
    <Text style={[styles.cell, style]} colSpan={colSpan}>{children}</Text>
);

const TableRow = ({ children, isHeader = false, isEven = false, isFooter = false }) => (
    <View style={[styles.row, isHeader && styles.rowHeader, isEven && styles.rowEven, isFooter && styles.rowFooter]}>{children}</View>
);

const TableColumn = ({ children, width = '25%' }) => (
    <View style={[styles.column, { width }]}>{children}</View>
);

const Table = ({ children }) => <View style={styles.table}>{children}</View>;

// Midificacion despues de la salida
// La placa  suma de moto y pedidos 
export const PdfConsolidadosDoc = ({datallegada,placa,datallegadapri,datallegadadoc}) => {

    
    /**
     *  Estrutura de la data que llega 
     * {
            "SlpName": "CAX012",
            "DocDate": "2024-08-03T05:00:00.000Z",
            "Documento": "01-FA06-00000095",
            "CardName": "CARRASCO CRUZ, TOMAS",
            "Condicion": "CO",
            "Importe": "187.195200",
            "Cobranza": "",
            "Observacion": "",
            "PrcName": "CAJAMARCA"
        }
     */
    /**
     * Agrupacion de la data por propiedad
     * 
     * @param {*} data 
     * @param {*} key 
     * @returns 
     */
    const groupBy = (data, key) => {
        
            console.log(data);
            
            return data.reduce((result, item) => {
                const groupKey = item[key];
                if (!result[groupKey]) {
                    result[groupKey] = [];
                }
                result[groupKey].push(item);
                return result;
            }, {});

        
            
        
      };
      
      // Agrupamiento por  sucursal (PrcName)
      const groupedByCardName = groupBy(datallegada, 'PrcName');

      //  Valores de los totales finales
      const sumaTotalMonto =datallegada.reduce((total, item) => total + parseFloat(item.Importe), 0)
      console.log("sumaTotalMonto:: ",  sumaTotalMonto);
      
      const TotalFilas = datallegada?.length;
      let placaTxt = placa.join(',')// placas.Placa
      console.log("placaTxt:.", placaTxt);

      console.log("groupedByCardName", groupedByCardName );

    //const  i = 1;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Text style={{ fontSize: 15, marginBottom: 10 }}> <Image styles={styles.image} src={logo_ab} />Consolidado de Documentos</Text>
                
                    <View styles={styles.div_1}>
                        <Text style={{ fontSize: 8, marginBottom: 2,fontFamily: 'Helvetica-Bold' }}>Fecha impresion: {moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}</Text>
                        <Text style={{ fontSize: 8, marginBottom: 2,fontFamily: 'Helvetica-Bold' }}>Placa: {placaTxt}</Text>
                    </View>
                    <Table>
                        <TableRow isHeader>
                            <TableColumn><TableCell style={styles.cellHeader}>FECHA</TableCell></TableColumn>
                            <TableColumn width="30%"><TableCell style={styles.cellHeader}>DOCUMENTO</TableCell></TableColumn>
                            <TableColumn width="50%"><TableCell style={styles.cellHeader}>NOMBRE</TableCell></TableColumn>
                            <TableColumn><TableCell style={styles.cellHeader}>CONDICION</TableCell></TableColumn>
                            <TableColumn><TableCell style={styles.cellHeader}>IMPORTE</TableCell></TableColumn>
                            <TableColumn><TableCell style={styles.cellHeader}>COMBRANZA</TableCell></TableColumn>
                            <TableColumn><TableCell style={styles.cellHeader}>OBSERVACION</TableCell></TableColumn>
                        </TableRow>
                        {Object.keys(groupedByCardName).map((cardName) => {
                            const groupedByItmsGrpNam = groupBy(groupedByCardName[cardName], 'SlpName');
                            // console.log("groupedByItmsGrpNam:: ", groupedByItmsGrpNam);
                            return (
                                <>
                                    <TableRow>
                                        <TableColumn  width="100%">
                                            <TableCell style={{  textAlign: 'LEFT', fontFamily: 'Helvetica-Bold'}} >
                                                {cardName}
                                            </TableCell>
                                        </TableColumn>
                                    </TableRow>
                                    {Object.keys(groupedByItmsGrpNam).map((itmsGrpNam) => {
                                         let totalImporte = parseFloat(
                                            groupedByItmsGrpNam[itmsGrpNam]
                                              .reduce((total, item) => total + parseFloat(item.Importe), 0)
                                              .toFixed(4)
                                          );

                                          return (
                                            <React.Fragment key={itmsGrpNam}>

                                             <TableRow>
                                                <TableColumn width="100%">
                                                    <TableCell style={{ textAlign: 'LEFT', fontFamily: 'Helvetica-Bold' }} >
                                                        {itmsGrpNam}
                                                    </TableCell>
                                                </TableColumn>
                                            </TableRow>
                                            {groupedByItmsGrpNam[itmsGrpNam].map((item, index) => (
                                                <TableRow key={index} >
                                                    <TableColumn><TableCell>{moment(item.DocDate).format('YYYY-MM-DD') }</TableCell></TableColumn>
                                                    <TableColumn width="30%" ><TableCell>{item.Documento}</TableCell></TableColumn>
                                                    <TableColumn width="50%"><TableCell>{item.CardName}</TableCell></TableColumn>
                                                    <TableColumn><TableCell>{item.Condicion}</TableCell></TableColumn>
                                                    <TableColumn><TableCell>{item.Importe}</TableCell></TableColumn>
                                                    <TableColumn><TableCell>{item.Cobranza}</TableCell></TableColumn>
                                                    <TableColumn><TableCell>{item.Observacion}</TableCell></TableColumn>
                                                </TableRow>
                                            )
                                            )}
                                            <TableRow>
                                                <TableColumn width="100%">
                                                    <TableCell style={{  textAlign: 'LEFT', fontWeight: 'bold' }} >
                                                    CANTIDAD: {groupedByItmsGrpNam[itmsGrpNam].length} &nbsp;&nbsp;&nbsp;&nbsp; TOTAL: {totalImporte}
                                                    </TableCell>
                                                </TableColumn>
                                                  </TableRow> 
                                           </React.Fragment>
                                            );
                                        }
                                     )}
                                     
                                </>
                            );
                        })} 
                </Table>
                {/* Esta tabla es de reportes  */}
                <Table>
                    <TableRow>
                        <TableColumn width="100%">
                            <TableCell style={{ textAlign: 'LEFT', fontFamily: 'Helvetica-Bold', fontSize: 8  }} >
                                {/* {cardName} */}
                                Resumen
                            </TableCell>
                        </TableColumn>
                    </TableRow>
                    <TableRow>
                        <TableColumn width="100%">
                            <TableCell style={{ textAlign: 'LEFT', fontFamily: 'Helvetica-Bold'}} >
                                CANTIDAD: {TotalFilas} &nbsp;&nbsp;&nbsp;&nbsp; TOTAL: {sumaTotalMonto}
                            </TableCell>
                        </TableColumn>
                    </TableRow>
                </Table>

            </Page>
        </Document>
    )
};
// export const PdfLiquidacionTrans = ({ datallegada, datallegadapri, datallegadadoc }) => {

//     const groupBy = (data, key) => {
//         return data.reduce((result, item) => {
//             const groupKey = item[key];
//             if (!result[groupKey]) {
//                 result[groupKey] = [];
//             }
//             result[groupKey].push(item);
//             return result;
//         }, {});
//     };

//     const groupedByCardName = groupBy(datallegada, 'PrcName');
//     //   console.log("groupedByCardName", groupedByCardName );

//     //const  i = 1;
//     return (
//         <Document>
//             <Page size="A4" style={styles.page}>
//                 <Text style={{ fontSize: 20, marginBottom: 10 }}> <Image styles={styles.image} src={logo_ab} />Consolidado de Documentos</Text>

//                 {/* <View styles={styles.div_1}>
//                         <Text>Fecha emision: {datallegadapri.FechaEmision}</Text>
//                     </View> */}
//                 <Table>
//                     <TableRow isHeader>
//                         <TableColumn><TableCell style={styles.cellHeader}>FECHA</TableCell></TableColumn>
//                         <TableColumn ><TableCell style={styles.cellHeader}>DOCUMENTO</TableCell></TableColumn>
//                         <TableColumn width="50%"><TableCell style={styles.cellHeader}>NOMBRE</TableCell></TableColumn>
//                         <TableColumn><TableCell style={styles.cellHeader}>CONDICION</TableCell></TableColumn>
//                         <TableColumn><TableCell style={styles.cellHeader}>IMPORTE</TableCell></TableColumn>
//                         <TableColumn><TableCell style={styles.cellHeader}>COMBRANZA</TableCell></TableColumn>
//                         <TableColumn><TableCell style={styles.cellHeader}>OBSERVACION</TableCell></TableColumn>
//                     </TableRow>
//                     {Object.keys(groupedByCardName).map((cardName) => {
//                         const groupedByItmsGrpNam = groupBy(groupedByCardName[cardName], 'SlpName');
//                         // console.log("groupedByItmsGrpNam:: ", groupedByItmsGrpNam);
//                         return (
//                             <>
//                                 <TableRow>
//                                     <TableColumn width="100%">
//                                         <TableCell style={{ backgroundColor: '#f0f0f0', textAlign: 'LEFT', fontWeight: 'bold' }} >
//                                             {cardName}
//                                         </TableCell>
//                                     </TableColumn>
//                                 </TableRow>
//                                 {Object.keys(groupedByItmsGrpNam).map((itmsGrpNam) => {
//                                     let totalImporte = parseFloat(
//                                         groupedByItmsGrpNam[itmsGrpNam]
//                                             .reduce((total, item) => total + parseFloat(item.Importe), 0)
//                                             .toFixed(4)
//                                     );
//                                     return (
//                                         <React.Fragment key={itmsGrpNam}>

//                                             <TableRow>
//                                                 <TableColumn width="100%">
//                                                     <TableCell style={{ backgroundColor: '#d0d0d0', textAlign: 'LEFT', fontWeight: 'bold' }} >
//                                                         {itmsGrpNam}
//                                                     </TableCell>
//                                                 </TableColumn>
//                                             </TableRow>
//                                             {groupedByItmsGrpNam[itmsGrpNam].map((item, index) => (
//                                                 <TableRow key={index} >
//                                                     <TableColumn><TableCell>{moment(item.DocDate).format('YYYY-MM-DD')}</TableCell></TableColumn>
//                                                     <TableColumn ><TableCell>{item.Documento}</TableCell></TableColumn>
//                                                     <TableColumn width="50%"><TableCell>{item.CardName}</TableCell></TableColumn>
//                                                     <TableColumn><TableCell>{item.Condicion}</TableCell></TableColumn>
//                                                     <TableColumn><TableCell>{item.Importe}</TableCell></TableColumn>
//                                                     <TableColumn><TableCell>{item.Cobranza}</TableCell></TableColumn>
//                                                     <TableColumn><TableCell>{item.Observacion}</TableCell></TableColumn>
//                                                 </TableRow>
//                                             ))}
//                                             <TableRow>
//                                                 <TableColumn width="100%">
//                                                     <TableCell style={{ backgroundColor: '#f0f0f0', textAlign: 'LEFT', fontWeight: 'bold' }} >
//                                                         CANTIDAD: {groupedByItmsGrpNam[itmsGrpNam].length} &nbsp;&nbsp;&nbsp;&nbsp; TOTAL: {totalImporte}
//                                                     </TableCell>
//                                                 </TableColumn>
//                                             </TableRow>
//                                         </React.Fragment>
//                                     );
//                                 }
//                                 )}

//                             </>
//                         );
//                     })}
//                 </Table>

//             </Page>
//         </Document>
//     )
// };
// export default PdfConsolidadosDoc