import React, {useState,useEffect, useMemo} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import BONUnoAUno from './BONUnoAUno';
import BONTicketCompra from './BONTicketCompra'
import BONTicketCompraEscala from './BONTicketCompraEscala'
import BONBasicaEscala from './BONBasicaEscala'
// import BONReglas from './BONReglas'
import BONAgrupacionReglas from './BONAgrupacionReglas'
import { createTheme, ThemeProvider } from '@mui/material';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
//const cook_idEmp = cookies.get('dtEmp_id');

//import $ from 'jquery';
const globalTheme = window.templateCustomizer.settings.style;


const BONIndex = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  const tab = searchParams.get('tab');
  const tip = searchParams.get('tip');

  //const cook_valEstado = cookies.get('boniValidarEstado');
  const [listTabs, setListTabs] = useState({ id1: '',id2: '',id3: '',id4: '',id5: '',id6: '' })
  const [valiTabs, setValiTabs] = useState({ id1: true,id2: true,id3: true,id4: true,id5: true,id6: true })
  useEffect(()=>{
    setListTabs({ 
      id1: (tab === "bon1a1" || ( tip!=='ver' && tab === null)) ? 'entra' : '',//este es el valido
      //id1: (tab === "bon1a1" || tab === null) ? 'entra' : '',//este es el valido
      //id1: (tab === "bon1a1") ? 'entra' : '',
      id2: (tab === "bonticketcompra") ? "entra" : '',
      id3: (tab === "bonticketcompraescala") ? "entra" : '',
      id4: (tab === "bonbasicaescala") ? "entra" : '',
      id5: (tab === "bonagrupacionescala") ? "entra" : '',
      id6: (tab === "bonagrupacionreglas") ? "entra" : '' 
    })
    setValiTabs({ 
      //id1: (tab === "bon1a1" || tab === null) ? false : true,//este es el valido
      id1: (tab === "bon1a1" || ( tip!=='ver' && tab === null)) ? false : true,//este es el valido
      //id1: (tab === "bon1a1") ? 'entra' : false,
      id2: (tab === "bonticketcompra"|| tab === null) ? false : true,
      id3: (tab === "bonticketcompraescala"|| tab === null) ? false : true,
      id4: (tab === "bonbasicaescala"|| tab === null) ? false : true,
      id5: (tab === "bonagrupacionescala"|| tab === null) ? false : true,
      id6: (tab === "bonagrupacionreglas"|| tab === null) ? false : true 
    })
  },[tab])


  const [listTabsVisu, setListTabsVisu] = useState({ id1: false,id2: false,id3: false,id4: false,id5: false,id6: false })
  const pruebaes = (valor) => {
    setListTabsVisu({ 
      id1: (valor === '1') ? true : false,
      id2: (valor === '2') ? true : false,
      id3: (valor === '3') ? true : false,
      id4: (valor === '4') ? true : false,
      id5: (valor === '5') ? true : false,
      id6: (valor === '6') ? true : false
    })
    //alert("funciona")
  }
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [],
  );

  return (
    <>
          <ThemeProvider theme={tableTheme}>
    <div className="col-xl-12 py-0">
      <h6 className="text-muted d-none">Inicio Bonificación</h6>
      <div className="nav-align-top">
          <ul className="nav nav-pills mb-3 " role="tablist">
              <li className="nav-item me-1">
                <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id1!=='' && 'active'}`} role="tab" data-bs-toggle="tab" data-bs-target="#bon1a1" aria-controls="bon1a1" aria-selected="true" onClick={() => {pruebaes('1')}} disabled={valiTabs.id1}><i className="tf-icons bx bx-street-view bx-xs" /> Bon. 1 a 1 </button>
              </li>
              <li className="nav-item me-1">
                <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id2!=='' && 'active'}`} role="tab" data-bs-toggle="tab" data-bs-target="#bonticketcompra" aria-controls="bonticketcompra" aria-selected="false" onClick={() => {pruebaes('2')}} disabled={valiTabs.id2}><i className="tf-icons bx bx-sticker bx-xs"  /> Bon. Ticket Compra</button>
              </li>
              <li className="nav-item me-1">
                <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id3!=='' && 'active'}`} role="tab" data-bs-toggle="tab" data-bs-target="#bonticketcompraescala" aria-controls="bonticketcompraescala" aria-selected="false" onClick={() => {pruebaes('3')}} disabled={valiTabs.id3}><i className="tf-icons bx bxs-sticker bx-xs" /> Bon. Ticket Compra Escala</button>
              </li>
              <li className="nav-item me-1">
                <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id4!=='' && 'active'}`} id='pruebatab' role="tab" data-bs-toggle="tab" data-bs-target="#bonbasicaescala" aria-controls="bonbasicaescala" aria-selected="false" onClick={() => {pruebaes('4')}} disabled={valiTabs.id4}><i className="tf-icons bx bx-popsicle bx-xs" /> Bon. Basica Escala</button>
              </li>
              {/* Bon. Agrupacion Escala */}
              {/* <li className="nav-item me-1">
                <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id5!=='' && 'active'}`} role="tab" data-bs-toggle="tab" data-bs-target="#bonagrupacionescala" aria-controls="bonagrupacionescala" aria-selected="false" onClick={() => {pruebaes('5')}}><i className="tf-icons bx bx-copy-alt bx-xs" /> Bon. Agrupacion Escala</button>
              </li> */}
              {/* <li className="nav-item me-1">
                <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 d-none" role="tab" data-bs-toggle="tab" data-bs-target="#bonreglas" aria-controls="bonreglas" aria-selected="false"><i className="tf-icons bx bx-menu bx-xs" /> Bon. Reglas</button>
              </li> */}
              <li className="nav-item me-1">
                <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id6!=='' && 'active'}`} role="tab" data-bs-toggle="tab" data-bs-target="#bonagrupacionreglas" aria-controls="bonagrupacionreglas" aria-selected="false" onClick={() => {pruebaes('6')}} disabled={valiTabs.id6}><i className="tf-icons bx bx-grid bx-xs" /> Bon. Agrupacion Reglas</button>
              </li>
              
          </ul>
          <div className="tab-content">
              <div className={`tab-pane fade ${listTabs.id1!=='' && 'show active'}`} id="bon1a1" role="tabpanel">
               {
                  (listTabsVisu.id1 || listTabs.id1)  && (
                    <BONUnoAUno />
                  )
                }
              </div>
              <div className={`tab-pane fade ${listTabs.id2!=='' && 'show active'}`} id="bonticketcompra" role="tabpanel">
                {
                  (listTabsVisu.id2 || listTabs.id2)  && (
                    <BONTicketCompra />
                  )
                }
              </div>
              <div className={`tab-pane fade ${listTabs.id3!=='' && 'show active'}`} id="bonticketcompraescala" role="tabpanel">
              {
                  (listTabsVisu.id3 || listTabs.id3)  && (
                    <BONTicketCompraEscala />
                )
                }
              </div>
              <div className={`tab-pane fade ${listTabs.id4!=='' && 'show active'}`} id="bonbasicaescala" role="tabpanel">
              {
                  (listTabsVisu.id4 || listTabs.id4)  && (
                    <BONBasicaEscala />
                )
                }
              </div>

              {/* Bon. Agrupacion Escala */}
              {/* <div className={`tab-pane fade ${listTabs.id5!=='' && 'show active'}`} id="bonagrupacionescala" role="tabpanel">
              {
                  (listTabsVisu.id5 || listTabs.id5)  && (
                <BONAgrupacionEscala />
                )
                }
              </div> */}
              <div className={`tab-pane fade ${listTabs.id6!=='' && 'show active'}`} id="bonagrupacionreglas" role="tabpanel">
              {
                  (listTabsVisu.id6 || listTabs.id6)  && (
                    <BONAgrupacionReglas />
                 )
              }
              </div>



          </div>
      </div>
      <ToastContainer />
    </div>
    </ThemeProvider>
    </>
  )
}

export default BONIndex