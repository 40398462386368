import React, { useState, useMemo, useEffect } from 'react'
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales'
import { ToastContainer } from 'react-toastify';
import { notify_valido, notify_warning, notify_error, newDateFValor } from '../../components/utils';
import { AButton } from '../../components/Button';
import { Link, NavLink, } from "react-router-dom";
//import $ from 'jquery'
import { Tooltip, Box, Modal, createTheme, ThemeProvider } from '@mui/material';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MaterialReactTable } from 'material-react-table';
import { BCEObsequioDetalle } from './SPBonif/BONUnoAUno'; 
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const globalTheme = window.templateCustomizer.settings.style;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.default',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const BONConIpar = () => {
  const [disabledb1, setdisabledb1] = useState(false);
  //const [disabledb2, setdisabledb2] = useState(false);
  //const [disabledb3, setdisabledb3] = useState(false);
  const [pending, setpending] = useState(false);
 // const [btnVisible2, setBtnVisible2] = useState(true);
  const [lst, setlst] = useState([]);
  const [sl_t_suc, setSl_t_suc] = useState('');
  const [filterActivo, setFilterActivo] = useState('');

  function savePathInCookie() {
    // Obtiene la parte después de la barra diagonal
    const path = window.location.pathname.substr(1);
    // Guarda el valor en una cookie con nombre "ultimapag"
    document.cookie = `ultimapag=${path}`;
  }

  const changeSl_t_suc = (event) => { setSl_t_suc(event.target.value); }

  // width: "10rem",
  //     Filter: ({ column }) => (
  //       <select className="form-select form-select-sm" onChange={(e) => setFilterActivo(e.target.value)} >
  //         <option value="">Todos</option>
  //         <option value="Bon.UnoAUno">UnoAUno</option>
  //         <option value="Bon.TicketCompra">TicketCompra</option>
  //         <option value="Bon.TicketCompraEscala">TicketCompraEscala</option>
  //         <option value="Bon.BasicaEscala">BasicaEscala</option>
  //         <option value="Bon.AgrupacionEscala">AgrupacionEscala</option>
  //         <option value="Bon.AgrupacionReglas">AgrupacionReglas</option>
  //       </select>
  //     ),

  const lsttipoudo = useMemo(() => [
    'Bon.UnoAUno',
    'Bon.TicketCompra',
    'Bon.TicketCompraEscala',
    'Bon.BasicaEscala',
    'Bon.AgrupacionEscala',
    'Bon.AgrupacionReglas',
    'Desc.UnoAUno',
    'Desc.TicketCompraEscala',
    'Desc.ComboEscala',
    'Desc.TicketCompra',
    'Desc.AgrupacionEscala',
    'Desc.AgrupacionReglas',
  ], []);
  const lsttipoudoar = useMemo(() => [
    'T1: Bonificación Simple',
    'T2: Bonificación por Soles',
    'T3: Combitos',
    'T4: Combazos',
    'T6: Escala',
  ], []);

  const columns = useMemo(() => [
    {
      accessorKey: 'nomTipos', header: 'TipoUdo',
      filterVariant: 'select',
      filterSelectOptions: lsttipoudo,
    },
    {
      accessorKey: 'NameIpar', header: 'NameIpar',
      filterVariant: 'select',
      filterSelectOptions: lsttipoudoar,
    },
    { header: 'nomCampo', accessorKey: 'nomCampo' },
    { header: 'CodeUdo', accessorKey: 'Code' },
    { header: 'Descripción', accessorKey: 'U_AB_BUDS' },
    {
      header: 'Activo', accessorKey: 'U_AB_BUAC',
      // muiTableBodyCellProps: ({cell}) => ({ 
      //   sx: { 
      //     backgroundColor:  '#91F77A',
      //     display: 'flex',
      //     justifyContent: 'center'
      //   } 
      // }),
      // Cell: ({ row }) => (
      //   <div className="align-items-center">
      //     <div className="form-check form-check-danger">
      //       <input className="form-check-input" type="checkbox" value='Y' onChange={(e) => handleInputCheckChange(e,row)} checked={(row.original.U_AB_BUAC==='Y')?'checked':''}  />
      //     </div>
      //   </div>
      // ),
      filterVariant: 'select',
      width: "10rem",
      Filter: ({ column }) => (
        <select className="form-select form-select-sm" onChange={(e) => setFilterActivo(e.target.value)} >
          <option value="">Todos</option>
          <option value="Y">Activo</option>
          <option value="N">Inactivo</option>
        </select>
      ),
      Cell: ({ row }) => (
        <div style={{ "color": row.original?.U_AB_BUAC === 'Y' ? 'green' : row.original?.U_AB_BUAC === 'N' ? 'red' : '' }} className="align-items-center">{(row.original?.U_AB_BUAC === 'Y') ? 'Activo' : row.original?.U_AB_BUAC === 'N' ? 'Inactivo' : ''}</div>
      ),

    },
    { header: 'FechaInicio', accessorKey: 'U_AB_BUFI' },
    { header: 'FechaFin', accessorKey: 'U_AB_BUFF' },
    { header: 'Sucursal', accessorKey: 'Sucursal' },
    { header: 'Obsequio', accessorKey: 'Obsequio' },

  ], [lsttipoudo]);

  const filteredData = filterActivo ? lst.filter(item => {
    if (filterActivo === "Y") {
      return item.U_AB_BUAC === filterActivo;
    } else if (filterActivo === "N") {
      return item.U_AB_BUAC === filterActivo;
    }
  }) : lst;

  const onClickListar = async () => {
    try {
      //onClickLimpiar()
      const url = '/apiListaIpar?vcodEmpresa=' + cook_idEmp+ '&vcodSucursal='+sl_t_suc;
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }

      setpending(true)
      setdisabledb1(true)
      const response = await fetch(url, opPe);
      const data = await response.json();
      //console.log(data);
      setlst(data)
      setdisabledb1(false)
      setpending(false)

    } catch (error) {
      setdisabledb1(false)
      setpending(false)
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }
  
  // const handleInputCheckChange = (e,row) => {

  //   let nuevosDatos = [...lst];
  //   const currentValue = row.original.U_AB_BUAC;
  //   const newValue = currentValue === 'Y' ? 'N' : 'Y';
  //   nuevosDatos[row.index]['U_AB_BUAC'] = newValue;
  //   setlst(nuevosDatos)

  // }



  useEffect(() => {
    savePathInCookie();
  }, []);
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [globalTheme],
  );

  const [rowSelection, setRowSelection] = useState([]);
  //const [botonVisible, setBotonVisible] = useState(false);
  
  const onClickActualizarMasivo = (table) => {

    // console.log("rowSelection", rowSelection);
    const selectedRFile = [];
    const selectedRFileCabe = [];
    var validarActIna = "A";
    const selectedRows = table.getSelectedRowModel().rows;
    selectedRows.map((row) => { return selectedRFile.push(row.original) })
    
    let countAct = 0;
    let countIna = 0;
    for (let i = 0; i < selectedRFile.length; i++) {
      if (selectedRFile[i].U_AB_BUAC === "Y") {
        countAct++;
      }
    }
    for (let i = 0; i < selectedRFile.length; i++) {
      if (selectedRFile[i].U_AB_BUAC === "N") {
        countIna++;
      }
    }

    if (countIna > countAct) {
      validarActIna = "I"
    }
    selectedRFile.map((row) => { return selectedRFileCabe.push({"Code":row.Code, "Tipo":row.Tipo, "Estado":(validarActIna === "I") ? "Y" : "N" }) })
    // console.log("countAct", countAct);
    // console.log("countIna", countIna);
    // console.log("arrCodig", selectedRFileCabe);


    if (selectedRows.length) {
      Swal.fire({
        title: 'ADVERTENCIA',
        text: '¿Desea confirmar la actualización?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: (validarActIna === "I") ? "ACTIVAR" : "INACTIVAR",
        customClass: {
          container: 'my-swal'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          var urlUpdate = '/apiActAI';
          //const Body = { vcodEmpresa: cook_idEmp, vcodSucursal: emp_sl_t_suc, validarActIna:validarActIna, vdtarray: [selectedRFileCabe] };
          const Body = { vcodEmpresa: cook_idEmp, vdtarray: selectedRFileCabe };
          const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
          const resp = await fetch(urlUpdate, opPe);//cambiar por proveedores
          const data = await resp.json();
          console.log("data",data);
          if (data.vl_rsp === 1) {
            notify_valido("Actualización exitosa")
            onClickListar()
          } else {
            notify_warning("¡Error al actualizar, intente mas tarde.!")
          }
          Swal.close()

          setRowSelection({});

        }
      })
    } else {
      notify_warning("Selecione uno o todas las filas que quiera asignar nuevos margenes")
    }



  }

  const onClickLimpiar = async (row) => {
    try {
      setlst([])
      setFilterActivo('')
      setRowSelection([])
      setdisabledb1(false)
      setpending(false)
    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }
  const onClickValidarEstado = (valor) => {
    cookies.set('boniValidarEstado', valor, { path: '/' });
  }

  /** Para datos de la fila */
  const [arrDatos, setArrDatoss] = useState({ txtCod: '', txtDBon: '', })
  const [validarTipo, setValidarTipo] = useState('');
  const [openModal, setOpenModal] = useState(false);
  //const [codExtrModalUni, setCodExtrModalUni] = useState('');
  const hanOpenModal = async(row) => {
    //setCodExtrModalUni(Code)
    console.log("row ", row);
    console.log("row.U_AB_BUFI ", row.U_AB_BUFI);
    console.log("row.U_AB_BUFF ", row.U_AB_BUFF);
    setOpenModal(true); 

    setArrDatoss(() => ({
      ...listDatos,
      txtCod: row.Code,
      txtDBon: row.U_AB_BUDS
    }));
    setValidarTipo(row.Tipo)

    handleKeyDown(null,row.Code, row.Tipo,row.U_AB_BUFI,row.U_AB_BUFF);
   }
  const hanClosModal= ()=> {
    setOpenModal(false);
    setArrDatoss(() => ({
      ...listDatos,
      txtCod: '',
      txtDBon: ''
    }));
  }


  /** Funciones De insertado obsequio  */
  const [validarPut, setValidarPut] = useState('0');
  const [disabledb11, setdisabledb11] = useState(false);
  // const [listDatos, setListDatos] = useState({ txtCod: '', txtDBon: '' })
  // const [listDataUstMemori, setlistDataUstMemori] = useState({ bceobsequiodetll: [] })
  const [listDatos, setListDatos] = useState({
    txtCod: '',
    txtNtco: '',
    txtDBon: '',
    txtActi: '',
    txtFIni: new Date(),
    txtFFin: new Date(),
    txtBode: '',
    txtMayo: '',
    txtMerc: '',
    txttipi: 'Y'
  })
  
  const [listDataUstMemori, setlistDataUstMemori] = useState({
    bceiniciodetallador:[],
    bcecliente:[],
    bcegrupoarticulo:[],
    bcevendedordetall:[],
    bcesucursaldetll: [],
    bcemesadetall: [],
    bceobsequiodetll: [],
    bcecategoriacliente:[],
    bcegrupoventas:[],
    bcecondicionespago:[],
    bcevendedoresexcluidos:[]

  })

  const handleFieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    setListDatos({
      ...listDatos,
      [fieldName]: value,
    });
  };
  
  const onclickCrearG = async() => {
    setdisabledb11(true)
    if ( listDatos.txtDBon !=="" ) {
      if (listDataUstMemori.bceobsequiodetll.length >= 1) {
        var url = ''
        if (validarTipo === "AB_BUU") { url = '/apiBuuInsert' }
        if (validarTipo === "AB_BCTC") { url = '/apiBtcInsert' }
        if (validarTipo === "AB_DCEH") { url = '/apiBtceInsert' }
        if (validarTipo === "AB_BOBE") { url = '/apiBbeInsert' }
        if (validarTipo === "AB_DBARC") { url = '/apiBagrInsert' }
        //const url = '/apiBuuInsert'

        const body = { vcodEmpresa:cook_idEmp, arrCabecera: listDatos, arrDetalle: listDataUstMemori, validarPut: validarPut};
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(url,opPe);
        const resdatos = await response.json();
        if (resdatos.vl_api === 1){ notify_warning("Error al registrar, verificar datos!") }
        if (resdatos.vl_existe === 1){ notify_warning("Ya esta registrado!") }
        if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
          notify_valido("Se registro correctamente!")
          setListDatos(() => ({
            ...listDatos,
            txtCod: resdatos.vl_codres
          }));
          setTimeout(() => {
            onClickListar()
            onClickLimpiar1()
            hanClosModal()
          }, 1000);
        }

      } else { notify_warning('Se requiere obsequio.'); }
    } else {
      if (listDatos.txtDBon===""){
        notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
      }
      
    }
    setdisabledb11(false)

  }
  const handleDataFromChild = (id,data) => {
    setlistDataUstMemori({
      ...listDataUstMemori,
      [id]:data
    });
  };
  const onClickLimpiar1 = async () => {
    setListDatos({
      txtCod: '',
      txtNtco: '',
      txtDBon: '',
      txtActi: '',
      txtFIni: new Date(),
      txtFFin: new Date(),
      txtBode: '',
      txtMayo: '',
      txtMerc: '',
      txttipi: ''
    })
    setlistDataUstMemori({
      bceiniciodetallador: [],
      bcecliente: [],
      bcegrupoarticulo: [],
      bcevendedordetall: [],
      bcesucursaldetll: [],
      bcemesadetall: [],
      bceobsequiodetll: [],
      bcecategoriacliente: [],
      bcegrupoventas: [],
      bcecondicionespago: [],
      bcevendedoresexcluidos:[]
    })

  }
  
  const onHandleVPut = (dato) => {
    setValidarPut(dato)
  }
  const handleKeyDown = async(e, cod, tip,fechaIni,fechaFin) => {

    Swal.fire({
      title: 'Procesando...',
      html: 'Se esta consultado datos.',
      didOpen: () => { Swal.showLoading() },
      customClass: { container: 'my-swal' },
      allowOutsideClick: false
    })
    var url = ''
    if (tip === "AB_BUU") { url = '/apiBuu' }
    if (tip === "AB_BCTC") { url = '/apiBtc' }
    if (tip === "AB_DCEH") { url = '/apiBtce' }
    if (tip === "AB_BOBE") { url = '/apiBbe' }
    if (tip === "AB_DBARC") { url = '/apiBagr' }


    //const url = '/apiBuu'
    const body = { vcodEmpresa: cook_idEmp, vlCodigo: cod};
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url,opPe);
    const resdatos = await response.json();
    console.log("resdatos que llegan", resdatos);
    if(resdatos.length){


      
      var arrcabe, arrdatos;
      if (tip === "AB_DBARC") {
        console.log("entra agrupacion reglas");
        arrcabe = { 
          ...listDatos,
          txtActi:'N',
          txtNtco:resdatos[0].txtNtco,
          // txtFIni:resdatos[0].txtFIni,
          // txtFFin:resdatos[0].txtFFin,
          txtFIni:fechaIni,
          txtFFin:fechaFin,
          txtBode:resdatos[0].txtBode,
          txtMayo:resdatos[0].txtMayo,
          txtMerc:resdatos[0].txtMerc,
          txttipi: 'Y'
        }
        arrdatos = {
          ...listDataUstMemori, 
          bceiniciodetallador:resdatos[0]?.arrDetalle1          
        }
      }else {
        arrcabe = { 
          ...listDatos,
          txtActi:'N',
          // txtFIni:resdatos[0].txtFIni,
          // txtFFin:resdatos[0].txtFFin,
          txtFIni:fechaIni,
          txtFFin:fechaFin,
          txtBode:resdatos[0].txtBode,
          txtMayo:resdatos[0].txtMayo,
          txtMerc:resdatos[0].txtMerc,
          txttipi: 'Y'
        }
        arrdatos = {
          ...listDataUstMemori, 
          bceiniciodetallador:resdatos[0]?.arrDetalle1,
          bcecliente:resdatos[0]?.arrDetalle2,
          bcegrupoarticulo:resdatos[0]?.arrDetalle3,
          bcevendedordetall:resdatos[0]?.arrDetalle4,
          bcesucursaldetll:resdatos[0]?.arrDetalle5,
          bcemesadetall:resdatos[0]?.arrDetalle6,
          bcecategoriacliente:resdatos[0]?.arrDetalle9,
          bcegrupoventas:resdatos[0]?.arrDetalle10,
          bcecondicionespago:resdatos[0]?.arrDetalle11,
          bcevendedoresexcluidos:resdatos[0]?.arrDetalle12,
          
        }
      }
      console.log("resdatos arreglados para que se inserten => ", arrcabe);

      setListDatos(arrcabe);
      setlistDataUstMemori(arrdatos)
      


    }else{ 
      notify_warning('Codigo no existe en nuestra base de datos.');
      onClickLimpiar()
    }

    Swal.close()
    

  };


  return (
    <div className="col-xl-12">
      <h6 className="text-muted d-none">Inicio Bonificacion</h6>
      {/* <div>BONConIpar</div> */}
      <div className="card card-action">
        {/* <div className="card-header ">
                <div className="card-action-title">Lista </div>
            </div> */}
        <div className="card-body ">
          <div className="row">
            <div className="col-lg-2">Sucursal:</div>
            <div className="col-lg-2">
              <SelectSucursal id="veh_slc_suc" value={sl_t_suc} onChange={changeSl_t_suc} />
            </div>
            <div className="col-lg-4 text-center ">
              <AButton tp_tilde="Consultar información" tp_posit="top" bt_class="btn btn-label-youtube btn-sm" bt_funct={onClickListar} disabled={disabledb1} ic_class="bx bx-cloud-download bx-ms" vl_tpbtn={2} />&nbsp;
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
          <ThemeProvider theme={tableTheme}>
            <div className="card-datatable table-responsive">
              <MaterialReactTable
                columns={columns}
                data={filteredData}
                state={{ rowSelection, isLoading: pending }}
                initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
                enableGrouping
                enableRowSelection
                //getRowId={(row) => row.Code}
                positionToolbarAlertBanner="bottom"
                onRowSelectionChange={setRowSelection}
                enableStickyHeader enableColumnResizing
                muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                localization={MRT_Localization_ES}
                enablePagination={true}
                muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                enableGlobalFilterModes
                rowVirtualizerProps={{ overscan: 150 }}
                enableRowVirtualization

                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    header: 'Options', //change header text
                    size: 110, //make actions column wider
                  },
                }}
                enableRowActions
                renderRowActions={({ row }) => (
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                    <Tooltip title="Ir a Visualizar" placement="left">
                     <span>
                      <Link to={'/'+ ((row.original.nomCampo.substring(0, 3) === "des") ? 'bondescuentos' : 'bonificaciones' ) + '?tip=ver&tab=' + row.original.nomCampo + '&cod=' + row.original.Code} onClick={ () => onClickValidarEstado('ver')} target="_blank" className="btn btn-sm btn-icon btn-label-success" ><i className="bx bx-ms bx-show"></i></Link>
                     </span> 
                    </Tooltip>
                    {
                      (row.original.nomCampo.substring(0, 3) !== "des"  ) && (
                        <AButton tp_tilde="Cambiar Obsequio" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-warning" bt_funct={ (e) => {hanOpenModal(row.original)}} ic_class="bx bx-duplicate bx-ms" vl_tpbtn={1} />
                      )
                    }

                    <Tooltip title="Copiar a Manual" placement="left">
                    {
                      (row.original.nomCampo.substring(0, 3) === "des") ?
                      <>
                      <Link to={'/bondescuentos?tip=copy&tab=' + row.original.nomCampo + '&cod=' + row.original.Code} onClick={ () => onClickValidarEstado('copy')} target="_blank" className="btn btn-sm btn-icon btn-label-reddit" ><i className="bx bx-copy bx-ms"></i></Link>
                      </>
                      :
                      <Link to={'/bonificaciones?tip=copy&tab=' + row.original.nomCampo + '&cod=' + row.original.Code} onClick={ () => onClickValidarEstado('copy')} target="_blank" className="btn btn-sm btn-icon btn-label-reddit" ><i className="bx bx-copy bx-ms"></i></Link>
                      
                    }
                    </Tooltip>
                  
                    
                  </Box>
                )}

                renderTopToolbarCustomActions={({ table, row }) => (
                  <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                    <button className="btn btn-sm btn-success" variant="contained"

                      onClick={() => { onClickActualizarMasivo(table) }}

                    >
                      <i className='bx bx-ms bx-sync'></i>Acti-Inac
                    </button>

                  </Box>
                )}
                muiCircularProgressProps={{ color: 'error' }}
                muiSkeletonProps={{
                  animation: 'pulse',
                  height: 28,
                }}

              />
            </div>
            
          </ThemeProvider>
        </div>
      </div>

      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '50%', height: '70%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={hanClosModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-3" style={{ userSelect: 'none' }}>
            <h6 className="text-muted mb-2">Detalle registro de la fila seleccionada</h6>
            <div className="row mb-3">
              <div className="col-md-1">Codigo:</div>
              <div className="col-md-3">
                <input type="text" id="txtCod" name="txtCod" className="form-control form-control-sm"  autoComplete='off'  value={arrDatos.txtCod}  disabled/>
              </div>
              <div className="col-md-1">D.Bonif:</div>
              <div className="col-md-7">
                <input type="text" id="txtDBon" name="txtDBon" className="form-control form-control-sm"  autoComplete='off' value={arrDatos.txtDBon} disabled/>
              </div>
            </div>
            <h6 className="text-muted mb-2">Detalle de registro a copiar</h6>
            <div className="row ">
              <div className="col-md-1">Codigo:</div>
              <div className="col-md-3">
                <input type="text" id="txtCod" name="txtCod" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtCod} onChange={handleFieldChange('txtCod')}   disabled/>
              </div>
              <div className="col-md-1">D.Bonif:</div>
              <div className="col-md-7">
                <input type="text" id="txtDBon" name="txtDBon" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtDBon} onChange={handleFieldChange('txtDBon')} />
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-4"></div>
              <div className="col-lg-8 text-end"> 
                <AButton tp_tilde="Crear" bt_descr="Crear" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2}  bt_funct={onclickCrearG} disabled={disabledb11} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="nav-align-top">
                <ul className="nav nav-pills " role="tablist">
                  <li className="nav-item me-1">
                    <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 active" role="tab" data-bs-toggle="tab" data-bs-target="#bceobsequiodetll" aria-controls="bceobsequiodetll" aria-selected="false" ><i className="tf-icons bx bxs-package bx-xs" /> BUUObsequio  </button>
                  </li>
                </ul>
                <div className="tab-content" style={{'height':'430px'}}>
                  <div className="tab-pane fade show active" id="bceobsequiodetll" role="tabpanel">
                    <BCEObsequioDetalle 
                      arrCampDatos={listDatos} 
                      onDataUpdate={handleDataFromChild}
                      listInitData={listDataUstMemori.bceobsequiodetll}
                      onHandleVPut={onHandleVPut} 
                      // onValCopyDat={''} 
                      // onValBloqueo={''}
                    /> 
                  </div>
                </div>
              </div>
            </div>

            

          </div>
        </Box>
      </Modal>
    <ToastContainer />
    </div>
  )
}

export default BONConIpar