import React from 'react'
//import {Page, Text, Document, StyleSheet, View } from "@react-pdf/renderer"
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer"
import logo_ab from '../../assets/img/logo/logo_ab.png';
import moment from 'moment';

const styles = StyleSheet.create({
    page: { padding: 30 },
    title: { fontSize: 14, marginBottom: 10 },
    subtitle: { fontSize: 12, marginTop: 15, marginBottom: 5 },

    // table: { width: '100%', fontSize: 6, border: '1px solid #000000', borderRadius: 3,borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 },
    table: { width: '100%', fontSize: 6, color: '#000000' },
    row: { flexDirection: 'row' },
    rowEven: { backgroundColor: '#f0f0f0' },
    rowHeader: { backgroundColor: '#ffffff', fontfamily: 'Roboto', fontweight: 700, borderBottom: '1px double  #000000' },

    rowCuerpo: { borderBottom: '1px dashed  #000000' },

    rowFooter: { backgroundColor: '#c0c0c0', fontStyle: 'italic' },
    column2: { borderStyle: 'solid', borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    column: {},

    tabla2: { width: '100%', fontSize: 7, border: '1px solid #000000', borderRadius: 3, borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 },

    cell: { padding: 5, fontSize: 6,fontFamily: 'Helvetica' },
    cellHeader: { fontFamily: 'Helvetica-Bold', fontWeight: '900' },
    cellWithBorder: { border: '1px solid #000000' },
    cellCenter: { textAlign: 'center' },
    cellRight: { textAlign: 'right' },
    cellFullBorder: { borderTop: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', textAlign: 'center' },
    // cellFullBorder: { borderTop: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', textAlign: 'center' },
    cellWithLeftBorder: { borderLeft: '1px solid #000000' }, // Estilo para el borde izquierdo

});

const TableCell = ({ children, style = { textAlign: 'CENTER' }, rowSpan = 1, colSpan = 1 }) => (
    <Text style={[styles.cell, style]} colSpan={colSpan}>{children}</Text>
);

const TableRow = ({ children, isHeader = false, isEven = false, isFooter = false, isCuerpo = false }) => (
    <View style={[styles.row, isHeader && styles.rowHeader, isEven && styles.rowEven, isFooter && styles.rowFooter, isCuerpo && styles.rowCuerpo]}>{children}</View>
);

const TableColumn = ({ children, width = '25%' }) => (
    <View style={[styles.column, { width }]}>{children}</View>
);
const TableColumn2 = ({ children, width = '25%' }) => (
    <View style={[styles.column2, { width }]}>{children}</View>
);
const TableColumn3 = ({ children, width = '25%' }) => (
    <View style={[styles.cellWithLeftBorder, { width }]}>{children}</View>
);

const Table = ({ children }) => <View style={styles.table}>{children}</View>;

export const PdfLiquidacionTrans = ({ datallegada, dataBancos, codigoCons }) => {

    const groupBy = (data, key) => {
        return data.reduce((result, item) => {
            const groupKey = item[key];
            if (!result[groupKey]) {
                result[groupKey] = [];
            }
            result[groupKey].push(item);
            return result;
        }, {});
    };

    const groupedByCardName = groupBy(datallegada, 'Tipo');
    //   console.log("groupedByCardName", groupedByCardName );

    //const  i = 1;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Text style={{ fontSize: 15, marginBottom: 10 }}> <Image styles={styles.image} src={logo_ab} />Liquidacion transportista</Text>

                <View styles={styles.div_1}>
                    <Text style={{ fontSize: 8, marginBottom: 2 }}>Fecha emision: {moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}</Text>
                    <Text style={{ fontSize: 8, marginBottom: 2 }}>Consolidado: {codigoCons}</Text>
                </View>
                <Table style={styles.table}>
                    <TableRow isHeader>
                        <TableColumn><TableCell style={styles.cellHeader}>VENDEDOR</TableCell></TableColumn>
                        <TableColumn width="40%"><TableCell style={styles.cellHeader}>CLIENTE</TableCell></TableColumn>
                        <TableColumn ><TableCell style={styles.cellHeader}>RUTA</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>FECHA</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>DOCUMENTO</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>FECHA DOC</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>TOTAL</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>SALDO</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>ENTREGA</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>PAGADO HASTA</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>ENTIDAD</TableCell></TableColumn>
                    </TableRow>
                    {Object.keys(groupedByCardName).map((cardName) => {
                        // const groupedByItmsGrpNam = groupBy(groupedByCardName[cardName], 'SlpName');
                        // console.log("groupedByItmsGrpNam:: ", groupedByItmsGrpNam);
                        let totalImporte = groupedByCardName[cardName].reduce((total, item) => {
                            return total + parseFloat(item.SumApplied);
                        }, 0.00);
                        // totalImporte = parseFloat(totalImporte.toPrecision(7));
                        totalImporte = parseFloat(totalImporte.toFixed(2));
                        let totalPagado = groupedByCardName[cardName].reduce((total, item) => {
                            return total + parseFloat(item.SumApplied) + parseFloat(item.Saldo);
                        }, 0.00);
                        // totalPagado = parseFloat(totalPagado.toPrecision(5));
                        totalPagado = parseFloat(totalPagado.toFixed(2));
                        return (
                            <>
                                <TableRow>
                                    <TableColumn width="100%">
                                        <TableCell style={{ backgroundColor: '#ffffff', color: '#000000', textAlign: 'LEFT', fontWeight: 'bold', borderBottom: '1px double  #000000' }} >
                                            {cardName}
                                        </TableCell>
                                    </TableColumn>
                                </TableRow>
                                {/* {Object.keys(groupedByItmsGrpNam).map((itmsGrpNam) => {
                                    let totalImporte = parseFloat(
                                        groupedByItmsGrpNam[itmsGrpNam]
                                            .reduce((total, item) => total + parseFloat(item.Importe), 0)
                                            .toFixed(4)
                                    );
                                    return (
                                        <React.Fragment key={itmsGrpNam}> */}
                                {/* 
                                            <TableRow>
                                                <TableColumn width="100%">
                                                    <TableCell style={{ backgroundColor: '#d0d0d0', textAlign: 'LEFT', fontWeight: 'bold' }} >
                                                        {itmsGrpNam}
                                                    </TableCell>
                                                </TableColumn>
                                            </TableRow> */}
                                {groupedByCardName[cardName].map((item, index) => (
                                    <TableRow key={index} isCuerpo >
                                        <TableColumn><TableCell>{item.SlpName}</TableCell></TableColumn>
                                        <TableColumn width="40%"><TableCell>{item.CardName}</TableCell></TableColumn>
                                        <TableColumn><TableCell>{item.Zona}</TableCell></TableColumn>
                                        <TableColumn><TableCell>{moment(item.DocDate).format('YYYY-MM-DD')}</TableCell></TableColumn>
                                        <TableColumn><TableCell>{item.Documento}</TableCell></TableColumn>
                                        <TableColumn><TableCell>{moment(item.FechaDoc).format('YYYY-MM-DD')}</TableCell></TableColumn>
                                        <TableColumn><TableCell>{item.DocTotal}</TableCell></TableColumn>
                                        <TableColumn><TableCell>{item.Saldo}</TableCell></TableColumn>
                                        <TableColumn><TableCell>{item.Entrega}</TableCell></TableColumn>
                                        <TableColumn><TableCell>{item.SumApplied}</TableCell></TableColumn>
                                        <TableColumn><TableCell>{item.Nombre_Banco}</TableCell></TableColumn>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableColumn width="100%">
                                        <TableCell style={{ backgroundColor: '#ffffff', textAlign: 'LEFT', fontWeight: 'bold' }} >
                                            CANTIDAD:{groupedByCardName[cardName].length}  &nbsp;&nbsp;&nbsp;&nbsp;     TOTAL: {totalImporte} &nbsp;&nbsp;&nbsp;&nbsp;   TOTAL PAGADO: {totalPagado}
                                        </TableCell>
                                    </TableColumn>
                                </TableRow>
                                {/* </React.Fragment>
                                    );
                                }
                                )} */}

                            </>
                        );
                    })}
                </Table>

                <div style={{ height: "15px" }}
                ></div>


                <TableRow>
                    <TableColumn width="100%">
                        <TableCell style={{ backgroundColor: '#f0f0f0', textAlign: 'CENTER', fontWeight: 'bold' }} >
                            MONTO POR ENTIDADES
                        </TableCell>
                    </TableColumn>
                </TableRow>

                <Table style={styles.tabla2}>
                    <TableRow isHeader>
                        <TableColumn width="50%"><TableCell style={styles.cellHeader}>TIPO</TableCell></TableColumn>
                        <TableColumn width="50%"><TableCell style={styles.cellHeader}>PAGO</TableCell></TableColumn>
                    </TableRow>
                    {/* {Object.keys(groupedByCardName).map((cardName) => {
                        return (
                            <> */}
                    {/* <TableRow>
                                    <TableColumn width="100%">
                                        <TableCell style={{ backgroundColor: '#f0f0f0', textAlign: 'LEFT', fontWeight: 'bold' }} >
                                            {cardName}
                                        </TableCell>
                                    </TableColumn>
                                </TableRow> */}
                    {dataBancos.map((item, index) => (
                        <TableRow key={index} >
                            <TableColumn2 width="50%"><TableCell>{item.Tipo}</TableCell></TableColumn2>
                            <TableColumn2 width="50%"><TableCell>{ parseFloat(item.Pago).toFixed(2)}</TableCell></TableColumn2>
                        </TableRow>
                    ))}
                    {/* </>
                        );
                    })} */}
                </Table>
            </Page>
        </Document>
    )
};

export const PdfLiquidacionResumen = ({ datallegada, fecha , dataBancos, codigoCons }) => {

    const groupBy = (data, key) => {
        return data.reduce((result, item) => {
            const groupKey = item[key];
            if (!result[groupKey]) {
                result[groupKey] = [];
            }
            result[groupKey].push(item);
            return result;
        }, {});
    };
    let newData = datallegada.map(obj => ({
        ...obj,

        cargoEfectivo: obj.Efectivo,
        cargoDeposito: obj.Deposito,
        cargoAbono: 0.00,
        cargoCheque: 0.00,

        abonoEfectivo: obj.Abono,
        abonoDeposito: 0.00,
        abonoAbono: 0.00,
        abonoCheque: 0.00,
    }));
    // const groupedByCardName = groupBy(datallegada, 'Tipo');
    const groupedByCardName = newData;
    let sumaImporte = groupedByCardName.reduce((suma, item) => suma + Number(item.Importe), 0).toFixed(2);
    let sumaEfectivo = groupedByCardName.reduce((suma, item) => suma + Number(item.Efectivo), 0).toFixed(2);
    let sumaDeposito = groupedByCardName.reduce((suma, item) => suma + Number(item.Deposito), 0).toFixed(2);
    let sumaAbonoEfectivo = groupedByCardName.reduce((suma, item) => suma + Number(item.Abono), 0).toFixed(2);

    const  campoFechaImpresion= `Fecha impresion:        ${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')} `
    const  campoFecha=          `Fecha de   Pago:        ${fecha} `

    //const  i = 1;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Text style={{ fontSize: 15, marginBottom: 10 }}> <Image styles={styles.image} src={logo_ab} />Resumen de caja </Text>

                <View styles={styles.div_1}>
                    <Text style={{ fontSize: 8, marginBottom: 2 }}>{campoFechaImpresion}</Text>
                    <Text style={{ fontSize: 8, marginBottom: 2, fontFamily: 'Helvetica-Bold' }}>{campoFecha}</Text>

                    {/* <Text style={{ fontSize: 8 ,marginBottom: 2  }}>Consolidado: {codigoCons}</Text> */}
                </View>
                <Table style={styles.table}>
                    <TableRow isHeader>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>
                        <TableColumn width="40%"><TableCell style={styles.cellHeader}></TableCell></TableColumn>
                        {/* <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn> */}
                        <TableColumn3><TableCell style={styles.cellHeader}></TableCell></TableColumn3>
                        <TableColumn ><TableCell style={styles.cellHeade}>CARGO</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>

                        <TableColumn3><TableCell style={styles.cellHeader}>ABONO</TableCell></TableColumn3>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>

                    </TableRow>
                    <TableRow isHeader>
                        <TableColumn><TableCell style={styles.cellHeader}>TIPO</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>NUMERO</TableCell></TableColumn>
                        <TableColumn width="40%"><TableCell style={styles.cellHeader}>COBRADOR</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>IMPORTE</TableCell></TableColumn>

                        <TableColumn><TableCell style={styles.cellHeader}>EFECTIVO</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>DEPOSITO</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>ABONO</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>CHEQUE</TableCell></TableColumn>

                        <TableColumn><TableCell style={styles.cellHeader}>EFECTIVO</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>DEPOSITO</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>ABONO</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>CHEQUE</TableCell></TableColumn>
                    </TableRow>



                    {groupedByCardName.map((item, index) => (
                        <TableRow key={index} isCuerpo >


                            <TableColumn><TableCell >{item.Tipo}</TableCell></TableColumn>

                            <TableColumn><TableCell>{item.Consolidado}</TableCell></TableColumn>
                            <TableColumn width="40%"><TableCell>{item.Chofer}</TableCell></TableColumn>
                            <TableColumn><TableCell>{item.Importe}</TableCell></TableColumn>

                            <TableColumn><TableCell>{item.cargoEfectivo}</TableCell></TableColumn>
                            <TableColumn><TableCell>{item.cargoDeposito}</TableCell></TableColumn>
                            <TableColumn><TableCell>{item.cargoAbono}</TableCell></TableColumn>
                            <TableColumn><TableCell>{item.cargoCheque}</TableCell></TableColumn>

                            <TableColumn><TableCell>{item.abonoEfectivo}</TableCell></TableColumn>
                            <TableColumn><TableCell>{item.abonoDeposito}</TableCell></TableColumn>
                            <TableColumn><TableCell>{item.abonoAbono}</TableCell></TableColumn>
                            <TableColumn><TableCell>{item.abonoCheque}</TableCell></TableColumn>
                        </TableRow>
                    ))}

                    <TableRow>
                        <TableColumn width="100%">
                            <TableCell style={{ backgroundColor: '#f0f0f0', textAlign: 'LEFT', fontWeight: 'bold' }} >
                                {/* CANTIDAD:{groupedByCardName[cardName].length}  &nbsp;&nbsp;&nbsp;&nbsp;     TOTAL: {totalImporte} &nbsp;&nbsp;&nbsp;&nbsp;   TOTAL PAGADO: {totalPagado} */}
                            </TableCell>
                        </TableColumn>

                    </TableRow>

                    <TableRow>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>
                        <TableColumn width="40%"><TableCell style={styles.cellHeader}>TOTAL</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>{sumaImporte}</TableCell></TableColumn>

                        <TableColumn><TableCell style={styles.cellHeader}>{sumaEfectivo}</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>{sumaDeposito}</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>0</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>0</TableCell></TableColumn>

                        <TableColumn><TableCell style={styles.cellHeader}>{sumaAbonoEfectivo}</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>0</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>0</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>0</TableCell></TableColumn>
                    </TableRow>



                    {/* <TableRow>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>EFECTIVO</TableCell></TableColumn>
                        <TableColumn width="40%"><TableCell style={styles.cellHeader}> {sumaEfectivo}</TableCell></TableColumn>

                    </TableRow>
                    <TableRow>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>DEPOSITO</TableCell></TableColumn>
                        <TableColumn width="40%"><TableCell style={styles.cellHeader}>{sumaDeposito}</TableCell></TableColumn>

                    </TableRow><TableRow>
                        <TableColumn><TableCell style={styles.cellHeader}></TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>CHEQUE</TableCell></TableColumn>
                        <TableColumn width="40%"><TableCell style={styles.cellHeader}>0.00</TableCell></TableColumn>

                    </TableRow> */}

                    <TableRow>
                        <TableColumn width="100%">
                            <TableCell style={{ backgroundColor: '#f0f0f0', textAlign: 'left', fontWeight: 'bold', whiteSpace: 'pre-line' }} >
                                EFECTIVO: &nbsp;&nbsp;&nbsp;&nbsp;  {sumaEfectivo} {'\n'} {'\n'}
                                DEPOSITO: &nbsp;&nbsp;&nbsp;&nbsp;  {sumaDeposito} {'\n'}{'\n'}
                                ABONO:    &nbsp;&nbsp;&nbsp;&nbsp;  {sumaAbonoEfectivo} {'\n'}{'\n'}
                                CHEQUE    &nbsp;&nbsp;&nbsp;&nbsp;  0.0
                            </TableCell>
                        </TableColumn>
                        {/* <TableColumn width="100%">
                            <TableCell style={{ backgroundColor: '#f0f0f0', textAlign: 'left', fontWeight: 'bold' }}>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ display: 'inline-block', width: '100px' }}>EFECTIVO:</span>
                                    <span>{sumaEfectivo}</span>
                                </div>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ display: 'inline-block', width: '100px' }}>DEPOSITO:</span>
                                    <span>{sumaDeposito}</span>
                                </div>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ display: 'inline-block', width: '100px' }}>ABONO:</span>
                                    <span>{sumaAbonoEfectivo}</span>
                                </div>
                                <div>
                                    <span style={{ display: 'inline-block', width: '100px' }}>CHEQUE:</span>
                                    <span>0.0</span>
                                </div>
                            </TableCell>
                        </TableColumn> */}

                    </TableRow>
                </Table>

                <div style={{ height: "15px" }}
                ></div>

            </Page>
        </Document>
    )
};

export const PdfLiquidacionPagos = ({ dataBancos, fecha , consolidado, chofer }) => {

    //const  i = 1;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Text style={{ fontSize: 15, marginBottom: 10 }}> <Image styles={styles.image} src={logo_ab} />Voucher de Pagos</Text>
                <View styles={styles.div_1}>
                    <Text style={{ fontSize: 8, marginBottom: 2 ,fontFamily: 'Helvetica-Bold'}}>FECHA : {fecha}</Text>
                    {/* <Text style={{ fontSize: 8 ,marginBottom: 2  }}>Consolidado: {codigoCons}</Text> */}
                </View>
                <View styles={styles.div_1}>
                    <Text style={{ fontSize: 8, marginBottom: 2 ,fontFamily: 'Helvetica-Bold'}}>CONSOLIDADO : {consolidado}</Text>
                    {/* <Text style={{ fontSize: 8 ,marginBottom: 2  }}>Consolidado: {codigoCons}</Text> */}
                </View>
                <View styles={styles.div_1}>
                    <Text style={{ fontSize: 8, marginBottom: 2 ,fontFamily: 'Helvetica-Bold'}}>CHOFER : {chofer}</Text>
                    {/* <Text style={{ fontSize: 8 ,marginBottom: 2  }}>Consolidado: {codigoCons}</Text> */}
                </View>
                <TableRow>
                    <TableColumn width="100%">
                        <TableCell style={{ backgroundColor: '#f0f0f0', textAlign: 'CENTER', fontWeight: 'bold' }} >
                            MONTO POR ENTIDADES
                        </TableCell>
                    </TableColumn>
                </TableRow>

                <Table style={styles.tabla2}>
                    <TableRow isHeader>
                        <TableColumn width="50%"><TableCell style={styles.cellHeader}>TIPO</TableCell></TableColumn>
                        <TableColumn width="50%"><TableCell style={styles.cellHeader}>PAGO</TableCell></TableColumn>
                    </TableRow>
                   
                    {dataBancos.map((item, index) => (
                        <TableRow key={index} >
                            <TableColumn2 width="50%"><TableCell>{item.Tipo}</TableCell></TableColumn2>
                            <TableColumn2 width="50%"><TableCell>{item.Pago}</TableCell></TableColumn2>
                        </TableRow>
                    ))}
                   
                </Table>
            </Page>
        </Document>
    )
};
// export default PdfAlmacenT001