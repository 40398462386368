import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import 'react-data-table-component-extensions/dist/index.css';
import { Box, Modal } from "@mui/material";
import { MaterialReactTable } from 'material-react-table'

import { AButton } from "../../components/Button";
import { createTheme, ThemeProvider } from '@mui/material';
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { SelectSucursal } from "../../components/Selects/SelectsPrincipales";
import { notify_valido, notify_warning } from "../../components/utils";
import Swal from 'sweetalert2';
import Cookies from "universal-cookie";
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.default',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const COMMesa = () => {
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [codeForTable, setCodeForTable] = useState('');
  const [dataForTable, setDataForTable] = useState([]);
  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }
  const listar = async () => {
    if (!emp_sl_t_suc || emp_sl_t_suc === '9999') {
      notify_warning('Seleccione una sucursal');
      return;
    }
    const url_lstClientes = `/getMesas?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    if (data.length === 0) {
      notify_warning('Sin clientes registrados')
    }
    setCodeForTable(emp_sl_t_suc);
    setDataForTable(data);
  }

  const setDataReturn = () => {
    listar();
  }

  return (
    <div className='d-flex flex-column'>
      <h6 className="text-muted">Mesa</h6>
      <div className="d-flex">
        <div className="col-lg-1">Sucursal:</div>
        <div className="col-lg-2">
          <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
        </div>
        <div className="col-lg-2">
          <div className="text-center mb-3">
            <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={listar}>
              <i className='bx bx-list-ul' ></i>&nbsp;Consultar
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <ViewTableMesa code={codeForTable} dataTable={dataForTable} sucursal={emp_sl_t_suc} dataReturn={setDataReturn} />
      </div>
      <ToastContainer />
    </div >
  );
};

export const ViewTableMesa = ({ code, dataTable, sucursal, dataReturn }) => {
  const [dataFiltred, setDataFiltred] = useState([]);
  const [openFirstModal, setOpenFirstModal] = useState(false);
  const [dataSelected, setDataSelected] = useState([]);
  const [loading, setloading] = useState(false)
  const columns = useMemo(() => [
    { header: "Codigo", accessorKey: "code" },
    { header: "Descripcion", accessorKey: "description" },
    { header: "Sucursal", accessorKey: "branch" },
  ], []);
  const handleOpen = () => {
    setOpenFirstModal(true);
  };

  const handleClose = () => {
    setOpenFirstModal(false);
  };

  const handleRowClicked = async (row) => {
    const fechtApi = async (CardCode) => {
      const url = `getMesaByCode?codigo=${CardCode}`;
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
      const response = await fetch(url, opPe);
      const data = await response.json();
      return data;
    };
    setDataSelected(await fechtApi(row.code));
    handleOpen();
  }

  const handleRowClickedDelete = async (row) => {
    Swal.fire({
      title: 'CONFIRMACION',
      text: `¿Deseas eliminar la mesa ${row.description}?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      customClass: {
        container: 'my-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        const url = `/deleteMesaByWeb?vcodEmpresa=${cook_idEmp}&codigo=${row.code}`;
        const opPe = { method: "DELETE", headers: { 'Content-Type': 'application/json' } }
        const response = await fetch(url, opPe);
        const data = await response.json();
        if (data) {
          notify_valido("Eliminado correctamente");
          dataReturn(true);
        }
      }
    });
  }

  const handleButtonClick = () => {
    setDataSelected([]);
    handleOpen();
  };

  useEffect(() => {
    setloading(true)
    setDataFiltred(dataTable);
    setloading(false)
  }, [dataTable]);
  const globalTheme = window.templateCustomizer.settings.style;
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [globalTheme],
  );
  const setDataReturn = () => {
    dataReturn(true);
    handleClose();
  };

  return (
    <>
      <div className="d-flex col-12">
        <div className="card-body ">
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={dataFiltred}
              enablePagination={true}
              enableRowActions
              renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '2px' }}>
                  <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-label-warning btn-icon btn-sm" ic_class="bx bxs-edit bx-ms" bt_funct={() => handleRowClicked(row.original)}
                    id={JSON.stringify(row.original)} vl_tpbtn={1} />
                  <AButton tp_tilde="Eliminar" tp_posit="top" bt_class="btn btn-label-youtube btn-icon btn-sm" ic_class="bx bxs-trash bx-ms" bt_funct={() => handleRowClickedDelete(row.original)}
                    id={JSON.stringify(row.original)} vl_tpbtn={1} />
                </Box>
              )}
              enableGrouping={true}
              state={{ isLoading: loading }}
              localization={MRT_Localization_ES}
              renderTopToolbarCustomActions={({ table }) => (
                code && !loading ?
                  <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                    <AButton tp_tilde="Nueva mesa" tp_posit="top" bt_class="btn btn-label-success btn-sm " ic_class="fa-solid fa-plus" vl_tpbtn={1}
                      bt_funct={handleButtonClick}
                    />
                  </Box> :
                  <div></div>
              )}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}
            />
          </ThemeProvider>
          <Modal
            open={openFirstModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <ThemeProvider theme={tableTheme}>
              <Box sx={{ ...style, width: '35%', height: '60%', overflow: 'auto' }}>
                <button
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  className='btn btn-outline-dark btn-sm'
                  onClick={handleClose}
                >
                  <i className='fa fa-x'></i>
                </button>
                <ModalMesa code={dataSelected} sucursal={sucursal} dataReturn={setDataReturn} />
              </Box>
            </ThemeProvider>
          </Modal>
        </div>
      </div>
    </>
  );
}

const ModalMesa = ({ code, sucursal, dataReturn }) => {
  const [finalForm, setFinalForm] = useState({
    FormPrincipal: code ? code.FormPrincipal : [],
    FormDetalles: code ? code.FormDetalles : [],
    FormProductos: code ? code.FormProductos : [],
  });
  const [openFirstModal, setopenFirstModal] = useState(false);
  const [whichComponent, setWhichComponent] = useState('');
  const handleButtonClick = () => {
    if (!finalForm.FormPrincipal || !finalForm.FormPrincipal.descripcion || finalForm.FormPrincipal.descripcion.trim() === "") {
      return notify_warning('Descripción no puede estar vacio ');
    }
    Swal.fire({
      title: 'CONFIRMACION',
      text: '¿Desea confirmar esta mesa?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      customClass: {
        container: 'my-swal'
      }
    }).then(async (result) => {
      let urlPost = `/saveMesa?codigo=${finalForm.FormPrincipal.codigo}&vcodEmpresa=${cook_idEmp}&vcodSucursal=${sucursal}`;
      let body = finalForm;
      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
      if (result.isConfirmed) {
        const response = await fetch(urlPost, opPe);
        await response.json();
        notify_valido("Guardado");
        dataReturn(true);
      }
    });
  }

  const handleOpen = () => {
    setopenFirstModal(true);
  }

  const handleClose = () => {
    setopenFirstModal(false);
  }

  const settingDialog = (dialog) => {
    setWhichComponent(dialog);
    handleOpen();
  }

  const setDataTableDetails = (id, dataTable) => {
    setFinalForm({ ...finalForm, [id]: dataTable });
    handleClose();
  }

  const setDataFromPrincipal = (fieldName) => (event) => {
    let value = event.target.value;
    setFinalForm(
      {
        ...finalForm, FormPrincipal: { ...finalForm.FormPrincipal, [fieldName]: value }
      }
    );
  }

  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex">
          <label className="col-form-label m-0 p-0">
            <span className="small fs-6">
              Datos generales:
            </span>
          </label>
        </div>
        <div className="d-flex flex-column mt-2">
          <div className="d-flex flex-column form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Codigo:
              </span>
            </label>
            <input type="number" className='form-control form-control-sm' autoComplete='off' value={finalForm.FormPrincipal?.codigo} onChange={setDataFromPrincipal('codigo')} disabled />
          </div>
          <div className="d-flex flex-column form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Descripcion:
              </span>
            </label>
            <textarea rows={2} type="number" className='form-control form-control-sm' autoComplete='off' style={{ "resize": "none" }} value={finalForm.FormPrincipal?.descripcion} onChange={setDataFromPrincipal('descripcion')} />
          </div>
        </div>
        <div className="d-flex mt-3">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item m-1" role="presentation">
              <button className="nav-link rounded-pill btn-danger btn btn-sm btn-icon me-4" id="pills-detail-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => settingDialog('detalle')}>Detalle</button>
            </li>
            <li className="nav-item m-1" role="presentation">
              <button className="nav-link rounded-pill btn-danger btn btn-sm btn-icon me-4" id="pills-products-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={() => settingDialog('productos')}>Productos</button>
            </li>
          </ul>
        </div>
        <div className='d-flex justify-content-end'>
          <button type="button" className="btn btn-label-success btn-sm" id="btn_modal_guardar" onClick={(e) => handleButtonClick(e)} >
            <i className='bx bx-save' id="icon_btn_modal_guardar" ></i>
            &nbsp;Enviar
          </button>
        </div>
        <Modal
          open={openFirstModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: '55%', height: '70%', overflow: 'auto' }}>
            <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
              className='btn btn-outline-dark btn-sm'
              onClick={handleClose}
            >
              <i className='fa fa-x'></i>
            </button>
            {
              whichComponent === 'detalle' ? <TableDetails dataTable={finalForm.FormDetalles} dataReturn={setDataTableDetails} /> : ''
            }
            {
              whichComponent === 'productos' ? <TableProducts
                dataTable={finalForm.FormProductos} dataReturn={setDataTableDetails} /> : ''
            }
          </Box>
        </Modal>
      </div>
    </>
  );
}

export const TableDetails = ({ dataTable, dataReturn }) => {
  const [lst, setArrDatosInsert] = useState(dataTable ? dataTable : []);
  const [openFirstModal, setOpenFirstModal] = useState(false);
  const handleOpen = (e) => {
    setOpenFirstModal(true);
  };

  const handleClose = () => {
    setOpenFirstModal(false);
  };


  const handleButtonClick = () => {
    dataReturn('FormDetalles', lst);
  };

  const onClickDelete = (code) => {
    const newDataArray = [...lst];
    const index = newDataArray.findIndex((response) => response.code === code);
    newDataArray.splice(index, 1);
    setArrDatosInsert(newDataArray);
  }

  const TRPlantilllaCD = (dataRow) => {
    return (
      <tr key={dataRow.dataRow.code}>
        <td className='text-center'>
          <AButton tp_tilde="Eliminar" tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} bt_funct={() => onClickDelete(dataRow.dataRow.code)} />
        </td>
        <td className='text-center'>{dataRow.dataRow.code}</td>
        <td className='text-center'>{dataRow.dataRow.name}</td>
      </tr>
    )
  }

  const onClickInsert = async () => {
    handleOpen();
  }
  const setInBussinessUnit = (event) => {
    const dataSetIn = [...lst];
    for (const iterator of event) {
      if (dataSetIn.findIndex((response) => response.code === iterator.code) === -1) {
        dataSetIn.push({ code: iterator.code, name: iterator.name });
      }
    }
    setArrDatosInsert(dataSetIn);
    handleClose();
  };
  const globalTheme = window.templateCustomizer.settings.style;

  return (
    <>

      <div className="d-flex flex-column">
        <div className="d-flex">
          <label className="col-form-label m-0 p-0">
            <span className="small fs-6">
              Unidades de negocio:
            </span>
          </label>
          <button
            className="btn btn-sm col-2 btn-danger rounded-pill me-4 p-0 m-0 active mx-3"
            onClick={handleButtonClick}
          >
            Guardar datos
          </button>
        </div>
        <div className="d-flex mt-2" style={{ flexDirection: 'column', height: '350px', overflow: 'auto' }}>
          <table className="table" style={{ fontSize: '10px', zIndex: '2' }}>
            <thead style={{
              position: 'sticky', top: 0, zIndex: 10, color: 'black', backgroundColor: globalTheme === 'light' ? '#fffff' : '#2B2C40'
            }}>
              <tr>
                <th>Actions</th>
                <th className='text-center'>Codigo</th>
                <th className='text-center'>Descripcion</th>
              </tr>
            </thead>
          </table>
          <div style={{ flex: '1', overflowY: 'auto' }}>
            <table className="table" style={{ fontSize: '10px', zIndex: '2', }}>
              <tbody>
                {
                  lst.length === 0 ? (
                    <tr>
                      <td colSpan="4">No hay datos</td>
                    </tr>
                  ) : (
                    lst.map((row, index) => (
                      <TRPlantilllaCD key={index} dataRow={row} />
                    ))
                  )
                }
              </tbody>
            </table>
          </div>
          <tr key={"no_index"}>
            <td className='td3'></td>
            <td className='td5 d-flex justify-content-center'>
              <AButton tp_tilde="Agregar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success "
                bt_funct={onClickInsert}
                ic_class="bx bx-ms bx-plus" vl_tpbtn={1} />
            </td>
          </tr>
        </div>
      </div>
      <Modal
        open={openFirstModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '75%', height: '80%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={handleClose}
          >
            <i className='fa fa-x'></i>
          </button>
          <AddBusinessUnit returnBusinessUnit={setInBussinessUnit} />
        </Box>
      </Modal>
    </>
  );
}

export const AddBusinessUnit = ({ returnBusinessUnit }) => {
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState([]);

  const get_unidades_negocio = async () => {
    const url_con = `/unidadesDeNegocio?vcodEmpresa=${cook_idEmp}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setData(data)
  }

  useEffect(() => {
    if (data.length === 0) {
      get_unidades_negocio();
    }
  }, [data.length]);

  const onClickSaveData = () => {
    returnBusinessUnit(rowSelection);
  }

  const setData1 = (data) => {
    setRowSelection(data);
  }

  return (
    <>
      <div className="d-flex mt-5 justify-content-start">
        <label className="col-form-label m-0 p-0">
          <span className="small fs-6">
            Lista de Unidades de Negocio
          </span>
        </label>
        <button
          className="btn btn-sm col-2 btn-danger rounded-pill me-4 p-0 m-0 active mx-4 my-2"
          onClick={(e) => onClickSaveData(e)}
        >
          Guardar datos
        </button>
      </div>
      <MultiSelectTable data={data} returnFinalSelection={setData1} />
    </>
  );
}

export const TableProducts = ({ dataTable, dataReturn }) => {
  const [lst, setArrDatosInsert] = useState(dataTable ? dataTable : []);
  const [openFirstModal, setOpenFirstModal] = useState(false);
  const handleOpen = (e) => {
    setOpenFirstModal(true);
  };

  const handleClose = () => {
    setOpenFirstModal(false);
  };


  const handleButtonClick = () => {
    dataReturn('FormProductos', lst);
  };

  const onClickDelete = (code) => {
    const newDataArray = [...lst];
    const index = newDataArray.findIndex((response) => response.Code === code);
    newDataArray.splice(index, 1);
    setArrDatosInsert(newDataArray);
  }

  const TRPlantilllaCD = (dataRow) => {
    return (
      <tr key={dataRow.dataRow.code}>
        <td className='text-center' >
          <AButton tp_tilde="Eliminar" tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} bt_funct={() => onClickDelete(dataRow.dataRow.Code)} />
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >{dataRow.dataRow.code}</td>
        <td>{dataRow.dataRow.name}</td>
      </tr>
    )
  }

  const onClickInsert = async () => {
    handleOpen();
  }
  const setInBussinessUnit = (event) => {
    const dataSetIn = [...lst];
    for (const iterator of event) {
      if (dataSetIn.findIndex((response) => response.code === iterator.code) === -1) {
        dataSetIn.push({ code: iterator.code, name: iterator.name });
      }
    }
    setArrDatosInsert(dataSetIn);
    handleClose();
  };
  const globalTheme = window.templateCustomizer.settings.style;


  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex">
          <label className="col-form-label m-0 p-0">
            <span className="small fs-6">
              Productos:
            </span>
          </label>
          <button
            className="btn btn-sm col-2 btn-danger rounded-pill me-4 p-0 m-0 active mx-3"
            onClick={handleButtonClick}
          >
            Guardar datos
          </button>
        </div>
        <div className="d-flex mt-2" style={{ flexDirection: 'column', height: '350px', overflow: 'auto' }}>
          <table className="table" style={{ fontSize: '10px', zIndex: '2' }}>
            <thead style={{
              position: 'sticky', top: 0, zIndex: 10, color: 'black', backgroundColor: globalTheme === 'light' ? '#fffff' : '#2B2C40'
            }}>
              <tr>
                <th>Actions</th>
                <th style={{ width: '350px' }}>Codigo</th>
                <th style={{ width: '450px' }}>Descripcion</th>
              </tr>
            </thead>
          </table>
          <div style={{ flex: '1', overflowY: 'auto' }}>
            <table className="table" style={{ fontSize: '10px', zIndex: '2', }}>
              <tbody>
                {
                  lst.length === 0 ? (
                    <tr>
                      <td colSpan="4">No hay datos</td>
                    </tr>
                  ) : (
                    lst.map((row, index) => (
                      <TRPlantilllaCD key={index} dataRow={row} />
                    ))
                  )
                }
              </tbody>
            </table>
          </div>
          <tr key={"no_index"}>
            <td className='td3'></td>
            <td className='td5 d-flex justify-content-center'>
              <AButton tp_tilde="Agregar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success "
                bt_funct={onClickInsert}
                ic_class="bx bx-ms bx-plus" vl_tpbtn={1} />
            </td>
          </tr>
        </div>
      </div >
      <Modal
        open={openFirstModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '75%', height: '80%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={handleClose}
          >
            <i className='fa fa-x'></i>
          </button>
          <AddProducts returnBusinessUnit={setInBussinessUnit} />
        </Box>
      </Modal>
    </>
  );
}

export const AddProducts = ({ returnBusinessUnit }) => {
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      get_products();
      return;
    }
  }, [data.length]);

  const get_products = async () => {
    const url_con = `/getarticulos?vcodEmpresa=${cook_idEmp}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setData(data)
  }

  const onClickSaveData = () => {
    returnBusinessUnit(rowSelection);
  };

  const setData1 = (data) => {
    setRowSelection(data);
  }

  return (
    <>
      <div className="d-flex mt-5 justify-content-start">
        <label className="col-form-label m-0 p-0">
          <span className="small fs-6">
            Productos
          </span>
        </label>
        <button
          className="btn btn-sm col-2 btn-danger rounded-pill me-4 p-0 m-0 active mx-4 my-2"
          onClick={(e) => onClickSaveData(e)}
        >
          Guardar datos
        </button>
      </div>
      <MultiSelectTable data={data} returnFinalSelection={setData1} type={'Productos'} />
    </>
  );
}

const MultiSelectTable = ({ data, returnFinalSelection, type }) => {
  const [startRowIndex, setStartRowIndex] = useState(null);
  const [endRowIndex, setEndRowIndex] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const [returnData, setDataForReturn] = useState([]);

  useEffect(() => {
    returnFinalSelection(returnData);
  }, [returnData, returnFinalSelection]);


  const dataLikeProducts = useCallback(async () => {
    const url = '/apiArticulos?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + searchTerm;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    setFilteredData(data);
    return data;
  }, [searchTerm]);

  useEffect(() => {
    if (type === "Productos" && searchTerm.trim() !== "") {
      dataLikeProducts();
      return;
    }
    const filtered = data.filter((row) => {
      const values = Object.values(row).map(String);
      return values.some((value) => value.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    );
    setFilteredData(filtered);
  }, [data, dataLikeProducts, searchTerm, type]);

  const handleMouseDown = (index) => {
    setStartRowIndex(index);
    setEndRowIndex(index);
  };

  const handleMouseEnter = (index) => {
    if (startRowIndex !== null) {
      setEndRowIndex(index);
    }
  };

  const handleMouseUp = () => {
    const start = Math.min(startRowIndex, endRowIndex);
    const end = Math.max(startRowIndex, endRowIndex);
    const selected = filteredData.slice(start, end + 1).map((row) => row.Code);
    const selectedDataForReturn = filteredData.slice(start, end + 1).map((row) => { return { code: row.Code, name: row.Name } });
    const newSelection = new Set([...selectedRows, ...selected]);
    const newSelectionFullJson = new Set([...returnData, ...selectedDataForReturn]);
    if (selectedRows.includes(selected[0])) {
      newSelection.delete(selected[0]);
      newSelectionFullJson.delete(selectedDataForReturn[0]);
    }

    setSelectedRows(Array.from(newSelection));
    setDataForReturn(Array.from(newSelectionFullJson))
  };
  const globalTheme = window.templateCustomizer.settings.style;

  return (
    <div style={{ userSelect: 'none' }}>
      <div className="d-flex justify-content-end">
        <input
          type="text"
          placeholder="Buscar..."
          value={searchTerm}
          className="form-control form-control-sm col-4"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <table className="table" style={{ fontSize: '10px', zIndex: '2' }}>
          <thead
            className="border-bottom"
            style={{
              position: 'sticky', top: 0, zIndex: 10, color: 'black', backgroundColor: globalTheme === 'light' ? '#fffff' : '#2B2C40',
            }}
          >
            <tr>
              <th scope="col">Codigo</th>
              <th scope="col">Nombre</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr
                key={row.Code}
                onMouseDown={() => handleMouseDown(index)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseUp={handleMouseUp}
                style={{
                  background: selectedRows.includes(row.Code) ? 'lightblue' : globalTheme === 'light' ? 'rgb(254,255,244)' : '#2B2C40',
                  cursor: 'pointer',
                }}
              >
                <td>{row.Code}</td>
                <td>{row.Name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  );
};

export default COMMesa;
