import React, { useState, useMemo, useEffect } from 'react'
import { SelectSucursal } from '../../../components/Selects/SelectsPrincipales'
import { ToastContainer } from 'react-toastify';
import { notify_valido, notify_warning, notify_error } from '../../../components/utils';
import { AButton } from '../../../components/Button';
import { Box } from '@mui/material';
import { Link, NavLink, } from "react-router-dom";
import { Tooltip } from '@mui/material';
import $ from 'jquery'
import { createTheme, ThemeProvider } from '@mui/material';

import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MaterialReactTable } from 'material-react-table';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const globalTheme = window.templateCustomizer.settings.style;

const DESConDesc = () => {
  const [disabledb1, setdisabledb1] = useState(false);
  const [disabledb2, setdisabledb2] = useState(false);
  const [disabledb3, setdisabledb3] = useState(false);
  const [pending, setpending] = useState(false);
  const [btnVisible2, setBtnVisible2] = useState(true);
  const [lst, setlst] = useState([]);
  const [sl_t_suc, setSl_t_suc] = useState('');
  const [filterActivo, setFilterActivo] = useState('');

  function savePathInCookie() {
    // Obtiene la parte después de la barra diagonal
    const path = window.location.pathname.substr(1);
    // Guarda el valor en una cookie con nombre "ultimapag"
    document.cookie = `ultimapag=${path}`;
  }

  const changeSl_t_suc = (event) => { setSl_t_suc(event.target.value); }


  const lsttipoudo = useMemo(() => [
    'Desc.UnoAUno',
    'Desc.TicketCompraEscala',
    'Desc.ComboEscala',
    'Desc.TicketCompra',
    'Desc.AgrupacionEscala',
    'Desc.AgrupacionReglas',
  ], []);

  const columns = useMemo(() => [
    {
      header: 'TipoUdo', accessorKey: 'nomTipos',
      filterVariant: 'select',
      filterSelectOptions: lsttipoudo,
    },
    { header: 'CodeUdo', accessorKey: 'Code' },
    { header: 'Descripción', accessorKey: 'U_AB_DUCD' },
    {
      header: 'Activo', accessorKey: 'U_AB_DUCA',
      // muiTableBodyCellProps: ({cell}) => ({ 
      //   sx: { 
      //     backgroundColor:  '#91F77A',
      //     display: 'flex',
      //     justifyContent: 'center'
      //   } 
      // }),
      // Cell: ({ row }) => (
      //   <div className="align-items-center">
      //     <div className="form-check form-check-danger">
      //       <input className="form-check-input" type="checkbox" value='Y' onChange={(e) => handleInputCheckChange(e,row)} checked={(row.original.U_AB_DUCA==='Y')?'checked':''}  />
      //     </div>
      //   </div>
      // ),
      filterVariant: 'select',
      width: "10rem",
      Filter: ({ column }) => (
        <select className="form-select form-select-sm" onChange={(e) => setFilterActivo(e.target.value)} >
          <option value="">Todos</option>
          <option value="Y">Activo</option>
          <option value="N">Inactivo</option>
        </select>
      ),
      Cell: ({ row }) => (
        <div style={{ "color": row.original?.U_AB_DUCA === 'Y' ? 'green' : row.original?.U_AB_DUCA === 'N' ? 'red' : '' }} className="align-items-center">{(row.original?.U_AB_DUCA === 'Y') ? 'Activo' : row.original?.U_AB_DUCA === 'N' ? 'Inactivo' : ''}</div>
      ),

    },
    { header: 'FechaInicio', accessorKey: 'U_AB_DUCI' },
    { header: 'FechaFin ', accessorKey: 'U_AB_DUCF' },
    { header: 'Sucursal', accessorKey: 'Sucursal' },
    { header: 'Unidad', accessorKey: 'Unidad' },
  ], [lst]);

  const filteredData = filterActivo ? lst.filter(item => {
    if (filterActivo === "Y") {
      return item.U_AB_DUCA === filterActivo;
    } else if (filterActivo === "N") {
      return item.U_AB_DUCA === filterActivo;
    }
  }) : lst;

  const onClickListar = async () => {
    try {

      const url = '/apiListaDescG?vcodEmpresa=' + cook_idEmp+ '&vcodSucursal='+sl_t_suc;
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }

      setpending(true)
      setdisabledb1(true)
      const response = await fetch(url, opPe);
      const data = await response.json();
      //console.log(data);
      setlst(data)
      setdisabledb1(false)
      setpending(false)

    } catch (error) {
      setdisabledb1(false)
      setpending(false)
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }
  const onClickCopy = async () => {
    try {
      //alert("hola boton copy")
      console.log("click lista ", lst);
    } catch (error) {
      setdisabledb1(false)
      setpending(false)
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }
  // const handleInputCheckChange = (e,row) => {

  //   let nuevosDatos = [...lst];
  //   const currentValue = row.original.U_AB_DUCA;
  //   const newValue = currentValue === 'Y' ? 'N' : 'Y';
  //   nuevosDatos[row.index]['U_AB_DUCA'] = newValue;
  //   setlst(nuevosDatos)

  // }



  useEffect(() => {
    savePathInCookie();
  }, []);
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [globalTheme],
  );

  const [rowSelection, setRowSelection] = useState([]);
  const [botonVisible, setBotonVisible] = useState(false);

  const onClickActualizarMasivo = (table) => {

    // console.log("rowSelection", rowSelection);
    const selectedRFile = [];
    const selectedRFileCabe = [];
    var validarActIna = "A";
    const selectedRows = table.getSelectedRowModel().rows;
    selectedRows.map((row) => { return selectedRFile.push(row.original) })

    let countAct = 0;
    let countIna = 0;
    for (let i = 0; i < selectedRFile.length; i++) {
      if (selectedRFile[i].U_AB_DUCA === "Y") {
        countAct++;
      }
    }
    for (let i = 0; i < selectedRFile.length; i++) {
      if (selectedRFile[i].U_AB_DUCA === "N") {
        countIna++;
      }
    }

    if (countIna > countAct) {
      validarActIna = "I"
    }
    selectedRFile.map((row) => { return selectedRFileCabe.push({"Code":row.Code, "Tipo":row.Tipo, "Estado":(validarActIna === "I") ? "Y" : "N" }) })
    // console.log("countAct", countAct);
    // console.log("countIna", countIna);


    // console.log("arrCodig", selectedRFileCabe);
    if (selectedRows.length) {
      Swal.fire({
        title: 'ADVERTENCIA',
        text: '¿Desea confirmar la actualización?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: (validarActIna === "I") ? "ACTIVAR" : "INACTIVAR",
        customClass: {
          container: 'my-swal'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          var urlUpdate = '/apiActAI';
          const Body = { vcodEmpresa: cook_idEmp, vdtarray: selectedRFileCabe };
          const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
          const resp = await fetch(urlUpdate, opPe);//cambiar por proveedores
          const data = await resp.json();
          if (data.vl_rsp === 1) {
            notify_valido("Actualización exitosa")
            onClickListar()
          } else {
            notify_warning("¡Error al actualizar, intente mas tarde.!")
          }
          Swal.close()

          setRowSelection({});

        }
      })
    } else {
      notify_warning("Selecione uno o todas las filas que quiera asignar nuevos margenes")
    }
  }

  const onClickLimpiar = async (row) => {
    try {
      setlst([])
      setFilterActivo('')
      setRowSelection([])
    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }
  const onClickValidarEstado = (valor) => {
    cookies.set('boniValidarEstado', valor, { path: '/' });
  }

  return (
    <div className="col-xl-12">
      {/* <div>DESConDesc</div> */}
      <div className="card card-action">
        {/* <div className="card-header ">
                <div className="card-action-title">Lista </div>
            </div> */}
        <div className="card-body ">
          <div className="row">
            <div className="col-lg-2">Sucursal:</div>
            <div className="col-lg-2">
              <SelectSucursal id="veh_slc_suc" value={sl_t_suc} onChange={changeSl_t_suc} />
            </div>
            <div className="col-lg-4 text-center ">
              <AButton tp_tilde="Consultar información" tp_posit="top" bt_class="btn btn-label-youtube btn-sm" bt_funct={onClickListar} disabled={disabledb1} ic_class="bx bx-cloud-download bx-ms" vl_tpbtn={2} />&nbsp;
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
          <ThemeProvider theme={tableTheme}>
            <div className="card-datatable table-responsive">
              <MaterialReactTable
                columns={columns}
                data={filteredData}
                state={{ rowSelection, isLoading: pending }}
                initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
                enableGrouping
                enableRowSelection
                //getRowId={(row) => row.Code}
                positionToolbarAlertBanner="bottom"
                onRowSelectionChange={setRowSelection}
                enableStickyHeader enableColumnResizing
                muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                localization={MRT_Localization_ES}
                enablePagination={true}
                muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                enableGlobalFilterModes
                rowVirtualizerProps={{ overscan: 150 }}
                enableRowVirtualization

                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    header: 'Options', //change header text
                    size: 120, //make actions column wider
                  },
                }}
                enableRowActions
                renderRowActions={({ row }) => (
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                    <Tooltip title="Ir a Visualizar" placement="left">
                     <span>
                      <Link to={'/bondescuentos?tip=ver&tab=' + row.original.nomCampo + '&cod=' + row.original.Code} onClick={ () => onClickValidarEstado('ver')} target="_blank" className="btn btn-sm btn-icon btn-label-success" ><i className="bx bx-ms bx-show"></i></Link>
                     </span> 
                    </Tooltip>
                    {
                      (row.original.U_AB_DUCA === 'N' ) && (
                        <Tooltip title="Ir a Editar" placement="left">
                          <Link to={'/bondescuentos?tip=edit&tab=' + row.original.nomCampo + '&cod=' + row.original.Code} onClick={ () => onClickValidarEstado('edit')} target="_blank" className="btn btn-sm btn-icon btn-label-info" ><i className="bx bx-ms bx-edit-alt"></i></Link>
                        </Tooltip>
                      )
                    }
                    <Tooltip title="Copiar" placement="left">
                      <Link to={'/bondescuentos?tip=copy&tab=' + row.original.nomCampo + '&cod=' + row.original.Code} onClick={ () => onClickValidarEstado('copy')} target="_blank" className="btn btn-sm btn-icon btn-label-warning" ><i className="bx bx-copy bx-ms"></i></Link>
                    </Tooltip>
                    {/* <AButton tp_tilde="Copy" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success" bt_funct={onClickCopy} ic_class="bx bx-copy bx-ms" vl_tpbtn={1} /> */}
                  </Box>
                )}

                renderTopToolbarCustomActions={({ table, row }) => (
                  <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                    <button className="btn btn-sm btn-success" variant="contained"

                      onClick={() => { onClickActualizarMasivo(table) }}

                    >
                      <i className='bx bx-ms bx-sync'></i>Acti-Inac
                    </button>

                  </Box>
                )}
                muiCircularProgressProps={{ color: 'error' }}
                muiSkeletonProps={{
                  animation: 'pulse',
                  height: 28,
                }}
              />
            </div>
            <ToastContainer />
          </ThemeProvider>
        </div>
      </div>

    </div>
  )
}

export default DESConDesc