
import React, {useState, useMemo, useRef, useCallback, useEffect} from 'react'
import Cookies from 'universal-cookie';
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales'
import {notify_warning, notify_valido, selectTabla, selectTablaMultiple,removeSelectedRows} from '../../components/utils';
import $ from 'jquery'
//import '../../App.css';
import './comisiones.css'
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { AButton } from '../../components/Button';
import {Box, Modal, Tooltip}from '@mui/material';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import AsyncSelect from 'react-select/async';
const moment = require('moment')
const globalTheme = window.templateCustomizer.settings.style;
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');

const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};
export const DFVolumen = (prosp) => {

  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const [arFileUltim, setArFileUltim] = useState({
    txtDet1: '0.00',
    txtDet2: '0.00',
  })
  const selectRowRef = useRef(null);

  const funDArticulos = useCallback( async (inputValue) => {
    const url ='/apiAgruUniNegocios';
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[])
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption);  };
  const handleFieldTabChange = (fieldName) => (event) => {
    const value = event.target.value;
    setArFileUltim({
      ...arFileUltim,
      [fieldName]: value,
    });
  };
  const onClickInsert =  async()=>{

    //if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {
    if (arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code )
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem===undefined || lastItem==="" ) ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt:slcArticulo.Code, txtDet1:arFileUltim.txtDet1, txtDet2:arFileUltim.txtDet2 };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert,newItem]);
        onDataUpdate('arrVolume',[...arrDatosInsert,newItem])
        setFilaActiva(newId);
        setSlcArticulo([]);
        setArFileUltim({
          txtDet1: '0.00',
          txtDet2: '0.00',
        })
        setTimeout(() => { selectRowRef.current.focus() }, 500);
      } else {
        notify_warning('Datos selecionados ya exiten.');
      }
    } else {
      //notify_warning('Campos vacios.');
      //  if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon==="") {
        notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
      }else{
        //if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim()===""){ notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code){ notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
        
      }
    }

  }
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    //console.log(arrDatosInsert);
    onDataUpdate('arrVolume',myList)
    setSlcArticulo([])
    
  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          { 
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={()=> onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger vervalidar" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }
        </td> 
        <td style={{ position: 'relative', zIndex: '2',maxWidth:'50px' }} >{prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.txtDet1}</td>
        <td>{prosp.datoRow.txtDet2}</td>
        <td>
          {/* <AButton tp_tilde="Ver Unidades" tp_posit="right" bt_class="btn btn-sm btn-icon btn-label-warning"  bt_funct={()=> handleOpenGeneralUni2(prosp.datoRow.CodArt) }  ic_class="bx bx-ms bx-cube" vl_tpbtn={1} /> */}
        </td>
      </tr>
    )
  }
  useEffect (() => {
    const lastItem = listInitData[listInitData?.length - 1];
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  },[listInitData])
  return (
    <>
      <div className="data-tables table-responsive text-nowrap "  style={{position: 'relative', zIndex: '1', width:'auto', height:'350px', overflowY: 'scroll', overflowX: 'scroll'}}>
          <table className="table table-sm" id='tab_BCECliente' style={{fontSize:'10px', zIndex: '2',}} >
            <thead style={{ position: 'sticky', top: 0, zIndex: 10}}>
              <tr >
                <th>Actions</th>
                <th style={{width:'350px'}}>Unidad Negocio</th>
                <th style={{width:'250px'}}>90%</th>
                <th style={{width:'250px'}}>100%</th>
                <th>Op</th>
              </tr>
            </thead>
            <tbody >
              {
                
                (arrDatosInsert?.length) ? arrDatosInsert?.map((row) => (
                  <React.Fragment key={row.idArt}>
                    <TRPlantilllaCD key={row.idArt} datoRow={row} />
                    {
                    
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt} className="vervalidar">
                        <td className='td1'></td> 
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                          <AsyncSelect 
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className=" from-control form-control-sm"  
                            value={slcArticulo}
                            cacheOptions
                            defaultOptions
                            getOptionLabel={e => e.Code +' - '+ e.Name}
                            getOptionValue={e => e.Code }
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        
                        </td>
                        <td className='td3'>
                          <input type="number" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off'  value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')}/>
                        </td>
                        <td className='td4'>
                          <input type="number" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off'  value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')}/>
                        </td>
                        <td className='td5'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success "  bt_funct={onClickInsert}  ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          {/* <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info"  bt_funct={handleOpenGeneral}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} /> */}
                          {/* <AButton tp_tilde="Ver Unidades" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-warning"  bt_funct={handleOpenGeneralUni}  ic_class="bx bx-ms bx-cube" vl_tpbtn={1} /> */}
                        </td>
                      </tr>
                    )
                    }
                  </React.Fragment>
                )) 
                : 
                
                <tr key={prosp?.datoRow?.idArt} className="vervalidar">
                  <td className='td1'></td> 
                  <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                    <AsyncSelect
                      styles={styles}
                      placeholder="Busque por Cod./Desc."
                      name="form-field-name"
                      className=" from-control form-control-sm"  
                      value={slcArticulo}
                      cacheOptions
                      defaultOptions
                      getOptionLabel={e => e.Code +' - '+ e.Name}
                      getOptionValue={e => e.Code }
                      loadOptions={funDArticulos}
                      onChange={handleSelectChange}
                      maxMenuHeight={150}
                    />
                  
                  </td>
                  <td className='td3'>
                    <input type="number" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off'  value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')}/>
                  </td>
                  <td className='td4'>
                    <input type="number" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off'  value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')}/>
                  </td>
                  <td className='td5'>
                    <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success "  bt_funct={onClickInsert}  ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                    {/* <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info"  bt_funct={handleOpenGeneral}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} /> */}
                    {/* <AButton tp_tilde="Ver Unidades" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-warning"  bt_funct={handleOpenGeneralUni}  ic_class="bx bx-ms bx-cube" vl_tpbtn={1} /> */}
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
    </>
  )
}
export const DFCobertura = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  //const [slcArticulo, setSlcArticulo] = useState([""]);
  const [arFileUltim, setArFileUltim] = useState({
    txtDet1: '0.00',
    txtDet2: '0.00',
  })
  const selectRowRef = useRef(null);

  const handleFieldTabChange = (fieldName) => (event) => {
    const value = event.target.value;
    setArFileUltim({
      ...arFileUltim,
      [fieldName]: value,
    });
  };
  const onClickInsert =  async()=>{

    if (arrCampDatos.txtDBon !=="" ) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.txtDet1 === arFileUltim.txtDet1 && varr.txtDet2 === arFileUltim.txtDet2 )
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem===undefined || lastItem==="" ) ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, txtDet1:arFileUltim.txtDet1, txtDet2:arFileUltim.txtDet2 };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert,newItem]);
        onDataUpdate('arrCobert',[...arrDatosInsert,newItem])
        setFilaActiva(newId);
        //setSlcArticulo([]);
        setArFileUltim({
          txtDet1: '0.00',
          txtDet2: '0.00',
        })
        //setTimeout(() => { selectRowRef.current.focus() }, 500);
      } else { notify_warning('Datos ya exiten.'); }
    } else {
      if (arrCampDatos.txtDBon==="") {
        notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
      }else{
        if (arrCampDatos.txtDBon.trim()===""){ notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }

        
      }
    }

  }
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    //console.log(arrDatosInsert);
    onDataUpdate('arrCobert',myList)
    //setSlcArticulo([])
    
  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          { 
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={()=> onClickDeletefila(prosp.datoRow.idArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger vervalidar" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }
        </td> 
        <td>{prosp.datoRow.txtDet1}</td>
        <td>{prosp.datoRow.txtDet2}</td>
        <td></td>
      </tr>
    )
  }
  useEffect (() => {
    const lastItem = listInitData[listInitData?.length - 1];
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  },[listInitData])
  return (
    <>
      <div className="data-tables table-responsive text-nowrap "  style={{position: 'relative', zIndex: '1', width:'auto', height:'350px', overflowY: 'scroll', overflowX: 'scroll'}}>
          <table className="table table-sm"  style={{fontSize:'10px', zIndex: '2',}} >
            <thead style={{ position: 'sticky', top: 0, zIndex: 10}}>
              <tr >
                <th>Actions</th>
                <th style={{width:'250px'}}>90%</th>
                <th style={{width:'250px'}}>100%</th>
                <th>Op</th>
              </tr>
            </thead>
            <tbody >
              {
                
                (arrDatosInsert?.length) ? arrDatosInsert?.map((row) => (
                  <React.Fragment key={row.idArt}>
                    <TRPlantilllaCD key={row.idArt} datoRow={row} />
                    {
                    
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt} className="vervalidar">
                        <td className='td1'></td> 
                        <td className='td3'>
                          <input type="number" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off'  value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')}/>
                        </td>
                        <td className='td4'>
                          <input type="number" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off'  value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')}/>
                        </td>
                        <td className='td5'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success "  bt_funct={onClickInsert}  ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          {/* <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info"  bt_funct={handleOpenGeneral}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} /> */}
                          {/* <AButton tp_tilde="Ver Unidades" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-warning"  bt_funct={handleOpenGeneralUni}  ic_class="bx bx-ms bx-cube" vl_tpbtn={1} /> */}
                        </td>
                      </tr>
                    )
                    }
                  </React.Fragment>
                )) 
                : 
                
                <tr key={prosp?.datoRow?.idArt} className="vervalidar">
                  <td className='td1'></td> 
                  <td className='td3'>
                    <input type="number" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off'  value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')}/>
                  </td>
                  <td className='td4'>
                    <input type="number" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off'  value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')}/>
                  </td>
                  <td className='td5'>
                    <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success "  bt_funct={onClickInsert}  ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
    </>
  )
}
export const DFNroPedidos = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  //const [slcArticulo, setSlcArticulo] = useState([""]);
  const [arFileUltim, setArFileUltim] = useState({
    txtDet1: '0.00',
    txtDet2: '0.00',
  })
  const selectRowRef = useRef(null);

  const handleFieldTabChange = (fieldName) => (event) => {
    const value = event.target.value;
    setArFileUltim({
      ...arFileUltim,
      [fieldName]: value,
    });
  };
  const onClickInsert =  async()=>{

    if (arrCampDatos.txtDBon !=="" ) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.txtDet1 === arFileUltim.txtDet1 && varr.txtDet2 === arFileUltim.txtDet2 )
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem===undefined || lastItem==="" ) ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, txtDet1:arFileUltim.txtDet1, txtDet2:arFileUltim.txtDet2 };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert,newItem]);
        onDataUpdate('arrNPedid',[...arrDatosInsert,newItem])
        setFilaActiva(newId);
        //setSlcArticulo([]);
        setArFileUltim({
          txtDet1: '0.00',
          txtDet2: '0.00',
        })
        //setTimeout(() => { selectRowRef.current.focus() }, 500);
      } else { notify_warning('Datos ya exiten.'); }
    } else {
      if (arrCampDatos.txtDBon==="") {
        notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
      }else{
        if (arrCampDatos.txtDBon.trim()===""){ notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }

        
      }
    }

  }
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    //console.log(arrDatosInsert);
    onDataUpdate('arrNPedid',myList)
    //setSlcArticulo([])
    
  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          { 
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={()=> onClickDeletefila(prosp.datoRow.idArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger vervalidar" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }
        </td> 
        <td>{prosp.datoRow.txtDet1}</td>
        <td>{prosp.datoRow.txtDet2}</td>
        <td></td>
      </tr>
    )
  }
  useEffect (() => {
    const lastItem = listInitData[listInitData?.length - 1];
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  },[listInitData])
  return (
    <>
      <div className="data-tables table-responsive text-nowrap "  style={{position: 'relative', zIndex: '1', width:'auto', height:'350px', overflowY: 'scroll', overflowX: 'scroll'}}>
          <table className="table table-sm"  style={{fontSize:'10px', zIndex: '2',}} >
            <thead style={{ position: 'sticky', top: 0, zIndex: 10}}>
              <tr >
                <th>Actions</th>
                <th style={{width:'250px'}}>90%</th>
                <th style={{width:'250px'}}>100%</th>
                <th>Op</th>
              </tr>
            </thead>
            <tbody >
              {
                
                (arrDatosInsert?.length) ? arrDatosInsert?.map((row) => (
                  <React.Fragment key={row.idArt}>
                    <TRPlantilllaCD key={row.idArt} datoRow={row} />
                    {
                    
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt} className="vervalidar">
                        <td className='td1'></td> 
                        <td className='td3'>
                          <input type="number" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off'  value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')}/>
                        </td>
                        <td className='td4'>
                          <input type="number" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off'  value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')}/>
                        </td>
                        <td className='td5'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success "  bt_funct={onClickInsert}  ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          {/* <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info"  bt_funct={handleOpenGeneral}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} /> */}
                          {/* <AButton tp_tilde="Ver Unidades" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-warning"  bt_funct={handleOpenGeneralUni}  ic_class="bx bx-ms bx-cube" vl_tpbtn={1} /> */}
                        </td>
                      </tr>
                    )
                    }
                  </React.Fragment>
                )) 
                : 
                
                <tr key={prosp?.datoRow?.idArt} className="vervalidar">
                  <td className='td1'></td> 
                  <td className='td3'>
                    <input type="number" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off'  value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')}/>
                  </td>
                  <td className='td4'>
                    <input type="number" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off'  value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')}/>
                  </td>
                  <td className='td5'>
                    <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success "  bt_funct={onClickInsert}  ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
    </>
  )
}

const COMICalculo = () => {
  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [data, setdata] = useState([]);
  //const [lst,                 setlst] = useState([]);
  const [pending,             setPending] = useState(false);
  const [btnVisible,          setbtnVisible] = useState(false);
  const [emp_sl_t_suc,        emp_setSl_t_suc] = useState('');
  const [inp_text_fecha,      setInp_text_fecha] = useState(new Date());
// Estado para las filas de pedidos seleccionadas  
  /** Listar datos */
  // const onClickListar=async()=>{
  //   //setPending(true)
  //   const url_lstClientes = `/apiSubirExcelMF`;
  //   const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' } };
  //   const response = await fetch(url_lstClientes, opPe);
  //   const data = await response.json();
  //   console.log("data",data);
  //   //setPending(false)
  // };
  const onClickListar=async()=>{
    setPending(true)
    const url_lstClientes = `/apiListarFactores?vcodEmpresa=${cook_idEmp}&vperiodo=${moment(inp_text_fecha, 'Y-M-D').format('YYYY-MM')}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    //console.log("data",data);
    setdata(data)
    setPending(false)
    if (data.length) {
      setbtnVisible(true)
    }
  };


   /** Cabaecera para Vhiculos */
  const columns  = useMemo( () => [
    { header: 'Codigo',     accessorKey: 'txtCodigo'},
    { header: 'Descripción',accessorKey: 'txtNTable' },
    { header: 'Canal',      accessorKey: 'txtCCanal' },
    { header: 'Perido',     accessorKey: 'txtPeriod' },
    { header: '% comison1', accessorKey: 'txtObjet1' },
    { header: '% comison2', accessorKey: 'txtObjet2' },
    { header: 'EfReparto',  accessorKey: 'txtERepar' },
    { header: 'Linealidad', accessorKey: 'txtLineal' },
    { header: 'Cobranza',   accessorKey: 'txtCobran' },
    
  ],[])
  
 

  
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
  }

  // const fc_cargar_excel = async (event) => {
  //   setPending(true)
  //   const formData = new FormData();
  //   const archivoExcel = event.target.files[0];
  //   formData.append('xml', archivoExcel);
  //   formData.append('valor', pending);
  //   formData.append("vcodEmpresa", cook_idEmp);
  //   const options = { method: "POST", headers: {}, body: formData }
  //   const responsea = await fetch('/apiSubirExcelMF', options);
  //   const data = await responsea.json()
  //   //const cuotaVentas = data.data.jsonData
  //   const fileInput = document.getElementById('formFile');
  //   fileInput.value = '';
  //   setdata(data)
  //   // console.log(" se registro el archivos");
  //   setPending(false)
  //   //const tableData = { columns, data };
  //   //setListaDatos(data.jsonData)
  //   //setListaDatosEnvio([data.enviados]);
  //   //console.log(tableData.data);
  // }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  /** INICIO :> Datos modal - 1 */ 
  const [openModal, setopenModal] = useState(false);
  const handleOpen = async(Code) => {
    //console.log("recojo de datos", Code);
    setopenModal(true); 
    handleReciviendoDatos(Code)
  }
  const handleRowClick = async(Code) => {
    setopenModal(false); 
    setTimeout(() => {
      onClickLimpiar()
    }, 500);
  }
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiCanal?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue; // cambiar la api 
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [cook_idEmp])
 

  const datosCabecera = {
    txtCodigo: '',
    txtNTable: '',
    txtCCanal: '',
    txtBasico: '0.00',
    txtObjet1: '0.00',
    txtObjet2: '0.00',
    txtERepar: '0.00',
    txtLineal: '0.00',
    txtCobran: '0.00',
    txtPeriod: new Date(),
  }
  const datosDetalle = {
    arrVolume:[],
    arrCobert:[],
    arrNPedid:[]
  }
  const [listDatos, setListDatos] = useState(datosCabecera)
  const [listDataUstMemori, setlistDataUstMemori] = useState(datosDetalle)
  const handleFieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    setListDatos({
      ...listDatos,
      [fieldName]: value,
    });
  };
  const handleDateChange1 = (date) => {
    setListDatos({
      ...listDatos,
      txtPeriod: date, // Actualiza el valor de la fecha seleccionada
    });
  };

  const handleSelectChange = (selectedOption) => {
    setSlcArticulo(selectedOption); // Actualiza el estado si es necesario.
    setListDatos({
      ...listDatos,
      txtCCanal: selectedOption.Code, // Actualiza el valor de la fecha seleccionada
    });
  };
  const [disabledb1, setdisabledb1] = useState(false);
  const [disabledb2, setdisabledb2] = useState(false);
  const [disabledbFecha, setdisabledbFecha] = useState(false);
  const [btnVisible1, setBtnVisible1] = useState(false);
  const [btnVisible2, setBtnVisible2] = useState(false);
  const [validarPut, setValidarPut] = useState('0');
  const onClickActualizar = async() => {
    setdisabledb2(true)
    const url = '/apiFactorCabecera'
    const body = { vcodEmpresa:cook_idEmp, arrCabecera: listDatos};
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url,opPe);
    const resdatos = await response.json();
    if (resdatos.vl_api === 1){
      notify_warning("Error al actualizar, verificar datos!")
    }
    if (resdatos.vl_existe === 1){
      notify_warning("Ya esta actualizado!")
    }
    if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
      notify_valido("Se actualizo correctamente!")
    }
    setdisabledb2(false)
  }
  const onclickCrearG = async() => {
    setdisabledb1(true)
    // setListDatos(() => ({
    //   ...listDatos,
    //   txtPeriod: moment(listDatos.txtPeriod, 'Y-M-D').format('MM-YYYY')
    // }));
    //if (listDatos.txtCod!=="" && listDatos.txtDBon !=="" ) {
    if (  
        listDatos.txtNTable !=="" && listDatos.txtCCanal !=="" && 
        listDatos.txtBasico !=="" && listDatos.txtObjet1 !=="" && 
        listDatos.txtObjet2 !==""
     ) {

      if (
        (listDataUstMemori.arrVolume.length >= 1) ||
        (listDataUstMemori.arrCobert.length >= 1) ||
        (listDataUstMemori.arrNPedid.length >= 1) 
      ) {
        
          
          const url = '/apiFactorInsert'
          const body = { vcodEmpresa:cook_idEmp, arrCabecera: listDatos, arrDetalle: listDataUstMemori, validarPut: validarPut};
          const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
          const response = await fetch(url,opPe);
          const resdatos = await response.json();
          if (resdatos.vl_api === 1){
            notify_warning("Error al registrar, verificar datos!")
          }
          if (resdatos.vl_existe === 1){
            notify_warning("Ya esta registrado!")
          }
          if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
            
            notify_valido("Se registro correctamente!")
            setBtnVisible1(true)
            setBtnVisible2(true)
            setListDatos(() => ({
              ...listDatos,
              txtCodigo: resdatos.vl_codres
            }));
            
          }
      }else{
        notify_warning('En niguno de los tabs hay filas agregadas, por favor agregar minimo 1 fila en un tab.');
      }


    } else {
      
      if(listDatos.txtNTable==="") 
        { notify_warning('Se requeire Nombre Tabla');}
      if(listDatos.txtCCanal==="") 
        { notify_warning('Se requeire Canal');}
      if(listDatos.txtObjet1==="") 
        { notify_warning('Se requeire Basico');}
      if(listDatos.txtObjet1==="") 
        { notify_warning('Se requeire Objetivo 1');}
      if(listDatos.txtObjet2==="") 
        { notify_warning('Se requeire Objetivo 2');}
      
    }
    setdisabledb1(false)
  }
  const convertirCadenaAFecha = (cadena) => {
    const partes = cadena.split('-');
    const año = parseInt(partes[0], 10) ; // Restamos 1 al mes porque en JavaScript los meses van de 0 a 11
    const mes = parseInt(partes[1], 10)- 1;
    return new Date(año, mes);
  };
  const handleReciviendoDatos = async(Row) => {
    onClickLimpiar()
    if(Row){ 
      setListDatos({
        ...listDatos,
        txtCodigo: Row.txtCodigo,
        txtNTable: Row.txtNTable,
        txtCCanal: Row.txtCCanal,
        txtBasico: Row.txtBasico,
        txtObjet1: Row.txtObjet1,
        txtObjet2: Row.txtObjet2,
        txtERepar: Row.txtERepar,
        txtLineal: Row.txtLineal,
        txtCobran: Row.txtCobran,
        txtPeriod: convertirCadenaAFecha(Row.txtPeriod),
        
      })
      setSlcArticulo({Code:Row.txtCCanal, Name: ''})
      
      
      setlistDataUstMemori({
        ...listDataUstMemori, 
        arrVolume:Row?.arrVolume,
        arrCobert:Row?.arrCobert,
        arrNPedid:Row?.arrNPedid,
      })
      
      setBtnVisible1(true)
      setBtnVisible2(true)
    }else{
      setBtnVisible1(false)
      setBtnVisible2(false)
      setListDatos({
        ...listDatos,
        txtPeriod: inp_text_fecha,
      })


    }
  }

  const onClickLimpiar = async() => {
    setListDatos(datosCabecera)
    setlistDataUstMemori(datosDetalle)
    setSlcArticulo([])
  }
  const onClickLimpiar2 = async() => {
    setListDatos(datosCabecera)
    setlistDataUstMemori(datosDetalle)
    setSlcArticulo([])
    setdata([])
    setbtnVisible(false)
  }
  const handleDataFromChild = (id,data) => {
    setlistDataUstMemori({
      ...listDataUstMemori,
      [id]:data
    });
  };
  const onHandleVPut = (dato) => {
    setValidarPut(dato)
  }
   /** Fin :> Datos modal - 1 */


  /** Inicio :> Datos modal - 2 */
  const [disabledb3, setdisabledb3] = useState(false);
  const [text_fecha, setText_fecha] = useState(new Date());
  const onClickCopyAnte = async() => { 
    setdisabledb3(true)

    const url = '/apiFactorFullInsert'
    const body = { vcodEmpresa: cook_idEmp,
      //txtper1: moment(inp_text_fecha,'Y-M-D').format('MM-YYYY'),
      txtper1: moment(inp_text_fecha,'Y-M-D').format('YYYY-MM'),
      //txtper2: moment(text_fecha,'Y-M-D').format('MM-YYYY'),
      txtper2: moment(text_fecha,'Y-M-D').format('YYYY-MM'),
      arrData: data
    };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url,opPe);
    const respdata = await response.json();
    console.log("respdata", respdata);

    setTimeout(() => {
      setdisabledb3(false)
    }, 1000);
  }

  return (
    <div>
        <h6 className="text-muted"> COMICalculo</h6>
        <div className="card card-action">
            <div className="card-body ">
              <div className="row mb-1">
                <div className="col-lg-2 d-none">Sucursal:</div>
                <div className="col-lg-2 d-none">
                  <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
                </div>
                <div className="col-lg-2 ">Periodo:</div>
                <div className="col-lg-3" style={{ zIndex: '3' }}>
                    <DatePicker
                      id="fecha"
                      locale="es"
                      className="form-control form-control-sm text-center"
                      selected={inp_text_fecha}
                      onChange={date => setInp_text_fecha(date)}
                      dateFormat="yyyy-MM"  // Muestra solo el mes y el año
                      showMonthYearPicker  // Permite seleccionar mes y año directamente
                      disabled={disabledbFecha}
                      autoComplete='off'
                    />
                </div>
                <div className="col-lg-7 text-end ">
                <button className="btn btn-sm btn-label-twitter" onClick={() => handleOpen('')} > <i className='bx bxs-file-plus'></i> Nuevo </button>
                  {
                    btnVisible && (
                      <>
                        <button className="btn btn-sm btn-label-success" type="button"  data-bs-toggle="modal" data-bs-target="#smallModal" ><i className='bx bxs-copy'></i>  Copy</button>
                        
                      </>
                    )
                  }&nbsp;
                  <Tooltip title="Consultar información" placement="top">
                    <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                      <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                      <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                      &nbsp;Consultar
                    </button>
                  </Tooltip>&nbsp;
                  <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar2} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
                  {/* <label htmlFor="formFile" className="btn btn-sm btn-success">
                    <i className='bx bx-cloud-upload bx-ms' id="btn_subirExcel_icon"></i> Migrar Formato
                  </label>
                  <input type="file" id="formFile" name="formFile" accept='.csv,.xlsx,.xlsm' className="form-control form-control-sm d-none" onChange={fc_cargar_excel} /> */}
                </div>
              </div>
              <div className="card-datatable table-responsive">
                <MaterialReactTable 
                  columns={columns}
                  data={data}
                  state={{ isLoading: pending }}
                  initialState={{  
                    density: 'compact', showColumnFilters: false,  
                    showGlobalFilter: false,
                    //columnVisibility: { clave: false, vlSema: false, },
                  }}
                  enableGrouping
                  enableStickyHeader enableColumnResizing
                  muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                  localization={MRT_Localization_ES}
                  enablePagination={true}
                  muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
                  enableGlobalFilterModes
                  rowVirtualizerProps={{ overscan: 150 }}
                  enableRowVirtualization
                  displayColumnDefOptions={{
                      'mrt-row-actions': {
                        header: 'Options', //change header text
                        size: 120, //make actions column wider
                      },
                    }}
                  enableRowActions
                  renderRowActions={({ row }) => (
                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                      <AButton tp_tilde="Ver detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_funct={() => handleOpen(row.original)} ic_class="bx bx-ms bx-list-ul" vl_tpbtn={1} />
                    </Box>
                  )}
                  muiCircularProgressProps={{ color: 'error' }}
                  muiSkeletonProps={{
                    animation: 'pulse',
                    height: 28,
                  }}
                  
                  
                  
                />
              </div>
            </div>
        </div>
      


      {/************  Modal Detalles  ******************/}
      <Modal open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '80%', height: '80%', overflow: 'auto' }}>
        
          <div className="modal-header mb-3">
            <h5 className="modal-title" id="exampl">Detalle de Factor</h5>
            <button type="button" className="btn btn-label-danger btn-sm" onClick={handleRowClick}><i className='bx bx-x'></i></button>
          </div>
          <div className="modal-body" >
            
            <div className="row ">
              {/* Navigation */}
              <div className="col-12 col-md-3 mx-auto card-separator ">
                <div className="d-flex justify-content-between flex-column mb-3 mb-md-0 pe-md-3">
                  <ul className="nav nav-align-left nav-pills flex-column">
                    <li className="nav-item">
                      <button className="nav-link active " data-bs-toggle="tab" data-bs-target="#dtprincipales">
                        <i className="bx bx-cube me-2" />
                        <span className="align-middle">Datos Principales</span>
                      </button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link " data-bs-toggle="tab" data-bs-target="#Volumen">
                        <i className="bx bx-cube me-2" />
                        <span className="align-middle">Volumen</span>
                      </button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link " data-bs-toggle="tab" data-bs-target="#Cobertura">
                        <i className="bx bx-car me-2" />
                        <span className="align-middle">Cobertura</span>
                      </button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link " data-bs-toggle="tab" data-bs-target="#NroPedidos">
                        <i className="bx bx-globe me-2" />
                        <span className="align-middle">Nro. Pedidos</span>
                      </button>
                    </li>
                  </ul>
                  <hr/>
                  <div className="row ">
                    <div className="col-lg-12 text-center"> 
                      <button type="button" className="btn btn-label-danger btn-sm" onClick={handleRowClick}>Cerrar</button>&nbsp;
                      {btnVisible1 ?
                        <AButton tp_tilde="Guardar nuevos registros" bt_descr="Guardar" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onclickCrearG} disabled = {disabledb1} />
                        :
                        <AButton tp_tilde="Crear" bt_descr="Crear" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onclickCrearG} disabled = {disabledb1} />        
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* /Navigation */}
              {/* Options */}
              <div className="col-12 col-md-9 pt-4 pt-md-0">
                <div className="tab-content p-0 pe-md-5 ps-md-3">
                  {/* Restock Tab */}
                  <div className="tab-pane fade show active" id="dtprincipales" role="tabpanel">
                    <div className="row ">
                      <div className="col-md-2"><label className="form-label mb-0 " >Periodo:</label></div>
                      <div className="col-md-4" style={{ position: 'relative', zIndex: '9899' }}>
                        <DatePicker 
                          id="Periodo"
                          locale="es"
                          wrapperClassName='input_date'
                          className="form-control form-control-sm text-center"
                          selected={listDatos.txtPeriod}
                          onChange={date => handleDateChange1(date)} 
                          dateFormat="yyyy-MM"
                          showMonthYearPicker
                        />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-2"><label className="form-label mb-0 " >Codigo:</label></div>
                      <div className="col-md-3">
                        <input type="text" id="txtCodigo" name="txtCodigo" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtCodigo} onChange={handleFieldChange('txtCodigo')} disabled/>
                      </div>
                      <div className="col-md-2"><label className="form-label mb-0 " >NomTabla:</label></div>
                      <div className="col-md-5">
                        <input type="text" id="txtNTable" name="txtNTable" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtNTable} onChange={handleFieldChange('txtNTable')} required={true} />
                      </div>
                      
                    </div>
                    <div className="row ">
                      <div className="col-md-2"><label className="form-label mb-0 " >Canal:</label></div>
                      <div className="col-md-10">
                        {/* <input type="text" id="Canal" name="Canal" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtCCanal} onChange={handleFieldChange('txtCCanal')}  /> */}
                        <AsyncSelect
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-4">
                        <label className="form-label mb-0" >Basico</label>
                        <input type="number" id="Basico" name="Basico" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtBasico} onChange={handleFieldChange('txtBasico')} />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label mb-0" >Objetivo1</label>
                        <input type="number" id="Objetivo1" name="Objetivo1" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtObjet1} onChange={handleFieldChange('txtObjet1')} />
                      </div>
                      <div className="col-md-4 ">
                        <label className="form-label mb-0 " >Objetivo2</label>
                        <input type="number" id="Objetivo2" name="Objetivo2" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtObjet2} onChange={handleFieldChange('txtObjet2')}  />
                      </div>
                      
                    </div>
                    <div className="row ">
                      <div className="col-md-4">
                        <label className="form-label mb-0 " >Efectividad Reparto</label>
                        <input type="text" id="txtERepar" name="txtERepar" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtERepar} onChange={handleFieldChange('txtERepar')} />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label mb-0 " >Linealidad</label>
                        <input type="text" id="txtLineal" name="txtLineal" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtLineal} onChange={handleFieldChange('txtLineal')} />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label mb-0 " >Cobranza</label>
                        <input type="text" id="txtCobran" name="txtCobran" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtCobran} onChange={handleFieldChange('txtCobran')} />
                      </div>
                    </div>
                    {
                      btnVisible2 && (
                        <div className="row mb-2">
                          <div className="col-lg-4"></div>
                          <div className="col-lg-8 text-end">
                            <AButton id="btn_actualizar" tp_tilde="Actualizar Cabecera" bt_descr="Actualizar Cabecera" tp_posit="top" bt_class="btn btn-sm btn-label-success" bt_funct={onClickActualizar} ic_class="bx bx-ms bx-send" vl_tpbtn={2} disabled = {disabledb2} />
                          </div>
                        </div>
                      )
                    }
                  </div>
                  {/* Volumen */}
                  <div className="tab-pane fade" id="Volumen" role="tabpanel">
                    <h5 className="mb-4">Volumen</h5>
                    <DFVolumen 
                      arrCampDatos={listDatos} 
                      onDataUpdate={handleDataFromChild}
                      listInitData={listDataUstMemori.arrVolume} 
                      onHandleVPut={onHandleVPut}

                    />  
                  </div>
                  {/* Cobertura */}
                  <div className="tab-pane fade" id="Cobertura" role="tabpanel">
                    <h5 className="mb-4">Cobertura</h5>
                    <DFCobertura 
                      arrCampDatos={listDatos} 
                      onDataUpdate={handleDataFromChild}
                      listInitData={listDataUstMemori.arrCobert} 
                      onHandleVPut={onHandleVPut}
                    />
                  </div>
                  {/* NroPedidos Tab */}
                  <div className="tab-pane fade" id="NroPedidos" role="tabpanel">
                    <h5 className="mb-4">NroPedidos</h5>
                    <DFNroPedidos 
                      arrCampDatos={listDatos} 
                      onDataUpdate={handleDataFromChild}
                      listInitData={listDataUstMemori.arrNPedid} 
                      onHandleVPut={onHandleVPut}
                    />
                  </div>
                  {/* /NroPedidos Tab */}
                  
                </div>
              </div>
              {/* /Options*/}
            </div>
             

          </div>
          <div className="modal-footer d-none">
          <div className="row ">
            <div className="col-lg-4"></div>
            <div className="col-lg-8 text-end"> 
            {btnVisible1 ?
              <AButton tp_tilde="Guardar nuevos registros" bt_descr="Guardar" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onclickCrearG} disabled = {disabledb1} />
              :
              <AButton tp_tilde="Crear" bt_descr="Crear" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onclickCrearG} disabled = {disabledb1} />        
            }
            </div>
          </div>
      
          </div>
        </Box>


      </Modal>

      {/** Modal **/} 
      <div className="modal fade" id="smallModal" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel2">Copiar al periodo</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body text-center" >
              <div className="row">
                <div className="col mb-3">
                {/* <label className="form-label mb-0 " >Actual:</label> */}
                  <DatePicker
                    id="fecha"
                    locale="es"
                    className="form-control form-control-sm text-center"
                    selected={text_fecha}
                    onChange={date => setText_fecha(date)}
                    //dateFormat="MM-yyyy"  // Muestra solo el mes y el año
                    dateFormat="yyyy-MM"  // Muestra solo el mes y el año
                    showMonthYearPicker  // Permite seleccionar mes y año directamente
                    autoComplete='off'
                  />
                </div>
                {/* <div className="col mb-3">
                <label className="form-label mb-0 " >Anterior:</label>
                  <DatePicker
                    id="fecha"
                    locale="es"
                    className="form-control form-control-sm text-center"
                    selected={inp_text_fecha}
                    onChange={date => setInp_text_fecha(date)}
                    dateFormat="MM/yyyy"  // Muestra solo el mes y el año
                    showMonthYearPicker  // Permite seleccionar mes y año directamente
                  />
                </div> */}
              </div>
            </div>
            <div className="modal-footer ">
              {/* <button type="button" className="btn btn-label-success btn-sm">Proceder a copiar</button> */}
              <AButton bt_descr="Proceder a copiar" tp_posit="top" bt_class="btn btn-label-success btn-sm" bt_funct={onClickCopyAnte} disabled={disabledb3} ic_class="bx bx-cloud-download bx-ms" vl_tpbtn={2} />
              <button type="button" className="btn btn-label-danger btn-sm" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>




      <ToastContainer />
    </div>
  )
}

export default COMICalculo

