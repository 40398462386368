import { Box, Modal, Tooltip } from "@mui/material";
import DataTable, { createTheme as createThemeS } from "react-data-table-component";
import React, { useEffect, useMemo, useState } from "react";
import { SelectSucursal } from "../../components/Selects/SelectsPrincipales";
import { notify_error, notify_valido, notify_warning, selectTabla } from "../../components/utils";
import { MaterialReactTable } from 'material-react-table'
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { AButton } from "../../components/Button";
import AsyncSelect from 'react-select/async';
import Cookies from 'universal-cookie';
import '../../App.css';
import { createTheme, ThemeProvider } from '@mui/material';
import { ToastContainer } from "react-toastify";
import { useCallback } from "react";
import Swal from 'sweetalert2';
import moment from "moment";

const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.default',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const styles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: `#D2D5DC`,
    border: '1px solid #D2D5DC',
    boxShadow: state.isFocused ? null : null,
    backgroundColor: window.templateCustomizer.settings.style === 'light'
      ? '#fefff4'
      : '#2B2C40',
    color: window.templateCustomizer.settings.style === 'light'
      ? '#2B2C40'
      : '#fefff4',
    minHeight: `29px`,
    height: `29px`,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (window.templateCustomizer.settings.style === 'light' ? '#fefff4' : '#2B2C40')
      : (window.templateCustomizer.settings.style === 'light' ? '#fefff4' : '#2B2C40'),
    color: state.isSelected
      ? 'white'
      : (window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: window
      .templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4',
    fontSize: `0.75rem`,
  }),

  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4',
  }),
};


export const COMVisitavendedor = () => {

  return (
    <div className="d-flex flex-column">
      <h6 className="text-muted">Visita vendedor</h6>
      <div className="card-body">
        <TableSallerVisit />
      </div>
      <ToastContainer />
    </div>
  );
};

export default COMVisitavendedor;

export const TableSallerVisit = ({ dataTable }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [openFirstModal, setOpenFirstModal] = useState(false);
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [sucSelected, empsucSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }
  const listar = async () => {
    if (!emp_sl_t_suc || emp_sl_t_suc === '9999') {
      notify_warning('Seleccione una sucursal');
      return;
    }
    setLoading(true);
    const url_lstClientes = `/getVistitasVendedor?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    if (data.length === 0) {
      notify_warning('Sin clientes registrados')
    }
    empsucSelected(emp_sl_t_suc);
    setFilteredData(data);
    setLoading(false);
  }
  const handleOpen = (e) => {
    setOpenFirstModal(true);
  };
  const handleClose = () => {
    setOpenFirstModal(false);
  };
  const handleButtonClick = (e) => {
    e.preventDefault();
    setSelectedData(null);
    handleOpen();
  };

  const onClickEdit = async (row) => {
    const fechtApi = async (CardCode) => {
      const url = `getVisitasVendedorByCode?codigo=${CardCode}`;
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
      const response = await fetch(url, opPe);
      const data = await response.json();
      return data;
    };
    setSelectedData(await fechtApi(row.code));
    handleOpen();
  }

  const setDta = async (data) => {
    listar();
    handleClose();
  }

  const columns = useMemo(() => [
    { header: 'Vendedor', accessorKey: "saller" },
    { header: 'Codigo', accessorKey: "code" },
    { header: 'Sucursal', accessorKey: "branchOffice" },
    {
      header: 'Activo',
      accessorKey: 'activo',
      Cell: ({ row }) => <div className="align-items-center"><div className="form-check form-check-danger">
        <input className="form-check-input" type="checkbox" checked={row.original.activo} readOnly />
      </div></div>
    },
  ], []);
  const globalTheme = window.templateCustomizer.settings.style;
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [globalTheme],
  );
  return (
    <>
      <ThemeProvider theme={tableTheme}>
        <div className="d-flex">
          <div className="col-lg-1">Sucursal:</div>
          <div className="col-lg-2">
            <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
          </div>
          <div className="col-lg-2">
            <div className="text-center mb-3">
              <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={listar}>
                <i className='bx bx-list-ul' ></i>&nbsp;Consultar
              </button>
            </div>
          </div>
        </div>
        <div className="card-datatable table-responsive">
          <div className="col-12">
            <MaterialReactTable
              initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false }}
              enablePagination={true}
              enableRowActions
              localization={MRT_Localization_ES}
              data={filteredData}
              state={{ isLoading: loading }}
              renderTopToolbarCustomActions={({ table }) => (
                sucSelected && !loading ?
                  <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                    <AButton tp_tilde="Asignar nueva ruta vendedor" tp_posit="top" bt_class="btn btn-label-success btn-sm " ic_class="fa-solid fa-plus" vl_tpbtn={1}
                      bt_funct={handleButtonClick}
                    />
                  </Box> :
                  <div></div>
              )}
              enableGrouping={true}
              renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '2px' }}>
                  <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-label-warning btn-icon btn-sm" ic_class="bx bxs-edit bx-ms" bt_funct={() => onClickEdit(row.original)}
                    id={JSON.stringify(row.original)} vl_tpbtn={1} />
                </Box>
              )}
              columns={columns} />
            <Modal
              open={openFirstModal}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{ ...style, width: '85%', height: '95%', overflow: 'auto' }}>
                <button
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  className='btn btn-outline-dark btn-sm'
                  onClick={handleClose}
                >
                  <i className='fa fa-x'></i>
                </button>
                <ContentModal sl_t_suc={emp_sl_t_suc} data={selectedData} dataReturn={setDta} />
              </Box>
            </Modal>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export const ContentModal = ({ sl_t_suc, data, dataReturn }) => {
  const [veh_sl_t_zona, setVehSl_t_zona] = useState([""]);
  const [slcArticulo, setSlcArticulo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [jsonInicial, setJsonInicial] = useState({
    vendedor: data ? data.vendedor : '',
    codigo: data ? data.codigo : '',
    ruta: '',
    activo: data ? data.activo : false,
  });
  const [dataTable, setDataTable] = useState(data ? data.table : []);
  const handleCheckboxChange = (index, valor) => (event) => {
    const value = event.target.checked;
    setDataTable(dataTable.map((row, i) => i === index ? {
      ...row,
      [valor]: value,
      activo: true,
    } : row));
  };
  const [oneExec, setOneExec] = useState(false);
  const [arrayListView, setArrayListView] = useState([]);
  const [arrayListViewByCode, setArrayListViewByCode] = useState([]);

  const columns = [
    {
      cell: (row) =>
        <div className="align-items-center">
          {/* <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={() => onClickDelete(row.codigoRuta)} id={JSON.stringify(row)}  ><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i>
          </button> */}
          <AButton tp_tilde="Desactivar ruta" tp_posit="top" bt_class="btn btn-sm btn-label-danger " bt_funct={() => onClickDelete(row.codigoRuta)} ic_class="bx bx-ms bx-no-entry" vl_tpbtn={1} />
        </div>
      , ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN'
    },
    { name: 'Vendedor', selector: row => row.codigo, sortable: true },
    { name: 'Ruta', selector: row => row.codigoRuta, sortable: true },
    {
      cell: (row, index) =>
        <div className="align-items-center">
          <div className="form">
            <input className="form-control form-control-sm" type="text" value={row.secuencia}
              onChange={handleFieldChange(index, 'secuencia')}
            />
          </div>
        </div>
      , ignoreRowClick: true, allowOverflow: true, name: 'Secuencia', with: "150px"
    },
    {
      cell: (row, index) =>
        <div className="align-items-center">
          <div className="form">
            <input className="form-control form-control-sm" type="date" value={row.inicioVisita}
              onChange={handleFieldChange(index, 'inicioVisita')}
            />
          </div>
        </div>
      , ignoreRowClick: true, allowOverflow: true, name: 'InicioVisita', width: "150px"
    },
    {
      cell: (row, index) =>
        <div className="align-items-center">
          <div className="form-check form-check-danger">
            <input className="form-check-input" type="checkbox" value="L" id="customCheckPrimary" onChange={handleCheckboxChange(index, 'lunes')} checked={(row.lunes)} />
          </div>
        </div>
      , ignoreRowClick: true, allowOverflow: true, button: true, name: 'L', width: "50px"
    },
    {
      cell: (row, index) =>
        <div className="align-items-center">
          <div className="form-check form-check-danger">
            <input className="form-check-input" type="checkbox" value="M" id="customCheckPrimary" onChange={handleCheckboxChange(index, 'martes')} checked={(row.martes)} />
          </div>
        </div>
      , ignoreRowClick: true, allowOverflow: true, button: true, name: 'M', width: "50px"
    },
    {
      cell: (row, index) =>
        <div className="align-items-center">
          <div className="form-check form-check-danger">
            <input className="form-check-input" type="checkbox" value="M" id="customCheckPrimary" onChange={handleCheckboxChange(index, 'miercoles')} checked={(row.miercoles)} />
          </div>
        </div>
      , ignoreRowClick: true, allowOverflow: true, button: true, name: 'M', width: "50px"
    },
    {
      cell: (row, index) =>
        <div className="align-items-center">
          <div className="form-check form-check-danger">
            <input className="form-check-input" type="checkbox" value="J" id="customCheckPrimary" onChange={handleCheckboxChange(index, 'jueves')} checked={(row.jueves)} />
          </div>
        </div>
      , ignoreRowClick: true, allowOverflow: true, button: true, name: 'J', width: "50px"
    },
    {
      cell: (row, index) =>
        <div className="align-items-center">
          <div className="form-check form-check-danger">
            <input className="form-check-input" type="checkbox" value="V" id="customCheckPrimary" onChange={handleCheckboxChange(index, 'viernes')} checked={(row.viernes)} />
          </div>
        </div>
      , ignoreRowClick: true, allowOverflow: true, button: true, name: 'V', width: "50px"
    },
    {
      cell: (row, index) =>
        <div className="align-items-center">
          <div className="form-check form-check-danger">
            <input className="form-check-input" type="checkbox" value="S" id="customCheckPrimary" onChange={handleCheckboxChange(index, 'sabado')} checked={(row.sabado)} />
          </div>
        </div>
      , ignoreRowClick: true, allowOverflow: true, button: true, name: 'S', width: "50px"
    },
    {
      cell: (row, index) =>
        <div className="align-items-center">
          <div className="form-check form-check-danger">
            <input className="form-check-input" type="checkbox" value="D" id="customCheckPrimary" onChange={handleCheckboxChange(index, 'domingo')} checked={(row.domingo)} />
          </div>
        </div>
      , ignoreRowClick: true, allowOverflow: true, button: true, name: 'D', width: "50px"
    },
  ];

  const getVendedores = useCallback(async () => {
    const url = `getSallersComboBox?vcodEmpresa=${cook_idEmp}&vcodSucursal=${sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    setArrayListView(data.listaVendedoresNombre);
    setArrayListViewByCode(data.listaVendedoresCodigo);
    return data;
  }, [sl_t_suc])

  useEffect(() => {
    if (!oneExec) {
      getVendedores();
      setOneExec(true)
    }
    const dataFoundByName = arrayListView.find((res) => { return res.Code === jsonInicial.codigo });
    if (dataFoundByName) {
      setSlcArticulo({ Code: jsonInicial.codigo, Name: dataFoundByName?.Name });
    }
  }, [arrayListView, arrayListViewByCode, getVendedores, jsonInicial, oneExec]);



  const funDVendedores = useCallback(async (inputValue) => {
    return arrayListView.filter((elemento) => {
      const Code = elemento.Code.toString();
      const Name = elemento.Name.toString().toUpperCase();
      return Code.includes(inputValue.toString().toUpperCase()) || Name.includes(inputValue.toString().toUpperCase());
    });;
  }, [arrayListView])

  const onClickDelete = (code) => {
    const newDataArray = [...dataTable];
    newDataArray.forEach((response) => {
      if (response.codigoRuta === code) {
        response.lunes = false;
        response.martes = false;
        response.miercoles = false;
        response.jueves = false;
        response.viernes = false;
        response.sabado = false;
        response.domingo = false;
        response.activo = false;
      }
    });
    // newDataArray.splice(index, 1);
    setDataTable(newDataArray);
  }


  const agregarRuta = () => {
    if (!slcArticulo.Code || slcArticulo.Code.trim() === "") {
      return notify_warning("Debe estar seleccionado un vendedor");
    }
    if (!veh_sl_t_zona.Code || veh_sl_t_zona.Code.trim() === "") {
      return notify_warning("Debe estar seleccionado una ruta");
    }
    const data = {
      codigo: slcArticulo.Code,
      ruta: `${veh_sl_t_zona.Name}`,
      codigoRuta: veh_sl_t_zona.Code,
      lunes: false,
      martes: false,
      miercoles: false,
      jueves: false,
      viernes: false,
      sabado: false,
      domingo: false,
      U_AB_DVFI: null,
      U_AB_DVSE: null,
      LineId: null,
      Object: null,
      LogInst: null,
      activo: true,
      secuencia: 1,
      inicioVisita: moment(new Date()).format('YYYY-MM-DD'),
    };
    if (dataTable.findIndex((response) => response.codigoRuta === data.codigoRuta) !== -1) return;
    dataTable.push(data);
    setDataTable([...dataTable]);
    resetData();
  }
  const resetData = () => {
    setVehSl_t_zona([]);
    setJsonInicial({ ...jsonInicial, ruta: '' });
  }
  const handleRowClicked = () => {
    selectTabla('tabla-resumen');
  }
  const guardarData = async () => {
    setLoading(true);
    let urlPost = `/saveVistitasVendedor?vcodEmpresa=${cook_idEmp}`;
    let body = {
      vcodEmpresa: cook_idEmp,
      codigo: dataTable[0]?.codigo,
      vendedor: jsonInicial.vendedor,
      activo: jsonInicial.activo,
      sucursal: sl_t_suc,
      table: dataTable,
    };
    if (!body.codigo || body.codigo.trim() === "") {
      return notify_warning("Debe de asignar un vendedor");
    }
    if (!body.vendedor) {
      const dataFoundByCode = arrayListViewByCode.find((res) => { return res.SlpName === body.codigo });
      body.vendedor = dataFoundByCode.SlpCode;
      body["isNew"] = true;
    }
    Swal.fire({
      title: "CONFIRMACION",
      text: "¿Desea guardar este vendedor?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      customClass: {
        container: "my-swal"
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
        const response = await fetch(urlPost, opPe);
        const data = await response.json();

        const respuestasCorrectas = [];
        let longitudClientes = 0;
        for (const iterator of data.AB_VVDTCollection) {
          const url2 = `/getclientesporruta?vcodEmpresa=${cook_idEmp}&U_AB_DVCR=${iterator.U_AB_DVCR}`;
          const opPe2 = { method: "GET", headers: { 'Content-Type': 'application/json' } }
          const response2 = await fetch(url2, opPe2)
          const dataResponse2 = await response2.json();
          if (dataResponse2.length) {
            const grupos = subdividirEnGrupos(dataResponse2, 10);
            longitudClientes += grupos.length;
            await delay(1);
            for (const key in grupos) {
              if (Object.hasOwnProperty.call(grupos, key)) {
                const dias = body.table.find((tabla) => tabla.codigoRuta.toString() === iterator.U_AB_DVCR.toString());
                const element = grupos[key];
                const url3 = `actualizarcliente?ruta=${iterator.U_AB_DVCR}&vendedor=${body.vendedor}`;
                const opPe3 = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ clientes: element, dias: dias }) }
                const response3 = await fetch(url3, opPe3);
                const dataResponse3 = await response3.json();
                if (dataResponse3.message) {
                  respuestasCorrectas.push(dataResponse3);
                }
              }
            }
          }
        }
        console.log("longitudClientes", longitudClientes);
        console.log("respuestasCorrectas.length", respuestasCorrectas.length);
        if (respuestasCorrectas.length === longitudClientes) {
          dataReturn(body);
          setLoading(false);
          notify_valido("Guardado");
        } else {
          setLoading(false);
          notify_error("Ha ocurrido un problema al actualizar rutas");
        }
      }
      setLoading(false);
    });
  };
  const subdividirEnGrupos = (lista, tamañoGrupo) => {
    const grupos = [];
    for (let i = 0; i < lista.length; i += tamañoGrupo) {
      grupos.push(lista.slice(i, i + tamañoGrupo));
    }
    return grupos;
  }
  const delay = (n) => {
    return new Promise((resolve) => {
      setTimeout(resolve, n * 1000);
    });
  }
  const veh_Select_Zona = useCallback(async (inputValue) => {
    const veh_url_lstZona = '/apicbxRuta';
    let body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(veh_url_lstZona, opPe);
    const data = await response.json();
    return data;
  }, [sl_t_suc])

  const globalTheme = window.templateCustomizer.settings.style;

  createThemeS('solarized', {
    text: {
      primary: globalTheme === 'light'
        ? '#2B2C40'
        : '#fefff4',
      secondary: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
    },
    background: {
      default: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
    },
    context: {
      background: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
      text: '#FFFFFF',
    },
  });

  const handleFieldChangeChecked = (fieldName) => (event) => {
    const value = event.target.checked;
    setJsonInicial({
      ...jsonInicial,
      [fieldName]: value,
    });
  };

  const handleFieldChange = (index, valor) => (event) => {
    const value = event.target.value;
    setDataTable(dataTable.map((row, i) => i === index ? { ...row, [valor]: value } : row));
  };

  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };

  return (
    <>
      <div className="d-flex flex-column mt-2">
        <div className="d-flex justify-content-start p-0 m-0">
          {
            <h5>Asignación de ruta vendedor</h5>
          }
        </div>
        <div className="d-flex p-0 m-0">
          <div className="col p-2 my-1 mx-2">
            <div className="small cont-weight-normal">
              Vendedor:
            </div>
            {/* <input type="text" className="form-control form-control-sm" value={jsonInicial.codigo} onChange={handleFieldChange('codigo')} readOnly={(dataTable.length) ? true : false} /> */}
            <AsyncSelect
              styles={styles}
              placeholder="Busque por Cod./Desc."
              name="form-field-name"
              className="sele_pru from-control form-control-sm tbCodArticulo my-swal"
              value={slcArticulo}
              defaultOptions
              getOptionLabel={e => `${e.Code} ${e.Name}`}
              getOptionValue={e => e.Code}
              loadOptions={funDVendedores}
              onChange={handleSelectChange}
              maxMenuHeight={150}
              isDisabled
              ={(dataTable.length) ? true : false}
            />
          </div>
          <div className="col p-2 my-1 mx-2">
            <div className="small cont-weight-normal">
              Ruta:
            </div>

            <AsyncSelect
              id="id_zona"
              styles={styles}
              placeholder="Busque por COD/DESC"
              name="form-field-name"
              className="sele_pru from-control form-control-sm my-swal"
              value={veh_sl_t_zona}
              cacheOptions
              defaultOptions
              getOptionLabel={e => e.Code + ' - ' + e.Name}
              getOptionValue={e => e.Code}
              loadOptions={veh_Select_Zona}
              onChange={setVehSl_t_zona}
            />
          </div>
          <div className='d-flex flex-column mt-2'>
            <div className="small cont-weight-normal mx-1">
              Activo:
            </div>
            <div className="d-flex justify-content-center mx-1">
              <input className="form-check-input" type="checkbox" checked={jsonInicial.activo} onChange={handleFieldChangeChecked('activo')} />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div className="d-flex">
            <button className="btn btn-label-youtube btn-sm" id="emp_btn_guardar" onClick={agregarRuta}>
              <i className='bx bx-save' id="icon_emp_btn_guardar" ></i>
              <span className="spinner-border d-none" id="spinner_emp_btn_guardar" role="status" aria-hidden="true"></span>
              &nbsp;Guardar Visita
            </button>
            <Tooltip title="Seleccionar nuevo vendedor" placement="top">
              <button className="btn btn-dark btn-sm" id="emp_btn_cancelar" onClick={resetData}>
                <i className='bx bx-history' ></i>&nbsp;
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="d-flex col-12 p-0 m-0" style={{
          'overflowY': 'hidden',
          'overflowX': 'hiden',
          'height': '350px',
        }}>
          <DataTable
            columns={columns}
            data={dataTable}
            className="tabla-resumen"
            Header
            defaultSortField="id"
            defaultSortAsc={true}
            pagination={false}
            responsive
            highlightOnHover
            theme="solarized"
            noContextMenu="No hay registros para mostrar"
            onRowClicked={handleRowClicked}
            direction="auto"
            fixedHeader
            fixedHeaderScrollHeight="350px"
          />
        </div>
        <div className="d-flex justify-content-end">
          <div className="d-flex">
            <AButton tp_tilde="Actualizar rutas" bt_descr="Guardar"
              bt_class="btn btn-success btn-sm" bt_funct={guardarData} vl_tpbtn={2} disabled={loading}
              ic_class="bx bx-save" />
          </div>
        </div>
      </div>
    </>
  );
};