import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { notify_warning, notify_valido, notify_error } from '../../../components/utils';
import { AButton } from '../../../components/Button';
//import BONReglas from './BONReglas'
import BONUnoAUno from './BONUnoAUno';
import BONTicketCompra from './BONTicketCompra';
import BONTicketCompraEscala from './BONTicketCompraEscala';
import BONBasicaEscala from './BONBasicaEscala';
import { ModalDetallesB, ModalDetallesBoni, ModalDetallesBoniPar,ModalDetallesBoniParStock, ModalBEPorE } from '../../../components/Modals/ModalsDetVen';

import DatePicker from "react-datepicker";
import './BON.css'
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import Cookies from 'universal-cookie';
import $ from 'jquery';
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id')
const moment = require('moment')
const globalTheme = window.templateCustomizer.settings.style;

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.default',
//   border: '1px #000',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3,
// };
// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '1px #000',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3,
// };
const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};

export const BARInicioDetalle = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const selectRowRef = useRef(null);
  const [listDataUstMemori, setlistDataUstMemori] = useState({
    bondatosReglas: [],
  })
  const [dataid, setDataid] = useState({ codigo: '', id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: '', id8: '', id9: '', id10: '', id11: '', id12: '', id13: ''  });
  const [arFileUltim, setArFileUltim] = useState({
    txtDet0: '',
    txtDet1: '',
    txtDet2: '',
  });
  const handleDataFromChild = (id, data) => {
    setlistDataUstMemori({
      ...listDataUstMemori,
      [id]: data
    });
  }
  const handleFieldTabChange = (fieldName) => (event) => {
    const value = event.target.value;
    setArFileUltim({
      ...arFileUltim,
      [fieldName]: value,
    });
  };
  const onClickInsert = async () => {

    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && arFileUltim.txtDet1 !== "") {      
    if (arrCampDatos.txtDBon !== "" && arFileUltim.txtDet1 !== "") {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === arFileUltim.txtDet1)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: arFileUltim.txtDet1, namArt: arFileUltim.txtDet2, namTip: arFileUltim.txtDet0 };

        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('bceiniciodetallador', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setlistDataUstMemori({ bondatosReglas: [] })
        setArFileUltim({ txtDet0: '', txtDet1: '', txtDet2: '' });
        setTimeout(() => { selectRowRef.current.focus() }, 500);
        //}        
      } else {
        notify_warning('Datos selecionados ya exiten.');
      }
    } else {
      // if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
      } else {
        //if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arFileUltim.txtDet0 === "") { notify_warning('Seleccione de tipo de udo'); }
        if (arFileUltim.txtDet1 === "") { notify_warning('Codigo de regla esta vacio'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción es obligatorio, no debe estar vacio.'); }

      }

    }

  }

  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])

  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('bceiniciodetallador', myList)
    // setlistDataUstMemori({bondatosReglas:[]})
    // setSlcArticulo([])

  }

  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }
        </td>
        <td>{prosp.datoRow.namTip}</td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          <div className="row">
            <div className="col-8">
              <label className="form-control-sm">{prosp.datoRow.CodArt}</label>
            </div>
            <div className="col-4 text-end">
              <AButton tp_tilde="Mas detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_nmodal="#exLargeModal2" bt_funct={(e) => { onClickAbrirModal(prosp.datoRow.CodArt, prosp.datoRow.namTip) }} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
            </div>
          </div>
        </td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  const fcoffModal = () => {
    if (listDataUstMemori) {
      setArFileUltim(()=>({
        ...arFileUltim,
        txtDet1: (listDataUstMemori?.bondatosReglas?.rtxtCod) ? listDataUstMemori?.bondatosReglas?.rtxtCod : '',
        txtDet2: (listDataUstMemori?.bondatosReglas?.rtxtDesc) ? listDataUstMemori?.bondatosReglas?.rtxtDesc : '',
      }));
    }
  }

  const [ estUdo, setEstUdo ] = useState('')
  const [ desUdo, setDesUdo ] = useState('')
  // const onClickAbrirModal = (cod,name) => (event) => {
  const onClickAbrirModal = (cod,name) => {
    setDesUdo('')
    setEstUdo(false)
    if(name==="AB_BUU"){ setDesUdo('Bonificación Uno a Uno') }
    if(name==="AB_BCTC"){ setDesUdo('Bonificación Ticket Compra') }
    if(name==="AB_DCEH"){ setDesUdo('Bonificación Ticket Compra Escala') }
    if(name==="AB_BOBE"){ setDesUdo('Bonificación Basica Escala') }
    setEstUdo(name)

    setDataid({ codigo: cod, id1: 'tab1', id2: 'tab2', id3: 'tab3', id4: 'tab4', id5: 'tab5', id6: 'tab6', id7: 'tab7', id8: 'tab8', id9: 'tab9', id10: 'tab10', id11: 'tab11', id12: 'tab12', id13: 'tab13'  })
  }

  return (
    <>
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_BCEInicioDetalleT' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '250px' }} >TipoUdo </th>
              <th style={{ width: '250px' }} >Code Regla  </th>
              <th style={{ width: '650px' }} >Des. Regla</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'></td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                          <select className="form-select form-select-sm" value={arFileUltim.txtDet0} onChange={handleFieldTabChange('txtDet0')} >
                                  <option value='' >-- Seleccione --</option> 
                                  <option value='AB_BUU' >Bon.1a1</option>
                                  <option value='AB_BCTC' >Bon.TicketCompra</option>
                                  <option value='AB_DCEH' >Bon.TicketCompraEscala</option>
                                  <option value='AB_BOBE' >Bon.BasicaEscala</option>
                          </select>
                        </td>
                        <td className='td3' style={{ position: 'relative', zIndex: '3' }} >
                          <div className='input-group'>
                            <input type="text" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off' ref={selectRowRef} value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')} readOnly={true} />
                            {
                              arFileUltim.txtDet0 !== '' && (
                                <AButton tp_tilde="Mas detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_nmodal="#exLargeModal2" bt_funct={(e) => { onClickAbrirModal('',arFileUltim.txtDet0) }} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                              ) 
                            }
                          </div>
                        </td>
                        <td className='td4'>
                          <input type="text" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')} readOnly={true} />
                        </td>
                        <td className='td5'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="BorrarDatosFile" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " ic_class="bx bx-ms bx-no-entry" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'></td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                      <select className="form-select form-select-sm" value={arFileUltim.txtDet0} onChange={handleFieldTabChange('txtDet0')} >
                              <option value='' >-- Seleccione --</option> 
                              <option value='AB_BUU' >Bon.1a1</option>
                              <option value='AB_BCTC' >Bon.TicketCompra</option>
                              <option value='AB_DCEH' >Bon.TicketCompraEscala</option>
                              <option value='AB_BOBE' >Bon.BasicaEscala</option>
                      </select>
                    </td>
                    <td className='td3' style={{ position: 'relative', zIndex: '3' }} >
                      <div className='input-group'>
                        <input type="text" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')}  />
                        {
                          arFileUltim.txtDet0 !== '' && (
                            <AButton tp_tilde="Mas detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_nmodal="#exLargeModal2" bt_funct={(e) => { onClickAbrirModal('',arFileUltim.txtDet0) }} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                          ) 
                        }
                      </div>
                    </td>
                    <td className='td4'>
                      <input type="text" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')}  />
                    </td>
                    <td className='td5'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="BorrarDatosFile" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " ic_class="bx bx-ms bx-no-entry" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
          <div className="modal fade" id="exLargeModal2" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog  modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel4">{desUdo}</h5>
                  <button type="button" onClick={() => { fcoffModal() }} className="btn-close closeModal" data-bs-dismiss="modal" aria-label="Close" ></button>
                </div>
                <div className="modal-body" >
                {
                  //(arFileUltim.txtDet0 === "AB_BUU" || estUdo) && ( 
                  (arFileUltim.txtDet0 === "AB_BUU" || estUdo === "AB_BUU") && ( 
                    <BONUnoAUno 
                      dataid={dataid}
                      onDataUpdate2={handleDataFromChild}
                    /> 
                  )
                }
                {
                  (arFileUltim.txtDet0 === "AB_BCTC" || estUdo === "AB_BCTC") && ( 
                    <BONTicketCompra 
                      dataid={dataid}
                      onDataUpdate2={handleDataFromChild}
                    /> 
                  )
                }
                {
                  (arFileUltim.txtDet0 === "AB_DCEH" || estUdo === "AB_DCEH") && ( 
                    <BONTicketCompraEscala 
                      dataid={dataid}
                      onDataUpdate2={handleDataFromChild}
                    /> 
                  )
                }
                {
                  (arFileUltim.txtDet0 === "AB_BOBE" || estUdo === "AB_BOBE") && ( 
                    <BONBasicaEscala 
                      dataid={dataid}
                      onDataUpdate2={handleDataFromChild}
                    /> 
                  )
                }
               
                  
                </div>
                {/* <div className="modal-footer">
                  <button type="button" className="btn btn-sm btn-label-secondary" onClick={onClickAgregarModal} >Agregar</button>
                  <button type="button" id="btnCerrarModal2" className="btn btn-sm btn-label-secondary d-none" data-bs-dismiss="modal" >Agregar</button>
                </div> */}
              </div>
            </div>
          </div>


    </>
  )
}
export const BARObsequioDetalle = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const  valorDefault = '999999999';
  const [arFileUltim, setArFileUltim] = useState({ //999999999
    txtDet1: '0.00',
    txtDet2: valorDefault,
    txtDet3: valorDefault,
    txtDet4: valorDefault,
    txtDet5: '0.00',
    txtDet6: '0.00',
    txtDet7: '0.00',
    txtDet8: '0.00',
    txtDet9: '0.00'
  });
  const selectRowRef = useRef(null);
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiArticulos?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])

  const handleSelectChange = async (selectedOption) => {
    setSlcArticulo(selectedOption);

    // /** Inicio */
    // /** Entregado */
    // const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    // const response = await fetch('/apiEntregado?vcodEmpresa=' + cook_idEmp + '&CodArt=' + selectedOption.Code, opPe);
    // const dataEntr = await response.json();
    // /** Entregado */
    // const opPe2 = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    // const respons2 = await fetch('/apiPorEntregar?vcodEmpresa=' + cook_idEmp + '&CodArt=' + selectedOption.Code, opPe2);
    // const dataPorE = await respons2.json();

    // setArFileUltim({
    //   ...arFileUltim,
    //   'txtDet5': (dataEntr.vl_rsp) ? dataEntr.vl_rsp : '0.00',
    //   'txtDet6': (dataPorE.vl_rsp) ? dataPorE.vl_rsp : '0.00'
    // });
    // /** Fin */
  };

  const handleFieldTabChange = (fieldName) => (event) => {
    const value = event.target.value;
    setArFileUltim({
      ...arFileUltim,
      [fieldName]: value,
    });
  };

  const onClickInsert = async () => {

    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {      
    if (arrCampDatos.txtDBon !== "" && slcArticulo.Code !== undefined&&
    (arFileUltim.txtDet1 !== '0.00' && arFileUltim.txtDet1 !== '0' && arFileUltim.txtDet1.trim() !== '') //&& 
    //(arFileUltim.txtDet4 !== '0.00' && arFileUltim.txtDet4 !== '0' && arFileUltim.txtDet4.trim() !== '') && 
    //(arFileUltim.txtDet5 !== '0.00' && arFileUltim.txtDet5 !== '0' && arFileUltim.txtDet5.trim() !== '') && 
    //(arFileUltim.txtDet6 !== '0.00' && arFileUltim.txtDet6 !== '0' && arFileUltim.txtDet6.trim() !== '') 
    ) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = {
          idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name, uniArt: slcArticulo.Unidad,
          txtDet1: arFileUltim.txtDet1,
          txtDet2: arFileUltim.txtDet2,
          txtDet3: arFileUltim.txtDet3,
          txtDet4: arFileUltim.txtDet4,
          txtDet5: arFileUltim.txtDet5,
          txtDet6: arFileUltim.txtDet6
        };
        notify_valido("Se registro correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('bceobsequiodetll', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
        setArFileUltim({
          txtDet1: '0.00',
          txtDet2: valorDefault,
          txtDet3: valorDefault,
          txtDet4: '0.00',
          txtDet5: valorDefault,
          txtDet6: '0.00'
        })
        setTimeout(() => { selectRowRef.current.focus() }, 500);      
      } else {
        notify_warning('Datos selecionados ya exiten.');
      }
    } else {
      // if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio no debe estar vacio.');

      } else {
        // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
        if (arFileUltim.txtDet1 === '0.00' || arFileUltim.txtDet1 === '0' || arFileUltim.txtDet1.trim() === ''){ notify_warning('Cantidad AB es obligatorio.'); }
        // if (arFileUltim.txtDet4 === '0.00' || arFileUltim.txtDet4 === '0' || arFileUltim.txtDet4.trim() === ''){ notify_warning('Desde es obligatorio.'); }
        // if (arFileUltim.txtDet5 === '0.00' || arFileUltim.txtDet5 === '0' || arFileUltim.txtDet5.trim() === ''){ notify_warning('Hasta es obligatorio.'); }
        //if (arFileUltim.txtDet6 === '0.00' || arFileUltim.txtDet6 === '0' || arFileUltim.txtDet6.trim() === ''){ notify_warning('Por cada es obligatorio.'); }
      }

    }

  }

  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1];
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData,])

  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('bceobsequiodetll', myList)
    // setlistDataUstMemori({bondatosReglas:[]})
    // setSlcArticulo([])

  }

  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='td1'>
          {onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td className='td2' style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}
        </td>
        <td className='td3'>
          {prosp.datoRow.namArt}
        </td>
        <td className='td4'>{prosp.datoRow.uniArt}</td>
        <td className='td5'>{prosp.datoRow.txtDet1}</td>
        <td className='td6'>{prosp.datoRow.txtDet2}</td>
        <td className='td7'>{prosp.datoRow.txtDet3}</td>
        <td className='td8'>{prosp.datoRow.txtDet4}</td>
        <td className='td9'>{prosp.datoRow.txtDet5}</td>
        <td className='td10'>{prosp.datoRow.txtDet6}</td>
        <td></td>
      </tr>
    )
  }

  // Modal stock
  const [generalModal, setGeneralModal] = useState(false);
  const handleCloseGeneral= ()=> {
    setGeneralModal(false);
  }
  const handleOpenGeneral = () => {
    setGeneralModal(true);
  }
  const handleRetrunData= (data) => {}
  let columns  = [
    { header: 'Code', accessorKey: 'Code'},
    { header: 'Name', accessorKey: 'Name' },
    { header: 'Unidad', accessorKey: 'Unidad' },
    { header: 'Categoria', accessorKey: 'Categoria' },
    { header: 'CodEAN', accessorKey: 'CodEAN' },
    {header: 'Stock total', accessorKey:'Total'}
  ]
  return (
    <>
      {/* Hola mundo BCEInicioDetalle 
      <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /> */}
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_obsequio' style={{ fontSize: '10px', zIndex: '2', width: '100%' }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '250px' }}>CodeB.<AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Des.B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>UnidadB.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>CantidadB.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Cant.Max&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Cant.MaxCliente&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              {/* <th>Deste&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Hasta&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>PorCada&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th> */}
              <th>Stock&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Entregado&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>PorEntregar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'>
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info"  bt_funct={handleOpenGeneral}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />

                        </td>
                        <td className='td2' style={{ position: 'relative', zIndex: '2' }} >
                          <AsyncSelect
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            id="selectprinr"
                            name="form-field-name"
                            className="from-control form-control-sm "
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo?.Name}</td>
                        <td className='td4'>{slcArticulo?.Unidad}</td>
                        <td className='td5'>
                          <input type="text" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')} />
                        </td>
                        <td className='td6'>
                          <input type="number" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')} />
                        </td>
                        <td className='td7'>
                          <input type="number" id="txt_det3" name="txt_det3" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet3} onChange={handleFieldTabChange('txtDet3')} />
                        </td>
                        <td className='td8'>
                          <input type="number" id="txt_det4" name="txt_det4" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet4} onChange={handleFieldTabChange('txtDet4')} />
                        </td>
                        <td className='td9'>
                          <input type="number" id="txt_det5" name="txt_det5" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet5} onChange={handleFieldTabChange('txtDet5')} disabled/>
                        </td>
                        <td className='td10'>
                          <input type="number" id="txt_det6" name="txt_det6" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet6} onChange={handleFieldTabChange('txtDet6')} disabled/>
                        </td>
                        <td className='td11'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="BorrarDatosFile" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " ic_class="bx bx-ms bx-no-entry" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'>
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info"  bt_funct={handleOpenGeneral}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                    <td className='td2' style={{ position: 'relative', zIndex: '2' }} >
                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        id="selectprinr"
                        name="form-field-name"
                        className="from-control form-control-sm "
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo?.Name}</td>
                    <td className='td4'>{slcArticulo?.Unidad}</td>
                    <td className='td5'>
                      <input type="text" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')} />
                    </td>
                    <td className='td6'>
                      <input type="number" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')} />
                    </td>
                    <td className='td7'>
                      <input type="number" id="txt_det3" name="txt_det3" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet3} onChange={handleFieldTabChange('txtDet3')} />
                    </td>
                    <td className='td8'>
                      <input type="number" id="txt_det4" name="txt_det4" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet4} onChange={handleFieldTabChange('txtDet4')} />
                    </td>
                    <td className='td9'>
                      <input type="number" id="txt_det5" name="txt_det5" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet5} onChange={handleFieldTabChange('txtDet5')} disabled/>
                    </td>
                    <td className='td10'>
                      <input type="number" id="txt_det6" name="txt_det6" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet6} onChange={handleFieldTabChange('txtDet6')} disabled/>
                    </td>
                    <td className='td11'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="BorrarDatosFile" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " ic_class="bx bx-ms bx-no-entry" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
       generalModal &&   <ModalDetallesBoniParStock
          hanOpenModal = {generalModal}
          hanClosModal = {handleCloseGeneral}
          onReturnData = {handleRetrunData}
          onDataEstras = {{"columns":columns,"url":'/apiParArticulos?vcodEmpresa='+cook_idEmp}}
      />
      }

    </>
  )
}




const BONAgrupacionReglas = () => {
  /** Inicio :> Datos para validar */
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cod = searchParams.get('cod')
  const tab = searchParams.get('tab');
  const tip = searchParams.get('tip');
  /** Fin :> Datos para validar */
  const [listDatos, setListDatos] = useState({
    txtCod: '',
    txtDBon: '',
    txtActi: '',
    txtFIni: new Date(),
    txtFFin: new Date(),
    txtBode: '',
    txtMayo: '',
    txtMerc: '',
    txttipi: 'N'
  })
  const [listDataUstMemori, setlistDataUstMemori] = useState({
    bceiniciodetallador: [],
    bceobsequiodetll: []
  })
  const [disabledb1, setdisabledb1] = useState(false);
  const [disabledb2, setdisabledb2] = useState(false);
  const [btnVisible1, setBtnVisible1] = useState(false);
  const [btnVisible2, setBtnVisible2] = useState(false);

  const [valBloq, setValBloq] = useState(false);
  const [validarPut, setValidarPut] = useState('0');
  const [valCopy, setValCopy] = useState(false);

  const handleFieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    setListDatos({
      ...listDatos,
      [fieldName]: value,
    });
  };

  const handleDateChange1 = (date) => {
    setListDatos({
      ...listDatos,
      txtFIni: date, // Actualiza el valor de la fecha seleccionada fecha inicio
    });
  };
  const handleDateChange2 = (date) => {
    setListDatos({
      ...listDatos,
      txtFFin: date, // Actualiza el valor de la fecha seleccionada fecha fin
    });
  };
  const handleKeyDown = async (e, cod) => {
    if ((e && e.key === 'Enter') || cod) {
      if (e) { e.preventDefault(); }

      Swal.fire({
        title: 'Procesando...',
        html: 'Se esta consultado datos.',
        didOpen: () => { Swal.showLoading() },
        customClass: { container: 'my-swal' },
        allowOutsideClick: false
      })
      const url = '/apiBagr'
      const body = { vcodEmpresa:cook_idEmp, vlCodigo: (cod) ? cod : listDatos.txtCod };
      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
      const response = await fetch(url, opPe);
      const resdatos = await response.json();
      //console.log("resdatos", resdatos);
      if (resdatos.length) {
        setBtnVisible1(true)
        setListDatos({
          ...listDatos,
          txtCod: (tip === "copy") ? '' : resdatos[0].txtCod,
          // txtCod: resdatos[0].txtCod,
          txtDBon: resdatos[0].txtDBon,
          txtActi: resdatos[0].txtActi,
          txtFIni: new Date(moment(resdatos[0].txtFIni, 'Y-M-D').format('YYYY-MM-DD HH:mm:ss')),
          txtFFin: new Date(moment(resdatos[0].txtFFin, 'Y-M-D').format('YYYY-MM-DD HH:mm:ss')),
          txtBode: resdatos[0].txtBode,
          txtMayo: resdatos[0].txtMayo,
          txtMerc: resdatos[0].txtMerc,
          txttipi: 'N'
        });
        setlistDataUstMemori({
          ...listDataUstMemori,
          bceiniciodetallador: resdatos[0]?.arrDetalle1,
          bceobsequiodetll: resdatos[0]?.arrDetalle2,
        })
        setBtnVisible2(true)
      } else {
        notify_warning('Codigo no existe en nuestra base de datos.');
        onClickLimpiar()
      }
      Swal.close();

    }
  };
  const handleDataFromChild = (id, data) => {
    setlistDataUstMemori({
      ...listDataUstMemori,
      [id]: data
    });
  }
  const onClickActualizar = async () => {
    setdisabledb2(true)
    const url = '/apiBagrCabecera'
    const body = { vcodEmpresa:cook_idEmp, arrCabecera: listDatos };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const resdatos = await response.json();
    if (resdatos.vl_api === 1) {
      notify_warning("Error al actualizar, verificar datos!")
    }
    if (resdatos.vl_existe === 1) {
      notify_warning("Ya esta actualizado!")
    }
    if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1) {
      notify_valido("Se actualizo correctamente!")
    }
    setdisabledb2(false)
  }
  const validarDescripcion = async (descrip, namUdo) => {
    try {
      // Esta funcion valida si la descripcion de la bni ya fue resgistrada, con el fin de porder 
      // evitar duplicados
      // Retorna verdadero si la descripcion es unica
      setdisabledb2(true)
      const url = '/apiConsulDescripBoD'
      const body = {
        vcodEmpresa: cook_idEmp,
        vNomUdo: namUdo,
        vDesUdo: descrip
      };
      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
      const response = await fetch(url, opPe);
      const resdatos = await response.json();
      const cantidad = resdatos[0].Count
      if (parseInt(cantidad) === 0) {
        return true
      }
      // console.log("Res ::  [validarDescripcion ] :: ", cantidad);
      return false;

    } catch (error) {
      notify_error('No se pudo validar la Descripcion de la bonificacion')
      return false;
    }
  }
  const onclickCrearG = async () => {
    try {

      // Verificar si el campo está vacío
      if (listDatos.txtDBon.trim() === "") {
        notify_warning('Campo descripción es obligatorio, no debe estar vacío.');
        return;
      }
      /**const descripcionValida = await validarDescripcion(listDatos.txtDBon.trim(), 'AB_DBARC');
      if (descripcionValida === false) {
        notify_warning("Ya se ha registrado una bonificación con esta descripción.");
        return;
      }**/
      setdisabledb1(true)
      if (
          listDatos.txtDBon !== "" && listDatos.txtActi !=="" && 
          listDatos.txtBode !=="" && listDatos.txtMayo !=="" && 
          listDatos.txtMerc !==""
        ) {
        if (
          (listDataUstMemori.bceiniciodetallador.length >= 1) ||
          (listDataUstMemori.bceobsequiodetll.length >= 1)
        ) {
          if (listDataUstMemori.bceobsequiodetll.length >= 1) {
            const url = '/apiBagrInsert'
            const body = { vcodEmpresa: cook_idEmp, arrCabecera: listDatos, arrDetalle: listDataUstMemori, validarPut: validarPut };
            const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
            const response = await fetch(url, opPe);
            const resdatos = await response.json();
            if (resdatos.vl_api === 1) {
              notify_warning("Error al registrar, verificar datos!")
            }
            if (resdatos.vl_existe === 1) {
              notify_warning("Ya esta registrado!")
            }
            if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1) {
              notify_valido("Se registro correctamente!")
              setBtnVisible1(true)
              setBtnVisible2(true)
              if (tip === "copy") {
                setValCopy(false)
                updateParUrl('edit', tab, resdatos.vl_codres)
              } else {
                setListDatos(() => ({
                  ...listDatos,
                  txtCod: resdatos.vl_codres
                }));
              }
            }
          } else { notify_warning('Se requiere obsequio.'); }
        } else {
          notify_warning('En niguno de los tabs hay filas agregadas, por favor agregar minimo 1 fila en un tab.');
        }
      } else {
        if(listDatos.txtDBon==="") { notify_warning('Campo descripción es obligatorio, no debe estar vacio.');}
        if(listDatos.txtActi==="") { notify_warning('Seleccione Activo');}
        if(listDatos.txtBode==="") { notify_warning('Seleccione Bodegas');}
        if(listDatos.txtMayo==="") { notify_warning('Seleccione Mayoritas');}
        if(listDatos.txtMerc==="") { notify_warning('Seleccione Mercados');}
      }
      setdisabledb1(false)
    } catch(e){
      console.log("error", e);
      notify_error('Error, comunicarse con la area de TI');
      setdisabledb1(false)
    }
  }
  const onClickLimpiar = async () => {
    setListDatos({
      txtCod: '',
      txtDBon: '',
      txtActi: '',
      txtFIni: new Date(),
      txtFFin: new Date(),
      txtBode: '',
      txtMayo: '',
      txtMerc: '',
      txttipi: 'N'
    })
    setlistDataUstMemori({
      bceiniciodetallador: [],
      bceobsequiodetll: []
    })
    setBtnVisible1(false)
    setBtnVisible2(false)
    notify_valido("Campos de cabecera y tablas de tab se limpiaron correctamente!")
  }
  const updateParUrl = (etip, etab, ecod) => {
    const newParams = { tip: etip, tab: etab, cod: ecod };
    navigate({
      pathname: window.location.pathname,
      search: '?' + new URLSearchParams(newParams).toString()
    });
  }

  const [disabledEdit, setDisabledEdit] = useState(false)
  useEffect(() => {
    if (cod && tab === 'bonagrupacionreglas') {
      setTimeout(() => {
        setListDatos(() => ({
          ...listDatos,
          txtCod: cod
        }));
      }, 1000)

      if (tip === "ver") {
        setValBloq(true)
        $("input").attr("disabled", true)
        $("select").attr("disabled", true)
      } else {
        setValBloq(false)
      }
      if (tip === "edit") { setDisabledEdit(true) }


      setTimeout(() => {
        (tip === "copy") ? setValCopy(true) : setValCopy(false)
        handleKeyDown(null, cod, tip);
      }, 2000)
    }

  }, [cod, tab])

  const onHandleVPut = (dato) => {
    setValidarPut(dato)
  }

  return (
    <>
      <div className="row ">
        <div className="col-md-1">Codigo:</div>
        <div className="col-md-3">
          <input type="text" id="txtCod" name="txtCod" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtCod} onChange={handleFieldChange('txtCod')} onKeyDown={handleKeyDown} disabled/>
        </div>
        <div className="col-md-1">D.Bonif:</div>
        <div className="col-md-7">
          <input type="text" id="txtDescr" name="txtDescr" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtDBon} onChange={handleFieldChange('txtDBon')} />
        </div>

      </div>
      <div className="row ">
        <div className="col-md-1">Inicio:</div>
        <div className="col-md-3" style={{ position: 'relative', zIndex: '2' }}>
          <DatePicker
            id="txtFIni"
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center"
            selected={listDatos.txtFIni}
            onChange={handleDateChange1}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="col-md-1">Fin:</div>
        <div className="col-md-3" style={{ position: 'relative', zIndex: '2' }}>
          <DatePicker
            id="txtFFin"
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center"
            selected={listDatos.txtFFin}
            onChange={handleDateChange2}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="col-md-1">Activo:</div>
        <div className="col-md-3">
          <select id="txtActi" name="txtActi" className="form-select form-select-sm" value={listDatos.txtActi} onChange={handleFieldChange('txtActi')} disabled={disabledEdit} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-md-1">Bodega:</div>
        <div className="col-md-3">
          <select id="txtBode" name="txtBode" className="form-select form-select-sm" value={listDatos.txtBode} onChange={handleFieldChange('txtBode')} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>
        <div className="col-md-1">Mayorista:</div>
        <div className="col-md-3">
          <select id="txtMayo" name="txtMayo" className="form-select form-select-sm" value={listDatos.txtMayo} onChange={handleFieldChange('txtMayo')} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>
        <div className="col-md-1">Mercados:</div>
        <div className="col-md-3">
          <select id="txtMerc" name="txtMerc" className="form-select form-select-sm" value={listDatos.txtMerc} onChange={handleFieldChange('txtMerc')} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>
      </div>
      {
        valBloq ? <div className="d-none"></div> :
          <div className="row mb-2">
            <div className="col-lg-4"></div>
            <div className="col-lg-8 text-end">
              {
                btnVisible2 && (
                  <AButton id="btn_actualizar" tp_tilde="Actualizar Cabecera" bt_descr="Actualizar Cabecera" tp_posit="top" bt_class="btn btn-sm btn-label-success " bt_funct={onClickActualizar} ic_class="bx bx-ms bx-send" vl_tpbtn={2} disabled={disabledb2} />
                )
              }
              <AButton id="btn_LimpiarTod" tp_tilde="Limpiar campos y tablas" bt_descr="Limpiar" tp_posit="top" bt_class="btn btn-sm btn-label-secondary" bt_funct={onClickLimpiar} ic_class="bx bx-ms bx-eraser" vl_tpbtn={1} />
            </div>
          </div>
      }

      <div className="row ">
        <div className="nav-align-top">
          <ul className="nav nav-pills " role="tablist">
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 active" role="tab" data-bs-toggle="tab" data-bs-target="#bariniciodetallador" aria-controls="bariniciodetallador" aria-selected="true"><i className="tf-icons bx bx-run bx-xs" /> BAE Inicio </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target="#barobsequiodetll" aria-controls="barobsequiodetll" aria-selected="false"><i className="tf-icons bx bxs-package bx-xs" /> BAE Obsequio </button>
            </li>

          </ul>
          <div className="tab-content" style={{ 'height': '500px' }}>

            <div className="tab-pane fade show active" id="bariniciodetallador" role="tabpanel">
              <BARInicioDetalle
                arrCampDatos={listDatos}
                onDataUpdate={handleDataFromChild}
                listInitData={listDataUstMemori.bceiniciodetallador}
                onHandleVPut={onHandleVPut}
                onValCopyDat={valCopy}
                onValBloqueo={valBloq}
              />
            </div>

            <div className="tab-pane fade" id="barobsequiodetll" role="tabpanel">
              <BARObsequioDetalle
                arrCampDatos={listDatos}
                onDataUpdate={handleDataFromChild}
                listInitData={listDataUstMemori.bceobsequiodetll}
                onHandleVPut={onHandleVPut}
                onValCopyDat={valCopy}
                onValBloqueo={valBloq}
              />
            </div>



          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-4"></div>
        <div className="col-lg-8 text-end"> {/**  bt_funct={onClickModal} */}
          {btnVisible1 ?
            valCopy ?
              <AButton tp_tilde="Continuar" bt_descr="Continuar" tp_posit="top" bt_class="btn btn-sm btn-label-warning " ic_class="bx bx-ms bx-copy" vl_tpbtn={2} bt_funct={onclickCrearG} disabled={disabledb1} />
              :
              <AButton tp_tilde="Guardar nuevos registros" bt_descr="Guardar" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onclickCrearG} disabled={disabledb1} />
            :
            <AButton tp_tilde="Crear" bt_descr="Crear" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onclickCrearG} disabled={disabledb1} />
          }
        </div>
      </div>

    </>
  )
}

export default BONAgrupacionReglas