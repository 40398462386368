import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import { AButton } from "../../components/Button";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { createTheme, ThemeProvider } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { ToastContainer } from "react-toastify";
import { notify_valido, notify_warning } from "../../components/utils";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const globalTheme = window.templateCustomizer.settings.style;

export const COMTipoTabla = () => {
  const [lst, setlst] = useState([]);
  const [pending, setpending] = useState(false);
  const [jsonFinal, setJsonFinal] = useState({
    codigo: "",
    nombre: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);

  const columns = useMemo(() => [
    { header: 'Codigo', accessorKey: 'Code', },
    { header: 'Nombre', accessorKey: 'Name' },
  ], []);
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [],
  );
  const onClickResetData = () => {
    setJsonFinal({ codigo: "", nombre: "" });
    setIsDisabled(false);
  }
  const onClickGuardar = async () => {
    const dataForSend = {
      codigo: jsonFinal.codigo,
      nombre: jsonFinal.nombre.toUpperCase(),
    };
    if (!dataForSend.codigo || dataForSend.codigo.trim() === "") {
      notify_warning("El nombre del tipo de tabla no puede estar vacio");
      return;
    }
    if (!dataForSend.nombre || dataForSend.nombre.trim() === "") {
      notify_warning("El nombre del tipo de tabla no puede estar vacio");
      return;
    }
    dataForSend.vcodEmpresa=cook_idEmp
    const urlApi = `/saveTipoTabla`;
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataForSend) }
    const response = await fetch(urlApi, opPe);
    await response.json();
    notify_valido("Guardado");
    onClickConsult();
    onClickResetData();
    setIsDisabled(false);
  }
  const handleFieldChange = (fieldName) => (event) => {
    let value = event.target.value;
    setJsonFinal({
      ...jsonFinal,
      [fieldName]: value,
    });
  };
  const onClickConsult = async () => {
    setpending(true);
    const url_lstClientes = `/getTipoTabla?vcodEmpresa=${cook_idEmp}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    if (data.length === 0) {
      notify_warning('Sin sedes registradas');
    }
    setlst(data);
    setpending(false);
  }
  const onClickEdit = (row) => {
    setJsonFinal({
      codigo: row.Code,
      nombre: row.Name
    });
    setIsDisabled(true);
  }
  return (
    <>
      <ThemeProvider theme={tableTheme}>
        <h6 className="text-muted">Tipo tabla</h6>
        <div className="card card-action">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12 text-justify p-2 ">
                <AButton tp_tilde="Consultar información" id="btn1" tp_posit="top" bt_class="btn btn-label-youtube btn-sm"
                  ic_class="bx bx-cloud-download bx-ms" vl_tpbtn={2} bt_funct={onClickConsult}
                />&nbsp;
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-start col-md-9 col-sm-12">
                  <div className="col">
                    <div className="d-flex">
                      Codigo
                    </div>
                    <div className="col-10">
                      <input type="text" className="form-control form-control-sm" value={jsonFinal.codigo} onChange={handleFieldChange('codigo')} disabled={isDisabled} />
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex">
                      Nombre
                    </div>
                    <div className="col-10">
                      <input type="text" className="form-control form-control-sm" value={jsonFinal.nombre} onChange={handleFieldChange('nombre')} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-4" align="right">
                  <AButton tp_tilde="Guardar Tipo de mesa" id="btn1" tp_posit="top" bt_class="btn btn-label-success btn-sm"
                    bt_funct={onClickGuardar}
                    ic_class="bx bxs-save bx-ms" vl_tpbtn={2}
                  />&nbsp;
                  <AButton tp_tilde="Reinicar información" id="btn1" tp_posit="top" bt_class="btn btn-label-warning btn-sm"
                    bt_funct={onClickResetData}
                    ic_class="bx bx-revision bx-ms" vl_tpbtn={2}
                  />&nbsp;
                </div>
              </div>
            </div>
            <div className="card-datatable table-responsive">
              <MaterialReactTable
                columns={columns}
                data={lst}
                state={{ isLoading: pending }}
                initialState={{
                  density: 'compact', showColumnFilters: false,
                  showGlobalFilter: false,
                  columnVisibility: { clave: false, vlSema: false, },
                }}
                enableGrouping
                enableStickyHeader enableColumnResizing
                muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                localization={MRT_Localization_ES}
                enablePagination={true}
                muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                enableGlobalFilterModes
                rowVirtualizerProps={{ overscan: 150 }}
                enableRowVirtualization
                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    header: 'Options',
                    size: 120,
                  },
                }}
                enableRowActions
                renderRowActions={({ row }) => (
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                    <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-label-warning btn-icon btn-sm" ic_class="bx bxs-edit bx-ms" bt_funct={() => onClickEdit(row.original)}
                      id={JSON.stringify(row.original)} vl_tpbtn={1} />
                  </Box>
                )}
                muiCircularProgressProps={{ color: 'error' }}
                muiSkeletonProps={{
                  animation: 'pulse',
                  height: 28,
                }}
              />
            </div>
          </div>
        </div>
      </ThemeProvider>
      <ToastContainer />

    </>
  );
}

export default COMTipoTabla;
