export  const apiAutinCuotas = async(body) => {
  const url ='/apiAutoinCuotas'+body; 
  const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}
export  const apiSubirArchivoDatosExcel = async(body) => {
  const url ='/apiSubirArchivoDatosExcel'; 
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}