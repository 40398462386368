//import React, { useEffect, useState, useMemo } from 'react'
import React, { useState, useMemo } from 'react'
import { MaterialReactTable } from 'material-react-table';
import 'react-data-table-component-extensions/dist/index.css';
import { notify_warning, notify_valido, notify_error } from '../../components/utils';
import { AButton } from '../../components/Button';
import { ToastContainer } from 'react-toastify';
import $ from 'jquery'
import Swal from 'sweetalert2';
//import { Box, Button } from '@mui/material';

import { Box, createMuiTheme, ThemeProvider } from '@mui/material';
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales'
import Cookies from 'universal-cookie';
import AsyncSelect from 'react-select/async';
import '../../App.css';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const globalTheme = window.templateCustomizer.settings.style;

//Desarrollado por Nils Purizaca :,D
//Gracias Chicos <3

const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};

export const MGRGestion = () => {
  const [list_gestion, setList] = useState([]);
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [filterColor, setFilterColor] = useState('');
  const [pending, setPending] = useState(false);
  const emp_changeSl_t_suc = (event) => { emp_setSl_t_suc(event.target.value); }
  const [rowSelection, setRowSelection] = useState({});
  const onClickListar = async () => {
    //alert("btn onClickListar ")
    setRowSelection([])
    setPending(true)
    const url = '/apilistGestionPBase'
    const body = { vcodEmpresa: cook_idEmp, vcodSucursal: emp_sl_t_suc };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    setList(data);
    setPending(false)
  }

  

  const columns3 = useMemo(() => [

    { header: 'Fecha', accessorKey: 'DocDate', Cell: ({ row }) => (<span>{(row.original.DocDate).substring(0, 10)}</span>) },
    { header: 'Codigo', accessorKey: 'ItemCode', width: "2rem" },
    { header: 'Costo Anterior', accessorKey: 'Acosto', width: "10rem" },
    {
      header: 'Nuevo Costo',
      accessorKey: 'Ncosto',
      width: "10rem",
      filterVariant: 'select',
      Filter: ({ column }) => (
        <select className="form-select form-select-sm" onChange={(e) => setFilterColor(e.target.value)}>
          <option value="">Todos</option>
          <option value="yellow">Amarillo</option>
          <option value="green">Verde</option>
          <option value="red">Rojo</option>
        </select>
      ),
      Cell: ({ row }) => (
        <span style={{ color: getColor(row.original.Ncosto, row.original.Acosto, row.original?.marguen) }}>
          {row.original.Ncosto}
        </span>
      ),
    },
    { header: '% Variación', accessorKey: 'marguen', width: "10rem" },
    { header: 'Producto', accessorKey: 'Dscription' },
    { header: 'Unidad de Negocio:', accessorKey: 'UnidadNegocio' },
    
    {
      header: 'Validar', accessorKey: 'validar',
      filterVariant: 'select',
      Filter: ({ column }) => (
        <select className="form-select form-select-sm" onChange={(e) => setFilterColor(e.target.value)}>
          <option value="">Todos</option>
          <option value="raaaa">Varia </option> {/* asi funciona, dejalo ahí xD */}
          <option value="yellow">Sin Variacion</option>
        </select>
      ),
      Cell: ({ row }) => (
        <span style={{ color: row.original?.marguen === '0' ? 'green' : 'red' }}>
          {row.original?.marguen === '0' ? 'Sin Variacion' : row.original?.marguen}
        </span>
      ),
    },
    { header: 'Unidad', accessorKey: 'InvntryUom' },
  
    { header: 'CantIngresada', accessorKey: 'Cantidad', width: "10rem", Cell: ({ row }) => (<span>{parseFloat(row.original.Cantidad)}</span>) },
    { header: 'Stock', accessorKey: 'stock', width: "10rem", Cell: ({ row }) => (<span>{parseFloat(row.original.stock)}</span>) },
    { header: 'Fecha Ejecutado', accessorKey: 'ejecutado', Cell: ({ row }) => (<span>{(row.original.ejecutado).substring(0, 10)}</span>) },
    { header: 'Sucursal', accessorKey: 'PrcName', width: "2rem" },
    { header: 'Num. Entrada de Mercancías:', accessorKey: 'DocNum' },


  ], [],);


  const getColor = (Ncosto, Acosto, marguen) => {
    if (marguen === '0') {
      return 'yellow';
    } else if (Ncosto > Acosto) {
      return 'green';
    } else if (Ncosto < Acosto) {
      return 'red';
    } else {
      return '';
    }
  };

  const filteredData = filterColor ? list_gestion.filter(item => {
    if (filterColor === "yellow") {
      return getColor(item.Ncosto, item.Acosto, item?.marguen) === filterColor;
    } else if (filterColor === "raaaa") {
      return getColor(item.Ncosto, item.Acosto, item?.marguen) !== "yellow";
    } else if (filterColor === "green") {
      return getColor(item.Ncosto, item.Acosto, item?.marguen) === filterColor;
    } else if (filterColor === "red") {
      return getColor(item.Ncosto, item.Acosto, item?.marguen) === filterColor;
    }
  }) : list_gestion;

  const customRowStyles = (row) => {
    return {
      backgroundColor: row.original.validar === 1 ? 'rgba(255, 54, 33, 0.18)' : '',
    };
  };
  const onClickLimpiar = async (row) => {
    try {
      setList([])
      setRowSelection([])
    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }
  const fuEnvioActP = async (cook_idEmp,sl_t_suc,updatedRows) => {
    const url2 = '/apiUpdatePreciosBase'
    const Body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, vdtarray: updatedRows };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
    const resp = await fetch(url2, opPe);//cambiar por proveedores
    const data = await resp.json();
    return data;
    // if (data.vl_update === 0) {
    //   notify_warning("¡Error al modificar, intente mas tarde.!")
    // } else {
    //   console.log("No entra en ninguna de las condiciones");
    // }

  }
  const arrDataProcesadaP = async(cook_idEmp,sl_t_suc,arrDataListaSec) =>{
    const batchSize = 50;
    const promises = []
    for (let i = 0; i < arrDataListaSec.length; i += batchSize) {
        const batch = arrDataListaSec.slice(i, i + batchSize);
        let respd = fuEnvioActP(cook_idEmp,sl_t_suc,batch)
        promises.push(respd);
    }
    const resultados = await Promise.all(promises);
    return resultados.flat();

  }
  //const [valiActualiza, setValiActualiza] = useState(false);
  const onClickActualizar = (table, row) => {
    //alert("btn actualizar ")

    const selectedRFile = [];
    const selectedRows = table.getSelectedRowModel().rows;
    if (selectedRows.length) {
      
   
      //console.log("selectedRows => ", selectedRows);
      let valiActualiza = false;
      selectedRows.forEach((row) => {
        if (row.original?.marguen === '0') {
          valiActualiza = true
          notify_warning("El codigo " + row.original?.ItemCode + " no tiene variación, no es necesario actualizar.!")
        }
        selectedRFile.push(row.original)
      })
      if (!valiActualiza) {
        console.log("entra a hacer el actualizado por todos tiene margen mayor que cero");
        Swal.fire({
          title: 'ADVERTENCIA',
          text: '¿Desea actualizar precios base?',
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
          customClass: { container: 'my-swal' }
        }).then(async (result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Procesando...',
              html: 'Se esta consultado datos.',
              customClass: {
                container: 'my-swal'
              },
              didOpen: () => { Swal.showLoading() },
              allowOutsideClick: false
            })
            console.log("procede ha relizar el enviado de la lista de los datos => ", selectedRFile);
            // Masiveact(selectedRFile);
            const resdato = await arrDataProcesadaP(cook_idEmp,emp_sl_t_suc,selectedRFile)
            console.log("arrDataProcesadaP - resdato -> ", resdato);
            if (resdato.length) {
              setTimeout(() => {
                Swal.close()
                notify_valido("Migración exitosa")
                setRowSelection({});
                onClickListar()
              }, 2000);
            } else {
              notify_warning("¡Error al actualizar, intente mas tarde.!")
            }
            Swal.close()

          }
        })

      }
    }else{
      notify_warning("No a seleccionado ninguna fila.")
    }
  };
  return (
    <>

      <div className="row mb-2 ">
        <div className="col-lg-1">Sucursal:</div>
        <div className="col-lg-2">
          <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
        </div>
        <div className="col-lg-3">
          <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={onClickListar}>
            <i className='bx bx-list-ul' ></i>&nbsp;Consultar
          </button>&nbsp;
          <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        
        </div>
        <div className="col-6 d-flex flex-row-reverse ">
                {/* <div className="p-2">Elemento A</div>
                <div className="p-2">Elemento B</div>
                <div className="p-2">Elemento C</div> */}
          {/* <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={onClickListar}>
            <i className='bx bx-list-ul' ></i>&nbsp;Consultar
            </button>&nbsp;
          <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
         */}
        </div>
      </div>

      <div className="card-body ">
        <MaterialReactTable
          className="tabla-resumen3"
          columns={columns3}
          data={filteredData}
          state={{ rowSelection, isLoading: pending, }}
          initialState={{ density: 'compact', showGlobalFilter: false }}
          enableStickyHeader
          muiTableContainerProps={{ sx: { maxHeight: '300px' } }}
          renderTopToolbarCustomActions={({ table, row }) => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
              <AButton tp_tilde="Actualizar Precios"
                tp_posit="top" bt_class="btn btn-sm btn-info "
                bt_descr="Actualizar"
                ic_class="bx bx-ms bx-sync"
                bt_funct={() => onClickActualizar(table, row)}
                vl_tpbtn={1} />
              {/* <button
                className="btn btn-sm btn-info"
                //disabled={!table.getIsSomeRowsSelected()}bt_funct={() => onClickMidalDetalle(row)} 
                onClick={() => {
                  alert("hola Actualizar")
                }}
                variant="contained"
              ><i className='bx bx-sync' id="icon_veh_btn_guardar" ></i>
                Actualizar
              </button> */}
            </Box>
          )}
          enableRowSelection
          enableColumnResizing //expandir hacia la izquiera o derecha de la columna
          muiSelectCheckboxProps={{ color: 'error' }}
          getRowId={(row) => row.userId}
          onRowSelectionChange={setRowSelection}

          muiToolbarAlertBannerChipProps={{ color: 'error' }}
          customRowStyles={customRowStyles}
          enableGrouping
          muiTableBodyCellProps={{ color: 'error', sx: { p: '2px 16px', } }}
          // sx: {
          //   backgroundColor: 'white',
          //   borderRight: '1px solid rgba(224,224,224,1)'
          // }
          //}}
          muiTableHeadCellProps={{
            color: 'error'
            // align: 'center',
            // sx: theme => ({
            //   background: 'rgba(52, 210, 235, 0.1)',
            //   borderRight: '1px solid rgba(224,224,224,1)',
            //   color: theme.palette.text.primary
            // })
          }}
          muiTopToolbarProps={{
            sx: {
              '& .Mui-ToolbarDropZone': {
                border: '1px solid red',
                zIndex: '2 !important',
                // color: 'white', //color texto
                backgroundColor: '#d93519',
                color: 'white'
              }
            }
          }}
          positionToolbarAlertBanner="bottom"
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
        />
      </div>
    </>
  )
}

export const MGRActualizar = () => {

  const [emp_lst, setEmp_lst] = useState([]);
  const [draggingColumn, setDraggingColumn] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [veh_sl_t_proveedor, setVehSl_t_proveedor] = useState([""]);
  // const [filterColor, setFilterColor] = useState('');
  const [pending2, setPending2] = useState(false);
  const [sorting, setSorting] = useState([]);
  
  const [filterSiRevision, setFilterSiRevision] = useState('');
  const [filterEditMargenes, setFilterEditMargenes] = useState(''); // filtro para aquellas filas que tinen cambio en sus margnes 
   // existiran dos tipo de filtro 
   // 1- por unidad de negocio  2- por mergen o revision 
  const [tipofilter, setTipofiltern] = useState(1);
  const [filtro, setfiltro] = useState('unidadN');

  const onChangeFilter = (e) => {
    (e === 'unidadN') ?  setTipofiltern(1) : setTipofiltern(2)
    setfiltro(e)
  }
  const veh_Select2_proveedor = async (inputValue) => {

    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;

  }

  
  const emp_changeSl_t_suc = (event) => { emp_setSl_t_suc(event.target.value); }

  const Consultarbtn = async () => {
    if(emp_sl_t_suc === '' ){
      notify_warning('Seleccione la sucursal.');
      return
    }
    if(tipofilter==='unidadN' && veh_sl_t_proveedor.Code=== undefined  ){
      // condicion en caso se selcione por la unidad 
      notify_warning('Seleccione la unidad de negocio.');
      return
    }

    setPending2(true)
    setRowSelection([]);
    const codigo = veh_sl_t_proveedor.Code;
    $("#tablareact").removeClass("d-none");
    $("#InsertMasiv").removeClass("d-none");
    $("#error").addClass("d-none");
    //const ga=$("#txtproduc").val();
    
    const url = '/apilistActualizacionG'
    const uneg = codigo
    const body = { vcodEmpresa: cook_idEmp, uneg: uneg, vcodSucursal: emp_sl_t_suc , tipofilter:tipofilter, filtro:filtro};
    console.log("vcodEmpresa:", body.vcodEmpresa);
    console.log("uneg:", body.uneg);
    console.log("vcodSucursal:", body.vcodSucursal);
    console.log("tipofilter:", body.tipofilter);
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    if (Array.isArray(data) && data.length > 0) {
      // Data es un array con al menos un elemento
      setEmp_lst(data);
      console.log( data);
    } else {
      setEmp_lst([]);
      // Manejar el caso cuando la respuesta no es un array o está vacía
      console.warn("La respuesta de la API no contiene datos esperados:", data);
    }
    // console.log("DATA: ", data);
    // console.log("data de respuesta ", data);
    // setEmp_lst(data); 
    setPending2(false)
  }

 

  // useEffect(() => {
  //   //console.log(rowSelection)
  // }, [rowSelection]);


  const Calcular = (event) => {

    const rowData = event;
    const info = rowData.original
    const Costo = parseFloat(info.Costo)
    const fMAYASIG = parseFloat(info.fMAYASIG)
    const fBODASIG = parseFloat(info.fBODASIG)
    const fMERASIG = parseFloat(info.fMERASIG)

    // const resul1 = (Costo / (1 - (fMAYASIG / 100))).toFixed(3)
    // const resul2 = (Costo / (1 - (fBODASIG / 100))).toFixed(3)
    // const resul3 = (Costo / (1 - (fMERASIG / 100))).toFixed(3)


    const resul1 = (Costo + (Costo * (fMAYASIG / 100))).toFixed(3)
    const resul2 = (Costo + (Costo * (fBODASIG / 100))).toFixed(3)
    const resul3 = (Costo + (Costo * (fMERASIG / 100))).toFixed(3)

    const MAYCRE = ((resul1) * 1.02).toFixed(2); //Mayorista Contable
    const BODCRE = ((resul2) * 1.02).toFixed(2); //Mayorista Contable
    const MERCRE = ((resul3) * 1.02).toFixed(2); //Mayorista Contable
    //console.log('MAYCON',redo)


    const resultado = {
      ...info,
      MAYCON: resul1,
      BODCON: resul2,
      MERCON: resul3,
      MAYCRE: MAYCRE,
      BODCRE: BODCRE,
      MERCRE: MERCRE,
      revision: 'N',
    };

    const resultadoArray = [resultado]; // Coloca el objeto resultado dentro de un arreglo

    //console.log('fila calculada', resultadoArray);
    setEmp_lst(resultadoArray)


  }

  const Actual = async (event) => {

    Swal.fire({
      title: 'ADVERTENCIA',
      text: '¿Desea confirmar la actualización?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      customClass: {
        container: 'my-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        //console.log(selectedRows)
        notify_valido("Migración exitosa")
        //setSelectedRows('')
        //$("#btn_actual").addClass("d-none");

        const dtLlegada = JSON.parse(event.target.id);
        const info = dtLlegada.original
        //console.log(info)
        const resultado = {
          ...info
        };
        console.log(resultado)

        const url3 = '/apiUpdateMasivaV2'
        const Body = { vcodEmpresa: cook_idEmp, vcodSucursal: emp_sl_t_suc, vdtarray: resultado };
        const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
        const resp = await fetch(url3, opPe);//cambiar por proveedores
        const data = await resp.json();

        if (data.vl_update === 0) {
          notify_warning("¡Error al modificar, intente mas tarde.!")
        } else {
          console.log("No entra en ninguna de las condiciones");
        }
        setRowSelection({});
        //ua
      }
    })



  }


  const columns3 = useMemo(() => [
    {
      header: 'Revisión',
      accessorKey: 'revision',
      filterVariant: 'select',
      width: "10rem",
      Filter: ({ column }) => (
        <select className="form-select form-select-sm" onChange={(e) => setFilterSiRevision(e.target.value)}>
          <option value="">Todos</option>
          <option value="Y">Revision</option>
          <option value="N">Sin Revision</option>
        </select>
      ),
      Cell: ({ row }) => (
        <span style={{ color: getColor(row.original.revision) }}>
          <input className="form-check-input" type="checkbox" checked={(row.original.revision === 'Y') ? 'checked' : ''} id={JSON.stringify(row)} onChange={(e) => handleCheck(e, row)} />
          {/* {row.original.revision} */}
        </span>
      ),
    },
    {
      header: 'V.Margen',
      accessorKey: 'Cambio Margenes',
      filterVariant: 'select',
      width: "10rem",
      Filter: ({ column }) => (
        <select className="form-select form-select-sm" onChange={(e) => setFilterEditMargenes(e.target.value)}>
          <option value="">Todos</option>
          <option value="Y">Cambio margenes</option>
          <option value="N">Sin Cambio  en Margenes</option>
        </select>
      ),
      Cell: ({ row }) => (
        <span style={{ color: getColor(row.original.revision) }}>
          <div className="form-check form-check-success">
            <input className="form-check-input form-check-success" type="checkbox" checked={( row.original.margen=== 'Y') ? 'checked' : ''} id={JSON.stringify(row)}  contentEditable={false} />
            {/* {row.original.revision} */}
          </div>
          
        </span>
      ),
    },
    // { header: 'margen', accessorKey: 'margen' },
    { header: 'Codigo', accessorKey: 'codigo' },
    { header: 'Descripcion', accessorKey: 'nombre' },
    { header: 'Unidad de Medida', accessorKey: 'unidad' },
    { header: 'May.Contado+IGV,', accessorKey: 'MAYCON', Cell: ({ row }) => (<span>{parseFloat(row.original.MAYCON).toFixed(3)}</span>) },
    // { header: 'May.Credito', accessorKey: 'MAYCRE', Cell: ({ row }) => (<span>{parseFloat(row.original.MAYCRE).toFixed(3)}</span>) },
    { header: 'Bod.Contado+IGV,', accessorKey: 'BODCON', Cell: ({ row }) => (<span>{parseFloat(row.original.BODCON).toFixed(3)}</span>) },
    // { header: 'Bod.Credito', accessorKey: 'BODCRE', Cell: ({ row }) => (<span>{parseFloat(row.original.BODCRE).toFixed(3)}</span>) },
    { header: 'Mer.Contado+IGV,', accessorKey: 'MERCON', Cell: ({ row }) => (<span>{parseFloat(row.original.MERCON).toFixed(3)}</span>) },
    // { header: 'Mer.Credito', accessorKey: 'MERCRE', Cell: ({ row }) => (<span>{parseFloat(row.original.MERCRE).toFixed(3)}</span>) },
    { header: 'Stock', accessorKey: 'Stock', Cell: ({ row }) => (<span>{parseFloat(row.original.Stock).toFixed(3)}</span>), },
    { header: 'Costo+IGV', accessorKey: 'Costo', Cell: ({ row }) => (<span>{parseFloat(row.original.Costo).toFixed(3)}</span>), },
    // {
    //   header: 'M.MayC.', accessorKey: 'fMAYCON', 
    //   muiTableBodyCellProps: ({ row, cell }) => ({ 
    //     sx: { 
    //       backgroundColor: '#91F77A',
    //       fontWeight: handleRow(row)
    //     } 
    //   })
    //   // Cell: ({row}) => (//parseFloat(row.original.fMAYCON).toFixed(3)
    //   //   <input type="number" className="form-control form-control-sm" value={row.original.fMAYCON} onChange={(e) => handleInputChange(e, row)} />
    //   // ),
    // },
    // {
    //   header: 'M.MinC.', 
    //   accessorKey: 'fBODCON', 
    //   muiTableBodyCellProps: ({ row, cell }) => ({ 
    //     sx: { 
    //       backgroundColor: '#91F77A',
    //       fontWeight: handleRow(row)
    //     } 
    //   })
    //   // Cell: ({row}) => (
    //   //   <input type="number" className="form-control form-control-sm" value={row.original.fBODCON} onChange={(e) => handleMinoristaC(e, row)} />
    //   // ),

    // },
    // {
    //   header: 'M.MerC.', 
    //   accessorKey: 'fMERCON', 
    //   muiTableBodyCellProps: ({ row, cell }) => ({ 
    //     sx: { 
    //       backgroundColor: '#91F77A',
    //       fontWeight: handleRow(row)
    //     } 
    //   })
    //   // Cell: ({row}) => (
    //   //   <input type="number" className="form-control form-control-sm" value={row.original.fMERCON} onChange={(e) => handleMercadoC(e, row)} />
    //   // ),
    // },/** Inicio Nuevos campos de asignados */
    {
      header: 'M.MayAsig.', accessorKey: 'fMAYASIG', 
      muiTableBodyCellProps: ({ row, cell }) => ({ 
        sx: { 
          backgroundColor: '#7B9DFF',
          fontWeight: handleRow(row)
        } 
      })
      // Cell: ({row}) => (//parseFloat(row.original.fMAYCON).toFixed(3)
      //   <input type="number" className="form-control form-control-sm" value={row.original.fMAYASIG} onChange={(e) => handleMayAsigC(e, row)} />
      // ),
    },
    {
      header: 'M.MinAsig.', 
      accessorKey: 'fBODASIG', 
      muiTableBodyCellProps: ({ row, cell }) => ({ 
        sx: { 
          backgroundColor: '#7B9DFF',
          fontWeight: handleRow(row)
        } 
      })
      // Cell: ({row}) => (
      //   <input type="number" className="form-control form-control-sm" value={row.original.fBODASIG} onChange={(e) => handleMinAsigC(e, row)} />
      // ),
    },
    {
      header: 'M.MerAsig.', 
      accessorKey: 'fMERASIG', 
      muiTableBodyCellProps: ({ row, cell }) => ({ 
        sx: { 
          backgroundColor: '#7B9DFF',
          fontWeight: handleRow(row)
        } 
      })
      // Cell: ({row}) => (
      //   <input type="number" className="form-control form-control-sm" value={row.original.fMERASIG} onChange={(e) => handleMerAsigC(e, row)} />
      // ),
    },
    /** Inicio Nuevos campos de asignados */
    {
      header: 'Opción',
      Cell: ({ row }) => (
        <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-warning" id={JSON.stringify(row)} onClick={() => Calcular(row)} ><i className='bx bxs-calculator' id={JSON.stringify(row)} ></i>Calcular</button>
          &nbsp;&nbsp;
          <button type="button" className="btn btn-sm btn-success d-none " id={JSON.stringify(row)} onClick={Actual}  ><i className='bx bx-sync' id={JSON.stringify(row)}></i>Actualizar</button>
        </div>
      ),
    },
    // {
    //   header: 'Comentarios', accessorKey: 'comentarios',
    //   Cell: ({ row }) => (
    //     <input type="text" className="form-control form-control-sm" />
    //   ),
    // },

    // eslint-disable-next-line
  ], [emp_lst],);

  const getColor = (revision) => {
    if (revision === 'Y') {
      return 'revision1';
    } else if (revision === 'N') {
      return 'a';
    } else {
      return '';
    }
  };
  
  // eslint-disable-next-line
  // const filteredData = filterColor ? emp_lst.filter(item => {
  //   console.log("item.revisionb:: ", item.revision);
  //   if (filterColor === "revision1") {
  //     return getColor(item.revision) === filterColor;
  //   } else if (filterColor === "a") {
  //     return getColor(item.revision) === filterColor;
  //   }
  // }) : emp_lst;
   function  funCalcularEditMargen (row)  {
    // console.log("row.fMAYCON: ", row);
    const a1 = row.fMAYCON?.toFixed(0)
    const a2 = row.fMAYASIG?.toFixed(0)
    const b1 = row.fBODCON?.toFixed(0)
    const b2 = row.fBODASIG?.toFixed(0)
    const c1 = row.fMERCON?.toFixed(0)
    const c2 = row.fMERASIG?.toFixed(0)
    var editMargen='N';
    if ((a1!==a2) && (a1!==0 && a2!==0) ) { editMargen = 'Y' }
    if ((b1!==b2) && (b1!==0 && b2!==0) ) { editMargen = 'Y' }
    if ((c1!==c2) && (c1!==0 && c2!==0) ) { editMargen = 'Y' }
    // console.log("editMargen: ", editMargen);
    return editMargen; 
  }

  const filteredData = ( filterEditMargenes || filterSiRevision ) ? emp_lst.filter(item => {
    // Esta funcion sirve para el filtrado de datos de la tabla 
    let margenMatch = true;
    if (filterEditMargenes === 'Y') {
        margenMatch = funCalcularEditMargen(item) === 'Y';
    } else if (filterEditMargenes === 'N') {
        margenMatch = funCalcularEditMargen(item) === 'N';
    } else if (filterEditMargenes !== '') {
        margenMatch = false;
    }

    // Manejar filtro para la revisión
    let revisionMatch = true;
    if (filterSiRevision === 'Y') {
        revisionMatch = item.revision === 'Y';
    } else if (filterSiRevision === 'N') {
        revisionMatch = item.revision === 'N';
    } else if (filterSiRevision !== '') {
        revisionMatch = false;
    }

    // Incluir el elemento solo si ambos filtros coinciden
    return margenMatch && revisionMatch;

    // if(item.revision===filterSiRevision )
    // if (filterSiRevision === 'Y') {
    //   return item.revision === 'Y';
    // } else if (filterColor === "a") {
    //   return item.revision === 'Y';
    // }
    
  }) : emp_lst;


  // const handleInputChange = (e, row) => {


  //   const value = e.target.value;
  //   setEmp_lst((prevEmp_lst) => {
  //     const nuevosDatos = [...prevEmp_lst];
  //     const newRow = nuevosDatos[row.index];
  //     newRow.fMAYCON = value;
  //     newRow.fMAYCRE = (value * 1.02).toFixed(2);
  //     return nuevosDatos;
  //   });
  //   //console.log("row.id", row.id, "e.target.value", e.target.value);

  //   ///setEmp_lst({...emp_lst, [row.id]: e.target.value });
  //   //console.log("e.target.value",e.target.value);
  //   // const value =  e.target.value
  //   // const nuevosDatos = [...emp_lst];
  //   // nuevosDatos[row.index] = {...nuevosDatos[row.index], fMAYCON: value,  };
  //   // nuevosDatos[row.index] = {...nuevosDatos[row.index], fMAYCRE: (value * 1.02).toFixed(2) };
  //   // setEmp_lst(nuevosDatos);

  //   // // const nuevosDatos = [...emp_lst];
  //   // // const value =  e.target.value
  //   // // nuevosDatos[row.index].fMAYCON = value;
  //   // // // console.log("nuevosDatos devuelta", nuevosDatos);
  //   // // setEmp_lst(nuevosDatos)


  //   // // setTimeout(() => {
  //   // //   const updatedData = [...emp_lst];
  //   // //   updatedData[row.index].fMAYCRE = (value * 1.02).toFixed(2);
  //   // //   setEmp_lst(updatedData);
  //   // // }, 1000);

  // };
  // const handleMinoristaC = (e, row) => {
  //   const nuevosDatos = [...emp_lst];
  //   const value = e.target.value

  //   nuevosDatos[row.index].fBODCON = value;
  //   // console.log("nuevosDatos devuelta", nuevosDatos);
  //   setEmp_lst(nuevosDatos)

  //   setTimeout(() => {
  //     const updatedData = [...emp_lst];
  //     updatedData[row.index].fBODCRE = (value * 1.02).toFixed(2);
  //     setEmp_lst(updatedData);
  //   }, 1000);

  // };

  // const handleMercadoC = (e, row) => {
  //   const nuevosDatos = [...emp_lst];
  //   const value = e.target.value
  //   nuevosDatos[row.index].fMERCON = value;
  //   setEmp_lst(nuevosDatos)


  //   setTimeout(() => {
  //     const updatedData = [...emp_lst];
  //     updatedData[row.index].fMERCRE = (value * 1.02).toFixed(2);
  //     setEmp_lst(updatedData);
  //   }, 1000);
  // };

  // const handleMercadoCre = (e, row) => {
  //   const nuevosDatos = [...emp_lst];
  //   const value =  e.target.value
  //   nuevosDatos[row.index].fMERCRE = value;
  //   setEmp_lst(nuevosDatos)
  // };


  const handleCheck = (e, row) => {

    const currentValue = row.original.revision;
    const newValue = currentValue === 'Y' ? 'N' : 'Y';
    //Para ver si se cambia el Y o N
    //console.log(newValue)
    const nuevosDatos = [...emp_lst];
    nuevosDatos[row.index].revision = newValue;
    setEmp_lst(nuevosDatos);

  }

  const onClickLimpiar = async (row) => {
    try {
      setEmp_lst([])
      setFilterSiRevision('')
      setFilterEditMargenes('')
      //setFilterColor([])
      setRowSelection([])
    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }

  
  /** Nuevos procesos de margen asigandos */

  // const handleMayAsigC = (e, row) => {
  //   const nuevosDatos = [...emp_lst];
  //   const value =  e.target.value

  //   nuevosDatos[row.index].fMAYASIG = value;
  //   setEmp_lst(nuevosDatos)

  // }
  // const handleMinAsigC = (e, row) => {
  //   const nuevosDatos = [...emp_lst];
  //   const value =  e.target.value

  //   nuevosDatos[row.index].fBODASIG = value;
  //   setEmp_lst(nuevosDatos)

  // }
  // const handleMerAsigC = (e, row) => {
  //   const nuevosDatos = [...emp_lst];
  //   const value =  e.target.value

  //   nuevosDatos[row.index].fMERASIG = value;
  //   setEmp_lst(nuevosDatos)

  // }
  const fuEnvioActA = async (cook_idEmp,sl_t_suc,updatedRows) => {
    const url3 = '/apiUpdateMasivaV2'
    const Body = { vcodEmpresa: cook_idEmp, vcodSucursal: emp_sl_t_suc, vdtarray: updatedRows };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
    const resp = await fetch(url3, opPe);//cambiar por proveedores
    const data = await resp.json();
    console.log("data de respuesta ", data);
    return data

    // if (data.vl_update === 0) {
    //   notify_warning("¡Error al modificar, intente mas tarde.!")
    // } else {
    //   console.log("No entra en ninguna de las condiciones");
    // }

  }
  const arrDataProcesadaA = async(cook_idEmp,sl_t_suc,arrDataListaSec) =>{
    const batchSize = 50;
    const promises = []
    for (let i = 0; i < arrDataListaSec.length; i += batchSize) {
        const batch = arrDataListaSec.slice(i, i + batchSize);
        let respd = fuEnvioActA(cook_idEmp,sl_t_suc,batch)
        promises.push(respd);
    }
    const resultados = await Promise.all(promises);
    return resultados.flat();

  }
  const actualizarMasivo = (table) => {
    //const selectedRFile = [];
    const selectedRows = table.getSelectedRowModel().rows;
    //selectedRows.forEach((row) => {
      Swal.fire({
        title: 'ADVERTENCIA',
        text: '¿Desea continuar con la Actualización?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        customClass: {
          container: 'my-swal'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          // eslint-disable-next-line
          // selectedRows.map((row) => {
          //   selectedRFile.push(row.original)
          //   setRowSelection({});
          // })
          const updatedRows = selectedRows.map((row) => {
            return {
              ...row.original,
            };
          });
          console.log(updatedRows);

          // notifyUPDATEMASIV_valido("Actualización exitosa")
          // //API Actualizacion Masiva------
          //UPDATEMASIV(updatedRows);
          // //API Actualizacion Masiva------
          const resdato = await arrDataProcesadaA(cook_idEmp,emp_sl_t_suc,updatedRows)
          console.log("arrDataProcesadaA - resdato -> ", resdato);
          if (resdato.length) {
            notify_valido("Actualización exitosa")
            setTimeout(() => {
              setRowSelection({});
              Consultarbtn();
            }, 2000);
          } else {
            notify_warning("¡Error al actualizar, intente mas tarde.!")
          }
          Swal.close()
        }
      })

    //});
    //console.log(selectedRFile);
   
  }
  const calcularMasivo = (table) => {
    const selectedRFile = [];
    const selectedRows = table.getSelectedRowModel().rows;
    //alert("ingresaa")
    // if ($("#Mmayorista").val() === '' || $("#Mminoristac").val() === '' || $("#Mmercado").val() === '') {
    //   notify_warning("faltan datos")

    // } else {

      selectedRows.forEach((row) => {
        Swal.fire({
          title: 'ADVERTENCIA',
          text: '¿Desea continuar?',
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
          customClass: {
            container: 'my-swal'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            // eslint-disable-next-line
            selectedRows.map((row) => {
              selectedRFile.push(row.original)
              //setRowSelection({});

            })

            console.log("selectedRows", selectedRows);
            const updatedRows = selectedRows.map((row) => {

              const rowData = row;
              const info = rowData.original;
              const Costo = parseFloat(info.Costo)
              const fMAYASIG = parseFloat(info.fMAYASIG)
              const fBODASIG = parseFloat(info.fBODASIG)
              const fMERASIG = parseFloat(info.fMERASIG)
              // const redo = (Costo / (1 - (fMAYASIG / 100))).toFixed(3)
              // const redo2 = (Costo / (1 - (fBODASIG / 100))).toFixed(3)
              // const redo3 = (Costo / (1 - (fMERASIG / 100))).toFixed(3)


              const redo = (Costo + (Costo * (fMAYASIG / 100))).toFixed(3)
              const redo2 = (Costo + (Costo * (fBODASIG / 100))).toFixed(3)
              const redo3 = (Costo + (Costo * (fMERASIG / 100))).toFixed(3)

              const MAYCRE = ((redo) * 1.02).toFixed(2); //Mayorista Contable
              const BODCRE = ((redo2) * 1.02).toFixed(2); //Mayorista Contable
              const MERCRE = ((redo3) * 1.02).toFixed(2); //Mayorista Contable
              //console.log('MAYCON',redo)


              return {
                ...info,
                MAYCON: redo,
                BODCON: redo2,
                MERCON: redo3,
                MAYCRE: MAYCRE,
                BODCRE: BODCRE,
                MERCRE: MERCRE,
                revision: 'N',
              };
              // const fMAYCONValue = parseFloat($("#Mmayorista").val());
              // const fBODCONValue = parseFloat($("#Mminoristac").val());
              // const fMERCONValue = parseFloat($("#Mmercado").val());
              // const Costo = row.original.Costo;
              // //const revision= row.original.revision

              // const MAYCON = (Costo / (1 - (fMAYCONValue / 100))).toFixed(3);
              // const BODCON = (Costo / (1 - (fBODCONValue / 100))).toFixed(3);
              // const MERCON = (Costo / (1 - (fMERCONValue / 100))).toFixed(3);

              // const MAYCRE = ((MAYCON) * 1.02).toFixed(2);
              // const BODCRE = ((BODCON) * 1.02).toFixed(2);
              // const MERCRE = ((MERCON) * 1.02).toFixed(2);

              // const fMAYCRE = (fMAYCONValue * 1.02).toFixed(2);
              // const fBODCRE = (fBODCONValue * 1.02).toFixed(2);
              // const fMERCRE = (fMERCONValue * 1.02).toFixed(2);

              // return {
              //   ...row.original,
              //   fMAYCON: $("#Mmayorista").val(),
              //   fBODCON: $("#Mminoristac").val(),
              //   fMERCON: $("#Mmercado").val(),
              //   fMAYCRE: fMAYCRE,
              //   fBODCRE: fBODCRE,
              //   fMERCRE: fMERCRE,

              //   MAYCON: MAYCON,
              //   BODCON: BODCON,
              //   MERCON: MERCON,

              //   MAYCRE: MAYCRE,
              //   BODCRE: BODCRE,
              //   MERCRE: MERCRE,

              //   revision: 'N',

              // };

            });

            console.log(updatedRows);
            setEmp_lst(updatedRows)
            notify_valido("Actualización exitosa")

          }
        })

      });

    //}

    console.log('selectedRFile: ', selectedRFile);

  };
  // const actualizarMasivo = (table) => {
  //   const selectedRFile = [];
  //   const selectedRows = table.getSelectedRowModel().rows;

  //   if ($("#Mmayorista").val() === '' || $("#Mminoristac").val() === '' || $("#Mmercado").val() === '') {
  //     notify_warning("faltan datos")

  //   } else {

  //     selectedRows.forEach((row) => {
  //       Swal.fire({
  //         title: 'ADVERTENCIA',
  //         text: '¿Desea continuar?',
  //         icon: 'info',
  //         showCancelButton: true,
  //         cancelButtonText: 'Cancelar',
  //         confirmButtonText: 'Confirmar',
  //         customClass: {
  //           container: 'my-swal'
  //         }
  //       }).then(async (result) => {
  //         if (result.isConfirmed) {
  //           // eslint-disable-next-line
  //           selectedRows.map((row) => {
  //             selectedRFile.push(row.original)
  //             //setRowSelection({});

  //           })

  //           const updatedRows = selectedRows.map((row) => {

  //             const rowData = row;
  //             const info = rowData.original;
  //             const Costo = parseFloat(info.Costo)
  //             const fMAYASIG = parseFloat(info.fMAYASIG)
  //             const fBODASIG = parseFloat(info.fBODASIG)
  //             const fMERASIG = parseFloat(info.fMERASIG)
  //             const redo = (Costo / (1 - (fMAYASIG / 100))).toFixed(3)
  //             const redo2 = (Costo / (1 - (fBODASIG / 100))).toFixed(3)
  //             const redo3 = (Costo / (1 - (fMERASIG / 100))).toFixed(3)
  //             const MAYCRE = ((redo) * 1.02).toFixed(2); //Mayorista Contable
  //             const BODCRE = ((redo2) * 1.02).toFixed(2); //Mayorista Contable
  //             const MERCRE = ((redo3) * 1.02).toFixed(2); //Mayorista Contable
  //             //console.log('MAYCON',redo)


  //             return {
  //               ...info,
  //               MAYCON: redo,
  //               BODCON: redo2,
  //               MERCON: redo3,
  //               MAYCRE: MAYCRE,
  //               BODCRE: BODCRE,
  //               MERCRE: MERCRE,
  //               revision: 'N',
  //             };
  //             // const fMAYCONValue = parseFloat($("#Mmayorista").val());
  //             // const fBODCONValue = parseFloat($("#Mminoristac").val());
  //             // const fMERCONValue = parseFloat($("#Mmercado").val());
  //             // const Costo = row.original.Costo;
  //             // //const revision= row.original.revision

  //             // const MAYCON = (Costo / (1 - (fMAYCONValue / 100))).toFixed(3);
  //             // const BODCON = (Costo / (1 - (fBODCONValue / 100))).toFixed(3);
  //             // const MERCON = (Costo / (1 - (fMERCONValue / 100))).toFixed(3);

  //             // const MAYCRE = ((MAYCON) * 1.02).toFixed(2);
  //             // const BODCRE = ((BODCON) * 1.02).toFixed(2);
  //             // const MERCRE = ((MERCON) * 1.02).toFixed(2);

  //             // const fMAYCRE = (fMAYCONValue * 1.02).toFixed(2);
  //             // const fBODCRE = (fBODCONValue * 1.02).toFixed(2);
  //             // const fMERCRE = (fMERCONValue * 1.02).toFixed(2);

  //             // return {
  //             //   ...row.original,
  //             //   fMAYCON: $("#Mmayorista").val(),
  //             //   fBODCON: $("#Mminoristac").val(),
  //             //   fMERCON: $("#Mmercado").val(),
  //             //   fMAYCRE: fMAYCRE,
  //             //   fBODCRE: fBODCRE,
  //             //   fMERCRE: fMERCRE,

  //             //   MAYCON: MAYCON,
  //             //   BODCON: BODCON,
  //             //   MERCON: MERCON,

  //             //   MAYCRE: MAYCRE,
  //             //   BODCRE: BODCRE,
  //             //   MERCRE: MERCRE,

  //             //   revision: 'N',

  //             // };

  //           });

  //           console.log(updatedRows);
  //           setEmp_lst(updatedRows)
  //           notify_valido("Actualización exitosa")

  //         }
  //       })

  //     });

  //   }

  //   console.log('selectedRFile: ', selectedRFile);

  // };


  const handleRow = (row) => {
    //fMAYCON original.

    //fMAYASIG
    // console.log("Fila:: ", row);
    // const a1 = row.getValue('fMAYCON')?.toFixed(0)
    // const a2 = row.getValue('fMAYASIG')?.toFixed(0)
    // const b1 = row.getValue('fBODCON')?.toFixed(0)
    // const b2 = row.getValue('fBODASIG')?.toFixed(0)
    // const c1 = row.getValue('fMERCON')?.toFixed(0)
    // const c2 = row.getValue('fMERASIG')?.toFixed(0)

    const a1 = row.original.fMAYCON?.toFixed(0)
    const a2 = row.original.fMAYASIG?.toFixed(0)
    const b1 = row.original.fBODCON?.toFixed(0) 
    const b2 = row.original.fBODASIG?.toFixed(0)
    const c1 = row.original.fMERCON?.toFixed(0)
    const c2 = row.original.fMERASIG?.toFixed(0) 

    
    
    var bold;
    const resul1 = (parseFloat(row.original.Costo) + (parseFloat(row.original.Costo) * (a2 / 100))).toFixed(3);
    const resul2 = (parseFloat(row.original.Costo) + (parseFloat(row.original.Costo) * (b2 / 100))).toFixed(3);
    const resul3 = (parseFloat(row.original.Costo) + (parseFloat(row.original.Costo) * (c2 / 100))).toFixed(3);

    if (Number(resul1).toFixed(0) !== Number(row.original.MAYCON).toFixed(0)) { bold = 'bold' } 
    if (Number(resul2).toFixed(0) !== Number(row.original.BODCON).toFixed(0)) { bold = 'bold' } 
    if (Number(resul3).toFixed(0) !== Number(row.original.MERCON).toFixed(0)) { bold = 'bold' } 

    return bold;
  }
  const reportExcel = async() => {
    console.log("filteredData", filteredData);
   
    
    if(tipofilter===1  ){
      if(emp_sl_t_suc === '' ){
        notify_warning('Seleccione la sucursal.');
        return
      }
      if(veh_sl_t_proveedor.Code=== undefined ){
        notify_warning('Seleccione la sucursal.');
        return
      }
    }
    if(tipofilter===2  ){
      if(emp_sl_t_suc === '' ){
        notify_warning('Seleccione la sucursal.');
        return
      }
    }
    
    if (filteredData.length === 0) {
      notify_warning('Primero consulte.');
        return
    }
    const arrReport = []
    filteredData.map((item) =>{
      
      return arrReport.push({ 
        "sucursal":emp_sl_t_suc,
        "unidadNegocio":veh_sl_t_proveedor.Code,
        "codigo":item.codigo,
        "nombre":item.nombre,
        "unidad":item.unidad,
        "MAY_CON":parseFloat(item.MAYCON).toFixed(3),
        "BOD_CON":parseFloat(item.BODCON).toFixed(3),
        "MER_CON":parseFloat(item.MERCON).toFixed(3),
        "Stock":parseFloat(item.Stock).toFixed(3),
        "Costo":parseFloat(item.Costo).toFixed(3),
        "fMAYASIG":item.fMAYASIG,
        "fBODASIG":item.fBODASIG,
        "fMERASIG":item.fMERASIG
      })
     
    })

    
    

    const ws = XLSX.utils.json_to_sheet(arrReport);
    const wb = {Sheets:{data:ws}, SheetNames:["data"]};
    const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
    const data = new Blob([excelBuffer], {type:"xlsx"});
    FileSaver.saveAs(data, `reportListaPrecios_${new Date()}.xlsx`);
  }


  return (
    <>

      <div className="card-body">
        <div className='row mb-3'>
        <div className="col-lg-2"> Filtrar por</div>

        <div className="col-lg-3">
          <select className="form-select form-select-sm" onChange={(e) => onChangeFilter(e.target.value)}>
          <option value="unidadN"> Unidad  de negocio </option>
            <option value="revision"> En Revision</option>
            {/* <option value="margen"> Margenes </option> */} 
          </select>
        </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-lg-8">
            <div className="row">
              {(filtro === 'unidadN') &&

                (
                  <>
                    <div className="col-lg-3">Unidad de Negocio</div>
                    <div className="col-lg-4" id="comboUN" style={{ position: 'relative' }} >
                      <AsyncSelect
                        id="veh_sl2_chofer"
                        styles={styles}
                        placeholder="Busque por Unidad de Negocio"
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm my-swal"
                        value={veh_sl_t_proveedor}
                        cacheOptions
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={veh_Select2_proveedor}
                        onChange={setVehSl_t_proveedor}
                      // isClearable={true}
                      //openMenuOnClick
                      //isDisabled={disabled}
                      />
                    </div> </>
                )
              }

              <div className="col-lg-2">Sucursal:</div>
              <div className="col-lg-3">
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
            </div>

          </div>

          {/* <div class="col-lg-4 d-flex flex-row-reverse" >
            <div className="row"> */}
          <div className="col-lg-4 text-end" >
            <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={Consultarbtn}>
              <i className='bx bx-list-ul' ></i>&nbsp;Consultar
            </button>&nbsp;
            <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            <AButton tp_tilde="Exportar en Excel" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success mx-1" ic_class="bx bx-ms bxs-file-export" vl_tpbtn={2}
               bt_funct={reportExcel}
              />
          </div>
          {/* </div>
          </div> */}
        </div>
        

        {/* <div className="card-body d-none " id="InsertMasiv">
          <div className="row">
            <div className="col-lg-2">Marg. Mayorista C.:</div>
            <div className="col-lg-2">
              value={veh_txt_marca} onChange={changeVeh_txt_marca}
              <input type="number" id="Mmayorista" name="Mmayorista" className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-lg-2">Marg. Minorista C.:</div>
            <div className="col-lg-2">
              value={veh_txt_marca} onChange={changeVeh_txt_marca}
              <input type="number" id="Mminoristac" name="Mminoristac" className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-lg-2">Marg. Mercado C.:</div>
            <div className="col-lg-2">
              value={veh_txt_marca} onChange={changeVeh_txt_marca}
              <input type="number" id="Mmercado" name="Mmercado" className="form-control form-control-sm" autoComplete='off' />
            </div>
          </div>
        </div> */}
      </div>

      <div className="card-body d-none" id="tablareact" >
        <div className="card-datatable table-responsive" >
          <MaterialReactTable
            className="actualizaM"
            columns={columns3}
            data={filteredData}
            enableStickyHeader
            state={{ draggingColumn, rowSelection, isLoading: pending2, sorting }}
            initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, pagination: { pageSize: 50 } }}
            muiTableContainerProps={{ sx: { maxHeight: '300px' } }}
            enableRowSelection
            enableColumnResizing //expandir hacia la izquiera o derecha de la columna
            getRowId={(row) => row.codigo}
            renderTopToolbarCustomActions={({ table, row }) => (
              <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                <button className="btn btn-sm btn-success" variant="contained"
                  onClick={() => actualizarMasivo(table)}
                >
                  <i className='bx bx-ms bx-sync'></i>Actualizar
                </button>
                <button className="btn btn-sm btn-warning" variant="contained"
                  // disabled={!table.getIsSomeRowsSelected()}
                  onClick={() => calcularMasivo(table)}
                >
                  <i className='bx bx-ms bxs-calculator'></i>Calculo Masivo
                </button>
              </Box>
            )}
            onRowSelectionChange={setRowSelection}
            muiSelectCheckboxProps={{ color: 'error' }}
            localization={!draggingColumn ? { dropToGroupBy: 'Arrastre un encabezado de columna para agrupar por esa columna' } : undefined}
            muiTopToolbarProps={{
              sx: {
                '& .Mui-ToolbarDropZone': {
                  border: '1px solid red',
                  zIndex: '2 !important',
                  // color: 'white', //color texto
                  backgroundColor: '#d93519',
                  color: 'white'
                }
              }
            }}
            onDraggingColumnChange={setDraggingColumn}
            positionToolbarAlertBanner="bottom"

            muiToolbarAlertBannerChipProps={{ color: 'primary' }}
            enableGrouping
            muiTableBodyCellProps={({ row }) => ({ 
              color: 'error', 
              sx: { 
                p: '2px 16px', 
                fontWeight: handleRow(row)
              }  
            })}
            muiTableHeadCellProps={{
              color: 'error',
              //align: 'center',
              //sx: theme => ({
              //background: 'rgba(255, 99, 71, 0.2)',
              //borderRight: '1px solid rgba(224,224,224,1)',
              //color: 'theme.palette.text.primary'
              //})
            }}
            onSortingChange={setSorting}
            // enablePagination={false}
            // enableBottomToolbar={false}
            rowVirtualizerProps={{ overscan: 8 }}
            enableRowVirtualization
            muiTablePaginationProps={{
              rowsPerPageOptions: [50, 100, 200],
              showFirstButton: false,
              showLastButton: false,
            }}
            muiCircularProgressProps={{ color: 'error' }}
            muiSkeletonProps={{
              animation: 'pulse',
              height: 28,
            }}
            // muiTableBodyRowProps={({ row }) => ({
            //   sx: { 
                
            //     backgroundColor: (row.getValue('codigo')==='0470001') ? '#91F77A' : '',
            //     fontStyle: (row.getValue('codigo')==='0470001') ? 'italic' : '',

            //   },
              
            // })}
          />
        </div>
      </div>
      <div className="misc-wrapper text-center d-none" id="error">

        <div className="row mb-4">
          <div className="col-md-12">
            <div className="mb-2 mx-2">No hay datos disponibles</div>
            {/* <p className="mb-4 mx-2">Perdón por las molestias, pero estamos realizando algunos tareas de mantenimiento en este momento.</p> */}
          </div>
        </div>
      </div>
    </>
  )

}

export const MGRMargenes = () => {

  const [emp_lst, setEmp_lst] = useState([]);
  const [draggingColumn, setDraggingColumn] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [veh_sl_t_proveedor, setVehSl_t_proveedor] = useState([""]);
  const [filterColor, setFilterColor] = useState('');
  const [pending2, setPending2] = useState(false);
  const [btnVisible, setBtnVisible] = useState(false);
  const [sorting, setSorting] = useState([]);


  const veh_Select2_proveedor = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;
  }

  const emp_changeSl_t_suc = (event) => { emp_setSl_t_suc(event.target.value); }

  const Consultarbtn = async () => {
    setPending2(true)
    setRowSelection([]);
    const codigo = veh_sl_t_proveedor.Code;
    $("#tablareact2").removeClass("d-none");
    $("#InsertMasiv2").removeClass("d-none");
    $("#error2").addClass("d-none");
    //const ga=$("#txtproduc").val();
    const url = '/apiListMargenes'
    const uneg = codigo
    const body = { vcodEmpresa: cook_idEmp, uneg: uneg, vcodSucursal: emp_sl_t_suc };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    // console.log("data de respuesta ", data);
    setEmp_lst(data);
    setPending2(false)
    setBtnVisible(false)
  }

  const columns3 = useMemo(() => [

    { header: 'Codigo', accessorKey: 'codigo' },
    { header: 'Descripcion', accessorKey: 'nombre' },
    { header: 'Unidad de Medida', accessorKey: 'unidad' },
    // { header: 'Categoria', accessorKey: 'Categoria' },
    { header: 'Stock', accessorKey: 'Stock', Cell: ({ row }) => (<span>{parseFloat(row.original.Stock).toFixed(3)}</span>), },
    {
      header: 'M.May.', accessorKey: 'MAY-CON', muiTableBodyCellProps: ({ cell }) => ({ sx: { backgroundColor: '#91F77A' } }),
      Cell: ({ row }) => (//parseFloat(row.original.fMAYCON).toFixed(3)
        <input type="number" className="form-control form-control-sm" value={row.original['MAY-CON']} onChange={(e) => handleInputChange(e, row)} />
      ),
    },
    {
      header: 'M.Min.', accessorKey: 'BOD-CON', muiTableBodyCellProps: ({ cell }) => ({ sx: { backgroundColor: '#91F77A' } }),
      Cell: ({ row }) => (
        <input type="number" className="form-control form-control-sm" value={row.original['BOD-CON']} onChange={(e) => handleMinoristaC(e, row)} />
      ),

    },
    {
      header: 'M.Mer.', accessorKey: 'MER-CON', muiTableBodyCellProps: ({ cell }) => ({ sx: { backgroundColor: '#91F77A' } }),
      Cell: ({ row }) => (
        <input type="number" className="form-control form-control-sm" value={row.original['MER-CON']} onChange={(e) => handleMercadoC(e, row)} />
      ),
    },
    {
      header: 'Comentarios', accessorKey: 'comentarios',
      Cell: ({ row }) => (
        <input type="text" className="form-control form-control-sm" value={row.original?.comentarios} onChange={(e) => handleChangeComentarios(e, row)} />
      ),
    },
    {
      header: 'Opción',
      Cell: ({ row }) => (
        <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-success" id={JSON.stringify(row)} onClick={() => onClickActualizarPorFila(row.original)}  ><i className='bx bx-sync' id={JSON.stringify(row)}></i>Actualizar</button>
        </div>
      ),
    },

    // eslint-disable-next-line
  ], [emp_lst],);

  const urlUpdate = '/apiUpMargenes';
  const onClickActualizarPorFila = async (event) => {
    console.log('siuuu', event);

    Swal.fire({
      title: 'ADVERTENCIA',
      text: '¿Desea confirmar la actualización?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      customClass: {
        container: 'my-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        //console.log(selectedRows)
        Swal.fire({
          title: 'Procesando...',
          html: 'Se estan enviando datos, se espera una respuesta.',
          didOpen: () => { Swal.showLoading() },
          customClass: { container: 'my-swal' },
          allowOutsideClick: false
        })

        // const dtLlegada = JSON.parse(event.target.id);
        const datosFila = event;

        const Body = { vcodEmpresa: cook_idEmp, vcodSucursal: emp_sl_t_suc, vdtarray: [datosFila] };
        const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
        const resp = await fetch(urlUpdate, opPe);//cambiar por proveedores
        const data = await resp.json();
        if (data.vl_rsp === 1) {
          notify_valido("Actualización exitosa")
        } else {
          notify_warning("¡Error al actualizar, intente mas tarde.!")
        }
        Swal.close()

        setRowSelection({});
      }
    })



  }
  const fuEnvioActM = async(cook_idEmp,sl_t_suc,validarUActivos) => {
    const Body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, vdtarray: validarUActivos };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
    const response = await fetch(urlUpdate, opPe);//cambiar por proveedores
    const resdatos = await response.json();
    return resdatos;
  }
  const arrDataProcesadaM = async(cook_idEmp,sl_t_suc,arrDataListaSec) =>{
    const batchSize = 50;
    const promises = []
    for (let i = 0; i < arrDataListaSec.length; i += batchSize) {
        const batch = arrDataListaSec.slice(i, i + batchSize);
        let respd = fuEnvioActM(cook_idEmp,sl_t_suc,batch)
        promises.push(respd);
    }
    const resultados = await Promise.all(promises);
    return resultados.flat();

  }


  const onClickActualizarMasivo = async (table) => {
    console.log("filteredData", filteredData);


    const selectedRows = table.getSelectedRowModel().rows;

    Swal.fire({
      title: 'ADVERTENCIA',
      text: '¿Desea continuar con la Actualización?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      customClass: {
        container: 'my-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {

        Swal.fire({
          title: 'Procesando...',
          html: 'Se estan enviando datos, se espera una respuesta.',
          didOpen: () => { Swal.showLoading() },
          customClass: { container: 'my-swal' },
          allowOutsideClick: false
        })

        const updatedRows = selectedRows.map((row) => {
          return {
            ...row.original,
          };
        });


        //API Actualizacion Masiva------
        // const Body = { vcodEmpresa: cook_idEmp, vcodSucursal: emp_sl_t_suc, vdtarray: updatedRows };
        // const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
        // const resp = await fetch(urlUpdate, opPe);//cambiar por proveedores
        // const data = await resp.json();
        // console.log("data de respuesta ", data);
        const resdato = await arrDataProcesadaM(cook_idEmp,emp_sl_t_suc,updatedRows)
        console.log("arrDataProcesadaM - resdato -> ", resdato);
        //if (resdato.vl_rsp === 1) {
        if (resdato.length) {
          notify_valido("Actualización exitosa")
          setTimeout(() => {
            setRowSelection({});
            Consultarbtn();
            setBtnVisible(false)
          }, 1000);

          $("#Mmayorista2").val('')
          $("#Mminoristac2").val('')
          $("#Mmercado2").val('')

        } else {
          notify_warning("¡Error al actualizar, intente mas tarde.!")
        }
        Swal.close()
      }
    })




  }


  const getColor = (revision) => {
    if (revision === 'Y') {
      return 'revision1';
    } else if (revision === 'N') {
      return 'a';
    } else {
      return '';
    }
  };
  // eslint-disable-next-line
  const filteredData = filterColor ? emp_lst.filter(item => {
    if (filterColor === "revision1") {
      return getColor(item.revision) === filterColor;
    } else if (filterColor === "a") {
      return getColor(item.revision) === filterColor;

    }
  }) : emp_lst;

  const handleInputChange = (e, row) => {


    const value = e.target.value;
    setEmp_lst((prevEmp_lst) => {
      const nuevosDatos = [...prevEmp_lst];
      const newRow = nuevosDatos[row.index];
      newRow['MAY-CON'] = value;
      return nuevosDatos;
    });


  };
  const handleMinoristaC = (e, row) => {
    const nuevosDatos = [...emp_lst];
    const value = e.target.value

    nuevosDatos[row.index]['BOD-CON'] = value;
    // console.log("nuevosDatos devuelta", nuevosDatos);
    setEmp_lst(nuevosDatos)


  };

  const handleMercadoC = (e, row) => {
    const nuevosDatos = [...emp_lst];
    const value = e.target.value
    nuevosDatos[row.index]['MER-CON'] = value;
    setEmp_lst(nuevosDatos)

  };

  const handleChangeComentarios = (e, row) => {
    const nuevosDatos = [...emp_lst];
    const value = e.target.value
    nuevosDatos[row.index]['comentarios'] = value
    // nuevosDatos[row.index]['MER-CON'] = value;
    setEmp_lst(nuevosDatos)

  };



  const onClickLimpiar = async (row) => {
    try {
      setEmp_lst([])
      //setFilterColor([])
      setRowSelection([])
      setBtnVisible(false)
    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }



  const handleAsignarMasivo = async (table) => {
    if ($("#Mmayorista2").val() === '' || $("#Mminoristac2").val() === '' || $("#Mmercado2").val() === '') {
      notify_warning("faltan datos")

    } else {
      const selectedRows = table.getSelectedRowModel().rows;

      if (selectedRows.length) {
        Swal.fire({
          title: 'ADVERTENCIA',
          text: '¿Desea confirmar la actualización?',
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
          customClass: {
            container: 'my-swal'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            const updatedRows = selectedRows.map((row) => {
              return {
                ...row.original,
                'MAY-CON': $("#Mmayorista2").val(),
                'BOD-CON': $("#Mminoristac2").val(),
                'MER-CON': $("#Mmercado2").val()
              }
            })
            setBtnVisible(true)
            setEmp_lst(updatedRows)
          }
        })
      } else {
        notify_warning("Selecione uno o todas las filas que quiera asignar nuevos margenes")
      }

    }


  }


  return (
    <>

      <div className="card-body">
        <div className="row">
          <div className="col-lg-2">Unidad de Negocio</div>
          <div className="col-lg-3 my-swal" id="comboUN" style={{ position: 'relative', zIndex: '2' }} >
            <AsyncSelect
              id="veh_sl2_chofer"
              styles={styles}
              placeholder="Busque por Unidad de Negocio"
              name="form-field-name"
              className="sele_pru from-control form-control-sm my-swal"
              value={veh_sl_t_proveedor}
              cacheOptions
              defaultOptions
              getOptionLabel={e => e.Code + ' - ' + e.Name}
              getOptionValue={e => e.Code}
              loadOptions={veh_Select2_proveedor}
              onChange={setVehSl_t_proveedor}
            // isClearable={true}
            //openMenuOnClick
            //isDisabled={disabled}
            />
          </div>
          <div className="col-lg-1">Sucursal:</div>
          <div className="col-lg-2">
            <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
          </div>
          <div className="col-lg-4 text-center">
            <div className="text-center">
              <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={Consultarbtn}>
                <i className='bx bx-list-ul' ></i>&nbsp;Consultar
              </button>&nbsp;
              <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>
        <div className="card-body d-none " id="InsertMasiv2">
          <div className="row">
            <div className="col-lg-2">Marg. Mayorista C.:</div>
            <div className="col-lg-2">
              {/* value={veh_txt_marca} onChange={changeVeh_txt_marca} */}
              <input type="number" id="Mmayorista2" name="Mmayorista2" className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-lg-2">Marg. Minorista C.:</div>
            <div className="col-lg-2">
              {/* value={veh_txt_marca} onChange={changeVeh_txt_marca} */}
              <input type="number" id="Mminoristac2" name="Mminoristac2" className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-lg-2">Marg. Mercado C.:</div>
            <div className="col-lg-2">
              {/* value={veh_txt_marca} onChange={changeVeh_txt_marca} */}
              <input type="number" id="Mmercado2" name="Mmercado2" className="form-control form-control-sm" autoComplete='off' />
            </div>
          </div>
        </div>
      </div>

      <div className="card-body d-none" id="tablareact2" >
        <div className="card-datatable table-responsive" >
          <MaterialReactTable
            className="actualizaM"
            columns={columns3}
            data={filteredData}
            enableStickyHeader
            state={{ draggingColumn, rowSelection, isLoading: pending2, sorting }}
            initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, pagination: { pageSize: 50 } }}
            muiTableContainerProps={{ sx: { maxHeight: '300px' } }}
            enableRowSelection
            enableColumnResizing //expandir hacia la izquiera o derecha de la columna
            getRowId={(row) => row.codigo}
            renderTopToolbarCustomActions={({ table, row }) => (
              <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                {btnVisible && (<button className="btn btn-sm btn-success" variant="contained"

                  onClick={() => { onClickActualizarMasivo(table) }}

                >
                  <i className='bx bx-ms bx-sync'></i>Actualizar
                </button>)}
                <button className="btn btn-sm btn-warning" variant="contained"
                  // disabled={!table.getIsSomeRowsSelected()}
                  onClick={() => { handleAsignarMasivo(table) }}

                >
                  <i className='bx bx-ms bx-shuffle'></i>Asignar Masivo
                </button>
              </Box>
            )}
            onRowSelectionChange={setRowSelection}
            muiSelectCheckboxProps={{ color: 'error' }}
            localization={!draggingColumn ? { dropToGroupBy: 'Arrastre un encabezado de columna para agrupar por esa columna' } : undefined}
            muiTopToolbarProps={{
              sx: {
                '& .Mui-ToolbarDropZone': {
                  border: '1px solid red',
                  zIndex: '2 !important',
                  // color: 'white', //color texto
                  backgroundColor: '#d93519',
                  color: 'white'
                }
              }
            }}
            onDraggingColumnChange={setDraggingColumn}
            positionToolbarAlertBanner="bottom"

            muiToolbarAlertBannerChipProps={{ color: 'primary' }}
            enableGrouping
            muiTableBodyCellProps={{ color: 'error', sx: { p: '2px 16px', } }}
            muiTableHeadCellProps={{ color: 'error' }}
            onSortingChange={setSorting}
            // enablePagination={false}
            // enableBottomToolbar={false}
            rowVirtualizerProps={{ overscan: 8 }}
            enableRowVirtualization
            muiTablePaginationProps={{
              rowsPerPageOptions: [50, 100, 200],
              showFirstButton: false,
              showLastButton: false,
            }}
            muiTableBodyRowProps={({row}) => ({
              sx: { //row.getValue('Glotes') === "Y" &&
                backgroundColor: ( row.getValue('comentarios') ) ? 'rgba(255, 99, 71, 0.2)' : '',
              }
            })}
            muiCircularProgressProps={{ color: 'error' }}
            muiSkeletonProps={{
              animation: 'pulse',
              height: 28,
            }}
          />
        </div>
      </div>
      <div className="misc-wrapper text-center d-none" id="error2">

        <div className="row mb-4">
          <div className="col-md-12">
            <div className="mb-2 mx-2">No hay datos disponibles</div>
            {/* <p className="mb-4 mx-2">Perdón por las molestias, pero estamos realizando algunos tareas de mantenimiento en este momento.</p> */}
          </div>
        </div>
      </div>
    </>
  )

}


const GPActualizacionPrecios = () => {
  const tableTheme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [],
  );
  return (
    <ThemeProvider theme={tableTheme}>
      <div className="col-xl-12">
        <h6 className="text-muted d-none">GPActualizacionPrecios</h6>
        <div className="nav-align-top">
          <ul className="nav nav-pills mb-3 " role="tablist">
            <li className="nav-item">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-Actualizar" aria-controls="navs-pills-justified-Actualizar" aria-selected="true"><i className='bx bx bxs-purchase-tag-alt bx-xs' /> Gestión de Precios base </button>
            </li>
            <li className="nav-item">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-Escala" aria-controls="navs-pills-justified-Escala" aria-selected="false"><i className='bx bx bx-coin-stack bx-xs' /> Actualización Masiva</button>
            </li>
            <li className="nav-item">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-Margenes" aria-controls="navs-pills-justified-Margenes" aria-selected="false"><i className='bx bx-list-ul' /> Margenes</button>
            </li>
          </ul>
          <div className="tab-content">
            {/** --------- Gestion de Precios Base ----- */}
            <div className="tab-pane fade show active" id="navs-pills-justified-Actualizar" role="tabpanel">
              <MGRGestion />
            </div>
            {/** --------- Actualizacion Masiva ----- */}
            <div className="tab-pane fade  " id="navs-pills-justified-Escala" role="tabpanel">
              <MGRActualizar />
            </div>
            {/** --------- Margenes ----- */}
            <div className="tab-pane fade  " id="navs-pills-justified-Margenes" role="tabpanel">
              <MGRMargenes />
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </ThemeProvider>
  )
}

export default GPActualizacionPrecios