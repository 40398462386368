import React, { useState, useMemo, useEffect, useCallback , useRef} from 'react'
import Cookies from 'universal-cookie';
import { SelectSucursal, SelectTipo } from '../../components/Selects/SelectsPrincipales'
//selectTablaMultiple
import { notify_warning, notify_valido, selectTabla, removeSelectedRows, notify_warning_fijo, newDateFormate, newDateF } from '../../components/utils';
import $ from 'jquery'
import DataTable, { defaultThemes } from "react-data-table-component";
//import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
import '../../App.css';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { AButton } from '../../components/Button';
import { Box, Modal, Tooltip } from '@mui/material';
import Swal from 'sweetalert2';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
//import * as Apis from './funApis'
const moment = require('moment')
//import AsyncSelect from 'react-select/async';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const customStyles2 = {
  header: {
    style: { minHeight: '15px', },
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: defaultThemes.default.divider.default,
      minHeight: '15px',
      fontSize: '12px',
      fontWeight: '800',
    },
  },
  rows: {
    style: { minHeight: '15px', fontSize: '11px', },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        minHeight: '15px',
        fontSize: '11px',
      },
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        minHeight: '15px',
        fontSize: '11px',
      },
    },
  },
};

const CustomSelectCell = ({ row, cook_idEmp, updateData, sucursal, lstLiqui }) => {
  console.log("ingresa CustomSelectCell");
  const rowIndex = row.index;
  const selectedValue = row.getValue('responsable');
  const handleChange = async(option) => {
    //console.log("option.target.value", option.target.value);
    updateData(rowIndex, option ? option.target.value : null);
    const codCon = row.original.Codigo
    const codLiq = option.target.value
    await updateLiquidador(cook_idEmp,codCon,codLiq)
  };

  const updateLiquidador = async(cook_idEmp,codCon,codLiq) => {
    const url = `/apiAsigLiquidador?vcodEmpresa=${cook_idEmp}&vcodConsolidado=${codCon}&vcodLiquidador=${codLiq}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url, opPe);
    const data = await response.json();
    if (data === 'Success') {
      notify_valido("Se Asigno Responsable") 
    }
    console.log(data);
  }
  
  
  return (
    <select className="form-select form-select-sm" value={selectedValue} onChange={handleChange}>
      <option value="9999">--Seleccione--</option>
      {
        lstLiqui?.map((item, index) => {
          return <option value={item?.Code} key={index} >{item?.Name}</option>
        })
      }
    </select>
  )

}

const REPPreConsolidadoDeCarga = () => {
  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  
  /**   variables de carga de tabla  */
  const [pending, setPending] = useState(false); // variable de la tabla principal 
  const [pendingPedido, setPendingPedido] = useState(false);
  const [pendingDetallePed, setPendingDetallePed] = useState(false);
  const [pendingVehiculos, setPendingVehiculos] = useState(false);
  const [rowSelection, setRowSelection] = useState([]);
  /**Data de los modales  */
  const [dataPD, setdataDP] = useState([]);
  const [dataVehiculo, setdataVehiculo] = useState([]);
  const [dataPedidos, setdataPedidos] = useState([]);
  const [dataPedidoDetall, setdataPedidoDetall] = useState([]);

  const [sorting, setSorting] = useState([]);
  /** Cabecera */
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState(''); // sucursal 
  const [inp_text_fecha, setInp_text_fecha] = useState(new Date());
  //const [imp_estado, setImp_estado] = useState('')
  const [tipConsolidado, setTipConsolidado] = useState('')
  // Data de nueva de prueba 
  const [dataTablaConsol, setdataTablaConsol] = useState([]);



  /**Capturamos el codigo de la sede */
  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }

  const handleTipoConsolChange = (event) => {
    const value = event.target.value;
    setTipConsolidado(value);
  };

 


  /** 
   * ========================================
   * Inicio :> Listar datos de PreConsolidado 
   * ======================================== 
  **/
  const [disabledBotonTodosC, setDisabledBotonTodosC] = useState(false);
  const validarEstadoConsolidad = (arrData) => {
    for (let i = 0; i < arrData.length; i++) {
      if (arrData[i].Abierto !== 'NO') {
        return false;
      }
    }
    return true;
  }
  const onClickListarPreCon = async () => {
    setDisabledBotonTodosC(false)
    //setDisabledBtnCTC(false)
    document.getElementById('btn_procesar').disabled = "true";
    document.getElementById('btn_procesar_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_procesar_spinner').className = 'spinner-border';
    setPending(true)
    if (!emp_sl_t_suc || emp_sl_t_suc === "9999") {
      notify_warning(" Debes seleccionar una sucursal")
      setPending(false)

    } else {

      const url1 = '/apilistPlanificador'
      const Body = { vcodEmpresa: cook_idEmp, vcodSucursal: emp_sl_t_suc, formattedDate: formatDate(inp_text_fecha) };
      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
      //setPending(false)
      const response = await fetch(url1, opPe);
      const resdatos = await response.json();
      if (resdatos.vl_rsparr === 0) {
        notify_warning("¡No existen datos.!")
        setPending(false)
        setdataTablaConsol([])
        // setdataPreConsol([])
      } else {
        // const tableData = { columns, data: resdatos };
        setdataTablaConsol(resdatos)
        // setdataPreConsol(tableData)
        setDisabledBotonTodosC(validarEstadoConsolidad(resdatos))
        await listLiquidadores()
        setPending(false)
      }
      //console.log("Data de prueba :", dataTablaConsol);

      $(".tabla-resumen .rdt_Table").addClass("table");

    }
    document.getElementById('btn_procesar').disabled = "";
    document.getElementById('btn_procesar_icon').setAttribute('class', 'bx bx-cloud-download bx-ms');
    document.getElementById('btn_procesar_spinner').className = 'spinner-border d-none';
  };

  const formatDate = (date) => {
    // Formatear la fecha a yyyy-MM-dd
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  };
  const formatDateYYYY = (date) => {
    // Formatear la fecha a yyyy-MM-dd
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const funCerrarConsolidado = async (row) => {
    Swal.fire({
      title: 'ADVERTENCIA',
      text: '¿Desea cerrar consolidado?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      customClass: { container: 'my-swal' }
    }).then(async (result) => {
      if (result.isConfirmed) {
        const url1 = '/cerrarConsolidado'
        const Body = { vcodEmpresa:cook_idEmp, vcodigo: row.Codigo };
        const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
        const response = await fetch(url1, opPe);
        const resdatos = await response.json();
        console.log("resdatos", resdatos);
        if (resdatos.vl_res === 1) {
          notify_valido("¡Se proceso correctamente.!")
          onClickListarPreCon()
        } else { console.log("error "); }
        Swal.close()
      }
    })
  }
  /** listar liquidadores */
  const [lstLiqui, setLstLiqui] = useState([]);
  const listLiquidadores = async() => {
    const url = `/apiListaLiquidador?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url, opPe);
    const data = await response.json();
    console.log(data);
    setLstLiqui(data)
  }
  
  const columConsolidado = useMemo(() => [
    { header: 'Codigo', accessorKey: 'Codigo' },
    { header: 'Fecha', accessorKey: 'Fecha' },
    { header: 'TipoC.', accessorKey: 'TipoDesc' },
    { 
      header: 'EstadoC.', 
      //accessorKey: 'Abierto',
      Cell: ({ row }) => (
           (row.original.Abierto === "SI") ? 'ABIERTO' : 'CERRADO'
      )
    },
    { header: 'Sucursal', accessorKey: 'Sucursal' },
    { header: 'Vehiculo ', accessorKey: 'Vehiculo' },
    { header: 'Chofer', accessorKey: 'Chofer' },
    { header: 'CapacidadPeso', accessorKey: 'CapacidadPeso' },
    { header: 'Pedidos', accessorKey: 'Pedidos' },
    { header: 'Clientes', accessorKey: 'Clientes' },
    { header: 'TotalContado', accessorKey: 'TotalContado' },
    { header: 'TotalCredito', accessorKey: 'TotalCredito' },
    { header: 'IDUsuario', accessorKey: 'IDUsuario' },
    {
      header: 'Responsable',
      accessorKey: 'responsable',
      Cell: ({ row }) => (
        <>
          <div className="input-group">
          
          <CustomSelectCell row={row} cook_idEmp={cook_idEmp} updateData={updateData} sucursal={emp_sl_t_suc} lstLiqui={lstLiqui} />
        
          <button className="btn btn-sm btn-icon btn-label-twitter" onClick={handleOpenModapResp} > <i className='bx bx-xs bxs-file-plus'></i></button>
          </div>
        </>
      ),
      size: 200
    },
  ], [emp_sl_t_suc, lstLiqui]);

  const updateData = (rowIndex, selectedValue) => {
    console.log("selectedValue", selectedValue);
    setdataTablaConsol((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex].responsable = selectedValue;
      return updatedData;
    });
  };
  
  /** 
   * ========================================
   * Fin :> Listar datos de PreConsolidado 
   * ======================================== 
  **/



  /** ModalPRINCIPAL */
  const [codeConsolidado, setCodeConsolidado] = useState('');
  const [codeConsoliVali, setCodeConsoliVali] = useState('');
  const [openModalDP, setopenModalDP] = useState(false);
  const handleOpenPD = (row) => { 
    setopenModalDP(true); 
    onClickModalPD(row.Codigo)
    setCodeConsolidado(row.Codigo)
    setCodeConsoliVali(row.Abierto)
  };
  const handleClosePD = () => { setopenModalDP(false); };
  const [pendingPG, setPendingPG] = useState(false);
  const rowVirtualizerInstanceRef = useRef(null);
  const columnVirtualizerInstanceRef = useRef(null);
  const onClickModalPD = async(Code)=> {
    //console.log("code", Code);
    setPendingPG(true)
    const url_lstClientes = `/apiListarPD?vcodEmpresa=${cook_idEmp}&vperiodo=${formatDateYYYY(inp_text_fecha)}&vcodSucursal=${emp_sl_t_suc}&vCodigo=${Code}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    //console.log(data);
    setdataDP(data)
    setPendingPG(false)
  }


  const columnsPD = useMemo(() => [
    { header: 'Cod.Vehiculo', accessorKey: 'txtCodVehi' },
    { header: 'DocNum', accessorKey: 'txtNroDocu' },
    { header: 'Peso', accessorKey: 'txtPeso' },
    { header: 'Volumen', accessorKey: 'txtVolumen' },
    { header: 'Fecha', accessorKey: 'txtFDocume' },
    { header: 'Credito', accessorKey: 'txtCredito' },
    { header: 'Total', accessorKey: 'txtTotal' },
    { header: 'Codigo Ruta', accessorKey: 'txtCodRuta' },
    { header: 'codConsoli.', accessorKey: 'txtCodCons' },
    { header: 'CodCli', accessorKey: 'txtCodClie' },
    { header: 'Cliente', accessorKey: 'txtNomClie' },
    { header: 'CodResp.', accessorKey: 'txtCodResp' },
    { header: 'Responsable', accessorKey: 'txtNomResp' }
  ], []);


  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);



  // actulizar datos de vehiculos:: peso, carga, monto
  const actualizarTotalesVehiculos = (objVehiculo, pedidos) => {
    // buscar el objeto del vehiculo que cumple con el codigo que se recoje
    //console.log(objVehiculo);
    const selectVehiculo = dataVehiculo.find((obj) => obj.Code === objVehiculo.Code);
    //console.log("rowTab2 :: ", pedidos);
    const pedidosVehiculo = pedidos.filter((obj) => obj.CodigoVehiculo === objVehiculo.Code)
    //console.log("rowTab_222:: ", pedidosVehiculo);
    const totalSumado = pedidosVehiculo.reduce((sum, pedido) => sum + parseFloat(pedido.total), 0);
    const totalCreditoSumado = pedidosVehiculo.reduce((sum, pedido) => sum + parseFloat(pedido.credito), 0);
    const totalVolumenSumado = pedidosVehiculo.reduce((sum, pedido) => sum + parseFloat(pedido.volumen), 0);
    const totalPesoSumado =   pedidosVehiculo.reduce((sum, pedido) => sum + parseFloat(pedido.peso), 0);

    const cardCodes = new Set();// Los codigos son unicos, y|sabes cuantos clientes ese vehiculo 

    pedidosVehiculo.forEach(x => cardCodes.add(x.CardCode));
    selectVehiculo.TPedidos = pedidosVehiculo.length
    selectVehiculo.TClientes = cardCodes.size
    selectVehiculo.Total = Math.round(parseFloat(totalSumado) * 1000) / 1000;
    selectVehiculo.TotalContado = Math.round(parseFloat(totalSumado) * 1000) / 1000;
    selectVehiculo.TotalCredito = Math.round(parseFloat(totalCreditoSumado) * 1000) / 1000;
    //console.log("selectVehiculo.DisponibleVolumen", selectVehiculo.DisponibleVolumen);
    //console.log("totalVolumenSumadon", totalVolumenSumado);
    //console.log("totalPesoSumado", totalPesoSumado);
    // selectVehiculo.DisponibleVolumen = (parseFloat(selectVehiculo.DisponibleVolumen) - parseFloat(totalVolumenSumado)).toFixed(2);
    selectVehiculo.DisponibleVolumen = (parseFloat(selectVehiculo.CapacidadVolumen) - parseFloat(totalVolumenSumado)).toFixed(2);
    selectVehiculo.DisponiblePeso = (parseFloat(selectVehiculo.CapacidadPeso) - parseFloat(totalPesoSumado)).toFixed(2);
    // Modificamos los campos   de las columnas indicadores 
    // selectVehiculo.IndicadorVolumen = parseFloat(selectVehiculo.DisponibleVolumen) < 0 ? "Exced V." : selectVehiculo.IndicadorVolumen;
    // selectVehiculo.IndicadorPeso = parseFloat(selectVehiculo.DisponiblePeso) < 0 ? "Exced P." : selectVehiculo.IndicadorPeso;

    selectVehiculo.IndicadorVolumen = (parseFloat(totalVolumenSumado) / parseFloat(selectVehiculo.CapacidadVolumen)).toFixed(3);
    selectVehiculo.IndicadorPeso = (parseFloat(totalPesoSumado) / parseFloat(selectVehiculo.CapacidadPeso)).toFixed(3);
    //console.log("selectVehiculo.IndicadorVolumen",selectVehiculo.IndicadorVolumen);
    //console.log("selectVehiculo.IndicadorPeso",selectVehiculo.IndicadorPeso);

    if (parseFloat(selectVehiculo.DisponibleVolumen) < 0 ||
      parseFloat(selectVehiculo.DisponiblePeso) < 0) { notify_warning("Se ha excedido el volumen o peso de vehiculo") }

  };

  /** Click en actualizar - para no hacer
   *  cambios a detalle se quedo con el nombre carga por defecto */
  const onClickCaDefecto = async () => {
    setRowSelection([])
    handleRowClickedClear()
    if (!emp_sl_t_suc || emp_sl_t_suc === "9999") {
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    } else {
      setTimeout(() => {
        const urlV = '/apilistVehiculosPreCons';
        const urlP = '/apilistPedidosPreCons';
        fcListaTablaVehicul(urlV);
        fcListaTablaPedidos(urlP);
      }, 10);
    }
  }
  /** Click en carga por defecto - para no hacer 
   * cambios a detalle se quedo con el nombre actualizar*/
  const onClickActualizar = async () => {
    setRowSelection([])
    handleRowClickedClear()
    if (!emp_sl_t_suc || emp_sl_t_suc === "9999") {
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    } else {
      setTimeout(() => {
        const urlV = '/apilistVehiculos2'
        const urlP = '/apilistPedidos2'
        fcListaTablaVehicul(urlV)
        fcListaTablaPedidos(urlP);
      }, 10);

    }
  }

  /** Funciones utilizadas para Listar */
  const fcListaTablaVehicul = async (url) => {
    setPendingVehiculos(true)
    const body = { vcodEmpresa: cook_idEmp, vcodSucursal: emp_sl_t_suc, formattedDate: formatDate(inp_text_fecha) };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();

    setdataVehiculo(data)
    $(".tabla-resumen2 .rdt_Table").addClass("table");
    setPendingVehiculos(false)
  }
  const fcListaTablaPedidos = async (url) => {

    setPendingPedido(true)
    //const url9 ='/apilistPedidosPreCons'    
    const Body = { vcodEmpresa: cook_idEmp, vcodSucursal: emp_sl_t_suc, formattedDate: formatDate(inp_text_fecha) };
    const opPe9 = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
    const resp = await fetch(url, opPe9);
    const data = await resp.json();
    setdataPedidos(data)
    //setTimeout(() => {  selectTablaMultipleReactTable() }, 1000);
    setPendingPedido(false)
  }




  /** Arbir modal desde boton nuevo Planificador*/
  const handleClickedModalRepN = () => {
    handleRowClickedClear() //Limpiar array
    handleOpen(); //Abrir primer modal o modal principal
  }
  /** Limpiar vectores  */
  const handleRowClickedClear = () => {
    setdataVehiculo([])
    setdataPedidos([])
    //setSelectedRows([])
  }
  const handleRowClickedClearN = () => {
    setdataVehiculo([])
    setdataPedidos([])
    //setSelectedRows([])
    setopenModalVehiPedi(false);
  }
  
  const getSelectedData = () => {
    const selectedIndices = Object.keys(rowSelection).map(Number);
    return dataPedidos.filter((_, index) => selectedIndices.includes(index));
  };

  

  


  /** Limpiar uno por uno */
  const getSelectedDataArr = useCallback(() => {
    const selectedIndices = Object.keys(rowSelection).map(Number);
    if (dataPedidos.length) {
      return dataPedidos?.filter((_, index) => selectedIndices.includes(index));
    }
    return []
  },[dataPedidos, rowSelection]);
  useEffect(() => {
    const arFila =  getSelectedDataArr();
    if (arFila.length) {
      //console.log("cuando la cantidad es menor que 10");
      const codigoVehiculosParaLimpiar = new Set(arFila.filter(key => key.CodigoVehiculo !== '').map(key => key.CodigoVehiculo));
      const docEntriesParaActualizar = new Set(arFila.filter(key => key.CodigoVehiculo !== '').map(item => item.DocEntry));
      if (docEntriesParaActualizar.size !== 0) {
        
      
        // console.log("arFila", arFila);
        // console.log("docEntriesParaActualizar", docEntriesParaActualizar);
        
        const updatedDataPedidos = dataPedidos.map(item => {
        if (docEntriesParaActualizar.has(item.DocEntry)) {
            return { ...item, CodigoVehiculo: '', codConsolidado: '' };
          }
          return item;
        });
        setdataPedidos(updatedDataPedidos);
        const myArray = [...codigoVehiculosParaLimpiar];
        myArray?.map((x) => {
          const selectVehiculo = dataVehiculo.find((obj) => obj.Code === x);
          actualizarTotalesVehiculos(selectVehiculo, updatedDataPedidos);
        })
          
      }
      
    }
    
  }, [dataPedidos, dataVehiculo, getSelectedDataArr]);
  
  /** Seleccion del vehiculo al que se le asiganaran los pedidos
   *  evento de la tabla 
   */
  const handleVehiculoRowSelected = (row) => {

    //limpiarFiles(row)


    //console.log("inicia click en vehiculos row", row);
    setRowSelection([])
    let updatedPedidosData;
    const formattedDate = formatDate(inp_text_fecha);
    //console.log("Datos seleccionados rowSelection", rowSelection);
    const selectedData = getSelectedData();
    //console.log("daat seleccionada", selectedData);
    //updatedPedidosData = selectedData
    updatedPedidosData = dataPedidos.map((pedidoRow) => {
      if (selectedData.includes(pedidoRow)) {
        return {
          ...pedidoRow,
          CodigoVehiculo: row.Code,
          //codConsolidado: formattedDate + emp_sl_t_suc,
          codConsolidado: formattedDate + row.Code,
        };
      }
      return pedidoRow;
    });
    //console.log("updatedPedidosData", updatedPedidosData);
    setdataPedidos(updatedPedidosData);
    // row el vehiculo selecioando 
    actualizarTotalesVehiculos(row, updatedPedidosData);
    

  };



  const arrDataProcesada = async(cook_idEmp,sl_t_suc,arrDataDividir, arrDataVehiEliminar) =>{
    const batchSize = 5;
    const promises = []
    for (let i = 0; i < arrDataDividir.length; i += batchSize) {
        const batch = arrDataDividir.slice(i, i + batchSize);
        let respd = fuEnvioAct(cook_idEmp,sl_t_suc,batch, arrDataVehiEliminar)
        promises.push(respd);
    }
    const resultados = await Promise.all(promises);
    return resultados.flat();
  }
  const fuEnvioAct = async(cook_idEmp,sl_t_suc,arrDataDividir, arrDataVehiEliminar) => {
    //console.log("data a enviar ", arrDataDividir);
    /** Sacar los vehiculos */
    const codVehiculosEnv = new Set(arrDataDividir.filter(key => key.Code !== '').map(key => key.Code));
    //console.log("codVehiculosEnv", codVehiculosEnv);
    /** Sacar los pedidos a enviar dependiendo de los vehiculos */
    const arrPedidosEnv = dataPedidos.filter(item => codVehiculosEnv.has(item.CodigoVehiculo) && item.CodigoVehiculo !== '' && item.codConsolidado !== ''  )
    //console.log("arrPedidosEnv", arrPedidosEnv);
      
    const url4 = '/insertConsolidado'
    const Body = {
      vcodEmpresa: cook_idEmp,
      vcodSucursal: sl_t_suc,
      inp_text_fecha: formatDateYYYY(inp_text_fecha),
      vtipo: tipConsolidado,
      formattedDate: formatDate(inp_text_fecha),
      arrDataVehi: arrDataDividir,
      arrDataPedi: arrPedidosEnv,
      arrDataVehiEliminar: arrDataVehiEliminar
    };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
    const resp = await fetch(url4, opPe);//cambiar por proveedores
    const resj = await resp.json();
    return resj;

  }
  const onClickGuardar = async () => {

    const fechaActual = newDateF()
    const fechaSelecc = newDateFormate(inp_text_fecha,'-')
    if (fechaSelecc <= fechaActual) {
      notify_warning("En la Fecha seleccionada "+fechaSelecc+" debe ser mayor a la fecha actual "+fechaActual);
      return;
    }
    //console.log("dataVehiculo", dataVehiculo);
    
    let arrDataVehi = dataVehiculo.filter(
      vehiculo => Number(parseFloat(vehiculo.Total)+parseFloat(vehiculo.TotalCredito)) > 0 ||  Number(vehiculo.TPedidos) >= 1
    );
    console.log("arrDataVehi", arrDataVehi);
    
    let arrDataVehiEliminar = dataVehiculo.filter(
      vehiculo => Number(vehiculo.Total) === 0 && 
      Number(vehiculo.TPedidos) === 0 && 
      Number(vehiculo.TotalCredito) === 0
    );

    if (arrDataVehi.length) {
      console.log("Hay vehiculos con mayor a 0");
      //arrDataProcesada(cook_idEmp,emp_sl_t_suc,arrDataVehi)
      Swal.fire({
        title: 'ADVERTENCIA',
        text: '¿Desea planificar para '+moment(inp_text_fecha).format('YYYY/MM/DD')+ '?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        customClass: { container: 'my-swal' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          $("#btn_modal_guardar").attr("disabled", true)
          $("#icon_btn_modal_guardar").addClass("d-none")
          $("#spinner_btn_modal_guardar").removeClass("d-none")
          //alert("sdsdsdd")
          const resdatos = await arrDataProcesada(cook_idEmp,emp_sl_t_suc,arrDataVehi, arrDataVehiEliminar)
         // const resdatos = await fuEnvioAct(cook_idEmp,emp_sl_t_suc,arrDataVehi)
          console.log("onClickGuardar -> resdatos ", resdatos);
          notify_valido("El proceso se realizo correctamente")
          $("#btn_modal_guardar").attr("disabled", false)
          $("#icon_btn_modal_guardar").removeClass("d-none")
          $("#spinner_btn_modal_guardar").addClass("d-none")
      
        }
      })
    }else{
      notify_warning("¡No hay valores.!");
    }

  }

  /**  estados para Mostrar modal   */
  const [openModalVehiPedi, setopenModalVehiPedi] = useState(false);
  const [openModalDetallPedi, setopenModalDetallPedi] = useState(false);

  const handleOpen = () => { setopenModalVehiPedi(true); }; // mostrar modal principal 

  // mostrar data del modal de detalle de pedido 
  const openModDetallePed = async (row) => {
    handleOpenSecondModal();
    setPendingDetallePed(true)
    const url = '/apilistDetalle'
    const Body = { vcodEmpresa: cook_idEmp, DocEntry: row.DocEntry, };
    const opPe9 = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
    const resp = await fetch(url, opPe9);
    const data = await resp.json();
    setdataPedidoDetall(data)
    setPendingDetallePed(false)
  }
  const handleOpenSecondModal = () => { setopenModalDetallPedi(true); }
  const handleCloseSecondModal = () => { setopenModalDetallPedi(false); }



  /**
   * *************  
   * INICIO::  Columnas de las tablas de los modales   
   * *************
  **/
  const columnsVehiculos = [
    { name: 'Placa', selector: row => row.Code, sortable: true },
    { name: 'Coordinador R.', selector: row => row.Name, sortable: true, grow: 2, reorder: true, width: '255px' },
    { name: 'TotalS/.', selector: row => row.Total, sortable: true, grow: 2, reorder: true },
    { name: 'TotPedi.', selector: row => row.TPedidos, sortable: true, grow: 2, reorder: true },
    { name: 'TotClie.', selector: row => row.TClientes, sortable: true, grow: 2, reorder: true },
    { name: 'TotCont.', selector: row => row.TotalContado, sortable: true, grow: 2, reorder: true },
    { name: 'TotCred.', selector: row => row.TotalCredito, sortable: true, grow: 2, reorder: true },
    { name: 'Cap.Peso', selector: row => row.CapacidadPeso, sortable: true, grow: 2, reorder: true },
    { name: 'Cap.Volu.', selector: row => row.CapacidadVolumen, sortable: true, grow: 2, reorder: true },
    { name: 'Dis.Peso', selector: row => row.DisponiblePeso, sortable: true, grow: 2, reorder: true },
    { name: 'Dis.Volu.', selector: row => row.DisponibleVolumen, sortable: true, grow: 2, reorder: true },
    {
      name: 'Ind.Peso.', selector: row => `${row.IndicadorPeso * 100}%`, sortable: true, grow: 2, reorder: true,
      conditionalCellStyles: [
        {
          when: row => row.IndicadorVolumen < 0.5,
          style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        {
          when: row => row.IndicadorVolumen >= 0.5 && row.IndicadorVolumen <0.8,
          style: {
            backgroundColor: 'rgba(248, 148, 6, 0.9)',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        {
          when: row => row.IndicadorVolumen >= 0.8,
          style: {
            backgroundColor: 'rgba(242, 38, 19, 0.9)',
            color: 'white',
            '&:hover': {
              cursor: 'not-allowed',
            },
          },
        },
      ],
    },
    {
      name: 'Ind.Vol.', selector: row => `${row.IndicadorVolumen*100}%`, sortable: true, grow: 2, reorder: true,
      conditionalCellStyles: [
        {
          when: row => row.IndicadorVolumen < 0.5,
          style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        {
          when: row => row.IndicadorVolumen >= 0.5 && row.IndicadorVolumen < 0.8,
          style: {
            backgroundColor: 'rgba(248, 148, 6, 0.9)',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        {
          when: row => row.IndicadorVolumen >= 0.8,
          style: {
            backgroundColor: 'rgba(242, 38, 19, 0.9)',
            color: 'white',
            '&:hover': {
              cursor: 'not-allowed',
            },
          },
        },
      ],
    }
  ]


  const columnsPedidos = useMemo(() => [
    { header: 'Cod.Vehiculo', accessorKey: 'CodigoVehiculo' },
    { header: 'DocNum', accessorKey: 'DocNum' },
    // { header: 'DocEntry', accessorKey: 'DocEntry' },
    { header: 'Peso', accessorKey: 'peso' },
    { header: 'Volumen', accessorKey: 'volumen' },
    { header: 'Fecha', accessorKey: 'DocDate' },
    { header: 'Credito', accessorKey: 'credito' },
    { header: 'Total', accessorKey: 'total' },
    { header: 'Codigo Ruta', accessorKey: 'U_AB_CRUTA' },
    { header: 'Nombre Ruta', accessorKey: 'U_AB_NRUTA' },
    { header: 'codConsoli.', accessorKey: 'codConsolidado' },
    { header: 'CodCli', accessorKey: 'CardCode' },
    { header: 'Cliente', accessorKey: 'CardName' },
    { header: 'CodResp.', accessorKey: 'SlpName' },
    { header: 'Responsable', accessorKey: 'Memo' },
    { header: 'CanalV', accessorKey: 'canalVendedor' },
    { header: 'DirEntrega', accessorKey: 'dirEntrega' },
    { header: 'Comentario', accessorKey: 'comentarios' }

  ], []);

  const columnsPedDetalle = useMemo(() => [
    { header: 'CodArticulo', accessorKey: 'codArticulo' },
    { header: 'Descipción', accessorKey: 'descripcion' },
    { header: 'Cantidad', accessorKey: 'cantidad' },
    { header: 'Almacen', accessorKey: 'Almacen' },
    { header: 'Precio', accessorKey: 'precio' },
    { header: 'Descuento', accessorKey: 'descuento' },
    { header: 'Impuesto', accessorKey: 'impuesto' },
    { header: 'Total', accessorKey: 'total' },
    { header: 'UnidadMedida', accessorKey: 'unidad' },
    { header: 'ListaPrecios', accessorKey: 'lprecio' },
    //{ header: 'LoteAsignado', accessorKey: 'LoteAsignado'},
    { header: 'Area', accessorKey: 'area' },
    { header: 'Linea', accessorKey: 'linea' },
    { header: 'Clinea', accessorKey: 'Clinea' },
    { header: 'CentroCosto', accessorKey: 'centroCosto', enableEditing: false },
    { header: 'Cant.Inv.', accessorKey: 'CantidadInventario', enableEditing: false },
    { header: 'GLotes', accessorKey: 'Glotes', enableEditing: false },
  ], []);


  /** Cerrar todos los consolidados */ 
  const [disabledBtnCTC, setDisabledBtnCTC] = useState(false);
  const cerrarTodosConsolidados = (table) => {
    //console.log("table", table.options.data);
    //console.log("dataTablaConsol",dataTablaConsol);

    let arrCodigosAbiertos = dataTablaConsol.filter((item) => item.Abierto === 'SI')
    console.log("arrCodigos",arrCodigosAbiertos);
    Swal.fire({
      title: 'ADVERTENCIA',
      text: '¿Desea cerrar todos los consolidados?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      customClass: { container: 'my-swal' }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDisabledBtnCTC(true)
        const url1 = '/cerrarTodosConsolidados'
        const Body = { vcodEmpresa:cook_idEmp, arrCodigosAbiertos: arrCodigosAbiertos};
        const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
        const response = await fetch(url1, opPe);
        const resdatos = await response.json();
        //console.log("resdatos", resdatos);
        if (resdatos.vl_res === 1) {
          setDisabledBtnCTC(false)
          notify_valido("¡Se proceso correctamente.!")
          onClickListarPreCon()
        } else { console.log("error "); }
        setDisabledBtnCTC(false)
        Swal.close()
        
      }
    })
  }
  //** Reporte excel */
  const [disabledBtnRC, setDisabledBtnRC] = useState(false);
  const reportExcel = async() => {
    if (!emp_sl_t_suc || emp_sl_t_suc === "9999") {
      notify_warning(" Debes seleccionar una sucursal")
    } else {
      setDisabledBtnRC(true)
      const url1 = '/apiReportConsolidado'
      const Body = { vcodEmpresa: cook_idEmp, vcodSucursal: emp_sl_t_suc, formattedDate: formatDate(inp_text_fecha) };
      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
      const response = await fetch(url1, opPe);
      const resdatos = await response.json();
      console.log("reportExcel resdatos => ",resdatos);
      const ws = XLSX.utils.json_to_sheet(resdatos);
      const wb = {Sheets:{data:ws}, SheetNames:["data"]};
      const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
      const data = new Blob([excelBuffer], {type:"xlsx"});
      FileSaver.saveAs(data, `reportConsolidado_${formatDate(inp_text_fecha)}.xlsx`);
      setDisabledBtnRC(false)
    }
  }
  const [disabledBtnEV, setDisabledBtnEV] = useState(false);
  const reportExcelEnvDiario = async() => {
    if (!emp_sl_t_suc || emp_sl_t_suc === "9999") {
      notify_warning(" Debes seleccionar una sucursal")
    } else {
      setDisabledBtnEV(true)
      const url1 = '/apiReportEnvioDiario'
      const Body = { vcodEmpresa: cook_idEmp, vcodSucursal: emp_sl_t_suc, formattedDate: formatDate(inp_text_fecha) };
      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
      const response = await fetch(url1, opPe);
      const resdatos = await response.json();
      console.log("reportExcel resdatos => ",resdatos);
      const ws = XLSX.utils.json_to_sheet(resdatos);
      const wb = {Sheets:{data:ws}, SheetNames:["data"]};
      const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
      const data = new Blob([excelBuffer], {type:"xlsx"});
      FileSaver.saveAs(data, `reportEnvioDiario_${formatDate(inp_text_fecha)}.xlsx`);
      setDisabledBtnEV(false)
    }
  }


  /** Agregando Responsable */
  const [openModalResp, setopenModalResp] = useState(false);
  const handleOpenModapResp = () => { 
    setArrDatosResp(arDatosRes)
    setopenModalResp(true); 
  };
  const handleCloseModapResp = () => { setopenModalResp(false); };
  const arDatosRes = {
    txtCodigo:'',
    txtNombres:'',
    txtApellidos:''
  }
  const [arrDatosResp, setArrDatosResp] = useState(arDatosRes);

  

  const handle2FieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    setArrDatosResp({
        ...arrDatosResp,
        [fieldName]: value,
    });
  };

  const onClickAgregarResp = async() => {
    console.log("arrDatosResp", arrDatosResp);
    const Body = {
      vcodEmpresa: cook_idEmp,
      vcodSucursal: emp_sl_t_suc,
      arrDatosResp: arrDatosResp
    }
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
    const resp = await fetch('/insertResponsable', opPe);//cambiar por proveedores
    const resdatos = await resp.json();
    console.log("onClickGuardar -> resdatos ", resdatos);
    if (resdatos.vl_insert === 1) {
      notify_valido("Se Registro Correctamente")
      handleCloseModapResp()
      await listLiquidadores()
    }else{
      notify_warning("Revisar datos, hay errores.")
    }
  }

  /** Modal Reprogramar  */
  const [openModalRepro, setopenModalRepro] = useState(false);
  const handleOpenModapRepro = () => { setopenModalRepro(true); };
  const handleCloseModapRepro = () => { setopenModalRepro(false); };

  const [inp_text_Nfecha, setInp_text_Nfecha] = useState(new Date());
  const funReprogramar = async(row) => {
    handleOpenModapRepro()
    setCodeConsolidado(row.Codigo)

  }
  const funProcesarReporgramacion = async() => {
    //console.log("documento liberado" +  codeConsolidado  );
    
    Swal.fire({
      title: 'ADVERTENCIA',
      text: '¿Desea Reprogramar?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      //confirmButtonColor: "#71dd37",
      customClass: { container: 'my-swal' }
    }).then(async (result) => {
      if (result.isConfirmed) {
        
        const url = `/apiReprogramarFecha?vcodEmpresa=${cook_idEmp}&vcodConsolidado=${codeConsolidado}&vNewFecha=${moment(inp_text_Nfecha).format('YYYY-MM-DD')}`;
        const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
        const response = await fetch(url, opPe);
        const data = await response.json();
        console.log(data);
        if (data) {
          onClickListarPreCon()
        }
        Swal.close()
      }
    })
  }

  /** Liberar documento */
  const handleLiberarDocumento = async(row) => {
    Swal.fire({
      title: 'ADVERTENCIA',
      text: '¿Desea Liberar Documento?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      //confirmButtonColor: "#71dd37",
      customClass: { container: 'my-swal' }
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("documento liberado" +  codeConsolidado + row.txtDocEntry );
        
        const url = `/apiLiberarDocumento?vcodEmpresa=${cook_idEmp}&vcodConsolidado=${codeConsolidado}&vcodDocEntry=${row.txtDocEntry}`;
        const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
        const response = await fetch(url, opPe);
        const data = await response.json();
        console.log(data);
        if (data) {
          const myList = [...dataPD];
          let dataValidar = myList.filter((item) => (item.txtDocEntry!==row.txtDocEntry));
          setdataDP(dataValidar)
        }
        Swal.close()
      }
    })

  }


  /**  */
  const [openModalAgregarDoc, setopenModalAgregarDoc] = useState(false);
  const handleOpenModapAgregarDoc = () => { setopenModalAgregarDoc(true); };
  const handleCloseModapAgregarDoc = () => { setopenModalAgregarDoc(false); };
  const [dataPDLiberados, setdataDPLiberados] = useState([]);
  const [pendingPGLiberados, setPendingPGLiberados] = useState(false);
  /** Consultar datos para mostrar los documneto agregados  */
  const funListarDocumnetosLiberados = async() => {
    setPendingPGLiberados(true)
    handleOpenModapAgregarDoc()
    const url_lstClientes = `/apiListarPDLiberados?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    //console.log(data);
    setdataDPLiberados(data)
    setPendingPGLiberados(false)
  }
  const funAgregarNewDocumentos = async(row) => {
    const url_lstClientes = `/apiAgregarDocumento?vcodEmpresa=${cook_idEmp}&vcodConsolidadoAnt=${row.txtCodCons}&vcodConsolidadoAct=${codeConsolidado}&vcodDocEntry=${row.txtDocEntry}&vcodVehiculo=${row.txtCodVehi}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    console.log(data);
    onClickModalPD(codeConsolidado)
    funListarDocumnetosLiberados()
  }


  const funEliminarFila = async(row) => {


    Swal.fire({
      title: 'ADVERTENCIA',
      text: '¿Desea Eliminar Consolidado '+row.Codigo+'?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      //confirmButtonColor: "#71dd37",
      customClass: { container: 'my-swal' }
    }).then(async (result) => {
      if (result.isConfirmed) {
        const url1 = '/eliminarConsolidado'
        const Body = { vcodEmpresa:cook_idEmp, vcodSucursal: emp_sl_t_suc, vcodigo: row.Codigo };
        const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
        const response = await fetch(url1, opPe);
        const resdatos = await response.json();
        console.log("resdatos", resdatos);
        if (resdatos.vl_res === 1) {
          notify_valido("¡Se proceso correctamente.!")
          onClickListarPreCon()
        } else { console.log("error "); }

        Swal.close()
      }
    })
  }


  return (
    <div>
      <h6 className="text-muted">Pre Consolidado De Carga</h6>
      <div className="card card-action">
        {/* <div className="card-header ">
                <div className="card-action-title">Lista </div>
            </div> */}
        <div className="card-body ">
          <div className="row">
            <div className="col-lg-2">Sucursal:</div>
            <div className="col-lg-2 ">
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
            </div>
            <div className="col-lg-1">Fecha:</div>
            <div className="col-lg-2" style={{ zIndex: 1000 }}>
              <DatePicker
                id="fecha"
                locale="es"
                className="form-control form-control-sm text-center"
                selected={inp_text_fecha}
                onChange={date => setInp_text_fecha(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>

            <div className="col-lg-4 text-end ">
              <Tooltip title="Consultar información" placement="top">
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListarPreCon} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                  &nbsp;Consultar
                </button>
              </Tooltip>&nbsp;
              <button className="btn btn-sm btn-label-twitter" id="btn_new" onClick={handleClickedModalRepN} > <i className='bx bxs-file-plus'></i> Planificar </button>
              <AButton tp_tilde="Exportar en Excel" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success mx-1" ic_class="bx bx-ms bxs-file-export" disabled={disabledBtnRC} vl_tpbtn={2}
               bt_funct={reportExcel}
              />
              <AButton tp_tilde="Envio Diario a Servis" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success mx-1" ic_class="bx bx-ms bx-navigation" disabled={disabledBtnEV} vl_tpbtn={2}
               bt_funct={reportExcelEnvDiario}
              />
            </div>
          </div>
          <div className="card-datatable table-responsive">
            {/* Tabla Nueva  */}


            <MaterialReactTable
              columns={columConsolidado}
              data={dataTablaConsol}
              //onSortingChange={setSorting}, sorting 
              state={{ isLoading: pending }}
              initialState={{
                density: 'compact',
                expanded: true,
                columnPinning: { left: ['mrt-row-actions'],right: ['responsable'] }
              }}
              enableGrouping enableColumnPinning
              enableStickyHeader
              enablePagination={true}
              enableBottomToolbar={true}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              enableRowActions
              renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                  <AButton 
                    tp_tilde="Cerrar consolidado" 
                    tp_posit="top" 
                    bt_class="btn btn-sm btn-icon btn-label-warning" 
                    disabled={(row.original.Abierto === 'SI') ? false : true} 
                    bt_funct={() => funCerrarConsolidado(row.original)} ic_class="bx bx-ms bxs-lock" vl_tpbtn={1} />
                  <AButton 
                    tp_tilde="Mas detalle" 
                    tp_posit="left" 
                    bt_class="btn btn-sm btn-icon btn-label-info detallePedido" 
                    bt_funct={() => handleOpenPD(row.original)} ic_class="bx bx-ms bx-list-ul" vl_tpbtn={1} />
                  <AButton 
                    tp_tilde="Reprogramar" 
                    tp_posit="right" 
                    bt_class="btn btn-sm btn-icon btn-label-success" 
                    disabled={(row.original.Abierto === 'NO') ? false : true} 
                    bt_funct={() => funReprogramar(row.original)} ic_class="bx bx-ms bxs-calendar-edit" vl_tpbtn={1} />
                  <AButton 
                    tp_tilde="Eliminar" 
                    tp_posit="right" 
                    bt_class="btn btn-sm btn-icon btn-label-danger" 
                    disabled={(row.original.Abierto === 'SI') ? false : true} 
                    bt_funct={() => funEliminarFila(row.original)} ic_class="bx bx-x-circle bx-ms" vl_tpbtn={1} />
                  {/* Otros botones o contenido según sea necesario */}
                </Box>
              )}
              renderTopToolbarCustomActions={({ table, row }) => (
                <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                  
                  <AButton tp_tilde="Cerrar todos los consolidados"
                    tp_posit="top" bt_class="btn btn-sm btn-warning "
                    bt_descr="Cerrar consolidados"
                    ic_class="bx bx-ms bx-lock"
                    //disabled={disabledBtnCTC}
                    disabled={(disabledBotonTodosC)?disabledBotonTodosC:disabledBtnCTC}
                    bt_funct={() => cerrarTodosConsolidados(table)}
                    vl_tpbtn={(disabledBotonTodosC)? 1: 2} /> 
                </Box>
              )}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}
              displayColumnDefOptions={{
                  'mrt-row-actions': {
                    header: 'Options', //change header text
                    size: 80, //make actions column wider
                  },
                }}
            />
          </div>
        </div>
      </div>

      {/************  Modal Vehiculos y Pedidos  ******************/}
      <Modal open={openModalVehiPedi}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '100%', height: '100%', overflow: 'auto' }}>

          <div className="modal-header mb-2">
            <h5 className="modal-title" id="exampleModalLabel4">Planificador Pre Consolidado Carga</h5>
          </div>
          <div className="modal-body" >
            <div className="row mb-2" >
              <div className="col-lg-1">Fecha F.:</div>
              <div className="col-lg-2" style={{ position: 'relative', zIndex: '2' }}>
                <DatePicker
                  id="fecha"
                  locale="es"
                  className="form-control form-control-sm text-center"
                  selected={inp_text_fecha}
                  onChange={(date) => {
                    setInp_text_fecha(date);
                  }}
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-1">Sucursal:</div>
              <div className="col-lg-2">
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} /> {/* disabled={emp_disabled} */}
              </div>
              <div className="col-lg-1">Tipo:</div>
              <div className="col-lg-2">
                <SelectTipo id="select_tipConsol" url={'/apiRTipo?vcodEmpresa=' + cook_idEmp} value={tipConsolidado} onChange={handleTipoConsolChange} disabled={false} />
              </div>
              {/* <div className="col-lg-1">Estado:</div>
              <div className="col-lg-2">
                <select id="txtActi" name="txtActi" className="form-select form-select-sm" value={imp_estado} onChange={handleEstadoChange} >
                  <option value={""} >{"<<Selecciona>>"}</option>
                  <option value={"A"} >Activo</option>
                  <option value={"C"} >Cancelado</option>
                </select>
              </div> */}
              {/* Botones */}
              <div className="col-lg-1">
                <Tooltip title="Manual" placement="top">
                  <button type="button" className="btn btn-sm btn-icon btn-label-info" onClick={onClickCaDefecto}><i className='bx bxs-archive-out bx-ms'></i></button>
                </Tooltip>&nbsp;
                <Tooltip title="Cargar Por Defecto" placement="top">
                  <button type="button" className="btn btn-sm btn-icon btn-label-info" onClick={onClickActualizar}><i className='bx bx-sync bx-ms'></i></button>
                </Tooltip>
              </div>
              <div className="col-lg-1 d-none">
                <div className="form-check form-check-primary">
                  <input className="form-check-input" type="checkbox" value="TRUE" id="customCheckPrimary" />
                  <label className="form-check-label" htmlFor="customCheckPrimary">Todos</label>
                </div>
              </div>
            </div>
            <div className="row mb-4" >
              <div className="col-lg-6 px-1">
                <div className="nav-align-top" style={{ fontSize: 12 }}>
                  <ul className="nav nav-tabs nav-fill " role="tablist" >
                    <li className="nav-item">
                      <button className="btn btn-danger nav-link active" data-bs-toggle="tab" data-bs-target="#form-tabs-personal" role="tab" aria-selected="true">Vehiculos</button>
                    </li>
                  </ul>
                  <div className="tab-content  px-0 py-0 border border-danger"  >
                    {/* {...dataVehiculo} <div className="tab-pane fade active show" id="form-tabs-personal" role="tabpanel" style={{ position: 'relative', zIndex: '1', height: '380px' }}> */}
                    <div className="card-datatable table-responsive" style={{ position: 'relative', zIndex: '1', height: '400px' }} >
                      {/* Tabla Vehiculo */}
                      <DataTable
                        columns={columnsVehiculos}
                        data={dataVehiculo}
                        className="tabla-resumen2"
                        customStyles={customStyles2}
                        //dense={true}
                        Header
                        defaultSortField="id"
                        defaultSortAsc={true}
                        pagination={false}
                        responsive
                        highlightOnHover
                        progressPending={pendingVehiculos}
                        noContextMenu="No hay registros para mostrar"
                        //onRowClicked={handleRowClicked2}
                        onRowClicked={handleVehiculoRowSelected}
                        direction="auto"
                        fixedHeader
                        fixedHeaderScrollHeight="364px"
                      // conditionalRowStyles={conditionalRowStyles} // pitar las filas 
                      // conditionalRowStyles={() => ({
                      //   when: row => row.CapacidadVolumen >= 300,
                      //   style: {
                      //     backgroundColor: 'rgba(63, 195, 128, 0.9)',
                      //     color: 'white',
                      //     '&:hover': {
                      //       cursor: 'pointer',
                      //     },
                      //   },
                      // })}
                      />

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-1">
                <div className="nav-align-top" style={{ fontSize: 12 }}>
                  <ul className="nav nav-tabs nav-fill " role="tablist" >
                    <li className="nav-item">
                      <button 
                      className="btn btn-danger nav-link active" 
                      data-bs-toggle="tab" 
                      data-bs-target="#form-tabs-personal" 
                      role="tab" aria-selected="true">
                      Pedidos
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content px-0 py-0 border border-danger" >
                    {/* <div className="tab-pane fade active show" id="form-tabs-personal" role="tabpanel"> */}
                    <div className="card-datatable table-responsive" style={{ position: 'relative', zIndex: '1', height: '400px' }}>
                      {/* Tabla Pedido */}
                      <MaterialReactTable
                        columns={columnsPedidos}
                        data={dataPedidos}
                        enableBottomToolbar= {false}
                        enableGlobalFilterModes= {true}
                        enablePagination= {false}
                        //enableRowNumbers= {true}
                        enableRowVirtualization= {true}
                        muiTableContainerProps= {{ sx: { maxHeight: '300px' } }}
                        onSortingChange= {setSorting}
                        state= {{
                          rowSelection,
                          isLoading: pendingPedido, 
                          sorting }}
                        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
                        rowVirtualizerOptions= {{ overscan: 5 }}

                        enableColumnPinning
                        muiTableBodyCellProps={{ sx: { p: '2px 16px', fontSize: '12px' } }}
                        muiCircularProgressProps={{ color: 'error' }}
                        muiSkeletonProps={{
                          animation: 'pulse',
                          height: 28,
                        }}
                        initialState={{
                          density: 'compact',
                          expanded: true,
                          showColumnFilters: false,
                          showGlobalFilter: false,
                          columnPinning: { left: ['mrt-row-actions', 'mrt-row-select'] },
                          //grouping: ['DocDate']
                        }}
                        enableGrouping 
                        enableRowSelection
                        enableStickyHeader 
                        enableColumnResizing
                        muiToolbarAlertBannerChipProps={{ color: 'error' }}

                        onRowSelectionChange={setRowSelection}
                        localization={MRT_Localization_ES} 

                        displayColumnDefOptions={{
                          'mrt-row-actions': {
                            header: 'Opt', //change header text
                            size: 60, //make actions column wider
                          },
                        }}
                        enableRowActions
                        renderRowActions={({ row }) => (
                          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                            <AButton tp_tilde="Mas detalle" tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger detallePedido" bt_funct={() => openModDetallePed(row.original)} ic_class="bx bx-ms bx-list-ul" vl_tpbtn={1} />
                          </Box>
                        )}
                        
                        
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4" >
              {/* <ModalDetalle></ModalDetalle> */}
            </div>
          </div>
          <div className="modal-footer ">
            <button type="button" className="btn btn-sm btn-label-success " id="btn_modal_guardar" onClick={onClickGuardar}>
             <i className='bx bx-save' id="icon_btn_modal_guardar" ></i>
              <span className="spinner-border d-none" id="spinner_btn_modal_guardar" role="status" aria-hidden="true"></span>
              &nbsp;Guardar PreConsolidado
            </button>
            <button type="button" className="btn btn-sm btn-label-youtube" onClick={handleRowClickedClearN}>Cerrar</button>
          </div>
        </Box>
      </Modal>


      {/************  Modal Detalle Pedidos  ******************/}
      <Modal
        open={openModalDetallPedi}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '75%', height: '80%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={handleCloseSecondModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-4" >
            <MaterialReactTable
              columns={columnsPedDetalle}
              data={dataPedidoDetall}
              state={{ isLoading: pendingDetallePed }}
              initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              localization={MRT_Localization_ES}
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              enableRowVirtualization
              muiTableContainerProps={{ sx: { maxHeight: 300 } }}
              //enableEditing

              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 100, //make actions column wider
                },
              }}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


            />
          </div>

        </Box>
      </Modal>


      {/************  Modal Detalle Pedidos Asignados  ******************/}
      <Modal
        open={openModalDP}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '75%', height: '80%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={handleClosePD}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-4" >
            <p>Pedidos asignados - {codeConsolidado}</p>
            <MaterialReactTable
              columns={columnsPD}
              data={dataPD}
              state={{ isLoading: pendingPG }}
              initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              localization={MRT_Localization_ES}
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              enableRowVirtualization
              muiTableContainerProps={{ sx: { maxHeight: 300 } }}
              //enableEditing
              enableRowActions
              renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                  {/* <AButton tp_tilde="Reprogramar" tp_posit="right" bt_class="btn btn-sm btn-icon btn-label-success" disabled={(row.original.Abierto === 'SI') ? false : true} bt_funct={() => funCerrarConsolidado(row.original)} ic_class="bx bx-ms bxs-calendar-edit" vl_tpbtn={1} /> */}
                  <AButton tp_tilde="Liberar Documento" tp_posit="right" bt_class="btn btn-sm btn-icon btn-label-youtube" disabled={(codeConsoliVali === 'NO') ? false : true} bt_funct={() => handleLiberarDocumento(row.original)} ic_class="bx bx-ms bx-reset" vl_tpbtn={1} />
                  {/* Otros botones o contenido según sea necesario */}
                </Box>
              )}
              renderTopToolbarCustomActions={({ table, row }) => (
                <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                  
                  <AButton tp_tilde="Listar Documentos Liberados"
                    tp_posit="top" bt_class="btn btn-sm btn-success "
                    //bt_descr="Agregar"
                    ic_class="bx bx-ms bx-list-plus"
                    disabled={(codeConsoliVali === 'NO') ? false : true}
                    bt_funct={() => funListarDocumnetosLiberados()}
                    //vl_tpbtn={(disabledBotonTodosC)? 2: 1} 
                    vl_tpbtn={1}

                    /> 
                </Box>
              )}
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 50, //make actions column wider
                },
              }}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}

            />
          </div>

        </Box>
      </Modal>
      {/************  Modal Detalle Pedidos Asignados Liberados  ******************/}
      <Modal
        open={openModalAgregarDoc}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '55%', height: '70%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={handleCloseModapAgregarDoc}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-4" >
            <p>Pedidos liberados </p>
            <MaterialReactTable
              columns={columnsPD}
              data={dataPDLiberados}
              state={{ isLoading: pendingPGLiberados }}
              initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              localization={MRT_Localization_ES}
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              enableRowVirtualization
              muiTableContainerProps={{ sx: { maxHeight: 300 } }}
              //enableEditing
              enableRowActions
              renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                  {/* <AButton tp_tilde="Reprogramar" tp_posit="right" bt_class="btn btn-sm btn-icon btn-label-success" disabled={(row.original.Abierto === 'SI') ? false : true} bt_funct={() => funCerrarConsolidado(row.original)} ic_class="bx bx-ms bxs-calendar-edit" vl_tpbtn={1} /> */}
                  <AButton tp_tilde="Agregar Documento" tp_posit="right" bt_class="btn btn-sm btn-icon btn-label-success" disabled={(codeConsoliVali === 'NO') ? false : true} bt_funct={() => funAgregarNewDocumentos(row.original)} ic_class="bx bx-ms bx-log-out" vl_tpbtn={1} />
                  {/* Otros botones o contenido según sea necesario */}
                </Box>
              )}
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 50, //make actions column wider
                },
              }}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}

            />
          </div>

        </Box>
      </Modal>



      {/************  Modal Regitrar Responsable  ******************/}
      <Modal
        open={openModalResp}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '20%', height: '40%', overflow: 'auto' }}>
          <div className="modal-header">
              <h5 className="modal-title" >Responsable</h5>
              <button
                className='btn btn-label-dark btn-sm btn-icon mb-4'
                onClick={handleCloseModapResp}
              >
                <i className='bx bx-x bx-ms'></i>
              </button>
          </div>
          <div className="modal-body mb-3" >
            <div className="row mb-2">
              <div className="col-lg-12">
                  <label className="form-label mb-0 " >Codigo:</label>
                  <input type="number" className="form-control form-control-sm" value={arrDatosResp.txtCodigo} onChange={handle2FieldChange('txtCodigo')} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-12">
                  <label className="form-label mb-0 " >Nombres:</label>
                  <input type="text" className="form-control form-control-sm" value={arrDatosResp.txtNombres} onChange={handle2FieldChange('txtNombres')} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-12">
                  <label className="form-label mb-0 " >Apellidos:</label>
                  <input type="text" className="form-control form-control-sm" value={arrDatosResp.txtApellidos} onChange={handle2FieldChange('txtApellidos')} />
              </div>
            </div>

          </div>
          <div className="text-end">
                    <div className="row">
                        <div className="col-lg-12">
                            <AButton tp_tilde="Agregar" bt_descr="Agregar" tp_posit="top" bt_class="btn btn-label-twitter btn-sm" bt_funct={onClickAgregarResp} ic_class="bx bx-save bx-ms" vl_tpbtn={1} />
                        </div>
                    </div>
                    
                </div>
        </Box>
      </Modal>
      {/************  Modal Reprogramar  ******************/}
      <Modal
        open={openModalRepro}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '40%', height: '40%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={handleCloseModapRepro}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="modal-header mb-4">
            <h5 className="modal-title" >Reprogramar Codigo Consolidado: {codeConsolidado}</h5>
          </div>
          <div className="modal-body " >
            <div className="row" >
              <div className="col-lg-4">Nueva Fecha R:</div>
              <div className="col-lg-4" style={{ position: 'relative', zIndex: '2' }}>
                <DatePicker
                  id="fecha"
                  locale="es"
                  className="form-control form-control-sm text-center"
                  selected={inp_text_Nfecha}
                  onChange={(date) => { setInp_text_Nfecha(date); }}
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4 text-end ">
                <AButton tp_tilde="Procesar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success mx-1" ic_class="bx bx-ms bx-cloud-download" disabled={disabledBtnRC} vl_tpbtn={2}
                bt_funct={funProcesarReporgramacion}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <ToastContainer />

    </div>
  )
}


export default REPPreConsolidadoDeCarga