import {Tooltip}from '@mui/material';

//data-bs-toggle={(props.bt_motal === true) ?? "modal" } data-bs-target="#exLargeModal2"""
export const AButton = (props) => {
  return (
    <Tooltip title={props.tp_tilde} placement={props.tp_posit}>
      <span>
        <button id={props.id} className={props.bt_class} onClick={props.bt_funct} disabled={props.disabled} data-bs-toggle={(props.bt_vmodal === 1) ? "modal" : ""} data-bs-target={props.bt_nmodal}  >
          {( props.vl_tpbtn === 2 && props.disabled === true) ? <span className="spinner-border" role="status" aria-hidden="true"></span> : <i className={props.ic_class}></i> }
          {(props.bt_descr) ? ` ${props.bt_descr}` : "" }
        </button>
      </span>
    </Tooltip>
  )
}