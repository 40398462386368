/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { SelectSucursal, SelectTipo } from '../../components/Selects/SelectsPrincipales'
import { notify_warning, notify_valido, selectTabla, selectTablaMultiple, removeSelectedRows, notify_error } from '../../components/utils';
import $ from 'jquery'
import '../../App.css';
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box, Modal, Tooltip } from '@mui/material';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { AButton } from '../../components/Button';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


const moment = require('moment')
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id')
const globalTheme = window.templateCustomizer.settings.style;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};

export const Report1 = (prosp) => {
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiArticulos?vcodEmpresa=' + cook_idEmp + '&numRepor=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };

  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns = useMemo(() => [
    { header: 'CodProveedor', accessorKey: 'CodProveedor' },
    { header: 'NomProveedor', accessorKey: 'NomProveedor' },
    { header: 'CodUnNeg', accessorKey: 'CodUnNeg' },
    { header: 'NomUnNeg', accessorKey: 'NomUnNeg' },
    { header: 'Sucursal', accessorKey: 'Sucursal' },
    { header: 'Almacen', accessorKey: 'Almacen' },
    { header: 'Codigo', accessorKey: 'Codigo' },
    { header: 'Descripcion', accessorKey: 'Descripcion' },
    { header: 'Activo', accessorKey: 'Activo' },
    { header: 'GrupoArticulos', accessorKey: 'GrupoArticulos' },
    { header: 'UND_Inventarios', accessorKey: 'UND_Inventarios' },
    { header: 'StockSistema', accessorKey: 'StockSistema' },
    { header: 'Stock_FLEX', accessorKey: 'Stock_FLEX' },
    { header: 'Stock_PENDDS', accessorKey: 'Stock_PENDDS' },
    { header: 'Stock_VENESP', accessorKey: 'Stock_VENESP' },
    { header: 'Stock_PANNET', accessorKey: 'Stock_PANNET' },
    { header: 'StockFisicoTotal', accessorKey: 'StockFisicoTotal' },
    { header: 'StockFisicoCAJA', accessorKey: 'StockFisicoCAJA' },
    { header: 'Presentacion_CAJA', accessorKey: 'Presentacion_CAJA' },
    { header: 'StockFisicoUND', accessorKey: 'StockFisicoUND' },
    { header: 'Presentacion_UND', accessorKey: 'Presentacion_UND' },
    { header: 'Factor_CAJA', accessorKey: 'Factor_CAJA' },
    { header: 'CostoPromedio', accessorKey: 'CostoPromedio' },
    { header: 'ValorizadoPROMEDIO', accessorKey: 'ValorizadoPROMEDIO' },
    { header: 'CostoReal', accessorKey: 'CostoReal' },
    { header: 'ValorizadoREAL', accessorKey: 'ValorizadoREAL' }
  ], []);

  const onClickListar = async () => {
    let tipoReporte= 1;
    try {
      setdataReport([])
      setPending(true)
      const url_reporte = `/apiReportAuditoria?vcodEmpresa=${cook_idEmp}&numRepor=${tipoReporte}`;
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
      const response = await fetch(url_reporte, opPe);
      if (response.status != 200) {
        // Si el código de estado es 404 (no se encontraron clientes)
        notify_warning('Ocurrio un error en el proceso')
        return;
      }
      const data = await response.json();
      setdataReport(data)

    } catch (error) {
      console.log("Ocurrio un error:: ", error);


    } finally {
      setPending(false)
    }
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    emp_setSl_t_suc('')
    setSlcArticulo([])
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE_AUDI_STOCK" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE_AUDITORIA_STOCK_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          {/* <div className="row ">
              <div className="col-lg-2"><label className="form-label mb-0 " >Sucursal:</label></div>
              <div className="col-lg-2">
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
              <div className="col-lg-2"><label className="form-label mb-0 " >Articulo:</label></div>
              <div className="col-lg-4 px-1">
                <AsyncSelect 
                  styles={styles}
                  placeholder="Busque por Cod./Desc."
                  name="form-field-name"
                  className="from-control form-control-sm my-swal border-0"  
                  value={slcArticulo}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code +' - '+ e.Name}
                  getOptionValue={e => e.Code }
                  loadOptions={funDArticulos}
                  onChange={handleSelectChange}
                  maxMenuHeight={150}
                />
              </div>
            </div> */}
        </div>
        <div className="col-lg-2 text-end">

          <Tooltip title="Consultar información" placement="top">
            <>
              <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
              </button>
            </>
          </Tooltip>
          <Tooltip title="Exportar" placement="top">
            <div className="btn-group" role="group">
              <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                {/* <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button> */}
                <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
              </ul>
            </div>
          </Tooltip>
          <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}

        />
      </div>
    </>
  )
}
export const Report2 = (prosp) => {
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiArticulos?vcodEmpresa=' + cook_idEmp + '&numRepor=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])

  /** Primer reporte */
  const columns = useMemo(() => [
    { header: 'TipoConsolidado', accessorKey: 'TipoConsolidado' },
    { header: 'Sucursal', accessorKey: 'Sucursal' },
    { header: 'Almacen', accessorKey: 'Almacen' },
    { header: 'FechaEmision', accessorKey: 'FechaEmision' },
    { header: 'Linea', accessorKey: 'Linea' },
    { header: 'Codigo', accessorKey: 'Codigo' },
    { header: 'Descripcion', accessorKey: 'Descripcion' },
    { header: 'UnidadVenta', accessorKey: 'UnidadVenta' },
    { header: 'FactorUndVenta', accessorKey: 'FactorUndVenta' },
    { header: 'CantidadUndVenta', accessorKey: 'CantidadUndVenta' },
    { header: 'TipoDocumento', accessorKey: 'TipoDocumento' },
    { header: 'SerieCorrelativo', accessorKey: 'SerieCorrelativo' },
    { header: 'CodigoCliente', accessorKey: 'CodigoCliente' },
    { header: 'NombreCliente', accessorKey: 'NombreCliente' },
    { header: 'Vendedor', accessorKey: 'Vendedor' },
    { header: 'Ruta', accessorKey: 'Ruta' },
    { header: 'Comentario', accessorKey: 'Comentario' },
  ], []);

  const onClickListar = async () => {
    // Este neumro de Repor, es de la api De RepoAuditori
    let tipoReporte= 2;
    try {
      setdataReport([])
      setPending(true)
      const url_reporte = `/apiReportAuditoria?vcodEmpresa=${cook_idEmp}&numRepor=${tipoReporte}`;
      console.log(url_reporte);
      
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
      const response = await fetch(url_reporte, opPe);
      if (response.status != 200) {
        // Si el código de estado es 404 (no se encontraron clientes)
        notify_warning('Ocurrio un error en el proceso')
        return;
      }
      const data = await response.json();
      setdataReport(data)

    } catch (error) {
      console.log("Ocurrio un error:: ", error);
      notify_error('Ocurrio un error')

    } finally {
      setPending(false)
    }
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
  }
  
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "DOCUMENTOS PENDIENTES POR DESPACHO_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
        </div>
        <div className="col-lg-2 text-end">

          <Tooltip title="Consultar información" placement="top">
            <>
              <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
              </button>
            </>
          </Tooltip>
          <Tooltip title="Exportar" placement="top">
            <div className="btn-group" role="group">
              <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                {/* <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button> */}
                <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
              </ul>
            </div>
          </Tooltip>
          <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}

        />
      </div>
    </>
  )
}

const ReportAuditoria = () => {
  const [slTipoReport, setSlTipoReport] = useState('');

  const changeSlTipoReport = (event) => {
    setSlTipoReport(event.target.value);
  }

  return (
    <div>
      <h6 className="text-muted"> Reporteria Auditoria</h6>
      <div className="card card-action">
        <div className="card-body ">
          <div className="row mb-2">
            <div className="col-lg-10">
              <div className="row ">
                <div className="col-lg-2"><label className="form-label mb-0 " >Tipo Reporte:</label></div>
                <div className="col-lg-10">
                  <select id="slc_t_con" className="form-select form-select-sm" value={slTipoReport} onChange={changeSlTipoReport} >
                    <option value={"9999"} >--Seleccione--</option>
                    <option value={"1"} > RAUD001 : REPORTE GENERAL DE STOCKS</option>
                    <option value={"2"} > RAUD002 : DOCUMENTOS PENDIENTES POR DESPACHO </option>
                    {/* <option value={"3"} > RAUD003 : En Desarrrollo  </option> */}
                    {/* <option value={"4"} > RAUD004 : En Desarrrollo  </option> */}
                    {/* <option value={"5"} > RAUD005 : En Desarrrollo  </option> */}


                  </select>
                </div>
              </div>
            </div>
          </div>
          {
            (slTipoReport === '1') && (
              <Report1
                dataTipo={slTipoReport}
              />
            )
          }
          {
                (slTipoReport === '2' ) && (
                  <Report2 
                    dataTipo={slTipoReport}
                  />
                )
              }
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default ReportAuditoria
