/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useMemo, useCallback, useEffect} from 'react'
import Cookies from 'universal-cookie';
import { SelectSucursal, SelectTipo } from '../../components/Selects/SelectsPrincipales'
import {notify_warning, notify_valido, selectTabla, selectTablaMultiple,removeSelectedRows, notify_error} from '../../components/utils';
import $ from 'jquery'
import '../../App.css';
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import {Box, Modal, Tooltip}from '@mui/material';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { AButton } from '../../components/Button';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import ReportConsolidado from './ReportView/ReportConsolidado';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {PdfConsolidadosDoc} from '../../components/pdf/PdfConsolidadosDoc';


const moment = require('moment')
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id')
const globalTheme = window.templateCustomizer.settings.style;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};

const SelectChofer = (prosp) => {
  const setSelectFila = prosp.setSelectFila
  const fila = prosp.fila

  const [data, setdata] = useState([])

  const funListarChofer = useCallback(async (nom) => {
    //console.log("cadena:", cadena);
    let  cadena= nom? nom.toUpperCase():"";
    const url = `/apiAlmacenLisChoferes?vcodEmpresa=${cook_idEmp}&cadenaBuscar=${cadena}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };

    try {

      const response = await fetch(url, opPe);
      if (!response.ok) {
        throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
        return[];
      }
      const data = response.json();
      // console.log("data :", data);
      return data;
    } catch (error) {
      console.error("Error en funListarChofer:", error);
      return [];
      throw error;
    }
  }, [])

  const [selecChofer, selectChofer] = useState([""]);

  const [cadenaBuscar, setCadenabuscar] = useState('');
  const handleSelectChange = (selectedOption) => {
    selectChofer(selectedOption);
    setSelectFila(selectedOption)
  };


  return (
    <div className='td2' style={{ position: 'relative', zIndex: '3' }} >
      <AsyncSelect
        styles={styles}
        placeholder="Busque por Cod./Desc."
        name="form-field-name"
        className=" from-control form-control-sm"
        value={fila}
        cacheOptions
        defaultOptions
        getOptionLabel={e => e.Placa + '  -  ' + e.Name}
        getOptionValue={e => e.Placa}
        loadOptions={funListarChofer}
        onChange={handleSelectChange}
        maxMenuHeight={150}
      />

    </div>
  )
}

export const Report1 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,           setdataReport] = useState([]);
  const [pending,       setPending] = useState(false);
  const [emp_sl_t_suc,  emp_setSl_t_suc] = useState('');
  const [slcArticulo,   setSlcArticulo] = useState([""]);
  const funDArticulos = useCallback( async (inputValue) => {
    const url ='/apiArticulos?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue;
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[]) 
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption);  };

  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns  = useMemo( () => [
    { header: 'CODVEN',   accessorKey: 'CODVEN'},
    { header: 'VENDEDOR', accessorKey: 'VENDEDOR' },
    { header: 'COD',      accessorKey: 'COD' },
    { header: 'CLIENTE',  accessorKey: 'CLIENTE' },
    { header: 'NUMERO',   accessorKey: 'NUMERO' },
    { header: 'ESTADO',   accessorKey: 'ESTADO' },
    { header: 'UNIDAD',   accessorKey: 'UNIDAD' },
    { header: 'CANTIDAD', accessorKey: 'CANTIDAD' },
  ],[])
  const onClickListar=async()=>{
    console.log("slcArticulo:: ", slcArticulo);
    console.log("emp_sl_t_suc:: ", emp_sl_t_suc);
    if(emp_sl_t_suc==='' || slcArticulo.length==0){
      notify_warning("Ingrese la sucursal y el aticulo")
      return
    }
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiReporFAC?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}&vCodigo=${slcArticulo.Code}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    emp_setSl_t_suc('')
    setSlcArticulo([])
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE__"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
              <div className="col-lg-2"><label className="form-label mb-0 " >Sucursal:</label></div>
              <div className="col-lg-2">
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
              <div className="col-lg-2"><label className="form-label mb-0 " >Articulo:</label></div>
              <div className="col-lg-4 px-1">
                <AsyncSelect 
                  styles={styles}
                  placeholder="Busque por Cod./Desc."
                  name="form-field-name"
                  className="from-control form-control-sm my-swal border-0"  
                  value={slcArticulo}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code +' - '+ e.Name}
                  getOptionValue={e => e.Code }
                  loadOptions={funDArticulos}
                  onChange={handleSelectChange}
                  maxMenuHeight={150}
                />
              </div>
            </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip> 
            <AButton tp_tilde="Limpiar y consultar de nuevo"  tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}
export const Report3 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,                 setdataReport] = useState([]);
  const [pending,             setPending] = useState(false);
  const [emp_sl_t_suc,        emp_setSl_t_suc] = useState('');
  
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const [slcProveedor, setSlcProveedor] = useState([""]);
  const funDArticulos = useCallback( async (inputValue) => {
    const url ='/apiArticulos?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue;
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[]) 
  const funSlcProvedor = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;
  }
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption);  };

  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns  = useMemo( () => [
    // { header: 'IDSUCURSAL',     accessorKey: 'IDSUCURSAL'},
    // { header: 'IDLINEA',        accessorKey: 'IDLINEA' },
    // { header: 'IDALMACEN',      accessorKey: 'IDALMACEN' },
    // { header: 'ALMACEN',        accessorKey: 'ALMACEN' },
    // { header: 'RUC',            accessorKey: 'RUC' },
    // { header: 'RAZONSOCIAL',    accessorKey: 'RAZONSOCIAL' },
    { header: 'COD.U.NEGOCIO',accessorKey: 'IDUNIDADNEGOCIO' },
    { header: 'UNI. NEGOCIO',  accessorKey: 'UNIDADNEGOCIO' },
    { header: 'SUCURSAL',       accessorKey: 'SUCURSAL' },
    { header: 'FAMILIA',        accessorKey: 'FAMILIA' },
    { header: 'SUBFAMILIA',     accessorKey: 'SUBFAMILIA' },
    { header: 'CODIGO',         accessorKey: 'CODIGO' },
    { header: 'PRODUCTO',       accessorKey: 'PRODUCTO' },
    { header: 'UND. INVENTARIO',     accessorKey: 'UND_INVENTARIO' },
    { header: 'ENSTOCK',          accessorKey: 'ENSTOCK' },
    { header: 'COMPROMETIDO',         accessorKey: 'COMPROMETIDO' },
    // { header: 'CANTCOMPRA',     accessorKey: 'CANTCOMPRA' },
    // { header: 'UNIDADCOMPRA',   accessorKey: 'UNIDADCOMPRA' },
    { header: 'PEDIDO_OC',        accessorKey: 'PEDIDO_OC' },
    // { header: 'UNIDADREF',      accessorKey: 'UNIDADREF' },
    { header: 'STOCK_DISPONIBLE',     accessorKey: 'STOCK_DISPONIBLE' },
  ],[])

  /*
  "IDUNIDADNEGOCIO": "047",
  "SUCURSAL": "PIURA",
  "UNIDADNEGOCIO": "047 - PROCTER & GAMBLE",
  "FAMILIA": "ARTICULOS PUBLICITARIOS",
  "SUBFAMILIA": "MERCHANDISING",
  "CODIGO": "0470479",
  "PRODUCTO": "BNF_ CHALECOS HS _NV",
  "ENSTOCK": "0.000000",
  "COMPROMETIDO": "0.000000",
  "PEDIDO_OC": "0.000000",
  "STOCK_DISPONIBLE": "0.000000",
  "UND_INVENTARIO": "UND"
*/
  const onClickListar=async()=>{
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiReporFAC?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vAlmacen=${emp_sl_t_suc}&vCodProd=${slcArticulo.Code}&vCodUniNego=${slcProveedor.Code}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    emp_setSl_t_suc([])
    setSlcArticulo([])
    setSlcProveedor([])
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE STOCK ACTUAL_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
              <div className="col-lg-4">
                <label className="form-label mb-0" >ALMACEN:</label>
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
              <div className="col-lg-4">
                <label className="form-label mb-0" >Articulo:</label>
                <AsyncSelect 
                  styles={styles}
                  placeholder="Busque por Cod/Desc"
                  className="sele_pru from-control form-control-sm my-swal "  
                  value={slcArticulo}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code +' - '+ e.Name}
                  getOptionValue={e => e.Code }
                  loadOptions={funDArticulos}
                  onChange={handleSelectChange}
                  maxMenuHeight={150}
                />
              </div>
              <div className="col-lg-4">
                <label className="form-label mb-0" >UniNegocio</label>
                <AsyncSelect
                  styles={styles}
                  placeholder="Busque por Cod/Desc"
                  className="sele_pru from-control form-control-sm my-swal"
                  value={slcProveedor}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code + ' - ' + e.Name}
                  getOptionValue={e => e.Code}
                  loadOptions={funSlcProvedor}
                  onChange={setSlcProveedor}
                />
              </div>
            </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip> 
            <AButton tp_tilde="Limpiar y consultar de nuevo"  tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}
export const Report4 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,            setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());



  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns1  = useMemo( () => [
    { header: 'IDCP',       accessorKey: 'IDCP'},
    { header: 'SUCURSAL',   accessorKey: 'SUCURSAL' },
    { header: 'FECHA',      accessorKey: 'FECHA' },
    { header: 'TD',         accessorKey: 'TD' },
    { header: 'NUMERO',     accessorKey: 'NUMERO' },
    { header: 'VENDEDOR',   accessorKey: 'VENDEDOR' },
    { header: 'CODCLIENTE', accessorKey: 'CODCLIENTE' },
    { header: 'CLIENTE',    accessorKey: 'CLIENTE' },
    { header: 'SALDO',      accessorKey: 'SALDO' },
    { header: 'TOTAL',      accessorKey: 'TOTAL' },
    { header: 'NCNUMERO',   accessorKey: 'NCNUMERO' },
    { header: 'NCTOTAL',    accessorKey: 'NCTOTAL' },
    { header: 'NCSALDO',    accessorKey: 'NCSALDO' }
  ],[])
  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiReporFAC?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}&vfecD=${transFechaD}&vfecH=${transFechaH}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    emp_setSl_t_suc([])
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "DOCUMENTOS PENDIENTE DE DESPACHO - NOTA DE CREDITO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "DOCUMENTOS PENDIENTE DE DESPACHO - NOTA DE CREDITO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
              <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Desde:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaD} 
                  onChange={date => setText_fechaD(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Hasta:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaH} 
                  onChange={date => setText_fechaH(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4">
                <label className="form-label mb-0 " >Sucursal:</label>
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
            </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip>
            <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}
export const Report5 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,           setdataReport] = useState([]);
  const [pending,       setPending] = useState(false);
  // const [emp_sl_t_suc,  emp_setSl_t_suc] = useState('');
  const [text_fechaD,   setText_fechaD] = useState(new Date());
  const [text_fechaH,   setText_fechaH] = useState(new Date());
  const [slcVendedor,   setSlcVendedor] = useState([""]);
  const [slcCliente,    setSlcCliente] = useState([""]);
  const [slcArticulo,   setSlcArticulo] = useState([""]);
  const [slcProveedor,  setSlcProveedor] = useState([""]);
  const funSlcVendedor = async (inputValue) => {
    const url = '/apiVendedores?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }
  const funSlcCliente = async (inputValue) => {
    const url = '/apiClientes?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }
  const funDArticulos = useCallback( async (inputValue) => {
    const url ='/apiArticulos?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue;
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[]) 
  const funSlcProvedor = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;
  }
  // const handleSelectChangeV = (selectedOption) => { setSlcVendedor(selectedOption);  };
  // const handleSelectChangeC = (selectedOption) => { setSlcCliente(selectedOption);  };
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption);  };

  // const emp_changeSl_t_suc = (event) => { 
  //   emp_setSl_t_suc(event.target.value);
  //   $("#emp_slc_tem").focus();
  // }
  /** Primer reporte */
  const columns  = useMemo( () => [
    { header: 'ZONACODIGO',               accessorKey: 'ZONACODIGO'},
    { header: 'ZONADESCRIPCION',          accessorKey: 'ZONADESCRIPCION' },
    { header: 'VENDEDORCODIGO',           accessorKey: 'VENDEDORCODIGO' },
    { header: 'VENDEDORNOMBRE',           accessorKey: 'VENDEDORNOMBRE' },
    { header: 'CLIENTECODIGO',            accessorKey: 'CLIENTECODIGO' },
    { header: 'CLIENTENOMBRE',            accessorKey: 'CLIENTENOMBRE' },
    { header: 'CLIENTECATEGORIANOMBRE',   accessorKey: 'CLIENTECATEGORIANOMBRE' },
    { header: 'CLIENTETIPOCODIGO',        accessorKey: 'CLIENTETIPOCODIGO' },
    { header: 'CLIENTETIPONOMBRE',        accessorKey: 'CLIENTETIPONOMBRE' },
    { header: 'PRODUCTOCODIGO',           accessorKey: 'PRODUCTOCODIGO' },
    { header: 'PRODUCTODESCRIPCION',      accessorKey: 'PRODUCTODESCRIPCION' },
    { header: 'PRODUCTOUNDREF_FACTOR',    accessorKey: 'PRODUCTOUNDREF_FACTOR' },
    { header: 'PRODUCTOUNDREF_UNIDAD',    accessorKey: 'PRODUCTOUNDREF_UNIDAD' },
    { header: 'PRODUCTOFACTORCOMPRA',     accessorKey: 'PRODUCTOFACTORCOMPRA' },
    { header: 'PRODUCTOUNDCOMPRA_UNIDAD', accessorKey: 'PRODUCTOUNDCOMPRA_UNIDAD' },
    { header: 'PRODUCTOFACTORCAJABACKUS', accessorKey: 'PRODUCTOFACTORCAJABACKUS' },
    { header: 'PRODUCTOLINEAN2CODIGO',    accessorKey: 'PRODUCTOLINEAN2CODIGO' },
    { header: 'PRODUCTOLINEAN2NOMBRE',    accessorKey: 'PRODUCTOLINEAN2NOMBRE' },
    { header: 'PRODUCTOLINEAN1CODIGO',    accessorKey: 'PRODUCTOLINEAN1CODIGO' },
    { header: 'PRODUCTOLINEAN1NOMBRE',    accessorKey: 'PRODUCTOLINEAN1NOMBRE' },
    { header: 'PRODUCTOTIPOCODIGO',       accessorKey: 'PRODUCTOTIPOCODIGO' },
    { header: 'PRODUCTOTIPONOMBRE',       accessorKey: 'PRODUCTOTIPONOMBRE' },
    { header: 'PROVEEDORID',              accessorKey: 'PROVEEDORID' },
    { header: 'PROVEEDORCODIGO',          accessorKey: 'PROVEEDORCODIGO' },
    { header: 'PROVEEDORNOMBRE',          accessorKey: 'PROVEEDORNOMBRE' },
    { header: 'MARCADESCRIPCION',         accessorKey: 'MARCADESCRIPCION' },
    { header: 'FORMAPAGO',                accessorKey: 'FORMAPAGO' },
    { header: 'ITEMCANTIDADBASE',         accessorKey: 'ITEMCANTIDADBASE' },
    { header: 'ITEMBONIFICACION',         accessorKey: 'ITEMBONIFICACION' },
    { header: 'ITEMUNIDADVENTA',          accessorKey: 'ITEMUNIDADVENTA' },
    { header: 'ITEMCANTIDADCONTADO',      accessorKey: 'ITEMCANTIDADCONTADO' },
    { header: 'ITEMCANTIDADCREDITO',      accessorKey: 'ITEMCANTIDADCREDITO' },
    { header: 'ITEMCANTIDADBONIFICACION', accessorKey: 'ITEMCANTIDADBONIFICACION' },
    { header: 'ITEMMONTOCONTADO',         accessorKey: 'ITEMMONTOCONTADO' },
    { header: 'ITEMMONTOCREDITO',         accessorKey: 'ITEMMONTOCREDITO' },
    { header: 'PRODUCTOLINEAN3CODIGO',    accessorKey: 'PRODUCTOLINEAN3CODIGO' },
    { header: 'PRODUCTOLINEAN3NOMBRE',    accessorKey: 'PRODUCTOLINEAN3NOMBRE' }

  ],[])
  const onClickListar=async()=>{
    setdataReport([])
    setPending(true)
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    let arrvVend = slcVendedor.map(option => option.Code)
    let arrvClie = slcCliente.map(option => option.Code)
    let arrvProd = slcArticulo.map(option => option.Code)
    let arrvProv = slcProveedor.map(option => option.Code)
    // console.log("arrvProv", arrvProv);


    const url_lstClientes = `/apiReporFAC?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vfecD=${transFechaD}&vfecH=${transFechaH}&arrvVend=${arrvVend}&arrvClie=${arrvClie}&arrvProd=${arrvProd}&arrvProv=${arrvProv}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
    setSlcVendedor([])
    setSlcCliente([])
    setSlcArticulo([])
    setSlcProveedor([])
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "RESUMEN DE VENTAS POR CLIENTE_  "+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "RESUMEN DE VENTAS POR CLIENTE_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
            <div className="col-lg-3" style={{ zIndex: '5' }}>
              <label className="form-label mb-0 " >Fecha Desde:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD} 
                onChange={date => setText_fechaD(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-3" style={{ zIndex: '5' }}>
              <label className="form-label mb-0 " >Fecha Hasta:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaH} 
                onChange={date => setText_fechaH(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-6" style={{ zIndex: '4' }}>
              <label className="form-label mb-0" >Vendedor:</label>
              <AsyncSelect 
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm  "  
                value={slcVendedor}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code +' - '+ e.Name}
                getOptionValue={e => e.Code }
                loadOptions={funSlcVendedor}
                //onChange={setSlcVendedor}
                //maxMenuHeight={150}
                onChange={(e)=>{
                  setSlcVendedor(e)
                  setTimeout(() =>{
                  $('.css-wsp0cs-MultiValueGeneric').each(function() {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split(' -')[0].trim();
                      $(this).text(textoCorto);
                  });
                  },1000)
                }}
              />
            </div>
            <div className="col-lg-6" style={{ zIndex: '4' }}>
              <label className="form-label mb-0" >Cliente:</label>
              <AsyncSelect 
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm  "  
                value={slcCliente}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code +' - '+ e.Name}
                getOptionValue={e => e.Code }
                loadOptions={funSlcCliente}
                //onChange={setSlcCliente}
                //maxMenuHeight={150}
                onChange={(e)=>{
                  setSlcCliente(e)
                  setTimeout(() =>{
                  $('.css-wsp0cs-MultiValueGeneric').each(function() {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split('-')[0].trim();
                      $(this).text(textoCorto);
                  });
                  },1000)
                }}
              />
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-6" style={{ zIndex: '3' }}>
              <label className="form-label mb-0" >Producto:</label>
              <AsyncSelect 
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm "  
                value={slcArticulo}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code +' - '+ e.Name}
                getOptionValue={e => e.Code }
                loadOptions={funDArticulos}
                //onChange={handleSelectChange}
                onChange={(e)=>{
                  handleSelectChange(e)
                  setTimeout(() =>{
                  $('.css-wsp0cs-MultiValueGeneric').each(function() {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split('-')[0].trim();
                      $(this).text(textoCorto);
                  });
                  },1000)
                }}
              />
            </div>
            <div className="col-lg-6" style={{ zIndex: '3' }}>
              <label className="form-label mb-0" >Proveedor</label>
              <AsyncSelect
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm "
                value={slcProveedor}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code +' - '+ e.Name}
                getOptionValue={e => e.Code}
                loadOptions={funSlcProvedor}
                onChange={(e)=>{
                  setSlcProveedor(e)
                  setTimeout(() =>{
                  $('.css-wsp0cs-MultiValueGeneric').each(function() {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split('-')[0].trim();
                      $(this).text(textoCorto);
                  });
                  },1000)
                }}
                
              />
            </div>
          </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>&nbsp;
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip> 
            <AButton tp_tilde="Limpiar y consultar de nuevo"  tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}
export const Report6 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,            setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());



  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns1  = useMemo( () => [
    { header: 'ANNO',           accessorKey: 'ANNO'},
    { header: 'MES',            accessorKey: 'MES' },
    { header: 'DIA',            accessorKey: 'DIA' },
    { header: 'TIPOCLIENTE',    accessorKey: 'TIPOCLIENTE' },
    { header: 'SUBCATEGORIA',   accessorKey: 'SUBCATEGORIA' },
    { header: 'IMPORTE',        accessorKey: 'IMPORTE' },
    { header: 'IMPORTEOFICINA', accessorKey: 'IMPORTEOFICINA' }
  ],[])
  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiReporFAC?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}&vfecD=${transFechaD}&vfecH=${transFechaH}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    emp_setSl_t_suc([])
    setText_fechaD(new Date());
    setText_fechaH(new Date());
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "RESUMEN DE VENTAS ANUALIZADO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "RESUMEN DE VENTAS ANUALIZADO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
              <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Desde:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaD} 
                  onChange={date => setText_fechaD(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Hasta:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaH} 
                  onChange={date => setText_fechaH(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4">
                <label className="form-label mb-0 " >Sucursal:</label>
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
            </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip> 
            <AButton tp_tilde="Limpiar y consultar de nuevo"  tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}
export const Report7 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,           setdataReport] = useState([]);
  const [pending,       setPending] = useState(false);
  // const [emp_sl_t_suc,  emp_setSl_t_suc] = useState('');
  const [text_fechaD,   setText_fechaD] = useState(new Date());
  const [text_fechaH,   setText_fechaH] = useState(new Date());
  const [slcSucursal,   setSlcSucursal] = useState([""]);
  const [slcVendedor,   setSlcVendedor] = useState([""]);
  const [slcArticulo,   setSlcArticulo] = useState([""]);
  const funSlcVendedor = async (inputValue) => {
    const url = '/apiVendedores?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }
  const funSlcSucursal = async (inputValue) => {
    const url = '/apiSucursal?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }
  const funDArticulos = useCallback( async (inputValue) => {
    const url ='/apiArticulos?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue;
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[]) 
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption);  };

  // const emp_changeSl_t_suc = (event) => { 
  //   emp_setSl_t_suc(event.target.value);
  //   $("#emp_slc_tem").focus();
  // }
  /** Primer reporte */
  const columns  = useMemo( () => [
    { header: 'IDCP',         accessorKey: 'IDCp'},
    { header: 'CPFECHA',      accessorKey: 'CpFecha' },
    { header: 'IDSUCURSAL',   accessorKey: 'IDSucursal' },
    { header: 'SUCURSAL',     accessorKey: 'Sucursal' },
    { header: 'IDPRODUCTO',   accessorKey: 'IDProducto' },
    { header: 'CODPRODUCTO',  accessorKey: 'CodProducto' },
    { header: 'PRODUCTO',     accessorKey: 'Producto' },
    { header: 'CANTIDAD',     accessorKey: 'Cantidad' },
    { header: 'UNIDAD',       accessorKey: 'Unidad' },
    { header: 'CODVENDEDOR',  accessorKey: 'CodVendedor' },
    { header: 'VENDEDOR',     accessorKey: 'Vendedor' },
    { header: 'CODCLIENTE',   accessorKey: 'CodCliente' },
    { header: 'CLIENTE',      accessorKey: 'Cliente' },
    { header: 'TOTAL',        accessorKey: 'Total' }

  ],[])
  const onClickListar = async () => {
   
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    let arrvSucu = slcSucursal.map(option => option.Code)
    let arrvVend = slcVendedor.map(option => option.Code)
    let arrvProd = slcArticulo.map(option => option.Code)

    if (!transFechaD || !transFechaH) {
      notify_warning("Ingrese las fechas")
      return;
    }
    if (!arrvSucu.length) {
      notify_warning("Seleccione la sucursal");
      return;
    }
    if (!arrvProd.length) {
      notify_warning("Seleccione le producto");
      return;
      // Maneja el error según sea necesario
    }
    setdataReport([])
    setPending(true)
    const url_lst = `/apiReporFAC?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vfecD=${transFechaD}&vfecH=${transFechaH}&arrvVend=${arrvVend}&arrvSucu=${arrvSucu}&arrvProd=${arrvProd}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lst, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
    setSlcVendedor([])
    setSlcSucursal([])
    setSlcArticulo([])
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "LISTA DE COMPROBANTES POR PRODUCTOS_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "LISTA DE COMPROBANTES POR PRODUCTOS_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
            <div className="col-lg-3" style={{ zIndex: '5' }}>
              <label className="form-label mb-0 " >Fecha Desde:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD} 
                onChange={date => setText_fechaD(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-3" style={{ zIndex: '5' }}>
              <label className="form-label mb-0 " >Fecha Hasta:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaH} 
                onChange={date => setText_fechaH(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-4" style={{ zIndex: '4' }}>
              <label className="form-label mb-0" >Sucursal:</label>
              <AsyncSelect 
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm  "  
                value={slcSucursal}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code +' - '+ e.Name}
                getOptionValue={e => e.Code }
                loadOptions={funSlcSucursal}
                //onChange={setSlcCliente}
                //maxMenuHeight={150}
                onChange={(e)=>{
                  setSlcSucursal(e)
                  setTimeout(() =>{
                  $('.css-wsp0cs-MultiValueGeneric').each(function() {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split('-')[0].trim();
                      $(this).text(textoCorto);
                  });
                  },1000)
                }}
              />
            </div>
            <div className="col-lg-4" style={{ zIndex: '4' }}>
              <label className="form-label mb-0" >Vendedor:</label>
              <AsyncSelect 
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm  "  
                value={slcVendedor}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code +' - '+ e.Name}
                getOptionValue={e => e.Code }
                loadOptions={funSlcVendedor}
                //onChange={setSlcVendedor}
                //maxMenuHeight={150}
                onChange={(e)=>{
                  setSlcVendedor(e)
                  setTimeout(() =>{
                    $('.css-wsp0cs-MultiValueGeneric').each(function() {
                        var textoCompleto = $(this).text();
                        var textoCorto = textoCompleto.split(' -')[0].trim();
                        $(this).text(textoCorto);
                    });
                  },1000)
                  // setTimeout(() =>{
                  //   $('.css-1p3m7a8-multiValue [class^=" css-"]').each(function() {
                  //       var textoCompleto = $(this).text();
                  //       console.log("textoCompleto", textoCompleto)
                  //       var textoCorto = textoCompleto.split(' -')[0].trim();
                  //       console.log("textoCorto", textoCorto)

                  //       $(this).text(textoCorto);
                  //   });
                  // },1000)
                }}
              />
            </div>
            <div className="col-lg-4" style={{ zIndex: '3' }}>
              <label className="form-label mb-0" >Producto:</label>
              <AsyncSelect 
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm "  
                value={slcArticulo}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code +' - '+ e.Name}
                getOptionValue={e => e.Code }
                loadOptions={funDArticulos}
                //onChange={handleSelectChange}
                onChange={(e)=>{
                  handleSelectChange(e)
                  setTimeout(() =>{
                  $('.css-wsp0cs-MultiValueGeneric').each(function() {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split('-')[0].trim();
                      $(this).text(textoCorto);
                  });
                  },1000)
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>&nbsp;
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip> 
            <AButton tp_tilde="Limpiar y consultar de nuevo"  tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}
export const Report8 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,           setdataReport] = useState([]);
  const [pending,       setPending] = useState(false);
  const [emp_sl_t_suc,  emp_setSl_t_suc] = useState('');
  //const [slcArticulo,   setSlcArticulo] = useState([""]);
  // const funDArticulos = useCallback( async (inputValue) => {
  //   const url ='/apiArticulos?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue;
  //   const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
  //   const response = await fetch(url,opPe);
  //   const data = await response.json();
  //   return data;
  // },[]) 
  // const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption);  };

  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns  = useMemo( () => [
    { header: 'SUCURSALNOMBRE',         accessorKey: 'SUCURSALNOMBRE'},
    { header: 'PRODUCTONOMBRE',         accessorKey: 'PRODUCTONOMBRE' },
    { header: 'PRODUCTOCODIGO',         accessorKey: 'PRODUCTOCODIGO' },
    { header: 'UNIDADABREV',            accessorKey: 'UNIDADABREV' },
    { header: 'UNIDADFACTOR',           accessorKey: 'UNIDADFACTOR' },
    { header: 'COSTOINCIMPTO',          accessorKey: 'COSTOINCIMPTO' },
    { header: 'PRECIOINCIMPTO_BOD',     accessorKey: 'PRECIOINCIMPTO_BOD' },
    { header: 'UTILIDADPORCENTUAL_BOD', accessorKey: 'UTILIDADPORCENTUAL_BOD' },
    { header: 'PRECIOINCIMPTO_MER',     accessorKey: 'PRECIOINCIMPTO_MER' },
    { header: 'UTILIDADPORCENTUAL_MER', accessorKey: 'UTILIDADPORCENTUAL_MER' },
    { header: 'PRECIOINCIMPTO_MAY',     accessorKey: 'PRECIOINCIMPTO_MAY' },
    { header: 'UTILIDADPORCENTUAL_MAY', accessorKey: 'UTILIDADPORCENTUAL_MAY' },
  ],[])
  const onClickListar=async()=>{
    setdataReport([])
    setPending(true)
    //const url_lstClientes = `/apiReporFAC?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}&vCodigo=${slcArticulo.Code}`;
    const url_lstClientes = `/apiReporFAC?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    emp_setSl_t_suc([])
    // setSlcArticulo([])
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "LISTA DE PRECIO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "LISTA DE PRECIO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
              <div className="col-lg-2"><label className="form-label mb-0 " >Sucursal:</label></div>
              <div className="col-lg-2">
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
              {/* <div className="col-lg-2"><label className="form-label mb-0 " >Articulo:</label></div>
              <div className="col-lg-4 px-1">
                <AsyncSelect 
                  styles={styles}
                  placeholder="Busque por Cod./Desc."
                  name="form-field-name"
                  className="from-control form-control-sm my-swal border-0"  
                  value={slcArticulo}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code +' - '+ e.Name}
                  getOptionValue={e => e.Code }
                  loadOptions={funDArticulos}
                  onChange={handleSelectChange}
                  maxMenuHeight={150}
                />
              </div> */}
            </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip> 
            <AButton tp_tilde="Limpiar y consultar de nuevo"  tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
          
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
        />
      </div>
    </>
  )
}
export const Report9 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,            setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());



  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns1  = useMemo( () => [
    // { header: 'ANNO',           accessorKey: 'ANNO'},
    // { header: 'MES',            accessorKey: 'MES' },
    // { header: 'DIA',            accessorKey: 'DIA' },
    // { header: 'TIPOCLIENTE',    accessorKey: 'TIPOCLIENTE' },
    // { header: 'SUBCATEGORIA',   accessorKey: 'SUBCATEGORIA' },
    // { header: 'IMPORTE',        accessorKey: 'IMPORTE' },
    // { header: 'IMPORTEOFICINA', accessorKey: 'IMPORTEOFICINA' }
    { header: 'SUCURSAL',           accessorKey: 'SUCURSAL'},
    { header: 'ALMACEN',            accessorKey: 'ALMACEN' },
    { header: 'FECHA',          accessorKey: 'FECHA' },
    { header: 'TD',            accessorKey: 'TD' },
    { header: 'NUMERO',            accessorKey: 'NUMERO' },
    { header: 'CODIGO',    accessorKey: 'CODIGO' },
    { header: 'DESCRIPCION',   accessorKey: 'DESCRIPCION' },
    { header: 'UNIDAD',        accessorKey: 'UNIDAD' },
    { header: 'FACTOR', accessorKey: 'FACTOR' },
    { header: 'CANTIDAD', accessorKey: 'CANTIDAD' },
    { header: 'LOTE', accessorKey: 'LOTE' },
    { header: 'VCTO', accessorKey: 'VCTO' },
    { header: 'VENDEDOR', accessorKey: 'VENDEDOR' },
    { header: 'CODCLIENTE', accessorKey: 'CODCLIENTE' },
    { header: 'CLIENTE', accessorKey: 'CLIENTE' },
    { header: 'SALDO', accessorKey: 'SALDO' },
  ],[])
  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiReporFAC?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vfecD=${transFechaD}&vfecH=${transFechaH}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    emp_setSl_t_suc([])
    setText_fechaD(new Date());
    setText_fechaH(new Date());
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "DOCUMENTOS PENDIENTES DE DESPACHO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "DOCUMENTOS PENDIENTES DE DESPACHO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
              <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Desde:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaD} 
                  onChange={date => setText_fechaD(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Hasta:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaH} 
                  onChange={date => setText_fechaH(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4">
                {/* <label className="form-label mb-0 " >Sucursal:</label> */}
                {/* <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} /> */}
              </div>
            </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip> 
            <AButton tp_tilde="Limpiar y consultar de nuevo"  tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}
export const Report10 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,     setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [slcVehiculo,    setslcVehiculo] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [placas, setplacas] = useState('');
  const [pdfSrc, setPdfSrc] = useState('');
  const [nombreSucursal,setnombreSucursal]= useState('')
  
  const [selecConsolidado,       setSeleConsolidado] = useState('');
  const changeSelecConsolidado = (event) => { setSeleConsolidado(event.target.value); }


  const hanOpenModal = () => { 
    if (dataReport.length == 0 ){
      notify_warning("No existen datos, verificar campos")
      return
    }  
    setOpenModal(true); }
  const hanClosModal = () => { setOpenModal(false); }

  
  const funDArticulos = useCallback( async (inputValue) => {
    // console.log("inputValue::", inputValue);
    const url ='/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar='+inputValue+'&sucursal='+emp_sl_t_suc;
    // const url ='/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar='+inputValue+'&sucursal=002';
    // console.log("url:", url);
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    // console.log("Data::", data);
    return data;
  },[emp_sl_t_suc]) 



  const handleSelectChange = (selectedOption) => { setslcVehiculo(selectedOption);  };
  

  useEffect(() => {
    // Cargar datos por defecto al montar el componente
    setdataReport([])
    setslcVehiculo([])
    // const loadDefaultOptions = async () => {
    //   const defaultData = await funDArticulos('');
    //   setDefaultOptions(defaultData);
    // };
    // loadDefaultOptions();
  }, [emp_sl_t_suc ]);


  const emp_changeSl_t_suc = (event) => { 
     // logica para capturar el nombre de la sucursal
    // se usara en el nombre del archivo 
    const selectedIndex = event.target.selectedIndex;
    const selectedText = event.target.options[selectedIndex].text;
    const nombre = selectedText ? selectedText : '';
    setnombreSucursal(nombre)
    emp_setSl_t_suc(event.target.value);

    console.log(nombre);
    
    $("#emp_slc_tem").focus();
  }


  /** Primer reporte */
  const columns1 = useMemo(() => [
    { header: 'NOMBRE', accessorKey: 'CardName' },
    { header: 'CODIGO', accessorKey: 'SlpName' },
    {
      header: 'FECHA', 
        accessorKey: 'DocDate',
        Cell: ({ cell }) => {
            // return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return moment(cell.value).format('YYYY-MM-DD');
            // return moment(date).format('YYYYMMDD'); // Formatea la fecha como YYYYMMDD
        }
    },
    { header: 'CONDICION', accessorKey: 'Condicion' },
    { header: 'IMPORTE', accessorKey: 'Importe' },
    { header: 'COBRANZA', accessorKey: 'Cobranza' },
    { header: 'DOCUMENTO', accessorKey: 'Documento' },
    { header: 'SUCURSAL', accessorKey: 'PrcName' },
    { header: 'OBSERVACION', accessorKey: 'Observacion' }
  ], [])
  const onClickListar=async()=>{
    try {
      
   console.log(selecConsolidado);
   
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');

    let placa = slcVehiculo.map(option => option.Placa)
    // console.log("arrVehiculo::", arrVehiculo);
    // let placa = arrVehiculo.join(',')// placas.Placa
    setplacas(placa)
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiReporFactConsoliDocumentPDF?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&placa=${selecConsolidado}&vfecD=${transFechaD}&sucursal=${emp_sl_t_suc}&nomSucur=${nombreSucursal}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    if (response.status === 204) {
      notify_warning("No se encontraron registros ")
      return
    }
    const resData = await response.json();
    // res.status(200).json({ pdf: pdf, data: arr_data2 });
    const daraProcesada = resData.data;
    const  resDocPDf = resData.pdf;
    setPdfSrc(`data:application/pdf;base64,${resDocPDf}`);
    setdataReport(daraProcesada)
  } catch (error) {
      
  }finally{
    setPending(false)

  }
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    emp_setSl_t_suc([])
    setText_fechaD(new Date());
    setslcVehiculo([])
  }

  


  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE-CONSOLIDADO DE DOCUMENTOSS_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      // const response = await fetch(url0,opti_t);
      // const datadtos = await response.json();
      //console.log("--saas",datadtos);
      //setData(datadtos)
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  const onClickExcel = async () => {
      //console.log(sl_t_unid.desLinea);
      const fileType = "xlsx"
      const nameFileType = "REPORTE-CONSOLIDADO DE DOCUMENTOS_"+moment(new Date()).format('YYYYMMDD HH:mm')
      const showData = async () => {
        if (dataReport.length !== 0 ) {
          const ws = XLSX.utils.json_to_sheet(dataReport);
          // Cabecera del archivo 
          // const header = []; // Cambia esto según tus necesidades
          // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
          const wb = {Sheets:{data:ws}, SheetNames:["data"]};
          const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
          const data = new Blob([excelBuffer], {type:fileType});
          FileSaver.saveAs(data, `${nameFileType}.xlsx`);
        }else{
          notify_warning("No existen datos, verificar campos")
        }
      }
      showData();
  }

  const handleDescargarPDF = () => {
    // Crear un enlace temporal
    const link = document.createElement('a');
    link.href = pdfSrc;
    // Nombre del archivo 
    link.download = 'R001_CONSOLIDADO_DE_DOCUMENTOS.pdf'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  return (
    <>
      <div className="row ">
        <div className="col-lg-2">
          <label className="form-label mb-0 " >Sucursal:</label>
          <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
        </div>
        <div className="col-lg-2" style={{ zIndex: '3' }}>
          <label className="form-label mb-0 " >Fecha Desde:</label>
          <DatePicker
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center"
            selected={text_fechaD}
            onChange={date => setText_fechaD(date)}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="col-lg-3">
              <label className="form-label mb-0 " >Consolidado:</label>
              <SelectTipo id="slc_t_tga" url={`/apiConsulCON?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}&vFecha=${moment(text_fechaD).format('YYYY-MM-DD')}`} value={selecConsolidado} onChange={changeSelecConsolidado} />
            </div>
        
        {/* <div className="col-lg-4" style={{ zIndex: '4' }}>
          <label className="form-label mb-0 " >Chofer:</label>
          <SelectChofer setSelectFila={setselectChofer} fila={selecChofer} />
          <SelectTipo id="slc_t_tga" url={'/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar=&sucursal='+emp_sl_t_suc} value={slcVehiculo} onChange={setslcVehiculo}  /> */}

          {/*  Comentado ahora se consulta solo lo de fecha y sucursal  */}
          {/* <AsyncSelect
            isMulti
            //styles={styles}
            placeholder="Busque por Cod/Desc"
            className="sele_pru from-control form-control-sm "
            value={slcVehiculo}
            cacheOptions
            defaultOptions={defaultOptions}
            getOptionLabel={e => e.Placa + '   /   ' + e.Name}
            getOptionValue={e => e.Placa}
            loadOptions={funDArticulos}
            // loadOptions={(inputValue) => funDArticulos(inputValue, emp_sl_t_suc)} 
            //onChange={handleSelectChange}
            // onClick={(inputValue) => funDArticulos(inputValue, emp_sl_t_suc)} 
            onChange={(e) => {
              handleSelectChange(e)
              setslcVehiculo(e)
              setTimeout(() => {
                $('.css-wsp0cs-MultiValueGeneric').each(function () {
                  var textoCompleto = $(this).text();
                  var textoCorto = textoCompleto.split('/')[0].trim();
                  $(this).text(textoCorto);
                });
              }, 1000)
            }}
          /> */}
        {/* </div> */}
        <div className="col-lg-4 text-end">
          <Tooltip title="Consultar información" placement="top">
            <>
              <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
              </button>
            </>
          </Tooltip>
          {dataReport && dataReport.length > 0 && (

            <>
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
          {/* PdfConsolidadosDoc */}
          {/* <PDFDownloadLink className="btn btn-sm  btn-label-youtube" document={<PdfConsolidadosDoc datallegada={dataReport} placa={placas} />} fileName="R001_CONSOLIDADO_DE_DOCUMENTOS.pdf">
            {({ blob, url, loading, error }) =>
              loading ? 'Cargando PDF...' : <i className="bx bx-xs bxs-file-pdf" ></i>
            }
              </PDFDownloadLink>   */}
              <AButton tp_tilde="Visualizar Rep View" tp_posit="top" bt_class="btn btn-label-info btn-sm" bt_funct={hanOpenModal} ic_class="bx bxs-spreadsheet bx-ms" vl_tpbtn={2} />
            </>
          )}
          {dataReport && dataReport.length > 0 && (
                <AButton tp_tilde="Descargar PDF" id="btn3" tp_posit="top" bt_class="btn btn-sm  btn-label-youtube" bt_funct={handleDescargarPDF} ic_class="bx bx-xs bxs-file-pdf" vl_tpbtn={2} />
              )}
          <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>


      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}

        />
      </div>

      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '100%', height: '100%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={hanClosModal}
          >
            <i className='fa fa-x'></i>
          </button>


          <div className="py-3" style={{ userSelect: 'none' }}>
            <ReportConsolidado
              fecha={moment(text_fechaD).format('YYYY-MM-DD')}
              placa={selecConsolidado}
              data={dataReport}

            />
          </div>
        </Box>
      </Modal>

    </>
  )
}
export const Report11 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,           setdataReport] = useState([]);
  const [pending,       setPending] = useState(false);
  const [emp_sl_t_suc,  emp_setSl_t_suc] = useState('');
  const [slcArticulo,   setSlcArticulo] = useState([""]);
  const funDArticulos = useCallback( async (inputValue) => {
    const url = '/apiConsulProveedor'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[]) 
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption);  };

  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns  = useMemo( () => [
    { header: 'Sucursal', accessorKey: 'sucursal' },
    { header: 'Codigo', accessorKey: 'codigo' },
    { header: 'Descripcion', accessorKey: 'nombre' },
    { header: 'Unidad de Medida', accessorKey: 'unidad' },
    { header: 'May.Contado+IGV,', accessorKey: 'MAY_CON'},
    { header: 'Bod.Contado+IGV,', accessorKey: 'BOD_CON'},
    { header: 'Mer.Contado+IGV,', accessorKey: 'MER_CON'},
    { header: 'Stock', accessorKey: 'Stock'},
    { header: 'Costo+IGV', accessorKey: 'Costo'},
    // { header: 'M.MayAsig.', accessorKey: 'fMAYASIG' },
    // { header: 'M.BodAsig.', accessorKey: 'fBODASIG' },
    // { header: 'M.MerAsig,', accessorKey: 'fMERASIG' },
    { header: 'UnidadNegocio,', accessorKey: 'UniNegocio' },
    { header: 'DesUniN,', accessorKey: 'desUniN' },
    { header: 'CodProveedor,', accessorKey: 'codProv' },
    { header: 'DescProveedor,', accessorKey: 'desProv' },
  ],[])
  const onClickListar=async()=>{
    if(emp_sl_t_suc === '' ){
      notify_warning('Seleccione la sucursal.');
      return
    }
    // if(slcArticulo.Code=== undefined  ){
    //   // condicion en caso se selcione por la unidad 
    //   notify_warning('Seleccione la unidad de negocio.');
    // }
    setdataReport([])
    setPending(true)
    const uneg =  slcArticulo.Code
    const tipofilter =  1
    const filtro ='unidadN'
    const body = { vcodEmpresa: cook_idEmp, uneg: uneg, vcodSucursal: emp_sl_t_suc , tipofilter:tipofilter, filtro:filtro};
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch('/apiReportFactListaP', opPe);
    const data = await response.json();

    const arrReport = []
    data?.map((item) =>{
      
      return arrReport.push({ 
        "sucursal":emp_sl_t_suc,
        "codigo":item.codigo,
        "nombre":item.nombre,
        "unidad":item.unidad,
        "MAY_CON":parseFloat(item.MAYCON).toFixed(3),
        "BOD_CON":parseFloat(item.BODCON).toFixed(3),
        "MER_CON":parseFloat(item.MERCON).toFixed(3),
        "Stock":parseFloat(item.Stock).toFixed(3),
        "Costo":parseFloat(item.Costo).toFixed(3),
        // "fMAYASIG":item.fMAYASIG,
        // "fBODASIG":item.fBODASIG,
        // "fMERASIG":item.fMERASIG,
        "UniNegocio":item.UniNegocio,
        "desUniN":item.desUniN,
        "codProv":item.codProv,
        "desProv":item.desProv,
      })
     
    })
    setdataReport(arrReport)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    emp_setSl_t_suc([])
    // setSlcArticulo([])
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "LISTA DE PRECIO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "LISTA DE PRECIO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
              <div className="col-lg-2"><label className="form-label mb-0 " >Sucursal:</label></div>
              <div className="col-lg-2">
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
               <div className="col-lg-2"><label className="form-label mb-0 " >Proveedor:</label></div>
              <div className="col-lg-4 px-1">
                <AsyncSelect 
                  styles={styles}
                  placeholder="Busque por Cod./Desc."
                  name="form-field-name"
                  className="from-control form-control-sm my-swal border-0"  
                  value={slcArticulo}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code +' - '+ e.Name}
                  getOptionValue={e => e.Code }
                  loadOptions={funDArticulos}
                  onChange={handleSelectChange}
                  maxMenuHeight={150}
                />
              </div> 
            </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip> 
            <AButton tp_tilde="Limpiar y consultar de nuevo"  tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
          
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
        />
      </div>
    </>
  )
}




const ReportFact = () => {
  //const cookies = new Cookies();
  //const cook_idEmp = cookies.get('dtEmp_id');
  const [slTipoReport,        setSlTipoReport] = useState('');

  const changeSlTipoReport = (event) => { 
    setSlTipoReport(event.target.value);
  }

  return (
    <div>
        <h6 className="text-muted"> Reporteria Facturacion</h6>
        <div className="card card-action">
            <div className="card-body ">
              <div className="row mb-2">
                <div className="col-lg-10">
                  <div className="row ">
                    <div className="col-lg-2"><label className="form-label mb-0 " >Tipo Reporte:</label></div>
                    <div className="col-lg-10">
                      <select id="slc_t_con" className="form-select form-select-sm" value={slTipoReport} onChange={changeSlTipoReport} >
                        <option value={"9999"} >--Seleccione--</option>
                        {/* <option value={"1"} > AB023 : Productos - Pedidos pendientes </option>
                        <option value={"2"} > AB023-PER : Prod. - Pedidos pendientes Bonoficación </option>
                        <option value={"3"} > AB039 : Stock Actual</option>
                        <option value={"4"} > AB053 : Doc. pendientes de despacho - nota credito </option>
                        <option value={"5"} > VNT008 : Resumen de ventas por cliente </option>
                        <option value={"6"} > VNT012 : Resumen de ventas - anualizado </option>
                        <option value={"7"} > AB067 : Listado de comprobantes por Producto </option>
                        <option value={"8"} > AB078 : Lista de precio </option>
                        <option value={"9"} > AB049 : Documentos - Pendientes de despacho </option> */}

                        {/* Los reporets ocualtado ya no seran necesarios, tiene la vision de flex */}
                        {/* <option value={"1"} > ABFAC001 : Productos - Pedidos pendientes </option> */}
                        {/* <option value={"2"} > ABFAC002 : Prod. - Pedidos pendientes Bonoficación </option> */}
                        <option value={"3"} > ABFAC003 : Stock Actual</option>
                        {/* <option value={"4"} > ABFAC004 : Doc. pendientes de despacho - nota credito </option> */}
                        <option value={"5"} > ABFAC005 : Resumen de ventas por cliente </option>
                        <option value={"6"} > ABFAC006 : Resumen de ventas - anualizado </option>
                        <option value={"7"} > ABFAC007 : Listado de comprobantes por Producto </option>
                        <option value={"8"} > ABFAC008 : Lista de precio </option>
                        <option value={"9"} > ABFAC009 : Documentos - Pendientes de despacho </option>
                        <option value={"10"} > ABFAC0010 : Consolidados de documentos </option>
                        <option value={"11"} > ABFAC0011 : Lista de precio por canal </option>


                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {
                (slTipoReport === '1' || slTipoReport === '2' ) && (
                  <Report1 
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '3' ) && (
                  <Report3 
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '4' ) && (
                  <Report4
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '5' ) && (
                  <Report5 
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '6' ) && (
                  <Report6
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '7' ) && (
                  <Report7
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '8' ) && (
                  <Report8
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '9' ) && (
                  <Report9
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '10' ) && (
                  <Report10
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '11' ) && (
                  <Report11
                    dataTipo={slTipoReport}
                  />
                )
              }
              




              
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}

export default ReportFact

