import React, { useCallback, useMemo, useState } from 'react'
import '../../App.css';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { AButton } from '../../components/Button';
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';

import PdfEJEM from "../../components/pdf/PdfEjemplo";
import { PDFDownloadLink } from "@react-pdf/renderer"
import AsyncSelect from 'react-select/async';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box, Modal, Tooltip } from '@mui/material';
import PdfAlmacenT001 from "../../components/pdf/PdfAlmacenT001";
import { notify_warning } from '../../components/utils';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import ReportLiquidacion from './ReportView/ReporViewLiquidacion';
import { PdfLiquidacionTrans } from '../../components/pdf/PdfLiquidacion';
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales';


const globalTheme = window.templateCustomizer.settings.style;

const moment = require('moment')
const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const SelectChofer = (prosp) => {
  const setSelectFila = prosp.setSelectFila
  const fila = prosp.fila

  const [data, setdata] = useState([])

  const funListarChofer = useCallback(async (nom) => {
    //console.log("cadena:", cadena);
    let cadena = nom ? nom.toUpperCase() : "";
    const url = `/apiAlmacenLisChoferes?vcodEmpresa=${cook_idEmp}&cadenaBuscar=${cadena}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };

    try {

      const response = await fetch(url, opPe);
      if (!response.ok) {
        throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
        return [];
      }
      const data = response.json();
      // console.log("data :", data);
      return data;
    } catch (error) {
      console.error("Error en funListarChofer:", error);
      return [];
      throw error;
    }
  }, [])

  const [selecChofer, selectChofer] = useState([""]);

  const [cadenaBuscar, setCadenabuscar] = useState('');
  const handleSelectChange = (selectedOption) => {
    selectChofer(selectedOption);
    setSelectFila(selectedOption)
  };


  return (
    <div className='td2' style={{ position: 'relative', zIndex: '3' }} >
      <AsyncSelect
        styles={styles}
        placeholder="Busque por Cod./Desc."
        name="form-field-name"
        className=" from-control form-control-sm"
        value={fila}
        cacheOptions
        defaultOptions
        getOptionLabel={e => e.Placa + '  -  ' + e.Name}
        getOptionValue={e => e.Placa}
        loadOptions={funListarChofer}
        onChange={handleSelectChange}
        maxMenuHeight={150}
      />

    </div>
  )
}

export const REPcolaboradores = () => {
  const dataTipo = 1;
  const [dataReport, setdataReport] = useState([]);
  const [nombreSucursal,  setnombreSucursal] = useState('');
  const [emp_sl_t_suc,    emp_setSl_t_suc] = useState('');

  const [pending, setPending] = useState(false);

  const changeSl_t_suc = (event) => {
    // logica para capturar el nombre de la sucursal
    // se usara en el nombre del archivo 
    const selectedIndex = event.target.selectedIndex;
    const selectedText = event.target.options[selectedIndex].text;
    const nombre = selectedText ? selectedText : '';
    setnombreSucursal(nombre)
    emp_setSl_t_suc(event.target.value);
  }

  const onClickListar = async () => {
    if (!emp_sl_t_suc || emp_sl_t_suc === '9999') {
      emp_setSl_t_suc('');
      setnombreSucursal('')
    }
    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiReporComercial?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&sucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    if (data.length === 0) {
      notify_warning("¡No de encontraron registros !")
    }
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    emp_setSl_t_suc('')
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE-COLABORADORES_" + nombreSucursal + '_' + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "buffer" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE-COLABORADORES_" + nombreSucursal + '_' + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() => [
    { header: 'CODIGO VENDEDOR', accessorKey: 'Codigo Vendedor' },
    { header: 'NOMBRE VENDEDOR', accessorKey: 'Nombre Vendedor' },
    { header: 'FAX', accessorKey: 'Fax' },
    { header: 'CORREO', accessorKey: 'Correo' },
    {
      header: 'Activo', accessorKey: 'activo', Cell: ({ row }) => (
        <div className='align-items-center form-check form-check-success'><div className='align-items-center'><input className="form-check-input" type="checkbox" checked={row.original.Activo} readOnly /></div></div>
      )
    },
    { header: 'TELEFONO', accessorKey: 'Telefono' },
    { header: 'TELEFONO MOVIL', accessorKey: 'Telefono Movil' },
    { header: 'SUCURSAL', accessorKey: 'Sucursal' },
    { header: 'SUPERVISOR', accessorKey: 'Supervisor' },
    { header: 'TIPO DOCUMENTO IDENTIDAD', accessorKey: 'Tipo Documento Identidad' },
    { header: 'DOC IDENTIDAD', accessorKey: 'Doc Identidad' },
    { header: 'CARGO', accessorKey: 'Cargo' },
    { header: 'ASIGNA VISITA', accessorKey: 'Asigna Visita' },
    { header: 'GRUPO DE VENTA', accessorKey: 'Grupo de venta' },
    { header: 'MESA', accessorKey: 'Mesa' },
    { header: 'CANAL', accessorKey: 'Canal' },
    { header: 'MESA AB GRUPO VENTA', accessorKey: 'Mesa AB Grupo Venta' },
    { header: 'SEDE', accessorKey: 'Sede' }
  ], []);


  return (
    <div>
      <div className="card card-action">
        <div className="card-body ">
          <div className="row">
            <div className="col-lg-1">Sucursal:</div>
            <div className="col-lg-3">
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={changeSl_t_suc} />
            </div>

            <div className="col-lg-8 text-end">

              <Tooltip title="Consultar información" placement="top">
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-label-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />

            </div>
          </div>

          <hr />

          <div className="card-datatable table-responsive ">
            <MaterialReactTable
              columns={columnsReport}
              data={dataReport}
              state={{ isLoading: pending }}
              initialState={{
                density: 'compact', showColumnFilters: false,
                showGlobalFilter: false,
                columnVisibility: { clave: false, vlSema: false, },
              }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              localization={MRT_Localization_ES}
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 150 }}
              enableRowVirtualization
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 120, //make actions column wider
                },
              }}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


            />
          </div>
        </div>
      </div>
    </div>
  )

}



const ReporComercial = () => {
  return (
    <div>
      <small className="text-light fw-medium"> Reporte Comercial</small>
      <div className="row">
        <div className="col-md mb-4 mb-md-2">
          <div className="accordion mt-3" id="accordionExample">
            <div className="card accordion-item ">
              <h2 className="accordion-header d-flex align-items-center" id="headingOne">
                <button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="true" aria-controls="accordionOne">
                  <i class="fa-solid fa-users me-2"></i>
                  Colaboradores
                </button>
              </h2>
              <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div className="accordion-body mt-2 mb-2">
                  <REPcolaboradores />
                </div>
              </div>
            </div>
            {/* <div className="card accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionTwo" aria-expanded="false" aria-controls="accordionTwo">
                  Accordion Item 2
                </button>
              </h2>
              <div id="accordionTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Dessert ice cream donut oat cake jelly-o pie sugar plum cheesecake. Bear claw dragée oat cake dragée ice
                  cream halvah tootsie roll. Danish cake oat cake pie macaroon tart donut gummies. Jelly beans candy canes
                  carrot cake. Fruitcake chocolate chupa chups.
                </div>
              </div>
            </div>
            <div className="card accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionThree" aria-expanded="false" aria-controls="accordionThree">
                  Accordion Item 3
                </button>
              </h2>
              <div id="accordionThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Oat cake toffee chocolate bar jujubes. Marshmallow brownie lemon drops cheesecake. Bonbon gingerbread
                  marshmallow sweet jelly beans muffin. Sweet roll bear claw candy canes oat cake dragée caramels. Ice cream
                  wafer danish cookie caramels muffin.
                </div>
              </div>
            </div> */}
          </div>

        </div>
      </div>
      <ToastContainer />
    </div>

  )
}

export default ReporComercial