import React, { useRef } from 'react';
import generatePDF, { Options } from "react-to-pdf";
import { useLocation } from 'react-router-dom';
const moment = require('moment')

const options = {
  filename: "R0010_consolidados_de_documentos.pdf",
  page: {
    margin: 2
  }
};

const ReportConsolidado = (prosp) => {
  const targetRef = useRef();
  // const downloadPdf = () => generatePDF(targetRef, options);
  // const location = useLocation();
  // const { data } = location.state || {};
  // console.log("dataRecvidad", data);
  // console.log("prosp", prosp);
  // console.log("prosp.data", prosp.data);
  // Función para agrupar datos por clave
  const groupBy = (data, key) => {
    return data.reduce((result, item) => {
      const groupKey = item[key];
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(item);
      return result;
    }, {});
  };

  const groupedByCardName = groupBy(prosp.data, 'PrcName'); // PrcName  SlpName
  // console.log("groupedByCardName::", groupedByCardName);
  // Object.keys(groupedByCardName).map((cardName) => {
  //   console.log("cardName:", cardName);
  //   const groupedByItmsGrpNam = groupBy(groupedByCardName[cardName], 'SlpName');
  //   console.log("groupedByItmsGrpNam::", groupedByItmsGrpNam);
  // })

  return (
    <div>
      <div className="row ">
        <div className="col-lg-11 text-end">
          
          {/* Ocultado de boton de pdf, mucho proceso  */}
          {/* <button className="btn btn-label-youtube btn-sm" style={{ position: "fixed", }} onClick={downloadPdf}><i className="bx bx-xs bxs-file-pdf" ></i> Descargar PDF </button> */}
        </div>
      </div>
      <div ref={targetRef}>
        <h5 ><b>DISTRIBUIDORA COMERCIAL ALVAREZ BOHL</b></h5>
        <h6 ><b>CONSOLIDADO DE DOCUMENTOS</b></h6>
        <hr />
        <div className="row">
          <div className="col-4">
            <h6 ><b>Fecha:</b> {prosp.fecha} </h6>
          </div>
          <div className="col-4">
            <h6 ><b>Placa:</b> {prosp.placa} </h6>
          </div>
          {/* <div className="col-4">
              <h6 ><b>Almacen:</b> {prosp.Ralmacen} </h6>
            </div> */}
        </div>
        <div className="row">
          {/* <div className="col-12">
              <h6 ><b>Dirección:</b> {prosp.Rdireccion} </h6>
            </div> */}
        </div>

        <div className="table-responsive text-nowrap">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Documento</th>

                <th>Nombre</th>
                <th>Condicion </th>
                <th>Importe</th>
                {/* <th>Sucursal</th> */}
                <th>Cobranza</th>
                <th>Observacion</th>

              </tr>
            </thead>
            <tbody className="table-border-bottom-0">
              {Object.keys(groupedByCardName).map((cardName) => {
                const groupedByItmsGrpNam = groupBy(groupedByCardName[cardName], 'SlpName');

                return (
                  <React.Fragment key={cardName}>
                    <tr>
                      <td colSpan="13" style={{ backgroundColor: '#f0f0f0', textAlign: 'left', fontWeight: 'bold' }}>
                        {cardName}
                      </td>
                    </tr>
                    {Object.keys(groupedByItmsGrpNam).map((itmsGrpNam) => {
                      let totalImporte = parseFloat(
                        groupedByItmsGrpNam[itmsGrpNam]
                          .reduce((total, item) => total + parseFloat(item.Importe), 0)
                          .toFixed(4)
                      );

                      return (
                        <React.Fragment key={itmsGrpNam}>
                          <tr>
                            <td colSpan="13" style={{ backgroundColor: '#d0d0d0', textAlign: 'left', fontWeight: 'bold' }}>
                              {itmsGrpNam}
                            </td>
                          </tr>
                          {groupedByItmsGrpNam[itmsGrpNam].map((item, index) => (
                            <tr key={index}>
                              <td>{moment(item.DocDate).format('YYYY-MM-DD')}</td>
                              <td>{item.Documento}</td>
                              <td>{item.CardName}</td>
                              <td>{item.Condicion}</td>
                              <td>{item.Importe}</td>
                              <td>{item.Cobranza}</td>
                              <td>{item.Observacion}</td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan="13" style={{ backgroundColor: '#f0f0f0', textAlign: 'center', fontWeight: 'bold' }}>
                              CANTIDAD: {groupedByItmsGrpNam[itmsGrpNam].length} &nbsp;&nbsp;&nbsp;&nbsp; TOTAL: {totalImporte}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}

            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
}

export default ReportConsolidado