export  const apiConsulBonificaciones = async(body) => {
  const url ='/apiConsulBonificaciones'; 
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}
export const apiConsulDescuentos = async(body) => {
  const url ='/apiConsulDescuentos'; 
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}
export const apiDisminuirStock = async(body) => {
  const url ='/apiDesminuirStock'; 
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}
export const apiAgruparBonificaciones = async(body) => {
  const url ='/apiConsulGrupoBonificaciones'; 
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}
export const apiAgruparDescuentos = async(body) => {
  const url ='/apiConsulGrupoDescuentos'; 
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}
export const apiConUnidadMedida = async(idEmpresa,codun) => {
  //console.log("aasasasasasa",codun);
  const url ='/apiConsUnidadMedida?vcodEmpresa='+idEmpresa+'&vtxtConsult='+codun; 
  const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
  const response = await fetch(url,opPe);
  const data = await response.json();
  //console.log("data", data);
  return data;
}
export const apiConsultarMontoMinimo = async(body) => {
  const url ='/apiTicketMinimo'; 
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}
export const apiConsultarFleteZonaCanal = async(body) => {
  const url ='/apiFleteZonaCanal'; 
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}
export const apiConsultarFleteProducto = async(body) => {
  const url ='/apiFleteProducto'; 
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}
export const apiConsultarFleteUnidadNegocio = async(body) => {
  const url ='/apiFleteUnidadNegocio'; 
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}

export const apiInsertOrder = async(body) => {
  const url ='/apiInsertOrder'; 
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}


