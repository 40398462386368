import React, {useState, useMemo, useEffect} from 'react'
import Cookies from 'universal-cookie';
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales'
import {notify_warning, notify_valido, selectTabla, selectTablaMultiple,removeSelectedRows} from '../../components/utils';
//import $ from 'jquery'
import '../../App.css';
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//import {Box, Modal, Tooltip}from '@mui/material';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { AButton } from '../../components/Button';
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');


const COMIVendedor = () => {
  
  

  const [lst,                 setlst] = useState([]);
  const [dataRExcel,          setDataRExcel] = useState([]);
  const [dataReport,          setDataReport] = useState([]);
  const [pending,             setPending] = useState(false);
  const [emp_sl_t_suc,        emp_setSl_t_suc] = useState('');
  const [inp_text_fecha,      setInp_text_fecha] = useState(new Date());
// Estado para las filas de pedidos seleccionadas  
  /** Listar datos */
  const onClickListar=async()=>{
    setPending(true)
    const urlLst = `/apiListarComisionesResp?vcodEmpresa=${cook_idEmp}&vperiodo=${inp_text_fecha.toISOString().slice(0, 7)}&vcodSucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(urlLst, opPe);
    const data = await response.json();
    setlst(data.resultadoAgrupado)
    setDataRExcel(data.resultadoAgruExcel)
    setDataReport(data.resultadoAgruRepo)
    setPending(false)
  };


   /** Cabaecera para Vhiculos */
  const columns  = useMemo( () => [
    { 
      header: 'Sucursal', 
      accessorKey: 'Sucursal'
    },
    { 
      header: 'CodVendedor',       
      accessorKey: 'Vendedor' 
    },
    { 
      header: 'NomVendedor',     
      accessorKey: 'Nombre' 
    },
    { 
      header: 'TipoTabla',         
      accessorKey: 'TipoTabla' 
    },
    { 
      header: 'Canal',         
      accessorKey: 'Canal' 
    },
    { 
      header: 'VolumenTotal',         
      accessorKey: 'VolumenTotal' 
    },
    { 
      header: 'VentaTotal',         
      accessorKey: 'VentaTotal' 
    },
    { 
      header: 'VolumenPorcentaje',         
      accessorKey: 'VolumenPorcentaje' 
    },
    { 
      header: 'PedidosTotal',         
      accessorKey: 'PedidosTotal' 
    },
    { 
      header: 'PedidosAvance',         
      accessorKey: 'PedidosAvance' 
    }, 
    { 
      header: 'PedidosPorcentaje',         
      accessorKey: 'PedidosPorcentaje' 
    }, 
    { 
      header: 'PedidosComision',         
      accessorKey: 'PedidosComision' 
    }, 
    { 
      header: 'CoberturaTotal',         
      accessorKey: 'CoberturaTotal' 
    }, 
    { 
      header: 'CoberturaAvance',         
      accessorKey: 'CoberturaAvance' 
    }, 
    { 
      header: 'CoberturaPorcentaje',         
      accessorKey: 'CoberturaPorcentaje' 
    }, 
    { 
      header: 'CoberturaComision',         
      accessorKey: 'CoberturaComision' 
    }, 
    { 
      header: 'EfectividadAvance',         
      accessorKey: 'EfectividadAvance' 
    }, 
    { 
      header: 'EfectividadComision',         
      accessorKey: 'EfectividadComision' 
    }, 
    { 
      header: 'LinealidadPorcentaje',         
      accessorKey: 'LinealidadPorcentaje' 
    }, 
    { 
      header: 'LinealidadComision',         
      accessorKey: 'LinealidadComision' 
    }, 
    { 
      header: 'Comision Inicial',         
      accessorKey: 'Basico' 
    }, 
    { 
      header: 'Rubro',         
      accessorKey: 'Rubro' 
    }, 
    { 
      header: 'Objetivo',         
      accessorKey: 'Objetivo' 
    }, 
    { 
      header: 'Venta',         
      accessorKey: 'Venta' 
    }, 
    { 
      header: 'Porcentaje',         
      accessorKey: 'Porcentaje' 
    }, 
    { 
      header: 'Comision',         
      accessorKey: 'Comision' 
    },
  ],[])
  
  
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "COMISIONES_VENDEDOR_"  + inp_text_fecha
    const showData = async () => {
      if (lst.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataRExcel);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcelRepor = async () => {
    const fileType = "xlsx"
    const nameFileType = "COMISIONES_VENDEDOR_"  + inp_text_fecha
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
  }
  useEffect(()=>{
    console.log( "vendedor");
    

  },[])

  return (
    <div>
        {/* <h6 className="text-muted"> COMIReporteria</h6> */}
        <div className="card card-action">
            <div className="card-body ">
              <div className="row">
                <div className="col-lg-2 d-none">Sucursal:</div>
                <div className="col-lg-2 d-none">
                  <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
                </div>
                <div className="col-lg-2">Periodo:</div>
                <div className="col-lg-2" style={{ zIndex: '3' }}>
                  <DatePicker
                    id="fecha"
                    locale="es"
                    className="form-control form-control-sm text-center"
                    selected={inp_text_fecha}
                    onChange={date => setInp_text_fecha(date)}
                    dateFormat="yyyy-MM"  // Muestra solo el mes y el año
                    showMonthYearPicker  // Permite seleccionar mes y año directamente
                    autoComplete='off'
                  />
                </div>
                <div className="col-lg-4"></div>
                <div className="col-lg-4 text-end ">
                
                  <AButton tp_tilde="Consultar información" bt_descr="Consultar" tp_posit="top" bt_class="btn btn-sm btn-label-youtube " bt_funct={onClickListar} ic_class="bx bx-ms bx-cloud-download" vl_tpbtn={2} />
                  
                  <AButton tp_tilde="Exportar en Excel" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success" ic_class="bx bx-ms bxs-file-export" vl_tpbtn={1}
                  bt_funct={onClickExcel}
                  />&nbsp;
                  <AButton tp_tilde="Exportar en Excel RH" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success" ic_class="bx bx-ms bxs-report" vl_tpbtn={1}
                  bt_funct={onClickExcelRepor}
                  />
                </div>
              </div>
                <div className="card-datatable table-responsive">
                  <MaterialReactTable 
                    columns={columns}
                    data={lst}
                    state={{ isLoading: pending }}
                     initialState={{  
                       density: 'compact', 
                       showColumnFilters: false,  
                       showGlobalFilter: false,
                       grouping: ['Vendedor']
                     }}
                    enableGrouping 
                    enableStickyHeader enableColumnResizing
                    muiToolbarAlertBannerChipProps={{ color: 'error' }}
                    localization={MRT_Localization_ES}
                    enablePagination={true}
                    muiTableBodyCellProps={({ row }) => ({ 
                      sx: { 
                        p: '2px 16px', 
                        fontWeight: 
                          (row.original.Nombre === "TOTAL" ) 
                          ? 'bold' : ''
                      }
                    })}
                    enableGlobalFilterModes
                    rowVirtualizerProps={{ overscan: 150 }}
                    enableRowVirtualization
                    muiTableBodyRowProps={({row}) => ({
                      sx: {
                        backgroundColor: 
                          ( row.getValue('Nombre') === "TOTAL" ) 
                          ? 'rgba(255, 99, 71, 0.2)' : ''
                      }
                    })}
                    muiCircularProgressProps={{ color: 'error' }}
                    muiSkeletonProps={{
                      animation: 'pulse',
                      height: 28,
                    }}
                    
                    enableExpanding= {true}
                    
                  />
                </div>
            </div>
        </div>
        {/* <ToastContainer /> */}
    </div>
  )
}

const COMISUpervisor = () => {
  

  const [lst,                 setlst] = useState([]);
  const [dataReport,          setDataReport] = useState([]);
  const [pending,             setPending] = useState(false);
  const [emp_sl_t_suc,        emp_setSl_t_suc] = useState('');
  const [inp_text_fecha,      setInp_text_fecha] = useState(new Date());
// Estado para las filas de pedidos seleccionadas  
  /** Listar datos */
  const onClickListar=async()=>{
    setPending(true)
    const urlLst = `/apiListarComisionesSuper?vcodEmpresa=${cook_idEmp}&vperiodo=${inp_text_fecha.toISOString().slice(0, 7)}&vcodSucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(urlLst, opPe);
    const data = await response.json();
    setlst(data.resultadoAgrupado)
    //setDataRExcel(data.resultadoAgruExcel)
    //setDataReport(data.resultadoAgruRepo)
    setPending(false)
  };


  /** Cabaecera para Vhiculos */
  const columns = useMemo(() => [
    { header: 'Sucursal', accessorKey: 'Sede' },
    { header: 'Code Supervidor', accessorKey: 'IdSup' },
    { header: 'Nombre', accessorKey: 'NomSup' },
    { header: 'ObjVta', accessorKey: 'ObjVta' },
    { header: 'VentaAvance', accessorKey: 'VentaAvance' },
    { header: 'Avance_Venta%"', accessorKey: 'Avance_Venta%' },
    { header: 'Comision_Volumen"', accessorKey: 'Comision_Volumen' },

    { header: 'Pedidos_Objetivo', accessorKey: 'Pedidos_Objetivo' },
    { header: 'PedidosAvance', accessorKey: 'PedidosAvance' },
    { header: 'Pedidos_Porcentaje', accessorKey: 'Pedidos_Porcentaje' },
    { header: 'Pedidos_Comision', accessorKey: 'Pedidos_Comision' },

    { header: 'Cobertura_Objetivo', accessorKey: 'Cobertura_Objetivo' },
    { header: 'CoberturaAvance', accessorKey: 'CoberturaAvance' },
    { header: 'Cobertura_Porcentaje', accessorKey: 'Cobertura_Porcentaje' },
    { header: 'Cobertura_Comision', accessorKey: 'Cobertura_Comision' },

    { header: 'Efectividad_Porcentaje', accessorKey: 'Efectividad_Porcentaje' },
    { header: 'Efectividad_Comision', accessorKey: 'Efectividad_Comision' },

    { header: 'Linealidad_Porcentaje', accessorKey: 'Linealidad_Porcentaje'    },
    { header: 'Linealidad_Comision', accessorKey: 'Linealidad_Comision' },

    { header: 'Basico', accessorKey: 'Basico' },
    { header: 'TotalComision', accessorKey: 'TotalComision' },
    { header: 'TOTAL_SALARIO', accessorKey: 'TOTAL_SALARIO' },
  
    { header: 'Tipo', accessorKey: 'Tipo' },
  ], [])
  
  
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "COMISIONES_SUPERVISOR_"  + inp_text_fecha
    const showData = async () => {
      if (lst.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(lst);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
  }

  

  return (
    <div>
        {/* <h6 className="text-muted"> COMIReporteria</h6> */}
        <div className="card card-action">
            <div className="card-body ">
              <div className="row">
                <div className="col-lg-2 d-none">Sucursal:</div>
                <div className="col-lg-2 d-none">
                  <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
                </div>
                <div className="col-lg-2">Periodo:</div>
                <div className="col-lg-2" style={{ zIndex: '3' }}>
                  <DatePicker
                    id="fecha"
                    locale="es"
                    className="form-control form-control-sm text-center"
                    selected={inp_text_fecha}
                    onChange={date => setInp_text_fecha(date)}
                    dateFormat="yyyy-MM"  // Muestra solo el mes y el año
                    showMonthYearPicker  // Permite seleccionar mes y año directamente
                    autoComplete='off'
                  />
                </div>
                <div className="col-lg-4"></div>
                <div className="col-lg-4 text-end ">
                
                  <AButton 
                    tp_tilde="Consultar información" 
                    bt_descr="Consultar" tp_posit="top" 
                    bt_class="btn btn-sm btn-label-youtube " 
                    bt_funct={onClickListar} vl_tpbtn={2} 
                    ic_class="bx bx-ms bx-cloud-download" 
                  />
                  <AButton 
                    tp_tilde="Exportar en Excel" tp_posit="top" 
                    bt_class="btn btn-sm btn-icon btn-label-success" 
                    ic_class="bx bx-ms bxs-file-export" 
                    vl_tpbtn={1} bt_funct={onClickExcel}
                  />
                </div>
              </div>
                <div className="card-datatable table-responsive">
                  <MaterialReactTable 
                    columns={columns}
                    data={lst}
                    state={{ isLoading: pending }}
                    initialState={{  
                      density: 'compact', 
                      showColumnFilters: false,  
                      showGlobalFilter: false,
                    }} 
                    enableStickyHeader enableColumnResizing
                    muiToolbarAlertBannerChipProps={{ color: 'error' }}
                    localization={MRT_Localization_ES}
                    enablePagination={true}
                  
                    enableGlobalFilterModes
                    rowVirtualizerProps={{ overscan: 150 }}
                    enableRowVirtualization
                    muiCircularProgressProps={{ color: 'error' }}
                    muiSkeletonProps={{
                      animation: 'pulse',
                      height: 28,
                    }}
                    
                    //enableExpanding= {true}
                    
                  />
                </div>
            </div>
        </div>
        {/* <ToastContainer /> */}
    </div>
  )
}

const COMiJefes = () => {
  

  const [lst,                 setlst] = useState([]);
  const [dataReport,          setDataReport] = useState([]);
  const [pending,             setPending] = useState(false);
  const [emp_sl_t_suc,        emp_setSl_t_suc] = useState('');
  const [inp_text_fecha,      setInp_text_fecha] = useState(new Date());
// Estado para las filas de pedidos seleccionadas  
  /** Listar datos */
  const onClickListar=async()=>{
    setPending(true)
    const urlLst = `/apiListarComisionesJefes?vcodEmpresa=${cook_idEmp}&vperiodo=${inp_text_fecha.toISOString().slice(0, 7)}&vcodSucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(urlLst, opPe);
    const data = await response.json();
    setlst(data.resultadoAgrupado)
    //setDataRExcel(data.resultadoAgruExcel)
    //setDataReport(data.resultadoAgruRepo)
    setPending(false)
  };


  /** Cabaecera para Vhiculos */
  const columns = useMemo(() => [
    // { header: 'Sucursal', accessorKey: 'Sucursal' },
    // { header: 'Code Supervidor', accessorKey: 'CodSup' },
    // { header: 'Nombre', accessorKey: 'Nombre' },
    // { header: 'TipoTabla', accessorKey: 'TipoTabla' },
    // // { header: 'Canal', accessorKey: 'Canal' },
    // { header: 'VolumenTotal', accessorKey: 'VolumenTotal' },
    // { header: 'VentaTotal', accessorKey: 'VentaTotal' },
    // { header: 'VolumenPorcentaje', accessorKey: 'VolumenPorcentaje' },
    // { header: 'PedidosTotal', accessorKey: 'PedidosTotal' },
    // { header: 'PedidosAvance', accessorKey: 'PedidosAvance' },
    // { header: 'PedidosPorcentaje', accessorKey: 'PedidosPorcentaje' },
    // { header: 'PedidosComision', accessorKey: 'PedidosComision' },
    // { header: 'CoberturaTotal', accessorKey: 'CoberturaTotal' },
    // { header: 'CoberturaAvance', accessorKey: 'CoberturaAvance' },
    // { header: 'CoberturaPorcentaje', accessorKey: 'CoberturaPorcentaje' },
    // { header: 'CoberturaComision', accessorKey: 'CoberturaComision' }, {
    //   header: 'EfectividadAvance', accessorKey: 'EfectividadAvance'    },
    // { header: 'EfectividadComision', accessorKey: 'EfectividadComision' },
    // { header: 'LinealidadPorcentaje', accessorKey: 'LinealidadPorcentaje' },
    // { header: 'LinealidadComision', accessorKey: 'LinealidadComision' },
    // { header: 'Comision Inicial', accessorKey: 'Basico' },
    // // { header: 'Rubro', accessorKey: 'Rubro' },
    // // { header: 'Objetivo', accessorKey: 'Objetivo' },
    // { header: 'Venta', accessorKey: 'Venta' },
    // { header: 'PorcentajeG', accessorKey: 'PorcentajeG' },
    // { header: 'ObjetivoG', accessorKey: 'ObjetivoG' },
    // { header: 'ComisionG', accessorKey: 'ComisionG' },
    // { header: 'Asegurable', accessorKey: 'Asegurable' },
    // { header: 'Total', accessorKey: 'Total' },

    


    // { header: 'Sucursal', accessorKey: 'Sede' },
    // { header: 'Code Supervidor', accessorKey: 'IdSup' },
    // { header: 'Nombre', accessorKey: 'NomSup' },
    // { header: 'VentaAvance', accessorKey: 'VentaAvance' },
    // { header: 'ObjVta', accessorKey: 'ObjVta' },
    // { header: 'VentaAvance', accessorKey: 'VentaAvance' },
    // { header: 'Avance_Venta%', accessorKey: 'Avance_Venta%' },
    // { header: 'Pedidos_Objetivo', accessorKey: 'Pedidos_Objetivo' },
    // { header: 'PedidosAvance', accessorKey: 'PedidosAvance' },
    // { header: 'Pedidos_Porcentaje', accessorKey: 'Pedidos_Porcentaje' },
    // { header: 'Pedidos_Comision', accessorKey: 'Pedidos_Comision' },
    // { header: 'Cobertura_Objetivo', accessorKey: 'Cobertura_Objetivo' },
    // { header: 'CoberturaAvance', accessorKey: 'CoberturaAvance' },
    // { header: 'Cobertura_Porcentaje', accessorKey: 'Cobertura_Porcentaje' },
    // { header: 'Cobertura_Comision', accessorKey: 'Cobertura_Comision' },
    // { header: 'Efectividad_Porcentaje', accessorKey: 'Efectividad_Porcentaje' },
    // { header: 'Efectividad_Comision', accessorKey: 'Efectividad_Comision' },
    // { header: 'Linealidad_Porcentaje', accessorKey: 'Linealidad_Porcentaje' },
    // { header: 'Linealidad_Comision', accessorKey: 'Linealidad_Comision' },
    // { header: 'Basico', accessorKey: 'Basico' },
    // { header: 'TotalComision', accessorKey: 'TotalComision' },
    // { header: 'TOTAL_SALARIO', accessorKey: 'TOTAL_SALARIO' },
    // { header: 'Tipo', accessorKey: 'Tipo' },


    { header: 'Sucursal', accessorKey: 'Sede' },
    { header: 'Code Jefes', accessorKey: 'IdJef' },
    { header: 'Nombre', accessorKey: 'NomJef' },
    { header: 'ObjVta', accessorKey: 'ObjVta' },
    { header: 'VentaAvance', accessorKey: 'VentaAvance' },
    { header: 'Avance_Venta%"', accessorKey: 'Avance_Venta%' },
    { header: 'Comision_Volumen"', accessorKey: 'Comision_Volumen' },

    { header: 'Pedidos_Objetivo', accessorKey: 'Pedidos_Objetivo' },
    { header: 'PedidosAvance', accessorKey: 'PedidosAvance' },
    { header: 'Pedidos_Porcentaje', accessorKey: 'Pedidos_Porcentaje' },
    { header: 'Pedidos_Comision', accessorKey: 'Pedidos_Comision' },

    { header: 'Cobertura_Objetivo', accessorKey: 'Cobertura_Objetivo' },
    { header: 'CoberturaAvance', accessorKey: 'CoberturaAvance' },
    { header: 'Cobertura_Porcentaje', accessorKey: 'Cobertura_Porcentaje' },
    { header: 'Cobertura_Comision', accessorKey: 'Cobertura_Comision' },

    { header: 'Efectividad_Porcentaje', accessorKey: 'Efectividad_Porcentaje' },
    { header: 'Efectividad_Comision', accessorKey: 'Efectividad_Comision' },

    { header: 'Linealidad_Porcentaje', accessorKey: 'Linealidad_Porcentaje'    },
    { header: 'Linealidad_Comision', accessorKey: 'Linealidad_Comision' },

    { header: 'Basico', accessorKey: 'Basico' },
    { header: 'TotalComision', accessorKey: 'TotalComision' },
    { header: 'TOTAL_SALARIO', accessorKey: 'TOTAL_SALARIO' },
  
    { header: 'Tipo', accessorKey: 'Tipo' },



  ], [])
  
  
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "COMISIONES_JEFES_"  + inp_text_fecha
    const showData = async () => {
      if (lst.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(lst);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
  }

  

  return (
    <div>
        {/* <h6 className="text-muted"> COMIReporteria</h6> */}
        <div className="card card-action">
            <div className="card-body ">
              <div className="row">
                <div className="col-lg-2 d-none">Sucursal:</div>
                <div className="col-lg-2 d-none">
                  <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
                </div>
                <div className="col-lg-2">Periodo:</div>
                <div className="col-lg-2" style={{ zIndex: '3' }}>
                  <DatePicker
                    id="fecha"
                    locale="es"
                    className="form-control form-control-sm text-center"
                    selected={inp_text_fecha}
                    onChange={date => setInp_text_fecha(date)}
                    dateFormat="yyyy-MM"  // Muestra solo el mes y el año
                    showMonthYearPicker  // Permite seleccionar mes y año directamente
                    autoComplete='off'
                  />
                </div>
                <div className="col-lg-4"></div>
                <div className="col-lg-4 text-end ">
                
                  <AButton 
                    tp_tilde="Consultar información" 
                    bt_descr="Consultar" tp_posit="top" 
                    bt_class="btn btn-sm btn-label-youtube " 
                    bt_funct={onClickListar} vl_tpbtn={2} 
                    ic_class="bx bx-ms bx-cloud-download" 
                  />
                  <AButton 
                    tp_tilde="Exportar en Excel" tp_posit="top" 
                    bt_class="btn btn-sm btn-icon btn-label-success" 
                    ic_class="bx bx-ms bxs-file-export" 
                    vl_tpbtn={1} bt_funct={onClickExcel}
                  />
                </div>
              </div>
                <div className="card-datatable table-responsive">
                  <MaterialReactTable 
                    columns={columns}
                    data={lst}
                    state={{ isLoading: pending }}
                    initialState={{  
                      density: 'compact', 
                      showColumnFilters: false,  
                      showGlobalFilter: false,
                    }} 
                    enableGrouping
                    enableStickyHeader enableColumnResizing
                    muiToolbarAlertBannerChipProps={{ color: 'error' }}
                    localization={MRT_Localization_ES}
                    enablePagination={true}
                    enableGlobalFilterModes
                    rowVirtualizerProps={{ overscan: 150 }}
                    enableRowVirtualization
                    muiCircularProgressProps={{ color: 'error' }}
                    muiSkeletonProps={{
                      animation: 'pulse',
                      height: 28,
                    }}
                    
                    //enableExpanding= {true}
                    
                  />
                </div>
            </div>
        </div>
        {/* <ToastContainer /> */}
    </div>
  )
}

const COMIReporteria = () => {
  return (
    <div>
      {/* <small className="text-light fw-medium"> Reporteria de Comisiones</small> */}
      <h6 > Reporteria de Comisiones</h6>
      <div className="row">
        <div className="col-md mb-4 mb-md-2">
          <div className="accordion mt-4" id="accordionWithIcon">

            <div className="card accordion-item active">
              <h2 className="accordion-header d-flex align-items-center">
                <button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionWithIcon-1" aria-expanded="true">
                  <i className="bx bx-bar-chart-alt-2 me-2"></i>
                  COMISION VENDEDOR
                </button>
              </h2>

              <div id="accordionWithIcon-1" className="accordion-collapse collapse show">
                <div className="accordion-body">
                  <COMIVendedor />
                </div>
              </div>
            </div>

            <div className="accordion-item card">
              <h2 className="accordion-header d-flex align-items-center">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionWithIcon-2" aria-expanded="false">
                  <i className="bx bx-briefcase me-2"></i>
                  COMISION SUPERVISOR
                </button>
              </h2>
              <div id="accordionWithIcon-2" className="accordion-collapse collapse">
                <div className="accordion-body">
                  <COMISUpervisor />
                </div>
              </div>
            </div>

            <div className="accordion-item card">
              <h2 className="accordion-header d-flex align-items-center">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionWithIcon-3" aria-expanded="false">
                  <i className="bx bxs-drink me-2"></i>
                  COMISION JEFES
                </button>
              </h2>
              <div id="accordionWithIcon-3" className="accordion-collapse collapse">
                <div className="accordion-body">
                  <COMiJefes />
                </div>
              </div>
            </div>
            
          </div>

        </div>
      </div>
      <ToastContainer />
    </div>

  )
}
export default COMIReporteria

