import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { BarChart4, BarChart5 } from '../Charts/BarChart'
import Cookies from 'universal-cookie';
import { notify_warning, notify_valido, selectTabla } from '../../components/utils';
import $ from 'jquery'
import DataTable, { createTheme as createThemeS } from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import Swal from 'sweetalert2';
//import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SelectSucursal, SelectTipo } from '../../components/Selects/SelectsPrincipales'
import img_pdf from '../../assets/img/icons/misc/pdf.png';
import AsyncSelect from 'react-select/async';
import { AButton } from '../../components/Button';
import { data } from '../../views/Reparto/markeData';
import { ToastContainer } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react'
import { Icon } from '@iconify/react'
import locationIcon from '@iconify/icons-mdi/map-marker'
import '../../assets/css/map.css'
import { Box, Modal, Typography, Tabs, Tab } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import 'react-data-table-component-extensions/dist/index.css';
const moment = require('moment')
const cookies = new Cookies();
const dtUser = cookies.get('dtUser');
const cook_idEmp = cookies.get('dtEmp_id');
const globalTheme = window.templateCustomizer.settings.style;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.default',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};
export const ModalsDetVen = ({ dtPorLineaVe }) => {
  var periodo = moment(new Date()).subtract(1, 'days').format('DD-MM-YYYY');
  return (
    <div className="modal fade" id="exLargeModal" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true" >
      <div className="modal-dialog modal-xl " role="document">
        <div className="modal-content" >
          <div className="modal-header ">
            <h5 className="modal-title " id="exampleModalLabel1">Avance Ventas del Vendedor {dtPorLineaVe.CodigoVendedor} al {periodo}  </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className='row'>
              <div className='col-lg-6'>
                <BarChart5 dtPorLineaVe={dtPorLineaVe} />
              </div>
              <div className='col-lg-6'>
                <BarChart4 dtPorLineaVe={dtPorLineaVe} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ModalsSubPartidas = () => {

  const [lst, setlst] = useState([]);
  const [pending, setpending] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [txt_codigo, setTxt_codigo] = useState('');
  const [txt_detalle, setTxt_detalle] = useState('');

  const changetxt_codigo = (event) => { setTxt_codigo(event.target.value); }
  const changetxt_detalle = (event) => { setTxt_detalle(event.target.value); }

  const ins_url = '/gm-cts-sp-insert'
  const lst_url = '/gm-cts-sp-lst'
  const upd_url = '/gm-cts-sp-update'
  const del_url = '/gm-cts-sp-delete'

  const lst_columns = [
    {
      cell: (row) =>
        <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDelete} id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
        </div>
      , ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN'
    },
    { name: 'CODIGO', selector: row => row.spa_cod, cellExport: row => row.spa_cod, sortable: true, width: "7rem" },
    { name: 'DETALLE', selector: row => row.spa_descripcion, cellExport: row => row.spa_descripcion, sortable: true },
  ];

  const onClickGuardar = async () => {
    var txt_cod = $(".modal_cod_partida").text();
    const body = { vcodEmpresa: cook_idEmp, txt_cod: txt_cod, txt_codigo: txt_codigo, txt_detalle: txt_detalle, txt_usuid: cookies.get('dtUser_id') };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    console.log('txt_cod -> ', txt_cod)
    $("#btn_sp_guardar").attr("disabled", true)
    $("#icon_btn_sp_guardar").addClass("d-none")
    $("#spinner_btn_sp_guardar").removeClass("d-none")
    if (txt_codigo === "" || txt_detalle === "") {
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    } else {
      const response = await fetch(ins_url, opPe);
      const resdatos = await response.json();
      if (resdatos.vl_existe === 1) {
        notify_warning("CODIGO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1) {
        notify_valido("CODIGO REGISTRO CORRECTAMENTE!!!")
        onClickClearInputs();
        if ($(".rdt_Table").hasClass("table")) {
          onClickListar();
        }
      }
    }
    $("#btn_sp_guardar").attr("disabled", false)
    $("#icon_btn_sp_guardar").removeClass("d-none")
    $("#spinner_btn_sp_guardar").addClass("d-none")
  }
  const onClickListar = async () => {
    var txt_cod = $(".modal_cod_partida").text();
    const body = { vcodEmpresa: cook_idEmp, txt_cod: txt_cod };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const columns = lst_columns
    setpending(true)
    const response = await fetch(lst_url, opPe);
    const data = await response.json();
    //console.log(data);
    const tableData = { columns, data };
    setlst(tableData)
    setpending(false)
    $(".tabla-subpartida .rdt_Table").addClass("table");
  }
  const onClickEditInputs = async () => {
    setdisabled(false)
    $("#btn_sp_editar").addClass('d-none')
    $("#btn_sp_gmodificado").removeClass('d-none')
    setTimeout(() => { $("#txt_sp_codigo").attr('disabled', true) }, 500)
  }
  const onClickGModifcado = async () => {
    const body = { vcodEmpresa: cook_idEmp, txt_codigo: txt_codigo, txt_detalle: txt_detalle, txt_usuid: cookies.get('dtUser_id') };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    $("#btn_sp_gmodificado").attr("disabled", true)
    $("#icon_btn_sp_gmodificado").addClass("d-none")
    $("#spinner_btn_sp_gmodificado").removeClass("d-none")
    if (txt_codigo === "" || txt_detalle === "") {
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    } else {
      const response = await fetch(upd_url, opPe);
      const resdatos = await response.json();
      //console.log("modifi", resdatos);
      if (resdatos.vl_insert === 0) {
        notify_warning("ERROR AL MODIFCAR, VERIFICAR DATOS!!!")
      } else if (resdatos.vl_insert === 1) {
        onClickClearInputs()
        onClickListar()
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
      }
    }
    $("#btn_sp_gmodificado").attr("disabled", false)
    $("#icon_btn_sp_gmodificado").removeClass("d-none")
    $("#spinner_btn_sp_gmodificado").addClass("d-none")
  }
  const onClickClearInputs = async () => {
    setTxt_codigo("")
    setTxt_detalle("")
    $('.rdt_TableRow').removeClass("table-danger");
    $("#btn_sp_guardar").removeClass('d-none')
    $("#btn_sp_editar").addClass('d-none')
    $("#btn_sp_gmodificado").addClass('d-none')
    setdisabled(false)
    $("#txt_sp_codigo").attr('disabled', false)
    $("#btn_sp_mlista").removeClass('d-none')
  }
  const onClickDelete = async (state) => {
    try {
      const dtLlegada = await JSON.parse(state.target.id);
      const codigo = dtLlegada.spa_cod
      const del_body = { vcodEmpresa: cook_idEmp, txt_codigo: codigo, txt_usuid: cookies.get('dtUser_id') };
      const del_opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(del_body) }

      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        customClass: {
          container: 'my-swal'
        }

      }).then(async response => {
        if (response.isConfirmed) {
          const respondt = await fetch(del_url, del_opPe);
          const resdatos = await respondt.json();
          console.log("resdatos", resdatos);
          if (resdatos.vl_insert === 0) {
            notify_warning("ERROR AL ELIMINAR!!!")
          } else if (resdatos.vl_insert === 1) {
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({ title: 'EXITO', text: 'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon: 'success', showConfirmButton: false, timer: 1000 })
            onClickListar()
            onClickClearInputs()
          }
        } else if (response.isDenied) {
          Swal.close();
        }
      })

    } catch (error) {
      console.log(error);
    }
  }

  const handleRowClicked = (row) => {
    setTxt_codigo(row.spa_cod)
    setTxt_detalle(row.spa_descripcion)
    selectTabla("tabla-subpartida")
    $("#btn_sp_guardar").addClass('d-none')
    $("#btn_sp_editar").removeClass('d-none')
    setdisabled(true)
    $("#btn_sp_gmodificado").addClass('d-none')
    $("#btn_sp_mlista").addClass('d-none')
  }
  // const selectTabla = () => {
  //   $('.rdt_TableBody').on('click', '.rdt_TableCell', function () {
  //     $('.rdt_TableRow').removeClass("table-danger");
  //     $($(this).parents(".rdt_TableRow")).addClass("table-danger");       
  //   })
  // };

  return (
    <div className="modal fade" id="modalCenter" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true" >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalCenterTitle">Partida <strong className="modal_cod_partida"></strong> - <strong className="modal_des_partida"></strong></h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={e => { setlst([]) }}></button>
          </div>
          <div className="modal-body">
            <div className="row mb-2">
              <div className="col-md-4">
                <label>Codigo:</label>
                <input type="text" id="txt_sp_codigo" name="txt_sp_codigo" className="form-control form-control-sm" autoComplete='off' value={txt_codigo} onChange={changetxt_codigo} disabled={disabled} />
              </div>
              <div className="col-md-8">
                <label>Descripción:</label>
                <input type="text" id="txt_sp_detalle" name="txt_sp_detalle" className="form-control form-control-sm" autoComplete='off' value={txt_detalle} onChange={changetxt_detalle} disabled={disabled} />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 text-center ">
                <button className="btn btn-primary btn-sm" id="btn_sp_guardar" onClick={onClickGuardar} >
                  <i className='bx bx-save' id="icon_btn_sp_guardar" ></i>
                  <span className="spinner-border d-none" id="spinner_btn_sp_guardar" role="status" aria-hidden="true"></span>
                  &nbsp;Guardar
                </button>
                <button className="btn btn-warning btn-sm d-none" id="btn_sp_editar" onClick={onClickEditInputs}>
                  <i className='bx bx-edit' ></i>&nbsp;Editar
                </button>
                <button className="btn btn-success btn-sm d-none" id="btn_sp_gmodificado" onClick={onClickGModifcado} >
                  <i className='bx bx-send' id="icon_btn_sp_gmodificado" ></i>
                  <span className="spinner-border d-none" id="spinner_btn_sp_gmodificado" role="status" aria-hidden="true"></span>
                  &nbsp;GModificado
                </button>
                <button className="btn btn-dark btn-sm" id="btn_sp_cancelar" onClick={onClickClearInputs}>
                  <i className='bx bx-history' ></i>&nbsp;Cancelar
                </button>
                <button className="btn btn-info btn-sm" id="btn_sp_mlista" onClick={onClickListar} >
                  <i className='bx bx-list-ul' ></i>&nbsp;MLista
                </button>
              </div>
            </div>
            <div className="card-datatable table-responsive ">
              <DataTableExtensions  {...lst} >
                <DataTable className="tabla-subpartida"
                  Header
                  defaultSortField="id"
                  defaultSortAsc={true}
                  pagination
                  responsive
                  highlightOnHover
                  progressPending={pending}
                  noContextMenu="No hay registros para mostrar"
                  onRowClicked={handleRowClicked}
                //conditionalRowStyles={conditionalRowStyles}
                />
              </DataTableExtensions>
            </div>
          </div>
          <div className="modal-footer">
          </div>
        </div>
      </div>
    </div>
  )
}

// var vtoday = new Date();
export const ModalDetallado = ({ dataLlegada, dataldt, pending2 }) => {

  //console.log(pending2);
  //console.log('dataldt', dataldt);url_dtigdt-update
  const url_tga = '/get-data-paridas' + cook_idEmp
  const url_con = '/post-data-subpartidas'
  const url_est = '/get-data-estados'
  const url_jor = '/get-data-jornal'
  const url_emp = '/post-data-empleados'


  const url_dti = '/gdt-insert'
  const url_dtu = '/gdt-update'
  const url_lst = '/gdt-lst'
  const url_dta = '/gdt-delete'
  // let sssss = dataldt
  // const redsd = pending2
  //console.log(redsd);
  //var fecha_actual = new Date()
  //const [txt_vfactual, seTfecha_actual] = useState(new Date());
  const [lst, setlst] = useState([]);
  const [pending, setpending] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [moEstado, setMoEstado] = useState(false);
  const [disabledUni, setdisabledUni] = useState('');
  const [txt_perfec, setTxt_perfec] = useState(new Date());
  const [slc_t_tga, setSlc_t_tga] = useState('');
  const [slc_t_con, setSlc_t_con] = useState('');
  const [slc_t_jor, setSlc_t_jor] = useState('');
  const [slc_t_emp, setslc_t_emp] = useState([""]);
  const [slc_conceptos, setSlc_conceptos] = useState([]);
  const [txt_monto, setTxt_monto] = useState('');
  const [txt_nrefe, setTxt_nrefe] = useState('');
  const [txt_obs, setTxt_obs] = useState('');
  const [slc_t_est, setSlc_t_est] = useState('E004');
  const [selectedFile, setSelectedFile] = useState([]);

  const changeSlc_t_tga = (event) => {
    setSlc_t_tga(event.target.value);
    fc_lstConceptos(event.target.value)

    if (event.target.value === 'P007') {
      $(".div-resto").addClass("d-none")
      $(".div-panilla").removeClass("d-none")
    } else {
      $(".div-panilla").addClass("d-none")
      $(".div-resto").removeClass("d-none")
    }

    //alert(event.target.value)

  }
  const changeSlc_t_con = (event) => { setSlc_t_con(event.target.value); }
  const changeSlc_t_jor = (event) => { setSlc_t_jor(event.target.value); }
  const fc_lstConceptos = async (r_cod) => {
    //const url_ven = '/post-data-subpartidas'
    let body = { txt_cod: r_cod };
    const opPe = { vcodEmpresa: cook_idEmp, method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setSlc_conceptos(data)
  }
  const changeTxt_monto = (event) => { setTxt_monto(event.target.value); }
  const changeTxt_nrefe = (event) => { setTxt_nrefe(event.target.value); }
  const changeTxt_obs = (event) => { setTxt_obs(event.target.value); }
  const changeSlc_t_est = (event) => { setSlc_t_est(event.target.value); }

  const changeFile = (event) => { setSelectedFile(event.target.files[0]); }

  const body = {
    slc_t_suc: dataLlegada.slc_t_suc, txt_periodo: dataLlegada.txt_periodo, slc_t_sem: dataLlegada.slc_t_sem,
    txt_perfec: txt_perfec,
    slc_t_tga: slc_t_tga,
    slc_t_con: slc_t_con,
    slc_t_jor: slc_t_jor,
    slc_t_emp: slc_t_emp,
    txt_monto: txt_monto,
    txt_nrefe: txt_nrefe,
    txt_obs: txt_obs,
    slc_t_est: slc_t_est, txt_usuid: cookies.get('dtUser_id'),
  };

  //const opPe = { method: "POST", headers: {'Content-Type': 'multipart/form-data'}, body: JSON.stringify(body) };
  const lst_columns2 = [
    { name: 'ID', selector: row => row.gpa_id, cellExport: row => row.gpa_id, sortable: true, omit: true },
    { name: 'SubPartidad', selector: row => row.spa_cod, cellExport: row => row.spa_cod, sortable: true, omit: true },
    { name: 'Concepto', selector: row => row.spa_descripcion, cellExport: row => row.spa_descripcion, sortable: true },
    { name: 'Monto', selector: row => row.gpa_monto, cellExport: row => row.gpa_monto, sortable: true },
    { name: 'est_cod', selector: row => row.est_cod, cellExport: row => row.est_cod, sortable: true, omit: true },
    { name: 'Estado', selector: row => row.est_descripcion, cellExport: row => row.est_descripcion, sortable: true },
    {
      cell: (row) =>
        <div className="align-items-center">
          {row.gpa_marchivo === "" ?
            <p className='d-none'>nimg</p>
            :
            <a href={"/archivos/" + row.gpa_marchivo} target="_blank" rel="noreferrer">
              {row.gpa_marchivo.split('.')[1] === 'PDF' ?
                <img src={img_pdf} alt="PDF imagess" width="18" className="me-2"></img>
                :
                <i className='bx bxs-file-image bx-ms me-2'></i>
              }
            </a>
          }
          <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDelete} id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
        </div>
      , ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN'
    },
    { name: 'est_des', selector: row => row.gpa_fecha, cellExport: row => row.gpa_fecha, sortable: true, omit: true },
    { name: 'est_des', selector: row => row.par_cod, cellExport: row => row.par_cod, sortable: true, omit: true },
    { name: 'est_des', selector: row => row.gpa_mreferencia, cellExport: row => row.gpa_mreferencia, sortable: true, omit: true },
    { name: 'est_des', selector: row => row.gpa_observacion, cellExport: row => row.gpa_observacion, sortable: true, omit: true },
    { name: 'est_des', selector: row => row.tjo_cod, cellExport: row => row.tjo_cod, sortable: true, omit: true },
    { name: 'est_des', selector: row => row.emp_cod, cellExport: row => row.emp_cod, sortable: true, omit: true },
    { name: 'est_des', selector: row => row.emp_detalle, cellExport: row => row.emp_detalle, sortable: true, omit: true },

  ]

  const onClickGuardar = async () => {
    $(".tabla-detalle .rdt_Table").addClass("table");
    $("#btn_guardar").attr("disabled", true)
    $("#icon_btn_guardar").addClass("d-none")
    $("#spinner_btn_guardar").removeClass("d-none")

    const txt_diaini = $(".modal_txt_diaini").text()
    const txt_diafin = $(".modal_txt_diafin").text()
    const vlidarfecha = moment(txt_perfec).format('YYYY-MM-DD')
    const nplaca = $(".modal_cod_placa").text()
    switch (slc_t_tga) {
      case 'P007':
        if (txt_perfec === "" || slc_t_tga === "" || txt_monto === "") {
          notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
        } else {
          if (txt_diaini <= vlidarfecha && vlidarfecha <= txt_diafin) {

            const formData = new FormData();
            formData.append("imagen", selectedFile);
            formData.append("data", JSON.stringify(body));
            formData.append("nplaca", nplaca);

            //console.log("f",f);
            const opPe22 = { method: "POST", headers: {}, body: formData }
            const response = await fetch(url_dti, opPe22);
            const resdatos = await response.json();
            if (resdatos.vl_insert === 1) {
              notify_valido("SE REGISTRO CORRECTAMENTE!!!")
              onClickClear();
              onClickListar();
              if (resdatos.vl_insert2 === 1) {
                PintarTablaSelect(5, "ABIERTO")
                PintarTablaSelect(7, "ABIERTO")
              }
            } else { notify_warning("ERROR AL GUARDAR, VERIFICAR!!!") }
            PintarTablaSelect(2, resdatos.vl_smonto)
          } else {
            notify_warning("ESTA FUERA DE RANGO, VERIFICAR!!!")
          }
        }
        break;

      default:
        // var fecha_actual = moment(vtoday).format('YYYY-MM-DD')
        if (txt_perfec === "" || slc_t_tga === "" || txt_monto === "" || slc_t_est === "" || selectedFile === undefined || selectedFile.length === 0) {
          notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
        } else {
          if (txt_diaini <= vlidarfecha && vlidarfecha <= txt_diafin) {
            //if ( fecha_actual <= vlidarfecha && vlidarfecha <= fecha_actual ) {
            const formData = new FormData();
            formData.append("imagen", selectedFile);
            formData.append("data", JSON.stringify(body));
            formData.append("nplaca", nplaca);

            //console.log("f",f);
            const opPe22 = { method: "POST", headers: {}, body: formData }
            const response = await fetch(url_dti, opPe22);
            const resdatos = await response.json();
            if (resdatos.vl_insert === 1) {
              notify_valido("SE REGISTRO CORRECTAMENTE!!!")
              onClickClear();
              onClickListar();
              if (resdatos.vl_insert2 === 1) {
                PintarTablaSelect(5, "ABIERTO")
                PintarTablaSelect(7, "ABIERTO")
              }
            } else { notify_warning("ERROR AL GUARDAR, VERIFICAR!!!") }
            PintarTablaSelect(2, resdatos.vl_smonto)
            //} else { notify_warning("NO PUEDES REGISTRAR, YA ESTAMOS "+fecha_actual)}
          } else { notify_warning("ESTA FUERA DE RANGO, VERIFICAR!!!") }
        }


        break;
    }
    $("#btn_guardar").attr("disabled", false)
    $("#icon_btn_guardar").removeClass("d-none")
    $("#spinner_btn_guardar").addClass("d-none")
  }
  const onClickClear = () => {
    //PintarTablaSelect()
    $("#txt_id").val("")
    setSlc_t_tga("")
    setSlc_t_con("")
    setSlc_t_jor("")
    setslc_t_emp([""])
    setTxt_monto("")
    setTxt_nrefe("")
    setTxt_obs("")
    setSlc_t_est("E004")
    setSelectedFile("")
    document.getElementById('formFile').value = null
    setdisabled(false)
    $('.tabla-detalle .table .rdt_TableRow').removeClass("table-danger");
    $("#btn_guardar").removeClass('d-none')
    $("#btn_editar").addClass('d-none')
    $("#btn_gmodificado").addClass('d-none')
    $("#formFile").removeClass("d-none")
    $("#formFile2").addClass("d-none")
    $("#formFile2").val("")
    fc_lstConceptos("")
    setdisabledUni('')

    $(".div-panilla").addClass("d-none")
    $(".div-resto").removeClass("d-none")

  };
  const onClickListar = async () => {

    const mun_placa = $(".modal_cod_placa").text()
    body['txt_nplaca'] = mun_placa
    setpending(true)
    let columns = lst_columns2
    const opPe2 = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response2 = await fetch(url_lst, opPe2);
    const data = await response2.json();
    const tableData = { columns, data };
    //console.log('data2', data2);
    setlst(tableData)
    setpending(false)
    //onClickClear()
  }
  const onClickDelete = async (state) => {
    try {
      const mun_placa = $(".modal_cod_placa").text()
      const dtLlegada = await JSON.parse(state.target.id);
      const codigo = dtLlegada.gpa_id
      const del_body = { vcodEmpresa: cook_idEmp, txt_periodo: dataLlegada.txt_periodo, slc_t_suc: dataLlegada.slc_t_suc, slc_t_sem: dataLlegada.slc_t_sem, txt_nplaca: mun_placa, txt_codigo: codigo, txt_usuid: cookies.get('dtUser_id') };
      const del_opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(del_body) }

      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        customClass: {
          container: 'my-swal'
        }

      }).then(async response => {
        if (response.isConfirmed) {
          const respondt = await fetch(url_dta, del_opPe);
          const resdatos = await respondt.json();
          console.log("resdatos", resdatos);
          if (resdatos.vl_insert === 0) {
            notify_warning("ERROR AL ELIMINAR!!!")
          } else if (resdatos.vl_insert === 1) {
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({ title: 'EXITO', text: 'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon: 'success', showConfirmButton: false, timer: 1000 })
            onClickListar()
            onClickClear()
          }
          PintarTablaSelect(2, resdatos.vl_smonto)
        } else if (response.isDenied) {
          Swal.close();
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
  const handleRowClicked = (row) => {

    selectTabla("tabla-detalle")
    setdisabled(true)
    $("#txt_id").val(row.gpa_id);
    setTxt_perfec(new Date(moment(row.gpa_fecha, 'Y-M-D').format('YYYY-MM-DD HH:mm:ss')))
    setSlc_t_tga(row.par_cod)
    fc_lstConceptos(row.par_cod)
    setSlc_t_con(row.spa_cod)
    setSlc_t_jor(row.tjo_cod)
    setslc_t_emp({ 'codigo': row.emp_cod, 'detalle': row.emp_cod + '-' + row.emp_detalle })
    setTxt_monto(row.gpa_monto)
    setTxt_nrefe(row.gpa_mreferencia)
    setTxt_obs(row.gpa_observacion)
    setSlc_t_est(row.est_cod)
    $("#formFile").addClass("d-none")
    $("#formFile2").removeClass("d-none")
    $("#formFile2").val(row.gpa_marchivo)
    $("#btn_guardar").addClass("d-none")
    $("#btn_gmodificado").addClass("d-none")
    $("#btn_editar").removeClass("d-none")

    if (row.est_descripcion === 'ABIERTO') {
      setMoEstado(false)
    } else {
      setMoEstado(true)
    }

    if (row.par_cod === 'P007') {
      $(".div-resto").addClass("d-none")
      $(".div-panilla").removeClass("d-none")
    } else {
      $(".div-panilla").addClass("d-none")
      $(".div-resto").removeClass("d-none")
    }
  }

  const PintarTablaSelect = (num, text) => {
    var iddato = $('.tabla-resumen .table .table-danger #cell-' + num + '-undefined')
    iddato.find('div').text(text)
  };
  const onClickEditInputs = async () => {
    //setdisabled(false)

    $("#btn_editar").addClass('d-none')
    $("#btn_gmodificado").removeClass('d-none')

    if (moEstado === true) {
      setdisabled(true)
      $("#formFile").addClass("d-none")
      $("#formFile2").removeClass("d-none")
    } else {
      setdisabled(false)
      $("#formFile").removeClass("d-none")
      $("#formFile2").addClass("d-none")
    }
    setdisabledUni(true)

    if (dtUser.tpu_id !== 9) {
      $("#slc_t_est").attr("disabled", false)
      $("#btn_gmodificado").removeAttr("disabled")
      setMoEstado(false)
    }

  }
  const onClickGModifcado = async () => {
    //alert("hola modi")
    $(".tabla-detalle .rdt_Table").addClass("table");
    $("#btn_gmodificado").attr("disabled", true)
    $("#icon_btn_gmodificado").addClass("d-none")
    $("#spinner_btn_gmodificado").removeClass("d-none")
    if (txt_perfec === "" || slc_t_tga === "" || txt_monto === "" || txt_nrefe === "" || slc_t_est === "") {
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    } else {
      const txt_diaini = $(".modal_txt_diaini").text()
      const txt_diafin = $(".modal_txt_diafin").text()
      const vlidarfecha = moment(txt_perfec).format('YYYY-MM-DD')

      if (txt_diaini <= vlidarfecha && vlidarfecha <= txt_diafin) {
        //$("#txt_id").val(row.gpa_id);
        var txt_id = $("#txt_id").val()
        var nplaca = $(".modal_cod_placa").text()
        body["txt_id"] = txt_id;

        const formData = new FormData();
        formData.append("imagen", selectedFile);
        formData.append("data", JSON.stringify(body));
        formData.append("nplaca", nplaca);

        //console.log("f",f);
        const opPe22 = { method: "POST", headers: {}, body: formData }
        const response = await fetch(url_dtu, opPe22);
        const resdatos = await response.json();
        if (resdatos.vl_insert === 1) {
          notify_valido("SE REGISTRO CORRECTAMENTE!!!")
          onClickClear();
          onClickListar();
        } else { notify_warning("ERROR AL GUARDAR, VERIFICAR!!!") }
        PintarTablaSelect(2, resdatos.vl_smonto)
      } else {
        notify_warning("ESTA FUERA DE RANGO, VERIFICAR!!!")
      }

    }

    $("#btn_gmodificado").attr("disabled", false)
    $("#icon_btn_gmodificado").removeClass("d-none")
    $("#spinner_btn_gmodificado").addClass("d-none")
  }


  const fc_getOptionsSelect = async (inputValue) => {

    let body = { slc_t_suc: dataLlegada.slc_t_suc, inp_text: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url_emp, opPe);
    const data = await response.json();
    return data;
  }



  useEffect(() => {

    setpending(pending2)
    setlst(dataldt)
    if (dtUser.tpu_id === 9) {
      $("#slc_t_est option[value='E001']").addClass("d-none");
      $("#slc_t_est option[value='E002']").addClass("d-none");
      $("#slc_t_est option[value='E003']").addClass("d-none");
    }
    // else{
    //   setSlc_t_est("")
    //   $("#slc_t_est option[value='E004']").addClass("d-none");
    // }

  }, [pending2, dataldt])

  // useEffect(() => {
  //   onClickListar()
  // },[])

  //
  return (
    <div className="modal fade" id="modalCenter" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true" >
      <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalCenterTitle">Detalle Rendición de Gastos - PLACA: <strong className="modal_cod_placa text-danger"></strong> desde <strong className="modal_des_semana text-danger"></strong> </h5>
            <strong className="modal_txt_diaini d-none"></strong>
            <strong className="modal_txt_diafin d-none"></strong>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClickClear} ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-2">Fecha:</div>
              <div className="col-md-2">
                <input type="number" id="txt_id" name="txt_id" className="form-control form-control-sm text-center d-none" autoComplete='off' disabled="disabled" />
                <DatePicker
                  locale="es"
                  className="form-control form-control-sm text-center txt_perfec"
                  selected={txt_perfec}
                  onChange={date => setTxt_perfec(date)}
                  dateFormat="yyyy-MM-dd"
                  disabled={(disabledUni === '') ? disabled : disabledUni}
                />
              </div>
              <div className="col-md-2">Tipo de Gasto:</div>
              <div className="col-md-2">
                <SelectTipo id="slc_t_tga" url={url_tga} value={slc_t_tga} onChange={changeSlc_t_tga} disabled={(disabledUni === '') ? disabled : disabledUni} />
              </div>
              <div className="col-md-2">Concepto:</div>
              <div className="col-md-2">
                {/* <SelectTipo id="slc_tgaanas" url={url_con+'?txt_cod='+slc_t_tga} value={slc_t_con} onChange={changeSlc_t_con} /> */}
                <select id="slc_t_con" className="form-select form-select-sm" value={slc_t_con} onChange={changeSlc_t_con} disabled={disabled}>
                  <option value={"9999"} >--Seleccione--</option>
                  {
                    slc_conceptos?.map((item, index) => {
                      return <option value={item?.cot_cod} key={index} >{item?.cot_descri1}</option>
                    })
                  }
                </select>
              </div>
            </div>
            <div className="row div-panilla d-none">
              <div className="col-md-2">Tipo de Jornal:</div>
              <div className="col-md-2">
                <SelectTipo id="slc_t_tga" url={url_jor} value={slc_t_jor} onChange={changeSlc_t_jor} disabled={disabled} />
              </div>
              <div className="col-md-2">Empleado:</div>
              <div className="col-md-6 ">
                {/** from-control form-control-sm select2 form-select form-select-lg */}
                <AsyncSelect
                  styles={styles}
                  placeholder="Busque cliente por codigo o descripción"
                  name="form-field-name"
                  className="sele_pru from-control form-control-sm"
                  value={slc_t_emp}
                  cacheOptions
                  defaultOptions={false}
                  getOptionLabel={e => e.detalle}
                  getOptionValue={e => e.codigo}
                  loadOptions={fc_getOptionsSelect}
                  onChange={setslc_t_emp}
                  isClearable={true}
                  openMenuOnClick={false}
                  isDisabled={disabled}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-2 div-resto">NReferencia:</div>
              <div className="col-lg-2 div-resto">
                <input type="text" id="txt_nrefe" name="txt_nrefe" className="form-control form-control-sm text-center" autoComplete='off' value={txt_nrefe} onChange={changeTxt_nrefe} disabled={disabled} />
              </div>
              <div className="col-lg-4 div-panilla d-none"></div>
              <div className="col-lg-2">Monto:</div>
              <div className="col-lg-2">
                <input type="number" id="txt_monto" name="txt_monto" className="form-control form-control-sm text-center" autoComplete='off' value={txt_monto} onChange={changeTxt_monto} disabled={disabled} />
              </div>

              <div className="col-lg-4 div-resto">
                <input type="file" id="formFile" name="formFile" accept='.jpg,.jpeg,.png,.pdf,.xlsx' className="form-control form-control-sm" onChange={changeFile} />
                <input type="text" id="formFile2" name="formFile" className="form-control form-control-sm d-none" disabled="disabled" />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-2">Observación:</div>
              <div className="col-lg-6">
                <input type="text" id="txt_obs" name="txt_obs" className="form-control form-control-sm" autoComplete='off' value={txt_obs} onChange={changeTxt_obs} disabled={disabled} />
              </div>
              <div className="col-md-2">Estado:</div>
              <div className="col-md-2">
                <SelectTipo id="slc_t_est" url={url_est} value={slc_t_est} onChange={changeSlc_t_est} disabled={disabled} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8"></div>
              <div className="col-lg-4 text-end">
                <button className="btn btn-primary btn-sm" id="btn_guardar" onClick={onClickGuardar} >
                  <i className='bx bx-save' id="icon_btn_guardar" ></i>
                  <span className="spinner-border d-none" id="spinner_btn_guardar" role="status" aria-hidden="true"></span>
                  &nbsp;Guardar
                </button>
                <button className="btn btn-warning btn-sm d-none" id="btn_editar" onClick={onClickEditInputs}>
                  <i className='bx bx-edit' ></i>&nbsp;Editar
                </button>
                <button className="btn btn-success btn-sm d-none" id="btn_gmodificado" onClick={onClickGModifcado} disabled={moEstado}>
                  <i className='bx bx-send' id="icon_btn_gmodificado" ></i>
                  <span className="spinner-border d-none" id="spinner_btn_gmodificado" role="status" aria-hidden="true"></span>
                  &nbsp;GModificado
                </button>
                <button className="btn btn-dark btn-sm" id="btn_cancelar" onClick={onClickClear}>
                  <i className='bx bx-history' ></i>&nbsp;Cancelar
                </button>
                <button className="btn btn-info btn-sm" id="btn_mlista" onClick={onClickListar} >
                  <i className='bx bx-list-ul' ></i>&nbsp;MLista
                </button>
              </div>
            </div>
            <div className="card-datatable table-responsive ">
              <DataTableExtensions  {...lst} >
                <DataTable className="tabla-detalle"
                  Header
                  defaultSortField="id"
                  defaultSortAsc={true}
                  pagination
                  responsive
                  highlightOnHover
                  progressPending={pending}
                  noContextMenu="No hay registros para mostrar"
                  onRowClicked={handleRowClicked}
                //conditionalRowStyles={conditionalRowStyles}
                />
              </DataTableExtensions>
            </div>
          </div>
          <div className="modal-footer">
          </div>
        </div>
      </div>
    </div>
  )
}
export const ModalDetallesB = ({ hanOpenModal, hanClosModal, onDataEstras }) => {
  const [pending2, setPending2] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [arrDatos, setArrDatos] = useState([]);
  const columns = useMemo(() => onDataEstras.columns, [onDataEstras.columns])

  const lstDatos = async () => {
    setPending2(true)
    const opPe9 = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const resp = await fetch(onDataEstras.url, opPe9);
    const data = await resp.json();
    setArrDatos(data)
    setPending2(false)
  }


  useEffect(() => {
    lstDatos()
  }, [])
  const globalTheme = window.templateCustomizer.settings.style;
  return (
    <Modal
      open={hanOpenModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: '75%', height: '80%', overflow: 'auto' }}>
        <button
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          className='btn btn-outline-dark btn-sm'
          onClick={hanClosModal}
        >
          <i className='fa fa-x'></i>
        </button>
        <div style={{ userSelect: 'none' }}>

          <div className="card-datatable table-responsive py-4" >
            {/*<div style={{ maxHeight: '300px', overflowY: 'auto' }}>*/}
            <MaterialReactTable
              columns={columns}
              data={arrDatos}
              state={{ isLoading: pending2, sorting }}
              onSortingChange={setSorting}

              initialState={{
                density: 'compact',
                expanded: false,
                showColumnFilters: false,
                showGlobalFilter: false,

              }}
              enableFullScreenToggle={false} //quitar el alternar pantalla completa
              enableGrouping //para agrupar
              enableStickyHeader //porner escrol
              muiTableContainerProps={{ sx: { maxHeight: '256px' } }} //tamaño de largo de la tabla
              muiTableBodyCellProps={{ sx: { p: '2px 16px', fontSize: '12px' } }} //tamaño de la letra de las filas
              localization={MRT_Localization_ES}//idioma
              enablePagination={false}//quitar el enpaginado
              muiTableBodyRowProps={({ row }) => ({
                hover: false,
                className: row.original.Code,
                sx: {
                  '&:hover': {
                    color: 'error',
                    cursor: 'pointer',//cambiar el puntero de maus
                    userSelect: 'none'//al seleccionar no surrallas las filas
                  },
                  //background: row.index === selectedPedidoRows ? 'lightblue' : 'white',

                }
              })
              }
              muiCircularProgressProps={{ color: 'error' }}
                        muiSkeletonProps={{
                          animation: 'pulse',
                          height: 28,
                        }}

            />
          </div>
          {/* <div className="text-end">

              <AButton tp_tilde="Probar2" bt_descr="Agregar a tabla" tp_posit="top" bt_class="btn btn-sm btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} />
            </div> */}
        </div>
      </Box>
    </Modal>
  )
}
export const ModalDetallesBoni = ({ hanOpenModal, hanClosModal, onReturnData, onDataEstras }) => {
  const [pending2, setPending2] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [arrDatos, setArrDatos] = useState([]);
  const [btnVisible, setBtnVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [startRowIndex, setStartRowIndex] = useState(null);


  const columns = useMemo(() => onDataEstras.columns, [onDataEstras.columns])

  const lstDatos = async () => {
    setPending2(true)
    const opPe9 = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const resp = await fetch(onDataEstras.url, opPe9);
    const data = await resp.json();
    setArrDatos(data)
    setPending2(false)
  }



  const handleRowMouseDown = (row) => {

    const valExist = selectedRows.filter(selectedRow => selectedRow === row.original.Code);
    if (valExist.length) {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow !== row.original.Code));
      setTimeout(() => {
        if (selectedRows.length === 1) {
          setBtnVisible(false)
        }
      }, 500)
    } else {
      setSelectedRows(prev => [...prev, row.original.Code]);
      setStartRowIndex(true);
      setBtnVisible(true)
    }

  };
  const handleRowMouseEnter = (row) => {
    if (startRowIndex) {
      setSelectedRows(prev => [...prev, row.original.Code]);
    }
  };

  const handleRowMouseUp = () => {
    setStartRowIndex(false);
    // const exportData = emp_lst2.filter(
    //   item => selectedRows.includes(item.DocEntry)
    // );
    // setSelectedRows(Array.from(newSelection));
  };

  const probar2 = () => {

    const exportData = arrDatos.filter((item) => selectedRows.includes(item.Code));
    onReturnData([...exportData])
    setSelectedRows([])
    setBtnVisible(false)

  }

  useEffect(() => {
    lstDatos()
    //onReturnData(selectedRows)
    //setArrDatosInsert(listInitData)


    //}, [listInitData])
  }, [])
  const globalTheme = window.templateCustomizer.settings.style;
  return (
    <Modal
      open={hanOpenModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: '75%', height: '80%', overflow: 'auto' }}>
        <button
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          className='btn btn-outline-dark btn-sm'
          onClick={hanClosModal}
        >
          <i className='fa fa-x'></i>
        </button>
        <div style={{ userSelect: 'none' }}>

          <div className="card-datatable table-responsive py-4" >
            {/*<div style={{ maxHeight: '300px', overflowY: 'auto' }}>*/}
            <MaterialReactTable
              columns={columns}
              data={arrDatos}
              // options ={{
              //   searchText:"sdsds",
              //   searchTerm:"sdsads"
              // }}
              // manualSorting={false}
              // manualFiltering={true}
              // manualPagination={true}
              state={{ isLoading: pending2, sorting }}
              onSortingChange={setSorting}

              initialState={{
                density: 'compact',
                expanded: false,
                showColumnFilters: false,
                showGlobalFilter: false,

              }}
              enableFullScreenToggle={false} //quitar el alternar pantalla completa
              enableGrouping //para agrupar
              enableStickyHeader //porner escrol
              muiTableContainerProps={{ sx: { maxHeight: '256px' } }} //tamaño de largo de la tabla
              muiTableBodyCellProps={{ sx: { p: '2px 16px', fontSize: '12px' } }} //tamaño de la letra de las filas
              localization={MRT_Localization_ES}//idioma
              enablePagination={false}//quitar el enpaginado
              muiTableBodyRowProps={({ row }) => ({
                hover: false,
                //className: selectedRows.find(r => r === row.original.Code) ? 'highlight2' : '',
                sx: {
                  '&:hover': {
                    color: 'error',
                    cursor: 'pointer',//cambiar el puntero de maus
                    userSelect: 'none'//al seleccionar no surrallas las filas
                  },
                  //background: row.index === selectedPedidoRows ? 'lightblue' : 'white',
                  backgroundColor: selectedRows.find(r => r === row.original.Code) ? 'lightblue' : '',
                },
                onMouseDown: () => handleRowMouseDown(row),
                onMouseEnter: () => handleRowMouseEnter(row),
                onMouseUp: handleRowMouseUp,
              })
              }
              renderTopToolbarCustomActions={({ table, row }) => (
                <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                  {btnVisible &&
                    (<AButton tp_tilde="Agregar a tabla" bt_descr="Agregar a tabla" tp_posit="top" bt_class="btn btn-sm btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bxs-share" vl_tpbtn={1} />)
                  }
                </Box>)}
muiCircularProgressProps={{ color: 'error' }}
                        muiSkeletonProps={{
                          animation: 'pulse',
                          height: 28,
                        }}
            />
          </div>
          {/* <div className="text-end">

              <AButton tp_tilde="Probar2" bt_descr="Agregar a tabla" tp_posit="top" bt_class="btn btn-sm btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} />
            </div> */}
        </div>
      </Box>
    </Modal>
  )
}
export const ModalDetallesBoniPar = ({ hanOpenModal, hanClosModal, onReturnData, onDataEstras }) => {
  const [pending2, setPending2] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [arrDatos, setArrDatos] = useState([]);
  const [btnVisible, setBtnVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [startRowIndex, setStartRowIndex] = useState(null);

  const [veh_sl_t_Unidad_Negocio, setVehSl_t_Unidad_Negocio] = useState([""]);
  const [veh_sl_t_Categorias, setVehSl_t_Categorias] = useState([""]);

  const veh_Select2_Unidad_Negocio = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }

  const veh_Select2_Categorias = async (inputValue) => {
    const url = '/apiCategoria?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }


  const columns = useMemo(() => onDataEstras.columns, [onDataEstras.columns])

  const handleRowMouseDown = (row) => {

    const valExist = selectedRows.filter(selectedRow => selectedRow === row.original.Code);
    if (valExist.length) {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow !== row.original.Code));
      setTimeout(() => {
        if (selectedRows.length === 1) {
          setBtnVisible(false)
        }
      }, 500)
    } else {
      setSelectedRows(prev => [...prev, row.original.Code]);
      setStartRowIndex(true);
      setBtnVisible(true)
    }

  };
  const handleRowMouseEnter = (row) => {
    if (startRowIndex) {
      setSelectedRows(prev => [...prev, row.original.Code]);
    }
  };

  const handleRowMouseUp = () => {
    setStartRowIndex(false);
    // const exportData = emp_lst2.filter(
    //   item => selectedRows.includes(item.DocEntry)
    // );
    // setSelectedRows(Array.from(newSelection));
  };

  const probar2 = () => {

    const exportData = arrDatos.filter((item) => selectedRows.includes(item.Code));
    onReturnData([...exportData])
    setSelectedRows([])
    setBtnVisible(false)

  }

  const Consultarbtn = async () => {
    //lstDatos();
    setPending2(true)
    const opPe9 = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const resp = await fetch(onDataEstras.url + '&coduni=' + veh_sl_t_Unidad_Negocio.Code + '&codcat=' + veh_sl_t_Categorias.Code, opPe9);
    const data = await resp.json();
    setArrDatos(data)
    setPending2(false)
  }
  const onClickLimpiar = async (row) => {
    setPending2(false)
    setSorting([])
    setArrDatos([])
    setBtnVisible(false)
    setSelectedRows([])
    setStartRowIndex(null)
    setVehSl_t_Unidad_Negocio([])
    setVehSl_t_Categorias([])
  }
  const globalTheme = window.templateCustomizer.settings.style;
  return (
    <Modal
      open={hanOpenModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: '75%', height: '80%', overflow: 'auto' }}>
        <button
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          className='btn btn-outline-dark btn-sm'
          onClick={hanClosModal}
        >
          <i className='fa fa-x'></i>
        </button>
        <div className="py-5" style={{ userSelect: 'none' }}>
          <div className="row">
            <div className="col-lg-1">UnidadN.:</div>
            <div className="col-lg-3" id="comboUN" style={{ position: 'relative' }} >
              <AsyncSelect
                id="veh_sl2_chofer"
                styles={styles}
                placeholder="Busque por Cod/Desc"
                name="form-field-name"
                className="sele_pru from-control form-control-sm my-swal"
                value={veh_sl_t_Unidad_Negocio}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code + ' - ' + e.Name}
                getOptionValue={e => e.Code}
                loadOptions={veh_Select2_Unidad_Negocio}
                onChange={setVehSl_t_Unidad_Negocio}
              />
            </div>
            <div className="col-lg-1">Categoria:</div>
            <div className="col-lg-3">
              <AsyncSelect
                id="veh_sl2_chofer"
                styles={styles}
                placeholder="Busque por Cod/Desc"
                name="form-field-name"
                className="sele_pru from-control form-control-sm my-swal"
                value={veh_sl_t_Categorias}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code + ' - ' + e.Name}
                getOptionValue={e => e.Code}
                loadOptions={veh_Select2_Categorias}
                onChange={setVehSl_t_Categorias}
              />
            </div>
            <div className="col-lg-3 text-center">
              <div className="text-center">
                <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={Consultarbtn}>
                  <i className='bx bx-list-ul' ></i>&nbsp;Consultar
                </button>&nbsp;
                <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
              </div>
            </div>
          </div>

          <div className="card-datatable table-responsive py-4" >
            {/*<div style={{ maxHeight: '300px', overflowY: 'auto' }}>*/}
            <MaterialReactTable
              columns={columns}
              data={arrDatos}
              // options ={{
              //   searchText:"sdsds",
              //   searchTerm:"sdsads"
              // }}
              // manualSorting={false}
              // manualFiltering={true}
              // manualPagination={true}
              state={{ isLoading: pending2, sorting }}
              onSortingChange={setSorting}

              initialState={{
                density: 'compact',
                expanded: false,
                showColumnFilters: false,
                showGlobalFilter: false,

              }}
              enableFullScreenToggle={false} //quitar el alternar pantalla completa
              enableGrouping //para agrupar
              enableStickyHeader //porner escrol
              muiTableContainerProps={{ sx: { maxHeight: '256px' } }} //tamaño de largo de la tabla
              muiTableBodyCellProps={{ sx: { p: '2px 16px', fontSize: '12px' } }} //tamaño de la letra de las filas
              localization={MRT_Localization_ES}//idioma
              enablePagination={false}//quitar el enpaginado
              muiTableBodyRowProps={({ row }) => ({
                hover: false,
                //className: selectedRows.find(r => r === row.original.Code) ? 'highlight2' : '',
                sx: {
                  '&:hover': {
                    color: 'error',
                    cursor: 'pointer',//cambiar el puntero de maus
                    userSelect: 'none',//al seleccionar no surrallas las filas
                  },
                  backgroundColor: selectedRows.find(r => r === row.original.Code) ? 'lightblue' : '',

                },
                onMouseDown: () => handleRowMouseDown(row),
                onMouseEnter: () => handleRowMouseEnter(row),
                onMouseUp: handleRowMouseUp,
              })
              }
              renderTopToolbarCustomActions={({ table, row }) => (
                <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                  {btnVisible &&
                    (<AButton tp_tilde="Agregar a tabla" bt_descr="Agregar a tabla" tp_posit="top" bt_class="btn btn-sm btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bxs-share" vl_tpbtn={1} />)
                  }
                </Box>)}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}
            />
          </div>
          {/* <div className="text-end">

              <AButton tp_tilde="Probar2" bt_descr="Agregar a tabla" tp_posit="top" bt_class="btn btn-sm btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} />
            </div> */}
        </div>
      </Box>
    </Modal>
  )
}

export const ModalDetallesBoniParStock = ({ hanOpenModal, hanClosModal, onReturnData, onDataEstras }) => {
  // Este modal es copia de: ModalDetallesBoniPar
  // per sin la funcionalidad de seleccionar filas
  const [pending2, setPending2] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [arrDatos, setArrDatos] = useState([]);
  const [btnVisible, setBtnVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [startRowIndex, setStartRowIndex] = useState(null);

  const [veh_sl_t_Unidad_Negocio, setVehSl_t_Unidad_Negocio] = useState([""]);
  const [veh_sl_t_Categorias, setVehSl_t_Categorias] = useState([""]);

  const veh_Select2_Unidad_Negocio = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }


  const veh_Select2_Categorias = async (inputValue) => {
    const url = '/apiCategoria?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }


  const columns = useMemo(() => onDataEstras.columns, [onDataEstras.columns])



  const handleRowMouseEnter = (row) => {
    if (startRowIndex) {
      setSelectedRows(prev => [...prev, row.original.Code]);
    }
  };

  const handleRowMouseUp = () => {
    setStartRowIndex(false);
    // const exportData = emp_lst2.filter(
    //   item => selectedRows.includes(item.DocEntry)
    // );
    // setSelectedRows(Array.from(newSelection));
  };

  const probar2 = () => {

    const exportData = arrDatos.filter((item) => selectedRows.includes(item.Code));
    onReturnData([...exportData])
    setSelectedRows([])
    setBtnVisible(false)

  }

  const Consultarbtn = async () => {
    //lstDatos();
    setPending2(true)
    const opPe9 = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const resp = await fetch(onDataEstras.url + '&coduni=' + veh_sl_t_Unidad_Negocio.Code + '&codcat=' + veh_sl_t_Categorias.Code, opPe9);
    const data = await resp.json();
    // console.log(data);
    setArrDatos(data)
    setPending2(false)
  }
  const onClickLimpiar = async (row) => {
    setPending2(false)
    setSorting([])
    setArrDatos([])
    setBtnVisible(false)
    setSelectedRows([])
    setStartRowIndex(null)
    setVehSl_t_Unidad_Negocio([])
    setVehSl_t_Categorias([])
  }
  const globalTheme = window.templateCustomizer.settings.style;

  // funciones par el modal hijo

  const [rowSelect, setrowSelect] = useState({})
  const [openModalHijo, setOpenModalHijo] = useState(false)

  const cerrModalHijo = () => {
    setOpenModalHijo(!openModalHijo)
  }
  const ModalStock = ({ modalOpen, cerrarModal, articulo }) => {
    const [articuloInfo, setArticuloInfo] = useState([]);
    const [dataCargada, setDataCargada] = useState(false);

    const fetchArticuloInfo = async () => {
      try {
        const response = await fetch(`/apiArticulosStock?vcodEmpresa=${cook_idEmp}&codArt=${articulo.Code}`);
        console.log(`/apiArticulosStock?vcodEmpresa=${cook_idEmp}&codArt=${articulo.Code}`);
        if (response.ok) {
          const data = await response.json();
          setArticuloInfo(data);
        } else {
          throw new Error('Error al consultar la API');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setDataCargada(true)
      }
    };
    useEffect(() => {
      if (modalOpen) {
        fetchArticuloInfo();
      }
    }, [modalOpen, articulo]);
    return (
      <>
        {dataCargada && modalOpen && (
          <div className={`modal fade ${modalOpen ? 'show' : ''}`} id="modalToggle1" tabIndex="-1" style={{ display: modalOpen ? 'block' : 'none', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999999 }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title ">Detalle de Articulo</h5>
                  <button type="button" className="btn-close" onClick={cerrarModal} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="row">

                    <div className="col-4">   <label> Codigo</label> <label className="form-control form-control-sm"> {articulo.Code}</label></div>
                    <div className="col-8"><label> Descripcion</label>  <label className="form-control form-control-sm"> {articulo.Name}</label></div>
                  </div>

                  <br />
                  {/* <h6> {articulo.Name}</h6> */}

                  <table className="table table-sm" id='tab_BCEObsequio' style={{ fontSize: '14px', zIndex: '2', width: '100%' }}>
                    <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
                      <tr >
                        <th>Sucursal</th>
                        <th>Stock</th>
                      </tr>
                    </thead>
                    <tbody >
                      {
                        (articuloInfo.length) ? articuloInfo.map((row) => (

                          <tr >
                            <td className='td3'>{row?.sucursal}</td>
                            <td className='td4'>{row?.cantidad}</td>
                          </tr>
                        ))
                          :
                          <div></div>
                      }
                    </tbody>
                  </table>

                </div>
                <div className="modal-footer">
                  {/* <button type="button" className="btn btn-secondary" onClick={cerrarModal}>Cerrar</button> */}

                </div>
              </div>
            </div>
          </div>
        )}
      </>

    )

  }
  const handleRowMouseDown = (row) => {
    setrowSelect(row.original)
    cerrModalHijo()

  };
  return (
    <Modal
      open={hanOpenModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: '75%', height: '80%', overflow: 'auto' }}>
        <button
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          className='btn btn-outline-dark btn-sm'
          onClick={hanClosModal}
        >
          <i className='fa fa-x'></i>
        </button>
        <div className="py-5" style={{ userSelect: 'none' }}>
          <div className="row">
            <div className="col-lg-1">UnidadN.:</div>
            <div className="col-lg-3" id="comboUN" style={{ position: 'relative' }} >
              <AsyncSelect
                id="veh_sl2_chofer"
                styles={styles}
                placeholder="Busque por Cod/Desc"
                name="form-field-name"
                className="sele_pru from-control form-control-sm my-swal"
                value={veh_sl_t_Unidad_Negocio}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code + ' - ' + e.Name}
                getOptionValue={e => e.Code}
                loadOptions={veh_Select2_Unidad_Negocio}
                onChange={setVehSl_t_Unidad_Negocio}
              />
            </div>
            <div className="col-lg-1">Categoria:</div>
            <div className="col-lg-3">
              <AsyncSelect
                id="veh_sl2_chofer"
                styles={styles}
                placeholder="Busque por Cod/Desc"
                name="form-field-name"
                className="sele_pru from-control form-control-sm my-swal"
                value={veh_sl_t_Categorias}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code + ' - ' + e.Name}
                getOptionValue={e => e.Code}
                loadOptions={veh_Select2_Categorias}
                onChange={setVehSl_t_Categorias}
              />
            </div>
            <div className="col-lg-3 text-center">
              <div className="text-center">
                <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={Consultarbtn}>
                  <i className='bx bx-list-ul' ></i>&nbsp;Consultar
                </button>&nbsp;
                <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
              </div>
            </div>
          </div>

          <div className="card-datatable table-responsive py-4" >
            {/*<div style={{ maxHeight: '300px', overflowY: 'auto' }}>*/}
            <MaterialReactTable
              columns={columns}
              data={arrDatos}
              // options ={{
              //   searchText:"sdsds",
              //   searchTerm:"sdsads"
              // }}
              // manualSorting={false}
              // manualFiltering={true}
              // manualPagination={true}
              state={{ isLoading: pending2, sorting }}
              onSortingChange={setSorting}

              initialState={{
                density: 'compact',
                expanded: false,
                showColumnFilters: false,
                showGlobalFilter: false,

              }}
              enableFullScreenToggle={false} //quitar el alternar pantalla completa
              enableGrouping //para agrupar
              enableStickyHeader //porner escrol
              muiTableContainerProps={{ sx: { maxHeight: '256px' } }} //tamaño de largo de la tabla
              muiTableBodyCellProps={{ sx: { p: '2px 16px', fontSize: '12px' } }} //tamaño de la letra de las filas
              localization={MRT_Localization_ES}//idioma
              enablePagination={false}//quitar el enpaginado
              muiTableBodyRowProps={({ row }) => ({
                hover: false,
                //className: selectedRows.find(r => r === row.original.Code) ? 'highlight2' : '',
                sx: {
                  '&:hover': {
                    color: 'error',
                    cursor: 'pointer',//cambiar el puntero de maus
                    userSelect: 'none'//al seleccionar no surrallas las filas
                  },
                  //background: row.index === selectedPedidoRows ? 'lightblue' : 'white',
                  backgroundColor: selectedRows.find(r => r === row.original.Code) ? 'lightblue' : '',
                },
                onMouseDown: () => handleRowMouseDown(row),
                // onMouseEnter: () => handleRowMouseEnter(row),
                //  onMouseUp: handleRowMouseUp,
              })
              }
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}
            // renderTopToolbarCustomActions={({ table, row }) => (
            //   <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
            //     {btnVisible &&
            //       (<AButton tp_tilde="Agregar a tabla" bt_descr="Agregar a tabla" tp_posit="top" bt_class="btn btn-sm btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bxs-share" vl_tpbtn={1} />)
            //     }
            //   </Box>)}

            />
          </div>
          {/* <div className="text-end">

              <AButton tp_tilde="Probar2" bt_descr="Agregar a tabla" tp_posit="top" bt_class="btn btn-sm btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} />
            </div> */}
        </div>
        <ModalStock modalOpen={openModalHijo} cerrarModal={cerrModalHijo} articulo={rowSelect} />

      </Box>
    </Modal>
  )
}
export const ModalBEPorE = ({ hanOpenModal, hanClosModal, onDataEstras }) => {

  const [txtEntre, setTxtEntre] = useState('0.00');
  const [txtPorEntre, setTxtPorEntre] = useState('0.00');




  // const Consultarbtn = async() => {
  //   const opPe9 = { method: "GET", headers: { 'Content-Type': 'application/json' } }
  //   const resp = await fetch(onDataEstras.url, opPe9);
  //   const data = await resp.json();
  //   console.log("validar data => ", data);

  // }

  // useEffect(() => {

  //   console.log("onDataEstras",onDataEstras);
  //   console.log("onDataEstras.Entregado", onDataEstras[0]?.Entregado);
  //   setTxtEntre(onDataEstras.Entregado)
  //   setTxtPorEntre(onDataEstras.PorEntregar)

  // }, [onDataEstras]);


  const globalTheme = window.templateCustomizer.settings.style;
  return (
    <Modal
      open={hanOpenModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: '50%', height: '50%', overflow: 'auto' }}>
        <button
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          className='btn btn-outline-dark btn-sm'
          onClick={hanClosModal}
        >
          <i className='fa fa-x'></i>
        </button>
        <div className="py-4" style={{ userSelect: 'none' }}>
          <div className="row ">
            <div className="col-6 ">
              <div className="col-lg mb-md-0">
                <div className="card border-info border shadow-none">
                  <div className="card-body position-relative">

                    <div className="my-3 pt-2 text-center">
                      <img src="../../assets/img/icons/unicons/wallet-info.png" alt="Pro Image" height="80" />
                    </div>
                    <h3 className="card-title text-center text-capitalize text-info mb-1">Entregado</h3>
                    <div className="text-center">
                      <div className="d-flex justify-content-center">
                        <sup className="h6 pricing-currency mt-3 mb-0 me-1 text-info">S/.</sup>
                        <h1 className="price-toggle price-yearly display-4 text-info mb-0">{onDataEstras[0]?.Entregado}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-6 ">
              <div className="col-lg mb-md-0">
                <div className="card border-info border shadow-none">
                  <div className="card-body position-relative">

                    <div className="my-3 pt-2 text-center ">
                      <img src="../../assets/img/icons/unicons/wallet-info.png" alt="Pro Image" height="80" />
                    </div>
                    <h3 className="card-title text-center text-capitalize text-info mb-1">PorEntregar</h3>
                    <div className="text-center">
                      <div className="d-flex justify-content-center">
                        <sup className="h6 pricing-currency mt-3 mb-0 me-1 text-info">S/.</sup>
                        <h1 className="price-toggle price-yearly display-4 text-info mb-0">{onDataEstras[0]?.PorEntregar}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export const ModalDataClientsAndLeads = ({ tipo, surcusal, code, dataReturn }) => {
  const [generalModal, setGeneralModal] = useState(false);
  const [direccionModal, setDireccionModal] = useState(false);
  const [pagoModal, setpagoModal] = useState(false);
  const [propiedades, setPropiedades] = useState(false);
  const [vendedores, setVendedores] = useState(false);
  const [listaPagoCombo, setSlc_pagoCombo] = useState([]);
  const [dataFinal, setDataFinal] = useState(
    {
      FormPrincipal: code ? {
        "codigo": code.codigo,
        "nombre": code.nombre,
        "categoria": code.categoria,
        "numeroDocumento": code.numeroDocumento,
        "apellidoPaterno": code.apellidoPaterno,
        "apellidoMaterno": code.apellidoMaterno,
        "primerNombre": code.primerNombre,
        "segundoNombre": code.segundoNombre,
        "tipoPersona": code.tipoPersona,
        "tipoDocumento": code.tipoDocumento,
        "multisede": code.multisede,
        "activo": code.activo,
        "abcategoriaClientes": code.abcategoriaClientes,
      } : [],
      FormDatosGenerales: code ? {
        "giroNegocio": code.giroNegocio,
        "telefono": code.telefono,
        "correo": code.correo
      } : [],
      FormCondicionesPago: code ? {
        "condicionesPago": code.condicionesPago,
        "precios": code.precios,
        "limiteCredito": code.limiteCredito
      } : [],
      FormDirecciones: code ? {
        "direccionVenta": code.direcciones ? code.direcciones.direccionVenta : [],
        "direccionReparto": code.direcciones ? code.direcciones.direccionReparto : [],
      } : [],
      FormPropiedades: code ? code.propiedades : [],
      FormVendedores: code ? code.vendedores : [],
    }
  );

  const handleOpenGeneral = () => {
    setGeneralModal(true);
  }

  const handleCloseGeneral = () => {
    setGeneralModal(false);
  }

  const handleOpenDireccion = () => {
    setDireccionModal(true);
  }

  const handleCloseDireccion = () => {
    setDireccionModal(false);
  }
  const handleOpenPago = () => {
    setpagoModal(true);
  }

  const handleClosePago = () => {
    setpagoModal(false);
  }
  const handleOpenPropiedades = () => {
    setPropiedades(true);
  }

  const handleClosePropiedades = () => {
    setPropiedades(false);
  }
  const handleOpenVendedores = () => {
    setVendedores(true);
  }

  const handleCloseVendedores = () => {
    setVendedores(false);
  }

  const handleButtonClick = async (event) => {
    const activos = contarActivosVerdaderos(dataFinal?.FormPropiedades);
    dataFinal.FormPrincipal.multisede = activos >= 2 ? true : false;
    if (validaciones()) {
      return;
    };
    if (!tipo) {
      dataReturn(dataFinal);
      return;
    }
    if (tipo === true) {
      dataReturn(dataFinal);
      return;
    }
    Swal.fire({
      title: 'ADVERTENCIA',
      text: '¿Desea confirmar este lead?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Solo guardar',
      confirmButtonText: 'Convertir a cliente',
      customClass: {
        container: 'my-swal'
      }
    }).then(async (result) => {
      let urlPost = `/apiSaveClientsOrLeads?vcodEmpresa=${cook_idEmp}&codigo=${dataFinal.FormPrincipal.codigo}&sucursal=${surcusal}`;
      if (result.isConfirmed) {
        urlPost = `/apiSaveClientsOrLeads?vcodEmpresa=${cook_idEmp}&codigo=${dataFinal.FormPrincipal.codigo}&sucursal=${surcusal}&tipo=lead`;
      }
      let body = dataFinal;
      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
      const response = await fetch(urlPost, opPe);
      const dataResponse = await response.json();
      if (dataResponse?.status === 400) {
        return notify_warning("Por favor revisar la información a enviar");
      }
      dataReturn(dataFinal);
      notify_valido("Guardado");
    })
  };

  const contarActivosVerdaderos = (datos) => {
    let count = 0;
    for (var i = 0; i < datos.length; i++) {
      if (datos[i].activo) {
        count++;
      }
    }
    return count;
  }

  const get_lista_pago = useCallback(async () => {
    const url_con = `/apigetListPrecio?vcodEmpresa=${cook_idEmp}&sucursal=${surcusal}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setSlc_pagoCombo(data)
  }, [surcusal]);

  useMemo(() => {
    if (listaPagoCombo.length === 0) {
      get_lista_pago();
    }
    let canalAcronimo = {
      '110': 'BOD-CON',
      '107': 'MER-CON',
      '100': 'MAY-CON',
    };
    const acronimo = canalAcronimo[dataFinal?.FormPrincipal?.categoria]
    const listaPrecio = listaPagoCombo.find((res) =>
      res.Nombre.includes(acronimo, 0),
    );
    if (listaPrecio?.Codigo) {
      dataFinal["FormCondicionesPago"]["precios"] = listaPrecio.Codigo;
    }
  }, [dataFinal, get_lista_pago, listaPagoCombo]);

  const validaciones = () => {
    if (dataFinal?.FormPrincipal?.tipoPersona === "TPN") {
      dataFinal["FormPrincipal"]["nombre"] = `${dataFinal["FormPrincipal"]["apellidoPaterno"]} ${dataFinal["FormPrincipal"]["apellidoMaterno"]}, ${dataFinal["FormPrincipal"]["primerNombre"]} ${dataFinal["FormPrincipal"]["segundoNombre"] ? dataFinal["FormPrincipal"]["segundoNombre"] : ''}`;
    }
    if (!dataFinal.FormPropiedades) {
      notify_warning("Es necesario asignar Propiedades");
      return true;
    }
    if (!dataFinal?.FormVendedores || !dataFinal?.FormVendedores.length) {
      notify_warning("Es necesario asignar al menos un vendedor");
      return true;
    }
    if (validarActivos(dataFinal.FormPropiedades)) {
      notify_warning("Minimo se debe asignar una propiedad");
      return true;
    }
    if (!dataFinal.FormDirecciones?.hasOwnProperty('direccionReparto') || !dataFinal.FormDirecciones['direccionReparto']?.length) {
      notify_warning("Es necesario asignar direcciones");
      return true;
    }
    if (!dataFinal.FormDatosGenerales?.hasOwnProperty('giroNegocio')) {
      notify_warning("Es necesario configurar el giro de negocio");
      return true;
    }
    if (!dataFinal.FormCondicionesPago?.hasOwnProperty('condicionesPago')) {
      notify_warning("Es necesario configurar las condiciones de pago");
      return true;
    }
    if (!dataFinal.FormCondicionesPago?.hasOwnProperty('precios')) {
      notify_warning("Es necesario configurar la lista de precios");
      return true;
    }
    if (!dataFinal.FormCondicionesPago?.precios) {
      notify_warning("Es necesario establecer una lista de precios");
      return true;
    }
    let canalAcronimo = {
      '110': 'BOD-CON',
      '107': 'MER-CON',
      '100': 'MAY-CON',
    };
    const acronimo = canalAcronimo[dataFinal.FormPrincipal.categoria]
    const listaPrecio = listaPagoCombo.find((res) =>
      res.Nombre.includes(acronimo, 0),
    );
    dataFinal["FormCondicionesPago"]["precios"] = listaPrecio.Codigo;
    return false;
  }

  const validarActivos = (datos) => {
    for (var i = 0; i < datos.length; i++) {
      if (datos[i]["activo"]) {
        return false;
      }
    }
    return true;
  }


  const setDataFromPrincipalData = (id, data) => {
    setDataFinal({ ...dataFinal, [id]: data });
    notify_valido('Guardando');
    if (id === 'FormDatosGenerales' || id === 'FormCondicionesPago' || id === 'FormDirecciones' || id === 'FormPropiedades' || id === 'FormVendedores') {
      handleCloseGeneral();
      handleClosePago();
      handleCloseDireccion();
      handleClosePropiedades();
      handleCloseVendedores();
    }
  }

  return (
    <div>
      <div className='d-flex'>
        <h6 className="text-muted">Datos Maestro Socio de Negocio</h6>
      </div>
      <div className="card card-action">
        <div className='card-body'>
          <div className='d-flex flex-column'>
            <div className='d-flex'>
              <PricipalData data={dataFinal.FormPrincipal} dataValidacion={dataFinal.FormPropiedades} dataReturn={setDataFromPrincipalData} tipo={tipo} />
            </div>
            <hr className='m-2' />
            <div className='d-flex align-items-end mt-3'>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item m-1" role="presentation">
                  <button className="nav-link rounded-pill btn-danger btn btn-sm btn-icon me-4" id="pills-general-tab" data-bs-toggle="pill" data-bs-target="#pills-general-content" type="button" role="tab" aria-controls="pills-general-content" aria-selected="false"
                    onClick={handleOpenGeneral}>General</button>
                </li>
                <li className="nav-item m-1" role="presentation">
                  <button className="nav-link rounded-pill btn-danger btn btn-sm btn-icon me-4" id="pills-direction-tab" data-bs-toggle="pill" data-bs-target="#pills-direction-content" type="button" role="tab" aria-controls="pills-direction-content" aria-selected="false" onClick={handleOpenDireccion}>Direcciones</button>
                </li>
                <li className="nav-item m-1" role="presentation">
                  <button className="nav-link rounded-pill btn-danger btn btn-sm btn-icon me-4" id="pills-method-page-tab" data-bs-toggle="pill" data-bs-target="#pills-method-page-content" type="button" role="tab" aria-controls="pills-method-page-content" aria-selected="false" onClick={handleOpenPago}>Condiciones de pago</button>
                </li>
                <li className="nav-item m-1" role="presentation">
                  <button className="nav-link rounded-pill btn-danger btn btn-sm btn-icon me-4" id="pills-properties-tab" data-bs-toggle="pill" data-bs-target="#pills-properties-content" type="button" role="tab" aria-controls="pills-properties-content" aria-selected="false" onClick={handleOpenPropiedades}>Propiedades</button>
                </li>
                <li className="nav-item m-1" role="presentation">
                  <button className="nav-link rounded-pill btn-danger btn btn-sm btn-icon me-4" id="pills-properties-tab" data-bs-toggle="pill" data-bs-target="#pills-properties-content" type="button" role="tab" aria-controls="pills-properties-content" aria-selected="false" onClick={handleOpenVendedores}>Vendedores</button>
                </li>
              </ul>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <button type="button" className="btn btn-label-success btn-sm" id="btn_modal_guardar" onClick={(e) => handleButtonClick(e)} >
              <i className='bx bx-save' id="icon_btn_modal_guardar" ></i>
              &nbsp;Guardar
            </button>
          </div>
        </div>
        <Modal
          open={generalModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: '45%', height: '55%', overflow: 'auto' }}>
            <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
              className='btn btn-outline-dark btn-sm'
              onClick={handleCloseGeneral}
            >
              <i className='fa fa-x'></i>
            </button>
            <GeneralDataModal data={dataFinal.FormDatosGenerales} dataReturn={setDataFromPrincipalData} />
          </Box>
        </Modal>
        <Modal
          open={direccionModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: '95%', height: '80%', overflow: 'auto' }}>
            <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
              className='btn btn-outline-dark btn-sm'
              onClick={handleCloseDireccion}
            >
              <i className='fa fa-x'></i>
            </button>
            <DirectionData surcusalCode={surcusal} data={dataFinal.FormDirecciones} dataReturn={setDataFromPrincipalData} />
          </Box>
        </Modal>
        <Modal
          open={pagoModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: '35%', height: '65%', overflow: 'auto' }}>
            <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
              className='btn btn-outline-dark btn-sm'
              onClick={handleClosePago}
            >
              <i className='fa fa-x'></i>
            </button>
            <PaymentMethodsData data={dataFinal.FormCondicionesPago} dataReturn={setDataFromPrincipalData} sucursal={surcusal} />
          </Box>
        </Modal>
        <Modal
          open={propiedades}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: '45%', height: '95%', overflow: 'auto' }}>
            <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
              className='btn btn-outline-dark btn-sm'
              onClick={handleClosePropiedades}
            >
              <i className='fa fa-x'></i>
            </button>
            <PropiedadesModal data={dataFinal.FormPropiedades} dataReturn={setDataFromPrincipalData} />
          </Box>
        </Modal>
        <Modal
          open={vendedores}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: '45%', height: '85%', overflow: 'auto' }}>
            <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
              className='btn btn-outline-dark btn-sm'
              onClick={handleCloseVendedores}
            >
              <i className='fa fa-x'></i>
            </button>
            <VendedoresModal
              leadOrClients={!tipo}
              sucursal={surcusal}
              codigoClienteLead={dataFinal.FormPrincipal.codigo}
              data={dataFinal.FormVendedores}
              dataReturn={setDataFromPrincipalData}
              validData={dataFinal.FormDirecciones} />
          </Box>
        </Modal>
      </div>
      <ToastContainer />
    </div >
  );
}

export const PricipalData = ({ data, dataValidacion, dataReturn, tipo }) => {
  const [jsonFinal, setJsonFinal] = useState({
    codigo: data ? data?.codigo : null,
    nombre: data ? data?.nombre : null,
    categoria: data ? data?.categoria : null,
    numeroDocumento: data ? data?.numeroDocumento : null,
    apellidoPaterno: data ? data?.apellidoPaterno : null,
    apellidoMaterno: data ? data?.apellidoMaterno : null,
    primerNombre: data ? data?.primerNombre : null,
    segundoNombre: data ? data?.segundoNombre : null,
    tipoPersona: data ? data?.tipoPersona : null,
    tipoDocumento: data ? data?.tipoDocumento : null,
    abcategoriaClientes: data ? data?.abcategoriaClientes : null,
    multisede: data ? data?.multisede : false,
    activo: data ? data?.activo : null,
  });
  const [categorias, setSlc_conceptos] = useState([]);
  const [tipoDocumento, setSlc_tipoDocumento] = useState([]);

  const get_categorias = async () => {
    const url_con = `/apigetCategorias?vcodEmpresa=${cook_idEmp}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setSlc_conceptos(data)
  }

  const get_tipoDocumento = async () => {
    const url_con = `/apigetTipoDocumento?vcodEmpresa=${cook_idEmp}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setSlc_tipoDocumento(data)
  }

  useMemo(() => {
    if (categorias.length === 0) {
      get_categorias();
    }
    if (tipoDocumento.length === 0) {
      get_tipoDocumento();
    }
  }, [categorias.length, tipoDocumento.length]);

  const handleFieldChange = (fieldName) => (event) => {
    let value = event.target.value;
    setJsonFinal({
      ...jsonFinal,
      [fieldName]: value,
    });
  };

  const handleFieldChangeChecked = (fieldName) => (event) => {
    const value = event.target.checked;
    setJsonFinal({
      ...jsonFinal,
      [fieldName]: value,
    });
  };

  const handleButtonClick = () => {
    dataReturn('FormPrincipal', jsonFinal);
  }

  useEffect(() => {
    if (tipo === true) {
      setJsonFinal((prevJsonFinal) => {
        const newCodigo = `C${prevJsonFinal["numeroDocumento"] === undefined ? "" : prevJsonFinal["numeroDocumento"]}`;

        if (newCodigo !== prevJsonFinal["codigo"]) {
          return {
            ...prevJsonFinal,
            "codigo": newCodigo,
          };
        }

        return prevJsonFinal;
      });
    }
  }, [jsonFinal, tipo]);

  const contarActivosVerdaderos = (datos) => {
    let count = 0;
    for (var i = 0; i < datos.length; i++) {
      if (datos[i].activo) {
        count++;
      }
    }
    return count;
  }

  return (
    <>
      <div className='d-flex flex-column col-sm-12 col-md-12 m-0 p-0 align-middle' align='justify' >
        <div className="col-12 d-flex align-items-start justify-content-between">
          <div className='d-flex flex-column col-md-4'>
            <div className="col form-group">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Codigo:
                </span>
              </label>
              <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.codigo} onChange={handleFieldChange('codigo')} disabled />
            </div>
            <div className="col form-group">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Denomicación:
                </span>
              </label>
              <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.nombre} onChange={handleFieldChange('nombre')} disabled={jsonFinal.tipoPersona === "TPN" ? true : false} />
            </div>
            <div className="col form-group">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Canal/Grupo:
                </span>
              </label>
              <select className='form-select form-select-sm' value={jsonFinal.categoria} onChange={handleFieldChange('categoria')} >
                <option value="9999">--Seleccione--</option>
                {
                  categorias?.map((item, index) => {
                    return <option value={item?.Codigo} key={index} >{item?.Nombre}</option>
                  })
                }
              </select>
            </div>
            <div className="col form-group">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  N°. Documento:
                </span>
              </label>
              <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.numeroDocumento} onChange={handleFieldChange('numeroDocumento')} />
            </div>
          </div>
          <div className='d-flex flex-column col-md-6'>
            <div className='d-flex'>
              <div className="col form-group mx-1">
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Apellido Paterno:
                  </span>
                </label>
                <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.apellidoPaterno} onChange={handleFieldChange('apellidoPaterno')} disabled={jsonFinal.tipoPersona === "TPJ" ? true : false} />
              </div>
              <div className="col form-group mx-1">
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Apellido Materno:
                  </span>
                </label>
                <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.apellidoMaterno} onChange={handleFieldChange('apellidoMaterno')} disabled={jsonFinal.tipoPersona === "TPJ" ? true : false} />
              </div>
            </div>
            <div className='d-flex'>
              <div className="col form-group mx-1">
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Primer Nombre:
                  </span>
                </label>
                <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.primerNombre} onChange={handleFieldChange('primerNombre')} disabled={jsonFinal.tipoPersona === "TPJ" ? true : false} />
              </div>
              <div className="col form-group mx-1">
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Segundo Nombre:
                  </span>
                </label>
                <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.segundoNombre} onChange={handleFieldChange('segundoNombre')} disabled={jsonFinal.tipoPersona === "TPJ" ? true : false} />
              </div>
            </div>
            <div className='d-flex'>
              <div className="col form-group mx-1">
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Tipo de persona:
                  </span>
                </label>
                <select id="slc_t_con" className="form-select form-select-sm" value={jsonFinal.tipoPersona} onChange={handleFieldChange('tipoPersona')}>
                  <option value={"9999"} >--Seleccione--</option>
                  <option value={"TPN"} >Natural</option>
                  <option value={"TPJ"} >Fiscal</option>
                </select>
              </div>
              <div className="col form-group mx-1">
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Tipo de documento:
                  </span>
                </label>
                <select id="slc_t_con" className="form-select form-select-sm" value={jsonFinal.tipoDocumento} onChange={handleFieldChange('tipoDocumento')} >
                  <option value={"9999"} >--Seleccione--</option>
                  {
                    tipoDocumento?.map((item, index) => {
                      return <option value={item?.Code} key={index} >{item?.Name}</option>
                    })
                  }
                </select>
              </div>
            </div>
            <div className='d-flex'>
              <div className="col form-group mx-1">
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    AB CATEGORIA DE CLIENTES:
                  </span>
                </label>
                <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.abcategoriaClientes} onChange={handleFieldChange('abcategoriaClientes')} />
              </div>
              <div className="col form-group mx-1">
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    MULTISEDE :
                  </span>
                </label>
                <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
                  <input className="form-check-input" type="checkbox"
                    checked={contarActivosVerdaderos(dataValidacion) < 2 ? false : true}
                    disabled
                    onChange={handleFieldChangeChecked('multisede')} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between mt-2'>
          <div className='d-flex mt-2'>
            <div className="small cont-weight-normal mx-1">
              Activo:
            </div>
            <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
              <input className="form-check-input" type="checkbox" checked={jsonFinal.activo} onChange={handleFieldChangeChecked('activo')} />
            </div>
          </div>
          <button
            className="btn btn-sm btn-danger rounded-pill active"
            onClick={(e) => handleButtonClick(e)}
          >
            Guardar datos
          </button>
        </div>
      </div>
    </>
  );
};

export const GeneralDataModal = ({ data, dataReturn }) => {
  const [jsonFinal, setJsonFinal] = useState({
    giroNegocio: data ? data.giroNegocio : null,
    telefono: data ? data.telefono : null,
    correo: data ? data.correo : null,
  });

  const [giroNegocioCombo, setSlc_giro_negocio] = useState([]);

  const get_giro_negocio = async () => {
    const url_con = `/apigetGiroNegocio?vcodEmpresa=${cook_idEmp}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setSlc_giro_negocio(data)
  }

  useMemo(() => {
    if (giroNegocioCombo.length === 0) {
      get_giro_negocio();
      return;
    }
  }, [giroNegocioCombo.length]);

  const handleFieldChange = (fieldName) => (event) => {
    let value = event.target.value;
    setJsonFinal({
      ...jsonFinal,
      [fieldName]: value,
    });
  };
  const handleButtonClick = () => {
    dataReturn('FormDatosGenerales', jsonFinal);
  }
  // const validateEmail = (email) => {
  //   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  //   return emailRegex.test(email);
  // };
  return (
    <>
      <div className='d-flex flex-column mt-2'>
        <label className="col-form-label m-0 p-0">
          <span className="small fs-6">
            Datos generales:
          </span>
        </label>
        <button
          className="btn btn-sm btn-danger rounded-pill active"
          onClick={(e) => handleButtonClick(e)}
        >
          Guardar datos
        </button>
        <hr className='m-0 p-0 mb-2' />
        <div className='d-flex flex-column'>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Giro de negocio:
              </span>
            </label>
            <select className='form-select form-select-sm' value={jsonFinal.giroNegocio} onChange={handleFieldChange('giroNegocio')} >
              <option value="9999">--Seleccione--</option>
              {
                giroNegocioCombo?.map((item, index) => {
                  return <option value={item?.codigo} key={index} >{item?.nombre}</option>
                })
              }
            </select>
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Telefono movil:
              </span>
            </label>
            <input type="number" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.telefono} onChange={handleFieldChange('telefono')} />
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Correo:
              </span>
            </label>
            <input type="email" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.correo} onChange={handleFieldChange('correo')} />
          </div>
        </div>
      </div>
    </>
  );
};

export const DirectionData = ({ surcusalCode, data, dataReturn }) => {
  const [dataTableVenta, setDataTableVenta] = useState(data ? data?.direccionVenta : []);
  const [dataTableReparto, setDataTableReparto] = useState(data ? data?.direccionReparto : []);
  const [openFirstModal, setOpenFirstModal] = useState(false);
  const [dataForDireccion, setDataForDireccion] = useState([]);
  const [fromTable, setFromTable] = useState([]);
  const [value, setValue] = useState(0);

  const onClickDelete = useCallback((etiqueta, index) => {
    console.log(etiqueta.AddressName);
    if (etiqueta.AddressName === "DIRECCION FISCAL") {
      const dataTableVentaArray = Array.isArray(dataTableVenta) ? dataTableVenta : [dataTableVenta];
      let newData = [...dataTableVentaArray];
      newData = newData.filter((res) => res.iddireccion !== etiqueta.iddireccion);
      // newData.splice(index, 1);
      setDataTableVenta(newData);
    }
    if (etiqueta.AddressName === "DIRECCION ENTREGA") {
      console.log(etiqueta);
      const dataTableRepartoArray = Array.isArray(dataTableReparto) ? dataTableReparto : [dataTableReparto];
      let newData = [...dataTableRepartoArray];
      newData = newData.filter((res) => {
        console.log(res?.iddireccion);
        console.log(etiqueta?.iddireccion);
        console.log(res?.iddireccion !== etiqueta?.iddireccion);
        return res?.iddireccion !== etiqueta?.iddireccion
      });
      console.log(newData);

      // newData.splice(index, 1);
      setDataTableReparto(newData);
    }
  }, [dataTableReparto, dataTableVenta])

  const onClickCopy = useCallback((row, index) => {
    setDataForDireccion(row);
    setFromTable("COPY");
    handleOpen();
  }, [])

  const columns = useMemo(
    () => [
      {
        cell: (row, index) => <>
          {/* <>
            <div>
              <p>{row.not_delete}</p>
            </div>
          </> */}
          {!row.not_delete ? <AButton tp_tilde="Eliminar" tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger mx-1" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1}
            bt_funct={() => onClickDelete(row, index)}
          /> : <></>}

          {
            <>
              <AButton tp_tilde="Copiar" tp_posit="right" bt_class="btn btn-sm btn-icon btn-label-warning mx-1" ic_class="bx bx-ms bx-copy" vl_tpbtn={1}
                bt_funct={() => onClickCopy(row, index)}
              />
            </>
            // row.AddressName === "DIRECCION ENTREGA" ?  : <></>
          }
        </>,
        allowOverflow: true,
        button: true,
        width: '56px',
      },
      {
        cell: (row, index) => <>
          {
            <>
              <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
                <input type="checkbox" className='form-check-input' autoComplete='off' checked={row.U_AB_SNDA} />
              </div>
            </>
          }
        </>,
        name: 'Activo',
      },
      {
        selector: row => row.calle,
        name: 'Calle',
      },
      {
        selector: row => row.ubigeo,
        name: 'Ubigeo',
      },
      {
        selector: row => row.provincia,
        name: 'Provincia',
      },
      {
        selector: row => row.departamento,
        name: 'Departamento',
      },
      {
        selector: row => row.pais,
        name: 'Pais',
      },
      {
        selector: row => row.distrito,
        name: 'Distrito',
      },
      {
        selector: row => row.latitud,
        name: 'Latitud',
      },
      {
        selector: row => row.longitud,
        name: 'Longitud',
      },
      {
        selector: row => row.codigoZona,
        name: 'Codigo de zona',
      },
      {
        selector: row => row.codigoRuta,
        name: 'Codigo ruta',
      },
    ],
    [onClickCopy, onClickDelete],
  );
  const handleRowClicked = (ID, row) => {
    setDataForDireccion(row);
    setFromTable(ID);
    handleOpen();
  }

  const handleOpen = (e) => {
    setOpenFirstModal(true);
  };

  const handleClose = () => {
    setDataForDireccion([]);
    setFromTable([]);
    setOpenFirstModal(false);
  };
  const setData = (data) => {
    if (data.id === 'aqui1') {
      let newData = [];
      if (dataTableVenta?.length) {
        newData = [...dataTableVenta];
      }
      let index = newData.findIndex((data1) => {
        console.log(data1.iddireccion);
        console.log(data.data.iddireccion);
        return data1.iddireccion === data.data.iddireccion
      })
      if (index !== -1) {
        const data1 = data.data;
        newData[index] = data1;
        console.log(newData[index]);
        setDataTableVenta([...newData]);
        return handleClose();
      }
      if (newData.length > 0) {
        return notify_warning("Ya existe una direccion de venta registrada");
      }
      data.data["not_delete"] = false;
      data.data["iddireccion"] = newData.length + 1;
      data.data["AddressType"] = "bo_BillTo";
      newData.push(data.data);
      setDataTableVenta(newData);
    }
    if (data.id === 'aqui2') {
      let newData = [];
      if (dataTableReparto?.length) {
        newData = [...dataTableReparto];
      }
      let index = newData.findIndex((data1) => {
        return data1.iddireccion === data.data.iddireccion;
      })
      if (index !== -1) {
        newData[index] = data.data;
        setDataTableReparto(newData);
      } else {
        data.data["not_delete"] = false;
        data.data["iddireccion"] = newData.length + 1;
        data.data["AddressType"] = "bo_ShipTo";
        newData.push(data.data);
        setDataTableReparto(newData);
      }
    }
    handleClose();
  }

  const handleButtonClick = () => {
    dataReturn('FormDirecciones', {
      direccionVenta: dataTableVenta,
      direccionReparto: dataTableReparto,
    });
  }

  createThemeS('solarized', {
    text: {
      primary: globalTheme === 'light'
        ? '#2B2C40'
        : '#fefff4',
      secondary: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
    },
    background: {
      default: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
    },
    context: {
      background: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
      text: '#FFFFFF',
    },
  });

  const generateNewDirecction = () => {
    handleOpen();
    setFromTable("general");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className='d-flex m-2'>
        <label className="col-form-label m-0 p-0">
          <span className="small fs-6">
            Direcciones:
          </span>
        </label>
        <button
          className="btn btn-sm btn-danger rounded-pill active"
          onClick={(e) => handleButtonClick(e)}
        >
          Guardar datos
        </button>
      </div>
      <hr className='m-0 p-0 mb-2' />
      <div className="d-flex align-items-start">
        <div className="nav flex-column nav-pills me-3 col-md-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <div className='d-flex flex-column'>
            <Tabs value={value} onChange={handleChange} orientation="vertical" >
              <Tab
                style={{
                  fontSize: '14px',
                  color: value === 0 ? 'red' : 'inherit',
                  backgroundColor: value === 0 ? 'lightgrey' : 'inherit',
                  '&:hover': {
                    backgroundColor: value === 0 ? 'lightcoral' : 'inherit',
                  },
                }}
                label="Direccion Fiscal" {...a11yProps(0)} />
              <Tab
                style={{
                  fontSize: '14px',
                  color: value === 1 ? 'red' : 'inherit',
                  backgroundColor: value === 1 ? 'lightgrey' : 'inherit',
                  '&:hover': {
                    backgroundColor: value === 1 ? 'lightcoral' : 'inherit',
                  },
                }}
                label="Direccion Reparto" {...a11yProps(1)} />
            </Tabs>
          </div>
        </div>
        <div className="tab-content col-md-10 border" id="v-pills-tabContent" style={{
          'overflowY': 'hidden',
          'overflowX': 'scroll',
        }}>
          <CustomTabPanel value={value} index={0}>
            <button className="rounded-pill btn-danger btn btn-sm active" onClick={() => generateNewDirecction("fromVenta")}>Nueva direccion</button>
            <DataTable
              columns={columns}
              data={dataTableVenta}
              className="tabla-resumen"
              Header
              defaultSortField="id"
              defaultSortAsc={true}
              pagination={true}
              responsive
              highlightOnHover
              theme="solarized"
              noContextMenu="No hay registros para mostrar"
              onRowClicked={(row) => handleRowClicked('aqui1', row)}
              direction="auto"
              fixedHeader
              fixedHeaderScrollHeight="300px"
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <button className="rounded-pill btn-danger btn btn-sm active" onClick={() => generateNewDirecction("fromReparto")}>Nueva direccion</button>
            <DataTable
              columns={columns}
              data={dataTableReparto}
              className="tabla-resumen"
              Header
              defaultSortField="id"
              defaultSortAsc={true}
              pagination={true}
              responsive
              highlightOnHover
              theme="solarized"
              noContextMenu="No hay registros para mostrar"
              onRowClicked={(row) => handleRowClicked('aqui2', row)}
              direction="auto"
              fixedHeader
              fixedHeaderScrollHeight="300px"
            />
          </CustomTabPanel>
        </div >
      </div >
      <Modal
        open={openFirstModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '50%', height: '90%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={handleClose}
          >
            <i className='fa fa-x'></i>
          </button>
          <ModalDataInfoDirection sucursal={surcusalCode} fromTable={fromTable} data={dataForDireccion} dataReturn={setData} />
        </Box>
      </Modal>
    </>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ModalDataInfoDirection = ({ sucursal, fromTable, data, dataReturn }) => {
  const [jsonFinal, setJsonFinal] = useState({
    not_delete: data.not_delete,
    U_AB_SNDA: data.U_AB_SNDA,
    iddireccion: data.iddireccion,
    calle: data.calle,
    ubigeo: data.ubigeo,
    provincia: data.provincia,
    departamento: data.departamento,
    pais: data.pais ? data.pais : "PE",
    distrito: data.distrito,
    latitud: data.latitud,
    longitud: data.longitud,
    codigoZona: data.codigoZona,
    codigoRuta: data.codigoRuta,
    AddressName: data.AddressName ? data.AddressName : "DIRECCION FISCAL",
    Block: data.Block,
    State: data.State,
    FederalTaxID: data.FederalTaxID,
    TaxCode: data.TaxCode,
    BuildingFloorRoom: data.BuildingFloorRoom,
    AddressType: data.AddressType,
    AddressName2: data.AddressName2,
    AddressName3: data.AddressName3,
    TypeOfAddress: data.TypeOfAddress,
    StreetNo: data.StreetNo,
    BPCode: data.BPCode,
    RowNum: data.RowNum,
    GlobalLocationNumber: data.GlobalLocationNumber,
    Nationality: data.Nationality,
    TaxOffice: data.TaxOffice,
    GSTIN: data.GSTIN,
    GstType: data.GstType,
    CreateDate: data.CreateDate,
    CreateTime: data.CreateTime,
    MYFType: data.MYFType,
    TaasEnabled: data.TaasEnabled,
    U_AB_REF: data.U_AB_REF,
    U_AB_TIPOVIA: data.U_AB_TIPOVIA,
    U_AB_NOMVIA: data.U_AB_NOMVIA,
    U_AB_TIPOHAB: data.U_AB_TIPOHAB,
    U_AB_NOMHAB: data.U_AB_NOMHAB,
    U_AB_INTMZL: data.U_AB_INTMZL,
    U_AB_VHINI: data.U_AB_VHINI,
    U_AB_VHFIN: data.U_AB_VHFIN,
    U_AB_SECUENCIA: data.U_AB_SECUENCIA,
    U_MSSM_FIV: data.U_MSSM_FIV,
    U_MSS_DVLU: data.U_MSS_DVLU,
    U_MSS_DVMA: data.U_MSS_DVMA,
    U_MSS_DVMI: data.U_MSS_DVMI,
    U_MSS_DVJU: data.U_MSS_DVJU,
    U_MSS_DVVI: data.U_MSS_DVVI,
    U_MSS_DVSA: data.U_MSS_DVSA,
    U_MSS_FREC: data.U_MSS_FREC,
    U_MSS_CANA: data.U_MSS_CANA,
    U_MSS_GIRO: data.U_MSS_GIRO,
    U_MSS_DVDO: data.U_MSS_DVDO,
    U_MSS_COVE: data.U_MSS_COVE,
  });
  const [rutas, setRutas] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [pais, setPais] = useState([]);
  const [departamento, setDepartamento] = useState([]);
  const [departamentoInmutable, setDepartamentoInmutable] = useState([]);
  const [provincia, setProvincia] = useState([]);
  const [provinciaInmutable, setProvinciaInmutable] = useState([]);
  const [distrito, setDistrito] = useState([]);
  const [distritoInmutable, setDistritoInmutable] = useState([]);
  const [modalMaps, setModalMap] = useState(false);

  const [emp_sl_t_suc, emp_setSl_t_suc] = useState(sucursal);
  const emp_changeSl_t_suc = (event) => {
    const sucursal = event.target.value;
    emp_setSl_t_suc(sucursal);
    console.log(sucursal);
    getRutas(sucursal);
  }

  const handleCloseModalMaps = () => {
    setModalMap(false);
  }

  useEffect(() => {
    if (fromTable === "COPY") {
      jsonFinal["AddressName"] = "";
      jsonFinal["iddireccion"] = "";
      return;
    }
  }, []);

  const newMarcker = (data) => {
    setJsonFinal({
      ...jsonFinal,
      'latitud': data.lat,
      'longitud': data.lng,
    });
  };

  const handleFieldChange = (fieldName) => (event) => {
    let value = event.target.value;
    setJsonFinal({
      ...jsonFinal,
      [fieldName]: value,
    });
  };

  const handleFieldChangeNotHtml = (fieldName, html) => {
    if (fieldName === 'distrito') {
      const { Codigo } = distrito.find(res => res.Descripcion === html);
      setJsonFinal({
        ...jsonFinal,
        [fieldName]: html,
        'ubigeo': Codigo
      });
      return;
    }
    setJsonFinal({
      ...jsonFinal,
      [fieldName]: html,
    });
  };

  const handleButtonClickCreate = () => {
    if (jsonFinal.AddressName === "9999") {
      return notify_warning("Debes seleccionar un tipo de direccion")
    }
    if (validacionesForm()) {
      return;
    }
    let data = "";
    if (jsonFinal.AddressName === "DIRECCION FISCAL") {
      data = "aqui1";
    }
    if (jsonFinal.AddressName === "DIRECCION ENTREGA") {
      data = "aqui2";
    }
    dataReturn({
      isCopy: fromTable,
      id: data,
      data: jsonFinal
    });
  }

  const handleButtonClick = () => {
    if (validacionesForm()) {
      return;
    }
    console.log(jsonFinal);
    dataReturn({ id: fromTable, data: jsonFinal });
  }

  const validacionesForm = () => {
    if (jsonFinal?.calle?.trim() === "") {
      return notify_warning("La calle no debe de estar vacia");
    }
    if (jsonFinal?.ubigeo?.trim() === "") {
      return notify_warning("El ubigeo es requerido");
    }
    if (!jsonFinal?.departamento || jsonFinal.departamento === "9999") {
      return notify_warning("La departamento es requerido");
    }
    if (!jsonFinal?.provincia || jsonFinal.provincia === "9999") {
      return notify_warning("La provincia es requerida");
    }
    if (!jsonFinal?.distrito || jsonFinal.distrito === "9999") {
      return notify_warning("El distrito es requerido");
    }
    // if (!jsonFinal?.codigoRuta) {
    //   return notify_warning("Es necesario asignar una ruta");
    // }
    return false;
  }

  const handleFieldChangeCombox = (fieldName) => (event) => {
    let valueString = event.target.value;
    handleFieldChangeNotHtml(fieldName, valueString);
  }

  const funDZonas = useCallback(async (inputValue) => {
    let filter = zonas.filter((response) => {
      const Codigo = response.Code.toString();
      const Descripcion = response.Name.toString().toUpperCase();
      return Codigo.includes(inputValue.toUpperCase()) || Descripcion.includes(inputValue.toUpperCase());
    });
    return filter;
  }, [zonas]);

  const funDRutas = useCallback(async (inputValue) => {
    console.log(emp_sl_t_suc, rutas.length);
    const url_con = `/actualizarRuta?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}&inputValue=${inputValue}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const filter = await response.json();
    return filter;
  }, [emp_sl_t_suc, rutas]);

  const [slcRutas, setSlcRutas] = useState([""]);

  const handleSelectChangeRutas = async (selectedOption) => {
    console.log(selectedOption);
    const rutas = await getRutas(emp_sl_t_suc);
    setRutas(rutas);
    const url_con = `/getzonabyroutecode?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}&ruta=${selectedOption.Code}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setJsonFinal({
      ...jsonFinal,
      'codigoRuta': selectedOption.Code,
      'codigoZona': data.Codigo,
    });
  };

  const getRutas = useCallback(async (sic) => {
    console.log("ejecuto");
    const url_con = `/apigetRuta?vcodEmpresa=${cook_idEmp}&vcodSucursal=${sic}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    return data;
  }, []);
  const getPais = async () => {
    const url_con = `/apigetPais?vcodEmpresa=${cook_idEmp}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    return data;
  }
  const getZonas = async () => {
    const url_con = `/apigetZonas?vcodEmpresa=${cook_idEmp}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    return data;
  }
  const getDepartamento = async () => {
    const url_con = `/apigetDepartamento?vcodEmpresa=${cook_idEmp}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    return data;
  }
  const getProvincia = async () => {
    const url_con = `/apigetProvincia?vcodEmpresa=${cook_idEmp}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    return data;
  }
  const getDistrito = async () => {
    const url_con = `/apigetDistrito?vcodEmpresa=${cook_idEmp}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    return data;
  }

  const [onlyOneExec, setonlyOneExec] = useState({
    zonas: true,
    rutas: true,
    pais: true,
    departamento: true,
    provincia: true,
    distrito: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!zonas.length && onlyOneExec.zonas) {
        setonlyOneExec({ ...onlyOneExec, zonas: false });
        const data = await getZonas();
        setZonas(data);
        return;
      }
      if (!rutas.length && onlyOneExec.rutas) {
        setonlyOneExec({ ...onlyOneExec, rutas: false });
        const data = await getRutas(emp_sl_t_suc);
        setRutas(data);
        return;
      }
      if (!pais.length && onlyOneExec.pais) {
        setonlyOneExec({ ...onlyOneExec, pais: false });
        const data = await getPais();
        return setPais(data);
      }
      if (!departamentoInmutable.length && onlyOneExec.departamento) {
        setonlyOneExec({ ...onlyOneExec, departamento: false });
        const data = await getDepartamento();
        return setDepartamentoInmutable(data);
      }
      if (!provinciaInmutable.length && onlyOneExec.provincia) {
        setonlyOneExec({ ...onlyOneExec, provincia: false });
        const data = await getProvincia();
        return setProvinciaInmutable(data);
      }
      if (!distritoInmutable.length && onlyOneExec.distrito) {
        setonlyOneExec({ ...onlyOneExec, distrito: false });
        const data = await getDistrito();
        return setDistritoInmutable(data);
      }
    };
    const settingDataDepartment = () => {
      if (jsonFinal.pais) {
        const dataDepartment = departamentoInmutable.filter((response) => response.Pais === jsonFinal.pais);
        return setDepartamento(dataDepartment);

      }
      if (jsonFinal.departamento) {
        const dataProvincia = provinciaInmutable.filter((response) => response.Departamento === jsonFinal.departamento);
        return setProvincia(dataProvincia);
      }
    }
    const settingDataProvince = () => {
      if (jsonFinal.departamento) {
        const dataProvincia = provinciaInmutable.filter((response) => response.Departamento === jsonFinal.departamento);
        return setProvincia(dataProvincia);
      }
    }
    const settingDataDistrict = () => {
      if (jsonFinal.provincia) {
        const dataProvincia = provinciaInmutable.find(response => response.Descripcion === jsonFinal.provincia);
        const dataDistrito = distritoInmutable.filter((response) => response.Provincia === dataProvincia?.Codigo);
        return setDistrito(dataDistrito);
      }
    }
    fetchData();
    settingDataDepartment();
    settingDataProvince();
    settingDataDistrict();
  }, [departamentoInmutable, distritoInmutable, emp_sl_t_suc, getRutas, jsonFinal.departamento, jsonFinal.pais, jsonFinal.provincia, onlyOneExec, pais.length, provinciaInmutable, rutas.length, zonas.length]);

  const viewMap = () => {
    setModalMap(true);
  }
  const handleFieldChangeChecked = (fieldName) => (event) => {
    const value = event.target.checked;
    setJsonFinal({
      ...jsonFinal,
      [fieldName]: value,
    });
  };

  return (
    <>
      <div className='d-flex flex-column'>
        <div className='d-flex'>
          <label className="col col-form-label m-0 p-0">
            <span className="small fs-6">
              Direcciones
            </span>
          </label>
          {
            fromTable === "general" || fromTable === "COPY" ? <><label className="col-form-label m-0 p-0">
              <select className='form-select form-select-sm' value={jsonFinal.AddressName} onChange={handleFieldChangeCombox('AddressName')} >
                <option value="9999">--Seleccione tipo de direccion--</option>
                <option value="DIRECCION FISCAL">Direccion fiscal</option>
                <option value="DIRECCION ENTREGA">Direccion de reparto</option>
              </select>
            </label></> : <></>
          }
          {
            fromTable === "general" ? <>
              <button
                className="btn btn-sm btn-danger rounded-pill active"
                onClick={(e) => handleButtonClickCreate(e)}
              >
                Crear
              </button>
            </> :
              fromTable === "COPY" ? <>
                <button
                  className="btn btn-sm btn-danger rounded-pill active"
                  onClick={(e) => handleButtonClickCreate(e)}
                >
                  Copiar
                </button>
              </> : <>
                <button
                  className="btn btn-sm btn-danger rounded-pill active"
                  onClick={(e) => handleButtonClick(e)}
                >
                  Guardar
                </button>
              </>
          }
        </div>

        <hr className='m-0 p-0 mb-2' />
        <div className='d-flex flex-column'>
          <div className='d-flex'>
            <div className="col form-group mx-1">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Calle:
                </span>
              </label>
              <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.calle} onChange={handleFieldChange('calle')} />
            </div>
            <div className="col form-group mx-1">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Ubigeo:
                </span>
              </label>
              <input type="number" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.ubigeo} onChange={handleFieldChange('ubigeo')} />
            </div>
            <div className="col-1 form-group mx-1">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Activo:
                </span>
              </label>
              <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
                <input type="checkbox" className='form-check-input' autoComplete='off' checked={jsonFinal.U_AB_SNDA} onChange={handleFieldChangeChecked('U_AB_SNDA')} disabled={jsonFinal.AddressName === "DIRECCION FISCAL"} />
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='col form-group mx-1'>
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Pais:
                </span>
              </label>
              <select className='form-select form-select-sm' value={jsonFinal.pais} onChange={handleFieldChangeCombox('pais')} >
                <option value="9999">--Seleccione--</option>
                {
                  pais.map((item, index) => {
                    return <option value={item?.Codigo} key={index} >{item?.Nombre}</option>
                  })
                }
              </select>
            </div>
            <div className='col form-group mx-1'>
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Departamento:
                </span>
              </label>
              {
                <select className='form-select form-select-sm' value={jsonFinal.departamento} onChange={handleFieldChangeCombox('departamento')}
                >
                  <option value="9999">--Seleccione--</option>
                  {
                    departamento?.map((item, index) => {
                      return <option value={item?.Codigo} key={index} >{item?.Nombre}</option>
                    })
                  }
                </select>
              }
            </div>
            <div className="col form-group mx-1">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Provincia:
                </span>
              </label>
              <select className='form-select form-select-sm' value={jsonFinal.provincia} onChange={handleFieldChangeCombox('provincia')}>
                <option value="9999">--Seleccione--</option>
                {
                  provincia?.map((item, index) => {
                    return <option value={item?.Descripcion} key={index} >{item?.Descripcion}</option>
                  })
                }
              </select>
            </div>
            <div className="col form-group mx-1">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Distrito:
                </span>
              </label>
              <select className='form-select form-select-sm' value={jsonFinal.distrito} onChange={handleFieldChangeCombox('distrito')}>
                <option value="9999">--Seleccione--</option>
                {
                  distrito?.map((item, index) => {
                    return <option value={item?.Descripcion} key={index} >{item?.Descripcion}</option>
                  })
                }
              </select>
            </div>
          </div>
          <hr />
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Sucursal:
              </span>
            </label>
            <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
          </div>
          <div className='d-flex'>
            <div className="col form-group mx-1">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Ruta y Zona:
                </span>
              </label>
              <div className='d-flex'>
                <AsyncSelect
                  styles={styles}
                  placeholder="Buscar ruta."
                  name="form-field-name"
                  className="form-control form-control-sm border-0"
                  value={slcRutas}
                  getOptionLabel={e => e.Code + ' - ' + e.Name}
                  getOptionValue={e => e.Code}
                  loadOptions={(e) => funDRutas(e, emp_sl_t_suc)}
                  onChange={handleSelectChangeRutas}
                  defaultOptions
                />
              </div>
              <div className='d-flex'>
                <select className='form-control form-control-sm' value={jsonFinal.codigoRuta} onChange={handleFieldChangeCombox('codigoRuta')} disabled>
                  <option value="9999">--Seleccione--</option>
                  {
                    rutas?.map((item, index) => {
                      return <option value={item?.Code} key={index} >{item?.Name}</option>
                    })
                  }
                </select>
                <select className='form-control form-control-sm' value={jsonFinal.codigoZona} onChange={handleFieldChangeCombox('codigoZona')} disabled>
                  <option value="9999">--Seleccione--</option>
                  {
                    zonas?.map((item, index) => {
                      return <option value={item?.Code} key={index} >{item?.Name}</option>
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          {/* <div className='d-flex'>
            <div className="col form-group mx-1">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Codigo de ruta:
                </span>
              </label>
              
            </div>
          </div> */}
          <hr />
          <div className='d-flex align-middle'>
            <div className='col-9'>
              <div className="col form-group mx-1">
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Latitud:
                  </span>
                </label>
                <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.latitud} onChange={handleFieldChange('latitud')} />
              </div>
              <div className='col form-group mx-1'>
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Longitud:
                  </span>
                </label>
                <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.longitud} onChange={handleFieldChange('longitud')} />
              </div>
            </div>
            <div className='col-3 text-center align-self-center' >
              <button className='btn btn-sm btn-danger rounded-pill active' onClick={viewMap}>Ver en mapa</button>
            </div>
          </div>
          {/* {
            <Gmaps currentLocation={{ lat: jsonFinal.latitud, lng: jsonFinal.longitud }} localName={jsonFinal.calle} onCoordinatesSelected={newMarcker} />
          } */}
        </div>
      </div >
      <Modal
        open={modalMaps}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '75%', height: '89%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={handleCloseModalMaps}
          >
            <i className='fa fa-x'></i>
          </button>
          {
            <Gmaps currentLocation={{ lat: jsonFinal.latitud, lng: jsonFinal.longitud }} localName={jsonFinal.calle} onCoordinatesSelected={newMarcker} />
          }
        </Box>
      </Modal>
    </>
  );
};
export const PaymentMethodsData = ({ data, dataReturn, sucursal }) => {
  const [jsonFinal, setJsonFinal] = useState(
    {
      condicionesPago: data ? data.condicionesPago : -1,
      precios: data ? data.precios : null,
      limiteCredito: data ? data.limiteCredito : null,
    }
  );

  const [condicionesPagoCombo, setSlc_condiciones_pago] = useState([]);
  const [listaPagoCombo, setSlc_pagoCombo] = useState([]);

  const get_giro_negocio = async () => {
    const url_con = `/apigetCondicionesPago?vcodEmpresa=${cook_idEmp}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setSlc_condiciones_pago(data)
  }

  const get_lista_pago = useCallback(async () => {
    const url_con = `/apigetListPrecio?vcodEmpresa=${cook_idEmp}&sucursal=${sucursal}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setSlc_pagoCombo(data)
    setJsonFinal({
      ...jsonFinal,
      "condicionesPago": -1,
    });
  }, [jsonFinal, sucursal])

  useMemo(() => {
    if (condicionesPagoCombo.length === 0) {
      get_giro_negocio();
    }
    if (listaPagoCombo.length === 0) {
      get_lista_pago();
    }
  }, [condicionesPagoCombo.length, get_lista_pago, listaPagoCombo.length]);

  const handleFieldChange = (fieldName) => (event) => {
    let value = event.target.value;
    setJsonFinal({
      ...jsonFinal,
      [fieldName]: value,
    });
  };
  const handleButtonClick = () => {
    dataReturn('FormCondicionesPago', jsonFinal);
  }
  return (
    <>
      <div className='d-flex flex-column'>
        <label className="col-form-label m-0 p-0">
          <span className="small fs-6">
            Condiciones de pago
          </span>
        </label>
        <button
          className="btn btn-sm btn-danger rounded-pill active"
          onClick={(e) => handleButtonClick(e)}
        >
          Guardar
        </button>
        <hr />
        <div className='d-flex flex-column'>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Condiciones de pago:
              </span>
            </label>
            <select className='form-select form-select-sm' value={jsonFinal.condicionesPago} onChange={handleFieldChange('condicionesPago')}>
              {/* <option value="9999">--Seleccione--</option> */}
              {
                condicionesPagoCombo?.map((item, index) => {
                  return <option value={item.codigo} key={index} >{item?.nombre}</option>
                })
              }
            </select>
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Lista Precios:
              </span>
            </label>
            <select className='form-select form-select-sm' value={jsonFinal.precios} onChange={handleFieldChange('precios')}>
              <option value="">--Seleccione--</option>
              {
                listaPagoCombo?.map((item, index) => {
                  return <option value={item?.Codigo} key={index} >{item?.Nombre}</option>
                })
              }
            </select>
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Limite de credito:
              </span>
            </label>
            <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.limiteCredito} onChange={handleFieldChange('limiteCredito')} />
          </div>
        </div>
      </div>
    </>
  );
}
const CustomMarker = ({ text = null, color, onClick }) => {
  return (
    <div
      className="pin"
      onClick={onClick}
    >
      <Icon icon={locationIcon} style={{
        color: color || 'red',
        cursor: 'pointer',
        fontSize: '3em'
      }}
      />
    </div>
  );
};
export const Gmaps = ({ currentLocation, localName, onCoordinatesSelected }) => {
  const apikey = process.env.REACT_APP_APIKEY;
  const [userLocation, setUserLocation] = useState({ lat: null, lng: null });
  const [mapRendered, setMapRendered] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [map, setMap] = useState(null);
  const [mapZoom] = useState(12);
  const [currentLoc, setCurrentLoc] = useState({ lat: null, lng: null });
  const [newLocation, setNewLocation] = useState(false);

  useEffect(() => {
    const getUserLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          setCurrentLoc({ lat: currentLocation.lat, lng: currentLocation.lng });

          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
      } else {
        console.error("Geolocalización no es compatible en este navegador.");
      }
    };
    const startGettingLocation = () => {
      getUserLocation();
    };

    const checkAndRenderMap = () => {
      if (userLocation.lat != null && userLocation.lng != null
        && currentLoc.lat != null && currentLoc.lng !== null
        && !mapRendered) {
        const clickedLocation = {
          lat: currentLoc.lat,
          lng: currentLoc.lng,
        };
        const newMarkers = [...markers, clickedLocation];
        setMarkers(newMarkers);
        setMapRendered(true);
      } else {
        setTimeout(checkAndRenderMap, 1000);
      }
    };
    startGettingLocation();
    checkAndRenderMap();
  }
  );

  const handleMapClick = (event) => {
    const clickedLocation = {
      lat: event.lat,
      lng: event.lng,
    };

    const newMarkers = [...markers, clickedLocation];
    if (newLocation) {
      setMarkers(newMarkers);
      onCoordinatesSelected(clickedLocation);
      setNewLocation(false)
      return;
    }
  };

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: false
  };

  const centerMapOnUser = (event) => {
    event.preventDefault();

    console.log(apikey);
    if (map) {
      map.panTo({ lat: userLocation.lat, lng: userLocation.lng });
    }
  };
  const deleteMapMarker = (event) => {
    event.preventDefault();
    // if (markers.length !== 0) {

    // }
    setMarkers([...[]]);
    setNewLocation(true);
  };


  const renderMap = () => {
    return (
      <>
        <div className='col-12 d-flex flex-column justify-content-center'>
          <div className='my-3 col-12 d-flex justify-content-evenly'>
            <button className='btn btn-sm btn-danger rounded-pill active' onClick={centerMapOnUser}><Icon icon={locationIcon} />Mi Ubicación</button>
            <button className='btn btn-sm btn-danger rounded-pill active' onClick={deleteMapMarker}>Establecer nueva locación</button>
          </div>
          <div className='map'>
            <div className="google-map">
              <GoogleMapReact
                bootstrapURLKeys={{ key: apikey }}
                center={userLocation}
                options={mapOptions}
                yesIWantToUseGoogleMapApiInternals={true}
                onClick={handleMapClick}
                onGoogleApiLoaded={({ map }) => setMap(map)}
                defaultZoom={mapZoom}
              >
                <CustomMarker
                  lat={userLocation.lat}
                  lng={userLocation.lng}
                  color="red"
                />
                {
                  markers.map(
                    (marker, index) =>
                    (
                      index < 1 ?
                        <CustomMarker
                          key={index}
                          lat={marker.lat}
                          lng={marker.lng}
                          text={localName}
                          color="orange"
                        /> : <div></div>
                    )
                  )
                }
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </>
    );
  };

  return renderMap()
};
export const PropiedadesModal = ({ data, dataReturn }) => {
  // const [searchText, setSearchText] = useState('');
  const [dataTable, setDataTable] = useState(data ? data : []);
  // const [dataTable1, setData] = useState(data ? data : []);

  // const handleSearch = (e) => {
  //   const searchValue = e.target.value;
  //   setSearchText(searchValue);
  //   const filtered = dataTable.filter((item) => {
  //     const values = Object.values(item).map(String);
  //     return values.some((value) => value.toLowerCase().includes(searchValue.toLowerCase()));
  //   });
  //   setDataTable(filtered);
  // };
  const columns = [
    { name: 'Codigo', selector: row => row.codigo, cellExport: row => row.codigo, sortable: true },
    { name: 'Descripción', selector: row => row.descripcion, cellExport: row => row.nombre, sortable: true },
    {
      name: 'Activo',
      selector: row => row.activo,
      cellExport: row => row.activo,
      sortable: true,
      cell: (row, index) => (
        <div className='align-items-center form-check form-check-danger'>
          <input
            className="form-check-input"
            type="checkbox"
            checked={row.activo}
            onChange={() => handleCheckboxChange(row.codigo)}
          />
        </div>
      ),
    },
  ];

  const handleCheckboxChange = (index) => {
    setDataTable((prevData) =>
      prevData.map((row) =>
        row.codigo === index ? { ...row, activo: !row.activo } : row
      )
    );
    // setSearchText('');
  };

  const handleButtonClick = () => {
    if (validarActivos(dataTable)) {
      return notify_warning('Es necesario asignar minimo una propiedad');
    }
    dataReturn('FormPropiedades', dataTable);
  };

  const dataPropiedades = useMemo(() => {
    return {
      Properties1: 'PIURA',
      Properties2: 'TUMBES',
      Properties3: 'TRUJILLO',
      Properties4: 'CHIMBOTE',
      Properties5: 'CHICLAYO',
      Properties6: 'CAJAMARCA',
      Properties7: 'Socios de negocios propiedad 7',
      Properties8: 'HUAMACHUCO',
    };
  }, []);

  useEffect(() => {
    if (dataTable.length === 0) {
      const dataPush = [];
      for (let index = 1; index <= 8; index++) {
        const clave = `Properties${index}`;
        dataPush.push(
          {
            codigo: index,
            descripcion: dataPropiedades[clave],
            clave: clave,
            activo: false,
          },
        );
      }
      setDataTable(dataPush);
    }
  }, [dataPropiedades, dataTable.length]);

  createThemeS('solarized', {
    text: {
      primary: globalTheme === 'light'
        ? '#2B2C40'
        : '#fefff4',
      secondary: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
    },
    background: {
      default: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
    },
    context: {
      background: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
      text: '#FFFFFF',
    },
  });

  function validarActivos(datos) {
    for (var i = 0; i < datos.length; i++) {
      if (datos[i]["activo"]) {
        return false;
      }
    }
    return true;
  }

  return (
    <>
      <div className='d-flex flex-column'>
        <div className='d-flex'>
          <label className="col-form-label m-0 p-0">
            <span className="small fs-6">
              Propiedades
            </span>
          </label>
          <button
            className="btn btn-sm btn-danger rounded-pill active"
            onClick={(e) => handleButtonClick(e)}
          >
            Guardar
          </button>
        </div>
        <hr />
      </div>
      <div className='d-flex flex-column'>
        {/* <input
          type="text"
          className='form-control rounded-0 border-0'
          value={searchText}
          onChange={handleSearch}
          placeholder="Buscar"
        /> */}
        <DataTable
          columns={columns}
          data={dataTable}
          theme='solarized'
        />
      </div>
    </>
  );
};
// si es verdadero (leadOrClients) es clente 
export const VendedoresModal = ({ sucursal, leadOrClients, codigoClienteLead, data, dataReturn, validData }) => {
  const [dataTable, setDataTable] = useState(data ? data : []);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [dataForTable, setData] = useState([]);
  const [typeModal, setTypeModal] = useState(
    {
      setMultipleVendedor: false,
      viewDetailVendedor: false,
    }
  );
  const handleOpen = (e) => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const [onlyOne, setOnly] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const get_products = async () => {
    const url_con = `/getVendedoresTableSelect?vcodEmpresa=${cook_idEmp}&vcodSucursal=${sucursal}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setData(data)
  }
  useEffect(() => {
    const filtered = data.filter((row) => {
      const values = Object.values(row).map(String);
      return values.some((value) => value.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    );
    setDataTable(filtered);
  }, [data, searchTerm]);
  const handleButtonClick = () => {
    dataReturn('FormVendedores', dataTable);
  };

  useEffect(() => {
    if (!onlyOne) {
      get_products();
      setOnly(true);
      return;
    }
  }, [get_products, onlyOne]);
  const onClickDelete = (code) => {
    const newDataArray = [...dataTable];
    const index = newDataArray.findIndex((response) => response.codigo === code);
    newDataArray.splice(index, 1);
    setDataTable(newDataArray);
  }

  const onClickEdit = async (code) => {
    const newDataArray = [...dataTable];
    const index = newDataArray.find((response) => response.id === code);
    setSelectedData(index);
    setTypeModal({
      setMultipleVendedor: false,
      viewDetailVendedor: true,
    });
    handleOpen();
  };

  const TRPlantilllaCD = (dataRow) => {
    return (
      <tr key={dataRow.dataRow.codigo}>
        <td className='text-center'>
          <>
            {
              !dataRow.dataRow.not_delete ?
                <AButton tp_tilde="Eliminar" tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger mx-1" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} bt_funct={() => onClickDelete(dataRow.dataRow.codigo)} />
                : <></>
            }
          </>
          {
            leadOrClients ?
              <AButton tp_tilde="Detalle" tp_posit="right" bt_class="btn btn-sm btn-icon btn-label-warning mx-1" ic_class="fa-solid fa-pen-to-square fa-sm" vl_tpbtn={1} bt_funct={() => onClickEdit(dataRow.dataRow.id)} />
              : <></>
          }
        </td>
        <td className='text-center'>{dataRow.dataRow.id}</td>
        <td className='text-center'>{dataRow.dataRow.codigo}</td>
        <td></td>
      </tr >
    )
  }

  const onClickInsert = async () => {
    console.log("veh_sl_t_zona::", veh_sl_t_zona);

    if (!veh_sl_t_zona)
      return;
    const dataSetIn = [...dataTable];
    if (dataSetIn.findIndex(response => Number(response.id) === Number(veh_sl_t_zona.Code)) === -1) {
      dataSetIn.push({
        not_delete: false,
        id: veh_sl_t_zona.Code,
        codigo: veh_sl_t_zona.Name,
        cardCode: codigoClienteLead,
        fechaInicioVisita: null,
        posicion: '',
        direccion: '',
        telefono: '',
        correo: '',
        titulo: '',
        nombre: '',
        segundoNombre: '',
        apellidos: '',
        lunes: 'N',
        martes: 'N',
        miercoles: 'N',
        jueves: 'N',
        viernes: 'N',
        sabado: 'N',
        domingo: 'N',
        frecuencia: '',
        ruta: '',
        Phone2: '',
        MobilePhone: '',
        Fax: '',
        Pager: '',
        Remarks1: '',
        Remarks2: '',
        Password: '',
        InternalCode: null,
        PlaceOfBirth: null,
        DateOfBirth: '',
        Gender: 'gt_Undefined',
        Profession: '',
        CityOfBirth: null,
        Active: 'Y',
        EmailGroupCode: null,
        BlockSendingMarketingContent: 'tNO',
        CreateDate: getFormattedDateTime(new Date(), true),
        CreateTime: getFormattedDateTime(new Date(), false),
        UpdateDate: getFormattedDateTime(new Date(), true),
        UpdateTime: getFormattedDateTime(new Date(), false),
        ConnectedAddressName: null,
        ConnectedAddressType: null,
        ForeignCountry: '',
        U_AB_PCCA: '',
        U_AB_PCAC: 'N',
        // U_AB_PCGI: '',
        ContactEmployeeBlockSendingMarketingContents: [],
      });
    }
    dataSetIn.map((data, index) =>
      data.codigo = data.codigo.includes('|') ? data.codigo : `${data.codigo.split(/ | /)[0]}|${index + 1}`,
    );
    setDataTable(dataSetIn);
    setVehSl_t_zona(null);
  }

  function getFormattedDateTime(date, dateOrHours) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    if (dateOrHours) {
      return `${year}-${month}-${day}`;
    }
    return `${hour}:${minute}:${second}`;
  }

  const onClickInsertGroup = async () => {
    setTypeModal({
      setMultipleVendedor: true,
      viewDetailVendedor: false,
    });
    handleOpen();
  }

  const [veh_sl_t_zona, setVehSl_t_zona] = useState(null);

  const veh_Select_Zona = useCallback(async (inputValue) => {
    let arr_data = [];
    arr_data = dataForTable?.filter((elemento) => {
      const Code = elemento.Code.toString();
      const Name = elemento.Name.toString().toUpperCase();
      return Code.includes(inputValue.toUpperCase()) || Name.includes(inputValue.toUpperCase());
    });
    return arr_data;
  }, [dataForTable]);

  const setMultipleVendedor = (event) => {
    const dataSetIn = [...dataTable];
    for (const iterator of event) {
      if (dataSetIn.findIndex((response) => Number(response.id) === Number(iterator.Code)) === -1) {
        dataSetIn.push({
          id: iterator.Code,
          codigo: iterator.Name,
          fechaInicioVisita: null,
          posicion: '',
          direccion: '',
          telefono: '',
          correo: '',
          titulo: '',
          nombre: '',
          segundoNombre: '',
          apellidos: '',
          lunes: 'N',
          martes: 'N',
          miercoles: 'N',
          jueves: 'N',
          viernes: 'N',
          sabado: 'N',
          domingo: 'N',
          frecuencia: '',
          ruta: '',
          Phone2: '',
          MobilePhone: '',
          Fax: '',
          Pager: '',
          Remarks1: '',
          Remarks2: '',
          Password: '',
          InternalCode: null,
          PlaceOfBirth: null,
          DateOfBirth: '',
          Gender: 'gt_Undefined',
          Profession: '',
          CityOfBirth: null,
          Active: 'Y',
          EmailGroupCode: null,
          BlockSendingMarketingContent: 'tNO',
          CreateDate: getFormattedDateTime(new Date(), true),
          CreateTime: getFormattedDateTime(new Date(), false),
          UpdateDate: getFormattedDateTime(new Date(), true),
          UpdateTime: getFormattedDateTime(new Date(), false),
          ConnectedAddressName: null,
          ConnectedAddressType: null,
          ForeignCountry: '',
          U_AB_PCCA: '',
          // U_AB_PCGI: '',
          ContactEmployeeBlockSendingMarketingContents: [],
        });
      }
    }
    setDataTable(dataSetIn);
    handleClose();
  }

  const setModifiquedSaller = (event) => {
    let dataSetIn = [...dataTable];
    const index = dataSetIn.findIndex((response) => response.id.toString() === event.id.toString());
    dataSetIn[index] = event;
    notify_valido('Guardado');
    setDataTable(dataSetIn);
    handleClose();
  }

  const autoCompleteVendedor = async (event) => {
    const rutas = [];
    validData["direccionReparto"]?.forEach(element => {
      if (element["codigoRuta"]) {
        rutas.push(`'${element["codigoRuta"]}'`);
      }
    })
    if (!rutas.length) {
      notify_warning("Es necesario asignar rutas a las direcciones de reparto");
      return;
    }

    /** Api de vendedor de oficina */
    const bodyOfi = {
      "empresaId": cook_idEmp,
      "sucursal": sucursal
    };

    const url_ven_ofi = `/getVendedoresByOficina`;
    const opPe_ofi = {
      method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(bodyOfi)
    }

    const responseOfic = await fetch(url_ven_ofi, opPe_ofi);
    const vendedoresRutasOfic = await responseOfic.json();
    // console.log("vendedoresRutasOfic:: ", vendedoresRutasOfic);


    const body = {
      "empresaId": cook_idEmp,
      "codigos": rutas.join(',')
    };
    const url_con = `/getVendedoresByRuta`;
    const opPe = {
      method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body)
    }
    const response = await fetch(url_con, opPe);
    let vendedoresRutas = await response.json();
    // console.log("vendedoresRutas:", vendedoresRutas);


    if (Array.isArray(vendedoresRutasOfic)) {
      let newRuOficina = vendedoresRutasOfic.map(obj => {
        return {
          ...obj,
          U_AB_DVLU: 'Y',
          U_AB_DVMA: 'N',
          U_AB_DVMI: "N",
          U_AB_DVJU: "N",
          U_AB_DVVI: "N",
          U_AB_DVSA: "N",
          U_AB_DVDO: null,
          U_AB_DVSE: "1",
          U_AB_DVCR: ""
        };
      });
      if (Array.isArray(vendedoresRutas)) {
        newRuOficina.forEach(objOfic => {
          const existeVenRuta = vendedoresRutas.some(objRuta => objRuta.Code === objOfic.Code);
          if (!existeVenRuta) {
            vendedoresRutas.push(objOfic);
          }
        });
      } else {
        vendedoresRutas = newRuOficina;
      }
    }

    returnData(vendedoresRutas, dataTable);
  }

  const returnData = (vendedoresRutas, datosdetabla) => {
    if (!Array.isArray(vendedoresRutas)) {
      setDataTable(datosdetabla)
      return;
    }
    const dataSetIn = [...datosdetabla];
    const tamanioData = dataSetIn.length;
    dataSetIn.forEach((data) => {
      Object.assign(data, {
        U_AB_PCAC: false,
      });
    });
    for (let index = 0; index < vendedoresRutas.length; index++) {
      const iterator = vendedoresRutas[index];
      const indexx = dataSetIn.findIndex((response) => iterator?.Code?.toString() === response?.id?.toString())
      if (indexx === -1) {
        console.log("es nuevo");
        dataSetIn.push({
          not_delete: iterator.not_delete,
          id: iterator.Code,
          codigo: `${iterator.Name}`,
          fechaInicioVisita: null,
          posicion: '',
          direccion: '',
          telefono: '',
          correo: '',
          titulo: '',
          nombre: '',
          segundoNombre: '',
          apellidos: '',
          lunes: iterator.U_AB_DVLU === 'Y' ? true : false,
          martes: iterator.U_AB_DVMA === 'Y' ? true : false,
          miercoles: iterator.U_AB_DVMI === 'Y' ? true : false,
          jueves: iterator.U_AB_DVJU === 'Y' ? true : false,
          viernes: iterator.U_AB_DVVI === 'Y' ? true : false,
          sabado: iterator.U_AB_DVSA === 'Y' ? true : false,
          domingo: iterator.U_AB_DVDO === 'Y' ? true : false,
          frecuencia: iterator.U_AB_DVSE,
          ruta: iterator.U_AB_DVCR,
          Phone2: '',
          MobilePhone: '',
          Fax: '',
          Pager: '',
          Remarks1: '',
          Remarks2: '',
          Password: '',
          InternalCode: null,
          PlaceOfBirth: null,
          DateOfBirth: '',
          Gender: 'gt_Undefined',
          Profession: '',
          CityOfBirth: null,
          Active: 'Y',
          EmailGroupCode: null,
          BlockSendingMarketingContent: 'tNO',
          CreateDate: getFormattedDateTime(new Date(), true),
          CreateTime: getFormattedDateTime(new Date(), false),
          UpdateDate: getFormattedDateTime(new Date(), true),
          UpdateTime: getFormattedDateTime(new Date(), false),
          ConnectedAddressName: null,
          ConnectedAddressType: null,
          ForeignCountry: '',
          U_AB_PCCA: '',
          U_AB_PCAC: true,
          // U_AB_PCGI: '',
          ContactEmployeeBlockSendingMarketingContents: [],
        });
      } else {
        console.log("ya existe");
        dataSetIn[indexx] = {
          not_delete: true,
          id: iterator.Code,
          codigo: `${iterator.Name}`,
          fechaInicioVisita: null,
          posicion: '',
          direccion: '',
          telefono: '',
          correo: '',
          titulo: '',
          nombre: '',
          segundoNombre: '',
          apellidos: '',
          lunes: iterator.U_AB_DVLU === 'Y' ? true : false,
          martes: iterator.U_AB_DVMA === 'Y' ? true : false,
          miercoles: iterator.U_AB_DVMI === 'Y' ? true : false,
          jueves: iterator.U_AB_DVJU === 'Y' ? true : false,
          viernes: iterator.U_AB_DVVI === 'Y' ? true : false,
          sabado: iterator.U_AB_DVSA === 'Y' ? true : false,
          domingo: iterator.U_AB_DVDO === 'Y' ? true : false,
          frecuencia: iterator.U_AB_DVSE,
          ruta: iterator.U_AB_DVCR,
          Phone2: '',
          MobilePhone: '',
          Fax: '',
          Pager: '',
          Remarks1: '',
          Remarks2: '',
          Password: '',
          InternalCode: null,
          PlaceOfBirth: null,
          DateOfBirth: '',
          Gender: 'gt_Undefined',
          Profession: '',
          CityOfBirth: null,
          Active: 'Y',
          EmailGroupCode: null,
          BlockSendingMarketingContent: 'tNO',
          CreateDate: getFormattedDateTime(new Date(), true),
          CreateTime: getFormattedDateTime(new Date(), false),
          UpdateDate: getFormattedDateTime(new Date(), true),
          UpdateTime: getFormattedDateTime(new Date(), false),
          ConnectedAddressName: null,
          ConnectedAddressType: null,
          ForeignCountry: '',
          U_AB_PCCA: '',
          U_AB_PCAC: true,
          // U_AB_PCGI: '',
          ContactEmployeeBlockSendingMarketingContents: [],
        }
      }
    }

    if (tamanioData === dataSetIn.length) {
      notify_warning('Ya se han asignado todos los vendedores disponibles para esta ruta');
    }
    dataSetIn.map((data, index) =>
      data.codigo = data.codigo.includes('|') ? data.codigo : `${data.codigo.split(/ | /)[0]}|${index + 1}`,
    );
    // dataSetIn.map((data, index) => {
    //   // Check if the codigo has already been split (contains a '|')
    //   if (data.codigo.includes('|')) {
    //     // If it has been split, update it
    //     const parts = data.codigo.split('|');
    //     data.codigo = `${parts[0]}|${index + 1}`;
    //   }
    //   // If it hasn't been split, do nothing (or you can include any other logic if needed)
    //   return data;
    // });

    setDataTable(dataSetIn);
  }

  /**   
   */

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className='flex-fill'>
          <label className="col-form-label m-0 p-0">
            <span className="small fs-6">
              Vendedores
            </span>
          </label>

        </div>
        <div className='flex-fill'>
          <button
            className="btn btn-sm btn-danger rounded-pill active"
            onClick={(e) => autoCompleteVendedor(e)}
          >
            Autoasignar Vendedores
          </button>
        </div>
        <div className='flex-fill'>
          <button
            className="btn btn-sm btn-danger rounded-pill active"
            onClick={(e) => handleButtonClick(e)}
          >
            Guardar
          </button>
        </div>

        {/* <div className='d-flex border'>
          <button
            className="btn btn-sm col-3 btn-danger rounded-pill me-4 p-0 m-0 active mx-4"
            onClick={(e) => handleButtonClick(e)}
          >
            Guardar
          </button>
        </div> */}

        <hr />
      </div>
      <div className='d-flex flex-column'>
        <div key={"no_index"} className='d-flex justify-content-between'>
          <td className='td5 d-flex'>
            <>
              {/*
              <AButton tp_tilde="Agregar en grupo" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success mx-2"
                bt_funct={onClickInsertGroup}
                ic_class="bx bx-ms bx-group" vl_tpbtn={1} />
              */}
              <AButton tp_tilde="Agregarjj" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success mx-2"
                bt_funct={onClickInsert}
                ic_class="bx bx-ms bx-user" vl_tpbtn={1} />
            </>
          </td>
          <td className='td3' style={{ width: '350px' }}>{
            <>
              <AsyncSelect
                id="id_zona"
                styles={styles}
                placeholder="Busque por COD/DESC"
                name="form-field-name"
                className="sele_pru from-control form-control-sm my-swal"
                value={veh_sl_t_zona}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code + ' - ' + e.Name}
                getOptionValue={e => e.Code}
                loadOptions={veh_Select_Zona}
                onChange={setVehSl_t_zona}
              />
            </>
          }</td>
        </div>
        <input
          type="text"
          className='form-control form-control-sm rounded-0 border-0'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Buscar..."
        />
        <div className="d-flex mt-2" style={{ flexDirection: 'column', height: '350px', overflow: 'auto' }}>
          <table className="table table-sm" style={{ fontSize: '10px', zIndex: '2' }}>
            <thead style={{
              position: 'sticky', top: 0, zIndex: 10, color: 'black', backgroundColor: globalTheme === 'light' ? '#fffff' : '#2B2C40'
            }}>
              <tr>
                <th className=''>Acciones</th>
                <th className=''>IDCOD</th>
                <th className=''>Código Vendedor</th>
              </tr>
            </thead>
          </table>
          <div style={{ flex: '1', overflowY: 'auto' }}>
            <table className="table" style={{ fontSize: '10px', zIndex: '2', }}>
              <tbody>
                {
                  dataTable.length === 0 ? (
                    <tr>
                      <td colSpan="4">No hay datos</td>
                    </tr>
                  ) : (
                    dataTable.map((row, index) => (
                      <>
                        <TRPlantilllaCD key={index} dataRow={row} />
                      </>
                    ))
                  )
                }
              </tbody>
            </table>
          </div>
        </div >
      </div >
      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{
          ...style, width: typeModal.setMultipleVendedor ? '50%' : '60%',
          height: typeModal.setMultipleVendedor ? '75%' : '60%',
          overflow: 'auto'
        }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm my-swal'
            onClick={handleClose}
          >
            <i className='fa fa-x'></i>
          </button>
          {
            typeModal.setMultipleVendedor ? <AddProducts sucursal={sucursal} returnBusinessUnit={setMultipleVendedor} /> : <></>
          }
          {
            typeModal.viewDetailVendedor ? <VisitSaller
              sl_t_suc={sucursal} data={selectedData} returnData={setModifiquedSaller} /> : <></>
          }
        </Box>
      </Modal>
    </>
  );
};
export const AddProducts = ({ sucursal, returnBusinessUnit }) => {
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const get_products = async () => {
    const url_con = `/getVendedoresTableSelect?vcodEmpresa=${cook_idEmp}&vcodSucursal=${sucursal}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setData(data)
  }
  useEffect(() => {
    if (data.length === 0) {
      get_products();
      return;
    }
  }, [data.length, get_products]);

  const onClickSaveData = () => {
    const exportData = data.filter((item) => rowSelection.includes(item.Code));
    returnBusinessUnit(exportData);
  };

  const setData1 = (data) => {
    setRowSelection(data);
  }

  return (
    <>
      <div className="d-flex mt-5 justify-content-start">
        <label className="col-form-label m-0 p-0">
          <span className="small fs-6">
            Lista de vendedores
          </span>
        </label>
        <button
          className="btn btn-sm col-2 btn-danger rounded-pill me-4 p-0 m-0 active mx-4 my-2"
          onClick={(e) => onClickSaveData(e)}
        >
          Guardar datos
        </button>
      </div>
      <MyDraggableTable data={data} returnFinalSelection={setData1} />
    </>
  );
}
const MyDraggableTable = ({ data, returnFinalSelection }) => {
  const [startRowIndex, setStartRowIndex] = useState(null);
  const [endRowIndex, setEndRowIndex] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data);


  useEffect(() => {
    returnFinalSelection(selectedRows);
  }, [returnFinalSelection, selectedRows]);

  useEffect(() => {
    const filtered = data.filter((row) => {
      const values = Object.values(row).map(String);
      return values.some((value) => value.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    );
    setFilteredData(filtered);
  }, [data, searchTerm]);

  const handleMouseDown = (index) => {
    setStartRowIndex(index);
    setEndRowIndex(index);
  };

  const handleMouseEnter = (index) => {
    if (startRowIndex !== null) {
      setEndRowIndex(index);
    }
  };

  const handleMouseUp = () => {
    const start = Math.min(startRowIndex, endRowIndex);
    const end = Math.max(startRowIndex, endRowIndex);
    const selected = filteredData.slice(start, end + 1).map((row) => row.Code);

    const newSelection = new Set([...selectedRows, ...selected]);

    if (selectedRows.includes(selected[0])) {
      newSelection.delete(selected[0]);
    }

    setSelectedRows(Array.from(newSelection));
  };
  const globalTheme = window.templateCustomizer.settings.style;

  return (
    <div style={{ userSelect: 'none' }}>
      <div className="d-flex justify-content-end">
        <input
          type="text"
          placeholder="Buscar..."
          value={searchTerm}
          className="form-control form-control-sm col-4"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <table className="table" style={{ fontSize: '10px', zIndex: '2' }}>
          <thead
            className="border-bottom"
            style={{
              position: 'sticky', top: 0, zIndex: 10, color: 'black', backgroundColor: globalTheme === 'light' ? '#fffff' : '#2B2C40',
            }}
          >
            <tr>
              <th scope="col">IDCOD</th>
              <th scope="col">Código Vendedor</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr
                key={row.Code}
                onMouseDown={() => handleMouseDown(index)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseUp={handleMouseUp}
                style={{
                  background: selectedRows.includes(row.Code) ? 'lightblue' : globalTheme === 'light' ? 'rgb(254,255,244)' : '#2B2C40',
                  cursor: 'pointer',
                }}
              >
                <td>{row.Code}</td>
                <td>{row.Name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  );
};
const VisitSaller = ({ sl_t_suc, data, returnData }) => {
  const [veh_sl_t_zona, setVehSl_t_zona] = useState([""]);
  const [jsonFinal, setJsonFinal] = useState(
    {
      not_delete: data.not_delete,
      cardCode: data.cardCode,
      codigo: data.codigo,
      id: data.id,
      fechaInicioVisita: data.fechaInicioVisita,
      posicion: data.posicion,
      direccion: data.direccion,
      telefono: data.telefono,
      correo: data.correo,
      titulo: data.titulo,
      nombre: data.nombre,
      segundoNombre: data.segundoNombre,
      apellidos: data.apellidos,
      lunes: data.lunes,
      martes: data.martes,
      miercoles: data.miercoles,
      jueves: data.jueves,
      viernes: data.viernes,
      sabado: data.sabado,
      domingo: data.domingo,
      frecuencia: data.frecuencia,
      ruta: data.ruta,
      Phone2: data.Phone2,
      MobilePhone: data.MobilePhone,
      Fax: data.Fax,
      Pager: data.Pager,
      Remarks1: data.Remarks1,
      Remarks2: data.Remarks2,
      Password: data.Password,
      InternalCode: data.InternalCode,
      PlaceOfBirth: data.PlaceOfBirth,
      DateOfBirth: data.DateOfBirth,
      Gender: data.Gender,
      Profession: data.Profession,
      CityOfBirth: data.CityOfBirth,
      Active: data.Active,
      EmailGroupCode: data.EmailGroupCode,
      BlockSendingMarketingContent: data.BlockSendingMarketingContent,
      CreateDate: data.CreateDate,
      CreateTime: data.CreateTime,
      UpdateDate: data.UpdateDate,
      UpdateTime: data.UpdateTime,
      ConnectedAddressName: data.ConnectedAddressName,
      ConnectedAddressType: data.ConnectedAddressType,
      ForeignCountry: data.ForeignCountry,
      U_AB_PCCA: data.U_AB_PCCA,
      U_AB_PCAC: data?.U_AB_PCAC,
      // U_AB_PCGI: data.U_AB_PCGI,
      ContactEmployeeBlockSendingMarketingContents: data.ContactEmployeeBlockSendingMarketingContents,
    }
  );
  const onClickSaveData = () => {
    returnData(jsonFinal);
  }
  const handleFieldChangeChecked = (fieldName) => (event) => {
    const value = event.target.checked;
    setJsonFinal({
      ...jsonFinal,
      [fieldName]: value,
    });
  };
  const handleFieldChange = (fieldName) => (event) => {
    let value = event.target.value;
    setJsonFinal({
      ...jsonFinal,
      [fieldName]: value,
    });
  };
  const veh_Select_Zona = useCallback(async (inputValue) => {
    const veh_url_lstZona = '/apicbxRuta';
    let body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(veh_url_lstZona, opPe);
    const data = await response.json();
    return data;
  }, [sl_t_suc]);
  const handleSelectChangeRutas = (selectedOption) => {
    setVehSl_t_zona(selectedOption);
    setJsonFinal({
      ...jsonFinal,
      'ruta': selectedOption.Code,
    });
  };
  return (
    <>
      <div className="d-flex mt-5 justify-content-start">
        <label className="col-form-label m-0 p-0">
          <span className="small fs-6">
            Detalle vendedor
          </span>
        </label>
        <button
          className="btn btn-sm btn-danger rounded-pill me-4 p-0 m-0 active mx-4 col-2"
          onClick={(e) => onClickSaveData(e)}
        >
          Guardar datos
        </button>
      </div>
      <div className="col-12 d-flex align-items-start justify-content-between">
        <div className='d-flex flex-column col-md-4'>
          <div className='d-flex'>
            <div className="col form-group mx-1">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Ruta
                </span>
              </label>
              <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.ruta} readOnly />
              <div className='d-flex'>
                <AsyncSelect
                  id="id_zona"
                  styles={styles}
                  placeholder="Busque por COD/DESC"
                  name="form-field-name"
                  className="sele_pru form-control form-control-sm my-swal"
                  value={veh_sl_t_zona}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code + ' - ' + e.Name}
                  getOptionValue={e => e.Code}
                  loadOptions={veh_Select_Zona}
                  onChange={handleSelectChangeRutas}
                />
              </div>
            </div>
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Dias de visita:
              </span>
            </label>
          </div>
          <div className='d-flex justify-content-start align-items-start'>
            <div className='d-flex flex-column justify-content-between'>
              <div className="d-flex form-group">
                <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
                  <input className="form-check-input" type="checkbox"
                    checked={jsonFinal.lunes} onChange={handleFieldChangeChecked('lunes')} />
                </div>
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Lunes
                  </span>
                </label>
              </div>
              <div className="d-flex form-group">
                <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
                  <input className="form-check-input" type="checkbox"
                    checked={jsonFinal.martes} onChange={handleFieldChangeChecked('martes')} />
                </div>
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Martes
                  </span>
                </label>
              </div>
              <div className="d-flex form-group">
                <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
                  <input className="form-check-input" type="checkbox"
                    checked={jsonFinal.miercoles} onChange={handleFieldChangeChecked('miercoles')} />
                </div>
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Miercoles
                  </span>
                </label>
              </div>
              <div className="d-flex form-group">
                <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
                  <input className="form-check-input" type="checkbox" checked={jsonFinal.jueves} onChange={handleFieldChangeChecked('jueves')} />
                </div>
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Jueves
                  </span>
                </label>
              </div>
            </div>
            <div className='d-flex mx-4 flex-column justify-content-between'>
              <div className="d-flex form-group">
                <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
                  <input className="form-check-input" type="checkbox" checked={jsonFinal.viernes} onChange={handleFieldChangeChecked('viernes')} />
                </div>
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Viernes
                  </span>
                </label>
              </div>
              <div className="d-flex form-group">
                <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
                  <input className="form-check-input" type="checkbox" checked={jsonFinal.sabado} onChange={handleFieldChangeChecked('sabado')} />
                </div>
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Sabado
                  </span>
                </label>
              </div>
              <div className="d-flex form-group">
                <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
                  <input className="form-check-input" type="checkbox" checked={jsonFinal.domingo} onChange={handleFieldChangeChecked('domingo')} />
                </div>
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Domingo
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column col-md-6'>
          <div className='d-flex'>
            <div className="col form-group mx-1">
              <label className="col-form-label m-0 p-0 mb-2">
                <span className="small">
                  Fecha de inicio de visita
                </span>
              </label>
              <input type="date" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.fechaInicioVisita} onChange={handleFieldChange('fechaInicioVisita')} />
            </div>
            <div className='d-flex'>
              <div className="col form-group mx-1">
                <label className="col-form-label m-0 p-0 mb-2">
                  <span className="small">
                    Frecuencia
                  </span>
                </label>
                <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.frecuencia} onChange={handleFieldChange('frecuencia')} />
              </div>
            </div>
          </div>
          <div className="col form-group mx-1">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Id contacto:
              </span>
            </label>
            <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.id} onChange={handleFieldChange('id')} readOnly />
          </div>
          <div className="col form-group mx-1">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Codigo vendedor:
              </span>
            </label>
            <input type="text" className='form-control form-control-sm' autoComplete='off' value={jsonFinal.codigo} onChange={handleFieldChange('codigo')} readOnly />
          </div>
          <div className='d-flex mt-2'>
            <div className="small cont-weight-normal mx-1">
              Activo:
            </div>
            <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
              <input className="form-check-input" type="checkbox" checked={jsonFinal.U_AB_PCAC} onChange={handleFieldChangeChecked('U_AB_PCAC')} />
            </div>
          </div>
          {/* <div className="col form-group mx-1">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Activo:
              </span>
            </label>
            <div className="mx-1 form-check form-check-danger">

            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
export const ModalListTableSelection = ({ hanOpenModal, hanClosModal, onReturnData, onDataEstras }) => {
  const [pending2, setPending2] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [arrDatos, setArrDatos] = useState([]);
  const [btnVisible, setBtnVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [startRowIndex, setStartRowIndex] = useState(null);


  const columns = useMemo(() => [
    { header: 'Codigo', accessorKey: 'Code' },
    { header: 'Nombre', accessorKey: 'Name' },
  ], [])

  const lstDatos = useCallback(async () => {
    setPending2(true)
    const opPe9 = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const resp = await fetch(`/apiVendedores?vcodEmpresa=${cook_idEmp}`, opPe9);
    const data = await resp.json();
    setArrDatos(data)
    setPending2(false)
  }, [])



  const handleRowMouseDown = (row) => {

    const valExist = selectedRows.filter(selectedRow => selectedRow === row.original.Code);
    if (valExist.length) {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow !== row.original.Code));
      setTimeout(() => {
        if (selectedRows.length === 1) {
          setBtnVisible(false)
        }
      }, 500)
    } else {
      setSelectedRows(prev => [...prev, row.original.Code]);
      setStartRowIndex(true);
      setBtnVisible(true)
    }

  };
  const handleRowMouseEnter = (row) => {
    if (startRowIndex) {
      setSelectedRows(prev => [...prev, row.original.Code]);
    }
  };

  const handleRowMouseUp = () => {
    setStartRowIndex(false);
    // const exportData = emp_lst2.filter(
    //   item => selectedRows.includes(item.DocEntry)
    // );
    // setSelectedRows(Array.from(newSelection));
  };

  const probar2 = () => {

    // const exportData = arrDatos.filter((item) => selectedRows.includes(item.Code));
    // onReturnData([...exportData])
    // setSelectedRows([])
    // setBtnVisible(false)

  }

  // useEffect(() => {
  //   lstDatos()
  //   //onReturnData(selectedRows)
  //   //setArrDatosInsert(listInitData)


  //   //}, [listInitData])
  // }, [lstDatos])
  const globalTheme = window.templateCustomizer.settings.style;
  const [veh_sl_t_Unidad_Negocio, setVehSl_t_Unidad_Negocio] = useState([""]);
  const [veh_sl_t_Categorias, setVehSl_t_Categorias] = useState([""]);

  const veh_Select2_Unidad_Negocio = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }

  const veh_Select2_Categorias = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }
  const Consultarbtn = () => {
    lstDatos();
  }
  const onClickLimpiar = async (row) => {

  }

  return (
    <div style={{ userSelect: 'none' }}>
      <div className="row">
        <div className="col-lg-2">Unidad de Negocio</div>
        <div className="col-lg-3" id="comboUN" style={{ position: 'relative' }} >
          <AsyncSelect
            id="veh_sl2_chofer"
            styles={styles}
            placeholder="Busque por Unidad de Negocio"
            name="form-field-name"
            className="sele_pru from-control form-control-sm my-swal"
            value={veh_sl_t_Unidad_Negocio}
            cacheOptions
            defaultOptions
            getOptionLabel={e => e.Code + ' - ' + e.Name}
            getOptionValue={e => e.Code}
            loadOptions={veh_Select2_Unidad_Negocio}
            onChange={setVehSl_t_Unidad_Negocio}
          />
        </div>
        <div className="col-lg-1">Categorias:</div>
        <div className="col-lg-2">
          <AsyncSelect
            id="veh_sl2_chofer"
            styles={styles}
            placeholder="Busque por Categoria"
            name="form-field-name"
            className="sele_pru from-control form-control-sm my-swal"
            value={veh_sl_t_Categorias}
            cacheOptions
            defaultOptions
            getOptionLabel={e => e.Code + ' - ' + e.Name}
            getOptionValue={e => e.Code}
            loadOptions={veh_Select2_Categorias}
            onChange={setVehSl_t_Categorias}
          />
        </div>
        <div className="col-lg-4 text-center">
          <div className="text-center">
            <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={Consultarbtn}>
              <i className='bx bx-list-ul' ></i>&nbsp;Consultar
            </button>&nbsp;
            <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
          </div>
        </div>
      </div>
      <div className="card-datatable table-responsive py-4" >
        {/*<div style={{ maxHeight: '300px', overflowY: 'auto' }}>*/}
        <MaterialReactTable
          columns={columns}
          data={arrDatos}
          // options ={{
          //   searchText:"sdsds",
          //   searchTerm:"sdsads"
          // }}
          // manualSorting={false}
          // manualFiltering={true}
          // manualPagination={true}
          state={{ isLoading: pending2, sorting }}
          onSortingChange={setSorting}

          initialState={{
            density: 'compact',
            expanded: false,
            showColumnFilters: false,
            showGlobalFilter: false,

          }}
          enableFullScreenToggle={false} //quitar el alternar pantalla completa
          enableGrouping //para agrupar
          enableStickyHeader //porner escrol
          muiTableContainerProps={{ sx: { maxHeight: '256px' } }} //tamaño de largo de la tabla
          muiTableBodyCellProps={{ sx: { p: '2px 16px', fontSize: '12px' } }} //tamaño de la letra de las filas
          localization={MRT_Localization_ES}//idioma
          enablePagination={false}//quitar el enpaginado
          muiTableBodyRowProps={({ row }) => ({
            hover: false,
            //className: selectedRows.find(r => r === row.original.Code) ? 'highlight2' : '',
            sx: {
              '&:hover': {
                color: 'error',
                cursor: 'pointer',//cambiar el puntero de maus
                userSelect: 'none'//al seleccionar no surrallas las filas
              },
              //background: row.index === selectedPedidoRows ? 'lightblue' : 'white',
              backgroundColor: selectedRows.find(r => r === row.original.Code) ? 'lightblue' : '',
            },
            onMouseDown: () => handleRowMouseDown(row),
            onMouseEnter: () => handleRowMouseEnter(row),
            onMouseUp: handleRowMouseUp,
          })
          }
          renderTopToolbarCustomActions={({ table, row }) => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
              {btnVisible &&
                (<AButton tp_tilde="Agregar a tabla" bt_descr="Agregar a tabla" tp_posit="top" bt_class="btn btn-sm btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bxs-share" vl_tpbtn={1} />)
              }
            </Box>)}
            muiCircularProgressProps={{ color: 'error' }}
            muiSkeletonProps={{
              animation: 'pulse',
              height: 28,
            }}

        />
      </div>
      {/* <div className="text-end">

              <AButton tp_tilde="Probar2" bt_descr="Agregar a tabla" tp_posit="top" bt_class="btn btn-sm btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} />
            </div> */}
    </div>
  )
}