import { ToastContainer } from "react-toastify";
import { SelectSucursal } from "../../components/Selects/SelectsPrincipales";
import { useMemo, useState } from "react";
import { notify_warning } from "../../components/utils";
import { Box, Modal } from '@mui/material';
import { MaterialReactTable } from 'material-react-table'

import { MRT_Localization_ES } from "material-react-table/locales/es";
import { AButton } from "../../components/Button";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const GPEmpleados = () => {
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [dataTable, setDataTable] = useState([]);
  const [load, setLoad] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [pending, setpending] = useState(false);
  const [dataSelected, setDataSelected] = useState([]);
  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const listar = async () => {
    setpending(true);
    if (!emp_sl_t_suc || emp_sl_t_suc === '9999') {
      notify_warning('Seleccione una sucursal');
      return;
    }
    setLoad(true);
    const dataForPush = [];
    for (let index = 0; index < 4; index++) {
      dataForPush.push({
        codigo: `${index}`,
        nombre: `vendedor ${index}`,
        activo: false,
        sucursal: `sucursal ${emp_sl_t_suc}`,
        supervisor: `supervisor ${index}`,
        tipodocumento: `tipo de documento ${index}`,
        documentoidentidad: `${Math.floor(100000 + Math.random() * 900000)}`,
        cargo: `cargo ${index}`,
        mesaabgrupoventa: `mesa ${index}`
      });
    }
    setDataTable(dataForPush);
    setLoad(false);
  }

  const columns = useMemo(() => [
    { header: "Codigo", accessorKey: "codigo" },
    { header: "Nombres del Vendedor", accessorKey: "nombre" },
    {
      header: "Activo", accessorKey: "activo", Cell: ({ row }) => (
        <>
          <div className="align-items-center">
            <div className="form-check form-check-danger">
              <input className="form-check-input" type="checkbox" value="M" id="customCheckPrimary" checked={row.activo} readOnly />
            </div>
          </div>
        </>
      ),
    },
    { header: "Telefono", accessorKey: "telefono" },
    { header: "Correo", accessorKey: "correo" },
    { header: "Sucursal", accessorKey: "sucursal" },
    { header: "Supervisor", accessorKey: "supervisor" },
    { header: "Tipo de documento", accessorKey: "tipodocumento" },
    { header: "Documento de identidad", accessorKey: "documentoidentidad" },
    { header: "Cargo", accessorKey: "cargo" },
    { header: "Mesa", accessorKey: "mesa" },
    { header: "Canal", accessorKey: "canal" },
    { header: "Grupo Ventas", accessorKey: "grupoVentas" },
    { header: "Mesa AB Grupo Venta", accessorKey: "mesaabgrupoventa" },
  ], []);

  const handleRowCliked = (row) => {
    setDataSelected(row);
    handleOpenModal();
  }

  const setDataReturn = (data) => {
    let index1 = dataTable.findIndex((data1) => data1.codigo === data.codigo);
    if (index1 === -1) {
      dataTable.push(data);
      setDataTable(dataTable);
      return;
    }
    dataTable[index1] = data;
    setDataTable(dataTable);
    handleCloseModal();
  }

  const handleButtonClick = () => {
    setDataSelected(null);
    handleOpenModal();
  }

  return (
    <>
      <div className='d-flex flex-column'>
        <h6 className="text-muted">Empleados</h6>
      </div >
      <div className="d-flex">
        <div className="col-lg-1">Sucursal:</div>
        <div className="col-lg-2">
          <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
        </div>
        <div className="col-lg-2">
          <div className="text-center mb-3">
            <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={listar}>
              <i className='bx bx-list-ul' ></i>&nbsp;Consultar
            </button>
          </div>
        </div>
      </div>
      <MaterialReactTable
        columns={columns}
        data={dataTable}
        enablePagination={true}
        enableRowActions
        enableTopToolbar
        renderTopToolbarCustomActions={({ row }) => (
          emp_sl_t_suc ?
            <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
              <AButton tp_tilde="Nuevo" tp_posit="top" bt_class="btn btn-label-success btn-icon btn-sm" ic_class="bx bxs-edit bx-ms" bt_funct={() => handleButtonClick()}
                vl_tpbtn={1} />
            </Box> : <div></div>
        )}
        enableGrouping={true}
        state={{ isLoading: load }}
        localization={MRT_Localization_ES}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '2px' }}>
            <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-label-warning btn-icon btn-sm" ic_class="bx bxs-edit bx-ms" bt_funct={() => handleRowCliked(row.original)}
              id={JSON.stringify(row.original)} vl_tpbtn={1} />
          </Box>
        )}
        muiCircularProgressProps={{ color: 'error' }}
        muiSkeletonProps={{
          animation: 'pulse',
          height: 28,
        }}
      />
      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '65%', height: '80%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={handleCloseModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <FormModal data={dataSelected} dataReturn={setDataReturn} />
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default GPEmpleados;

export const FormModal = ({ data, dataReturn }) => {
  const [dataForm, setDataForm] = useState({
    codigo: data ? data.codigo : '',
    nombre: data ? data.nombre : '',
    activo: data ? data.activo : '',
    sucursal: data ? data.sucursal : '',
    supervisor: data ? data.supervisor : '',
    tipodocumento: data ? data.tipodocumento : '',
    documentoidentidad: data ? data.documentoidentidad : '',
    cargo: data ? data.cargo : '',
    mesaabgrupoventa: data ? data.mesaabgrupoventa : '',
    telefono: data ? data.telefono : '',
    correo: data ? data.correo : '',
    mesa: data ? data.mesa : '',
    grupoVentas: data ? data.grupoVentas : '',
  });

  const handleFieldChange = (fieldName) => (event) => {
    let value = event.target.value;
    setDataForm({
      ...dataForm,
      [fieldName]: value,
    });
  };

  const handleFieldChangeChecked = (fieldName) => (event) => {
    const value = event.target.checked;
    setDataForm({
      ...dataForm,
      [fieldName]: value,
    });
  };

  const handleButtonClick = () => {
    dataReturn(dataForm)
  }

  return (
    <>
      <div className="d-flex">
        <label className="col-form-label m-0 p-0">
          <span className="small fs-6">
            Datos generales:
          </span>
        </label>
        <button
          className="btn btn-sm col-2 btn-danger rounded-pill me-4 p-0 m-0 active mx-4"
          onClick={(e) => handleButtonClick(e)}
        >
          Guardar datos
        </button>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column col-md-5">
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                codigo
              </span>
            </label>
            <input type="text" className='form-control form-control-sm' autoComplete='off' value={dataForm.codigo} onChange={handleFieldChange('codigo')} disabled={data ? true : false} />
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Nombres del vendedor
              </span>
            </label>
            <input type="text" className='form-control form-control-sm' autoComplete='off' value={dataForm.nombre} onChange={handleFieldChange('nombre')} />
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Nombres del vendedor
              </span>
            </label>
            <input type="text" className='form-control form-control-sm' autoComplete='off' value={dataForm.telefono} onChange={handleFieldChange('telefono')} />
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Correo
              </span>
            </label>
            <input type="text" className='form-control form-control-sm' autoComplete='off' value={dataForm.correo} onChange={handleFieldChange('correo')} />
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Surcusal
              </span>
            </label>
            <SelectSucursal type="text" autoComplete='off' value={dataForm.sucursal} onChange={handleFieldChange('sucursal')} />
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Supervisor
              </span>
            </label>
            <input type="text" className="form-control form-control-sm" autoComplete='off' value={dataForm.supervisor} onChange={handleFieldChange('supervisor')} />
          </div>
        </div>
        <div className="d-flex flex-column col-md-5">
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Tipo de documento
              </span>
            </label>
            <select className="form-select form-select-sm" value={dataForm.tipodocumento} onChange={handleFieldChange('tipodocumento')}>
              <option value="">--Seleccione--</option>
              <option value="DNI">DNI</option>
              <option value="C.E.">Carnet de extranjeria</option>
            </select>
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                N° Documento
              </span>
            </label>
            <input className="form-control form-control-sm" value={dataForm.documentoidentidad} onChange={handleFieldChange('documentoidentidad')} />
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Cargo
              </span>
            </label>
            <select className='form-select form-select-sm' value={dataForm.cargo} onChange={handleFieldChange('cargo')} >
              <option value="">--Seleccione--</option>
              <option value={"Vendedor"}>Vendedor</option>
              <option value={"Supervisor"}>Supervisor</option>
            </select>
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Mesa
              </span>
            </label>
            <select className='form-select form-select-sm' value={dataForm.mesa} onChange={handleFieldChange('mesa')} >
              <option value="">--Seleccione--</option>
              <option value={"Mesa 1"}>Mesa 1</option>
              <option value={"Mesa 2"}>Mesa 2</option>
            </select>
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Canal
              </span>
            </label>
            <select className='form-select form-select-sm' value={dataForm.canal} onChange={handleFieldChange('canal')} >
              <option value="">--Seleccione--</option>
              <option value={"Bodegas (A)"}>Bodegas (A)</option>
              <option value={"Empleados"}>Empleados</option>
              <option value={"Mayoristas (C)"}>Mayoristas (C)</option>
              <option value={"Mercados (B)"}>Mercados (B)</option>
              <option value={"Relacionados"}>Relacionados</option>
            </select>
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                AB GrupoVenta
              </span>
            </label>
            <input className="form-control form-control-sm" value={dataForm.mesaabgrupoventa} onChange={handleFieldChange('mesaabgrupoventa')} />
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between mt-2'>
        <div className='d-flex mt-2'>
          <div className="small cont-weight-normal mx-1">
            Activo:
          </div>
          <div className="d-flex justify-content-center mx-1">
            <input className="form-check-input" type="checkbox" checked={dataForm.activo} onChange={handleFieldChangeChecked('activo')} />
          </div>
        </div>
      </div>
    </>
  );
};