import React, {useState,useCallback} from 'react'
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales'
import { ToastContainer } from 'react-toastify';
import { notify_warning, notify_valido, selectTabla, notify_error} from '../../components/utils';
import DataTable, { createTheme as createThemeS } from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import AsyncSelect from 'react-select/async';
//import DatePicker from "react-datepicker";
import {Tooltip}from '@mui/material';
import $ from 'jquery'
//import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
import '../../App.css';
const globalTheme = window.templateCustomizer.settings.style;
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
// const styles = {
//   control: (provided, state) => ({
//       ...provided,
//       background: `#fff`,
//       borderColor: `#D2D5DC`,
//       minHeight: `29px`,
//       height: `29px`,
//       boxShadow: state.isFocused ? null : null,
//       border: '1px solid #D2D5DC',

//     }),

//     valueContainer: (provided, state) => ({
//       ...provided,
//       height: `29px`,
//       padding: `0 6px`,
//       color: `#697a8d`,
//     }),

//     input: (provided, state) => ({
//       ...provided,
//       color: `#697a8d`,
//       fontSize: `0.75rem`,
      
//     }),
//     indicatorSeparator: state => ({
//       display: `none`,
      
//     }),
//     indicatorsContainer: (provided, state) => ({
//       ...provided,
//       height: `29px`,
      
//     }),
// };
const styles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: `#D2D5DC`,
    border: '1px solid #D2D5DC',
    boxShadow: state.isFocused ? null : null,
    backgroundColor: window.templateCustomizer.settings.style === 'light'
      ? '#fefff4'
      : '#2B2C40',
    color: window.templateCustomizer.settings.style === 'light'
      ? '#2B2C40'
      : '#fefff4',
    minHeight: `29px`,
    height: `29px`,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (window.templateCustomizer.settings.style === 'light' ? '#fefff4' : '#2B2C40')
      : (window.templateCustomizer.settings.style === 'light' ? '#fefff4' : '#2B2C40'),
    color: state.isSelected
      ? 'white'
      : (window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: window
      .templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4',
    fontSize: `0.75rem`,
  }),

  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4',
  }),
};
const REPVisitasRepartidor = () => {
  const [pending, setpending] = useState(false);
  const [disabled, setdisabled] = useState(false);
  //const tableRef = useRef(null);
  const [lst, setlst] = useState([]);
  //const [lstModal, setlstModal] = useState([]);
  const [sl_t_suc, setSl_t_suc] = useState('');
  const [veh_sl_t_vehi, setVehSl_t_vehi] = useState([""]);
  const [veh_sl_t_zona, setVehSl_t_zona] = useState([""]);
  const [veh_sl_t_cho, setVehSl_t_cho] = useState([""]);


  const [vlSelec_sucursal, setVlSelec_sucursal] = useState('');
  const [dtarray_Modaltab, setDtarray_Modaltab] = useState([]);

  const [rb_estado, setRb_estado] = useState(true);


  const handleCheckboxChange = () => {
    setRb_estado(!rb_estado);
  };

  const onClickModal  = async() => {
    $("#emp_btn_cancelar").removeClass('d-none')
    $("#btn_modal_guardar").removeClass('d-none')
    $("#btn_modal_asigModificado").addClass('d-none')
    var red = $("#veh_slc_suc").find(':selected').text()
    $("#txt_desc_suc").text(red)
    setdisabled(false)
    setVehSl_t_vehi([]);
    setVehSl_t_zona([]);
    setDtarray_Modaltab([]);
    if(!sl_t_suc||sl_t_suc==="9999"){ 
      notify_warning(' Debes seleccionar una sucursal.');
    }else{ 
      setVlSelec_sucursal(sl_t_suc)
    }
  }
  

  const onClickListar = async() =>{
    if(!sl_t_suc||sl_t_suc==="9999"){
      notify_warning(' Debes seleccionar una sucursal.');
      // Si se coloca solo aparecerá en la vista de mantenimiento 
    }else{ 
        $("#btn_new").removeClass("d-none")
        const url = '/apiListarVisitaRepartidor'
        const body = { vcodEmpresa: cook_idEmp, sl_t_suc: sl_t_suc };
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const columns = [
          { cell:(row) =>
            <div className="align-items-center">
                <button type="button" className="btn btn-sm btn-icon btn-label-youtube" id={JSON.stringify(row)} data-bs-target="#modalToggle1" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={handleRowClickedModal} ><i id={JSON.stringify(row)} className='bx bx-bus bx-ms'></i></button>
            </div>
            ,ignoreRowClick: true,  allowOverflow: true, button: true, name: 'OPCIÓN' 
          },
          { name: 'Sucursal',  selector: row => row.sucursal,           cellExport: row => row.sucursal,           sortable: true, },
          { name: 'NPlaca',  selector: row => row.codigo,           cellExport: row => row.codigo,           sortable: true },      
          { name: 'Codigo',  selector: row => row.codChofer,           cellExport: row => row.Nombre,           sortable: true},
          { name: 'Nombre',  selector: row => row.Conductor,           cellExport: row => row.Conductor,           sortable: true  },
          { name: 'Estado',  selector: row => row.activo,           cellExport: row => row.Conductor,           sortable: true, omit:true  },
        
          ];
      //const columns = lst_veh_columns
      setpending(true)
      document.getElementById('btn_procesar').disabled = "true";
      document.getElementById('btn_procesar_icon').setAttribute('class', 'd-none');
      document.getElementById('btn_procesar_spinner').className = 'spinner-border';
      const response = await fetch(url,opPe);
      const data = await response.json();
      //console.log(data);
      const tableData = { columns, data };
      setlst(tableData)
      document.getElementById('btn_procesar').disabled = "";
      document.getElementById('btn_procesar_icon').setAttribute('class', 'bx bx-cloud-download bx-ms');
      document.getElementById('btn_procesar_spinner').className = 'spinner-border d-none';
      setpending(false)
      $(".tabla-resumen .rdt_Table").addClass("table");
    }
  }

  const handleRowClicked = (row) => {
    selectTabla("tabla-resumen")
    
  }
  const veh_url_lstchofer ='/apiConsulChofer';
  const veh_Select2_Chofer = useCallback( async (inputValue) => {
    //console.log("veh_sl_t_suc",veh_sl_t_suc);
    let body = { vcodEmpresa: cook_idEmp,vcodSucursal: vlSelec_sucursal, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(veh_url_lstchofer,opPe);
    const data = await response.json();
    //console.log('select ',data);
    return data;
  },[vlSelec_sucursal])
  const changeSl_t_suc = (event) => { 
    setSl_t_suc(event.target.value);
  }

  const loadOptions_Select_Vehiculos = useCallback(async (inputValue) => {
    // setVehSl_t_zona(inputValue);
    // $("#emp_slc_tem").focus();
    const veh_url_lstZona ='/apicbxVehi';
    let body = { vcodEmpresa: cook_idEmp,vcodSucursal: sl_t_suc, vtxtConsult: inputValue  };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(veh_url_lstZona,opPe);
    const data = await response.json();
    return data;
  },[sl_t_suc])

  const veh_Select_Zona = useCallback(async (inputValue) => {
    const veh_url_lstZona ='/apicbxRuta';
    let body = { vcodEmpresa: cook_idEmp,vcodSucursal: sl_t_suc, vtxtConsult: inputValue  };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(veh_url_lstZona,opPe);
    const data = await response.json();
    return data;
  },[sl_t_suc])


  const handleRowClickedModal = async(state) => {
    
    $("#emp_btn_cancelar").addClass('d-none')
    $("#btn_modal_guardar").addClass('d-none')
    $("#btn_modal_asigModificado").removeClass('d-none')
    setdisabled(true)
    const dtLlegada = JSON.parse(state.target.id);
    //alert(dtLlegada.activo)
    setVlSelec_sucursal(sl_t_suc)
    setVehSl_t_vehi({Code:dtLlegada.codigo})
    setVehSl_t_cho({Code: dtLlegada.codChofer, Name: dtLlegada.Conductor})
    setRb_estado((dtLlegada.activo==="Y")? true : false)

    
    var red = $("#veh_slc_suc").find(':selected').text()
    $("#txt_desc_suc").text(red)
    const veh_url_lstZona ='/apilistAsigncacion';
    let body = { vcodEmpresa: cook_idEmp,vcodSucursal: sl_t_suc, vcodVehiculo: dtLlegada.codigo  };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(veh_url_lstZona,opPe);
    const resdatos = await response.json();
    if (resdatos.vl_rsparr === 0){
      notify_warning("¡No existen datos, pero puede registrar.!")
    }else{
      //console.log("apilistAsigncacion resdatos",resdatos);
      setDtarray_Modaltab(resdatos)
    }

  }


  const columns = [
    { cell:(row) =>
      <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDeleteFile} id={JSON.stringify(row)}  ><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
      </div>
      ,ignoreRowClick: true,  allowOverflow: true, button: true, name: 'OPCIÓN' 
    },
    { name: 'Codigo', selector: row => row.codigo, sortable: true },
    { name: 'Nombre', selector: row => row.nombre, sortable: true },
    { cell:(row) =>
      <div className="align-items-center">
        <div className="form-check form-check-danger">
          <input className="form-check-input" type="checkbox" value="L" id="customCheckPrimary" onChange={() => handleRowLu(row)} checked={(row.lu==='Y')?'checked':''}/>
          {/* <label className="form-check-label " htmlFor="customCheckPrimary">Lunes</label> */}
        </div>
      </div>
      ,ignoreRowClick: true,  allowOverflow: true, button: true, name: 'L', width: "50px"
    },
    { cell:(row) =>
      <div className="align-items-center">
        <div className="form-check form-check-danger">
          <input className="form-check-input" type="checkbox" value="M" id="customCheckPrimary" onChange={() => handleRowMa(row)} checked={(row.ma==='Y')?'checked':''}/>
          {/* <label className="form-check-label " htmlFor="customCheckPrimary">Lunes</label> */}
        </div>
      </div>
      ,ignoreRowClick: true,  allowOverflow: true, button: true, name: 'M', width: "50px" 
    },
    { cell:(row) =>
      <div className="align-items-center">
        <div className="form-check form-check-danger">
          <input className="form-check-input" type="checkbox" value="M" id="customCheckPrimary"  onChange={() => handleRowMi(row)} checked={(row.mi==='Y')?'checked':''}/>
          {/* <label className="form-check-label " htmlFor="customCheckPrimary">Lunes</label> */}
        </div>
      </div>
      ,ignoreRowClick: true,  allowOverflow: true, button: true, name: 'M', width: "50px" 
    },
    { cell:(row) =>
      <div className="align-items-center">
        <div className="form-check form-check-danger">
          <input className="form-check-input" type="checkbox" value="J" id="customCheckPrimary"  onChange={() => handleRowJu(row)} checked={(row.ju==='Y')?'checked':''}/>
          {/* <label className="form-check-label " htmlFor="customCheckPrimary">Lunes</label> */}
        </div>
      </div>
      ,ignoreRowClick: true,  allowOverflow: true, button: true, name: 'J', width: "50px"
    },
    { cell:(row) =>
      <div className="align-items-center">
        <div className="form-check form-check-danger">
          <input className="form-check-input" type="checkbox" value="V" id="customCheckPrimary"  onChange={() => handleRowVi(row)} checked={(row.vi==='Y')?'checked':''}/>
          {/* <label className="form-check-label " htmlFor="customCheckPrimary">Lunes</label> */}
        </div>
      </div>
      ,ignoreRowClick: true,  allowOverflow: true, button: true, name: 'V', width: "50px" 
    },
    { cell:(row) =>
      <div className="align-items-center">
        <div className="form-check form-check-danger">
          <input className="form-check-input" type="checkbox" value="S" id="customCheckPrimary"  onChange={() => handleRowSa(row)} checked={(row.sa==='Y')?'checked':''}/>
          {/* <label className="form-check-label " htmlFor="customCheckPrimary">Lunes</label> */}
        </div>
      </div>
      ,ignoreRowClick: true,  allowOverflow: true, button: true, name: 'S', width: "50px" 
    },
    { cell:(row) =>
      <div className="align-items-center">
        <div className="form-check form-check-danger">
          <input className="form-check-input" type="checkbox" value="D" id="customCheckPrimary"  onChange={() => handleRowDo(row)} checked={(row.do==='Y')?'checked':''}/>
          {/* <label className="form-check-label " htmlFor="customCheckPrimary">Lunes</label> */}
        </div>
      </div>
      ,ignoreRowClick: true,  allowOverflow: true, button: true, name: 'D', width: "50px" 
    },
  ];

  // const nuevosDatos = [];
  const fc_btn_agregar= async() => {
    
    setdisabled(true)
    //console.log("veh_sl_t_vehi",veh_sl_t_vehi);
    //console.log("veh_sl_t_zona",veh_sl_t_zona);
    if (veh_sl_t_vehi.length === 0 || veh_sl_t_cho.length === 0 || veh_sl_t_zona.length === 0) {
      notify_warning('Seleccione datos.');
      setdisabled(false)

    } else {
      scrollToLastRow()
      const varr = dtarray_Modaltab
      /** -obtener valores */
      const codVehi = veh_sl_t_vehi.Code;
      const codRuta = veh_sl_t_zona.Code;
      // console.log("Hola fc_btn_agregar  codVehi->"+codVehi+" codRuta->"+codRuta)
      const datosrepetidos = varr.filter(varr => varr.codigo === codVehi && varr.nombre === codRuta)
      // console.log("datosrepetidos",datosrepetidos);
      const data = { codigo: codVehi, nombre: codRuta, lu:"N",ma:"N",mi:"N",ju:"N",vi:"N",sa:"N",do:"N" }
      if (datosrepetidos.length === 0) {
        setDtarray_Modaltab([...dtarray_Modaltab,data])
        setVehSl_t_zona([])
      } else {
        notify_warning('Datos selecionados ya exiten.');
      }
    }


  }
  const onClickGuardar= async() => {
    $("#btn_modal_guardar").attr("disabled",true)
    $("#icon_btn_modal_guardar").addClass("d-none")
    $("#spinner_btn_modal_guardar").removeClass("d-none")
    //if (veh_sl_t_vehi.length === 0 || veh_sl_t_zona.length === 0  || dtarray_Modaltab.length === 0) {
    if (veh_sl_t_vehi.length === 0 || veh_sl_t_cho.length === 0 || dtarray_Modaltab.length === 0) {
      notify_warning('Seleccione datos.');
    } else {
      const veh_url_lstZona ='/gr-rep-asing';
      let body = { vcodEmpresa: cook_idEmp,vcodSucursal: vlSelec_sucursal, vcodVehiculo: veh_sl_t_vehi.Code, vcodChofer: veh_sl_t_cho, vestActivo:rb_estado, vdtarray:dtarray_Modaltab  };
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const response = await fetch(veh_url_lstZona,opPe);
      const resdatos = await response.json();
      if (resdatos.vl_api === 1){
        notify_warning("ERROR AL AGREGAR O REGISTRO YA EXISTE, VERIFICAR DATOS")
      }
      if (resdatos.vl_existe === 1){
        notify_warning("CODIGO DE USUARIO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("CODIGO DE USUARIO SE REGISTRO CORRECTAMENTE!!!")
        setDtarray_Modaltab([])
        setVehSl_t_vehi([]);
        setVehSl_t_zona([]);
        setdisabled(false)
      }
    }
    $("#btn_modal_guardar").attr("disabled",false)
    $("#icon_btn_modal_guardar").removeClass("d-none")
    $("#spinner_btn_modal_guardar").addClass("d-none")
  }
  const onClickGuardar2= async() => {
    $("#btn_modal_asigModificado2").attr("disabled",true)
    $("#icon_btn_modal_asigModificado2").addClass("d-none")
    $("#spinner_btn_modal_asigModificado2").removeClass("d-none")
    if (veh_sl_t_vehi.length === 0 || veh_sl_t_cho.length === 0 || dtarray_Modaltab.length === 0) {
      notify_warning('Seleccione datos.');
    } else {
      const veh_url_lstZona ='/gr-rep-asing';
      let body = { vcodEmpresa: cook_idEmp,vcodSucursal: vlSelec_sucursal, vcodVehiculo: veh_sl_t_vehi.Code, vcodChofer: veh_sl_t_cho, vestActivo:rb_estado, vdtarray:dtarray_Modaltab  };
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const response = await fetch(veh_url_lstZona,opPe);
      const resdatos = await response.json();
      if (resdatos.vl_api === 1){
        notify_warning("ERROR AL AGREGAR O REGISTRO YA EXISTE, VERIFICAR DATOS")
      }
      if (resdatos.vl_existe === 1){
        notify_warning("VEHICULO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
        setDtarray_Modaltab([])
        setVehSl_t_vehi([]);
        setVehSl_t_zona([]);
        setdisabled(false)
        $("#btn-cerrar").click()
      }
    }
    $("#btn_modal_asigModificado2").attr("disabled",false)
    $("#icon_btn_modal_asigModificado2").removeClass("d-none")
    $("#spinner_btn_modal_asigModificado2").addClass("d-none")
  }
  const onClickModificarAsigancion= async() => {
    $("#btn_modal_asigModificado").attr("disabled",true)
    $("#icon_btn_modal_asigModificado").addClass("d-none")
    $("#spinner_btn_modal_asigModificado").removeClass("d-none")
    if (veh_sl_t_vehi.length === 0 || dtarray_Modaltab.length === 0) {
      //console.log("drevv", dtarray_Modaltab);
      notify_warning('Seleccione datos.');
    } else {
      const veh_url_lstZona ='/gr-mod-asing';
      let body = { vcodEmpresa: cook_idEmp,vcodSucursal: vlSelec_sucursal, vcodVehiculo: veh_sl_t_vehi.Code, vcodChofer: veh_sl_t_cho, vestActivo:rb_estado, vdtarray:dtarray_Modaltab  };
      const opPe = { method: "PATCH", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const response = await fetch(veh_url_lstZona,opPe);
      const resdatos = await response.json();
      if (resdatos.vl_api === 1){
        notify_warning("ERROR AL AGREGAR O REGISTRO YA EXISTE, VERIFICAR DATOS")
      }
      if (resdatos.vl_existe === 1){
        notify_warning("CODIGO DE USUARIO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("CODIGO DE USUARIO SE REGISTRO CORRECTAMENTE!!!")
        setDtarray_Modaltab([])
        setVehSl_t_vehi([]);
        setVehSl_t_zona([]);
        setdisabled(false)
        $("#btn-cerrar").click()
      }
    }
    $("#btn_modal_asigModificado").attr("disabled",false)
    $("#icon_btn_modal_asigModificado").removeClass("d-none")
    $("#spinner_btn_modal_asigModificado").addClass("d-none")
  }
  const onClickClearInputs = () => {
    setDtarray_Modaltab([])
    setVehSl_t_vehi([]);
    setVehSl_t_zona([]);
    setdisabled(false)
    $("#btn_modal_asigModificado").addClass('d-none')
  }

  const handleRowLu = (row) => {

    const nuevosDatos = [...dtarray_Modaltab];
    const elementIndex = nuevosDatos.findIndex((obj => obj.nombre === row.nombre));
    nuevosDatos[elementIndex].lu = (nuevosDatos[elementIndex].lu === "N") ? nuevosDatos[elementIndex].lu = "Y" : nuevosDatos[elementIndex].lu = "N"
    setDtarray_Modaltab(nuevosDatos)
    
  }
  const handleRowMa = (row) => {

    const nuevosDatos = [...dtarray_Modaltab];
    const elementIndex = nuevosDatos.findIndex((obj => obj.nombre === row.nombre));
    nuevosDatos[elementIndex].ma = (nuevosDatos[elementIndex].ma === "N") ? nuevosDatos[elementIndex].ma = "Y" : nuevosDatos[elementIndex].ma = "N"
    setDtarray_Modaltab(nuevosDatos)

  }
  const handleRowMi = (row) => {

    const nuevosDatos = [...dtarray_Modaltab];
    const elementIndex = nuevosDatos.findIndex((obj => obj.nombre === row.nombre));
    nuevosDatos[elementIndex].mi = (nuevosDatos[elementIndex].mi === "N") ? nuevosDatos[elementIndex].mi = "Y" : nuevosDatos[elementIndex].mi = "N"
    setDtarray_Modaltab(nuevosDatos)

  }
  const handleRowJu = (row) => {
    
    const nuevosDatos = [...dtarray_Modaltab];
    const elementIndex = nuevosDatos.findIndex((obj => obj.nombre === row.nombre));
    nuevosDatos[elementIndex].ju = (nuevosDatos[elementIndex].ju === "N") ? nuevosDatos[elementIndex].ju = "Y" : nuevosDatos[elementIndex].ju = "N"
    setDtarray_Modaltab(nuevosDatos)

  }
  const handleRowVi = (row) => {

    const nuevosDatos = [...dtarray_Modaltab];
    const elementIndex = nuevosDatos.findIndex((obj => obj.nombre === row.nombre));
    nuevosDatos[elementIndex].vi = (nuevosDatos[elementIndex].vi === "N") ? nuevosDatos[elementIndex].vi = "Y" : nuevosDatos[elementIndex].vi = "N"
    setDtarray_Modaltab(nuevosDatos)

  }
  const handleRowSa = (row) => {

    
    const nuevosDatos = [...dtarray_Modaltab];
    const elementIndex = nuevosDatos.findIndex((obj => obj.nombre === row.nombre));
    nuevosDatos[elementIndex].sa = (nuevosDatos[elementIndex].sa === "N") ? nuevosDatos[elementIndex].sa = "Y" : nuevosDatos[elementIndex].sa = "N"
    //const dtLlegada = JSON.parse(state.target.id);

    // const updatedDataArray = dtarray_Modaltab.map((data) =>
    //   data === row ? { ...data, selected: !data.selected } : data
    // );
    setDtarray_Modaltab(nuevosDatos)
  }
  const handleRowDo = (row) => {

    const nuevosDatos = [...dtarray_Modaltab];
    const elementIndex = nuevosDatos.findIndex((obj => obj.nombre === row.nombre));
    nuevosDatos[elementIndex].do = (nuevosDatos[elementIndex].do === "N") ? nuevosDatos[elementIndex].do = "Y" : nuevosDatos[elementIndex].do = "N"
    setDtarray_Modaltab(nuevosDatos)

  }

  const onClickCloseModal = async(state) => {
    setDtarray_Modaltab([])
    setVehSl_t_vehi([])
    setVehSl_t_zona([])
    setVehSl_t_cho([])
    $("#btn_modal_asigModificado").addClass("d-none")
    $("#btn_modal_asigModificado2").addClass("d-none")
  }

  const onClickDeleteFile = async(state) => {
    
    try {
      const dtLlegada = JSON.parse(state.target.id);
      const rutaElimar = dtLlegada.nombre; 
      const rutaEliCod = dtLlegada.codigo; 


      const newArrY = dtarray_Modaltab.filter(item => item.nombre !== rutaElimar)
      if (newArrY) {
        setDtarray_Modaltab(newArrY)
        $("#btn_modal_asigModificado").addClass("d-none")
        $("#btn_modal_asigModificado2").removeClass("d-none")
        notify_valido('Se elimino correctamente.');
        /** Elimna en el sap */
        const url ='/gr-del-asing';
        let body = { vcodEmpresa: cook_idEmp,vcodSucursal: vlSelec_sucursal, vcodVehiculo: rutaEliCod };
        const opPe = { method: "DELETE", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(url,opPe);
        const resdatos = await response.json();
        console.log("onClickDeleteFile resdatos", resdatos);

      } else {
        notify_error('Error al Eliminar!!');
        
      }
      
      
     
    } catch (error) {
      console.log(error);;
    }
  }

  const scrollToLastRow = () => {
    setTimeout(() => {
      $(".tabla-resumen div[data-column-id='4'] .form-check-input").focus()
    },500)
    
  };
  // estilo de la atabla Modo oscuro 
  createThemeS('solarized', {
    text: {
      primary: globalTheme === 'light'
        ? '#2B2C40'
        : '#fefff4',
      secondary: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
    },
    background: {
      default: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
    },
    context: {
      background: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
      text: '#FFFFFF',
    },
  });
  return (
    <div>
      <h6 className="text-muted">Visitas Del Repartidor</h6>
        <div className="card card-action">
            {/* <div className="card-header ">
                <div className="card-action-title">Lista </div>
            </div> */}
            <div className="card-body ">
              <div className="row">
                <div className="col-lg-2">Sucursal:</div>
                <div className="col-lg-2">
                  <SelectSucursal id="veh_slc_suc" value={sl_t_suc} onChange={changeSl_t_suc}  /> {/* disabled={disabled} */}
                </div>
                <div className="col-lg-4 text-end ">
                  <Tooltip title="Consultar información" placement="top">
                    <button className="btn btn-label-youtube btn-sm" id="btn_procesar" onClick={onClickListar} >
                      <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                      <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                      &nbsp;Consultar
                    </button>
                  </Tooltip>&nbsp;
                  <button className="btn btn-label-twitter btn-sm d-none" id="btn_new" data-bs-toggle="modal" data-bs-target="#modalToggle1"  onClick={onClickModal} > <i className='bx bxs-file-plus'></i>&nbsp;Nuevo </button>
                </div>
              </div>
              <div className="card-datatable table-responsive">
                <DataTableExtensions {...lst} >
                  <DataTable className="tabla-resumen"
                    Header
                    defaultSortField="id"
                    defaultSortAsc={true}
                    pagination
                    responsive  
                    highlightOnHover
                    progressPending={pending}
                    noContextMenu="No hay registros para mostrar"
                    onRowClicked={handleRowClicked}
                    theme='solarized'
                  />
                </DataTableExtensions>
              </div>
            </div>
        </div>

        <div className="modal fade" id="modalToggle1" data-bs-keyboard="false" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true" style={{"display": "none"}}>
          <div className="modal-dialog modal-lg " role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel4">Asignación de rutas a vehículo - Sucursal {sl_t_suc} - <span id='txt_desc_suc'></span> </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClickCloseModal}></button>
              </div>
              <div className="modal-body">
                <div className="row  mb-3">
                  <div className="col-lg-1">PlacaV:</div>
                  <div className="col-lg-4">
                    <AsyncSelect
                      id="id_zona"
                      styles={styles}
                      placeholder="Busque por COD/DESC"
                      name="form-field-name"
                      className="sele_pru from-control form-control-sm"  
                      value={veh_sl_t_vehi}
                      cacheOptions
                      defaultOptions
                      getOptionLabel={e => e.Code}
                      getOptionValue={e => e.Code}
                      loadOptions={loadOptions_Select_Vehiculos}
                      onChange={setVehSl_t_vehi}
                      // isClearable={true}
                      // openMenuOnClick={false}
                      isDisabled={disabled}
                    />
                  </div>
                  <div className="col-lg-1">Chofer:</div>
                  <div className="col-lg-4" > 
                    <AsyncSelect
                      id="veh_sl2_chofer"
                      styles={styles}
                      placeholder="Busque por Cod./Desc."
                      name="form-field-name"
                      className="sele_pru from-control form-control-sm "  
                      value={veh_sl_t_cho}
                      
                      defaultOptions
                      getOptionLabel={e => e.Code +' - '+ e.Name}
                      getOptionValue={e => e.Code }
                      loadOptions={veh_Select2_Chofer}
                      onChange={setVehSl_t_cho}
                      // isClearable={true}
                      //openMenuOnClick
                      //isDisabled={disabled}
                    />
                  </div>
                  <div className="col-lg-1">
                    <div className="form-check form-check-danger">
                      <input className="form-check-input" type="checkbox" value="true" id="customCheckPrimary" checked={rb_estado} onChange={handleCheckboxChange} />
                      <label className="form-check-label" htmlFor="customCheckPrimary">Activo</label>
                    </div>
                  </div>
                </div>
                <h6 className="text-muted text-start">Registrar Detalle</h6>
                <div className="row ">
                  <div className="col-lg-1">Ruta:</div>
                  <div className="col-lg-4 " style={{ position: 'relative', zIndex: '2' }}>
                    <AsyncSelect
                      id="id_zona"
                      styles={styles}
                      placeholder="Busque por COD/DESC"
                      name="form-field-name"
                      className="sele_pru from-control form-control-sm" 
                      value={veh_sl_t_zona}
                      cacheOptions
                      defaultOptions
                      getOptionLabel={e => e.Code +' - '+ e.Name}
                      getOptionValue={e => e.Code}
                      loadOptions={veh_Select_Zona}
                      onChange={setVehSl_t_zona}
                      // isClearable={true}
                      // openMenuOnClick={false}
                      //isDisabled={disabled}
                    />
                  </div>
                  <div className="col-md-7 text-center ">
                    <button className="btn btn-label-youtube btn-sm" id="emp_btn_guardar" onClick={fc_btn_agregar}  >
                      <i className='bx bx-save' id="icon_emp_btn_guardar" ></i>
                      <span className="spinner-border d-none" id="spinner_emp_btn_guardar" role="status" aria-hidden="true"></span>
                      &nbsp;Guardar Ruta
                    </button>
                    <Tooltip title="Seleccionar nuevo vehiculo" placement="top">
                      <button className="btn btn-dark btn-sm" id="emp_btn_cancelar" onClick={onClickClearInputs} >
                        <i className='bx bx-history' ></i>&nbsp;
                      </button>
                    </Tooltip>
                  </div>
                </div>
                <div className="tab-content  px-0 py-0 "  >
                {/* style={{ height: '280px' }} */}
                  <div className="card-datatable table-responsive" >
                    {/* Tabla de de rutas de vehiculo */}
                    <DataTable  
                      columns={columns}
                      data={dtarray_Modaltab}
                      className="tabla-resumen"
                      Header
                      defaultSortField="id"
                      defaultSortAsc={true}
                      pagination={false}
                      responsive  
                      highlightOnHover
                      progressPending={pending}
                      noContextMenu="No hay registros para mostrar"
                      onRowClicked={handleRowClicked}
                      direction="auto"
                      fixedHeader
                      fixedHeaderScrollHeight="280px"
                      theme='solarized'
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-label-youtube btn-sm" id="btn_modal_guardar" onClick={onClickGuardar} >
                  <i className='bx bx-save' id="icon_btn_modal_guardar" ></i>
                  <span className="spinner-border d-none" id="spinner_btn_modal_guardar" role="status" aria-hidden="true"></span>
                  &nbsp;Guardar Asignación
                </button>
                <button type="button" className="btn btn-label-success btn-sm d-none" id="btn_modal_asigModificado" onClick={onClickModificarAsigancion} >
                  <i className='bx bx-save' id="icon_btn_modal_asigModificado" ></i>
                  <span className="spinner-border d-none" id="spinner_btn_modal_asigModificado" role="status" aria-hidden="true"></span>
                  &nbsp;Guardar Asignación 1
                </button>
                <button type="button" className="btn btn-label-success btn-sm d-none" id="btn_modal_asigModificado2" onClick={onClickGuardar2} >
                  <i className='bx bx-save' id="icon_btn_modal_asigModificado2" ></i>
                  <span className="spinner-border d-none" id="spinner_btn_modal_asigModificado2" role="status" aria-hidden="true"></span>
                  &nbsp;Guardar Asignación2
                </button>
                <button type="button" className="btn btn-label-secondary btn-sm" id="btn-cerrar" data-bs-dismiss="modal" onClick={onClickCloseModal}>Cerrar</button>
              </div>
            </div>
          </div>
        </div>
       
        <ToastContainer /> 
    </div>
  )
}

export default REPVisitasRepartidor