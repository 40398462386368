import React, { useRef } from 'react';
import generatePDF, { Options } from "react-to-pdf";
import { useLocation } from 'react-router-dom';
const moment = require('moment')

const options = {
  filename: "R_Liquidacion_Trasportista.pdf",
  page: {
    margin: 2
  }
};

const ReportLiquidacion = (prosp) => {
  const targetRef = useRef();
  const downloadPdf = () => generatePDF(targetRef, options);
  // const location = useLocation();
  // const { data } = location.state || {};
  // console.log("dataRecvidad", data);
//   console.log("prosp", prosp);
//   console.log("prosp.data", prosp.data);
  // Función para agrupar datos por clave
  const groupBy = (data, key) => {
    return data.reduce((result, item) => {
        const groupKey = item[key];
        if (!result[groupKey]) {
            result[groupKey] = [];
        }
        result[groupKey].push(item);
        return result;
    }, {});
  };
  const data2= prosp.dataBancos
  const groupedByCardName = groupBy(prosp.data, 'Tipo');
  console.log("groupedByCardName::", groupedByCardName);
//   Object.keys(groupedByCardName).map((cardName) => {
//     console.log("cardName:", cardName);
//     const groupedByItmsGrpNam = groupBy(groupedByCardName[cardName], 'Condicion');
//     console.log("groupedByItmsGrpNam::", groupedByItmsGrpNam);
//   })
  
  return (
    <div>
      <div className="row ">
        <div className="col-lg-11 text-end">
          <button className="btn btn-label-youtube btn-sm" style={{position: "fixed", }} onClick={downloadPdf}><i className="bx bx-xs bxs-file-pdf" ></i> Descargar PDF </button>
        </div>
      </div>
      <div ref={targetRef}>
          <h5 ><b>DISTRIBUIDORA COMERCIAL ALVAREZ BOHL</b></h5>
          <h6 ><b>LIQUIDACION TRANSPORTISTA </b></h6>
          <hr/>
          <div className="row">
            <div className="col-4">
              <h6 ><b>Fecha:</b> {prosp.fecha} </h6>
            </div>
            <div className="col-4">
              <h6 ><b>Placa:</b> {prosp.placa} </h6>
            </div>
            {/* <div className="col-4">
              <h6 ><b>Almacen:</b> {prosp.Ralmacen} </h6>
            </div> */}
          </div>
          <div className="row">
            {/* <div className="col-12">
              <h6 ><b>Dirección:</b> {prosp.Rdireccion} </h6>
            </div> */}
          </div>
          
          <div className="table-responsive text-nowrap">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Vendedor</th>
                  <th>Cliente</th>

                  <th>Ruta</th>
                  <th>Fecha  </th>
                  <th>Documento</th>
                  <th>Fecha Doc</th>
                  <th>Total</th>
                  <th>Saldo</th>
                  <th>Entrega</th>
                  <th>Pagado Hasta</th>
                  <th>Banco</th>


                  
                </tr>
              </thead>
                <tbody className="table-border-bottom-0">
                    {Object.keys(groupedByCardName).map((cardName) => {
                        let totalImporte = groupedByCardName[cardName].reduce((total, item) => {
                            return total + parseFloat(item.SumApplied);
                        }, 0);
                        totalImporte = parseFloat(totalImporte.toFixed(3));
                        let totalPagado = groupedByCardName[cardName].reduce((total, item) => {
                            return total + parseFloat(item.SumApplied) + parseFloat(item.Saldo);
                        }, 0);
                        totalPagado = parseFloat(totalPagado.toFixed(3));
                        return (
                            <>
                                <tr key={cardName}>
                                    <td colSpan="13" style={{ backgroundColor: '#d0d0d0', textAlign: 'LEFT', fontWeight: 'bold' }}>
                                        {cardName}
                                    </td>
                                </tr>
                                {groupedByCardName[cardName].map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.SlpName}</td>
                                        <td>{item.CardName}</td>
                                        <td>{item.Zona}</td>


                                        <td>{moment(item.DocDate).format('YYYY-MM-DD')}</td>
                                        <td>{item.Documento}</td>
                                        <td>{moment(item.FechaDoc).format('YYYY-MM-DD')}</td>

                                        <td>{item.DocTotal}</td>
                                        <td>{item.Saldo}</td>
                                        <td>{item.Entrega}</td>
                                        <td>{item.SumApplied}</td> 
                                        <td>{item.Nombre_Banco}</td> 
                                        
                                    </tr>
                                ))}
                                <tr key={cardName}>
                                    <td colSpan="13" style={{ backgroundColor: '#f0f0f0', textAlign: 'center', fontWeight: 'bold' }}>
                                        CANTIDAD:{groupedByCardName[cardName].length}  &nbsp;&nbsp;&nbsp;&nbsp;     TOTAL: {totalImporte} &nbsp;&nbsp;&nbsp;&nbsp;   TOTAL PAGADO: {totalPagado}
                                    </td>
                                </tr>
                            </>
                        );
                    })}

                </tbody>
            </table>
          </div>

          <div className="table-responsive text-nowrap mt-3">
            <h3 style={{  textAlign: 'center', fontWeight: 'bold' }}> Monto por Entidades </h3>
            <table className="table table-sm">
              <thead>
                  <tr style={{ backgroundColor: '#d0d0d0', textAlign: 'LEFT', fontWeight: 'bold' }}>
                    <th>Tipo</th>
                    <th>Monto</th>
                  </tr>
              </thead>
              <tbody>
                  {data2.map((banco, index) => (
                    <tr key={index} className="vervalidar">
                      <td className='td1'>{banco.Tipo}</td>
                      <td className='td2'>{banco.Pago}</td>
                    </tr>
                  ))}
                </tbody>
            </table>
          </div>
      </div>
      
    </div>
  )
}
export const ResLiquiResumenVentas = (prosp) => {
  const targetRef = useRef();
  const downloadPdf = () => generatePDF(targetRef, options);
  // const location = useLocation();
  // const { data } = location.state || {};
  // console.log("dataRecvidad", data);
//   console.log("prosp", prosp);
//   console.log("prosp.data", prosp.data);
  // Función para agrupar datos por clave
  const groupBy = (data, key) => {
    return data.reduce((result, item) => {
        const groupKey = item[key];
        if (!result[groupKey]) {
            result[groupKey] = [];
        }
        result[groupKey].push(item);
        return result;
    }, {});
  };
  const data2= prosp.dataBancos
  const groupedByCardName = groupBy(prosp.data, 'Tipo');
  console.log("groupedByCardName::", groupedByCardName);
//   Object.keys(groupedByCardName).map((cardName) => {
//     console.log("cardName:", cardName);
//     const groupedByItmsGrpNam = groupBy(groupedByCardName[cardName], 'Condicion');
//     console.log("groupedByItmsGrpNam::", groupedByItmsGrpNam);
//   })
  
  return (
    <div>
      <div className="row ">
        <div className="col-lg-11 text-end">
          <button className="btn btn-label-youtube btn-sm" style={{position: "fixed", }} onClick={downloadPdf}><i className="bx bx-xs bxs-file-pdf" ></i> Descargar PDF </button>
        </div>
      </div>
      <div ref={targetRef}>
          <h5 ><b>DISTRIBUIDORA COMERCIAL ALVAREZ BOHL</b></h5>
          <h6 ><b>LIQUIDACION TRANSPORTISTA </b></h6>
          <hr/>
          <div className="row">
            <div className="col-4">
              <h6 ><b>Fecha:</b> {prosp.fecha} </h6>
            </div>
            <div className="col-4">
              {/* <h6 ><b>Placa:</b> {prosp.placa} </h6> */}
            </div>
            {/* <div className="col-4">
              <h6 ><b>Almacen:</b> {prosp.Ralmacen} </h6>
            </div> */}
          </div>
          <div className="row">
            {/* <div className="col-12">
              <h6 ><b>Dirección:</b> {prosp.Rdireccion} </h6>
            </div> */}
          </div>
          
          <div className="table-responsive text-nowrap">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Vendedor</th>
                  <th>Cliente</th>

                  <th>Ruta</th>
                  <th>Fecha  </th>
                  <th>Documento</th>
                  <th>Fecha Doc</th>
                  <th>Total</th>
                  <th>Saldo</th>
                  <th>Entrega</th>
                  <th>Pagado Hasta</th>
                  <th>Banco</th>


                  
                </tr>
              </thead>
                <tbody className="table-border-bottom-0">
                    {Object.keys(groupedByCardName).map((cardName) => {
                        let totalImporte = groupedByCardName[cardName].reduce((total, item) => {
                            return total + parseFloat(item.SumApplied);
                        }, 0);
                        totalImporte = parseFloat(totalImporte.toFixed(3));
                        let totalPagado = groupedByCardName[cardName].reduce((total, item) => {
                            return total + parseFloat(item.SumApplied) + parseFloat(item.Saldo);
                        }, 0);
                        totalPagado = parseFloat(totalPagado.toFixed(3));
                        return (
                            <>
                                <tr key={cardName}>
                                    <td colSpan="13" style={{ backgroundColor: '#d0d0d0', textAlign: 'LEFT', fontWeight: 'bold' }}>
                                        {cardName}
                                    </td>
                                </tr>
                                {groupedByCardName[cardName].map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.SlpName}</td>
                                        <td>{item.CardName}</td>
                                        <td>{item.Zona}</td>


                                        <td>{item.DocTotal}</td>
                                        <td>{item.Saldo}</td>
                                        <td>{item.Entrega}</td>
                                        <td>{item.SumApplied}</td> 
                                        <td>{item.Nombre_Banco}</td> 
                                        
                                    </tr>
                                ))}
                                <tr key={cardName}>
                                    <td colSpan="13" style={{ backgroundColor: '#f0f0f0', textAlign: 'center', fontWeight: 'bold' }}>
                                        CANTIDAD:{groupedByCardName[cardName].length}  &nbsp;&nbsp;&nbsp;&nbsp;     TOTAL: {totalImporte} &nbsp;&nbsp;&nbsp;&nbsp;   TOTAL PAGADO: {totalPagado}
                                    </td>
                                </tr>
                            </>
                        );
                    })}

                </tbody>
            </table>
          </div>

          <div className="table-responsive text-nowrap mt-3">
            <h3 style={{  textAlign: 'center', fontWeight: 'bold' }}> Monto por Entidades </h3>
            <table className="table table-sm">
              <thead>
                  <tr style={{ backgroundColor: '#d0d0d0', textAlign: 'LEFT', fontWeight: 'bold' }}>
                    <th>Tipo</th>
                    <th>Monto</th>
                  </tr>
              </thead>
              <tbody>
                  {data2.map((banco, index) => (
                    <tr key={index} className="vervalidar">
                      <td className='td1'>{banco.Tipo}</td>
                      <td className='td2'>{banco.Pago}</td>
                    </tr>
                  ))}
                </tbody>
            </table>
          </div>
      </div>
      
    </div>
  )
}

export default ReportLiquidacion