/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { SelectSucursal, SelectTipo } from '../../components/Selects/SelectsPrincipales'
import { notify_warning, notify_valido, selectTabla, selectTablaMultiple, removeSelectedRows } from '../../components/utils';
import $ from 'jquery'
import '../../App.css';
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box, Modal, Tooltip } from '@mui/material';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { AButton } from '../../components/Button';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import ReportConsolidado from './ReportView/ReportConsolidado';
const moment = require('moment')

const globalTheme = window.templateCustomizer.settings.style;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};


export const Report1 = (prosp) => { // IMPRESION GUIA REMISION - CONSOLIDADO (COMPROBANTES)

  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [dataModal, setdataModal] = useState([]);
  const [pendingModal, setpendingModal] = useState(false);
  const [dataRowSelect, setdataRowSelect] = useState({
    ChoferNombre: '',
    VehiculoCodigo: '',
    ConsolidadoCargaID: ''
  });
  const selecionaFila = (e, rowData) => {
    const data = rowData.original;
    setopenModal(false)
    setdataRowSelect({
      ...dataRowSelect,
      ChoferNombre: '' + data.ChoferNombre,
      VehiculoCodigo: '' + data.VehiculoCodigo,
      ConsolidadoCargaID: '' + data.ConsolidadoCargaID,
      ConsolidadoCargaFecha: '' + data.ConsolidadoCargaFecha,
      ConsolidadoEstado: '' + data.ConsolidadoEstado
    });
  }

  const funBuscarConsolidado = async () => {

    try {
      setpendingModal(true);
      const response = await fetch(`/selectConsolidadoReparto?vcodEmpresa=${cook_idEmp}`, { method: "GET", headers: { 'Content-Type': 'application/json' } });
      if (!response.ok) throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
      const data = await response.json();
      console.log(data);
      setdataModal(data);
    } catch (error) {
      notify_warning('Sin datos registrados');
      console.error('Error:', error);
      // Manejar el error aquí según sea necesario.
    } finally {
      setpendingModal(false);
    }
  }
  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const handleOpen = () => {
    setopenModal(true);
    funBuscarConsolidado();

  };
  const cerrarModal = () => {
    setopenModal(false);
  }


  /** Primer reporte */
  const columnsModal = useMemo(() => [
    { header: 'RUTA', accessorKey: 'ConsolidadoCargaID' },
    { header: 'FECHA', accessorKey: 'ConsolidadoCargaFecha' },
    { header: 'ESTADO', accessorKey: 'ConsolidadoEstado' },
    { header: 'NOMBRE', accessorKey: 'ChoferNombre' },
    { header: 'PLACA', accessorKey: 'VehiculoCodigo' },

  ], [])
  const onClickListar = async () => {

    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiReparto?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&NumConsolidado=${dataRowSelect.ConsolidadoCargaID}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    console.log("data :", data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "IMPRESION GUIA REMISION - CONSOLIDADO (COMPROBANTES)_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "IMPRESION GUIA REMISION - CONSOLIDADO (COMPROBANTES)_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() => [
    { header: 'ID de Carga', accessorKey: 'ConsolidadoCargaID' },
    { header: 'Tipo de Carga', accessorKey: 'ConsolidadoCargaTipoCp' },
    { header: 'Número de Carga', accessorKey: 'ConsolidadoCargaNumero' },
    { header: 'Fecha de Carga', accessorKey: 'ConsolidadoCargaFecha' },
    { header: 'Estado de Operación', accessorKey: 'ConsolidadoEstadoOperacion' },
    { header: 'Estado', accessorKey: 'ConsolidadoEstado' },
    { header: 'Nombre del Cliente', accessorKey: 'ClienteNombre' },
    { header: 'Número de Documento del Cliente', accessorKey: 'ClienteNroDocumento' },
    { header: 'Dirección del Cliente', accessorKey: 'ClienteDireccion' },
    { header: 'Distrito del Cliente', accessorKey: 'ClienteDistrito' },
    { header: 'Código del Chofer', accessorKey: 'ChoferCodigo' },
    { header: 'Nombre del Chofer', accessorKey: 'ChoferNombre' },
    { header: 'Licencia del Chofer', accessorKey: 'ChoferLicencia' },
    { header: 'Documento de Identidad del Chofer', accessorKey: 'ChoferDocIdentidad' },
    { header: 'Código del Vehículo', accessorKey: 'VehiculoCodigo' },
    { header: 'Nombre del Vehículo', accessorKey: 'VehiculoNombre' },
    { header: 'Capacidad de Peso del Vehículo', accessorKey: 'VehiculoCapacidadPeso' },
    { header: 'Capacidad de Volumen del Vehículo', accessorKey: 'VehiculoCapacidadVolumen' },
    { header: 'Modelo del Vehículo', accessorKey: 'VehiculoModelo' },
    { header: 'Marca del Vehículo', accessorKey: 'VehiculoMarca' },
    { header: 'Placa del Vehículo', accessorKey: 'VehiculoPlaca' },
    { header: 'Certificado de Inscripción del Vehículo', accessorKey: 'VehiculoCertificadoInscripcion' },
    { header: 'Identificación del Transporte', accessorKey: 'TransporteIden' },
    { header: 'Código del Transporte', accessorKey: 'TransporteCodigo' },
    { header: 'Nombre del Transporte', accessorKey: 'TransporteNombre' },
    { header: 'Número de Comprobante', accessorKey: 'NumeroComprob' },
    { header: 'Código', accessorKey: 'Codigo' },
    { header: 'Comprobante', accessorKey: 'Comprob' },
    { header: 'Dirección de la Sucursal', accessorKey: 'DireccionSucursal' },
    { header: 'Dirección de Entrega', accessorKey: 'DireccionEntregaC' }
], []);


  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-md-1">Codigo:</div>
            <div className="col-md-2">
              <input type="text" id="txtCod" name="txtCod" value={dataRowSelect.ConsolidadoCargaID} className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-md-1">Chofer:</div>
            <div className="col-md-5">
              <input type="text" id="txtDBon" name="txtDBon" value={dataRowSelect.ChoferNombre} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
            <div className="col-md-3 d-flex justify-content-end">
              <Tooltip title="Buscar consolidado" placement="top">
                <button className="btn btn-sm  btn-lg btn-success ml-auto" onClick={handleOpen}>
                  <i class='bx bx-search-alt-2'></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
            </div>


          </div>
          <div className="row">
            <div className="col-md-1">Estado:</div>
            <div className="col-md-2">
              <input type="text" id="txtCod2" name="txtCod" value={dataRowSelect.ConsolidadoEstado} className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-md-1">Placa:</div>
            <div className="col-md-2">
              <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.VehiculoCodigo} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
            <div className="col-md-1">Fecha:</div>
            <div className="col-md-2">
              <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.ConsolidadoCargaFecha} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
          </div>
          <hr />

          <div className="row mb-2">
            <div className="col-lg-12 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>

              </Tooltip>
              {/* <Tooltip title="Consultar información" placement="top">
                <button
                  className="btn btn-sm btn-label-youtube"
                  id="btn_procesar"
                  onClick={onClickListar}
                  title="Consultar información"
                >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip> */}
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


        />
      </div>
      {/************  Modal Select ******************/}

      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '75%', height: '90%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-4" >
            <p>Pedidos asignados</p>
            <MaterialReactTable
              columns={columnsModal}
              data={dataModal}
              state={{ isLoading: pendingModal }}
              initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: '' }}
              localization={MRT_Localization_ES} primary
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              enableRowVirtualization
              muiTableContainerProps={{ sx: { maxHeight: 300 } }}
              //enableEditing

              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 100, //make actions column wider
                },
              }}
              onRowDoubleClick={selecionaFila}
              muiTableBodyRowProps={({ row, event }) => ({
                hover: false,

                onMouseDown: (event) => selecionaFila(event, row), // El  presiona el mouse 

              })}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


            />
          </div>

        </Box>
      </Modal>
    </>
  )
}
export const Report2 = (prosp) => { // IMPRESION GUIA REMISION . CONSOLIDADO (PRODUCTOS) 
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [dataModal, setdataModal] = useState([]);
  const [pendingModal, setpendingModal] = useState(false);
  const [dataRowSelect, setdataRowSelect] = useState({
    ChoferNombre: '',
    VehiculoCodigo: '',
    ConsolidadoCargaID: ''
  });
  const selecionaFila = (e, rowData) => {
    const data = rowData.original;
    setopenModal(false)
    setdataRowSelect({
      ...dataRowSelect,
      ChoferNombre: '' + data.ChoferNombre,
      VehiculoCodigo: '' + data.VehiculoCodigo,
      ConsolidadoCargaID: '' + data.ConsolidadoCargaID,
      ConsolidadoCargaFecha: '' + data.ConsolidadoCargaFecha,
      ConsolidadoEstado: '' + data.ConsolidadoEstado
    });
  }

  const funBuscarConsolidado = async () => {

    try {
      setpendingModal(true);
      const response = await fetch(`/selectConsolidadoReparto?vcodEmpresa=${cook_idEmp}`, { method: "GET", headers: { 'Content-Type': 'application/json' } });
      if (!response.ok) throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
      const data = await response.json();
      console.log(data);
      setdataModal(data);
    } catch (error) {
      notify_warning('Sin datos registrados');
      console.error('Error:', error);
      // Manejar el error aquí según sea necesario.
    } finally {
      setpendingModal(false);
    }
  }
  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const handleOpen = () => {
    setopenModal(true);
    funBuscarConsolidado();

  };
  const cerrarModal = () => {
    setopenModal(false);
  }


  /** Primer reporte */
  const columnsModal = useMemo(() => [
    { header: 'RUTA', accessorKey: 'ConsolidadoCargaID' },
    { header: 'FECHA', accessorKey: 'ConsolidadoCargaFecha' },
    { header: 'ESTADO', accessorKey: 'ConsolidadoEstado' },
    { header: 'NOMBRE', accessorKey: 'ChoferNombre' },
    { header: 'PLACA', accessorKey: 'VehiculoCodigo' },

  ], [])
  const onClickListar = async () => {

    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiReparto?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&NumConsolidado=${dataRowSelect.ConsolidadoCargaID}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    console.log("data :", data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "IMPRESION GUIA REMISION . CONSOLIDADO (PRODUCTOS)_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "IMPRESION GUIA REMISION . CONSOLIDADO (PRODUCTOS)_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() =>[
    { header: 'ID de Consolidado de Carga', accessorKey: 'ConsolidadoCargaID' },
    { header: 'Tipo de Comprobante del Consolidado de Carga', accessorKey: 'ConsolidadoCargaTipoCp' },
    { header: 'Número de Consolidado de Carga', accessorKey: 'ConsolidadoCargaNumero' },
    { header: 'Fecha de Consolidado de Carga', accessorKey: 'ConsolidadoCargaFecha' },
    { header: 'Estado de Operación del Consolidado', accessorKey: 'ConsolidadoEstadoOperacion' },
    { header: 'Estado del Consolidado', accessorKey: 'ConsolidadoEstado' },
    { header: 'Nombre del Cliente', accessorKey: 'ClienteNombre' },
    { header: 'Número de Documento del Cliente', accessorKey: 'ClienteNroDocumento' },
    { header: 'Dirección del Cliente', accessorKey: 'ClienteDireccion' },
    { header: 'Distrito del Cliente', accessorKey: 'ClienteDistrito' },
    { header: 'Código del Chofer', accessorKey: 'ChoferCodigo' },
    { header: 'Nombre del Chofer', accessorKey: 'ChoferNombre' },
    { header: 'Licencia del Chofer', accessorKey: 'ChoferLicencia' },
    { header: 'Documento de Identidad del Chofer', accessorKey: 'ChoferDocIdentidad' },
    { header: 'Código del Vehículo', accessorKey: 'VehiculoCodigo' },
    { header: 'Nombre del Vehículo', accessorKey: 'VehiculoNombre' },
    { header: 'Capacidad de Peso del Vehículo', accessorKey: 'VehiculoCapacidadPeso' },
    { header: 'Capacidad de Volumen del Vehículo', accessorKey: 'VehiculoCapacidadVolumen' },
    { header: 'Modelo del Vehículo', accessorKey: 'VehiculoModelo' },
    { header: 'Marca del Vehículo', accessorKey: 'VehiculoMarca' },
    { header: 'Placa del Vehículo', accessorKey: 'VehiculoPlaca' },
    { header: 'Certificado de Inscripción del Vehículo', accessorKey: 'VehiculoCertificadoInscripcion' },
    { header: 'Código de Almacén Detalle', accessorKey: 'DetalleAlmacenCodigo' },
    { header: 'Nombre de Almacén Detalle', accessorKey: 'DetalleAlmacenNombre' },
    { header: 'Dirección de Almacén Detalle', accessorKey: 'DetalleAlmacenDireccion' },
    { header: 'Código de Producto Detalle', accessorKey: 'DetalleProductoCodigo' },
    { header: 'Nombre de Producto Detalle', accessorKey: 'DetalleProductoNombre' },
    { header: 'Unidad de Producto', accessorKey: 'UnidadProducto' },
    { header: 'Peso del Producto', accessorKey: 'PesoProducto' },
    { header: 'Volumen del Producto', accessorKey: 'VolumenProducto' },
    { header: 'Unidad Base de Detalle', accessorKey: 'DetalleUnidadBase' },
    { header: 'Factor de Unidad Base de Detalle', accessorKey: 'DetalleUnidadBaseFactor' },
    { header: 'Peso de Unidad Base de Detalle', accessorKey: 'DetalleUnidadBasePeso' },
    { header: 'Volumen de Unidad Base de Detalle', accessorKey: 'DetalleUnidadBaseVolumen' },
    { header: 'Cantidad de Producto Detalle', accessorKey: 'DetalleProductoCantidad' },
    { header: 'Unidad de Referencia de Detalle', accessorKey: 'DetalleUnidadRef' },
    { header: 'Factor de Unidad de Referencia de Detalle', accessorKey: 'DetalleUnidadRefFactor' },
    { header: 'Peso de Unidad de Referencia de Detalle', accessorKey: 'DetalleUnidadRefPeso' },
    { header: 'Volumen de Unidad de Referencia de Detalle', accessorKey: 'DetalleUnidadRefVolumen' },
    { header: 'Identificación de Transporte', accessorKey: 'TransporteIden' },
    { header: 'Código de Transporte', accessorKey: 'TransporteCodigo' },
    { header: 'Nombre de Transporte', accessorKey: 'TransporteNombre' },
    { header: 'Valor Unitario del Producto', accessorKey: 'ProductoValorUnitario' },
    { header: 'Total del Producto', accessorKey: 'ProductoTotal' },
    { header: 'Cantidad de Producto', accessorKey: 'CantidadProducto' },
    { header: 'Número de Comprobante', accessorKey: 'NumeroComprob' }
], []);


  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-md-1">Codigo:</div>
            <div className="col-md-2">
              <input type="text" id="txtCod" name="txtCod" value={dataRowSelect.ConsolidadoCargaID} className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-md-1">Chofer:</div>
            <div className="col-md-5">
              <input type="text" id="txtDBon" name="txtDBon" value={dataRowSelect.ChoferNombre} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
            <div className="col-md-3 d-flex justify-content-end">
              <Tooltip title="Buscar consolidado" placement="top">
                <button className="btn btn-sm  btn-lg btn-success ml-auto" onClick={handleOpen}>
                  <i class='bx bx-search-alt-2'></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
            </div>


          </div>
          <div className="row">
            <div className="col-md-1">Estado:</div>
            <div className="col-md-2">
              <input type="text" id="txtCod2" name="txtCod" value={dataRowSelect.ConsolidadoEstado} className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-md-1">Placa:</div>
            <div className="col-md-2">
              <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.VehiculoCodigo} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
            <div className="col-md-1">Fecha:</div>
            <div className="col-md-2">
              <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.ConsolidadoCargaFecha} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
          </div>
          <hr />

          <div className="row mb-2">
            <div className="col-lg-12 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>

              </Tooltip>
              {/* <Tooltip title="Consultar información" placement="top">
                <button
                  className="btn btn-sm btn-label-youtube"
                  id="btn_procesar"
                  onClick={onClickListar}
                  title="Consultar información"
                >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip> */}
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


        />
      </div>
      {/************  Modal Select ******************/}

      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '75%', height: '90%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-4" >
            <p>Pedidos asignados</p>
            <MaterialReactTable
              columns={columnsModal}
              data={dataModal}
              state={{ isLoading: pendingModal }}
              initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: '' }}
              localization={MRT_Localization_ES} primary
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              enableRowVirtualization
              muiTableContainerProps={{ sx: { maxHeight: 300 } }}
              //enableEditing

              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 100, //make actions column wider
                },
              }}
              onRowDoubleClick={selecionaFila}
              muiTableBodyRowProps={({ row, event }) => ({
                hover: false,

                onMouseDown: (event) => selecionaFila(event, row), // El  presiona el mouse 

              })}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


            />
          </div>

        </Box>
      </Modal>
    </>
  )
}
export const Report3 = (prosp) => { // IMPRESION GUIA DE REMISION ELECTRONICA 
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [dataModal, setdataModal] = useState([]);
  const [pendingModal, setpendingModal] = useState(false);
  const [dataRowSelect, setdataRowSelect] = useState({
    ChoferNombre: '',
    VehiculoCodigo: '',
    ConsolidadoCargaID: ''
  });
  const selecionaFila = (e, rowData) => {
    const data = rowData.original;
    setopenModal(false)
    setdataRowSelect({
      ...dataRowSelect,
      ChoferNombre: '' + data.ChoferNombre,
      VehiculoCodigo: '' + data.VehiculoCodigo,
      ConsolidadoCargaID: '' + data.ConsolidadoCargaID,
      ConsolidadoCargaFecha: '' + data.ConsolidadoCargaFecha,
      ConsolidadoEstado: '' + data.ConsolidadoEstado
    });
  }

  const funBuscarConsolidado = async () => {

    try {
      setpendingModal(true);
      const response = await fetch(`/selectConsolidadoReparto?vcodEmpresa=${cook_idEmp}`, { method: "GET", headers: { 'Content-Type': 'application/json' } });
      if (!response.ok) throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
      const data = await response.json();
      console.log(data);
      setdataModal(data);
    } catch (error) {
      notify_warning('Sin datos registrados');
      console.error('Error:', error);
      // Manejar el error aquí según sea necesario.
    } finally {
      setpendingModal(false);
    }
  }
  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const handleOpen = () => {
    setopenModal(true);
    funBuscarConsolidado();

  };
  const cerrarModal = () => {
    setopenModal(false);
  }


  /** Primer reporte */
  const columnsModal = useMemo(() => [
    { header: 'RUTA', accessorKey: 'ConsolidadoCargaID' },
    { header: 'FECHA', accessorKey: 'ConsolidadoCargaFecha' },
    { header: 'ESTADO', accessorKey: 'ConsolidadoEstado' },
    { header: 'NOMBRE', accessorKey: 'ChoferNombre' },
    { header: 'PLACA', accessorKey: 'VehiculoCodigo' },

  ], [])
  const onClickListar = async () => {

    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiReparto?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&NumConsolidado=${dataRowSelect.ConsolidadoCargaID}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    console.log("data :", data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "IMPRESION GUIA DE REMISION ELECTRONICA_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "IMPRESION GUIA DE REMISION ELECTRONICA_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() => [
    { header: 'ID de Consolidado de Carga', accessorKey: 'IDCp' },
    { header: 'ID de Consolidado de Carga', accessorKey: 'ConsolidadoCargaID' },
    { header: 'Tipo de Comprobante del Consolidado de Carga', accessorKey: 'ConsolidadoCargaTipoCp' },
    { header: 'Fecha', accessorKey: 'Fecha' },
    { header: 'Número de Comprobante', accessorKey: 'NumeroComprob' },
    { header: 'Punto de Partida', accessorKey: 'PuntoPartida' },
    { header: 'Transportista', accessorKey: 'Transportita' },
    { header: 'RUC del Transportista', accessorKey: 'TransportistaRUC' },
    { header: 'Código del Vehículo', accessorKey: 'VehiculoCodigo' },
    { header: 'Marca del Vehículo', accessorKey: 'Marca' },
    { header: 'Placa del Vehículo', accessorKey: 'VehiculoPlaca' },
    { header: 'Certificado del Vehículo', accessorKey: 'VehiculoCertificado' },
    { header: 'Nombre del Chofer', accessorKey: 'ChoferNombre' },
    { header: 'Documento de Identidad del Chofer', accessorKey: 'ChoferDocIdentidad' },
    { header: 'Tipo de Venta', accessorKey: 'CpVentaTipo' },
    { header: 'Número de Venta', accessorKey: 'CpVentaNumero' },
    { header: 'Licencia del Chofer', accessorKey: 'ChoferLicencia' },
    { header: 'Distrito', accessorKey: 'Distrito' },
    { header: 'Provincia', accessorKey: 'Provincia' },
    { header: 'Departamento', accessorKey: 'Departamento' },
    { header: 'Dirección de Entrega de la Venta', accessorKey: 'CpVentaDireccionEntrega' },
    { header: 'Código de Producto Detalle', accessorKey: 'DetalleProductoCodigo' },
    { header: 'Nombre de Producto Detalle', accessorKey: 'DetalleProductoNombre' },
    { header: 'Unidad de Producto', accessorKey: 'UnidadProducto' },
    { header: 'Factor', accessorKey: 'Factor' },
    { header: 'Peso del Producto', accessorKey: 'PesoProducto' },
    { header: 'Nombre del Cliente', accessorKey: 'ClienteNombre' },
    { header: 'Número de Documento del Cliente', accessorKey: 'ClienteNroDocumento' },
    { header: 'Cantidad de Producto', accessorKey: 'CantidadProducto' },
    { header: 'Razón Social del Emisor', accessorKey: 'RazonSocialEmisor' },
    { header: 'RUC del Emisor', accessorKey: 'RUCEmisor' },
    { header: 'Calle del Emisor', accessorKey: 'CalleEmisor' },
    { header: 'Distrito del Emisor', accessorKey: 'DistritoEmisor' },
    { header: 'Provincia del Emisor', accessorKey: 'ProvinciaEmisor' },
    { header: 'Departamento del Emisor', accessorKey: 'DepartamentoEmisor' },
    { header: 'Teléfono del Emisor', accessorKey: 'TelefonoEmisor' },
    { header: 'Sitio Web del Emisor', accessorKey: 'WebEmisor' },
    { header: 'Urbanización del Emisor', accessorKey: 'UrbanizacionEmisor' },
    { header: 'Nombre Comercial del Emisor', accessorKey: 'NombreComercialEmisor' },
    { header: 'Número de Cuenta de Detracción', accessorKey: 'NroCuentaDetraccion' },
    { header: 'Código de Sucursal', accessorKey: 'SucursalCodigo' },
    { header: 'Dirección de Sucursal', accessorKey: 'SucursalDireccion' },
    { header: 'Distrito de Sucursal', accessorKey: 'SucursalDistrito' },
    { header: 'Provincia de Sucursal', accessorKey: 'SucursalProvincia' },
    { header: 'Departamento de Sucursal', accessorKey: 'SucursalDepartamento' },
    { header: 'Números', accessorKey: 'Numeros' },
    { header: 'Bonificación', accessorKey: 'ItemEsBonificacion' }
], []);


  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-md-1">Codigo:</div>
            <div className="col-md-2">
              <input type="text" id="txtCod" name="txtCod" value={dataRowSelect.ConsolidadoCargaID} className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-md-1">Chofer:</div>
            <div className="col-md-5">
              <input type="text" id="txtDBon" name="txtDBon" value={dataRowSelect.ChoferNombre} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
            <div className="col-md-3 d-flex justify-content-end">
              <Tooltip title="Buscar consolidado" placement="top">
                <button className="btn btn-sm  btn-lg btn-success ml-auto" onClick={handleOpen}>
                  <i class='bx bx-search-alt-2'></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
            </div>


          </div>
          <div className="row">
            <div className="col-md-1">Estado:</div>
            <div className="col-md-2">
              <input type="text" id="txtCod2" name="txtCod" value={dataRowSelect.ConsolidadoEstado} className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-md-1">Placa:</div>
            <div className="col-md-2">
              <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.VehiculoCodigo} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
            <div className="col-md-1">Fecha:</div>
            <div className="col-md-2">
              <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.ConsolidadoCargaFecha} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
          </div>
          <hr />

          <div className="row mb-2">
            <div className="col-lg-12 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>

              </Tooltip>
              {/* <Tooltip title="Consultar información" placement="top">
                <button
                  className="btn btn-sm btn-label-youtube"
                  id="btn_procesar"
                  onClick={onClickListar}
                  title="Consultar información"
                >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip> */}
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


        />
      </div>
      {/************  Modal Select ******************/}

      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '75%', height: '90%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-4" >
            <p>Pedidos asignados</p>
            <MaterialReactTable
              columns={columnsModal}
              data={dataModal}
              state={{ isLoading: pendingModal }}
              initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: '' }}
              localization={MRT_Localization_ES} primary
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              enableRowVirtualization
              muiTableContainerProps={{ sx: { maxHeight: 300 } }}
              //enableEditing

              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 100, //make actions column wider
                },
              }}
              onRowDoubleClick={selecionaFila}
              muiTableBodyRowProps={({ row, event }) => ({
                hover: false,

                onMouseDown: (event) => selecionaFila(event, row), // El  presiona el mouse 

              })}

              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}

            />
          </div>

        </Box>
      </Modal>
    </>
  )
}
export const Report4 = (prosp) => { //  IMPRESION GUIA REMISION • TRANSPORTISTA 
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [dataModal, setdataModal] = useState([]);
  const [pendingModal, setpendingModal] = useState(false);
  const [dataRowSelect, setdataRowSelect] = useState({
    ChoferNombre: '',
    VehiculoCodigo: '',
    ConsolidadoCargaID: ''
  });
  const selecionaFila = (e, rowData) => {
    const data = rowData.original;
    setopenModal(false)
    setdataRowSelect({
      ...dataRowSelect,
      ChoferNombre: '' + data.ChoferNombre,
      VehiculoCodigo: '' + data.VehiculoCodigo,
      ConsolidadoCargaID: '' + data.ConsolidadoCargaID,
      ConsolidadoCargaFecha: '' + data.ConsolidadoCargaFecha,
      ConsolidadoEstado: '' + data.ConsolidadoEstado
    });
  }

  const funBuscarConsolidado = async () => {

    try {
      setpendingModal(true);
      const response = await fetch(`/selectConsolidadoReparto?vcodEmpresa=${cook_idEmp}`, { method: "GET", headers: { 'Content-Type': 'application/json' } });
      if (!response.ok) throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
      const data = await response.json();
      console.log(data);
      setdataModal(data);
    } catch (error) {
      notify_warning('Sin datos registrados');
      console.error('Error:', error);
      // Manejar el error aquí según sea necesario.
    } finally {
      setpendingModal(false);
    }
  }
  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const handleOpen = () => {
    setopenModal(true);
    funBuscarConsolidado();

  };
  const cerrarModal = () => {
    setopenModal(false);
  }


  /** Primer reporte */
  const columnsModal = useMemo(() => [
    { header: 'RUTA', accessorKey: 'ConsolidadoCargaID' },
    { header: 'FECHA', accessorKey: 'ConsolidadoCargaFecha' },
    { header: 'ESTADO', accessorKey: 'ConsolidadoEstado' },
    { header: 'NOMBRE', accessorKey: 'ChoferNombre' },
    { header: 'PLACA', accessorKey: 'VehiculoCodigo' },

  ], [])
  const onClickListar = async () => {

    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiReparto?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&NumConsolidado=${dataRowSelect.ConsolidadoCargaID}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    console.log("data :", data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
  }
  

  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "IMPRESION GUIA REMISION • TRANSPORTISTA_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "IMPRESION GUIA REMISION • TRANSPORTISTA_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() => [
    { header: 'ID de Carga', accessorKey: 'ConsolidadoCargaID' },
    { header: 'Tipo de Carga', accessorKey: 'ConsolidadoCargaTipoCp' },
    { header: 'Número de Carga', accessorKey: 'ConsolidadoCargaNumero' },
    { header: 'Fecha de Carga', accessorKey: 'ConsolidadoCargaFecha' },
    { header: 'Estado de Operación', accessorKey: 'ConsolidadoEstadoOperacion' },
    { header: 'Estado de Carga', accessorKey: 'ConsolidadoEstado' },
    { header: 'Nombre del Cliente', accessorKey: 'ClienteNombre' },
    { header: 'Número de Documento del Cliente', accessorKey: 'ClienteNroDocumento' },
    { header: 'Dirección del Cliente', accessorKey: 'ClienteDireccion' },
    { header: 'Distrito del Cliente', accessorKey: 'ClienteDistrito' },
    { header: 'Código de Chofer', accessorKey: 'ChoferCodigo' },
    { header: 'Nombre del Chofer', accessorKey: 'ChoferNombre' },
    { header: 'Licencia del Chofer', accessorKey: 'ChoferLicencia' },
    { header: 'Documento de Identidad del Chofer', accessorKey: 'ChoferDocIdentidad' },
    { header: 'Código de Vehículo', accessorKey: 'VehiculoCodigo' },
    { header: 'Nombre de Vehículo', accessorKey: 'VehiculoNombre' },
    { header: 'Capacidad de Peso del Vehículo', accessorKey: 'VehiculoCapacidadPeso' },
    { header: 'Capacidad de Volumen del Vehículo', accessorKey: 'VehiculoCapacidadVolumen' },
    { header: 'Modelo del Vehículo', accessorKey: 'VehiculoModelo' },
    { header: 'Marca del Vehículo', accessorKey: 'VehiculoMarca' },
    { header: 'Placa del Vehículo', accessorKey: 'VehiculoPlaca' },
    { header: 'Certificado de Inscripción del Vehículo', accessorKey: 'VehiculoCertificadoInscripcion' },
    { header: 'Identificación del Transporte', accessorKey: 'TransporteIden' },
    { header: 'Código de Transporte', accessorKey: 'TransporteCodigo' },
    { header: 'Nombre del Transporte', accessorKey: 'TransporteNombre' },
    { header: 'Número de Comprobante', accessorKey: 'NumeroComprob' },
    { header: 'Dirección de la Sucursal', accessorKey: 'DireccionSucursal' },
    { header: 'Dirección de Entrega del Cliente', accessorKey: 'DireccionEntregaC' },
    { header: 'Tipo de Documento de Identidad', accessorKey: 'TipoDocIdentidad' },
    { header: 'Peso del Comprobante de Venta', accessorKey: 'PesoComprobanteVenta' },
    { header: 'Número de Guía del Comprobante', accessorKey: 'GuiaNumComprobante' },
    { header: 'Fecha de Emisión del Comprobante', accessorKey: 'FechaEmisionComprobantes' }
  ]
    , []);


  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-md-1">Codigo:</div>
            <div className="col-md-2">
              <input type="text" id="txtCod" name="txtCod" value={dataRowSelect.ConsolidadoCargaID} className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-md-1">Chofer:</div>
            <div className="col-md-5">
              <input type="text" id="txtDBon" name="txtDBon" value={dataRowSelect.ChoferNombre} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
            <div className="col-md-3 d-flex justify-content-end">
              <Tooltip title="Buscar consolidado" placement="top">
                <button className="btn btn-sm  btn-lg btn-success ml-auto" onClick={handleOpen}>
                  <i class='bx bx-search-alt-2'></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
            </div>


          </div>
          <div className="row">
            <div className="col-md-1">Estado:</div>
            <div className="col-md-2">
              <input type="text" id="txtCod2" name="txtCod" value={dataRowSelect.ConsolidadoEstado} className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-md-1">Placa:</div>
            <div className="col-md-2">
              <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.VehiculoCodigo} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
            <div className="col-md-1">Fecha:</div>
            <div className="col-md-2">
              <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.ConsolidadoCargaFecha} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
          </div>
          <hr />

          <div className="row mb-2">
            <div className="col-lg-12 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>

              </Tooltip>
              {/* <Tooltip title="Consultar información" placement="top">
                <button
                  className="btn btn-sm btn-label-youtube"
                  id="btn_procesar"
                  onClick={onClickListar}
                  title="Consultar información"
                >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip> */}
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


        />
      </div>
      {/************  Modal Select ******************/}

      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '75%', height: '90%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-4" >
            <p>Pedidos asignados</p>
            <MaterialReactTable
              columns={columnsModal}
              data={dataModal}
              state={{ isLoading: pendingModal }}
              initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: '' }}
              localization={MRT_Localization_ES} primary
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              enableRowVirtualization
              muiTableContainerProps={{ sx: { maxHeight: 300 } }}
              //enableEditing

              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 100, //make actions column wider
                },
              }}
              onRowDoubleClick={selecionaFila}
              muiTableBodyRowProps={({ row, event }) => ({
                hover: false,

                onMouseDown: (event) => selecionaFila(event, row), // El  presiona el mouse 

              })}

              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}

            />
          </div>

        </Box>
      </Modal>
    </>
  )
}
export const Report5 = (prosp) => {// ORDEN DE SALIDA-REPARTO
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [dataModal, setdataModal] = useState([]);
  const [pendingModal, setpendingModal] = useState(false);
  const [dataRowSelect, setdataRowSelect] = useState({
    ChoferNombre: '',
    VehiculoCodigo: '',
    ConsolidadoCargaID: ''
  });
  const selecionaFila = (e, rowData) => {
    const data = rowData.original;
    setopenModal(false)
    setdataRowSelect({
      ...dataRowSelect,
      ChoferNombre: '' + data.ChoferNombre,
      VehiculoCodigo: '' + data.VehiculoCodigo,
      ConsolidadoCargaID: '' + data.ConsolidadoCargaID,
      ConsolidadoCargaFecha: '' + data.ConsolidadoCargaFecha,
      ConsolidadoEstado: '' + data.ConsolidadoEstado
    });
  }

  const funBuscarConsolidado = async () => {

    try {
      setpendingModal(true);
      const response = await fetch(`/selectConsolidadoReparto?vcodEmpresa=${cook_idEmp}`, { method: "GET", headers: { 'Content-Type': 'application/json' } });
      if (!response.ok) throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
      const data = await response.json();
      console.log(data);
      setdataModal(data);
    } catch (error) {
      notify_warning('Sin datos registrados');
      console.error('Error:', error);
      // Manejar el error aquí según sea necesario.
    } finally {
      setpendingModal(false);
    }
  }
  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const handleOpen = () => {
    setopenModal(true);
    funBuscarConsolidado();

  };
  const cerrarModal = () => {
    setopenModal(false);
  }


  /** Primer reporte */
  const columnsModal = useMemo(() => [
    { header: 'RUTA', accessorKey: 'ConsolidadoCargaID' },
    { header: 'FECHA', accessorKey: 'ConsolidadoCargaFecha' },
    { header: 'ESTADO', accessorKey: 'ConsolidadoEstado' },
    { header: 'NOMBRE', accessorKey: 'ChoferNombre' },
    { header: 'PLACA', accessorKey: 'VehiculoCodigo' },

  ], [])
  const onClickListar = async () => {

    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiReparto?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&NumConsolidado=${dataRowSelect.ConsolidadoCargaID}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    console.log("data :", data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "ORDEN DE SALIDA-REPARTO_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "ORDEN DE SALIDA-REPARTO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() =>  [
    { header: 'ID de Carga', accessorKey: 'ConsolidadoCargaID' },
    { header: 'Número de Carga', accessorKey: 'ConsolidadoCargaNumero' },
    { header: 'Fecha de Carga', accessorKey: 'ConsolidadoCargaFecha' },
    { header: 'Nombre del Chofer', accessorKey: 'ChoferNombre' },
    { header: 'Modelo del Vehículo', accessorKey: 'VehiculoModelo' },
    { header: 'Marca del Vehículo', accessorKey: 'VehiculoMarca' },
    { header: 'Placa del Vehículo', accessorKey: 'VehiculoPlaca' },
    { header: 'Nombre del Producto', accessorKey: 'DetalleProductoNombre' },
    { header: 'Cantidad de Producto', accessorKey: 'DetalleProductoCantidad' },
    { header: 'Total del Producto', accessorKey: 'ProductoTotal' },
    { header: 'Código del Producto', accessorKey: 'DetalleProductoCodigo' },
    { header: 'Cantidad de Producto Vendido', accessorKey: 'CantidadProducto' },
    { header: 'Número de Guía del Comprobante', accessorKey: 'GuiaNumComprobante' },
    { header: 'Nombre de la Persona', accessorKey: 'PersonaNombre' },
    { header: 'Descripción', accessorKey: 'Descripcion' },
    { header: 'Dirección del Cliente', accessorKey: 'ClienteDireccion' },
    { header: 'Zona', accessorKey: 'Zona' },
    { header: 'Código', accessorKey: 'Codigo' },
    { header: 'Teléfono', accessorKey: 'Telefono' },
    { header: 'Bonificación', accessorKey: 'Bonificacion' },
    { header: 'Orden', accessorKey: 'Orden' }
] , []);


  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-md-1">Codigo:</div>
            <div className="col-md-2">
              <input type="text" id="txtCod" name="txtCod" value={dataRowSelect.ConsolidadoCargaID} className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-md-1">Chofer:</div>
            <div className="col-md-5">
              <input type="text" id="txtDBon" name="txtDBon" value={dataRowSelect.ChoferNombre} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
            <div className="col-md-3 d-flex justify-content-end">
              <Tooltip title="Buscar consolidado" placement="top">
                <button className="btn btn-sm  btn-lg btn-success ml-auto" onClick={handleOpen}>
                  <i class='bx bx-search-alt-2'></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
            </div>


          </div>
          <div className="row">
            <div className="col-md-1">Estado:</div>
            <div className="col-md-2">
              <input type="text" id="txtCod2" name="txtCod" value={dataRowSelect.ConsolidadoEstado} className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-md-1">Placa:</div>
            <div className="col-md-2">
              <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.VehiculoCodigo} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
            <div className="col-md-1">Fecha:</div>
            <div className="col-md-2">
              <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.ConsolidadoCargaFecha} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
          </div>
          <hr />

          <div className="row mb-2">
            <div className="col-lg-12 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>

              </Tooltip>
              {/* <Tooltip title="Consultar información" placement="top">
                <button
                  className="btn btn-sm btn-label-youtube"
                  id="btn_procesar"
                  onClick={onClickListar}
                  title="Consultar información"
                >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip> */}
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


        />
      </div>
      {/************  Modal Select ******************/}

      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '75%', height: '90%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-4" >
            <p>Pedidos asignados</p>
            <MaterialReactTable
              columns={columnsModal}
              data={dataModal}
              state={{ isLoading: pendingModal }}
              initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: '' }}
              localization={MRT_Localization_ES} primary
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              enableRowVirtualization
              muiTableContainerProps={{ sx: { maxHeight: 300 } }}
              //enableEditing

              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 100, //make actions column wider
                },
              }}
              onRowDoubleClick={selecionaFila}
              muiTableBodyRowProps={({ row, event }) => ({
                hover: false,

                onMouseDown: (event) => selecionaFila(event, row), // El  presiona el mouse 

              })}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


            />
          </div>

        </Box>
      </Modal>
    </>
  )
}
export const Report6 = (prosp) => {//SALIDA DE PRODUCTOS PARA REPARTO POR CONSOLIDADO
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [dataModal, setdataModal] = useState([]);
  const [pendingModal, setpendingModal] = useState(false);
  const [dataRowSelect, setdataRowSelect] = useState({
    ChoferNombre: '',
    VehiculoCodigo: '',
    ConsolidadoCargaID: ''
  });
  const selecionaFila = (e, rowData) => {
    const data = rowData.original;
    setopenModal(false)
    setdataRowSelect({
      ...dataRowSelect,
      ChoferNombre: '' + data.ChoferNombre,
      VehiculoCodigo: '' + data.VehiculoCodigo,
      ConsolidadoCargaID: '' + data.ConsolidadoCargaID,
      ConsolidadoCargaFecha:''+data.ConsolidadoCargaFecha,
      ConsolidadoEstado:''+data.ConsolidadoEstado
    });
  }

  const funBuscarConsolidado = async () => {
  
    try {
      setpendingModal(true);
      const response = await fetch(`/selectConsolidadoReparto?vcodEmpresa=${cook_idEmp}`, { method: "GET", headers: { 'Content-Type': 'application/json' } });
      if (!response.ok) throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
      const data = await response.json();
      console.log(data);
      setdataModal(data);
    } catch (error) {
      notify_warning('Sin datos registrados');
      console.error('Error:', error);
      // Manejar el error aquí según sea necesario.
    } finally {
      setpendingModal(false);
    }
  }
  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const handleOpen = () => {
    setopenModal(true);
    funBuscarConsolidado();

  };
  const cerrarModal = () => {
    setopenModal(false);
  }
  
 
  /** Primer reporte */
  const columnsModal = useMemo(() => [
    { header: 'RUTA', accessorKey: 'ConsolidadoCargaID' },
    { header: 'FECHA', accessorKey: 'ConsolidadoCargaFecha' },
    { header: 'ESTADO', accessorKey: 'ConsolidadoEstado' },
    { header: 'NOMBRE', accessorKey: 'ChoferNombre' },
    { header: 'PLACA', accessorKey: 'VehiculoCodigo' },

  ], [])
  const onClickListar = async () => {
    
    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiReparto?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&NumConsolidado=${dataRowSelect.ConsolidadoCargaID}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    console.log("data :",data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "SALIDA DE PRODUCTOS PARA REPARTO POR CONSOLIDADO_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "SALIDA DE PRODUCTOS PARA REPARTO POR CONSOLIDADO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() =>[
    { header: 'ID de Carga', accessorKey: 'ConsolidadoCargaID' },
    { header: 'Número de Carga', accessorKey: 'ConsolidadoCargaNumero' },
    { header: 'Fecha de Carga', accessorKey: 'ConsolidadoCargaFecha' },
    { header: 'Estado', accessorKey: 'Estado' },
    { header: 'Código del Chofer', accessorKey: 'ChoferCodigo' },
    { header: 'Nombre del Chofer', accessorKey: 'ChoferNombre' },
    { header: 'Código de Vehículo', accessorKey: 'VehiculoCodigo' },
    { header: 'Nombre de Vehículo', accessorKey: 'VehiculoNombre' },
    { header: 'Capacidad de Peso del Vehículo', accessorKey: 'VehiculoCapacidadPeso' },
    { header: 'Capacidad de Volumen del Vehículo', accessorKey: 'VehiculoCapacidadVolumen' },
    { header: 'Licencia del Chofer', accessorKey: 'ChoferLicencia' },
    { header: 'Modelo del Vehículo', accessorKey: 'VehiculoModelo' },
    { header: 'Marca del Vehículo', accessorKey: 'VehiculoMarca' },
    { header: 'Placa del Vehículo', accessorKey: 'VehiculoPlaca' },
    { header: 'Código del Almacén', accessorKey: 'DetalleAlmacenCodigo' },
    { header: 'Nombre del Almacén', accessorKey: 'DetalleAlmacenNombre' },
    { header: 'Dirección del Almacén', accessorKey: 'DetalleAlmacenDireccion' },
    { header: 'Código del Producto', accessorKey: 'DetalleProductoCodigo' },
    { header: 'Nombre del Producto', accessorKey: 'DetalleProductoNombre' },
    { header: 'Unidad de Compra', accessorKey: 'DetalleUnidadCompra' },
    { header: 'Factor de Unidad de Compra', accessorKey: 'DetallleUnidadCompraFactor' },
    { header: 'Unidad de Referencia', accessorKey: 'DetalleUnidadRef' },
    { header: 'Factor de Unidad de Referencia', accessorKey: 'DetalleUnidadRefFactor' },
    { header: 'Unidad de Base', accessorKey: 'DetalleUnidadBase' },
    { header: 'Factor de Unidad de Base', accessorKey: 'DetalleUnidadBaseFactor' },
    { header: 'Cantidad de Compra', accessorKey: 'CantCompra' },
    { header: 'Cantidad de Referencia', accessorKey: 'CantRef' },
    { header: 'Cantidad de Base', accessorKey: 'CantBase' },
    { header: 'Cantidad de Producto', accessorKey: 'DetalleProductoCantidad' },
    { header: 'Peso de Unidad de Base', accessorKey: 'DetalleUnidadBasePeso' },
    { header: 'Volumen de Unidad de Base', accessorKey: 'DetalleUnidadBaseVolumen' },
    { header: 'Peso de Unidad de Referencia', accessorKey: 'DetalleUnidadRefPeso' },
    { header: 'Volumen de Unidad de Referencia', accessorKey: 'DetalleUnidadRefVolumen' },
    { header: 'Número de Lote', accessorKey: 'NroLote' },
    { header: 'Vencimiento de Lote', accessorKey: 'LoteVencimiento' },
    { header: 'Clase de Producto', accessorKey: 'DetalleClaseProducto' },
    { header: 'Subfamilia', accessorKey: 'Subfamilia' }
], []);
  

  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-md-1">Codigo:</div>
            <div className="col-md-2">
              <input type="text" id="txtCod" name="txtCod" value={dataRowSelect.ConsolidadoCargaID} className="form-control form-control-sm" autoComplete='off' />
            </div>
            <div className="col-md-1">Chofer:</div>
            <div className="col-md-5">
              <input type="text" id="txtDBon" name="txtDBon" value={dataRowSelect.ChoferNombre} className="form-control form-control-sm" autoComplete='off' required={true} />
            </div>
            <div className="col-md-3 d-flex justify-content-end">
              <Tooltip title="Buscar consolidado" placement="top">
                <button className="btn btn-sm  btn-lg btn-success ml-auto" onClick={handleOpen}>
                  <i class='bx bx-search-alt-2'></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
            </div>


          </div>
          <div className="row">
          <div className="col-md-1">Estado:</div>
              <div className="col-md-2">
                <input type="text" id="txtCod2" name="txtCod" value={dataRowSelect.ConsolidadoEstado}className="form-control form-control-sm" autoComplete='off'  />
              </div>
              <div className="col-md-1">Placa:</div>
              <div className="col-md-2">
                <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.VehiculoCodigo} className="form-control form-control-sm" autoComplete='off' required={true} />
              </div>
              <div className="col-md-1">Fecha:</div>
              <div className="col-md-2">
                <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.ConsolidadoCargaFecha} className="form-control form-control-sm" autoComplete='off' required={true} />
              </div>
          </div>
            <hr />

          <div className="row mb-2">
            <div className="col-lg-12 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>

              </Tooltip>
              {/* <Tooltip title="Consultar información" placement="top">
                <button
                  className="btn btn-sm btn-label-youtube"
                  id="btn_procesar"
                  onClick={onClickListar}
                  title="Consultar información"
                >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip> */}
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


        />
      </div>
      {/************  Modal Select ******************/}

      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '75%', height: '90%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-4" >
            <p>Pedidos asignados</p>
            <MaterialReactTable
              columns={columnsModal}
              data={dataModal}
              state={{ isLoading: pendingModal }}
              initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: '' }}
              localization={MRT_Localization_ES} primary
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              enableRowVirtualization
              muiTableContainerProps={{ sx: { maxHeight: 300 } }}
              //enableEditing

              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 100, //make actions column wider
                },
              }}
              onRowDoubleClick={selecionaFila}
              muiTableBodyRowProps={({ row, event }) => ({
                hover: false,

                onMouseDown: (event) => selecionaFila(event, row), // El  presiona el mouse 

              })}

              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}

            />
          </div>

        </Box>
      </Modal>
    </>
  )
}
export const Report7 = (prosp) => {// SALIDA DE PRODUCTOS PARA REPARTO POR FECHA
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,            setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());



  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns1  = useMemo( () => [
    { header: 'Placas', accessorKey: 'Placas' },
    { header: 'Consolidados', accessorKey: 'Consolidados' },
    { header: 'Código del Almacén', accessorKey: 'DetalleAlmacenCodigo' },
    { header: 'Nombre del Almacén', accessorKey: 'DetalleAlmacenNombre' },
    { header: 'Dirección del Almacén', accessorKey: 'DetalleAlmacenDireccion' },
    { header: 'Código del Producto', accessorKey: 'DetalleProductoCodigo' },
    { header: 'Nombre del Producto', accessorKey: 'DetalleProductoNombre' },
    { header: 'Unidad de Compra', accessorKey: 'DetalleUnidadCompra' },
    { header: 'Factor de Unidad de Compra', accessorKey: 'DetallleUnidadCompraFactor' },
    { header: 'Unidad de Referencia', accessorKey: 'DetalleUnidadRef' },
    { header: 'Factor de Unidad de Referencia', accessorKey: 'DetalleUnidadRefFactor' },
    { header: 'Unidad de Base', accessorKey: 'DetalleUnidadBase' },
    { header: 'Factor de Unidad de Base', accessorKey: 'DetalleUnidadBaseFactor' },
    { header: 'Cantidad de Compra', accessorKey: 'CantCompra' },
    { header: 'Cantidad de Referencia', accessorKey: 'CantRef' },
    { header: 'Cantidad de Base', accessorKey: 'CantBase' },
    { header: 'Cantidad de Producto', accessorKey: 'DetalleProductoCantidad' },
    { header: 'Número de Lote', accessorKey: 'NroLote' },
    { header: 'Vencimiento de Lote', accessorKey: 'LoteVencimiento' },
    { header: 'Clase de Producto', accessorKey: 'DetalleClaseProducto' },
    { header: 'Subfamilia', accessorKey: 'Subfamilia' }
],[])
  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiReparto?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}&vfecD=${transFechaD}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    emp_setSl_t_suc([])
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "SALIDA DE PRODUCTOS PARA REPARTO POR FECHA_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "SALIDA DE PRODUCTOS PARA REPARTO POR FECHA_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
          <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Desde:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaD} 
                  onChange={date => setText_fechaD(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4">
                <label className="form-label mb-0 " >Sucursal:</label>
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
          </div>
        </div>
        <div className="col-lg-2 text-end">

          <Tooltip title="Consultar información" placement="top">
            <>
              <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
              </button>
            </>
          </Tooltip>
          <Tooltip title="Exportar" placement="top">
            <div className="btn-group" role="group">
              <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
              </ul>
            </div>
          </Tooltip>
          <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


        />
      </div>
    </>
  )
}
export const Report8 = (prosp) => {// DETALLE DE GUIA REMISION
  const {dataTipo} = prosp
  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,            setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());



  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns1  = useMemo( () => [
    { header: 'Tipo de Documento', accessorKey: 'Name' },
    { header: 'Número de Licencia de Conducir', accessorKey: 'LicTradNum' },
    { header: 'Número de Guía del Comprobante', accessorKey: 'GuiaNumComprobante' },
    { header: 'Fecha de Emisión', accessorKey: 'FechaEmision' },
    { header: 'Nombre del Cliente', accessorKey: 'NombreCliente' },
    { header: 'Dirección de Entrega', accessorKey: 'DireccionEntrega' },
    { header: 'Peso', accessorKey: 'Peso' },
    { header: 'Placa del Vehículo', accessorKey: 'PlacaVehiculo' },
    { header: 'Sucursal', accessorKey: 'Sucursal' }
]
,[])
  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiReparto?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}&vfecD=${transFechaD}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    emp_setSl_t_suc([])
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "DETALLE DE GUIA REMISION_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "DETALLE DE GUIA REMISION_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
          <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Desde:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaD} 
                  onChange={date => setText_fechaD(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Hasta:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaH} 
                  onChange={date => setText_fechaH(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4">
                <label className="form-label mb-0 " >Sucursal:</label>
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
          </div>
        </div>
        <div className="col-lg-2 text-end">

          <Tooltip title="Consultar información" placement="top">
            <>
              <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
              </button>
            </>
          </Tooltip>
          <Tooltip title="Exportar" placement="top">
            <div className="btn-group" role="group">
              <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
              </ul>
            </div>
          </Tooltip>
          <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


        />
      </div>
    </>
  )
}
export const Report9 = (prosp) => {// ANALISIS DE EFECTIVIDAD - POR RUTAS (VER 2.0) (EXCEL)
  const {dataTipo} = prosp
  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport, setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());



  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns1  = useMemo( () => [
    { header: 'Fecha', accessorKey: 'Fecha' },
    { header: 'Código de Sucursal', accessorKey: 'SucursalCodigo' },
    { header: 'Nombre de Sucursal', accessorKey: 'SucursalNombre' },
    { header: 'Categoría de Cliente', accessorKey: 'CategoriaCliente' },
    { header: 'Mesa', accessorKey: 'Mesa' },
    { header: 'Proveedor', accessorKey: 'Proveedor' },
    { header: 'Placa del Vehículo', accessorKey: 'Placa' },
    { header: 'Código del Vendedor', accessorKey: 'VendedorCodigo' },
    { header: 'Código de Zona', accessorKey: 'ZonaCodigo' },
    { header: 'Nombre de Zona', accessorKey: 'ZonaNombre' },
    { header: 'Código de Ruta', accessorKey: 'RutaCodigo' },
    { header: 'Descripción de Ruta', accessorKey: 'RutaDescripcion' },
    { header: 'Motivo', accessorKey: 'Motivo' },
    { header: 'Monto Despachado', accessorKey: 'MontoDespachado' },
    { header: 'Monto Anulados', accessorKey: 'MontoAnulados' },
    { header: 'Soles Devueltos', accessorKey: 'SolesDevol' },
    { header: 'Soles Perdidos', accessorKey: 'SolesPerdidos' },
    { header: 'Soles Efectivos', accessorKey: 'SolesEfectivos' },
    { header: 'Porcentaje de Soles', accessorKey: 'PorcentajeSoles' },
    { header: 'Documentos Despachados', accessorKey: 'Docs_Despachados' },
    { header: 'Documentos Anulados', accessorKey: 'Docs_Anulados' },
    { header: 'Documentos Devueltos Totalmente', accessorKey: 'Docs_DevTotal' },
    { header: 'Documentos Devueltos Parcialmente', accessorKey: 'Docs_DevParcial' },
    { header: 'Clientes Despachados', accessorKey: 'Clientes_Despachados' },
    { header: 'Clientes Devueltos Totalmente', accessorKey: 'Clientes_DevTotal' },
    { header: 'Clientes Devueltos Parcialmente', accessorKey: 'Clientes_DevParcial' },
    { header: 'Canal', accessorKey: 'Canal' },
    { header: 'Documento', accessorKey: 'Documento' },
    { header: 'Fecha de Emisión del Documento', accessorKey: 'Doc_FechaEmision' },
    { header: 'Cliente del Documento', accessorKey: 'Doc_Cliente' },
    { header: 'Fecha de Generación', accessorKey: 'FechaGeneracion' },
    { header: 'Estado', accessorKey: 'Estado' },
    { header: 'Liquidador', accessorKey: 'Liquidador' }
],[])
  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiReparto?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}&vfecD=${transFechaD}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    emp_setSl_t_suc([])
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "ANALISIS DE EFECTIVIDAD - POR RUTAS _"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "ANALISIS DE EFECTIVIDAD - POR RUTAS_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
          <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Desde:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaD} 
                  onChange={date => setText_fechaD(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Hasta:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaH} 
                  onChange={date => setText_fechaH(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
          </div>
        </div>
        <div className="col-lg-2 text-end">

          <Tooltip title="Consultar información" placement="top">
            <>
              <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
              </button>
            </>
          </Tooltip>
          <Tooltip title="Exportar" placement="top">
            <div className="btn-group" role="group">
              <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
            </div>
          </Tooltip>
          <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columns1}
          // data={dataReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


        />
      </div>
    </>
  )
}



export const Report10 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,     setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [slcVehiculo,    setslcVehiculo] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [placas, setplacas] = useState('');
  const [pdfSrc, setPdfSrc] = useState('');
  const [nombreSucursal,setnombreSucursal]= useState('')
  
  const [selecConsolidado,       setSeleConsolidado] = useState('');
  const changeSelecConsolidado = (event) => { setSeleConsolidado(event.target.value); }


  const hanOpenModal = () => { 
    if (dataReport.length == 0 ){
      notify_warning("No existen datos, verificar campos")
      return
    }  
    setOpenModal(true); }
  const hanClosModal = () => { setOpenModal(false); }

  
  const funDArticulos = useCallback( async (inputValue) => {
    // console.log("inputValue::", inputValue);
    const url ='/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar='+inputValue+'&sucursal='+emp_sl_t_suc;
    // const url ='/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar='+inputValue+'&sucursal=002';
    // console.log("url:", url);
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    // console.log("Data::", data);
    return data;
  },[emp_sl_t_suc]) 



  const handleSelectChange = (selectedOption) => { setslcVehiculo(selectedOption);  };
  

  useEffect(() => {
    // Cargar datos por defecto al montar el componente
    setdataReport([])
    setslcVehiculo([])
    // const loadDefaultOptions = async () => {
    //   const defaultData = await funDArticulos('');
    //   setDefaultOptions(defaultData);
    // };
    // loadDefaultOptions();
  }, [emp_sl_t_suc ]);


  const emp_changeSl_t_suc = (event) => { 
     // logica para capturar el nombre de la sucursal
    // se usara en el nombre del archivo 
    const selectedIndex = event.target.selectedIndex;
    const selectedText = event.target.options[selectedIndex].text;
    const nombre = selectedText ? selectedText : '';
    setnombreSucursal(nombre)
    emp_setSl_t_suc(event.target.value);

    console.log(nombre);
    
    $("#emp_slc_tem").focus();
  }


  /** Primer reporte */
  const columns1 = useMemo(() => [
    { header: 'NOMBRE', accessorKey: 'CardName' },
    { header: 'CODIGO', accessorKey: 'SlpName' },
    {
      header: 'FECHA', 
        accessorKey: 'DocDate',
        Cell: ({ cell }) => {
            // return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return moment(cell.value).format('YYYY-MM-DD');
            // return moment(date).format('YYYYMMDD'); // Formatea la fecha como YYYYMMDD
        }
    },
    { header: 'CONDICION', accessorKey: 'Condicion' },
    { header: 'IMPORTE', accessorKey: 'Importe' },
    { header: 'COBRANZA', accessorKey: 'Cobranza' },
    { header: 'DOCUMENTO', accessorKey: 'Documento' },
    { header: 'SUCURSAL', accessorKey: 'PrcName' },
    { header: 'OBSERVACION', accessorKey: 'Observacion' }
  ], [])
  const onClickListar=async()=>{
    try {
      
   console.log(selecConsolidado);
   
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');

    let placa = slcVehiculo.map(option => option.Placa)
    // console.log("arrVehiculo::", arrVehiculo);
    // let placa = arrVehiculo.join(',')// placas.Placa
    setplacas(placa)
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiReporReparConsoliDocumentPDF?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&placa=${selecConsolidado}&vfecD=${transFechaD}&sucursal=${emp_sl_t_suc}&nomSucur=${nombreSucursal}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    if (response.status === 204) {
      notify_warning("No se encontraron registros ")
      return
    }
    const resData = await response.json();
    // res.status(200).json({ pdf: pdf, data: arr_data2 });
    const daraProcesada = resData.data;
    const  resDocPDf = resData.pdf;
    setPdfSrc(`data:application/pdf;base64,${resDocPDf}`);
    setdataReport(daraProcesada)
  } catch (error) {
      
  }finally{
    setPending(false)

  }
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    emp_setSl_t_suc([])
    setText_fechaD(new Date());
    setslcVehiculo([])
  }

  


  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE-CONSOLIDADO DE DOCUMENTOSS_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      // const response = await fetch(url0,opti_t);
      // const datadtos = await response.json();
      //console.log("--saas",datadtos);
      //setData(datadtos)
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  const onClickExcel = async () => {
      //console.log(sl_t_unid.desLinea);
      const fileType = "xlsx"
      const nameFileType = "REPORTE-CONSOLIDADO DE DOCUMENTOS_"+moment(new Date()).format('YYYYMMDD HH:mm')
      const showData = async () => {
        if (dataReport.length !== 0 ) {
          const ws = XLSX.utils.json_to_sheet(dataReport);
          // Cabecera del archivo 
          // const header = []; // Cambia esto según tus necesidades
          // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
          const wb = {Sheets:{data:ws}, SheetNames:["data"]};
          const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
          const data = new Blob([excelBuffer], {type:fileType});
          FileSaver.saveAs(data, `${nameFileType}.xlsx`);
        }else{
          notify_warning("No existen datos, verificar campos")
        }
      }
      showData();
  }

  const handleDescargarPDF = () => {
    // Crear un enlace temporal
    const link = document.createElement('a');
    link.href = pdfSrc;
    // Nombre del archivo 
    link.download = 'R001_CONSOLIDADO_DE_DOCUMENTOS.pdf'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  return (
    <>
      <div className="row ">
        <div className="col-lg-2">
          <label className="form-label mb-0 " >Sucursal:</label>
          <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
        </div>
        <div className="col-lg-2" style={{ zIndex: '3' }}>
          <label className="form-label mb-0 " >Fecha Desde:</label>
          <DatePicker
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center"
            selected={text_fechaD}
            onChange={date => setText_fechaD(date)}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="col-lg-3">
              <label className="form-label mb-0 " >Consolidado:</label>
              <SelectTipo id="slc_t_tga" url={`/apiConsulCON?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}&vFecha=${moment(text_fechaD).format('YYYY-MM-DD')}`} value={selecConsolidado} onChange={changeSelecConsolidado} />
            </div>
        
        {/* <div className="col-lg-4" style={{ zIndex: '4' }}>
          <label className="form-label mb-0 " >Chofer:</label>
          <SelectChofer setSelectFila={setselectChofer} fila={selecChofer} />
          <SelectTipo id="slc_t_tga" url={'/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar=&sucursal='+emp_sl_t_suc} value={slcVehiculo} onChange={setslcVehiculo}  /> */}

          {/*  Comentado ahora se consulta solo lo de fecha y sucursal  */}
          {/* <AsyncSelect
            isMulti
            //styles={styles}
            placeholder="Busque por Cod/Desc"
            className="sele_pru from-control form-control-sm "
            value={slcVehiculo}
            cacheOptions
            defaultOptions={defaultOptions}
            getOptionLabel={e => e.Placa + '   /   ' + e.Name}
            getOptionValue={e => e.Placa}
            loadOptions={funDArticulos}
            // loadOptions={(inputValue) => funDArticulos(inputValue, emp_sl_t_suc)} 
            //onChange={handleSelectChange}
            // onClick={(inputValue) => funDArticulos(inputValue, emp_sl_t_suc)} 
            onChange={(e) => {
              handleSelectChange(e)
              setslcVehiculo(e)
              setTimeout(() => {
                $('.css-wsp0cs-MultiValueGeneric').each(function () {
                  var textoCompleto = $(this).text();
                  var textoCorto = textoCompleto.split('/')[0].trim();
                  $(this).text(textoCorto);
                });
              }, 1000)
            }}
          /> */}
        {/* </div> */}
        <div className="col-lg-4 text-end">
          <Tooltip title="Consultar información" placement="top">
            <>
              <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
              </button>
            </>
          </Tooltip>
          {dataReport && dataReport.length > 0 && (

            <>
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
          {/* PdfConsolidadosDoc */}
          {/* <PDFDownloadLink className="btn btn-sm  btn-label-youtube" document={<PdfConsolidadosDoc datallegada={dataReport} placa={placas} />} fileName="R001_CONSOLIDADO_DE_DOCUMENTOS.pdf">
            {({ blob, url, loading, error }) =>
              loading ? 'Cargando PDF...' : <i className="bx bx-xs bxs-file-pdf" ></i>
            }
              </PDFDownloadLink>   */}
              <AButton tp_tilde="Visualizar Rep View" tp_posit="top" bt_class="btn btn-label-info btn-sm" bt_funct={hanOpenModal} ic_class="bx bxs-spreadsheet bx-ms" vl_tpbtn={2} />
            </>
          )}
          {dataReport && dataReport.length > 0 && (
                <AButton tp_tilde="Descargar PDF" id="btn3" tp_posit="top" bt_class="btn btn-sm  btn-label-youtube" bt_funct={handleDescargarPDF} ic_class="bx bx-xs bxs-file-pdf" vl_tpbtn={2} />
              )}
          <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>


      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


        />
      </div>

      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '100%', height: '100%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={hanClosModal}
          >
            <i className='fa fa-x'></i>
          </button>


          <div className="py-3" style={{ userSelect: 'none' }}>
            <ReportConsolidado
              fecha={moment(text_fechaD).format('YYYY-MM-DD')}
              placa={selecConsolidado}
              data={dataReport}

            />
          </div>
        </Box>
      </Modal>

    </>
  )
}


// export const Report10_aux = (prosp) => {//SREPORTE DE RECHAZOS POR CONSOLIDADO - DOCUMENTOS

//   const { dataTipo } = prosp

//   const cookies = new Cookies();
//   const cook_idEmp = cookies.get('dtEmp_id');
//   // variables 
//   const [dataModal, setdataModal] = useState([]);
//   const [pendingModal, setpendingModal] = useState(false);
//   const [dataRowSelect, setdataRowSelect] = useState({
//     ChoferNombre: '',
//     VehiculoCodigo: '',
//     ConsolidadoCargaID: ''
//   });
//   const [text_fechaD,    setText_fechaD] = useState(new Date());
//   const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
//   const emp_changeSl_t_suc = (event) => { 
//     emp_setSl_t_suc(event.target.value);
//   }
//   const selecionaFila = (e, rowData) => {
//     const data = rowData.original;
//     setopenModal(false)
//     setdataRowSelect({
//       ...dataRowSelect,
//       ChoferNombre: '' + data.ChoferNombre,
//       VehiculoCodigo: '' + data.VehiculoCodigo,
//       ConsolidadoCargaID: '' + data.ConsolidadoCargaID,
//       ConsolidadoCargaFecha:''+data.ConsolidadoCargaFecha,
//       ConsolidadoEstado:''+data.ConsolidadoEstado
//     });
//   }

//   const funBuscarConsolidado = async () => {
  
//     try {
//       setpendingModal(true);
//       const response = await fetch(`/selectConsolidadoReparto?vcodEmpresa=${cook_idEmp}`, { method: "GET", headers: { 'Content-Type': 'application/json' } });
//       if (!response.ok) throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
//       const data = await response.json();
//       console.log(data);
//       setdataModal(data);
//     } catch (error) {
//       notify_warning('Sin datos registrados');
//       console.error('Error:', error);
//       // Manejar el error aquí según sea necesario.
//     } finally {
//       setpendingModal(false);
//     }
//   }
//   const [dataReport, setdataReport] = useState([]);
//   const [pending, setPending] = useState(false);
//   const [openModal, setopenModal] = useState(false);
//   const handleOpen = () => {
//     setopenModal(true);
//     funBuscarConsolidado();

//   };
//   const cerrarModal = () => {
//     setopenModal(false);
//   }
  
 
//   /** Primer reporte */
//   const columnsModal = useMemo(() => [
//     { header: 'RUTA', accessorKey: 'ConsolidadoCargaID' },
//     { header: 'FECHA', accessorKey: 'ConsolidadoCargaFecha' },
//     { header: 'ESTADO', accessorKey: 'ConsolidadoEstado' },
//     { header: 'NOMBRE', accessorKey: 'ChoferNombre' },
//     { header: 'PLACA', accessorKey: 'VehiculoCodigo' },

//   ], [])
//   const onClickListar = async () => {
//     if(dataRowSelect.ConsolidadoCargaID === ''){
//       notify_warning("Seleccion el consolidado")
//       return
//     }
//     setdataReport([])
//     setPending(true)
//     var fecha =  moment(text_fechaD).format('YYYYMMDD');
//     const url_dataReport = `/apiReparto?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&NumConsolidado=${dataRowSelect.ConsolidadoCargaID}&vSucursal=${emp_sl_t_suc}&fecha=${fecha}`;
//    console.log("url_dataReport:: ", url_dataReport ); 
//     const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
//     const response = await fetch(url_dataReport, opPe);
//     const data = await response.json();
//     console.log("data :",data);
//     setdataReport(data)
//     setPending(false)
//   };
//   const onClickLimpiar = async () => {
//     setdataReport([])
//     setPending(false)
//   }
//   const onClickCSV = async () => {
//     const fileType = "CSV"
//     const nameFileType = "REPORTE DE RECHAZOS POR CONSOLIDADO DOCUMENTOS _"+moment(new Date()).format('YYYYMMDD HH:mm')

//     const showData = async () => {
//       if (dataReport.length !== 0 ) {
//         for (var i = 0; i < dataReport.length; i++) {
//           let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
//           fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
//           dataReport[i]["FechaEmision"] = fecha;
//         };
//         const ws = XLSX.utils.json_to_sheet(dataReport);
//         const wb = {Sheets:{data:ws}, SheetNames:["data"]};
//         const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
//         const data = new Blob([excelBuffer], {type:fileType});
//         FileSaver.saveAs(data, `${nameFileType}.CSV`);
//       }else{
//         notify_warning("No existen datos, verificar campos")
//       }
//     }
//     showData();
//   }
//   const onClickExcel = async () => {
//     const fileType = "xlsx"
//     const nameFileType = "REPORTE DE RECHAZOS POR CONSOLIDADO - DOCUMENTOS_"+moment(new Date()).format('YYYYMMDD HH:mm')
//     const showData = async () => {
//       if (dataReport.length !== 0 ) {
//         const ws = XLSX.utils.json_to_sheet(dataReport);
//         // Cabecera del archivo 
//         // const header = []; // Cambia esto según tus necesidades
//         // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
//         const wb = {Sheets:{data:ws}, SheetNames:["data"]};
//         const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
//         const data = new Blob([excelBuffer], {type:fileType});
//         FileSaver.saveAs(data, `${nameFileType}.xlsx`);
//       }else{
//         notify_warning("No existen datos, verificar campos")
//       }
//     }
//     showData();
//   }
// //   const columnsReport = useMemo(() => [
// //     // { header: 'ID de Sucursal', accessorKey: 'SucursalID' },
// //     { header: 'Sucursal', accessorKey: 'SucursalNombre' },
// //     { header: 'Fecha', accessorKey: 'ConsolidadoFecha' },
// //     { header: 'Número de Consolidado', accessorKey: 'ConsolidadoNumero' },
// //     { header: 'Placa', accessorKey: 'Placa' },
// //     { header: 'Fecha de Cp', accessorKey: 'CpFecha' },
// //     { header: 'Número de Cp', accessorKey: 'NumCp' },
// //     { header: 'Código de Cliente', accessorKey: 'ClienteCodigo' },
// //     { header: 'Nombre de Cliente', accessorKey: 'Cliente' },
// //     { header: 'Total', accessorKey: 'Total' },
// //     { header: 'Fecha Relacionada', accessorKey: 'FechaRelacionado' },
// //     { header: 'Número de Cp R.', accessorKey: 'NumCpRelacionado' },
// //     { header: 'Total Relacionado', accessorKey: 'TotalRelacionado' },
// //     { header: 'ID de Tipo Asociado', accessorKey: 'IDTipoAsociado' },
// // ], []);

// const columnsReport = useMemo(() => [
//   // { header: 'Consolidado', accessorKey: 'Consolidado' },
//   // { header: 'Documento', accessorKey: 'CodAlmacen' },
//   { header: 'G.U.Negocio  ', accessorKey: 'CardName' },
//   // { header: 'Direccion', accessorKey: 'Dire' },
//   // { header: 'CardName', accessorKey: 'CardName' },
//   { header: ' Cod. U.Negocio', accessorKey: 'ItmsGrpNam' },
//   // { header: 'Articulo', accessorKey: 'Articulo' },
//   { header: 'Cod. Articulo ', accessorKey: 'ItemCode' },
//   { header: 'Nom. Articulo ', accessorKey: 'ItemName' },
//   { header: 'Lote ', accessorKey: 'Lote' },
//   { header: 'U.M', accessorKey: 'Unid1' },
//   { header: 'Unidad V.M. ', accessorKey: 'Q1' },
//   { header: 'U.m', accessorKey: 'InvntryUom' },
//   { header: 'Unidad V.m.', accessorKey: 'Q2' },
//   { header: 'DocDate', accessorKey: 'U_SYP_MDFN' },
// ], []);
  

//   return (
//     <>
//       <div className="row mt-2 ">
//         <div className="col-lg-2" style={{ zIndex: '3' }}>
//           <label className="form-label mb-0 " >Fecha Desde:</label>
//           <DatePicker
//             locale="es"
//             wrapperClassName='input_date'
//             className="form-control form-control-sm text-center"
//             selected={text_fechaD}
//             onChange={date => setText_fechaD(date)}
//             dateFormat="yyyy-MM-dd"
//           />
//         </div>
//         <div className="col-lg-3">
//           <label className="form-label mb-0 " >Sucursal:</label>
//           <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
//         </div>
//         <div className="col-md-4">
//           <label className="form-label mb-0 " >Consolidado:</label>
          
//           <div className='input-group'>

//           <input type="text" id="txtCod" name="txtCod" value={dataRowSelect.ConsolidadoCargaID} className="form-control form-control-sm" autoComplete='off' />
//           <Tooltip title="Buscar consolidado" placement="top">
//             <button className="btn btn-sm  btn-lg btn-success ml-auto" onClick={handleOpen}>
//               <i class='bx bx-search-alt-2'></i>
//               <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
//             </button>
//           </Tooltip>
//           </div>
         
//         </div>
//         {/* <div className="col-md-1">Chofer:</div>
//         <div className="col-md-3">
//           <input type="text" id="txtDBon" name="txtDBon" value={dataRowSelect.ChoferNombre} className="form-control form-control-sm" autoComplete='off' required={true} />
//         </div> */}
//         {/* <div className="col-md-3 d-flex justify-content-end">
//           <Tooltip title="Buscar consolidado" placement="top">
//             <button className="btn btn-sm  btn-lg btn-success ml-auto" onClick={handleOpen}>
//               <i class='bx bx-search-alt-2'></i>
//               <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
//             </button>
//           </Tooltip>
//         </div> */}



//         {/* <div className="row">
//             <div className="col-md-1">Estado:</div>
//                 <div className="col-md-2">
//                   <input type="text" id="txtCod2" name="txtCod" value={dataRowSelect.ConsolidadoEstado}className="form-control form-control-sm" autoComplete='off'  />
//                 </div>
//                 <div className="col-md-1">Placa:</div>
//                 <div className="col-md-2">
//                   <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.VehiculoCodigo} className="form-control form-control-sm" autoComplete='off' required={true} />
//                 </div>
//                 <div className="col-md-1">Fecha:</div>
//                 <div className="col-md-2">
//                   <input type="text" id="txtDBon2" name="txtDBon" value={dataRowSelect.ConsolidadoCargaFecha} className="form-control form-control-sm" autoComplete='off' required={true} />
//                 </div>
//             </div> */}
        

//       </div>
//       <div className="row mb-2">
//           <div className="col-lg-12 text-end">

//             <Tooltip title="Consultar información" placement="top">

//               <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
//                 <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
//                 <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
//               </button>

//             </Tooltip>
//             <Tooltip title="Exportar" placement="top">
//               <div className="btn-group" role="group">
//                 <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                   <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
//                   <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
//                 </button>
//                 <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
//                   <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
//                   <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
//                 </ul>
//               </div>
//             </Tooltip>
//             <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
//           </div>
//         </div>

//         <hr />

//       <div className="card-datatable table-responsive ">
//         <MaterialReactTable
//           columns={columnsReport}
//           data={dataReport}
//           state={{ isLoading: pending }}
//           initialState={{
//             density: 'compact', showColumnFilters: false,
//             showGlobalFilter: false,
//             columnVisibility: { clave: false, vlSema: false, },
//           }}
//           enableGrouping
//           enableStickyHeader enableColumnResizing
//           muiToolbarAlertBannerChipProps={{ color: 'primary' }}
//           localization={MRT_Localization_ES}
//           enablePagination={true}
//           muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
//           enableGlobalFilterModes
//           rowVirtualizerProps={{ overscan: 150 }}
//           enableRowVirtualization
//           displayColumnDefOptions={{
//             'mrt-row-actions': {
//               header: 'Options', //change header text
//               size: 120, //make actions column wider
//             },
//           }}


//         />
//       </div>
//       {/************  Modal Select ******************/}

//       <Modal
//         open={openModal}
//         aria-labelledby="parent-modal-title"
//         aria-describedby="parent-modal-description"
//       >
//         <Box sx={{ ...style, width: '75%', height: '90%', overflow: 'auto' }}>
//           <button
//             style={{
//               position: 'absolute',
//               top: '10px',
//               right: '10px',
//               background: 'transparent',
//               border: 'none',
//               cursor: 'pointer',
//             }}
//             className='btn btn-outline-danger btn-sm'
//             onClick={cerrarModal}
//           >
//             <i className='fa fa-x'></i>
//           </button>
//           <div className="py-4" >
//             <p>Pedidos asignados</p>
//             <MaterialReactTable
//               columns={columnsModal}
//               data={dataModal}
//               state={{ isLoading: pendingModal }}
//               initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
//               enableGrouping
//               enableStickyHeader enableColumnResizing
//               muiToolbarAlertBannerChipProps={{ color: '' }}
//               localization={MRT_Localization_ES} primary
//               enablePagination={true}
//               muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
//               enableGlobalFilterModes
//               rowVirtualizerProps={{ overscan: 8 }}
//               enableRowVirtualization
//               muiTableContainerProps={{ sx: { maxHeight: 300 } }}
//               //enableEditing

//               displayColumnDefOptions={{
//                 'mrt-row-actions': {
//                   header: 'Options', //change header text
//                   size: 100, //make actions column wider
//                 },
//               }}
//               onRowDoubleClick={selecionaFila}
//               muiTableBodyRowProps={({ row, event }) => ({
//                 hover: false,

//                 onMouseDown: (event) => selecionaFila(event, row), // El  presiona el mouse 

//               })}

//             />
//           </div>

//         </Box>
//       </Modal>
//     </>
//   )
// }


const ReportReparto = () => {
  //const cookies = new Cookies();
  //const cook_idEmp = cookies.get('dtEmp_id');
  const [slTipoReport, setSlTipoReport] = useState('');

  const changeSlTipoReport = (event) => {
    setSlTipoReport(event.target.value);
  }

  return (
    <div>
      <h6 className="text-muted"> Reparto</h6>
      <div className="card card-action">
        <div className="card-body ">
          <div className="row mb-2">
            <div className="col-lg-10">
              <div className="row ">
                <div className="col-lg-2"><label className="form-label mb-0 " >Tipo Reporte:</label></div>
                <div className="col-lg-10">
                  <select id="slc_t_con" className="form-select form-select-sm" value={slTipoReport} onChange={changeSlTipoReport} >
                    <option value={"9999"} >--Seleccione--</option>
                    <option value={"1"} > DIS006 : IMPRESION GUIA REMISION - CONSOLIDADO (COMPROBANTES) </option>
                    <option value={"2"} > DIS005 : IMPRESION GUIA REMISION . CONSOLIDADO (PRODUCTOS)</option>
                    <option value={"3"} > AB029  : IMPRESION GUIA DE REMISION ELECTRONICA </option>
                    <option value={"4"} > AB027  : IMPRESION GUIA REMISION • TRANSPORTISTA</option>
                    <option value={"5"} > DIS018 : ORDEN DE SALIDA-REPARTO </option>
                    <option value={"6"} > DISOOI : SALIDA DE PRODUCTOS PARA REPARTO POR CONSOLIDADO</option>
                    <option value={"7"} > AB058  : SALIDA DE PRODUCTOS PARA REPARTO POR FECHA </option>
                    <option value={"8"} > INP023 : DETALLE DE GUIA REMISION </option>
                    <option value={"9"} > AB059 : ANALISIS DE EFECTIVIDAD - POR RUTAS (VER 2.0) (EXCEL) </option>
                    <option value={"10"} > REP0010 : CONSOLIDADO DE DOCUMENTOS</option>
                    {/* <option value={"10"} > AB069 : REPORTE DE RECHAZOS POR CONSOLIDADO - DOCUMENTOS </option> */}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {
            (slTipoReport === '1') && (
              <Report1
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '2') && (
              <Report2
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '3') && (
              <Report3
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '4') && (
              <Report4
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '5') && (
              <Report5
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '6') && (
              <Report6
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '7') && (
              <Report7
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '8') && (
              <Report8
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '9') && (
              <Report9
                dataTipo={slTipoReport}
              />
            )
          }
           {
            (slTipoReport === '10') && (
              <Report10
                dataTipo={slTipoReport}
              />
            )
          }





        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default ReportReparto

