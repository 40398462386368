/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
//import {BarChart1,BarChart2,BarChart3} from '../components/Charts/BarChart'

function Contenido() {

   //Guardar cookie ultima vista
  useEffect(() => {
    savePathInCookie();
  }, []);
  function savePathInCookie() {
    const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal

    // Guarda el valor en una cookie con nombre "ultimapag"
    document.cookie = `ultimapag=${path}`;
  }
  
  return (
    <div>
      <div className="row">
        <div className="col-lg-12 mb-4 order-0">
          <div className="card">
            <div className="d-flex align-items-end row">
              <div className="col-sm-7">
                <div className="card-body">
                  <h5 className="card-title text-primary">Bienvenid@ 🎉</h5>
                  <p className="mb-3">Al nuevo Sistema Web de <span className="fw-bold text-danger">Alvarez Bohl</span>, actualmente se viene trabajando para optimizar procesos.</p>
                  <a href="#" className="btn btn-sm btn-label-primary d-none">View Badges</a>
                </div>
              </div>
              <div className="col-sm-5 text-center text-sm-left">
                <div className="card-body pb-0 px-0 px-md-4">
                  <img src="assets/img/illustrations/man-with-laptop-light.png" height={140} alt="View Badge User" data-app-dark-img="illustrations/man-with-laptop-dark.png" data-app-light-img="illustrations/man-with-laptop-light.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/**
      <div className="row">
        <div className="col-lg-6 mb-4 order-0">
          <div className="card">
              <div className="card-body">
                <h5 className="card-title text-primary">Proveedores</h5>
                <BarChart1 />
              </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4 order-0">
          <div className="card">
              <div className="card-body">
                <h5 className="card-title text-primary">Canales</h5>
                <BarChart2 />
              </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mb-4 order-0">
          <div className="card">
              <div className="card-body">
                <h5 className="card-title text-primary">Sub Canales</h5>
                <BarChart3 />
              </div>
          </div>
        </div>
      </div>
       */}
    </div>

  )
}

export default Contenido