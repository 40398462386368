import { Box } from "@mui/material";
import React, { useMemo, useState, useCallback, useRef, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import Swal from 'sweetalert2';
import { AButton } from "../../components/Button";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { createTheme, ThemeProvider } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import {  Modal } from "@mui/material";
import { notify_error, notify_valido, notify_warning } from "../../components/utils";
import { SelectSucursal } from "../../components/Selects/SelectsPrincipales";
import AsyncSelect from 'react-select/async';
import Cookies from "universal-cookie";
import { useParams } from 'react-router-dom';

const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const globalTheme = window.templateCustomizer.settings.style;
const styles = {

    control: (provided, state) => ({
        ...provided,
        boxShadow: state.isFocused ? null : null,
        backgroundColor: globalTheme === 'light'
            ? '#FEFFF4'
            : '#2B2C40',
        color: globalTheme === 'light'
            ? '#2B2C40'
            : '#FEFFF4',
        minHeight: `29px`,
        height: `29px`,
    }),

    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
            : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
        color: state.isSelected
            ? '#FF3E1D'
            : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
        '&:hover': {
            backgroundColor: '#FF3E1D',
            color: 'white',
        },
    }),
    noOptionsMessage: (provided, state) => ({
        ...provided,
        backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
        color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: `29px`,
        padding: `0 6px`,
        color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    }),
    input: (provided, state) => ({
        ...provided,
        color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
        fontSize: `0.75rem`,
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: `29px`,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    }),
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.default',
    border: '1px #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
export const GPAsignarUnidadVenta = (prosp) => {
    
    const  {data}=prosp
    // const [ dataArticulo , setDataArticulo]=useState()
    const [slcArticulo, setSlcArticulo] = useState({
        "CodeArt":prosp.data.CodArt,
        "Name":prosp.data.DesArt
    });
    const navigate = useNavigate();
    const location = useLocation();
    // Varible de la url en caso se desea Editar 
    const searchParams = new URLSearchParams(location.search);
    const cod = searchParams.get('cod') || '';
    const nombreArt = searchParams.get('desArt') || '';
    const tip = searchParams.get('tip') || '';
    /** Fin :> Datos para validar */



    // const [isDisabled, setIsDisabled] = useState(false);


    const [codDeUrl, setcodDeUrl] = useState("");

    const [jsonFinal, setJsonFinal] = useState({
        codigo: "",
        codeArticulo: "",
        desArticulo: "",
        sucursal: ""
    });

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme,
                    background: {
                        default:
                            globalTheme === 'light'
                                ? 'rgb(254,255,244)'
                                : '#2B2C40',
                    },
                },
                typography: {
                    button: {
                        textTransform: 'none',
                        fontSize: '1.2rem',
                    },
                },
            }),
        [],
    );

    const handleFieldChange = (fieldName) => (event) => {
        let value = event.target.value;
        setJsonFinal({
            ...jsonFinal,
            [fieldName]: value,
        });
    };



    /*****************************************************************************************************/
    


    //  Sucursal
    const emp_changeSl_t_suc = (event) => {
        emp_setSl_t_suc(event.target.value);
    }

    const [codidoaAuto, setCodigoAuto] = useState("")

    // const [ desableSelecUVenta, setDesableSelecUVenta]= useState(false);
    const [arrDatosInsert, setArrDatosInsert] = useState([]); // detalles 
    const selectRowRef = useRef(null);
    const selectRowRef_2 = useRef(null);


    
    const [slcMedidaKey, setSlcMedidaKey] = useState(0);
    const [disabledb1, setdisabledb1] = useState(false);
    const [edit, setEdit] = useState(false);
    
    const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
    const handleSelectChange = (selectedOption) => {
        setSlcArticulo(selectedOption);
        setArrDatosInsert([])
        setCodigoAuto("");
        SetselecArt(true)
        setSlcMedidaKey(prevKey => prevKey + 1);
    };

    // data del detalle de articulo para la undia de medida
    const [arrDatostMedida, setArrDatostMedida] = useState([]);
    const [filaActiva, setFilaActiva] = useState(null);
    const [selecArt, SetselecArt] = useState(false);
    const funDArticulos = useCallback(async (inputValue) => {
        const url = '/apiArticulos?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
        const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
        const response = await fetch(url, opPe);
        const data = await response.json();
        return data;

    }, [])
    // const url = '/apiUnidadArticulos?vcodEmpresa=' + cook_idEmp + '&CodArt=5110051' ; 

    /**
     * Esta funcion busca las unidades de medida del arituculo
     */
    const funUnidadMedida = useCallback(async () => {
        console.log("sr:: ", slcArticulo);
        const url = '/apiUnidadArticulos?vcodEmpresa=' + cook_idEmp + '&CodArt=' + slcArticulo.CodeArt;
        // const url = '/apiUnidadArticulos?vcodEmpresa=' + cook_idEmp + '&CodArt=5110051' ; 
        const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
        const response = await fetch(url, opPe);
        const data = await response.json();
        setArrDatostMedida(data);
        console.log("Data MEdiad  :: ", data);
        return data;
    }, [slcArticulo])

    const [slcMedida, setSlcMedida] = useState([""]);
    const handleSelectChangeMedida = (selectedOption) => {
        console.log("selectedOption:", selectedOption);
        const updatedOption = {
            ...selectedOption,
            BaseQty: Math.round(selectedOption.BaseQty)
        };
        setSlcMedida(updatedOption);
    };


    /**
     * Esta funcion agrega una nueva unidad de venta 
     */
    const onClickInsert = async () => {
        // valdiar si ingreso sucursal 
        console.log("Datos de cabecera ::", tip, nombreArt, cod);
        if (
            slcArticulo.Name !== undefined &&
            emp_sl_t_suc !== "" &&
            slcMedida.Code !== undefined &&
            emp_sl_t_suc.trim() !== '9999') {
            const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcMedida.CodeEmtry)
            if (datosrepetidos.length === 0) {
                const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
                const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
                const newItem = { idArt: newId, CodArt: slcMedida.CodeEmtry, DesUniVenta: slcMedida.UomCode , Factor:  Math.round(slcMedida.BaseQty)
                };
                // console.log("newItem:: ", newItem);
                notify_valido("Se agrego correctamente!")
                setArrDatosInsert([...arrDatosInsert, newItem]);
                setFilaActiva(newId);
                setSlcMedida([])
                setTimeout(() => { selectRowRef.current.focus() }, 500);
            } else {
                notify_warning('Datos selecionados ya exiten.');
            }
        } else {

            if (slcArticulo.Name == undefined) {
                notify_warning('Campo descripción  no deben estar vacíos seleccioné un artículo.');
            } else if (emp_sl_t_suc == "") {
                notify_warning('Campo  sucursal no deben estar vacíos.')
            }
            else if (slcMedida.Code == undefined || slcMedida.Code == "") {
                notify_warning('Debe elegir una unidad de venta');
            } else if (emp_sl_t_suc.trim() === '9999' || emp_sl_t_suc == undefined) {
                notify_warning('Debe ingresar la sucursal.');
            }
        }

        // console.log(slcArticulo);
    }
    /**
     * Esta funcion elimina una nueva unidad de venta 
     */
    const onClickDeletefila = (code) => {
        const myList = [...arrDatosInsert];
        console.log("arrDatosInserta :: ", arrDatosInsert);
        const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
        myList.splice(data, 1);
        // fila activa 
        const lastItem = myList[myList?.length - 1]
        setFilaActiva(lastItem?.idArt)
        setArrDatosInsert(myList);
        setSlcMedida([])

    }

    const transfObjeto = (arr) => {
        let objuniVenta = [];
        // const newItem = { idArt: newId, CodArt: slcMedida.CodeEmtry, DesUniVenta: slcMedida.UomCode };
        let id = 0;
        if (arr.length) {
            console.log("etra");
            arr.forEach((row) => {
                id++;
                objuniVenta.push({
                    idArt: id,
                    CodArt: row.CodeUniVenta,
                    DesUniVenta: row.DesUniVenta,
                    Factor:Math.round(row.Factor),

                });
            });
            console.log("objuniVenta", objuniVenta);
            // ahora la fila activa sera la ultima, que corresponde al filas de la tabla 
            const lastItem = objuniVenta[objuniVenta?.length - 1]
            setFilaActiva(lastItem?.idArt)
            setSlcMedidaKey(prevKey => prevKey + 1);
        }
        return objuniVenta

    }
    
    const fun_buscarArticuloUVenta = async (codArt) => {
        Swal.fire({
            title: 'Procesando...',
            html: 'Se esta consultado datos.',
            didOpen: () => { Swal.showLoading() },
            customClass: { container: 'my-swal' },
            allowOutsideClick: false
        })
        try {
            const url = '/UnidadPrecioDetalleArt?vcodEmpresa=' + cook_idEmp + '&codArt=' + codArt;
            const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
            const response = await fetch(url, opPe);
            const resdatos = await response.json();
            console.log("Datos busqueda prodc:: ", resdatos);
            setSlcArticulo({ Code: resdatos.CodeArt, Name: nombreArt })  // escribimos el nombre de codigo que vine por la cabecera 
            console.log(resdatos);
            setCodigoAuto(resdatos.Code)
            emp_setSl_t_suc(resdatos.CodSucusal)
            setArrDatosInsert(transfObjeto(resdatos.DetalleUnVenta))
        } catch (error) {
            notify_warning(" No se encontro el articulo")
            console.error('Error al realizar la solicitud:', error);
        }
        Swal.close();
    }


    // // inico del componente 
    // useEffect(() => {
    //     if (cod != '' && tip != '' && tip == 'edit' && nombreArt != '') {
    //         setcodDeUrl(cod);
    //         fun_buscarArticuloUVenta(cod);
    //         setEdit(true);
    //     }
    //     // savePathInCookie();
    // }, []);

    useEffect(() => {
            // console.log("DATA: ", data);
            // funUnidadMedida();
            // asignacion del codigo de sucursal al inicio
            emp_setSl_t_suc( prosp.data.CodSucursal)
            // setEdit(true);

    }, []);
    // codigoUnidadVentaAuntoInt

    const fun_generarCod = async () => {
        try {
            const url = '/codigoUnidadVentaAuntoInt?vcodEmpresa=' + cook_idEmp;
            const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
            const response = await fetch(url, opPe);
            const resdatos = await response.json();
            let condigonuevo = resdatos.NUEVOCODIGO;
            setCodigoAuto(condigonuevo)
            console.log("codigo Nuevo::  ", resdatos.NUEVOCODIGO);
            return condigonuevo
        } catch (error) {
            console.error('Error al realizar la solicitud:', error);
            return "";
        }
    }

    function criteriosCumplidos() {
        return slcArticulo.Name !== "" &&
            emp_sl_t_suc !== "" && emp_sl_t_suc !== "9999" &&
            arrDatosInsert.length > 0;
    }

    /**
     * Esta funcion guarda los datos en la BD 
     */
    const onClickGuardar = async () => {

        // cada vez que se guarde se tiene que crear un codigo 
        // let codigo = 0;
        // if (edit) {
        //     codigo = codDeUrl
        // } else {
        //     codigo = await fun_generarCod();
        // }
        let codigo = 0;
        codigo = await fun_generarCod();
        // validamos 
        if (criteriosCumplidos() && codigo !== "") {
            setdisabledb1(true)
            const arrCabecera = {
                codigo: codigo,
                codeArticulo: slcArticulo.CodeArt,
                desArticulo: slcArticulo.Name,
                sucursal: emp_sl_t_suc
            };

            console.log("arrCabecera", arrCabecera);
            console.log("arrDatosInsert", arrDatosInsert);
            /**
             * * La variable "edit", se envia por qque  el API tiene la funcionalidad de editar, 
             * en ese caso solo  inserta 
             */
            const url = "/insertDataUnidadPrecio"
            const body = { vcodEmpresa: cook_idEmp, arrCabecera: arrCabecera, tipoInserEdit: edit, arrDetalle: arrDatosInsert };
            const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

            const response = await fetch(url, opPe);
            const resdatos = await response.json();
            console.log("DAtos :", resdatos);
            if (resdatos.vl_api === 1) {
                notify_warning("Error al registrar, verificar datos!")
            }
            if (resdatos.vl_existe === 1) {
                notify_warning("Ya fue registrado este articulo!")
                setTimeout(() => {
                    navigate('/gplistaunidadventa');
                }, 2000)

            }
            if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1) {

                notify_valido("Se registro correctamente!")
            }
        } else {
            if (slcArticulo.Name == undefined) {
                notify_warning('Campo descripción  no deben estar vacíos seleccioné un artículo.');
            } else if (emp_sl_t_suc == "") {
                notify_warning('Campo  sucursal no deben estar vacíos.');
            } else if (codigo = "") {
                notify_warning('Error en el codigo');
            } else if (arrDatosInsert.length == 0) {
                notify_warning('Debe elegir una unidad de venta');
            } else if (emp_sl_t_suc.trim() === '9999' || emp_sl_t_suc == undefined) {
                notify_warning('Debe ingresar la sucursal.');
            }

        }
        setdisabledb1(false)

    }

    const onClickResetData = () => {
        setCodigoAuto("");
        emp_setSl_t_suc('');
        setArrDatosInsert([]);
        setSlcArticulo([]);
        setSlcArticulo({ Code: "", Name: "" }) 
        if (edit) {  navigate('/gpunidadventa')  ;
        window.location.reload();}

    }
    /**
     * Plantilla de la tabla de unidad de venta 
     * @param {*} prosp 
     * @returns 
     */
    const TRPlantilllaCD = (prosp) => {
        return (
            <tr key={prosp.datoRow.idArt} >
                <td className='text-center' >

                    <AButton tp_tilde="Eliminar" bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
                </td>
                <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
                    {prosp.datoRow.CodArt}</td>
                <td>{prosp.datoRow.DesUniVenta}</td>
                <td>{prosp.datoRow.Factor}</td>
                <td></td>
            </tr>
        )
    }

    /******************************+***********************************************************************+*/
   

    return (
        <>
            <ThemeProvider theme={tableTheme}>
                <h5 className="text-muted">Asignar unidad de venta </h5>
                <div className="card card-action">
                    <div className="card-body">
                        <div className="row" >
                            <div className="col-lg-12 text-justify p-2 ">
                                {/* <AButton tp_tilde="Consultar información" id="btn1" tp_posit="top" bt_class="btn btn-label-youtube btn-sm"
                                    ic_class="bx bx-cloud-download bx-ms" vl_tpbtn={2}
                                    bt_funct={onClickConsult}
                                />&nbsp; */}
                            </div>
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-row justify-content-start col-md-12 col-sm-12">

                                    <div className="col-2">
                                        <div className="d-flex">
                                            Codigo
                                        </div>
                                        <div className="col-10">
                                            <input type="number" className="form-control form-control-sm" value={codidoaAuto} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-2" style={{ zIndex: 99 }}>
                                        <div className="d-flex">
                                            Cod.Articulo
                                        </div>
                                        <div className="col-10" >
                                            {/* {(edit) ? */}
                                                <input type="text" className="form-control form-control-sm" value={slcArticulo.CodeArt} disabled />
                                                {/* :
                                                <AsyncSelect
                                                    ref={selectRowRef_2}
                                                    styles={styles}
                                                    placeholder="Busque por Cod./Desc."
                                                    name="form-field-name"
                                                    className=" from-control form-control-sm"
                                                    value={slcArticulo}
                                                    cacheOptions
                                                    defaultOptions
                                                    getOptionLabel={e => e.Code + ' - ' + e.Name}
                                                    getOptionValue={e => e.Code}
                                                    loadOptions={funDArticulos}
                                                    onChange={handleSelectChange}
                                                    maxMenuHeight={150}
                                                /> */} 
                                            {/* } */}

                                            {/* <input type="text" className="form-control form-control-sm" value={jsonFinal.nombre} onChange={handleFieldChange('nombre')} /> */}
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="d-flex">
                                            D.Articulo
                                        </div>
                                        <div className="col-10">
                                            {/* <label className="form-control form-control-sm" > { dataArticulo.DesArt}</label> */}
                                            <input type="text" className="form-control form-control-sm" value={slcArticulo.Name} disabled={true}  />
                                            {/* <input type="text" className="form-control form-control-sm" value={jsonFinal.desArticulo} onChange={handleFieldChange('desArticulo')} /> */}

                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="d-flex">
                                            Sucursal
                                        </div>
                                        <div className="col-10">
                                            {/* <label className="form-control form-control-sm" > { dataArticulo.Sucursal}</label> */}
                                            {/* <input type="text" className="form-control form-control-sm" value={slcArticulo.CodeArt} disabled /> */}

                                            <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} disabled={ true} />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-12 mt-4" align="right">
                                    <div className="row mb-2">
                                        <div className="col-lg-4"></div>
                                        <div className="col-lg-8 text-end">
                                                    <AButton id="btn_LimpiarTod" tp_tilde="" bt_descr="Guardar " tp_posit="top" bt_class="btn  btn-label-success btn-sm vervalidar "
                                                        bt_funct={onClickGuardar}
                                                        ic_class="bx bxs-save bx-ms" vl_tpbtn={2} disabled={disabledb1}
                                                    />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-datatable table-responsive  " style={{ position: 'relative', zIndex: '0', width: 'auto', height: '250px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>


                            <table className="table table-sm mt-3" id='a' style={{ fontSize: '10px', zIndex: '12    ', }}>
                                <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                    <tr >
                                        <th style={{ width: '100px' }} > Actions</th>
                                        <th style={{ width: '450px' }} >Cod. U. Medida </th>
                                        <th style={{ width: '450px' }} >Des. U. Medida  </th>
                                        <th style={{ width: '450px' }} >Factor. U. Medida </th>

                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {
                                        (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                                            <React.Fragment key={row.idArt}>
                                                <TRPlantilllaCD key={row.idArt} datoRow={row} />
                                                {
                                                    (!filaActiva || filaActiva === row.idArt) && (
                                                        <tr >
                                                            <td className='td1'>
                                                            </td>
                                                            <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                                                                {/* {(selecArt || edit) && */}
                                                                    <AsyncSelect
                                                                        key={slcMedidaKey}
                                                                        ref={selectRowRef}
                                                                        styles={styles}
                                                                        placeholder="Busque por Cod./Desc."
                                                                        name="form-field-name"
                                                                        className="sele_pru from-control form-control-sm tbCodArticulo"
                                                                        value={slcMedida}
                                                                        defaultOptions
                                                                        getOptionLabel={e => e.CodeEmtry + ' - ' + e.UomCode}
                                                                        getOptionValue={e => e.Code}
                                                                        loadOptions={funUnidadMedida}
                                                                        // loadOptions={(inputValue) => funUnidadMedida(inputValue,slcArticulo )}
                                                                        onChange={handleSelectChangeMedida}
                                                                        maxMenuHeight={150}
                                                                    />
                                                                    {/* } */}
                                                            </td>
                                                            <td className='td3'>{slcMedida.UomCode}</td>
                                                            <td className='td3'>{slcMedida.BaseQty}</td>

                                                            <td className='td4'>
                                                                <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </React.Fragment>
                                        ))
                                            :

                                            <tr >

                                                <td className='td1'>
                                                </td>
                                                <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                                                    {/* {(selecArt || edit) &&  */}
                                                    <AsyncSelect
                                                        key={slcMedidaKey}
                                                        ref={selectRowRef}
                                                        styles={styles}
                                                        placeholder="Busque por Cod./Desc."
                                                        name="form-field-name"
                                                        className="sele_pru from-control form-control-sm tbCodArticulo"
                                                        value={slcMedida}
                                                        defaultOptions
                                                        getOptionLabel={e => e.CodeEmtry + ' - ' + e.UomCode}
                                                        getOptionValue={e => e.Code}
                                                        loadOptions={funUnidadMedida}
                                                        onChange={handleSelectChangeMedida}
                                                        maxMenuHeight={150}
                                                    />
                                                    {/* } */}
                                                </td>
                                                <td className='td3'>{slcMedida.UomCode}</td>
                                                <td className='td3'>{slcMedida.BaseQty}</td>
                                                <td className='td4'>
                                                    <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </>
    );
}

// export default GPUnidadVenta;


const GPUnidadVenta = () => {
    const [disabledb1, setdisabledb1] = useState(false);
    const [pending, setpending] = useState(false);
    const [lst, setlst] = useState([]);
    const [articulo, setarticulo]= useState({});
    const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
    const emp_changeSl_t_suc = (event) => {
      emp_setSl_t_suc(event.target.value);
  }
    
    const handleOpen = async (e) => {
      setarticulo(e.original)
      setOpen(true)
    }
    const [open, setOpen] = useState(false);
    const columns = useMemo(() => [
     
    //   { header: 'Code', accessorKey: 'Code' },
      { header: 'Codigo Articulo', accessorKey: 'CodArt' },
      { header: 'DesArticulo', accessorKey: 'DesArt' },
      { header: 'Sucursal', accessorKey: 'Sucursal' },
    ], [lst]);
  
  
    const onClickListar = async () => {
      try {
        console.log("cod;:",emp_sl_t_suc );
        //   const url = '/apiListaDescG?vcodEmpresa=' + cook_idEmp+ '&vcodSucursal='+sl_t_suc;
        if(emp_sl_t_suc!==''  && emp_sl_t_suc !='9999'){
          console.log("emp_sl_t_suc::", emp_sl_t_suc);
          const url = '/listDataArtiSinUVenta?vcodEmpresa=' + cook_idEmp + '&vcodSucursal=' + emp_sl_t_suc;
        //   getArtiSinUnidadVenta.xsjs?empId=4&codSucursal=001
          const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
          setpending(true)
          setdisabledb1(true)
          const response = await fetch(url, opPe);
          const data = await response.json();
          setlst(data)
          setdisabledb1(false)
          setpending(false)
        }else{
          notify_warning('Seleccione una Sede')
        }
       
  
      } catch (error) {
        setdisabledb1(false)
        setpending(false)
        notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
      }
    }
  
  
  
  
    const tableTheme = useMemo(
      () =>
        createTheme({
          palette: {
            mode: globalTheme,
            background: {
              default:
                globalTheme === 'light'
                  ? 'rgb(254,255,244)'
                  : '#2B2C40',
            },
          },
          typography: {
            button: {
              textTransform: 'none',
              fontSize: '1.2rem',
            },
          },
        }),
      [globalTheme],
    );
  
    const [rowSelection, setRowSelection] = useState([]);
  
    const onClickLimpiar = async (row) => {
      try {
        setlst([])
        setRowSelection([]);
        emp_setSl_t_suc('');
      } catch (error) {
        notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
      }
    }
  
    return (
      <div className="col-xl-12">
        <h6 className="text-muted">Asignar unidad de venta</h6>

        <div className="card card-action">
          <div className="card-body ">
            <div className="row">
              <div className="col-lg-2">Sucursal:</div>
              <div className="col-lg-2">
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
              <div className="col-lg-4 text-center ">
                <AButton tp_tilde="Consultar información" tp_posit="top" bt_class="btn btn-label-youtube btn-sm" bt_funct={onClickListar} disabled={disabledb1} ic_class="bx bx-cloud-download bx-ms" vl_tpbtn={2} />&nbsp;
                <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
              </div>
            </div>
            <ThemeProvider theme={tableTheme}>
              <div className="card-datatable table-responsive">
                <MaterialReactTable
                  columns={columns}
                  data={lst}
                  state={{ rowSelection, isLoading: pending }}
                  initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
                  enableGrouping
                  // enableRowSelection  // activa el check para sleccionar 
                  //getRowId={(row) => row.Code}
                  positionToolbarAlertBanner="bottom"
                  onRowSelectionChange={setRowSelection}
                  enableStickyHeader enableColumnResizing
                  muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                  localization={MRT_Localization_ES}
                  enablePagination={true}
                  muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                  enableGlobalFilterModes
                  rowVirtualizerProps={{ overscan: 150 }}
                  enableRowVirtualization
  
                  displayColumnDefOptions={{
                    'mrt-row-actions': {
                      header: 'Options', //change header text
                      size: 120, //make actions column wider
                    },
                  }}
  
                  enableRowActions  
                  renderRowActions={({ row }) => (
                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                      <AButton tp_tilde="Asignar UVenta" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success" bt_funct={() =>handleOpen(row)} ic_class="bx  bx-ms bx-box bx-ms" vl_tpbtn={1} />
                    </Box>
                     
                  )}
                  muiCircularProgressProps={{ color: 'error' }}
                    muiSkeletonProps={{
                        animation: 'pulse',
                        height: 28,
                    }}
                />
              </div>
            </ThemeProvider>
          </div>
          {/*  Modal para editar la unidad de venta del articulo */}
          <Modal
            open={open}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style,  width: '80%', height: '90%', overflow: 'auto' }}>
              <button
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}
                className='btn btn-outline-dark btn-sm'
                onClick={()=>setOpen(false)}
              >
                <i className='fa fa-x'></i>
              </button>
              < GPAsignarUnidadVenta data={articulo} />
            </Box>
          </Modal>
        </div>
        <ToastContainer />
  
      </div>
    )
}
  
  
  
export default GPUnidadVenta

