import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SelectSucursal, SelectTipo } from '../../components/Selects/SelectsPrincipales'
import '../../App.css';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { AButton } from '../../components/Button';
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';
import $ from 'jquery'

import PdfEJEM from "../../components/pdf/PdfEjemplo";
import { PDFDownloadLink } from "@react-pdf/renderer"
import AsyncSelect from 'react-select/async';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box, Modal, Tooltip } from '@mui/material';
import PdfAlmacenT001 from "../../components/pdf/PdfAlmacenT001";
import { notify_warning, notify_valido, newDateF, newDateFValor } from '../../components/utils';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import ReportLiquidacion from './ReportView/ReporViewLiquidacion';
import { PdfLiquidacionPagos, PdfLiquidacionResumen, PdfLiquidacionTrans } from '../../components/pdf/PdfLiquidacion';
//TODO

const globalTheme = window.templateCustomizer.settings.style;

const moment = require('moment')
const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    // height: `29px`, // modifiado para que se muetren todos los codigos de vendedor 
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // height: `29px`, // modifiado para que se muetren todos los codigos de vendedor 
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const SelectChofer = (prosp) => {
  const setSelectFila = prosp.setSelectFila
  const fila = prosp.fila

  const [data, setdata] = useState([])

  const funListarChofer = useCallback(async (nom) => {
    //console.log("cadena:", cadena);
    let cadena = nom ? nom.toUpperCase() : "";
    const url = `/apiAlmacenLisChoferes?vcodEmpresa=${cook_idEmp}&cadenaBuscar=${cadena}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };

    try {

      const response = await fetch(url, opPe);
      if (!response.ok) {
        throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
        return [];
      }
      const data = response.json();
      // console.log("data :", data);
      return data;
    } catch (error) {
      console.error("Error en funListarChofer:", error);
      return [];
      throw error;
    }
  }, [])

  const [selecChofer, selectChofer] = useState([""]);

  const [cadenaBuscar, setCadenabuscar] = useState('');
  const handleSelectChange = (selectedOption) => {
    selectChofer(selectedOption);
    setSelectFila(selectedOption)
  };


  return (
    <div className='td2' style={{ position: 'relative', zIndex: '3' }} >
      <AsyncSelect
        styles={styles}
        placeholder="Busque por Cod./Desc."
        name="form-field-name"
        className=" from-control form-control-sm"
        value={fila}
        cacheOptions
        defaultOptions
        getOptionLabel={e => e.Placa + '  -  ' + e.Name}
        getOptionValue={e => e.Placa}
        loadOptions={funListarChofer}
        onChange={handleSelectChange}
        maxMenuHeight={150}
      />

    </div>
  )
}

export const REPLiquidicionTransportista = () => {
  const [inp_text_vehic, setInp_text_vehic] = useState('');
  //const [pending, setPending] = useState(false);
  const [dataReport, setdataReport] = useState([]);
  const changeInp_text_vehic = (event) => { setInp_text_vehic(event.target.value); }

  const dataTipo = 1;
  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD, setText_fechaD] = useState(new Date());

  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }
  const [sl_t_suc, setSl_t_suc] = useState('');
  const [sl_t_con, setSl_t_con] = useState('');
  const changeSl_t_suc = (event) => { setSl_t_suc(event.target.value); }
  const changeSl_t_con = (event) => { setSl_t_con(event.target.value); }
  const [pending, setPending] = useState(false);
  const [selecChofer, setselectChofer] = useState('');
  const [montoBancos, setmontoBancos] = useState([]);

  const listarMontoBancos = async () => {
    const url_dataReport = `/apiRepLiquiBancos?vcodEmpresa=${cook_idEmp}&conso=${sl_t_con}`;
    console.log("url_reprote:: ", url_dataReport);
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    setmontoBancos(data)
  }
  const onClickListar = async () => {
    // if (!emp_sl_t_suc || emp_setSl_t_suc === '9999') {
    //   notify_warning("¡Seleccione una sucursal!");
    //   return;
    // }
    setdataReport([])
    setPending(true)
    //var conso = ''
    // const fecha= moment(text_fechaD).format('YYYYMMDD');
    // console.log("fecha :", fecha);
    // const placa = selecChofer.Placa? selecChofer.Placa: '';
    // console.log("placa: ", placa);
    // console.log("body: ", body);
    // setPending(false)
    // return 
    //const url_dataReport = `/apiRepLiquidacion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&placa=${placa}&fecha=${fecha}`;
    const url_dataReport = `/apiRepLiquidacion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&conso=${sl_t_con}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    // console.log("data :", data);
    if (data.length === 0) {
      notify_warning("¡No de encontraron registros !")
    }
    await listarMontoBancos();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setmontoBancos([])
    setselectChofer('')
    setPending(false)
    setText_fechaD(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE-DOCUMENTOS A LIQUIDAR POR CONSOLIDADO PARA TRANSPOR_" + moment(new Date()).format('YYYYMMDD HH:mm')
    console.log("dataReport.length ::", dataReport.length);
    const showData = async () => {
      if (dataReport.length !== 0) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE-DOCUMENTOS A LIQUIDAR POR CONSOLIDADO PARA TRANSPOR_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() => [
    { header: 'VENDEDOR', accessorKey: 'SlpName' },
    { header: 'DOCUMENTO', accessorKey: 'Documento' },
    { header: 'CLIENTE', accessorKey: 'CardName' },
    { header: 'FECHA PAGO', accessorKey: 'DocDate' },
    {
      header: 'FECHA DOC', accessorKey: 'FechaDoc',
      Cell: ({ cell }) => {
        return moment(cell.value).format('YYYY-MM-DD');
      }
    },
    { header: 'TOTAL', accessorKey: 'DocTotal' },
    { header: 'SALDO', accessorKey: 'Saldo' },
    { header: 'TOTAL PAGADO ', accessorKey: 'SumApplied' },
    { header: 'NUM. DOCUMENTO ', accessorKey: 'DocNum' },
    { header: 'TIPO DE PAGO', accessorKey: 'Tipo' },
    { header: 'BANCO', accessorKey: 'Nombre_Banco' },

    { header: 'ENTREGA', accessorKey: 'Entrega' },

  ], []);

  const [openModal, setOpenModal] = useState(false);
  const hanOpenModal = () => {
    if (dataReport.length == 0) {
      notify_warning("No existen datos, verificar campos")
      return
    }
    setOpenModal(true);
  }
  const hanClosModal = () => { setOpenModal(false); }


  return (
    <div>
      <div className="card card-action">
        <div className="card-body ">
          <div className="row">
            <div className="col-lg-3">
              <label className="form-label mb-0 " >Sucursal:</label>
              <SelectSucursal id="veh_slc_suc" value={sl_t_suc} onChange={changeSl_t_suc} />
            </div>
            <div className="col-lg-3 col-sm-3" style={{ zIndex: '3' }}>
              <label className="form-label mb-0 " >Fecha .:</label>
              <DatePicker
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD}
                onChange={date => setText_fechaD(date)}
                dateFormat='yyyy-MM-dd'
              />
            </div>

            <div className="col-lg-3">
              <label className="form-label mb-0 " >Consolidado:</label>

              <SelectTipo id="slc_t_tga" url={`/apiConsulCON?vcodEmpresa=${cook_idEmp}&vcodSucursal=${sl_t_suc}&vFecha=${moment(text_fechaD).format('YYYY-MM-DD')}`} value={sl_t_con} onChange={changeSl_t_con} />
            </div>
            <div className="col-lg-3 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-label-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              {
                dataReport.length != 0 && (
                  <>
                    <AButton tp_tilde="Visualizar Rep View" tp_posit="top" bt_class="btn btn-label-info btn-sm" bt_funct={hanOpenModal} ic_class="bx bxs-spreadsheet bx-ms" vl_tpbtn={2} />
                    <PDFDownloadLink className="btn btn-sm  btn-label-youtube" document={<PdfLiquidacionTrans datallegada={dataReport} dataBancos={montoBancos} codigoCons={sl_t_con} />} fileName="R001_LIQUIDACION_TRASPORTISTA.pdf">
                      {({ blob, url, loading, error }) =>
                        loading ? 'Cargando PDF...' : <i className="bx bx-xs bxs-file-pdf" ></i>
                      }
                    </PDFDownloadLink>
                  </>

                )
              }

              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />

            </div>
          </div>
          <hr />

          <div className="card-datatable table-responsive ">
            <MaterialReactTable
              columns={columnsReport}
              data={dataReport}
              state={{ isLoading: pending }}
              initialState={{
                density: 'compact', showColumnFilters: false,
                showGlobalFilter: false,
                columnVisibility: { clave: false, vlSema: false, },
              }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              localization={MRT_Localization_ES}
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 150 }}
              enableRowVirtualization
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 120, //make actions column wider
                },
              }}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}

            />
          </div>
          {
            montoBancos.length != 0 && (
              <>
                <div className="col mt-3">
                  <div className="container text-center">
                    <h5>Pago  por entidades</h5>
                  </div>
                  <div >
                    <table className="table table-sm" id='tab_BCECliente' style={{ fontSize: '12px', zIndex: '2' }}>
                      <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
                        <tr>
                          <th>Tipo</th>
                          <th>Monto</th>
                        </tr>
                      </thead>
                      <tbody>
                        {montoBancos.map((banco, index) => (
                          <tr key={index} className="vervalidar">
                            <td className='td1'>{banco.Tipo}</td>
                            <td className='td2'>{banco.Pago}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )
          }



          <Modal
            open={openModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: '100%', height: '100%', overflow: 'auto' }}>
              <button
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}
                className='btn btn-outline-dark btn-sm'
                onClick={hanClosModal}
              >
                <i className='fa fa-x'></i>
              </button>
              <div className="py-3" style={{ userSelect: 'none' }}>
                <ReportLiquidacion
                  fecha={moment(text_fechaD).format('YYYY-MM-DD')}
                  placa={selecChofer.Placa}
                  data={dataReport}
                  dataBancos={montoBancos}

                />
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  )
}
export const REPRporteCajaPorDia = () => {
  
  const [inp_text_vehic, setInp_text_vehic] = useState('');
  //const [pending, setPending] = useState(false);
  const [dataReport, setdataReport] = useState([]);

  const dataTipo = 2;
  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD, setText_fechaD] = useState(new Date());

  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [sl_t_suc, setSl_t_suc] = useState('');
  const [sl_t_con, setSl_t_con] = useState('');
  const changeSl_t_suc = (event) => { setSl_t_suc(event.target.value); }
  const changeSl_t_con = (event) => { setSl_t_con(event.target.value); }
  const [pending, setPending] = useState(false);
  const [selecChofer, setselectChofer] = useState('');
  const [montoBancos, setmontoBancos] = useState([]);
  const [fechaVisible, setFechaVisible] = useState(true)

  const onClickListar = async () => {
    /** Descomentar despues */
    /**if (newDateF() === newDateFValor(text_fechaD)) {
      console.log("entra");
      setFechaVisible(true)
      
    }else{
      console.log("no entra");
      setFechaVisible(false)
    }*/
    // if (!emp_sl_t_suc || emp_setSl_t_suc === '9999') {
    //   notify_warning("¡Seleccione una sucursal!");
    //   return;
    // }
    setdataReport([])
    setPending(true)
    //var conso = ''
    const fecha = moment(text_fechaD).format('YYYY-MM-DD');
    // console.log("fecha :", fecha);
    // const placa = selecChofer.Placa? selecChofer.Placa: '';
    // console.log("placa: ", placa);
    // console.log("body: ", body);
    // setPending(false)
    // return 
    //const url_dataReport = `/apiRepLiquidacion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&placa=${placa}&fecha=${fecha}`;
    const url_dataReport = `/apiRepLiquidacion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&fecha=${fecha}&suc=${sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    // console.log("data :", data);
    if (data.length === 0) {
      notify_warning("¡No de encontraron registros !")
    }
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setmontoBancos([])
    setselectChofer('')
    setPending(false)
    setText_fechaD(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "R001_RESUMEN_CAJA_" + moment(new Date()).format('YYYYMMDD HH:mm')
    // console.log("dataReport.length ::", dataReport.length);
    const showData = async () => {
      if (dataReport.length !== 0) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "R001_RESUMEN_CAJA_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickCancelarItemConsolidadoPagos = async (codConsolidado) => {
    Swal.fire({
      title: 'ADVERTENCIA',
      text: '¿Desea eliminar pagos del consolidado '+codConsolidado+ '?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      customClass: { container: 'my-swal' }
    }).then(async (result) => {
      if (result.isConfirmed) {

        Swal.fire({
          title: 'Procesando...',
          html: 'Se esta consultado datos.',
          didOpen: () => { Swal.showLoading() },
          customClass: { container: 'my-swal' },
          allowOutsideClick: false
        })
        const url_dataReport = `/apiCancelarItemsConsolidado?vcodEmpresa=${cook_idEmp}&codConsolidado=${codConsolidado}`;
        const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
        const response = await fetch(url_dataReport, opPe);
        const data = await response.json();
        console.log("onClickCancelarItemConsolidadoPagos => data", data);
        setTimeout(() => {
          notify_valido("Se proceso correctamente")
          Swal.close()
          onClickListar()
        }, 6000);

       
      }
    })

  }
  
  useEffect(() => {
    // console.log("text_fechaD ", text_fechaD);
    // console.log("newDateFValor(text_fechaD) ", newDateFValor(text_fechaD));
    //  //const Fecha = 
    // console.log("newDateF", newDateF());
    if (newDateF() === newDateFValor(text_fechaD)) {
      console.log("entra");
      setFechaVisible(true)
      
    }else{
      console.log("no entra");
      setFechaVisible(false)
    }
    
  }, [text_fechaD]);
  const columnsReport = useMemo(() => [
    { header: 'TIPO', accessorKey: 'Tipo' },
    { 
      header: 'CONSOLIDADO ', 
      accessorKey: 'Consolidado',
      size: 250,
      Cell: ({ row }) => (
      
        <div className="row">
          <div className="col-12">
          {
            (row.original.Consolidado && fechaVisible) && (
              <AButton 
                tp_tilde="Eliminar Pagos" 
                tp_posit="top" 
                bt_class="btn btn-sm btn-icon btn-label-warning"  
                bt_funct={() => onClickCancelarItemConsolidadoPagos(row.original.Consolidado)} 
                ic_class="bx bx-ms bxs-trash" vl_tpbtn={1} />
            )
          }&nbsp;
          {row.original.Consolidado}
          </div>
      
        </div>
      ),  },
    { header: 'CHOFER', accessorKey: 'Chofer' },
    { header: 'IMPORTE', accessorKey: 'Importe' },
    { header: 'EFECTIVO', accessorKey: 'Efectivo' },
    { header: 'DEPOSITO', accessorKey: 'Deposito' },
    { header: 'ABONO', accessorKey: 'Abono' },

  ], [fechaVisible]);

  const [openModal, setOpenModal] = useState(false);
  const hanOpenModal = () => {
    if (dataReport.length == 0) {
      notify_warning("No existen datos, verificar campos")
      return
    }
    setOpenModal(true);
  }
  const hanClosModal = () => { setOpenModal(false); }
  

  return (
    <div>
      <div className="card card-action">
        <div className="card-body ">
          <div className="row">
            <div className="col-lg-1">Sucursal:</div>
            <div className="col-lg-2">
              <SelectSucursal id="veh_slc_suc" value={sl_t_suc} onChange={changeSl_t_suc} />
            </div>
            <div className="col-lg-1">Fecha:</div>
            <div className="col-lg-2" style={{ zIndex: 1000 }}>
              <DatePicker
                id="fecha"
                locale="es"
                className="form-control form-control-sm text-center"
                selected={text_fechaD}
                onChange={date => setText_fechaD(date)}
                dateFormat='yyyy-MM-dd'
              />
            </div>

            <div className="col-lg-3 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-label-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              {/* <AButton tp_tilde="Visualizar Rep View" tp_posit="top" bt_class="btn btn-label-info btn-sm" bt_funct={hanOpenModal} ic_class="bx bxs-spreadsheet bx-ms" vl_tpbtn={2} /> */}
              <PDFDownloadLink className="btn btn-sm  btn-label-youtube" document={<PdfLiquidacionResumen fecha={moment(text_fechaD).format('YYYY-MM-DD')} datallegada={dataReport} dataBancos={montoBancos} codigoCons={sl_t_con} />} fileName="R001_RESUMEN_CAJA.pdf">
                {({ blob, url, loading, error }) =>
                  loading ? 'Cargando PDF...' : <i className="bx bx-xs bxs-file-pdf" ></i>
                }
              </PDFDownloadLink>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />

            </div>
          </div>
          <hr />

          <div className="card-datatable table-responsive ">
            <MaterialReactTable
              columns={columnsReport}
              data={dataReport}
              state={{ isLoading: pending }}
              initialState={{
                density: 'compact', showColumnFilters: false,
                showGlobalFilter: false,
                columnVisibility: { clave: false, vlSema: false, },
              }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              localization={MRT_Localization_ES}
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 150 }}
              enableRowVirtualization
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 120, //make actions column wider
                },
              }}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}

            />
          </div>
          {
            montoBancos.length != 0 && (
              <>
                <div className="col mt-3">
                  <div className="container text-center">
                    <h5>Pago  por entidades</h5>
                  </div>
                  <div >
                    <table className="table table-sm" id='tab_BCECliente' style={{ fontSize: '12px', zIndex: '2' }}>
                      <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
                        <tr>
                          <th>Tipo</th>
                          <th>Monto</th>
                        </tr>
                      </thead>
                      <tbody>
                        {montoBancos.map((banco, index) => (
                          <tr key={index} className="vervalidar">
                            <td className='td1'>{banco.Tipo}</td>
                            <td className='td2'>{banco.Pago}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )
          }



          <Modal
            open={openModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: '100%', height: '100%', overflow: 'auto' }}>
              <button
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}
                className='btn btn-outline-dark btn-sm'
                onClick={hanClosModal}
              >
                <i className='fa fa-x'></i>
              </button>
              <div className="py-3" style={{ userSelect: 'none' }}>
                {/* <ReportLiquidacion 
              fecha = { moment(text_fechaD).format('YYYY-MM-DD')  }
              placa = {selecChofer.Placa}
              data={dataReport}
              dataBancos={montoBancos} 
            
              /> */}
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export const REPLiquidicionVendedor = () => {
  const dataTipo = 3;
  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  const [text_fechaD, setText_fechaD] = useState(new Date());

  const [pending, setPending] = useState(false);
  const [slcVendedor, setSlcVendedor] = useState([""]);
  const [dataReport, setdataReport] = useState([]);


  const funSlcVendedor = async (inputValue) => {
    console.log(inputValue);
    const url = '/apiVendedores?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    console.log("url:", url);
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }


  const onClickListar = async () => {
    // if (!emp_sl_t_suc || emp_setSl_t_suc === '9999') {
    //   notify_warning("¡Seleccione una sucursal!");
    //   return;
    // }
    setdataReport([])
    setPending(true)
    const fecha = moment(text_fechaD).format('YYYY-MM-DD');
    let arrvVend = slcVendedor.map(option => option.Code)

    const url_dataReport = `/apiRepLiquidacion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&fecha=${fecha}&vendedor=${arrvVend}`;
    console.log("url_reprote:: ", url_dataReport);
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    if (data.length === 0) {
      notify_warning("¡No de encontraron registros !")
    }
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE_LIQUIDACION_VENDEDOR_" + moment(new Date()).format('YYYYMMDD HH:mm')
    console.log("dataReport.length ::", dataReport.length);
    const showData = async () => {
      if (dataReport.length !== 0) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE_LIQUIDACION_VENDEDOR_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() => [
    { header: 'CODIGO VENDEDOR', accessorKey: 'SlpName' },
    { header: 'NOMBRE CLIENTE', accessorKey: 'CardName' },
    { header: 'ZONA', accessorKey: 'Zona' },
    {
      header: 'FECHA DOCUMENTO', accessorKey: 'DocDate',
      Cell: ({ cell }) => moment(cell.value).format('YYYY-MM-DD')
    },
    { header: 'DOCUMENTO', accessorKey: 'Documento' },
    { header: 'TIPO', accessorKey: 'Tipo' },
    { header: 'TOTAL DOCUMENTO', accessorKey: 'DocTotal' },
    { header: 'SALDO', accessorKey: 'Saldo' },
    { header: 'APLICADO', accessorKey: 'SumApplied' },
    { header: 'NUMERO DOCUMENTO', accessorKey: 'DocNum' }
  ], []);

  // const hanOpenModal = () => {
  //   if (dataReport.length == 0 ){
  //     notify_warning("No existen datos, verificar campos")
  //     return
  //   }  
  // }


  return (
    <div>
      <div className="card card-action">
        <div className="card-body ">
          <div className="row">

            <div className="col-lg-1">Fecha:</div>
            <div className="col-lg-2" style={{ zIndex: 1000 }}>
              <DatePicker
                id="fecha"
                locale="es"
                className="form-control form-control-sm text-center"
                selected={text_fechaD}
                onChange={date => setText_fechaD(date)}
                dateFormat='yyyy-MM-dd'
              />
            </div>
            <div className="col-lg-2">Vendedor:</div>
            <div className="col-lg-3" style={{ zIndex: 3 }}>
              <AsyncSelect
                isMulti
                styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm  "
                value={slcVendedor}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code + ' - ' + e.Name}
                getOptionValue={e => e.Code}
                loadOptions={funSlcVendedor}
                //onChange={setSlcVendedor}
                //maxMenuHeight={150}
                onChange={(e) => {
                  setSlcVendedor(e)
                  setTimeout(() => {
                    $('.css-wsp0cs-MultiValueGeneric').each(function () {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split('-')[0].trim();
                      $(this).text(textoCorto);
                    });
                  }, 1000)
                  // setTimeout(() =>{
                  //   $('.css-1p3m7a8-multiValue [class^=" css-"]').each(function() {
                  //       var textoCompleto = $(this).text();
                  //       console.log("textoCompleto", textoCompleto)
                  //       var textoCorto = textoCompleto.split(' -')[0].trim();
                  //       console.log("textoCorto", textoCorto)

                  //       $(this).text(textoCorto);
                  //   });
                  // },1000)
                }}
              />

              {/* <SelectTipo id="slc_t_tga" url={`/apiConsulCON?vcodEmpresa=${cook_idEmp}&vcodSucursal=${sl_t_suc}&vFecha=${moment(text_fechaD).format('YYYY-MM-DD')}`} value={sl_t_con} onChange={changeSl_t_con}  /> */}
              {/* <SelectChofer setSelectFila={setselectChofer} fila={selecChofer} /> */}
              {/* <input type="text" id="txtVehi" name="txtVehi" className="form-control form-control-sm"  autoComplete='off' value={inp_text_vehic} onChange={changeInp_text_vehic} required={true} /> */}
            </div>
            <div className="col-lg-3 text-end">
              {/* <PDFDownloadLink document={<PdfEJEM datallegada={dataReport} />} fileName="ejemplo">
                <AButton tp_tilde="Guardar Tipo de mesa" bt_descr="EXPORTAR PDF" id="btn1" tp_posit="top" bt_class="btn btn-sm  btn-label-youtube"
                    bt_funct={onClickConsultar} disabled={({loading}) => (loading  ? true  : false)} 
                    ic_class="bx bx-xs bxs-file-pdf " vl_tpbtn={2} 
                />
                </PDFDownloadLink> */}
              <Tooltip title="Consultar información" placement="top">

                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-label-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              {/* <AButton tp_tilde="Visualizar Rep View" tp_posit="top" bt_class="btn btn-label-info btn-sm" bt_funct={hanOpenModal} ic_class="bx bxs-spreadsheet bx-ms" vl_tpbtn={2} />
                <PDFDownloadLink className="btn btn-sm  btn-label-youtube" document={<PdfLiquidacionTrans datallegada={dataReport} />} fileName="R001_LIQUIDACION_TRASPORTISTA.pdf">
                    {({ blob, url, loading, error }) =>
                        loading ? 'Cargando PDF...' : <i className="bx bx-xs bxs-file-pdf" ></i>
                    }
                </PDFDownloadLink> */}
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />

            </div>
          </div>
          {/* <div className="row ">
            <div className="col-lg-12 text-end">
              <Tooltip title="Consultar información" placement="top">

                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-label-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              <AButton tp_tilde="Visualizar Rep View" tp_posit="top" bt_class="btn btn-label-info btn-sm" bt_funct={hanOpenModal} ic_class="bx bxs-spreadsheet bx-ms" vl_tpbtn={2} />
              <PDFDownloadLink className="btn btn-sm  btn-label-youtube" document={<PdfAlmacenT001 datallegada={dataReport} />} fileName="R001_SALIDA_DE_PRODUCTOS_DE_ALMACEN.pdf">
              {({ blob, url, loading, error }) => 
                  loading ? 'Cargando PDF...' : <i className="bx bx-xs bxs-file-pdf" ></i>
                }
                </PDFDownloadLink>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div> */}
          <hr />

          <div className="card-datatable table-responsive ">
            <MaterialReactTable
              columns={columnsReport}
              data={dataReport}
              state={{ isLoading: pending }}
              initialState={{
                density: 'compact', showColumnFilters: false,
                showGlobalFilter: false,
                columnVisibility: { clave: false, vlSema: false, },
              }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              localization={MRT_Localization_ES}
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 150 }}
              enableRowVirtualization
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 120, //make actions column wider
                },
              }}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}


export const REPLiquidicionTrasTicket = () => {
  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD, setText_fechaD] = useState(new Date());
  const dataTipo = 1;
  const [sl_t_suc, setSl_t_suc] = useState('');
  const [sl_t_con, setSl_t_con] = useState('');
  // suscursal
  const changeSl_t_suc = (event) => { setSl_t_suc(event.target.value); }
  const changeSl_t_con = (event) => {
    setmontoBancos([]);
    const selectedText = event.target.options[event.target.selectedIndex].text;
    // console.log("selectedText:: ", selectedText);
    // recortado de la cadena para extaer el nombre 
    const index = selectedText.indexOf('/');
    const nombreFinal = index !== -1 ? selectedText.substring(index + 1) : selectedText;
    // console.log("nombre final::",nombreFinal );
    setnameChofer(nombreFinal);

    setSl_t_con(event.target.value); // nombre del chofer 
  }
  const [montoBancos, setmontoBancos] = useState([]);
  const [nameChofer, setnameChofer] = useState('');

  const listarMontoBancos = async () => {
    // validacion del consolidado
    if (sl_t_con == '') {
      notify_warning("Ingrese el cosolidado "); return;
    }
    const url_dataReport = `/apiRepLiquiBancos?vcodEmpresa=${cook_idEmp}&conso=${sl_t_con}`;
    // console.log( "url_reprote:: ", url_dataReport);
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    if (data.length == 0) {
      notify_warning("No se encontraron datos ")
    }
    setmontoBancos(data)
  }

  useEffect(() => {
    return () => {
      setmontoBancos([]);
    };
  }, []); // Solo volver a ejecutar el efecto si el contador cambia


  const onClickListar = async () => {

    await listarMontoBancos();
  };
  const onClickLimpiar = async () => {
    setmontoBancos([])
    setSl_t_con('')
    setText_fechaD(new Date())
  }

  // const [openModal, setOpenModal] = useState(false);
  // const hanClosModal = () => { setOpenModal(false); }


  return (
    <div>
      <div className="card card-action">
        <div className="card-body ">
          <div className="row">
            <div className="col-lg-3">
              <label className="form-label mb-0 " >Sucursal</label>
              <SelectSucursal id="veh_slc_suc" value={sl_t_suc} onChange={changeSl_t_suc} />
            </div>
            <div className="col-lg-3 col-sm-3" style={{ zIndex: '3' }}>
              <label className="form-label mb-0 " >Fecha </label>
              <DatePicker
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD}
                onChange={date => setText_fechaD(date)}
                dateFormat='yyyy-MM-dd'
              />
            </div>

            <div className="col-lg-4">
              <label className="form-label mb-0 " >Consolidado</label>

              <SelectTipo id="slc_t_tga" url={`/apiConsulConsolChofer?vcodEmpresa=${cook_idEmp}&vcodSucursal=${sl_t_suc}&vFecha=${moment(text_fechaD).format('YYYY-MM-DD')}`} value={sl_t_con} onChange={changeSl_t_con} />
            </div>
            <div className="col-lg-2 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>

              {
                montoBancos.length != 0 && (
                  <>
                      <PDFDownloadLink className="btn btn-sm  btn-label-youtube" document={<PdfLiquidacionPagos dataBancos={montoBancos} fecha={moment(text_fechaD).format('YYYY-MM-DD')} consolidado={sl_t_con} chofer={nameChofer} />} fileName="R004_VOUCHER_DE_PAGO.pdf">
                        {({ blob, url, loading, error }) =>
                          loading ? 'Cargando PDF...' : <i className="bx bx-xs bxs-file-pdf" ></i>
                        }
                      </PDFDownloadLink>
                  </>

                )
              }

              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />

            </div>
          </div>
          <hr />

          <div className="card-datatable table-responsive ">


          </div>
          {
            montoBancos.length != 0 && (
              <>
                <div className="col mt-3">
                  <div className="container text-center">
                    <h5>Pago  por entidades</h5>
                  </div>
                  <div className="row mb-3">
                    <div className="col-1"> Chofer  </div>
                    <div className="col-4">
                      <input type="text" className="form-control form-control-sm" autoComplete='off' defaultValue={nameChofer} />
                    </div>

                  </div>

                  <div >
                    <table className="table table-sm" id='tab_BCECliente' style={{ fontSize: '12px', zIndex: '2' }}>
                      <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
                        <tr>
                          <th>Tipo</th>
                          <th>Monto</th>
                        </tr>
                      </thead>
                      <tbody>
                        {montoBancos.map((banco, index) => (
                          <tr key={index} className="vervalidar">
                            <td className='td1'>{banco.Tipo}</td>
                            <td className='td2'>{banco.Pago}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )
          }



          {/* <Modal
            open={openModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: '100%', height: '100%', overflow: 'auto' }}>
              <button
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}
                className='btn btn-outline-dark btn-sm'
                onClick={hanClosModal}
              >
                <i className='fa fa-x'></i>
              </button>
              <div className="py-3" style={{ userSelect: 'none' }}>
                <ReportLiquidacion
                  fecha={moment(text_fechaD).format('YYYY-MM-DD')}
                  placa={selecChofer.Placa}
                  data={dataReport}
                  dataBancos={montoBancos}

                />
              </div>
            </Box>
          </Modal> */}
        </div>
      </div>
    </div>
  )
}


const RLIQTransportista = () => {
  return (
    <div>
      <small className="text-light fw-medium"> RLIQTransportista Basic Accordion</small>
      <div className="row">
        <div className="col-md mb-4 mb-md-2">
          <div className="accordion mt-3" id="accordionExample">
            <div className="card accordion-item ">
              <h2 className="accordion-header d-flex align-items-center" id="headingOne">
                <button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="true" aria-controls="accordionOne">
                  <i className="bx bx-bar-chart-alt-2 me-2"></i>
                  Liquidación Transportista
                </button>
              </h2>
              <div id="accordionOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body mt-2 mb-2">
                  <REPLiquidicionTransportista />
                </div>
              </div>
            </div>
            <div className="card accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionTwo" aria-expanded="false" aria-controls="accordionTwo">
                  <i className="fa-solid fa-money-bill-trend-up me-2"></i>
                  Reporte de caja por dia
                </button>
              </h2>
              <div id="accordionTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <REPRporteCajaPorDia />
                </div>
              </div>
            </div>
            <div className="card accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionThree" aria-expanded="false" aria-controls="accordionThree">
                  <i className="fa-solid fa-user-tag me-2"></i>
                  Liquidacion vendedor
                </button>
              </h2>
              <div id="accordionThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <REPLiquidicionVendedor />
                </div>
              </div>
            </div>
            <div className="card accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFour" aria-expanded="false" aria-controls="accordionThree">
                  {/* <i claclassNamess="fa-solid fa-user-tag me-2"></i> */}
                  <i className="fa-solid fa-file me-2"></i>
                  Liquidacion  -  voucher de pago
                </button>
              </h2>
              <div id="accordionFour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <REPLiquidicionTrasTicket />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <ToastContainer />
    </div>

  )
}

export default RLIQTransportista