/* eslint-disable react/style-prop-object */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useCallback, useMemo} from 'react'
import { SelectSucursal,SelectTipo } from '../../components/Selects/SelectsPrincipales'
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';
import { notify_warning, notify_valido, selectTabla, notify_error } from '../../components/utils';
import $ from 'jquery'
import DataTable, { createTheme as createThemeS } from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
// import { createTheme, ThemeProvider } from '@mui/material';
//import '../../App.css';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import { ModalsSubPartidas } from '../../components/Modals/ModalsDetVen';
import "react-datepicker/dist/react-datepicker.css";
//import { AButton } from '../../components/Button';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MaterialReactTable } from 'material-react-table';
import { AButton } from '../../components/Button';
import { Box, Modal, Tooltip } from '@mui/material';
const globalTheme = window.templateCustomizer.settings.style;

const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');

const styles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: `#D2D5DC`,
    border: '1px solid #D2D5DC',
    boxShadow: state.isFocused ? null : null,
    backgroundColor: window.templateCustomizer.settings.style === 'light'
      ? '#fefff4'
      : '#2B2C40',
    color: window.templateCustomizer.settings.style === 'light'
      ? '#2B2C40'
      : '#fefff4',
    minHeight: `29px`,
    height: `29px`,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (window.templateCustomizer.settings.style === 'light' ? '#fefff4' : '#2B2C40')
      : (window.templateCustomizer.settings.style === 'light' ? '#fefff4' : '#2B2C40'),
    color: state.isSelected
      ? 'white'
      : (window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: window
      .templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4',
    fontSize: `0.75rem`,
  }),

  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4',
  }),
};
const emp_url_tdoc ='/apiTipoDocumento?vcodEmpresa='+cook_idEmp;
export const MGRMantenimientoVehiculos = () => {

  const veh_url_lstchofer ='/apiConsulChofer';
  const [veh_lst, setVeh_lst] = useState([]);
  const [pending, setpending] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [disabledb1, setdisabledb1] = useState(false);
  // const [disabledb2, setdisabledb2] = useState(false);
  // const [disabledb3, setdisabledb3] = useState(false);

  const [veh_sl_t_suc, setVehSl_t_suc] = useState('');
  const [veh_sl_t_cho, setVehSl_t_cho] = useState([""]);
  const [veh_sl_t_proveedor, setVehSl_t_proveedor] = useState([""]);
  const [veh_txt_nplaca, setVeh_txt_nplaca] = useState('');
  // const [veh_txt_nlic, setVeh_txt_nlic] = useState('');
  const [veh_txt_modelo, setVeh_txt_modelo] = useState('');
  const [veh_txt_marca, setVeh_txt_marca] = useState('');
  const [veh_txt_peso, setVeh_txt_peso] = useState('');
  const [veh_txt_volu, setVeh_txt_volu] = useState('');
  const [veh_txt_detalle, setVeh_txt_detalle] = useState('');
  const [veh_cbx_estado, setVeh_cbx_estado] = useState(true);


  const changeVeh_cbx_estado = (event) => { 
    setVeh_cbx_estado(!veh_cbx_estado); 
  }

  const veh_changeSl_t_suc = (event) => {
    //veh_Select2_Chofer()
    setVehSl_t_suc(event.target.value);
    //alert(event.target.value)
    // var inputValue=''
    // veh_Select2_Chofer(inputValue, event.target.value)
  }
  const changeVeh_txt_nplaca = (event) => { setVeh_txt_nplaca(event.target.value); }
  // const changeVeh_txt_ncodigo= (event) => { setVeh_txt_ncodigo(event.target.value); }
  // const changeVeh_txt_nlic= (event) => { setVeh_txt_nlic(event.target.value); }
  const changeVeh_txt_modelo= (event) => { setVeh_txt_modelo(event.target.value); }
  const changeVeh_txt_marca= (event) => { setVeh_txt_marca(event.target.value); }
  const changeVeh_txt_peso= (event) => { setVeh_txt_peso(event.target.value); }
  const changeVeh_txt_volu= (event) => { setVeh_txt_volu(event.target.value); }
  const changeVeh_txt_detalle = (event) => { setVeh_txt_detalle(event.target.value); }
 
  const veh_Select2_Chofer = useCallback( async (inputValue) => {
    //console.log("veh_sl_t_suc",veh_sl_t_suc);
    let body = { vcodEmpresa: cook_idEmp,vcodSucursal: veh_sl_t_suc, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(veh_url_lstchofer,opPe);
    const data = await response.json();
    //console.log('select ',data);
    return data;
  },[veh_sl_t_suc])

  const veh_Select2_proveedor = async (inputValue) => {
    
    const url = '/apiConsulProveedorTransportistas'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue  };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const resp = await fetch(url,opPe);//cambiar por proveedores
    const data = await resp.json();
    //console.log('select ',data);
    return data;
  }

  
  
  

  const veh_body = { vcodEmpresa:cook_idEmp, veh_sl_t_suc: veh_sl_t_suc, veh_sl_t_cho: veh_sl_t_cho, veh_txt_nplaca: veh_txt_nplaca,veh_txt_modelo: veh_txt_modelo,veh_sl_t_proveedor:veh_sl_t_proveedor,
  veh_txt_peso: veh_txt_peso, veh_txt_volu: veh_txt_volu,veh_txt_marca: veh_txt_marca, veh_txt_detalle:veh_txt_detalle, veh_cbx_estado:veh_cbx_estado, usu_id: cookies.get('dtUser_id') };
  const veh_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(veh_body) }

  const onClickGuardarVehiculos = async() =>{
    //try {
      const ins_veh_url = '/gm-veh-insert'
      
      
      $("#veh_btn_guardar").attr("disabled",true)
      $("#icon_veh_btn_guardar").addClass("d-none")
      $("#spinner_veh_btn_guardar").removeClass("d-none")
      //if( veh_sl_t_suc === "" || veh_sl_t_cho === "" || veh_txt_nplaca === "" || veh_txt_detalle === ""){
      if( veh_sl_t_suc === "" || veh_sl_t_cho === "" || veh_txt_nplaca === "" || veh_txt_detalle === "" ){
        notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
      }else{
        const response = await fetch(ins_veh_url,veh_opPe);
        const resdatos = await response.json();
        if (resdatos.vl_api === 1){
          notify_warning("ERROR AL AGREGAR O REGISTRO YA EXISTE, VERIFICAR DATOS")
        }
        if (resdatos.vl_existe === 1){
          notify_warning("CODIGO YA EXISTE!!!")
        }
        if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
          notify_valido("CODIGO SE REGISTRO CORRECTAMENTE!!!")
          onClickClearInputsVehiculos();
          if($(".rdt_Table").hasClass("table")){
            onClickListarVehiculos();
          }
        }      
      }
      $("#veh_btn_guardar").attr("disabled",false)
      $("#icon_veh_btn_guardar").removeClass("d-none")
      $("#spinner_veh_btn_guardar").addClass("d-none")
    // } catch (error) {
    //   setdisabled(false)
    //   setpending(false)
    //   notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    // }
  }
  const onClickListarVehiculos = async() =>{
    try {
      if(!veh_sl_t_suc||veh_sl_t_suc==="9999"){
        notify_warning(' Debes seleccionar una sucursal.');
      }else{
        
        const lst_veh_url = '/apilistVehiculos'
        const lst_veh_body = {vcodEmpresa: cook_idEmp, sl_t_suc: veh_sl_t_suc };
        const lst_veh_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(lst_veh_body) }
        const columns = [
          // Se oculto par que el usurio no tenga opcion de editar 
          // { cell:(row) =>
          //   <div className="align-items-center">
          //       <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDeleteInputsVehiculos}  id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
          //   </div>
          //   ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
          { name: 'SUCURSAL',  selector: row => row.U_AB_SUCU,           cellExport: row => row.U_AB_SUCU,   sortable: true, width: "8rem", omit:true},
          { name: 'SUCURSAL',     selector: row => row.PrcName,     cellExport: row => row.PrcName,    sortable: true, width: "8rem"},

          { name: 'CHOFER COD',  selector: row => row.U_AB_CODCHO,           cellExport: row => row.U_AB_CODCHO,           sortable: true },
          
          { name: 'NOMBRE CHOFER',  selector: row => row.Nombre,           cellExport: row => row.Nombre, sortable: true,width: "15rem"  },
          { name: 'N DE PLACA',  selector: row => row.U_SYP_VEPL,           cellExport: row => row.U_SYP_VEPL, sortable: true ,width: "8rem"},

          { name: 'CODIGO',  selector: row => row.Code,           cellExport: row => row.Code,           sortable: true,omit:true},
          { name: 'NOMBRE',  selector: row => row.Name,           cellExport: row => row.Name,           sortable: true,omit:true },
          { name: 'NOMBRE',  selector: row => row.CardName,           cellExport: row => row.CardName,           sortable: true,width: "15rem"  },
          // { name: 'DocEntry',    selector: row => row.DocEntry,         cellExport: row => row.DocEntry,         sortable: true },
          // { name: 'Canceled',   selector: row => row.Canceled, cellExport: row => row.Canceled, sortable: true },
          // { name: 'Object',   selector: row => row.Object, cellExport: row => row.Object, sortable: true },
          { name: 'MARCA',   selector: row => row.U_SYP_VEMA, cellExport: row => row.U_SYP_VEMA, sortable: true },
          { name: 'MODELO',   selector: row => row.U_SYP_VEMO, cellExport: row => row.U_SYP_VEMO, sortable: true },
          { name: 'PLACA',   selector: row => row.U_SYP_VEPL, cellExport: row => row.U_SYP_VEPL, sortable: true,omit:true },
          { name: 'U_SYP_VECI',   selector: row => row.U_SYP_VECI, cellExport: row => row.U_SYP_VECI, sortable: true },
          { name: 'COD.PROVEEDOR',   selector: row => row.U_AB_CODPROV, cellExport: row => row.U_AB_CODPROV, sortable: true, width: "11rem" },
          { name: 'CAP.PESO',   selector: row => row.U_AB_CAPPES, cellExport: row => row.U_AB_CAPPES, sortable: true, width: "8rem" },
          { name: 'CAP.VOLUMEN',   selector: row => row.U_AB_CAPVOL, cellExport: row => row.U_AB_CAPVOL, sortable: true, width: "8rem" },
          { name: 'U_AB_APLDIS',   selector: row => row.U_AB_APLDIS, cellExport: row => row.U_AB_APLDIS, sortable: true,  omit:false },
        ];
        //const columns = lst_veh_columns
        setpending(true)
        setdisabledb1(true)
        const response = await fetch(lst_veh_url,lst_veh_opPe);
        const data = await response.json();
        //console.log(data);
        const tableData = { columns, data };
        setVeh_lst(tableData)
        setpending(false)
        setdisabledb1(false)

        $(".veh_tbl_lista .rdt_Table").addClass("table");
      }
    } catch (error) {
      setdisabled(false)
      setpending(false)
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
   
  }
  
  // const selectTablaVeh = () => {
  //   $('.rdt_TableBody').on('click', '.rdt_TableCell', function () {
  //     $('.rdt_TableRow').removeClass("table-danger");
  //     $($(this).parents(".rdt_TableRow")).addClass("table-danger");       
  //   })
  // };
  const handleRowClicked = (row) => {
    //alert("hola")
    setVehSl_t_suc(row.U_AB_SUCU)
    setVehSl_t_cho({Code: row.U_AB_CODCHO, Name: row.Nombre})
    setVeh_txt_nplaca(row.Code)
    setVehSl_t_proveedor({CardCode: row.U_AB_CODPROV, CardName: row.CardName})
    setVeh_txt_detalle(row.U_SYP_VECI)
    setVeh_txt_modelo(row.U_SYP_VEMO)
    setVeh_txt_marca(row.U_SYP_VEMA)
    setVeh_txt_peso(row.U_AB_CAPPES??'')
    setVeh_txt_volu(row.U_AB_CAPVOL)
    //alert(row.U_AB_APLDIS)|| row.U_AB_APLDIS !== 'null'
    setVeh_cbx_estado(((row.U_AB_APLDIS === 'Y' || row.U_AB_APLDIS !== 'N') && (row.U_AB_APLDIS !== 'null')) ? true : false )
    selectTabla("veh_tbl_lista")
    $("#veh_btn_guardar").addClass('d-none')
    $("#veh_btn_editar").removeClass('d-none')
    setdisabled(true)
    $("#veh_btn_gmodificado").addClass('d-none')
    $("#veh_btn_mlista").addClass('d-none')


  };
  const onClickClearInputsVehiculos = (row) => {
   
    //setVehSl_t_suc("")
    setVehSl_t_cho("")
    setVehSl_t_proveedor("")
    setVeh_txt_nplaca("")
    // setVeh_txt_nlic("")
    setVeh_txt_modelo("")
    setVeh_txt_peso("")
    setVeh_txt_volu("")
    setVeh_txt_marca("")
    setVeh_txt_detalle("")
    $('.rdt_TableRow').removeClass("table-danger");
    $("#veh_btn_guardar").removeClass('d-none')
    $("#veh_btn_editar").addClass('d-none')
    $("#veh_btn_gmodificado").addClass('d-none')
    setdisabled(false)
    $("#veh_txt_cod").attr('disabled',false)
    $("#veh_btn_mlista").removeClass('d-none');
    $("#veh_txt_nplaca").attr('disabled',false) 
    $("#veh_slc_suc").attr('disabled',false) 

  };
  const onClickEditInputsVehiculos = (row) => {
    setdisabled(false)
    $("#veh_btn_editar").addClass('d-none')
    $("#veh_btn_gmodificado").removeClass('d-none')
    setTimeout(()=>{ 
      $("#veh_txt_nplaca").attr('disabled',true) 
      $("#veh_slc_suc").attr('disabled',true) 
  },500)
  };
  const onClickGModifcadoVehiculos = async() =>{
    const upd_veh_url = '/gm-veh-update'  
    $("#veh_btn_gmodificado").attr("disabled",true)
    $("#icon_veh_btn_gmodificado").addClass("d-none")
    $("#spinner_veh_btn_gmodificado").removeClass("d-none")
    if( veh_sl_t_suc === "" || veh_sl_t_cho === "" || veh_txt_nplaca === "" || veh_txt_detalle === ""){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const veh_opPe = { method: "PUT", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(veh_body) }
      //console.log(veh_body);
      const response = await fetch(upd_veh_url,veh_opPe);
      const resdatos = await response.json();
      //console.log("modifi", resdatos);
      if (resdatos.vl_insert === 0){
        notify_warning("ERROR AL MODIFCAR, VERIFICAR DATOS!!!")
      }else if (resdatos.vl_insert === 1 ){
        onClickClearInputsVehiculos()
        onClickListarVehiculos()
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
      }
      
    }
    $("#veh_btn_gmodificado").attr("disabled",false)
    $("#icon_veh_btn_gmodificado").removeClass("d-none")
    $("#spinner_veh_btn_gmodificado").addClass("d-none")
  }
  const onClickDeleteInputsVehiculos = async(state) => {
    
    try {
      const del_veh_url = '/gm-veh-delete'
      const dtLlegada = await JSON.parse(state.target.id);
      //console.log("dtLlegada",dtLlegada); 
      const codigo = dtLlegada.Code;
      const del_veh_body = { vcodEmpresa:cook_idEmp, veh_txt_nplaca: codigo, usu_id: cookies.get('dtUser_id') };
      const del_veh_opPe = { method: "DELETE", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(del_veh_body) }
  
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const respondt = await fetch(del_veh_url,del_veh_opPe);
          const resdatos = await respondt.json();
          //console.log("resdatos",resdatos);
          if (resdatos.vl_insert === 0){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            onClickListarVehiculos()
            onClickClearInputsVehiculos()
          }
        }else if(response.isDenied){
          Swal.close();
          //Swal.fire('Información', 'No paso nada', 'error')
        }
      })
  
    } catch (error) {
      console.log(error);;
    }
  }
  createThemeS('solarized', {
    text: {
      primary: globalTheme === 'light'
        ? '#2B2C40'
        : '#fefff4',
      secondary: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
    },
    background: {
      default: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
    },
    context: {
      background: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
      text: '#FFFFFF',
    },
  });
  return(
    <>
      <div className="row">
        {/* <div className="col-lg-1">Codigo:</div>
        <div className="col-lg-2">
        <input type="text" id="veh_txt_ncodigo" name="veh_txt_ncodigo" className="form-control form-control-sm"  autoComplete='off' value={veh_txt_ncodigo} onChange={changeVeh_txt_ncodigo} disabled={disabled} />
        </div> */}
        <div className="col-lg-1">Sucursal:</div>
        <div className="col-lg-2">
          <SelectSucursal id="veh_slc_suc" value={veh_sl_t_suc} onChange={veh_changeSl_t_suc} disabled={disabled} />
        </div>
        <div className="col-lg-1">Chofer:</div>
        {/* <div className="col-lg-5" onClick={handleSelectClick}>  cacheOptions*/}
        <div className="col-lg-5" > 
          <AsyncSelect
            id="veh_sl2_chofer"
            styles={styles}
            placeholder="Busque por Cod./Desc."
            name="form-field-name"
            className="sele_pru from-control form-control-sm"  
            value={veh_sl_t_cho}
            
            defaultOptions
            getOptionLabel={e => e.Code +' - '+ e.Name}
            getOptionValue={e => e.Code }
            loadOptions={veh_Select2_Chofer}
            onChange={setVehSl_t_cho}
            // isClearable={true}
            //openMenuOnClick
            isDisabled={disabled}
          />
        </div>
        <div className="col-lg-2">
          <div className="row">
            <div className="col-lg-6">
              <label className="form-check-label" htmlFor="customCheckPrimary">Activo</label>
            </div>
            <div className="col-lg-6">
              <div className="form-check form-check-danger">
                <input className="form-check-input" type="checkbox" value="Y" id="customCheckPrimary" onChange={changeVeh_cbx_estado} checked={veh_cbx_estado} disabled={disabled}/>
              </div>
            </div>
          </div>
        </div>
         {/* <div className="col-lg-1">Nro.Lic:</div>
        <div className="col-lg-2">
          <input type="text" id="veh_txt_nplic" name="veh_txt_nplic" className="form-control form-control-sm"  autoComplete='off' value={veh_txt_nlic} onChange={changeVeh_txt_nlic} disabled={disabled} />
        </div>  */}
      </div>
      <div className="row">
        <div className="col-lg-1">N.Placa:</div>
        <div className="col-lg-2">
          <input type="text" id="veh_txt_nplaca" name="veh_txt_nplaca" className="form-control form-control-sm"  autoComplete='off' value={veh_txt_nplaca} onChange={changeVeh_txt_nplaca} disabled={disabled} />
        </div>
        <div className="col-lg-1">Proveedor:</div>
        <div className="col-lg-5">
          <AsyncSelect
            id="veh_sl2_chofer"
            styles={styles}
            placeholder="Busque por Cod./Desc."
            name="form-field-name"
            className="sele_pru from-control form-control-sm"  
            value={veh_sl_t_proveedor}
            cacheOptions
            defaultOptions
            getOptionLabel={e => e.CardCode +' - '+ e.CardName}
            getOptionValue={e => e.CardCode }
            loadOptions={veh_Select2_proveedor}
            onChange={setVehSl_t_proveedor}
            // isClearable={true}
            // openMenuOnClick={false}
            isDisabled={disabled}
          />
        </div>
        <div className="col-lg-1">Cer.Ins.:</div>
        <div className="col-lg-2">
          <input type="text" id="veh_txt_detalle" name="veh_txt_detalle" className="form-control form-control-sm"  autoComplete='off' value={veh_txt_detalle} onChange={changeVeh_txt_detalle} disabled={disabled} />
        </div>
        
        
      </div>
      <div className="row mb-2">
        <div className="col-lg-1">Marca:</div>
        <div className="col-lg-2">
          <input type="text" id="veh_txt_marca" name="veh_txt_marca" className="form-control form-control-sm"  autoComplete='off' value={veh_txt_marca} onChange={changeVeh_txt_marca} disabled={disabled} />
        </div>
        <div className="col-lg-1">Modelo:</div>
        <div className="col-lg-2">
          <input type="text" id="veh_txt_modelo" name="veh_txt_modelo" className="form-control form-control-sm"  autoComplete='off' value={veh_txt_modelo} onChange={changeVeh_txt_modelo} disabled={disabled} />
        </div>
        <div className="col-lg-1">Cap.Peso:</div>
        <div className="col-lg-2">
          <input type="number" id="veh_txt_peso" name="veh_txt_peso" className="form-control form-control-sm"  autoComplete='off' value={veh_txt_peso} onChange={changeVeh_txt_peso} disabled={disabled} />
        </div>
        <div className="col-lg-1">Cap.Volu.:</div>
        <div className="col-lg-2">
          <input type="number" id="veh_txt_volu" name="veh_txt_volu" className="form-control form-control-sm"  autoComplete='off' value={veh_txt_volu} onChange={changeVeh_txt_volu} disabled={disabled} />
        </div>
      </div>
      
      <div className="row ">
        <div className="col-md-4 "></div>
        <div className="col-md-4 text-center ">
          <button className="btn btn-primary btn-sm" id="veh_btn_guardar" onClick={onClickGuardarVehiculos} disabled={disabled}>
            <i className='bx bx-send' id="icon_veh_btn_guardar" ></i>
            <span className="spinner-border d-none" id="spinner_veh_btn_guardar" role="status" aria-hidden="true"></span>
            &nbsp;Guardar
          </button>
          {/* Bonton oculto para restringir al usurio de editar 
          <button className="btn btn-warning btn-sm d-none" id="veh_btn_editar" onClick={onClickEditInputsVehiculos}>
            <i className='bx bx-edit' ></i>&nbsp;Editar
          </button> */}
          <button className="btn btn-success btn-sm d-none" id="veh_btn_gmodificado" onClick={onClickGModifcadoVehiculos} >
            <i className='bx bx-send' id="icon_veh_btn_gmodificado" ></i>
            <span className="spinner-border d-none" id="spinner_veh_btn_gmodificado" role="status" aria-hidden="true"></span>
            &nbsp;GModificado
          </button>
          <button className="btn btn-dark btn-sm" id="veh_btn_cancelar" onClick={onClickClearInputsVehiculos}>
            <i className='bx bx-history' ></i>&nbsp;Cancelar
          </button>
          {/* <AButton 
            bt_class = "btn btn-info btn-sm" 
            bt_funct = {onClickListarVehiculos} disabled = {disabledb1} 
            ic_class = "bx bx-list-ul bx-ms" 
            vl_tpbtn = {2} /> */}
          <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={onClickListarVehiculos} disabled={disabledb1}>
            {(disabledb1 === true) ? <span className="spinner-border" role="status" aria-hidden="true"></span> : <i className='bx bx-list-ul'></i> }
            &nbsp;MLista
          </button>
        </div>
      </div>
      <div className="card-datatable table-responsive ">
        <DataTableExtensions  {...veh_lst} >
          <DataTable className="veh_tbl_lista"
              Header
              defaultSortField="id"
              defaultSortAsc={true}
              pagination
              responsive
              highlightOnHover
              progressPending={pending}
              noContextMenu="No hay registros para mostrar"
              onRowClicked={handleRowClicked}
              //theme="solarized"
              //conditionalRowStyles={conditionalRowStyles}
          />
        </DataTableExtensions>
      </div>
    </>
  )
}




export const MGRMantenimientoChoferes = () => {
  // const vcodEm = 1
  /** INICIO - EMPLEADOS */
  

  const [emp_lst, setEmp_lst] = useState([]);
  const [emp_pending, setEmp_pending] = useState(false);
  const [emp_disabled, setEmp_disabled] = useState(false);
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');


  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
  }

  
  
  //const [emp_sl_t_emp, emp_setSl_t_emp] = useState('');

  const [emp_sl_t_doc, emp_setSl_t_doc] = useState('');
  const emp_changeSl_t_doc = (event) => { 
    emp_setSl_t_doc(event.target.value);
    // $("#emp_txt_cod").select();
  }

  
  //const [emp_txt_cod, emp_setEmp_txt_codt] = useState('');
  //const [emp_txt_detalle, emp_setEmp_txt_detalle] = useState('');
  const [emp_txt_nombre, emp_setEmp_txt_nombre] = useState('');
  const emp_changeEmp_txt_nombre = (event) => { emp_setEmp_txt_nombre(event.target.value); }
  const [emp_txt_nplic, emp_setEmp_txt_nplic] = useState('');
  const emp_changeEmp_txt_nplic = (event) => { emp_setEmp_txt_nplic(event.target.value); }
  const [emp_txt_nroDoc, emp_setEmp_txt_nroDoc] = useState('');
  const emp_changeEmp_nroDoc = (event) => { emp_setEmp_txt_nroDoc(event.target.value); }


  
  // const emp_body = { emp_sl_t_suc: emp_sl_t_suc, emp_sl_t_emp: emp_sl_t_emp, emp_sl_t_doc: emp_sl_t_doc, emp_txt_cod:emp_txt_cod, emp_txt_detalle: emp_txt_detalle, emp_txt_nombre: emp_txt_nombre, emp_txt_apP: emp_txt_apP,emp_txt_apM: emp_txt_apM, emp_txt_observacion: emp_txt_observacion, usu_id: cookies.get('dtUser_id'), emp_txt_nplic:emp_txt_nplic };
  const emp_body = { 
                    vcodEmpresa: cook_idEmp,
                    emp_sl_t_suc: emp_sl_t_suc, 
                    emp_sl_t_doc: emp_sl_t_doc, 
                    emp_txt_nroDoc: emp_txt_nroDoc, 
                    emp_txt_nplic:  emp_txt_nplic,
                    emp_txt_nombre: emp_txt_nombre
                  };
  const emp_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(emp_body) }


  const onClickGuardarEmpleados = async() =>{
    const emp_url = '/gm-emp-insert'
    $("#emp_btn_guardar").attr("disabled",true)
    $("#icon_emp_btn_guardar").addClass("d-none")
    $("#spinner_emp_btn_guardar").removeClass("d-none")
    
    if( emp_sl_t_suc === "" || emp_sl_t_doc === "" || emp_txt_nroDoc === "" || emp_txt_nombre === ""|| emp_txt_nplic === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(emp_url,emp_opPe);
      const resdatos = await response.json();

      if (resdatos.vl_api === 1){
        notify_warning("ERROR AL AGREGAR O REGISTRO YA EXISTE, VERIFICAR DATOS")
      }
      if (resdatos.vl_existe === 1){
        notify_warning("CODIGO DE USUARIO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("CODIGO DE USUARIO SE REGISTRO CORRECTAMENTE!!!")
        onClickClearInputsEmpleados();
        if($(".rdt_Table").hasClass("table")){
          onClickListarEmpleados();
        }
      }
      //console.log("resdatos",resdatos);
      
    }
    $("#emp_btn_guardar").attr("disabled",false)
    $("#icon_emp_btn_guardar").removeClass("d-none")
    $("#spinner_emp_btn_guardar").addClass("d-none")
  }
  const onClickListarEmpleados = async() =>{
    if(!emp_sl_t_suc||emp_sl_t_suc==="9999"){
      notify_warning(' Debes seleccionar una sucursal.');
    }else{
    const lst_emp_url ='/apilistChoferes'
    const lst_veh_body = { vcodEmpresa: cook_idEmp, sl_t_suc: emp_sl_t_suc };
    const lst_emp_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(lst_veh_body) }
    const columns = [
      // Bonton oculto para restringir al usurio de editar 
      // { cell:(row) =>
      //   <div className="align-items-center">
      //       <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDeleteInputsEmpleados}  id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
      //   </div>
      //   ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
      // { name: 'SUCURSAL',     selector: row => row.U_AB_SUCU,     cellExport: row => row.U_AB_SUCU,    sortable: true, width: "8rem",omit:true},
      { name: 'SUCURSAL',     selector: row => row.PrcName,     cellExport: row => row.PrcName,    sortable: true, width: "8rem"},
      { name: 'N DOCUMENTO',  selector: row => row.U_AB_DOCIDE,   cellExport: row => row.U_AB_DOCIDE,  sortable: true, width: "9rem"},
      { name: 'NOMBRE',     selector: row => row.nombre,     cellExport: row => row.nombre,    sortable: true, width: "18rem"},
      { name: 'TIPO DE DOC',       selector: row => row.Name,          cellExport: row => row.Name,         sortable: true, width: "16rem" },
      { name: 'N.LICENCIA',   selector: row => row.U_SYP_CHLI,    cellExport: row => row.U_SYP_CHLI,   sortable: true },
      //{ name: 'TIPDOC',       selector: row => row.U_AB_TIPDOC,   cellExport: row => row.U_AB_TIPDOC,  sortable: true },
     
    ]
    setEmp_pending(true)
    const response = await fetch(lst_emp_url,lst_emp_opPe);
    const data = await response.json();
    //console.log(data);
    const tableData = { columns, data };
    setEmp_lst(tableData)
    setEmp_pending(false)
    $(".emp_tbl_lista .rdt_Table").addClass("table");
  }
}
  const handleRowClicked = (row) => {
    emp_setSl_t_suc(row.U_AB_SUCU)
    emp_setSl_t_doc(row.U_AB_TIPDOC)
    emp_setEmp_txt_nroDoc(row.U_AB_DOCIDE)
    emp_setEmp_txt_nplic((row.U_SYP_CHLI)? row.U_SYP_CHLI : "")
    emp_setEmp_txt_nombre(row.nombre)

    selectTabla("emp_tbl_lista")
    $("#emp_btn_guardar").addClass('d-none')
    $("#emp_btn_editar").removeClass('d-none')
    setEmp_disabled(true)
    $("#emp_btn_gmodificado").addClass('d-none')
    $("#emp_btn_mlista").addClass('d-none')
  };
  const onClickClearInputsEmpleados = (row) => {
    //emp_setSl_t_suc("")
    //emp_setSl_t_emp("")
    emp_setSl_t_doc("")
    //emp_setEmp_txt_codt("")
    //emp_setEmp_txt_detalle("")
    emp_setEmp_txt_nplic("")
    emp_setEmp_txt_nroDoc("")
    emp_setEmp_txt_nombre("")
    // emp_setEmp_txt_observacion("")
    $('.rdt_TableRow').removeClass("table-danger");
    $("#emp_btn_guardar").removeClass('d-none')
    $("#emp_btn_editar").addClass('d-none')
    $("#emp_btn_gmodificado").addClass('d-none')
    setEmp_disabled(false)
    $("#emp_txt_cod").attr('disabled',false)
    $("#emp_btn_mlista").removeClass('d-none')
    setTimeout(()=>{ 
      $("#emp_slc_suc").attr('disabled',false)
      $("#emp_txt_nroDoc").attr('disabled',false)
    },500)
  };
  const onClickEditInputsEmpleados = (row) => {
    setEmp_disabled(false)
    
    $("#emp_btn_editar").addClass('d-none')
    $("#emp_btn_gmodificado").removeClass('d-none')
    setTimeout(()=>{ 
      $("#emp_slc_suc").attr('disabled',true)
      $("#emp_txt_nroDoc").attr('disabled',true)
    },500)
  };
  const onClickGModifcadoEmpleados = async() =>{
    $("#emp_btn_gmodificado").attr("disabled",true)
    $("#icon_emp_btn_gmodificado").addClass("d-none")
    $("#spinner_emp_btn_gmodificado").removeClass("d-none")
    //if( emp_sl_t_suc === "" || emp_sl_t_emp === "" || emp_sl_t_doc === "" || emp_txt_cod === "" || emp_txt_detalle === ""){
    if( emp_sl_t_suc === "" || emp_sl_t_doc === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const upd_emp_url = '/gm-emp-update'
      const emp_body = { 
        vcodEmpresa:cook_idEmp,
        emp_sl_t_suc: emp_sl_t_suc, 
        emp_sl_t_doc: emp_sl_t_doc, 
        emp_txt_nroDoc: emp_txt_nroDoc, 
        emp_txt_nplic:  emp_txt_nplic,
        emp_txt_nombre: emp_txt_nombre
      };
      const emp_opPe = { method: "PUT", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(emp_body) }
      const response = await fetch(upd_emp_url,emp_opPe);
      const resdatos = await response.json();
      //console.log("modifi", resdatos);
      if (resdatos.vl_insert === 0){
        notify_warning("ERROR AL MODIFCAR, VERIFICAR DATOS!!!")
      }else if (resdatos.vl_insert === 1 ){
        onClickClearInputsEmpleados()
        onClickListarEmpleados()
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
      }
      //console.log("resdatos",resdatos);
      
    }
    $("#emp_btn_gmodificado").attr("disabled",false)
    $("#icon_emp_btn_gmodificado").removeClass("d-none")
    $("#spinner_emp_btn_gmodificado").addClass("d-none")
  }
  const onFocusEmpleados = async() => {
    $("#emp_txt_cod").on("keypress",function (e) {
      var code = (e.keyCode ? e.keyCode : e.which); 
      if(code === 13){
        $("#emp_txt_detalle").select()
      }
    })
    $("#emp_txt_detalle").on("keypress",function (e) {
      var code = (e.keyCode ? e.keyCode : e.which); 
      if(code === 13){
        $("#emp_txt_observacion").select()
      }
    })
  }
  const onClickDeleteInputsEmpleados = async(state) => {
    
    try {
      const dtLlegada = await JSON.parse(state.target.id);
      //console.log(dtLlegada);
      const codigo = dtLlegada.U_AB_DOCIDE;
      const del_emp_url = '/gm-emp-delete'
      const del_emp_body = { vcodEmpresa: cook_idEmp, emp_sl_t_suc: emp_sl_t_suc, emp_txt_nroDoc: codigo };
      const del_emp_opPe = { method: "DELETE", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(del_emp_body) }
  
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const respondt = await fetch(del_emp_url,del_emp_opPe);
          const resdatos = await respondt.json();
          //console.log("resdatos",resdatos);
          if (resdatos.vl_insert === 0){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            onClickListarEmpleados()
            onClickClearInputsEmpleados()
          }
        }else if(response.isDenied){
          Swal.close();
          //Swal.fire('Información', 'No paso nada', 'error')
        }
      })
      
  
  
  
  
    } catch (error) {
      console.log(error);;
    }
  }
  
  

  useEffect(() => {
    onFocusEmpleados()
  },[])
  
  createThemeS('solarized', {
    text: {
      primary: globalTheme === 'light'
        ? '#2B2C40'
        : '#fefff4',
      secondary: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
    },
    background: {
      default: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
    },
    context: {
      background: globalTheme === 'light'
        ? '#fefff4'
        : '#2B2C40',
      text: '#FFFFFF',
    },
  });

  /** FIN - EMPLEADOS */

  return (
    <>
    <div className="row">
      <div className="col-lg-2">Sucursal:</div>
      <div className="col-lg-2">
        <SelectSucursal id="emp_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} disabled={emp_disabled} />
      </div> 
    </div>
    <div className="row">
      <div className="col-lg-2">Doc. de Identidad:</div>
      <div className="col-lg-2">
        <SelectTipo id="emp_slc_tdo" url={emp_url_tdoc} value={emp_sl_t_doc} onChange={emp_changeSl_t_doc} disabled={emp_disabled} />
      </div>
      <div className="col-lg-2">Nro.Documento:</div>
      <div className="col-lg-2">
        <input type="number" id="emp_txt_nroDoc" name="emp_txt_nroDoc" className="form-control form-control-sm" autoComplete='off' value={emp_txt_nroDoc} onChange={emp_changeEmp_nroDoc} disabled={emp_disabled} min="1" max="99999999" pattern="\d*"  />
      </div>
      <div className="col-lg-2">Nro.Licencia:</div>
      <div className="col-lg-2">
        <input type="text" id="veh_txt_nplic" name="veh_txt_nplic" className="form-control form-control-sm"  autoComplete='off' value={emp_txt_nplic} onChange={emp_changeEmp_txt_nplic} disabled={emp_disabled} />
      </div> 
    </div>
    <div className="row mb-2">
      <div className="col-lg-2">Nombre y Apellidos:</div>
      <div className="col-lg-10">
        <input type="text" id="emp_txt_nombre" name="emp_txt_nombre" className="form-control form-control-sm" autoComplete='off' value={emp_txt_nombre} onChange={emp_changeEmp_txt_nombre} disabled={emp_disabled} />
      </div>
    </div>    
    <div className="row ">
      <div className="col-md-4 "></div>
      <div className="col-md-4 text-center ">
        <button className="btn btn-primary btn-sm" id="emp_btn_guardar" onClick={onClickGuardarEmpleados} >
          <i className='bx bx-save' id="icon_emp_btn_guardar" ></i>
          <span className="spinner-border d-none" id="spinner_emp_btn_guardar" role="status" aria-hidden="true"></span>
          &nbsp;Guardar
        </button>
        {/* Bonton oculto para restringir al usurio de editar 
        <button className="btn btn-warning btn-sm d-none" id="emp_btn_editar" onClick={onClickEditInputsEmpleados}>
          <i className='bx bx-edit' ></i>&nbsp;Editar
        </button> */}
        <button className="btn btn-success btn-sm d-none" id="emp_btn_gmodificado" onClick={onClickGModifcadoEmpleados} >
          <i className='bx bx-send' id="icon_emp_btn_gmodificado" ></i>
          <span className="spinner-border d-none" id="spinner_emp_btn_gmodificado" role="status" aria-hidden="true"></span>
          &nbsp;GModificado
        </button>
        <button className="btn btn-dark btn-sm" id="emp_btn_cancelar" onClick={onClickClearInputsEmpleados}>
          <i className='bx bx-history' ></i>&nbsp;Cancelar
        </button>
        <button className="btn btn-info btn-sm" id="emp_btn_mlista" onClick={onClickListarEmpleados} >
          <i className='bx bx-list-ul' ></i>&nbsp;MLista
        </button>
      </div>
    </div>
    
    <div className="card-datatable table-responsive ">
        <DataTableExtensions  {...emp_lst} >
          <DataTable className="emp_tbl_lista"
              Header
              defaultSortField="id"
              defaultSortAsc={true}
              pagination
              responsive
              highlightOnHover
              progressPending={emp_pending}
              noContextMenu="No hay registros para mostrar"
              onRowClicked={handleRowClicked}
              theme="solarized"
              //conditionalRowStyles={conditionalRowStyles}
          />
        </DataTableExtensions>
    </div>
    
    </>
  )

}




export const MGRMantenimientoColaboradores = () => {

  const itemsVar = {
    vcodEmpresa: cook_idEmp,
    txtCodigo     : '',
    txtNombre     : '',
    txtSucursal   : '',
    txtTipoDocum  : '',
    txtDocumento  : '',
    txtActivo     : true
  }

  const [data, setData] = useState([])
  const [pending, setPending] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [disabledb1, setDisabledB1] = useState(false)
  const [disabledb2, setDisabledB2] = useState(false)
  const [disabledb3, setDisabledB3] = useState(false)
  const [visibleb1, setVisibleB1] = useState(false)
  const [visibleb2, setVisibleB2] = useState(false)
  const [visibleb3, setVisibleB3] = useState(false)
  const [visibleb4, setVisibleB4] = useState(false)
  const [items, setItems] = useState(itemsVar)

  const handleFieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    setItems({
      ...items,
      [fieldName]: value,
    });
  };
  const changeCbx_estado = (event) => { 
    setItems({
      ...items,
      'txtActivo': !items.txtActivo,
    });
  }

  
  const onClickGuardar = async() => {
    if( items.txtDocumento === "" || items.txtNombre === "" || items.txtSucursal === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      setDisabledB1(true)
      const body = {items};
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const url = '/insertMColaboradores'
      const response = await fetch(url,opPe);
      const resdatos = await response.json();
      console.log(resdatos);
      if (resdatos.vl_api === 1){
        notify_warning("ERROR AL AGREGAR O REGISTRO YA EXISTE, VERIFICAR DATOS")
      }
      if (resdatos.vl_existe === 1){
        notify_warning("CODIGO DE USUARIO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("CODIGO DE USUARIO SE REGISTRO CORRECTAMENTE!!!")
        onClickClearInputs();
        onClickListar();
      }
      setDisabledB1(false)
    }
  }
  const onClickEditInputs = async() => {
    setDisabled(false)
    setVisibleB2(false)
    setVisibleB3(true)
    setTimeout(() => {
      $("#selectSucursal").attr('disabled', true)
      $("#selectIdentidad").attr('disabled', true)
      $("#selectCodigo").attr('disabled', true)
      $("#selectDocumento").attr('disabled', true)
    }, 500);
  }
  const onClickGModifcado = async() => {
    if( items.txtSucursal === "" || items.txtDocumento === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      setDisabledB3(true)
      const url = '/updateMColaboradores'
      const body = { items };
      const opPe = { method: "PUT", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const response = await fetch(url,opPe);
      const resdatos = await response.json();
      console.log("resdatos", resdatos);
      if (resdatos.vl_insert === 0){
        notify_warning("ERROR AL MODIFCAR, VERIFICAR DATOS!!!")
      }else if (resdatos.vl_insert === 1 ){
        onClickClearInputs()
        onClickListar()
        setDisabled(false)
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
      }
      setDisabledB3(false)
      setVisibleB4(false)

    }
  }
  const onClickClearInputs = async() => {
    setItems(prevItems => ({
      ...itemsVar,
      txtSucursal: prevItems.txtSucursal
    }));
    setVisibleB1(false)
    setVisibleB2(false)
    setVisibleB3(false)
    setVisibleB4(false)
      setDisabled(false)
      setTimeout(() => {
        $("#selectSucursal").attr('disabled', false)
        $("#selectIdentidad").attr('disabled', false)
        $("#selectCodigo").attr('disabled', false)
        $("#selectDocumento").attr('disabled', false)
      }, 500);
  }
  const onClickListar = async() => {
    if(!items.txtSucursal||items.txtSucursal==="9999"){
      notify_warning(' Debes seleccionar una sucursal.');
    }else{
    const lst_emp_url ='/apilistColaboradores'
    const lst_veh_body = { vcodEmpresa: cook_idEmp, sl_t_suc: items.txtSucursal };
    const lst_emp_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(lst_veh_body) }
    
    setPending(true)
    const response = await fetch(lst_emp_url,lst_emp_opPe);
    const data = await response.json();
    setData(data)
    setPending(false)
  }
    
  }
  const onClickDeleteFile = async(row) => {
    
    try {      
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const codigo = row.SlpCode;
          const url = '/deleteMColaboradores'
          const body = { codigo:codigo };
          const opPe = { method: "DELETE", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  
          const respondt = await fetch(url,opPe);
          const resdatos = await respondt.json();
          console.log("resdatos", resdatos);
          //console.log("resdatos",resdatos);
          if (resdatos.vl_insert === '0'){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            onClickListar()
            onClickClearInputs()
          }
        }else if(response.isDenied){
          Swal.close();
        }
      })
  
    } catch (error) {
      console.log(error);;
    }
  }
  const handleRowClicked = (row) => {
    setDisabled(true)
    setVisibleB1(true)
    setVisibleB2(true)
    setVisibleB3(false)
    setVisibleB4(true)
    setItems({
      vcodEmpresa: cook_idEmp,
      txtID     : row.SlpCode,
      txtCodigo     : row.SlpName,
      txtNombre     : row.Memo,
      txtSucursal   : row.U_AB_SUCURSAL,
      txtTipoDocum  : row.U_AB_TIPDOC,
      txtDocumento  : row.U_AB_DOCIDE,
      txtActivo     : (row.Active === 'Y') ? true : false
    })
  }


  const columns = useMemo(() => [
    { header: 'ID', accessorKey: 'SlpCode' },
    { header: 'Codigo', accessorKey: 'SlpName' },
    { header: 'Nombre', accessorKey: 'Memo' },
    { header: 'Sucursal', accessorKey: 'SUCURSAL' },
    { header: 'Documento', accessorKey: 'U_AB_DOCIDE' },
    { header: 'Activo', accessorKey: 'Active' }
  ], []);


  return (<>
    <div className="row">
      <div className="col-lg-2">Sucursal:</div>
      <div className="col-lg-2">
        <SelectSucursal id="selectSucursal"  value={items?.txtSucursal} onChange={handleFieldChange('txtSucursal')} disabled={disabled} /> 
      </div> 
    </div>
    <div className="row">
      <div className="col-lg-2">Doc. de Identidad:</div>
      <div className="col-lg-2">
        <SelectTipo id="selectIdentidad" url={emp_url_tdoc} value={items?.txtTipoDocum} onChange={handleFieldChange('txtTipoDocum')} disabled={disabled} />
      </div>
      <div className="col-lg-1">Codigo:</div>
      <div className="col-lg-2">
        <input type="text" id="selectCodigo" className="form-control form-control-sm" autoComplete='off' value={items?.txtCodigo} onChange={handleFieldChange('txtCodigo')} disabled={disabled} min="1" max="99999999" pattern="\d*"  />
      </div>
      <div className="col-lg-1">Nro.Doc:</div>
      <div className="col-lg-2">
        <input type="number" id="selectDocumento" className="form-control form-control-sm" autoComplete='off' value={items?.txtDocumento} onChange={handleFieldChange('txtDocumento')} disabled={disabled} min="1" max="99999999" pattern="\d*"  />
      </div>
      <div className="col-lg-2">
          <div className="row">
            <div className="col-lg-6">
              <label className="form-check-label" htmlFor="customCheckPrimaryA">Activo</label>
            </div>
            <div className="col-lg-6">
              <div className="form-check form-check-danger">
                <input className="form-check-input" type="checkbox" value='Y'id="customCheckPrimaryA"  onChange={changeCbx_estado} checked={items?.txtActivo} disabled={disabled}/>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div className="row mb-2">
      <div className="col-lg-2">Nombre y Apellidos:</div>
      <div className="col-lg-10">
        <input type="text" className="form-control form-control-sm" autoComplete='off' value={items?.txtNombre} onChange={handleFieldChange('txtNombre')}  disabled={disabled} />
      </div>
    </div>    
    <div className="row ">
      <div className="col-md-4 "></div>
      <div className="col-md-4 text-center ">
        
        {!visibleb1 && (<AButton 
          tp_tilde="Guardar Registro" bt_descr='Guardar'  
          tp_posit="top" bt_class="btn btn-label-youtube btn-sm" 
          bt_funct={onClickGuardar} disabled={disabledb1} 
          ic_class="bx bx-revision bx-ms" vl_tpbtn={2} 
        />)}
        {visibleb2 && (
          <button 
            className="btn btn-warning btn-sm" 
            id="emp_btn_editar" onClick={onClickEditInputs}>
            <i className='bx bx-edit' ></i>&nbsp;Editar
          </button>
        )}
        {visibleb3 && (<AButton 
          tp_tilde="Modificar Registro" bt_descr='GModificado'  
          tp_posit="top" bt_class="btn btn-label-success btn-sm" 
          bt_funct={onClickGModifcado} disabled={disabledb3} 
          ic_class="bx bx-revision bx-ms" vl_tpbtn={2} 
        /> )}
        {/* <button className="btn btn-success btn-sm d-none" id="emp_btn_gmodificado" onClick={onClickGModifcado} >
          <i className='bx bx-send' id="icon_emp_btn_gmodificado" ></i>
          <span className="spinner-border d-none" id="spinner_emp_btn_gmodificado" role="status" aria-hidden="true"></span>
          &nbsp;GModificado
        </button> */}
        <button className="btn btn-dark btn-sm" id="emp_btn_cancelar" onClick={onClickClearInputs}>
          <i className='bx bx-history' ></i>&nbsp;Cancelar
        </button>
        {!visibleb4 && (<button className="btn btn-info btn-sm" id="emp_btn_mlista" onClick={onClickListar} >
          <i className='bx bx-list-ul' ></i>&nbsp;MLista
        </button>)}
      </div>
    </div>
    <div className="card-datatable table-responsive ">
      <MaterialReactTable
        columns={columns}
        data={data}
        state={{ isLoading: pending }}
        initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
        enableGrouping
        enableStickyHeader enableColumnResizing
        muiToolbarAlertBannerChipProps={{ color: 'primary' }}
        localization={MRT_Localization_ES}
        enablePagination={true}
        muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
        enableGlobalFilterModes
        rowVirtualizerProps={{ overscan: 8 }}
        enableRowVirtualization
        muiTableContainerProps={{ sx: { maxHeight: 300 } }}
        //enableEditing
        // Bonton oculto para restringir al usurio de editar 
        // enableRowActions
        // renderRowActions={({ row }) => (
        //   <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
        //     <AButton
        //       tp_tilde="Eliminar"
        //       tp_posit="top"
        //       bt_class="btn btn-sm btn-icon btn-label-youtube"
        //       //disabled={(row.original.Abierto === 'SI') ? false : true} 
        //       bt_funct={() => onClickDeleteFile(row.original)} ic_class="bx bx-x-circle bx-ms" vl_tpbtn={1} />
        //   </Box>
        // )}
        muiTableBodyRowProps={({ row, event }) => ({
          onClick: () => { handleRowClicked(row.original) },
        })}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: 'Options', //change header text
            size: 100, //make actions column wider
          },
        }}
        muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
      />
    </div>
  </>)

}
export const MGRMantenimientoUsuarios = () => {
  const itemsVar = {
    vcodEmpresa: cook_idEmp,
    txtCodigo     : '',
    txtNombre     : '',
    txtSucursal   : '',
    txtTipoDocum  : '',
    txtDocumento  : '',
    txtActivo     : true
  }

  const [data, setData] = useState([])
  const [pending, setPending] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [disabledb1, setDisabledB1] = useState(false)
  const [disabledb2, setDisabledB2] = useState(false)
  const [disabledb3, setDisabledB3] = useState(false)
  const [visibleb1, setVisibleB1] = useState(false)
  const [visibleb2, setVisibleB2] = useState(false)
  const [visibleb3, setVisibleB3] = useState(false)
  const [visibleb4, setVisibleB4] = useState(false)
  const [items, setItems] = useState(itemsVar)

  const handleFieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    setItems({
      ...items,
      [fieldName]: value,
    });
  };
  const changeCbx_estado = (event) => { 
    setItems({
      ...items,
      'txtActivo': !items.txtActivo,
    });
  }

  
  const onClickGuardar = async() => {
    if( items.txtDocumento === "" || items.txtNombre === "" || items.txtSucursal === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      setDisabledB1(true)
      const body = {items};
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const url = '/insertMColaboradores'
      const response = await fetch(url,opPe);
      const resdatos = await response.json();
      console.log(resdatos);
      if (resdatos.vl_api === 1){
        notify_warning("ERROR AL AGREGAR O REGISTRO YA EXISTE, VERIFICAR DATOS")
      }
      if (resdatos.vl_existe === 1){
        notify_warning("CODIGO DE USUARIO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("CODIGO DE USUARIO SE REGISTRO CORRECTAMENTE!!!")
        onClickClearInputs();
        onClickListar();
      }
      setDisabledB1(false)
    }
  }
  const onClickEditInputs = async() => {
    setDisabled(false)
    setVisibleB2(false)
    setVisibleB3(true)
    setTimeout(() => {
      $("#selectSucursal").attr('disabled', true)
      $("#selectIdentidad").attr('disabled', true)
      $("#selectCodigo").attr('disabled', true)
      $("#selectDocumento").attr('disabled', true)
    }, 500);
  }
  const onClickGModifcado = async() => {
    if( items.txtSucursal === "" || items.txtDocumento === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      setDisabledB3(true)
      const url = '/updateMColaboradores'
      const body = { items };
      const opPe = { method: "PUT", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const response = await fetch(url,opPe);
      const resdatos = await response.json();
      console.log("resdatos", resdatos);
      if (resdatos.vl_insert === 0){
        notify_warning("ERROR AL MODIFCAR, VERIFICAR DATOS!!!")
      }else if (resdatos.vl_insert === 1 ){
        onClickClearInputs()
        onClickListar()
        setDisabled(false)
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
      }
      setDisabledB3(false)
      setVisibleB4(false)

    }
  }
  const onClickClearInputs = async() => {
    setItems(prevItems => ({
      ...itemsVar,
      txtSucursal: prevItems.txtSucursal
    }));
    setVisibleB1(false)
    setVisibleB2(false)
    setVisibleB3(false)
    setVisibleB4(false)
      setDisabled(false)
      setTimeout(() => {
        $("#selectSucursal").attr('disabled', false)
        $("#selectIdentidad").attr('disabled', false)
        $("#selectCodigo").attr('disabled', false)
        $("#selectDocumento").attr('disabled', false)
      }, 500);
  }
  const onClickListar = async() => {
    if(!items.txtSucursal||items.txtSucursal==="9999"){
      notify_warning(' Debes seleccionar una sucursal.');
    }else{
    const lst_emp_url ='/apilistColaboradores'
    const lst_veh_body = { vcodEmpresa: cook_idEmp, sl_t_suc: items.txtSucursal };
    const lst_emp_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(lst_veh_body) }
    
    setPending(true)
    const response = await fetch(lst_emp_url,lst_emp_opPe);
    const data = await response.json();
    setData(data)
    setPending(false)
  }
    
  }
  const onClickDeleteFile = async(row) => {
    
    try {      
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const codigo = row.SlpCode;
          const url = '/deleteMColaboradores'
          const body = { codigo:codigo };
          const opPe = { method: "DELETE", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  
          const respondt = await fetch(url,opPe);
          const resdatos = await respondt.json();
          console.log("resdatos", resdatos);
          //console.log("resdatos",resdatos);
          if (resdatos.vl_insert === '0'){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            onClickListar()
            onClickClearInputs()
          }
        }else if(response.isDenied){
          Swal.close();
        }
      })
  
    } catch (error) {
      console.log(error);;
    }
  }
  const handleRowClicked = (row) => {
    setDisabled(true)
    setVisibleB1(true)
    setVisibleB2(true)
    setVisibleB3(false)
    setVisibleB4(true)
    setItems({
      vcodEmpresa: cook_idEmp,
      txtID     : row.SlpCode,
      txtCodigo     : row.SlpName,
      txtNombre     : row.Memo,
      txtSucursal   : row.U_AB_SUCURSAL,
      txtTipoDocum  : row.U_AB_TIPDOC,
      txtDocumento  : row.U_AB_DOCIDE,
      txtActivo     : (row.Active === 'Y') ? true : false
    })
  }


  const columns = useMemo(() => [
    { header: 'ID', accessorKey: 'SlpCode' },
    { header: 'Codigo', accessorKey: 'SlpName' },
    { header: 'Nombre', accessorKey: 'Memo' },
    { header: 'Sucursal', accessorKey: 'SUCURSAL' },
    { header: 'Documento', accessorKey: 'U_AB_DOCIDE' },
    { header: 'Activo', accessorKey: 'Active' }
  ], []);
  return (<>
    hola mundo
    
    <div className="card-datatable table-responsive ">
      <MaterialReactTable
        columns={columns}
        data={data}
        state={{ isLoading: pending }}
        initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
        enableGrouping
        enableStickyHeader enableColumnResizing
        muiToolbarAlertBannerChipProps={{ color: 'primary' }}
        localization={MRT_Localization_ES}
        enablePagination={true}
        muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
        enableGlobalFilterModes
        rowVirtualizerProps={{ overscan: 8 }}
        enableRowVirtualization
        muiTableContainerProps={{ sx: { maxHeight: 300 } }}
        //enableEditing

        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: 'Options', //change header text
            size: 100, //make actions column wider
          },
        }}
        muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
      />
    </div>
  </>)

}


const Maestro = () => {

  return (
    <div>
      {/* <h4 className="fw-bold py-1"> Mantenimiento </h4> */}
      <div className="col-xl-12">
        <h6 className="text-muted">Mantenimiento</h6>
        <div className="nav-align-top">
            <ul className="nav nav-pills mb-3 " role="tablist">
                <li className="nav-item">
                    <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-Vehiculos" aria-controls="navs-pills-justified-Vehiculos" aria-selected="true"><i className="tf-icons bx bx-car" /> Vehículos </button>
                </li>
                <li className="nav-item">
                    <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-Empleados" aria-controls="navs-pills-justified-Empleados" aria-selected="false"><i className="tf-icons bx bx-user" /> Choferes</button>
                </li>
                <li className="nav-item">
                    <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-Colaboradores" aria-controls="navs-pills-justified-Colaboradores" aria-selected="false"><i className="tf-icons bx bx-user" /> Colaboradores</button>
                </li>
                <li className="nav-item">
                    <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-Usuarios" aria-controls="navs-pills-justified-Usuarios" aria-selected="false"><i className="tf-icons bx bx-user" /> Usuarios</button>
                </li>
            </ul>
            <div className="tab-content">

                {/** --------- VEHICULOS ----- */}
                <div className="tab-pane fade show active" id="navs-pills-justified-Vehiculos" role="tabpanel">
                  <MGRMantenimientoVehiculos />
                </div>

                {/** --------- CHOFERES ----- */}
                <div className="tab-pane fade  " id="navs-pills-justified-Empleados" role="tabpanel">
                  <MGRMantenimientoChoferes />
                </div>
                {/** --------- COLABORADORES ----- */}
                <div className="tab-pane fade  " id="navs-pills-justified-Colaboradores" role="tabpanel">
                  <MGRMantenimientoColaboradores />
                </div>
                {/** --------- USUARIOS ----- */}
                <div className="tab-pane fade  " id="navs-pills-justified-Usuarios" role="tabpanel">
                  <MGRMantenimientoUsuarios />
                </div>


  

            </div>
        </div>
      </div>
      <ModalsSubPartidas  />
      <ToastContainer />
    </div>
  )
}

export default Maestro