/* eslint-disable array-callback-return */
import { ToastContainer } from "react-toastify";
import { SelectSucursal } from "../../components/Selects/SelectsPrincipales";
import { useCallback, useEffect, useMemo, useState } from "react";
import { notify_valido, notify_warning } from "../../components/utils";
import { MaterialReactTable } from 'material-react-table';
import { Box, Modal } from '@mui/material';
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { createTheme, ThemeProvider } from '@mui/material';
import { AButton } from "../../components/Button";
import Cookies from "universal-cookie";
import AsyncSelect from 'react-select/async';

const styles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: `#D2D5DC`,
    border: '1px solid #D2D5DC',
    boxShadow: state.isFocused ? null : null,
    backgroundColor: window.templateCustomizer.settings.style === 'light'
      ? '#fefff4'
      : '#2B2C40',
    color: window.templateCustomizer.settings.style === 'light'
      ? '#2B2C40'
      : '#fefff4',
    minHeight: `29px`,
    height: `29px`,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (window.templateCustomizer.settings.style === 'light' ? '#fefff4' : '#2B2C40')
      : (window.templateCustomizer.settings.style === 'light' ? '#fefff4' : '#2B2C40'),
    color: state.isSelected
      ? '#fefff4'
      : (window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: '#fefff4',
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: window
      .templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4',
    fontSize: `0.75rem`,
  }),

  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#fefff4',
  }),
};


const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const dtUser = cookies.get('dtUser');
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.default',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const COMVendedor = () => {
  const [sucCode, setsucCode] = useState('');
  const [dataFiltred, setDataFiltred] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [pending, setpending] = useState(false);

  const [dataSelected, setDataSelected] = useState([]);
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  }
  const listar = async () => {
    if (!emp_sl_t_suc || emp_sl_t_suc === '9999') {
      notify_warning('Seleccione una sucursal');
      return;
    }
    setpending(true);
    const url_lstClientes = `/apigetColaboradores?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    if (data.length === 0) {
      notify_warning('Sin clientes registrados')
    }
    setsucCode(emp_sl_t_suc);
    setDataFiltred(data);
    setpending(false);
  }
  const columns = useMemo(() => [
    { header: 'Id', accessorKey: 'id' },
    { header: 'Codigo', accessorKey: 'codigo' },
    { header: 'Nombres del vendedor', accessorKey: 'nombre' },
    {
      header: 'Activo', accessorKey: 'activo', Cell: ({ row }) => (
        <div className='align-items-center form-check form-check-danger'><div className='align-items-center'><input className="form-check-input" type="checkbox" checked={row.original.activo} readOnly /></div></div>
      )
    },
    { header: 'Telefono', accessorKey: 'telefono' },
    { header: 'Correo', accessorKey: 'correo' },
    { header: 'Sucursal', accessorKey: 'sucursal' },
    { header: 'Supervisor', accessorKey: 'supervisor' },
    { header: 'Tipo de documento', accessorKey: 'tipodocumento' },
    { header: 'Documento de identidad', accessorKey: 'documentoidentidad' },
    { header: 'Cargo', accessorKey: 'cargo' },
    { header: 'Mesa', accessorKey: 'mesa' },
    { header: 'Canal', accessorKey: 'canal' },
    { header: 'Grupo Ventas', accessorKey: 'grupoVentas' },
    { header: 'Mesa AB Grupo Venta', accessorKey: 'mesaabgrupoventa' },
  ], []);

  const handleRowCliked = async (row) => {
    const fechtApi = async (CardCode) => {
      const url = `apigetColaboradoresByCode?codigo=${CardCode}`;
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
      const response = await fetch(url, opPe);
      const data = await response.json();
      return data;
    };
    setDataSelected(await fechtApi(row.id));
    handleOpenModal();
  }

  const setDataReturn = (data) => {
    handleCloseModal();
    let index2 = dataFiltred.findIndex((data1) => data1.codigo === data.codigo);
    if (index2 === -1) {
      dataFiltred.push(data);
      setDataFiltred(dataFiltred);
      return;
    }
    dataFiltred[index2] = data;
    setDataFiltred(dataFiltred);
  }

  const handleButtonClick = () => {
    setDataSelected(null);
    handleOpenModal();
  }
  const globalTheme = window.templateCustomizer.settings.style;
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [globalTheme],
  );


  return (
    <>
      <ThemeProvider theme={tableTheme}>
        <div className='d-flex flex-column'>
          <h6 className="text-muted">Colaboradores</h6>
        </div >
        <div className="d-flex">
          <div className="col-lg-1">Sucursal:</div>
          <div className="col-lg-2">
            <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
          </div>
          <div className="col-lg-2">
            <div className="text-center mb-3">
              <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={listar}>
                <i className='bx bx-list-ul' ></i>&nbsp;Consultar
              </button>
            </div>
          </div>
        </div>
        <MaterialReactTable
          columns={columns}
          data={dataFiltred}
          initialState={{ density: 'compact' }}
          enablePagination={true}
          enableRowActions
          enableGrouping={true}
          renderTopToolbarCustomActions={({ table }) => {
            if (dtUser.tpu_id === '5') {
              // El usuario de tipo 5 solo puede visualizar 
              return <div></div>;
            }
            if (sucCode && !pending) {
              return (
                <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                  <AButton tp_tilde="Nuevo Colaborador" tp_posit="top" bt_class="btn btn-label-success btn-sm " ic_class="fa-solid fa-plus" vl_tpbtn={1} bt_funct={handleButtonClick} />

                </Box>
              );
            } else {
              return <div></div>;
            }
          }}
          state={{ isLoading: pending }}
          localization={MRT_Localization_ES}
          renderRowActions={({ row }) => {
            // El usuario de tipo 5 solo puede visualizar 
            if (dtUser.tpu_id === '5') {
              return <div></div>;
            }
            return (
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '2px' }}>
                <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-label-warning btn-icon btn-sm" ic_class="bx bxs-edit bx-ms" bt_funct={() => handleRowCliked(row.original)}
                  id={JSON.stringify(row.original)} vl_tpbtn={1} />
              </Box>
            );
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
        />
        <Modal
          open={openModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: '65%', height: '80%', overflow: 'auto' }}>
            <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
              className='btn btn-outline-dark btn-sm'
              onClick={handleCloseModal}
            >
              <i className='fa fa-x'></i>
            </button>
            <FormModal data={dataSelected} dataReturn={setDataReturn} sucursal={emp_sl_t_suc} />
          </Box>
        </Modal>
      </ThemeProvider>
      <ToastContainer />
    </>
  );
};

export default COMVendedor;

export const FormModal = ({ data, dataReturn, sucursal }) => {
  const [tipoDocumento, setSlc_tipoDocumento] = useState([]);
  const [cargoCombobox, setSlc_cargoCombobox] = useState([]);
  const [listSupervisores, setSlc_supervisores] = useState([]);
  const [listMesas, setSlc_mesas] = useState([]);
  const [listGrupoVentas, setGrupoVentas] = useState([]);

  const [dataForm, setDataForm] = useState({
    codigo: data ? data.codigo : '',
    codigoNombre: data ? data.codigoNombre : '',
    nombre: data ? data.nombre : '',
    activo: data ? data.activo : '',
    sucursal: sucursal,
    supervisor: data ? data.supervisor : '',
    tipodocumento: data ? data.tipodocumento : '',
    documentoidentidad: data ? data.documentoidentidad : '',
    cargo: data ? data.cargo : '',
    mesaabgrupoventa: data ? data.mesaabgrupoventa : '',
    telefono: data ? data.telefono : '',
    correo: data ? data.correo : '',
    mesa: data ? data.mesa : '',
    canal: data ? data.canal : '',
    grupoVentas: data ? data.grupoVentas : '',
    asignaVisita: data ? data.asignaVisita : '',
  });
  const [slcMesa, setSlcMesa] = useState([""]);
  const [slcSupervisores, setSlcSupervisores] = useState([""]);

  const handleFieldChange = (fieldName) => (event) => {
    let value = event.target.value;
    setDataForm({
      ...dataForm,
      [fieldName]: value,
    });
  };

  const [canal, setSlc_conceptos] = useState([]);
  const get_categorias = async () => {
    const url_con = `/apigetcanal?vcodEmpresa=${cook_idEmp}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    console.log("data", data);
    setSlc_conceptos(data)
  }
  const handleFieldChangeChecked = (fieldName) => (event) => {
    const value = event.target.checked;
    setDataForm({
      ...dataForm,
      [fieldName]: value,
    });
  };

  const handleButtonClick = async () => {
    let urlPost = `/saveColaborador`;
    dataForm.mesa = slcMesa?.Code
    dataForm.supervisor = slcSupervisores?.Code
    if (!dataForm.nombre || dataForm.nombre.trim() === "") {
      notify_warning('El nombre es obligatorio');
      return;
    }
    // if (!dataForm.telefono) {
    //   notify_warning('El telefono es obligatorio');
    //   return;
    // }
    // if (!dataForm.correo || dataForm.correo.trim() === "") {
    //   notify_warning('El correo es obligatorio');
    //   return;
    // }
    if (!dataForm.tipodocumento) {
      notify_warning('Es necesario seleccionar un tipo de documeto');
      return;
    }
    if (!dataForm.documentoidentidad) {
      notify_warning('Ingrese un numero de documento');
      return;
    }
    if (!dataForm.cargo) {
      notify_warning('Es necesario asignar un cargo');
      return;
    }
    if (!dataForm.mesa) {
      notify_warning('Es necesario asignar una mesa');
      return;
    }
    if (!dataForm.mesa) {
      notify_warning('Es necesario asignar una mesa');
      return;
    }
    if (!dataForm.grupoVentas) {
      notify_warning('Es necesario asignar un grupo de ventas');
      return;
    }
    if (!dataForm.supervisor) {
      notify_warning('Es necesario asignar un supervisor');
      return;
    }
    dataForm.vcodEmpresa = cook_idEmp
    let body = dataForm;
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(urlPost, opPe);
    await response.json();
    dataReturn(dataForm)
    notify_valido("Guardado");
  }

  const funDMesa = useCallback(async (inputValue) => {
    const url = '/apiMesa?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const funDSupervisores = useCallback(async (inputValue) => {
    const url_con = `/apigetsupervisorescombox?vcodEmpresa=${cook_idEmp}&vtxtConsult=${inputValue}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const filter = await response.json();
    return filter;
    // const response = listSupervisores.filter((elemento) => {
    //   const Code = elemento.Code.toString();
    //   const Name = elemento.Name.toString().toUpperCase();
    //   return (Code + Name).toString().toUpperCase().includes(inputValue ? inputValue.toUpperCase() : '0');
    //   // return Code.includes(inputValue.toUpperCase()) || Name.includes(inputValue.toUpperCase());
    // });
    // return response;
  }, []);
  const getSupervisores = useCallback(async () => {
    const url = '/apigetsupervisorescombox?vcodEmpresa=' + cook_idEmp;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    setSlc_supervisores(data);
  }, []);
  const getMesas = useCallback(async () => {
    const url = '/apiMesa?vcodEmpresa=' + cook_idEmp;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    setSlc_mesas(data);
  }, []);
  const getTipoDocumento = useCallback(async () => {
    const url_con = `/apiTipoDocumento?vcodEmpresa=${cook_idEmp}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setSlc_tipoDocumento(data)
  }, [])
  const getGrupoVentas = useCallback(async () => {
    const url_con = `/getGrupoVentas?vcodEmpresa=${cook_idEmp}&vcodSucursal=${sucursal}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setGrupoVentas(data)
  }, [])
  const getCargosCombobox = useCallback(async () => {
    const url_con = `/apigetcargoscombox?vcodEmpresa=${cook_idEmp}`
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url_con, opPe);
    const data = await response.json();
    setSlc_cargoCombobox(data)
  }, [])

  useMemo(() => {
    if (canal.length === 0) {
      get_categorias();
    }
    if (tipoDocumento.length === 0) {
      getTipoDocumento();
    }
    if (cargoCombobox.length === 0) {
      getCargosCombobox();
    }
    if (listSupervisores.length === 0) {
      getSupervisores();
    }
    if (listMesas.length === 0) {
      getMesas();
    }
    if (listGrupoVentas.length === 0) {
      getGrupoVentas();
    }
  }, [canal.length, tipoDocumento.length, cargoCombobox.length, listSupervisores.length, listMesas.length, listGrupoVentas.length, getTipoDocumento, getCargosCombobox, getSupervisores, getMesas, getGrupoVentas]);

  useEffect(() => {
    if (dataForm.mesa) {
      const mesas = listMesas?.find((res) => {
        if (String(res.Code) === String(dataForm.mesa)) {
          return {
            Code: res.Code,
            Name: res.Name,
          }
        }
      });
      setSlcMesa({ Code: mesas?.Code, Name: mesas?.Name });
    }
    if (dataForm.supervisor) {
      const supervisor = listSupervisores?.find((res) => {
        if (res.Code === dataForm.supervisor) {
          return {
            Code: res.Code,
            Name: res.Name,
          }
        }
      });
      setSlcSupervisores({ Code: supervisor?.Code, Name: supervisor?.Name });
    }
  }, [dataForm.mesa, dataForm.supervisor, listMesas, listSupervisores]);

  const handleSelectChangeMesa = (selectedOption) => {
    setSlcMesa(selectedOption);
  };
  const handleSelectChangeSupervisor = (selectedOption) => {
    setSlcSupervisores(selectedOption);
  };

  return (
    <>
      <div className="d-flex">
        <label className="col-form-label m-0 p-0">
          <span className="small fs-6">
            Colaborador
          </span>
        </label>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column col-md-5">
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                id
              </span>
            </label>
            <input type="text" className='form-control form-control-sm' autoComplete='off' value={dataForm.codigo} onChange={handleFieldChange('codigo')} disabled />
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Codigo del vendedor
              </span>
            </label>
            <input type="text" className='form-control form-control-sm' autoComplete='off' value={dataForm.codigoNombre} onChange={handleFieldChange('codigoNombre')} />
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Nombres del vendedor
              </span>
            </label>
            <input type="text" className='form-control form-control-sm' autoComplete='off' value={dataForm.nombre} onChange={handleFieldChange('nombre')} />
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Telefono
              </span>
            </label>
            <input type="number" className='form-control form-control-sm' autoComplete='off' value={dataForm.telefono} onChange={handleFieldChange('telefono')} />
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Correo
              </span>
            </label>
            <input type="text" className='form-control form-control-sm' autoComplete='off' value={dataForm.correo} onChange={handleFieldChange('correo')} />
          </div>
          {/* <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Surcusal
              </span>
            </label>
            <SelectSucursal type="text" autoComplete='off' value={dataForm.sucursal} onChange={handleFieldChange('sucursal')} />
          </div> */}
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Supervisor
              </span>
            </label>
            <AsyncSelect
              styles={styles}
              placeholder="Busque por Desc."
              name="form-field-name"
              className="sele_pru from-control form-control-sm tbCodArticulo"
              value={slcSupervisores}
              defaultOptions
              getOptionLabel={e => `${e.Code} ${e.Name}`}
              getOptionValue={e => e.Code}
              loadOptions={funDSupervisores}
              onChange={handleSelectChangeSupervisor}
              maxMenuHeight={150}
            />
            {/* <input type="text" className="form-control form-control-sm" autoComplete='off' value={dataForm.supervisor} onChange={handleFieldChange('supervisor')} /> */}
          </div>
        </div>
        <div className="d-flex flex-column col-md-5">
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Tipo de documento
              </span>
            </label>
            <select id="slc_t_con" className="form-select form-select-sm" value={dataForm.tipodocumento} onChange={handleFieldChange('tipodocumento')} >
              <option value={"9999"} >--Seleccione--</option>
              {
                tipoDocumento?.map((item, index) => {
                  return <option value={item?.Code} key={index} >{item?.Name}</option>
                })
              }
            </select>
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                N° Documento
              </span>
            </label>
            <input type="number" className="form-control form-control-sm" value={dataForm.documentoidentidad} onChange={handleFieldChange('documentoidentidad')} />
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Cargo
              </span>
            </label>
            <select id="slc_t_con" className="form-select form-select-sm" value={dataForm.cargo} onChange={handleFieldChange('cargo')} >
              <option value={"9999"} >--Seleccione--</option>
              {
                cargoCombobox?.map((item, index) => {
                  return <option value={item?.Code} key={index} >{item?.Name}</option>
                })
              }
            </select>
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Mesa
              </span>
            </label>
            <AsyncSelect
              styles={styles}
              placeholder="Busque por Desc."
              name="form-field-name"
              className="sele_pru from-control form-control-sm tbCodArticulo"
              value={slcMesa}
              defaultOptions
              getOptionLabel={e => `${e.Code} ${e.Name}`}
              getOptionValue={e => e.Code}
              loadOptions={funDMesa}
              onChange={handleSelectChangeMesa}
              maxMenuHeight={150}
            />
            {/* <select className='form-select form-select-sm' value={dataForm.mesa} onChange={handleFieldChange('mesa')} >
              <option value="">--Seleccione--</option>
              <option value={"Mesa 1"}>Mesa 1</option>
              <option value={"Mesa 2"}>Mesa 2</option>
            </select> */}
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Canal
              </span>
            </label>
            <select className='form-select form-select-sm' value={dataForm.canal} onChange={handleFieldChange('canal')} >
              <option value="9999">--Seleccione--</option>
              {
                canal?.map((item, index) => {
                  return <option value={item?.Codigo} key={index} >{item?.Nombre}</option>
                })
              }
            </select>
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                GrupoVentas
              </span>
            </label>
            <select className='form-select form-select-sm' value={dataForm.grupoVentas} onChange={handleFieldChange('grupoVentas')} >
              <option value="9999">--Seleccione--</option>
              {
                listGrupoVentas?.map((item, index) => {
                  return <option value={item?.Code} key={index} >{item?.Name}</option>
                })
              }
            </select>
          </div>
          <div className="col form-group">
            <label className="col-form-label m-0 p-0 mb-2">
              <span className="small">
                Mesa ab grupo venta
              </span>
            </label>
            <input className="form-control form-control-sm" value={dataForm.mesaabgrupoventa} onChange={handleFieldChange('mesaabgrupoventa')} />
          </div>
        </div>
      </div>
      <div className='d-flex flex-row justify-content-between mt-2'>
        <div className="d-flex flex-column">
          <div className='d-flex mt-2'>
            <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
              <input className="form-check-input" type="checkbox" checked={dataForm.activo} onChange={handleFieldChangeChecked('activo')} />
            </div>
            <div className="small cont-weight-normal mx-1">
              Activo:
            </div>
          </div>
          <div className='d-flex mt-2'>
            <div className="d-flex justify-content-center mx-1 form-check form-check-danger">
              <input className="form-check-input" type="checkbox" checked={dataForm.asignaVisita} onChange={handleFieldChangeChecked('asignaVisita')} />
            </div>
            <div className="small cont-weight-normal mx-1">
              Asigna visita:
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-end mt-4">
          <button type="button" className="btn btn-label-success btn-sm" id="btn_modal_guardar" onClick={(e) => handleButtonClick(e)} >
            <i className='bx bx-save' id="icon_btn_modal_guardar" ></i>
            &nbsp;Guardar
          </button>
        </div>
      </div>
    </>
  );
};
