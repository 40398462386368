import React, { useState }  from 'react'
import '../../App.css';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { AButton } from '../../components/Button';
import Cookies from 'universal-cookie';
import PdfEJEM from "../../components/pdf/PdfEjemplo";
import { PDFDownloadLink } from "@react-pdf/renderer"



const moment = require('moment')


const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');


export const REPLiquidicionTransportista = () => {
  const [inp_text_fecha, setInp_text_fecha] = useState(new Date());
  const [inp_text_vehic, setInp_text_vehic] = useState('');
  //const [pending, setPending] = useState(false);
  const [dataReport, setdataReport] = useState([]);
  const changeInp_text_vehic = (event) => { setInp_text_vehic(event.target.value); }


  const onClickConsultar = async() => {
    setdataReport([])
    //setPending(true)
    const url_dataReport = `/apiReparto?vcodEmpresa=${cook_idEmp}&vTipoReport=${inp_text_fecha}&NumConsolidado=${inp_text_vehic}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    console.log("data :", data);
    setdataReport(data)
    //setPending(false)

  }

  return (
    <div>
      <div className="card card-action">
        <div className="card-body ">
          <div className="row">
            <div className="col-lg-1">Fecha:</div>
            <div className="col-lg-2" style={{ zIndex: 1000 }}>
              <DatePicker
                id="fecha"
                locale="es"
                className="form-control form-control-sm text-center"
                selected={inp_text_fecha}
                onChange={date => setInp_text_fecha(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-2">Placa Vehiculo:</div>
            <div className="col-md-2">
              <input type="text" id="txtVehi" name="txtVehi" className="form-control form-control-sm"  autoComplete='off' value={inp_text_vehic} onChange={changeInp_text_vehic} required={true} />
            </div>
            <div className="col-lg-2">
              <PDFDownloadLink document={<PdfEJEM datallegada={dataReport} />} fileName="ejemplo">
                <AButton tp_tilde="Guardar Tipo de mesa" bt_descr="EXPORTAR PDF" id="btn1" tp_posit="top" bt_class="btn btn-sm  btn-label-youtube"
                  bt_funct={onClickConsultar} disabled={({loading}) => (loading  ? true  : false)} 
                  ic_class="bx bx-xs bxs-file-pdf " vl_tpbtn={2} 
                />
              </PDFDownloadLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  
}



const RLIQTransportista = () => {
  return (
    <div>
      <small className="text-light fw-medium"> RLIQTransportista Basic Accordion</small>
      <div className="row">
        <div className="col-md mb-4 mb-md-2">
          <div className="accordion mt-3" id="accordionExample">
            <div className="card accordion-item ">
              <h2 className="accordion-header d-flex align-items-center" id="headingOne">
                <button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="true" aria-controls="accordionOne">
                  <i class="bx bx-bar-chart-alt-2 me-2"></i>
                  Liquidación Transportista
                </button>
              </h2>
              <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div className="accordion-body mt-2 mb-2">
                  <REPLiquidicionTransportista />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      
    </div>

  )
}

export default RLIQTransportista