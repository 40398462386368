import Cookies from "universal-cookie";
// import { SelectSucursal } from "../../components/Selects/SelectsPrincipales";
import { useEffect, useMemo, useState } from "react";
import { Box, Modal } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { AButton } from "../../components/Button";
import { ToastContainer } from "react-toastify";
import { notify_valido, notify_warning } from "../../components/utils";

const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
// const dtUser = cookies.get('dtUser');

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  // width: '500rem',
  bgcolor: 'background.default',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};


const COMSupervisores = () => {
  const [openModal, setOpenModal] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [pending, setpending] = useState(false);
  const [dataSelected, setDataSelected] = useState('');
  const [isEdit, setEdit] = useState(false);
  // const emp_changeSl_t_suc = (event) => {
  //   emp_setSl_t_suc(event.target.value);
  // }
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  }
  const listar = async () => {
    setpending(true);
    const url = `/getSupervisores?vcodEmpresa=${cook_idEmp}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url, opPe);
    const data = await response.json();
    setDataTable([...data]);
    setpending(false);
  };
  const columns = useMemo(() => [
    { header: 'Codigo', accessorKey: 'Code' },
    { header: 'Nombre supervisor', accessorKey: 'U_MSS_NOSU' },
    {
      header: 'Activo', accessorKey: 'U_MSS_ESSU', Cell: ({ row }) => (
        <>
          <div className='align-items-center form-check form-check-danger'>
            <div className='align-items-center'>
              <input className="form-check-input" type="checkbox" checked={row.original.U_MSS_ESSU === 'Y'} readOnly />
            </div>
          </div>
        </>
      )
    },
    { header: 'Empleado ventas', accessorKey: 'U_MSS_EVEN' },
  ], []);

  const onClickEdit = (event) => {
    setDataSelected(event);
    setEdit(true);
    handleOpenModal();
  }
  const onClickNuevo = (event) => {
    setDataSelected('');
    setEdit(false);
    handleOpenModal();
  }

  const setDataReturn = () => {
    listar();
    setDataSelected('');
    setEdit(false);
    handleCloseModal();
  }

  return <>
    <div className='d-flex flex-column'>
      <h6 className="text-muted">Supervisores</h6>
    </div >
    <div className="d-flex">
      {/* 
        <div className="col-lg-1">Sucursal:</div>
        <div className="col-lg-2">
          <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
        </div>
      */}
    </div>
    <MaterialReactTable
      columns={columns}
      data={dataTable}
      initialState={{ density: 'compact' }}
      enablePagination={true}
      enableRowActions
      enableGrouping={true}
      state={{ isLoading: pending }}
      renderRowActions={({ row }) => (
        <>
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
            <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-label-warning btn-icon btn-sm" ic_class="bx bxs-edit bx-ms" bt_funct={() => onClickEdit(row.original)}
              id={JSON.stringify(row.original)} vl_tpbtn={1} />
          </Box>
        </>
      )}
      renderTopToolbarCustomActions={({ row }) => (
        <>
          <div className="row col-6">
            <div className="col-md-3">
              <div className="text-center mb-3">
                <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={listar}>
                  <i className='bx bx-list-ul' ></i>&nbsp;Consultar
                </button>
              </div>
            </div>
            <div className="col-md-3">
              <div className="text-center mb-3">
                <button className="btn btn-warning btn-sm" id="veh_btn_nuevo" onClick={onClickNuevo}>
                  <i className='bx bx-plus' ></i>&nbsp;Nuevo
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      muiCircularProgressProps={{ color: 'error' }}
      muiSkeletonProps={{
        animation: 'pulse',
        height: 28,
      }}
    />
    <Modal
      open={openModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: '25rem', height: '22rem', overflow: 'auto' }}>
        <button
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          className='btn btn-outline-dark btn-sm'
          onClick={handleCloseModal}
        >
          <i className='fa fa-x'></i>
        </button>
        <VistaEnModal data={dataSelected} tipoModal={isEdit} dataReturn={setDataReturn} />
      </Box>
    </Modal>
    <ToastContainer />
  </>
};

const VistaEnModal = ({ data, tipoModal, dataReturn }) => {
  const [formulario, setFormulario] = useState({
    Code: data ? data.Code : '',
    U_MSS_NOSU: data ? data.U_MSS_NOSU : '',
    U_MSS_ESSU: data ? data.U_MSS_ESSU === 'Y' : false,
    U_MSS_EVEN: data ? data.U_MSS_EVEN : '',
  });
  const handleFieldChange = (fieldName) => (event) => {
    let value = event.target.value;
    console.log(formulario);
    console.log({ ...formulario, [fieldName]: value, });
    setFormulario({
      ...formulario,
      [fieldName]: value,
    });
  };
  const handleFieldChangeChecked = (fieldName) => (event) => {
    let value = event.target.checked;
    setFormulario({
      ...formulario,
      [fieldName]: value,
    });
  };
  const handleButtonClick = async () => {
    const url = `${tipoModal ? '/updateSupervisores' : '/saveSupervisores'}?vcodEmpresa=${cook_idEmp}`;
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(formulario) }
    const response = await fetch(url, opPe);
    const res = await response.json();
    console.log(res);
    if (res?.status === 400) {
      return notify_warning("Algo salio mal, no se realizo el registro de la información");
    }
    notify_valido("Guardado");
    dataReturn(res);
  }
  return <>
    <div className="d-flex">
      <label className="col-form-label m-0 p-0">
        <span className="small fs-6">
          Colaborador
        </span>
      </label>
    </div>
    <div className="d-flex justify-content-between">
      <div className="d-flex flex-column col-md-12">
        <div className="col form-group">
          <label className="col-form-label m-0 p-0 mb-2">
            <span className="small">
              Codigo
            </span>
          </label>
          <input type="text" className='form-control form-control-sm' autoComplete='off' value={formulario.Code} onChange={handleFieldChange('Code')} disabled={tipoModal} />
        </div>
        <div className="col form-group">
          <label className="col-form-label m-0 p-0 mb-2">
            <span className="small">
              Nombres del supervisor
            </span>
          </label>
          <input type="text" className='form-control form-control-sm' autoComplete='off' value={formulario.U_MSS_NOSU} onChange={handleFieldChange('U_MSS_NOSU')} />
        </div>
        {/* <div className="col form-group">
          <label className="col-form-label m-0 p-0 mb-2">
            <span className="small">
              Empleado ventas
            </span>
          </label>
          <input type="text" className='form-control form-control-sm' autoComplete='off' value={formulario.U_MSS_EVEN} onChange={handleFieldChange('U_MSS_EVEN')} />
        </div> */}
        <div className="col form-check">
          <label className="form-check-label m-0 p-0 mb-2" htmlFor='activeSupervisor'>
            <span className="small">
              Activo
            </span>
          </label>
          <input type="checkbox" id="activeSupervisor" className='form-check-input' autoComplete='off' checked={formulario.U_MSS_ESSU} onChange={handleFieldChangeChecked('U_MSS_ESSU')} />
        </div>
        <div className="d-flex flex-column align-items-end mt-4">
          <button type="button" className="btn btn-label-success btn-sm" id="btn_modal_guardar" onClick={(e) => handleButtonClick(e)} >
            <i className='bx bx-save' id="icon_btn_modal_guardar" ></i>
            &nbsp;Guardar
          </button>
        </div>
      </div>
    </div>
  </>
}

export default COMSupervisores;