/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect} from 'react'
//import $ from 'jquery'


const Usuarios = () => {

    useEffect(() => {
        document.getElementById("myCheck").click();
      },[])
      const handleClick = (event) => {
        event.preventDefault();
      }
    
  return ( <>
    <h4 className="fw-bold py-3 mb-4"> Usuarios </h4>
    <div className="col-md">

        <div className="card card-action mb-4">
        <div className="card-alert"></div>
        <div className="card-header">
            <div className="card-action-title">Refresh Content</div>
            <div className="card-action-element">
            <ul className="list-inline mb-0">
                <li className="list-inline-item">
                <a href="#" onClick={handleClick} id='myCheck' className="card-reload"><i className="tf-icons bx bx-rotate-left scaleX-n1-rtl"></i></a>
                </li>
            </ul>
            </div>
        </div>

        <div className="card-body">
            <p className="card-text">To create a card with refresh action, use <code>.card-reload</code> className with action item. Use <code>.card-alert</code> className to show custom response message.</p>
            <p className="card-text">Click on <i className="tf-icons bx bx-rotate-left scaleX-n1-rtl"></i> icon to see refresh card content in action.</p>
        </div>
        </div>
    </div>

    <div className="card card-action mb-4">
        <div className="card-header">
            <div className="card-action-title">Collapsible Card</div>
            <div className="card-action-element">
                <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                        <a href="#" onClick={handleClick} className="card-collapsible"><i className="tf-icons bx bx-chevron-up"></i></a>
                    </li>
                    <li className="list-inline-item">
                    <a href="#" onClick={handleClick} className="card-collapsible"><i className="tf-icons bx bx-chevron-up"></i></a>
                    </li>
                    <li className="list-inline-item">
                    <a href="#" onClick={handleClick} className="card-reload"><i className="tf-icons bx bx-rotate-left scaleX-n1-rtl"></i></a>
                    </li>
                    <li className="list-inline-item">
                    <a href="#" onClick={handleClick} className="card-expand"><i className="tf-icons bx bx-fullscreen"></i></a>
                    </li>
                    <li className="list-inline-item">
                    <a href="#" onClick={handleClick} className="card-close"><i className="tf-icons bx bx-x"></i></a>
                    </li>
                </ul>
            </div>
        </div>
        <div className="collapse show">
            <div className="card-body pt-0">
                <form className="card-body" >
                    <h6>1. Account Details</h6>
                    <div className="row g-3">
                    <div className="col-md-6">
                        <label className="form-label" htmlFor="multicol-username">Username</label>
                        <input type="text" id="multicol-username" className="form-control" placeholder="john.doe" />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label" htmlFor="multicol-email">Email</label>
                        <div className="input-group input-group-merge">
                        <input type="text" id="multicol-email" className="form-control" placeholder="john.doe" aria-label="john.doe" aria-describedby="multicol-email2" />
                        <span className="input-group-text" id="multicol-email2">@example.com</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-password-toggle">
                        <label className="form-label" htmlFor="multicol-password">Password</label>
                        <div className="input-group input-group-merge">
                            <input type="password" id="multicol-password" className="form-control" placeholder="············" aria-describedby="multicol-password2" />
                            <span className="input-group-text cursor-pointer" id="multicol-password2"><i className="bx bx-hide" /></span>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-password-toggle">
                        <label className="form-label" htmlFor="multicol-confirm-password">Confirm Password</label>
                        <div className="input-group input-group-merge">
                            <input type="password" id="multicol-confirm-password" className="form-control" placeholder="············" aria-describedby="multicol-confirm-password2" />
                            <span className="input-group-text cursor-pointer" id="multicol-confirm-password2"><i className="bx bx-hide" /></span>
                        </div>
                        </div>
                    </div>
                    </div>
                    <hr className="my-4 mx-n4" />
                    <h6>2. Personal Info</h6>
                    <div className="row g-3">
                    <div className="col-md-6">
                        <label className="form-label" htmlFor="multicol-first-name">First Name</label>
                        <input type="text" id="multicol-first-name" className="form-control" placeholder="John" />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label" htmlFor="multicol-last-name">Last Name</label>
                        <input type="text" id="multicol-last-name" className="form-control" placeholder="Doe" />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label" htmlFor="multicol-country">Country</label>
                        <select id="multicol-country" className="select2 form-select" data-allow-clear="true">
                        <option value>Select</option>
                        <option value="Australia">Australia</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Brazil">Brazil</option>
                        <option value="Canada">Canada</option>
                        <option value="China">China</option>
                        <option value="France">France</option>
                        <option value="Germany">Germany</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Japan">Japan</option>
                        <option value="Korea">Korea, Republic of</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Russia">Russian Federation</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        </select>
                    </div>
                    
                    <div className="col-md-6">
                        <label className="form-label" htmlFor="multicol-birthdate">Birth Date</label>
                        <input type="text" id="multicol-birthdate" className="form-control dob-picker" placeholder="YYYY-MM-DD" />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label" htmlFor="multicol-phone">Phone No</label>
                        <input type="text" id="multicol-phone" className="form-control phone-mask" placeholder="658 799 8941" aria-label="658 799 8941" />
                    </div>
                    </div>
                    <div className="pt-4">
                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Submit</button>
                    <button type="reset" className="btn btn-label-secondary">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </>
  )
}

export default Usuarios