import { Box, Modal } from "@mui/material";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.default',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const COMZonas = () => {
  return (
    <>
      <TableZonas />
      <ToastContainer />
      {/* <div className='d-flex flex-column'>
      </div > */}
    </>
  );
}

export default COMZonas;

export const TableZonas = () => {
  const [open, setOpen] = useState(false);
  const [sedes, setSedes] = useState([]);
  const [tipoModal, setTipoModal] = useState();
  const [sedeId, setSedeId] = useState();
  const [zonaId, setZonaId] = useState();

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const toggleVisibility = (id) => {
    setSedes(prevSedes => prevSedes.map(sede =>
      sede.id === id ? { ...sede, isVisible: !sede.isVisible } : sede
    ));
  };

  const toggleZonaVisibility = (sedeId, zonaId) => {
    setSedes(prevSedes => prevSedes.map(sede =>
      sede.id === sedeId ? {
        ...sede,
        zonas: sede.zonas.map(zona =>
          zona.id === zonaId ? { ...zona, isVisible: !zona.isVisible } : zona
        )
      } : sede
    ));
  };

  const addZona = (id, nombre) => {
    setSedes(prevSedes => prevSedes.map(sede => {
      const nuevaZona = {
        id: sede.zonas.length,
        nombre: nombre,
        isVisible: false,
        ruta: []
      };
      return sede.id === id
        ? { ...sede, zonas: [...sede.zonas, nuevaZona] }
        : sede;
    }));
  };

  const openModal = (data) => {
    setTipoModal(data.tipo);
    setSedeId(data.sedeId)
    setZonaId(data.zonaId);
    handleOpen();
  };

  const addSede = (nombre) => {
    const nuevaSede = {
      id: sedes.length,
      ciudad: nombre,
      isVisible: false,
      zonas: []
    };
    setSedes(prevSedes => [...prevSedes, nuevaSede]);
  }

  const addRuta = (sedeId, zonaId, nombre) => {
    setSedes(prevSedes => prevSedes.map(sede =>
      sede.id === sedeId ? {
        ...sede,
        zonas: sede.zonas.map(zona =>
          zona.id === zonaId ? {
            ...zona,
            ruta: [...zona.ruta, { nombre: nombre }]
          } : zona
        )
      } : sede
    ));
  };

  const setInfo = (event) => {
    if (event.tipo === "Sede") {
      addSede(event.data);
    }
    if (event.tipo === "Zona") {
      addZona(event.sedeId, event.data);
    }
    if (event.tipo === "Ruta") {
      addRuta(event.sedeId, event.zonaId, event.data);
    }
    handleClose();
  }
  return (
    <>
      <div className="col-12 p-0 m-0 overflow-h-x" align="center">
        <hr />
        <div className="col-12 col-md-10 p-0 m-0" align="justify">
          <div className="col-12 p-2 p-md-0 m-0 d-flex justify-content-between">
            <span >Zonas</span>
            <button type="button" className="btn btn-sm btn-outline-youtube d-md-inline" onClick={() => openModal({ tipo: 'Sede' })}>
              <span>Agregar Sucursal </span><i className='bx bx-plus'></i>
            </button>
          </div>
          <div className="col p-0 m-0">
            <div className="col-12 p-2 p-md-0 m-0 mt-0 mt-sm-3 container overflow-h-x">
              <div className="row row-cols-1 p-1">
                {
                  sedes.map((sede, index) => (
                    <div key={`sede_${index}`} className="col m-2 mb-1 cursor-pointer">
                      <div className="card card-link alert-youtube text-nowrap text-truncate shadow-sm m-1 text-primary alert-form-primary"
                        onClick={() => toggleVisibility(sede.id)}>
                        <div className="col card-body p-2 d-flex justify-content-between">
                          <div className="col-4 justify-content-start p-0 m-0">
                            <i className='bx bx-store-alt'></i>
                            <span className="small font-weight-light pl-1"> {sede.ciudad}</span>
                          </div>
                        </div>
                      </div>
                      {
                        sede.isVisible && (
                          <div className="col-12 m-2 ml-0 container overflow-h-x">
                            <button type="button" className="btn btn-sm btn-outline-youtube d-none d-md-inline" onClick={() => openModal({ sedeId: sede.id, tipo: "Zona" })}>
                              <span>Agregar Zona</span><i className='bx bx-plus'></i>
                            </button>
                            <div className="row row-cols-1 p-1">
                              {sede.zonas.map((zona, indexx) => (
                                <div key={`zona_${indexx}`} className="col m-2 mb-1 p-0 cursor-pointer">
                                  <div className="card card-link alert-youtube shadow-sm m-1"
                                    onClick={() => toggleZonaVisibility(sede.id, zona.id)}>
                                    <div className="col card-body p-2 d-flex justify-content-between">
                                      <div className="col-4 justify-content-start p-0 m-0">
                                        <i className='bx bxs-map'></i>
                                        <span className="small font-weight-light pl-1"> {zona.nombre}</span>
                                      </div>
                                    </div>
                                  </div>
                                  {zona.isVisible && (
                                    <div className="col-12 m-2 ml-0 container overflow-h-x">
                                      <button type="button" className="btn btn-sm btn-outline-youtube d-md-inline" onClick={() => openModal({ sedeId: sede.id, zonaId: zona.id, tipo: 'Ruta' })}>
                                        <span>Agregar Ruta</span><i className='bx bx-plus'></i>
                                      </button>
                                      <div className="row row-cols-1 p-1">
                                        {zona.ruta.map((ruta, rutaIdx) => (
                                          <div key={`ruta_${rutaIdx}`} className="col m-2 mb-1 p-0 cursor-pointer">
                                            <div className="card card-link alert-youtube text-nowrap text-truncate shadow-sm m-1">
                                              <div className="col card-body p-2 d-flex justify-content-between">
                                                <div className="col-4 justify-content-start p-0 m-0">
                                                  <i className='bx bxs-map-pin'></i>
                                                  <span className="small font-weight-light pl-1"> {ruta.nombre}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
      >
        <Box sx={{ ...style, width: '19rem', height: '11rem', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={handleClose}
          >
            <i className='fa fa-x'></i>
          </button>
          <NuevosParametros tipoModal={tipoModal} sedeId={sedeId} zonaId={zonaId} returnData={setInfo}></NuevosParametros>
        </Box>
      </Modal>
    </>
  );
};


export const NuevosParametros = ({ tipoModal, sedeId, zonaId, returnData }) => {
  const [name, setName] = useState("");

  const saveInfo = () => {
    returnData({ tipo: tipoModal, sedeId, zonaId, data: name.toUpperCase() });
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-center p-0 m-0">
        {
          tipoModal === "Sede" ?
            <h6>Nueva Sede</h6> : tipoModal === "Zona" ?
              <h6>Nueva Zona</h6> :
              <h6>Nueva Ruta</h6>
        }
      </div>
      <div className="d-flex flex-column p-0 m-0">
        <div className="d-flex flex-column">
          <div className="d-flex flex-column form-group mx-1">
            <div className="small cont-weight-normal">
              Nombre:
            </div>
            <input type="text" className="form-control form-control-sm" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end ml-auto mt-2">
        <div className="d-flex m-1">
          <button className="btn btn-sm btn-outline-success form-control" onClick={saveInfo}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
}