import 'react-data-table-component-extensions/dist/index.css';
import React, { useMemo, useState } from 'react';
import { Box, Modal } from '@mui/material';
import { ModalDataClientsAndLeads } from '../../components/Modals/ModalsDetVen';
import { notify_valido, notify_warning } from '../../components/utils';
import { ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { AButton } from '../../components/Button';
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales';
import { createTheme, ThemeProvider } from '@mui/material';
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const globalTheme = window.templateCustomizer.settings.style;
const dtUser = cookies.get('dtUser');
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.default',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const VistaClientes = () => {
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [pending, setpending] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = useState([]);
  const [searchTextFor, setSearchTextFor] = useState([]);
  const [tipoModal, setTipoModal] = useState(false);
  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }

  const handleOpen = async (data) => {
    const fechtApi = async (CardCode) => {
      const url = `apiGetLeadOrClientsDistribucionByCode?codigo=${CardCode}`;
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
      const response = await fetch(url, opPe);
      const data = await response.json();
      return data;
    };
    setCode(await fechtApi(data.CardCode));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTipoModal(false);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };
  const columns = useMemo(() => [
    { header: 'Codigo', accessorKey: 'CardCode' },
    { header: 'Nombre', accessorKey: 'CardName', accessorFunction: formatDate },
    { header: 'Fecha de ingreso', accessorKey: 'CreateDate' },
    { header: 'Condiciones de pago', accessorKey: 'PymntGroup' },
    { header: 'Categoria', accessorKey: 'GroupName' },
  ], []);
  const listar = async () => {
    if (!emp_sl_t_suc || emp_sl_t_suc === '9999') {
      notify_warning('Seleccione una sucursal');
      return;
    }
    if (!searchTextFor) {
      notify_warning('Ingrese un valor de buqueda');
      return;
    }
    setpending(true);
    const url_lstClientes = `/apiClienteDistribucion?vcodEmpresa=${cook_idEmp}&txtSearch=${searchTextFor.toString().toUpperCase()}&suc=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    if (data.length === 0) {
      notify_warning('Sin clientes registrados')
    }
    const formattedData = data.map(item => ({
      ...item,
      CreateDate: formatDate(item.CreateDate),
    }));
    setFilteredData(formattedData);
    setpending(false);
  }

  const setDataReturn = async (data) => {
    let urlPost = `/apiSaveClientsOrLeads?vcodEmpresa=${cook_idEmp}&codigo=${data.FormPrincipal.codigo}&sucursal=${emp_sl_t_suc}`;
    if (!code) {
      urlPost = `/apiSaveClientsOrLeads?vcodEmpresa=${cook_idEmp}&codigo=${data.FormPrincipal.codigo}&sucursal=${emp_sl_t_suc}&isNewClient=true`;
    }
    let body = data;
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(urlPost, opPe);
    const dataResponse = await response.json();
    if (dataResponse?.status === 400) {
      return notify_warning("Por favor revisar la información a enviar, posible repetición de cliente");
    }
    notify_valido("Guardado");
    if (dataResponse) {
      listar();
      handleClose();
      setTipoModal(false);
    }
  }
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [],
  );

  const nuevoCliente = () => {
    setCode(null);
    setOpen(true);
    setTipoModal(true);
  }

  return (
    <>
      <ThemeProvider theme={tableTheme}>
        <div className="d-flex">
          <div className="col-lg-1">Sucursal:</div>
          <div className="col-lg-2">
            <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
          </div>
          <div className="col-lg-1">Buscar:</div>
          <div className="col-lg-3">
            <input type="text" id="txt_sp_codigo" name="txt_sp_codigo" className="form-control form-control-sm" autoComplete='off' value={searchTextFor} onChange={(event) => setSearchTextFor(event.target.value)} />
          </div>
          <div className="col-lg-2">
            <div className="text-center mb-3">
              <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={listar}>
                <i className='bx bx-list-ul' ></i>&nbsp;Consultar
              </button>
            </div>
          </div>
          { // el usuario 5 solo puede visualizar
            emp_sl_t_suc && dtUser.tpu_id !== '5' && (
              <div className="col-lg-2">
                <div className="text-center mb-3">
                  <button className="btn btn-warning btn-sm" id="veh_btn_mlista" onClick={nuevoCliente}>
                    <i className='bx bx-file'></i>&nbsp;Nuevo
                  </button>
                </div>
              </div>
            )
          }

        </div>
        <div className="card-body">
          <MaterialReactTable
            columns={columns}
            data={filteredData}
            initialState={{ density: 'compact' }}
            enablePagination={true}
            enableRowActions
            enableGrouping={true}
            state={{ isLoading: pending }}
            localization={MRT_Localization_ES}
            renderRowActions={({ row }) => {
              //  El usuario 5 solo puede visualizar 
              return (
                <>
                  {
                    dtUser.tpu_id !== '5' ? (<Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '2px' }}>
                      <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-label-warning btn-icon btn-sm" ic_class="bx bxs-edit bx-ms" bt_funct={() => handleOpen(row.original)}
                        id={JSON.stringify(row.original)} vl_tpbtn={1} />
                    </Box>) : (
                      <div></div>
                    )}
                </>
              );

            }}
            muiCircularProgressProps={{ color: 'error' }}
            muiSkeletonProps={{
              animation: 'pulse',
              height: 28,
            }}
          />
          <Modal
            open={open}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: '85%', height: '90%', overflow: 'auto' }}>
              <button
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}
                className='btn btn-outline-dark btn-sm'
                onClick={handleClose}
              >
                <i className='fa fa-x'></i>
                {/* X */}
              </button>
              <ModalDataClientsAndLeads tipo={tipoModal} surcusal={emp_sl_t_suc} code={code} dataReturn={setDataReturn} />
            </Box>
          </Modal>
        </div>
      </ThemeProvider>
    </>
  );
};

const GPClientes = () => {
  return (
    <div className='d-flex flex-column'>
      <h6 className="text-muted">Clientes</h6>
      <VistaClientes />
      <ToastContainer />
    </div >
  );
};

export default GPClientes;
