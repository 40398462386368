/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useMemo, useCallback} from 'react'
import Cookies from 'universal-cookie';
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales'
import {notify_warning, notify_valido, selectTabla, selectTablaMultiple,removeSelectedRows} from '../../components/utils';
import $ from 'jquery'
import '../../App.css';
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import {Box, Modal, Tooltip}from '@mui/material';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { AButton } from '../../components/Button';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const moment = require('moment')

const globalTheme = window.templateCustomizer.settings.style;


const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};


export const Report1 = (prosp) =>{ // RUTAS
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,            setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());



  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns1  = useMemo( () => [
    { header: 'RUTA',           accessorKey: 'Ruta'},
    { header: 'NOMBRE',            accessorKey: 'Nombre' },
    { header: 'ACTIVO',            accessorKey: 'Activo' },
    // { header: 'TIPOCLIENTE',    accessorKey: 'TIPOCLIENTE' },
    // { header: 'SUBCATEGORIA',   accessorKey: 'SUBCATEGORIA' },
    // { header: 'IMPORTE',        accessorKey: 'IMPORTE' },
    // { header: 'IMPORTEOFICINA', accessorKey: 'IMPORTEOFICINA' },
  ],[])
  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    console.log("Campo de la sucursal::", emp_sl_t_suc);

    // Modificacion para que cuando no se selecione una sucursal en especifico
    // se envie un campo vacio  y liste todas las sucursales(30-07)
    // console.log(" emp_sl_t_suc:: ", emp_sl_t_suc); 
    let codSucursal=emp_sl_t_suc
    console.log(" codSucursal:: ", codSucursal); 
    if(codSucursal=='9999'){
      codSucursal='';
    }
    console.log(" codSucursal:: ", codSucursal); 
    
    // console.log(" emp_sl_t_suc:: ", emp_sl_t_suc);
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiDistribucion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${codSucursal}&vfecD=${transFechaD}&vfecH=${transFechaH}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    console.log(data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    emp_setSl_t_suc([])
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "RUTAS_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "RUTAS_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
              {/* <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Desde:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaD} 
                  onChange={date => setText_fechaD(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Hasta:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaH} 
                  onChange={date => setText_fechaH(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div> */}
              <div className="col-lg-4">
                <label className="form-label mb-0 " >Sucursal:</label>
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
            </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
              </div>
            </Tooltip>
            <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
          
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
        />
      </div>
    </>
  )
}
export const Report2 = (prosp) =>{ // ZONAS
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,            setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());
 
  /** Primer reporte */
  const columns1  = useMemo( () => [
    { header: 'RUTA',           accessorKey: 'Ruta'},
    { header: 'NOMBRE',            accessorKey: 'Nombre' },
    { header: 'ACTIVO',            accessorKey: 'Activo' },
    // { header: 'SEDE',           accessorKey: 'SEDE'},
    // { header: 'CODVDR',         accessorKey: 'CODVDR' },
    // { header: 'DNI',            accessorKey: 'DNI' },
    // { header: 'VENDEDOR',       accessorKey: 'VENDEDOR' },
    // { header: 'MESA',           accessorKey: 'MESA' },
    // { header: 'CODLINEA',       accessorKey: 'CODLINEA' },
    // { header: 'LINEA',          accessorKey: 'LINEA' },
    // { header: 'VALORVENTA',     accessorKey: 'VALORVENTA' },
    // { header: 'VALORPROMOCION', accessorKey: 'VALORPROMOCION' },
    // { header: 'VALORDESCUENTO', accessorKey: 'VALORDESCUENTO' },
    // { header: 'COSTOREAL',      accessorKey: 'COSTOREAL' },
    // { header: 'COSTOPROM',      accessorKey: 'COSTOPROM' },
    // { header: 'COBERTURA',      accessorKey: 'COBERTURA' },
    // { header: 'PEDIDOS',        accessorKey: 'PEDIDOS' }
  ],[])
  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiDistribucion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vfecD=${transFechaD}&vfecH=${transFechaH}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "ZONAS_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "ZONAS_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
              {/* <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Desde:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaD} 
                  onChange={date => setText_fechaD(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Hasta:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaH} 
                  onChange={date => setText_fechaH(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div> */}
            </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
              </div>
            </Tooltip>
            <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}
export const Report3 = (prosp) =>{  // REPORTE DE VENDEDORES 
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,            setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());
 
  /** Primer reporte */
  const columns1  = useMemo( () => [
    { header: 'CODIGO',            accessorKey: 'Codigo' },
    { header: 'VENDEDOR',            accessorKey: 'Vendedor' },
    { header: 'MESA',            accessorKey: 'Mesa' },
    { header: 'ESTADO',            accessorKey: 'Estado' },
    { header: 'SUCURSAL',            accessorKey: 'Sucursal' },
    // { header: 'Fecha',              accessorKey: 'Fecha'},
    // { header: 'SucursalCodigo',     accessorKey: 'SucursalCodigo' },
    // { header: 'SucursalNombre',     accessorKey: 'SucursalNombre' },
    // { header: 'CategoriaCliente',   accessorKey: 'CategoriaCliente' },
    // { header: 'Mesa',               accessorKey: 'Mesa' },
    // { header: 'Proveedor',          accessorKey: 'Proveedor' },
    // { header: 'Placa',              accessorKey: 'Placa' },
    // { header: 'VendedorCodigo',     accessorKey: 'VendedorCodigo' },
    // { header: 'ZonaCodigo',         accessorKey: 'ZonaCodigo' },
    // { header: 'ZonaNombre',         accessorKey: 'ZonaNombre' },
    // { header: 'RutaCodigo',         accessorKey: 'RutaCodigo' },
    // { header: 'RutaDescripcion',    accessorKey: 'RutaDescripcion' },
    // { header: 'Motivo',             accessorKey: 'Motivo' },
    // { header: 'MontoDespachado',    accessorKey: 'MontoDespachado' },
    // { header: 'MontoAnulados',      accessorKey: 'MontoAnulados' },
    // { header: 'SolesDevol',         accessorKey: 'SolesDevol' },
    // { header: 'SolesPerdidos',      accessorKey: 'SolesPerdidos' },
    // { header: 'SolesEfectivos',     accessorKey: 'SolesEfectivos' },
    // { header: 'PorcentajeSoles',    accessorKey: 'PorcentajeSoles' },
    // { header: 'Canal',              accessorKey: 'Canal' },
    // { header: 'Documento',          accessorKey: 'Documento' },
    // { header: 'Doc_FechaEmision',   accessorKey: 'Doc_FechaEmision' },
    // { header: 'Doc_Cliente',        accessorKey: 'Doc_Cliente' },
    // { header: 'Estado',             accessorKey: 'Estado' },
  ],[])
  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiDistribucion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vfecD=${transFechaD}&vfecH=${transFechaH}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE DE VENDEDORES_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE DE VENDEDORES__"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
                {/* <div className="col-lg-4" style={{ zIndex: '3' }}>
                  <label className="form-label mb-0 " >Fecha Desde:</label>
                  <DatePicker 
                    locale="es"
                    wrapperClassName='input_date'
                    className="form-control form-control-sm text-center"
                    selected={text_fechaD} 
                    onChange={date => setText_fechaD(date)} 
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
                <div className="col-lg-4" style={{ zIndex: '3' }}>
                  <label className="form-label mb-0 " >Fecha Hasta:</label>
                  <DatePicker 
                    locale="es"
                    wrapperClassName='input_date'
                    className="form-control form-control-sm text-center"
                    selected={text_fechaH} 
                    onChange={date => setText_fechaH(date)} 
                    dateFormat="yyyy-MM-dd"
                  />
                </div> */}
            </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
              </div>
            </Tooltip>
            <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}
export const Report4 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,            setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());
  const [slcProveedor,   setSlcProveedor] = useState([""]);
  const [slcSupervuso,   setSlcSupervuso] = useState([""]);
  const funSlcProvedor = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;
  }
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  const funListaSupervisores= async (inputValue) => {
    const url = `/apiListSupervisores?vcodEmpresa=${cook_idEmp}&vtxtConsult=${inputValue}`
    //const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;
  }
  /** Primer reporte */
  const columns1  = useMemo( () => [
    { header: 'CODIGO',            accessorKey: 'Codigo' },
    { header: 'DESCRIPCION',            accessorKey: 'Descripcion' },
    { header: 'CODIGO  SUPERVISOR',             accessorKey: 'SupervisorCodigo'},
    { header: 'NOMBRE SUPERVISOR',               accessorKey: 'SupervisorNombre'},
    // { header: 'Sede',                   accessorKey: 'Sede'},
    // { header: 'Estado',                 accessorKey: 'Estado'},
    // { header: 'Periodo',                accessorKey: 'Periodo'},
    // { header: 'FechaEmision',           accessorKey: 'FechaEmision'},
    // { header: 'CodCliente',             accessorKey: 'CodCliente'},
    // { header: 'Cliente',                accessorKey: 'Cliente'},
    // { header: 'DocIdentidad',           accessorKey: 'DocIdentidad'},
    // { header: 'Direccion',              accessorKey: 'Direccion'},
    // { header: 'CategoriaCliente',       accessorKey: 'CategoriaCliente'},
    // { header: 'CodTipoNegocio',         accessorKey: 'CodTipoNegocio'},
    // { header: 'TipoNegocio',            accessorKey: 'TipoNegocio'},
    // { header: 'Cod_Vehiculo',           accessorKey: 'Cod_Vehiculo'},
    // { header: 'Vehiculo',               accessorKey: 'Vehiculo'},
    // { header: 'Placa',                  accessorKey: 'Placa'},
    // { header: 'Conductor',              accessorKey: 'Conductor'},
    // { header: 'Documento',              accessorKey: 'Documento'},
    // { header: 'CondVenta',              accessorKey: 'CondVenta'},
    // { header: 'TipoItem',               accessorKey: 'TipoItem'},
    // { header: 'Motivo_Anulacion',       accessorKey: 'Motivo_Anulacion'},
    // { header: 'IDLinea',                accessorKey: 'IDLinea'},
    // { header: 'Linea',                  accessorKey: 'Linea'},
    // { header: 'Marca',                  accessorKey: 'Marca'},
    // { header: 'Familia',                accessorKey: 'Familia'},
    // { header: 'SubFamilia',             accessorKey: 'SubFamilia'},
    // { header: 'CodProducto',            accessorKey: 'CodProducto'},
    // { header: 'Producto',               accessorKey: 'Producto'},
    // { header: 'Cantidad',               accessorKey: 'Cantidad'},
    // { header: 'Unidad',                 accessorKey: 'Unidad'},
    // { header: 'Factor',                 accessorKey: 'Factor'},
    // { header: 'CantidadBase',           accessorKey: 'CantidadBase'},
    // { header: 'Cantidad_Rechazo',       accessorKey: 'Cantidad_Rechazo'},
    // { header: 'Monto_Origen',           accessorKey: 'Monto_Origen'},
    // { header: 'MontoRechazo',           accessorKey: 'MontoRechazo'},
    // { header: 'Cod_Promocion',          accessorKey: 'Cod_Promocion'},
    // { header: 'Promocion',              accessorKey: 'Promocion'},
    // { header: 'ValorVentaBonif',        accessorKey: 'ValorVentaBonif'},
    // { header: 'CodReglaDscto',          accessorKey: 'CodReglaDscto'},
    // { header: 'DescuentoPorcentual',    accessorKey: 'DescuentoPorcentual'},
    // { header: 'ValorDescuento',         accessorKey: 'ValorDescuento'},
    // { header: 'VentaAfecta',            accessorKey: 'VentaAfecta'},
    // { header: 'ValorIGV',               accessorKey: 'ValorIGV'},
    // { header: 'VentaTotal',             accessorKey: 'VentaTotal'},
    // { header: 'ID_Agente',              accessorKey: 'ID_Agente'},
    // { header: 'CodVendedor',            accessorKey: 'CodVendedor'},
    // { header: 'Vendedor',               accessorKey: 'Vendedor'},
    // { header: 'IDSupervisor',           accessorKey: 'IDSupervisor'},
    // { header: 'CodZona',                accessorKey: 'CodZona'},
    // { header: 'Zona',                   accessorKey: 'Zona'},
    // { header: 'Cod_Concepto',           accessorKey: 'Cod_Concepto'},
    // { header: 'Concepto',               accessorKey: 'Concepto'},
    // { header: 'TipoDoc_Referencia',     accessorKey: 'TipoDoc_Referencia'},
    // { header: 'Doc_Referencia',         accessorKey: 'Doc_Referencia'},
    // { header: 'TipoDeCambio',           accessorKey: 'TipoDeCambio'},
    // { header: 'FechaGuia',              accessorKey: 'FechaGuia'},
    // { header: 'CostoReal',              accessorKey: 'CostoReal'},
    // { header: 'CostoPromedio',          accessorKey: 'CostoPromedio'},
    // { header: 'GrupoVenta',             accessorKey: 'GrupoVenta'},
    // { header: 'Mesa',                   accessorKey: 'Mesa'},
    // { header: 'Canal',                  accessorKey: 'Canal'},
    // { header: 'Proveedor',              accessorKey: 'Proveedor'},
    // { header: 'Categoria',              accessorKey: 'Categoria'},
    // { header: 'SubCategoria',           accessorKey: 'SubCategoria'}
  ],[])
  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiDistribucion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}&vfecD=${transFechaD}&vfecH=${transFechaH}&vUnidadNe=${slcProveedor.Code}&vSupervis=${slcSupervuso.Code}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    emp_setSl_t_suc([])
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE_DE_MESAS_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE_DE_MESAS_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
            {/* <div className="col-lg-4" style={{ zIndex: '3' }}>
              <label className="form-label mb-0 " >Fecha Desde:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD} 
                onChange={date => setText_fechaD(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-4" style={{ zIndex: '3' }}>
              <label className="form-label mb-0 " >Fecha Hasta:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaH} 
                onChange={date => setText_fechaH(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div> */}
          </div>
          {/* <div className="row ">
            <div className="col-lg-4">
              <label className="form-label mb-0 " >Sucursal:</label>
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
            </div>
            <div className="col-lg-4" style={{ zIndex: '3' }}>
              <label className="form-label mb-0" >UniNegocio</label>
                <AsyncSelect
                  styles={styles}
                  placeholder="Busque por Cod/Desc"
                  className="sele_pru from-control form-control-sm my-swal"
                  value={slcProveedor}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code + ' - ' + e.Name}
                  getOptionValue={e => e.Code}
                  loadOptions={funSlcProvedor}
                  onChange={setSlcProveedor}
                />
            </div>
            <div className="col-lg-4" style={{ zIndex: '3' }}>
              <label className="form-label mb-0" >Supervisor</label>
                <AsyncSelect
                  styles={styles}
                  placeholder="Busque por Cod/Desc"
                  className="sele_pru from-control form-control-sm my-swal"
                  value={slcSupervuso}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code + ' - ' + e.Name}
                  getOptionValue={e => e.Code}
                  loadOptions={funListaSupervisores}
                  onChange={setSlcSupervuso}
                />
            </div>
          </div> */}
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
              </div>
            </Tooltip>
            <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}
export const Report5 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,            setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());
  const [slcProveedor,   setSlcProveedor] = useState([""]);
  const [slcSupervuso,   setSlcSupervuso] = useState([""]);
  const funSlcProvedor = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;
  }
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  const funListaSupervisores= async (inputValue) => {
    const url = `/apiListSupervisores?vcodEmpresa=${cook_idEmp}&vtxtConsult=${inputValue}`
    //const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;
  }
  /** Primer reporte */
  // ;;;;;;;;;;;;;;;
  const columns1  = useMemo( () => [
    { header: 'CodCliente',             accessorKey: 'CodigoCliente'},
    { header: 'NomCliente',             accessorKey: 'NombreCliente'},
    { header: 'IdDireccion',              accessorKey: 'IdDireccion'},
    { header: 'Direccion',              accessorKey: 'Direccion'},
    { header: 'Distrito',               accessorKey: 'Distrito'},
    { header: 'Provincia',              accessorKey: 'Provincia'},
    { header: 'Departamento',           accessorKey: 'Departamento'},
    { header: 'TipoDoc',                accessorKey: 'TipoDoc'},
    { header: 'DocIdentidad',           accessorKey: 'DocIdentidad'},
    { header: 'Giro',                   accessorKey: 'Giro'},
    { header: 'Ruta',                   accessorKey: 'Ruta'},
    { header: 'Vendedor',           accessorKey: 'Vendedor'},
    { header: 'DiaVisita',       accessorKey: 'CategoriaCliente'},
    { header: 'Condicion',         accessorKey: 'Condicion'},
    { header: 'Telefonos',            accessorKey: 'Telefonos'},
    { header: 'TipoCLT',           accessorKey: 'TipoCLT'},
    { header: 'Categoria',               accessorKey: 'Categoria'},
    { header: 'Latitud',                  accessorKey: 'Latitud'},
    { header: 'Longitud',              accessorKey: 'Longitud'},
    // { header: 'SucategoríaBO',              accessorKey: 'SucategoríaBO'},
    { header: 'FechaRegistro',              accessorKey: 'FechaRegistro'},

    // { header: 'TipoItem',               accessorKey: 'TipoItem'},
    // { header: 'Motivo_Anulacion',       accessorKey: 'Motivo_Anulacion'},
    // { header: 'IDLinea',                accessorKey: 'IDLinea'},
    // { header: 'Linea',                  accessorKey: 'Linea'},
    // { header: 'Marca',                  accessorKey: 'Marca'},
    // { header: 'Familia',                accessorKey: 'Familia'},
    // { header: 'SubFamilia',             accessorKey: 'SubFamilia'},
    // { header: 'CodProducto',            accessorKey: 'CodProducto'},
    // { header: 'Producto',               accessorKey: 'Producto'},
    // { header: 'Cantidad',               accessorKey: 'Cantidad'},
    // { header: 'Unidad',                 accessorKey: 'Unidad'},
    // { header: 'Factor',                 accessorKey: 'Factor'},
    // { header: 'CantidadBase',           accessorKey: 'CantidadBase'},
    // { header: 'Cantidad_Rechazo',       accessorKey: 'Cantidad_Rechazo'},
    // { header: 'Monto_Origen',           accessorKey: 'Monto_Origen'},
    // { header: 'MontoRechazo',           accessorKey: 'MontoRechazo'},
    // { header: 'Cod_Promocion',          accessorKey: 'Cod_Promocion'},
    // { header: 'Promocion',              accessorKey: 'Promocion'},
    // { header: 'ValorVentaBonif',        accessorKey: 'ValorVentaBonif'},
    // { header: 'CodReglaDscto',          accessorKey: 'CodReglaDscto'},
    // { header: 'DescuentoPorcentual',    accessorKey: 'DescuentoPorcentual'},
    // { header: 'ValorDescuento',         accessorKey: 'ValorDescuento'},
    // { header: 'VentaAfecta',            accessorKey: 'VentaAfecta'},
    // { header: 'ValorIGV',               accessorKey: 'ValorIGV'},
    // { header: 'VentaTotal',             accessorKey: 'VentaTotal'},
    // { header: 'ID_Agente',              accessorKey: 'ID_Agente'},
    // { header: 'CodVendedor',            accessorKey: 'CodVendedor'},
    // { header: 'Vendedor',               accessorKey: 'Vendedor'},
    // { header: 'IDSupervisor',           accessorKey: 'IDSupervisor'},
    // { header: 'CodZona',                accessorKey: 'CodZona'},
    // { header: 'Zona',                   accessorKey: 'Zona'},
    // { header: 'Cod_Concepto',           accessorKey: 'Cod_Concepto'},
    // { header: 'Concepto',               accessorKey: 'Concepto'},
    // { header: 'TipoDoc_Referencia',     accessorKey: 'TipoDoc_Referencia'},
    // { header: 'Doc_Referencia',         accessorKey: 'Doc_Referencia'},
    // { header: 'TipoDeCambio',           accessorKey: 'TipoDeCambio'},
    // { header: 'FechaGuia',              accessorKey: 'FechaGuia'},
    // { header: 'CostoReal',              accessorKey: 'CostoReal'},
    // { header: 'CostoPromedio',          accessorKey: 'CostoPromedio'},
    // { header: 'GrupoVenta',             accessorKey: 'GrupoVenta'},
    // { header: 'Mesa',                   accessorKey: 'Mesa'},
    // { header: 'Canal',                  accessorKey: 'Canal'},
    // { header: 'Proveedor',              accessorKey: 'Proveedor'},
    // { header: 'Categoria',              accessorKey: 'Categoria'},
    // { header: 'SubCategoria',           accessorKey: 'SubCategoria'}
  ],[])
  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiDistribucion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}&vfecD=${transFechaD}&vfecH=${transFechaH}&vUnidadNe=${slcProveedor.Code}&vSupervis=${slcSupervuso.Code}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    emp_setSl_t_suc([])
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "CARTERA DE CLIENTES_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "CARTERA DE CLIENTES__"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  
  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          {/* <div className="row ">
            <div className="col-lg-4" style={{ zIndex: '3' }}>
              <label className="form-label mb-0 " >Fecha Desde:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD} 
                onChange={date => setText_fechaD(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-4" style={{ zIndex: '3' }}>
              <label className="form-label mb-0 " >Fecha Hasta:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaH} 
                onChange={date => setText_fechaH(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-4">
              <label className="form-label mb-0 " >Sucursal:</label>
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
            </div>
            <div className="col-lg-4" style={{ zIndex: '3' }}>
              <label className="form-label mb-0" >UniNegocio</label>
                <AsyncSelect
                  styles={styles}
                  placeholder="Busque por Cod/Desc"
                  className="sele_pru from-control form-control-sm my-swal"
                  value={slcProveedor}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code + ' - ' + e.Name}
                  getOptionValue={e => e.Code}
                  loadOptions={funSlcProvedor}
                  onChange={setSlcProveedor}
                />
            </div>
            <div className="col-lg-4" style={{ zIndex: '3' }}>
              <label className="form-label mb-0" >Supervisor</label>
                <AsyncSelect
                  styles={styles}
                  placeholder="Busque por Cod/Desc"
                  className="sele_pru from-control form-control-sm my-swal"
                  value={slcSupervuso}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code + ' - ' + e.Name}
                  getOptionValue={e => e.Code}
                  loadOptions={funListaSupervisores}
                  onChange={setSlcSupervuso}
                />
            </div>
          </div> */}
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip>
            <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}
export const Report6 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,            setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [slcListPrec1,    setSlcListPrec1] = useState([""]);
  const [slcListPrec2,    setSlcListPrec2] = useState([""]);
  const [slcProveedor, setSlcProveedor] = useState([""]);
  const funSlcProvedor = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;
  }
  const funListaTipoPre = async (inputValue) => {
    const url = `/apiListarTipoPrecios?vcodEmpresa=${cook_idEmp}&vtxtConsult=${inputValue}`
    //const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;
  }

  /** Primer reporte */
  // ;;NOMB_VEND;Semana;LUNES;MARTES;MIERCOLES;JUEVES;VIERNES;SABADO;DOMINGO
  const columns1  = useMemo( () => [
    { header: 'CODIGOVISITA',    accessorKey: 'CODIGOVISITA'},
    { header: 'COD VENDEDOR',      accessorKey: 'COD_VEND'},
    { header: 'NOM VENDEDOR',     accessorKey: 'NOMB_VEND'},
    { header: 'SEMANA',           accessorKey: 'Semana'},
    { header: 'LUNES', accessorKey: 'LUNES'},
    { header: 'MARTES', accessorKey: 'MARTES'},
    { header: 'MIERCOLES',        accessorKey: 'MIERCOLES'},
    { header: 'JUEVES',          accessorKey: 'JUEVES'},
    { header: 'VIERNES',         accessorKey: 'VIERNES'},
    { header: 'SABADO',       accessorKey: 'SABADO'},
    { header: 'DOMINGO',         accessorKey: 'DOMINGO'},
    // { header: 'Desde',          accessorKey: 'Desde'},
    // { header: 'Hasta',          accessorKey: 'Hasta'},
    // { header: 'PrecioLista1',   accessorKey: 'PrecioLista1'},
    // { header: 'PrecioLista2',   accessorKey: 'PrecioLista2'},
    // { header: 'Lista1',         accessorKey: 'Lista1'},
    // { header: 'Lista2',         accessorKey: 'Lista2'}
  ],[])
  const onClickListar=async()=>{
  
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiDistribucion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}&lst1=${slcListPrec1.Name}&lst2=${slcListPrec2.Name}&vUnidadNe=${slcProveedor.Code}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    emp_setSl_t_suc([])
    setdataReport([])
    setPending(false)
    
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "DIAS DE VISIA_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "DIAS DE VISIA__"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
            {/* <div className="col-lg-3" style={{ zIndex: '3' }}>
              <label className="form-label mb-0 " >Lista precio 1:</label>
              <AsyncSelect
                  styles={styles}
                  placeholder="Busque por Cod/Desc"
                  className="sele_pru from-control form-control-sm my-swal"
                  value={slcListPrec1}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code + ' - ' + e.Name}
                  getOptionValue={e => e.Code}
                  loadOptions={funListaTipoPre}
                  onChange={setSlcListPrec1}
                />
            </div>
            <div className="col-lg-3">
              <label className="form-label mb-0 " >Lista precio 2:</label>
              <AsyncSelect
                  styles={styles}
                  placeholder="Busque por Cod/Desc"
                  className="sele_pru from-control form-control-sm my-swal"
                  value={slcListPrec2}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code + ' - ' + e.Name}
                  getOptionValue={e => e.Code}
                  loadOptions={funListaTipoPre}
                  onChange={setSlcListPrec2}
                />
            </div>
            <div className="col-lg-3" style={{ zIndex: '3' }}>
              <label className="form-label mb-0" >UniNegocio</label>
                <AsyncSelect
                  styles={styles}
                  placeholder="Busque por Cod/Desc"
                  className="sele_pru from-control form-control-sm my-swal"
                  value={slcProveedor}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code + ' - ' + e.Name}
                  getOptionValue={e => e.Code}
                  loadOptions={funSlcProvedor}
                  onChange={setSlcProveedor}
                />
            </div> */}
          </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
              </div>
            </Tooltip>
            <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}
export const Report7 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,            setdataReport] = useState([]);
  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());
  const [slcProveedor, setSlcProveedor] = useState([""]);
  const funSlcProvedor = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;
  }
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  // Categoria;ClienteCodigo;ClienteNombre;DireccionCodigo;Direccion;Departamento;Provincia;Distrito;ZonaR;ZonaA
  const columns1  = useMemo( () => [
    { header: 'Categoria',              accessorKey: 'Categoria'},
    { header: 'Nom Cliente',             accessorKey: 'ClienteNombre'},
    { header: 'Cod Cliente',             accessorKey: 'ClienteCodigo'},
    { header: 'IdDireccion',              accessorKey: 'IdDireccion'},
    { header: 'CodigoDireccion',        accessorKey: 'DireccionCodigo'},
    { header: 'Direccion',              accessorKey: 'Direccion'},
    { header: 'Departamento',           accessorKey: 'Departamento'},
    { header: 'Provincia',              accessorKey: 'Provincia'},
    { header: 'Distrito',               accessorKey: 'Distrito'},
    { header: 'ZonaA',                  accessorKey: 'ZonaA'},
    { header: 'ZonaR',                  accessorKey: 'ZonaR'},

    // { header: 'UnidadNegocio',    accessorKey: 'UnidadNegocio' },
    // { header: 'Sucursal',         accessorKey: 'Sucursal' },
    // { header: 'ProdCodigo',       accessorKey: 'ProdCodigo' },
    // { header: 'Producto',         accessorKey: 'Producto' },
    // { header: 'Categoria',        accessorKey: 'Categoria' },
    // { header: 'SubCategoria',     accessorKey: 'SubCategoria' },
    // { header: 'CanalVendedor',    accessorKey: 'CanalVendedor' },
    // { header: 'UnidadMax',        accessorKey: 'UnidadMax' },
    // { header: 'UnidadMin',        accessorKey: 'UnidadMin' },
    // { header: 'fecha',            accessorKey: 'fecha' },
    // { header: 'NumCp',            accessorKey: 'NumCp' },
    // { header: 'DocIdentidad',     accessorKey: 'DocIdentidad' },
    // { header: 'PersonaNombre',    accessorKey: 'PersonaNombre' },
    // { header: 'CantidadCaj',      accessorKey: 'CantidadCaj' },
    // { header: 'CantidadUni',      accessorKey: 'CantidadUni' },
    // { header: 'PrecioListaMax',   accessorKey: 'PrecioListaMax' },
    // { header: 'PrecioListaMin',   accessorKey: 'PrecioListaMin' },
    // { header: 'Porcen',           accessorKey: 'Porcen' },
    // { header: 'PrecioFinal',      accessorKey: 'PrecioFinal' },
    // { header: 'Dscto',            accessorKey: 'Dscto' },
    // { header: 'VendedorCodigo',   accessorKey: 'VendedorCodigo' },
    // { header: 'VendedorNombre',   accessorKey: 'VendedorNombre' },
    // { header: 'Regla',            accessorKey: 'Regla' },
    // { header: 'ReglaDescripcion', accessorKey: 'ReglaDescripcion' },
    // { header: 'Comentario',       accessorKey: 'Comentario' },
  ],[])
  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setdataReport([])
    setPending(true)
    const url_lstClientes = `/apiDistribucion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}&vfecD=${transFechaD}&vfecH=${transFechaH}&vUnidadNe=${slcProveedor.Code}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    emp_setSl_t_suc([])
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "PLANTILLA_CLIENTES_ZONAS"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "PLANTILLA_CLIENTES_ZONAS"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          {/* <div className="row ">
            <div className="col-lg-3" style={{ zIndex: '3' }}>
              <label className="form-label mb-0 " >Fecha Desde:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD} 
                onChange={date => setText_fechaD(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-3" style={{ zIndex: '3' }}>
              <label className="form-label mb-0 " >Fecha Hasta:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaH} 
                onChange={date => setText_fechaH(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-3">
              <label className="form-label mb-0 " >Sucursal:</label>
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
            </div>
            <div className="col-lg-3" style={{ zIndex: '3' }}>
              <label className="form-label mb-0" >UniNegocio</label>
                <AsyncSelect
                  styles={styles}
                  placeholder="Busque por Cod/Desc"
                  className="sele_pru from-control form-control-sm my-swal"
                  value={slcProveedor}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code + ' - ' + e.Name}
                  getOptionValue={e => e.Code}
                  loadOptions={funSlcProvedor}
                  onChange={setSlcProveedor}
                />
            </div>
          </div> */}
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
              </div>
            </Tooltip>
            <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns1}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}

// Este repote es de facturacion
export const Report8 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport,           setdataReport] = useState([]);
  const [pending,       setPending] = useState(false);
  // const [emp_sl_t_suc,  emp_setSl_t_suc] = useState('');
  const [text_fechaD,   setText_fechaD] = useState(new Date());
  const [text_fechaH,   setText_fechaH] = useState(new Date());
  const [slcVendedor,   setSlcVendedor] = useState([""]);
  const [slcCliente,    setSlcCliente] = useState([""]);
  const [slcArticulo,   setSlcArticulo] = useState([""]);
  const [slcProveedor,  setSlcProveedor] = useState([""]);
  const funSlcVendedor = async (inputValue) => {
    const url = '/apiVendedores?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }
  const funSlcCliente = async (inputValue) => {
    const url = '/apiClientes?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }
  const funDArticulos = useCallback( async (inputValue) => {
    const url ='/apiArticulos?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue;
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[]) 
  const funSlcProvedor = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;
  }
  // const handleSelectChangeV = (selectedOption) => { setSlcVendedor(selectedOption);  };
  // const handleSelectChangeC = (selectedOption) => { setSlcCliente(selectedOption);  };
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption);  };

  // const emp_changeSl_t_suc = (event) => { 
  //   emp_setSl_t_suc(event.target.value);
  //   $("#emp_slc_tem").focus();
  // }
  /** Primer reporte */
  const columns  = useMemo( () => [
    { header: 'ZONACODIGO',               accessorKey: 'ZONACODIGO'},
    { header: 'ZONADESCRIPCION',          accessorKey: 'ZONADESCRIPCION' },
    { header: 'VENDEDORCODIGO',           accessorKey: 'VENDEDORCODIGO' },
    { header: 'VENDEDORNOMBRE',           accessorKey: 'VENDEDORNOMBRE' },
    { header: 'CLIENTECODIGO',            accessorKey: 'CLIENTECODIGO' },
    { header: 'CLIENTENOMBRE',            accessorKey: 'CLIENTENOMBRE' },
    { header: 'CLIENTECATEGORIANOMBRE',   accessorKey: 'CLIENTECATEGORIANOMBRE' },
    { header: 'CLIENTETIPOCODIGO',        accessorKey: 'CLIENTETIPOCODIGO' },
    { header: 'CLIENTETIPONOMBRE',        accessorKey: 'CLIENTETIPONOMBRE' },
    { header: 'PRODUCTOCODIGO',           accessorKey: 'PRODUCTOCODIGO' },
    { header: 'PRODUCTODESCRIPCION',      accessorKey: 'PRODUCTODESCRIPCION' },
    { header: 'PRODUCTOUNDREF_FACTOR',    accessorKey: 'PRODUCTOUNDREF_FACTOR' },
    { header: 'PRODUCTOUNDREF_UNIDAD',    accessorKey: 'PRODUCTOUNDREF_UNIDAD' },
    { header: 'PRODUCTOFACTORCOMPRA',     accessorKey: 'PRODUCTOFACTORCOMPRA' },
    { header: 'PRODUCTOUNDCOMPRA_UNIDAD', accessorKey: 'PRODUCTOUNDCOMPRA_UNIDAD' },
    { header: 'PRODUCTOFACTORCAJABACKUS', accessorKey: 'PRODUCTOFACTORCAJABACKUS' },
    { header: 'PRODUCTOLINEAN2CODIGO',    accessorKey: 'PRODUCTOLINEAN2CODIGO' },
    { header: 'PRODUCTOLINEAN2NOMBRE',    accessorKey: 'PRODUCTOLINEAN2NOMBRE' },
    { header: 'PRODUCTOLINEAN1CODIGO',    accessorKey: 'PRODUCTOLINEAN1CODIGO' },
    { header: 'PRODUCTOLINEAN1NOMBRE',    accessorKey: 'PRODUCTOLINEAN1NOMBRE' },
    { header: 'PRODUCTOTIPOCODIGO',       accessorKey: 'PRODUCTOTIPOCODIGO' },
    { header: 'PRODUCTOTIPONOMBRE',       accessorKey: 'PRODUCTOTIPONOMBRE' },
    { header: 'PROVEEDORID',              accessorKey: 'PROVEEDORID' },
    { header: 'PROVEEDORCODIGO',          accessorKey: 'PROVEEDORCODIGO' },
    { header: 'PROVEEDORNOMBRE',          accessorKey: 'PROVEEDORNOMBRE' },
    { header: 'MARCADESCRIPCION',         accessorKey: 'MARCADESCRIPCION' },
    { header: 'FORMAPAGO',                accessorKey: 'FORMAPAGO' },
    { header: 'ITEMCANTIDADBASE',         accessorKey: 'ITEMCANTIDADBASE' },
    { header: 'ITEMBONIFICACION',         accessorKey: 'ITEMBONIFICACION' },
    { header: 'ITEMUNIDADVENTA',          accessorKey: 'ITEMUNIDADVENTA' },
    { header: 'ITEMCANTIDADCONTADO',      accessorKey: 'ITEMCANTIDADCONTADO' },
    { header: 'ITEMCANTIDADCREDITO',      accessorKey: 'ITEMCANTIDADCREDITO' },
    { header: 'ITEMCANTIDADBONIFICACION', accessorKey: 'ITEMCANTIDADBONIFICACION' },
    { header: 'ITEMMONTOCONTADO',         accessorKey: 'ITEMMONTOCONTADO' },
    { header: 'ITEMMONTOCREDITO',         accessorKey: 'ITEMMONTOCREDITO' },
    { header: 'PRODUCTOLINEAN3CODIGO',    accessorKey: 'PRODUCTOLINEAN3CODIGO' },
    { header: 'PRODUCTOLINEAN3NOMBRE',    accessorKey: 'PRODUCTOLINEAN3NOMBRE' }

  ],[])
  const onClickListar=async()=>{
    setdataReport([])
    setPending(true)
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    let arrvVend = slcVendedor.map(option => option.Code)
    let arrvClie = slcCliente.map(option => option.Code)
    let arrvProd = slcArticulo.map(option => option.Code)
    let arrvProv = slcProveedor.map(option => option.Code)
    // console.log("arrvProv", arrvProv);


    const url_lstClientes = `/apiDistribucion?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vfecD=${transFechaD}&vfecH=${transFechaH}&arrvVend=${arrvVend}&arrvClie=${arrvClie}&arrvProd=${arrvProd}&arrvProv=${arrvProv}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    console.log(url_lstClientes);
    
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
    setSlcVendedor([])
    setSlcCliente([])
    setSlcArticulo([])
    setSlcProveedor([])
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "RESUMEN DE VENTAS POR CLIENTE_  "+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "RESUMEN DE VENTAS POR CLIENTE_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
            <div className="col-lg-3" style={{ zIndex: '5' }}>
              <label className="form-label mb-0 " >Fecha Desde:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD} 
                onChange={date => setText_fechaD(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-3" style={{ zIndex: '5' }}>
              <label className="form-label mb-0 " >Fecha Hasta:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaH} 
                onChange={date => setText_fechaH(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-6" style={{ zIndex: '4' }}>
              <label className="form-label mb-0" >Vendedor:</label>
              <AsyncSelect 
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm  "  
                value={slcVendedor}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code +' - '+ e.Name}
                getOptionValue={e => e.Code }
                loadOptions={funSlcVendedor}
                //onChange={setSlcVendedor}
                //maxMenuHeight={150}
                onChange={(e)=>{
                  setSlcVendedor(e)
                  setTimeout(() =>{
                  $('.css-wsp0cs-MultiValueGeneric').each(function() {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split(' -')[0].trim();
                      $(this).text(textoCorto);
                  });
                  },1000)
                }}
              />
            </div>
            <div className="col-lg-6" style={{ zIndex: '4' }}>
              <label className="form-label mb-0" >Cliente:</label>
              <AsyncSelect 
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm  "  
                value={slcCliente}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code +' - '+ e.Name}
                getOptionValue={e => e.Code }
                loadOptions={funSlcCliente}
                //onChange={setSlcCliente}
                //maxMenuHeight={150}
                onChange={(e)=>{
                  setSlcCliente(e)
                  setTimeout(() =>{
                  $('.css-wsp0cs-MultiValueGeneric').each(function() {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split('-')[0].trim();
                      $(this).text(textoCorto);
                  });
                  },1000)
                }}
              />
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-6" style={{ zIndex: '3' }}>
              <label className="form-label mb-0" >Producto:</label>
              <AsyncSelect 
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm "  
                value={slcArticulo}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code +' - '+ e.Name}
                getOptionValue={e => e.Code }
                loadOptions={funDArticulos}
                //onChange={handleSelectChange}
                onChange={(e)=>{
                  handleSelectChange(e)
                  setTimeout(() =>{
                  $('.css-wsp0cs-MultiValueGeneric').each(function() {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split('-')[0].trim();
                      $(this).text(textoCorto);
                  });
                  },1000)
                }}
              />
            </div>
            <div className="col-lg-6" style={{ zIndex: '3' }}>
              <label className="form-label mb-0" >Proveedor</label>
              <AsyncSelect
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm "
                value={slcProveedor}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code +' - '+ e.Name}
                getOptionValue={e => e.Code}
                loadOptions={funSlcProvedor}
                onChange={(e)=>{
                  setSlcProveedor(e)
                  setTimeout(() =>{
                  $('.css-wsp0cs-MultiValueGeneric').each(function() {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split('-')[0].trim();
                      $(this).text(textoCorto);
                  });
                  },1000)
                }}
                
              />
            </div>
          </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>&nbsp;
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip> 
            <AButton tp_tilde="Limpiar y consultar de nuevo"  tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}


const ReportDistribu = () => {
  //const cookies = new Cookies();
  //const cook_idEmp = cookies.get('dtEmp_id');
  const [slTipoReport,        setSlTipoReport] = useState('');

  const changeSlTipoReport = (event) => { 
    setSlTipoReport(event.target.value);
  }

  return (
    <div>
        <h6 className="text-muted"> Reporteria Distribucion</h6>
        <div className="card card-action">
            <div className="card-body ">
              <div className="row mb-2">
                <div className="col-lg-10">
                  <div className="row ">
                    <div className="col-lg-2"><label className="form-label mb-0 " >Tipo Reporte:</label></div>
                    <div className="col-lg-10">
                      <select id="slc_t_con" className="form-select form-select-sm" value={slTipoReport} onChange={changeSlTipoReport} >
                        <option value={"9999"} >--Seleccione--</option>
                        <option value={"1"} > DIO001 : RUTAS </option>
                        <option value={"2"} > DIO002 : ZONAS </option>
                        <option value={"3"} > DIO003 : REPORTE DE VENDEDORES </option>
                        <option value={"4"} > DIO004 : REPORTE DE MESAS </option>
                        <option value={"5"} > DIO005 : CARTERA DE CLIENTES </option>
                        <option value={"6"} > DIO006 : DIAS DE VISITA </option>
                        <option value={"7"} > DIO007 : PLANTILLA - CLIENTES ZONAS </option>
                        <option value={"8"} > DIO008 : RESUMEN DE VENTAS POR CLIENTE </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {
                (slTipoReport === '1'  ) && (
                  <Report1 
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '2' ) && (
                  <Report2 
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '3' ) && (
                  <Report3 
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '4' ) && (
                  <Report4 
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '5' ) && (
                  <Report5 
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '6' ) && (
                  <Report6
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '7' ) && (
                  <Report7
                    dataTipo={slTipoReport}
                  />
                )
              }
              {
                (slTipoReport === '8' ) && (
                  <Report8
                    dataTipo={slTipoReport}
                  />
                )
              }



              
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}

export default ReportDistribu

