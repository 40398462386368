
import * as React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom"
import { registerLocale } from "react-datepicker";
import { useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import './App.css';

import es from 'date-fns/locale/es';
//import { useIsAuthenticated } from "@azure/msal-react";
import Cookies from 'universal-cookie';
/** Vistas publicas - blancas */
import Login from "./views/Login"
import Dashboard from "./views/Dashboard";
// import Home from "./views/Home";

import Contenido from "./views/Contenido";
import Trabajadores from "./views/Mantenimiento/Trabajadores";
import TablasGenerales from "./views/Mantenimiento/TablasGenerales";
import Usuarios from "./views/Mantenimiento/Usuarios";
import Error404 from "./views/error/error_404";
import Error503 from "./views/error/error_503";

/** REPARTO */
import REPMantenimiento from './views/Reparto/REPMantenimiento';
import REPPreConsolidadoDeCarga from './views/Reparto/REPPreConsolidadoDeCarga';
import REPVisitasRepartidor from './views/Reparto/REPVisitasRepartidor';
//import REPVisitasVendedor from './views/Reparto/REPVisitasVendedor';
import REPAsigLotes from './views/Reparto/REPAsigLotes';

/** BONIFICACION */
import BONIndex from './views/Bonificaciones/SPBonif/BONIndex';
import BONConBoni from './views/Bonificaciones/SPBonif/BONConBoni';
import BONDescuentos from './views/Bonificaciones/SPDescu/DESIndex';
import DESConDesc from './views/Bonificaciones/SPDescu/DESConDesc';
import BONConIpar from './views/Bonificaciones/BONConIpar';

/** GESTION DE PRECIOS */
import GPActualizacionPrecios from './views/GestionPrecios/GPActualizacionPrecios';
import GPUnidadVenta from './views/GestionPrecios/GPUnidadVenta';
import GPListaUnidadVenta from './views/GestionPrecios/GPListaUnidadVenta';


import GPDistribucion from './views/Distribucion/GPDistribucion';
import GPClientes from './views/Distribucion/GPClientes';
import COMMesa from './views/Comercial/COMMesa';
import COMGrupoventas from './views/Comercial/COMGrupoventas';
import COMVendedor from './views/Comercial/COMVendedor';
import COMVisitavendedor from './views/Comercial/COMVisitavendedor';
import COMZonas from './views/Comercial/COMZonas';
import GPEmpleados from './views/Distribucion/GPEmpleados';
// import COMIMaestros from './views/Comisiones/COMIMaestros';


/** Comisiones */
import COMICarga from './views/Comisiones/COMICarga';
import COMICalculo from './views/Comisiones/COMICalculo';
import COMIReporteria from './views/Comisiones/COMIReporteria';
import COMTipoTabla from './views/Comercial/COMTipotabla';
import COMSede from './views/Comercial/COMSede';
import COMLineaBase from './views/Comercial/COMLineaBase';
import COMTipoMesa from './views/Comercial/COMTipomesa';
// import COMTipoMesa from './views/Comercial/COMTipomesa';


/** Reporterria */
import ReportFact from './views/Reportes/ReportFact';
import ReportCostos from './views/Reportes/ReportCostos';
import ReportDistribu from './views/Reportes/ReportDistribu';
import ReportReparto from './views/Reportes/ReportReparto'; 
import ReportFinanzasCreditos from './views/Reportes/ReportFinazasCreditos'; 
import Reporliquidacion from './views/Reportes/ReportLiquidacion';
import ReporComercial from './views/Reportes/ReportComercial';
import ReportAuditoria from './views/Reportes/ReportAuditoria';

/** Liquidacion */
import LIQPlanCobranzas from './views/Liquidacion/LIQPlanCobranzas';
import LIQNotasCredito from './views/Liquidacion/LIQNotasCredito';
import RLIQTransportista from './views/Liquidacion/RLIQTransportista';

/** Facturación */
import FACTPedidos from './views/Facturacion/FACTPedidos';

/*** Almacen  */
import AlmacenReportes from  './views/Almacen/ReportesAlmacen';
import COMSupervisores from './views/Comercial/COMSupervisores';

/** ========= PUBLICO ========== */
/** ========= APPMOBIL ========== */
import AppPedidos from './views/Public/APP/AppPedidos';

const globalTheme = window.templateCustomizer.settings.style;
registerLocale('es', es)

function App() {


  const cookies = new Cookies();
  let status = cookies.get("CookieSession")
  var ultimaPagina = cookies.get("ultimapag")
  //var sinDashboard = cookies.get("repview")


  /** datos a validar url */
  //const location = window.location;
  //const location = useLocation();
  // const [sinDashboard, setSinDashboard] = React.useState(false);
  // React.useEffect(() => {
  //   const searchParams = new URLSearchParams(window.location.search);;
  //   const repview = searchParams.get('repview');
  //   console.log(repview);
  //   setSinDashboard(repview);
  // }, []);
  // const searchParams = new URLSearchParams(location.search);
  // console.log("searchParams", searchParams);
  // var sinDashboard = searchParams.get('repview');
  // console.log("sinDashboard", sinDashboard);

  //var sinDashboard = 0;
  //status === 'False' || !status ?

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [],
  );
  /* sinDashboard === '1' ?
                <Route>
                  <Route path="/repviewal" element={<RepViewAl />} ></Route>
                </Route>
                :  */

  return (
    <ThemeProvider theme={tableTheme}>
      <Router>
        <Routes>
          {
            status === 'False' || !status ?
              //<Route path="/" element={<Login />} >
              <Route path="/" >
                <Route index element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/appPedidos" element={<AppPedidos />} />
                <Route path="*" element={<Login />} />
              </Route>
              :
                
                <Route element={<Dashboard />}>
                  <Route path="/contenido" exec={true} element={<Contenido />} />
                  {/* <Route path="/home"               element={<Home />} />  */}

                  <Route path="/trabajadores" element={<Trabajadores />} />
                  <Route path="/tablasgenerales" element={<TablasGenerales />} />
                  <Route path="/usuarios" element={<Usuarios />} />

                  <Route path="/repmantenimiento" element={<REPMantenimiento />} />
                  <Route path="/reppreconsolidadodecarga" element={<REPPreConsolidadoDeCarga />} />
                  <Route path="/repvisitasrepartidor" element={<REPVisitasRepartidor />} />
                  {/* <Route path="/repvisitasvendedor" element={<REPVisitasVendedor />} /> */}
                  <Route path="/repgordenesventas" element={<REPAsigLotes />} />

                  {/* <Route path="/bonificaciones"   element={<MGRBonificacion />} />   */}
                  <Route path="/bonificaciones" element={<BONIndex />} />
                  <Route path="/bonconboni" element={<BONConBoni />} />
                  <Route path="/bondescuentos" element={<BONDescuentos />} />
                  <Route path="/boncondesc" element={<DESConDesc />} />
                  <Route path="/bonconipar" element={<BONConIpar />} />

                  <Route path="/gpactualizacionprecios" element={<GPActualizacionPrecios />} />
                  <Route path="/gpunidadventa" element={<GPUnidadVenta />} />
                  <Route path="/gplistaunidadventa" element={<GPListaUnidadVenta />} />
                  

                  <Route path="/dcomercial" element={<GPDistribucion />} />
                  <Route path="/distclientes" element={<GPClientes />} />
                  <Route path="/disempleados" element={<GPEmpleados />} />
                  <Route path="/commesa" element={<COMMesa />} />
                  <Route path="/comgrupoventas" element={<COMGrupoventas />} />
                  <Route path="/comvendedor" element={<COMVendedor />} />
                  <Route path="/comvisitavendedor" element={<COMVisitavendedor />} />
                  <Route path="/comzonas" element={<COMZonas />} />
                  <Route path="/comsupervisores" element={<COMSupervisores />} />

                  {/* <Route path="/comimaestros" element={<COMIMaestros />} /> */}
                  <Route path="/comicarga" element={<COMICarga />} />
                  <Route path="/comicalculo" element={<COMICalculo />} />
                  <Route path="/comireporteria" element={<COMIReporteria />} />
                  <Route path="/comtipotabla" element={<COMTipoTabla />} />
                  <Route path="/comsede" element={<COMSede />} />
                  <Route path="/comlineabase" element={<COMLineaBase />} />
                  <Route path="/comtipomesa" element={<COMTipoMesa />} />

                  {/* Reportes  */}
                  <Route path="/reporfact" element={<ReportFact />} />
                  <Route path="/reporcostos" element={<ReportCostos />} />
                  <Route path="/repordistri" element={<ReportDistribu />} />
                  <Route path="/reporrepart" element={<ReportReparto />} />
                  <Route path="/reporfinanzascreditos" element={<ReportFinanzasCreditos />} />
                  <Route path="/reporliquidacion" element={<Reporliquidacion />} />
                  <Route path="/reporcomercial" element={<ReporComercial />} />
                  <Route path="/reporauditoria" element={<ReportAuditoria />} />
                  
                  
                  {/* Liquidaciones  */}
                  <Route path="/liqpcobranzas" element={<LIQPlanCobranzas />} />
                  <Route path="/liqnotascredito" element={<LIQNotasCredito />} />
                  <Route path="/rliqTransportista" element={<RLIQTransportista />} />

                  {/** Facturación */}
                  <Route path="/factPedidos" element={<FACTPedidos />} />
                  
                  
                  {/* Almacen  */} 
                  <Route path="/reporalmacen" element={<AlmacenReportes />} />

                  <Route path="/*" element={ultimaPagina !== "" ? <Navigate to={"/" + ultimaPagina} /> : <Error503 />} />
                </Route>
                
                
                

          }
          <Route path="*" element={<Error404 />} ></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  )
}
export default App