/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
//, useEffect , Box
//notify_warning
import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {apiInsertOrder, apiDisminuirStock} from './funApis'
import Cookies from 'universal-cookie';
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';

import { ToastContainer } from 'react-toastify';
import { notify_valido, notify_warning, notify_error } from '../../components/utils';
import { Tooltip, Box, Modal, createTheme, ThemeProvider  }from '@mui/material';
//import { SelectTipo, SelectSucursal } from '../../components/Selects/SelectsPrincipales';
import { SelectSucursal, SelectTipo } from '../../components/Selects/SelectsPrincipales';//
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MaterialReactTable } from 'material-react-table';
import { AButton } from '../../components/Button';
import { consultaBonificacionYDescuentos } from './funBonificaciones'

import "react-datepicker/dist/react-datepicker.css";
import 'react-data-table-component-extensions/dist/index.css';
import '../../App.css';

// import PdfEJEM from "../../components/pdf/PdfEjemplo";
// import { PDFDownloadLink } from "@react-pdf/renderer"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
//const moment = require('moment')
const moment = require('moment-timezone')
const globalTheme = window.templateCustomizer.settings.style;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
const styles = {

    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? null : null,
      backgroundColor: globalTheme === 'light'
        ? '#FEFFF4'
        : '#2B2C40',
      color: globalTheme === 'light'
        ? '#2B2C40'
        : '#FEFFF4',
      minHeight: `29px`,
      height: `29px`,
    }),
  
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
        : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
      color: state.isSelected
        ? '#FF3E1D'
        : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
      '&:hover': {
        backgroundColor: '#FF3E1D',
        color: 'white',
      },
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
      color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: `29px`,
      padding: `0 6px`,
      color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    }),
    input: (provided, state) => ({
      ...provided,
      color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
      fontSize: `0.75rem`,
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: `29px`,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    }),
  };
  
const formatDateYYYY = (date) => {
// Formatear la fecha a yyyy-MM-dd
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
const FACTPedidos = () => {
    const cookies = new Cookies();
    const cook_idEmp = cookies.get('dtEmp_id');
    const dtUser = cookies.get('dtUser');
    // const dtgrve = dtUser.ls_gr_ven
    // const txt_IdSuperv = dtUser.idsupervisor

    /** Obtenere ruta y almacenar */
    function savePathInCookie() {
        const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal
        // Guarda el valor en una cookie con nombre "ultimapag"
        document.cookie = `ultimapag=${path}`;
    }
    useEffect(() => { savePathInCookie(); }, []);
    
    /** Datos principales */
    const [users, setUsers] = useState( [] )
    const [pending, setPending] = useState(false);
    const [sorting, setSorting] = useState([]);
    const [inp_text_nuncompr, setinp_text_nuncompr] = useState('');
    const [inp_text_fecdesde, setInp_text_fecdesde] = useState(new Date());
    const [inp_text_fechasta, setInp_text_fechasta] = useState(new Date());
    const [slc_text_tipocons, setSlc_text_tipocons] = useState('');
    const changeinp_text_nuncompr = (event) => { setinp_text_nuncompr(event.target.value); }
    const changeSlc_text_tipocons = (event) => { setSlc_text_tipocons(event.target.value); }
    /** Filtros inputs */
    const [sl_t_suc, setsl_t_suc] = useState('');
    const changeSl_t_suc = (event) => { setsl_t_suc(event.target.value); }
    /** Mesas */
    const [sl_t_mesas, setsl_mesas] = useState([]);
    const changeSetsl_mesas = (selectedOption) => { setsl_mesas(selectedOption); }
    const funDArticulos = useCallback( async (inputValue) => {
        const url ='/apiMesa?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const response = await fetch(url,opPe);
        const data = await response.json();
        return data;
    },[cook_idEmp])
     /** Grupo Venta */
    const [sl_t_grupoventa, setsl_t_grupoventa] = useState([]);
    const changeSl_t_grupoventa = (selectedOption) => { setsl_t_grupoventa(selectedOption); }
    const funGrupoVenta = useCallback( async (inputValue) => {
        const url ='/apiGrupoVenta?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue; // cambiar la api ahora esta con api de MEsa
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const response = await fetch(url,opPe);
        const data = await response.json();
        return data;
    },[cook_idEmp])
    const [sl_t_ven, setsl_t_ven] = useState([]);
    /** clientes  */
    const [sl_t_cli, setsl_t_cli] = useState([]);
    const changeSetsl_t_cli = (selectedOption) => { setsl_t_cli(selectedOption); }
    
    const funClientes = useCallback( async (inputValue) => {
        const url ='/apiClientes?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const response = await fetch(url,opPe);
        const data = await response.json();
        return data;
    },[cook_idEmp])
    /** vendedor  */
    
    const changeSetsl_t_ven = (selectedOption) => { setsl_t_ven(selectedOption); }
    const funVendedores = useCallback( async (inputValue) => {
        // console.log("dtUser.sl_dt_suc", dtUser.sl_dt_suc);
        var arSucursal;
        //const url ='/apiVendedores?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue;
        if (dtUser.sl_dt_suc.length === 2) {
            arSucursal = dtUser.sl_dt_suc[1].Code
            console.log("arSucursal => ", arSucursal);
        }else{
            const resArrSucur = dtUser.sl_dt_suc
            .filter(item => item.Code !== "9999")
            .map(item => `'${item.Code}'`)
            .join(',');
            arSucursal = resArrSucur;
        }



        const url ='/apiCodeVenSucursal?vcodEmpresa='+cook_idEmp+'&varrSucursal='+arSucursal+'&vtxtConsult='+inputValue;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const response = await fetch(url,opPe);
        const data = await response.json();
        return data;
    },[cook_idEmp,  dtUser.sl_dt_suc])
    const funDtVendedores = async (code) => {
        //const url ='/apiVendedores?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue;
        const url ='/apiCodeVendedores?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+code;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const response = await fetch(url,opPe);
        const data = await response.json();
        return data;
    }
    
    

    /** Listar ORRDENES */
    const columns = useMemo(() => [
        { header: 'N° Preconsolidado',  accessorKey: 'Numero_Preconsolidado',   },
        { header: 'N° Consolidado',     accessorKey: 'Numero_Consolidado',  },
        { header: 'N° Unico',           accessorKey: 'Numero_Unico',    },
        { header: 'N° Documento',       accessorKey: 'Numero_Documento',    },
        { header: 'FechaEmision',       accessorKey: 'Fecha_Emision',   },
        { header: 'HoraEmision',        accessorKey: 'Hora_Emision', },
        { header: 'Cliente',            accessorKey: 'Cliente', },
        { header: 'Vendedor',           accessorKey: 'Vendedor',    },
        { header: 'Total Documento',    accessorKey: 'Total_Documento', },
        { header: 'Credito',            accessorKey: 'Credito', },
        { header: 'Estado Facturacion', accessorKey: 'Estado_Facturacion',  },
        { header: 'Estado Documento',   accessorKey: 'Estado_Documento',    },
        { header: 'Ruta Reparto',       accessorKey: 'Ruta_Reparto',    },
        { header: 'Zona',               accessorKey: 'Zona',    },
        { header: 'Vehiculo',           accessorKey: 'Vehiculo',    },
        { header: 'Sucursal',           accessorKey: 'Sucursal',    },
        { header: 'Comentario',         accessorKey: 'Comentario',  },
    ], [users]);
    const url0 = '/apiListarOrders';
    let body_t = { 
        vcodEmpresa: cook_idEmp,
        vcodSucursal: sl_t_suc,
        vcodTipoCons: slc_text_tipocons,
        // vlFechadesde: moment(inp_text_fecdesde,'Y-M-D').format('YYYY-MM-DD HH:mm:ss'),
        // vlFechahasta: moment(inp_text_fechasta,'Y-M-D').format('YYYY-MM-DD HH:mm:ss'),
        vlFechadesde: formatDateYYYY(inp_text_fecdesde),
        vlFechahasta: formatDateYYYY(inp_text_fechasta),
        vlNroDocumen: inp_text_nuncompr,
        vlarrGrupoVe: sl_t_grupoventa.Code,
        vlarrMesa: sl_t_mesas.Code,
        vlarrCliente: sl_t_cli.Code,
        vlarrVendedor: sl_t_ven.Code
    };
    const opti_t = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body_t) }
    const onClickProce = async () => {

        if (slc_text_tipocons === "" || slc_text_tipocons === '9999') {
            notify_warning("Tipo de consulta es obligatorio")
        }else{
          setPending(true)
        
          const showData = async () => {
              const response = await fetch(url0,opti_t);
              const data = await response.json();
              //const tableData = { columns, data, };
              setUsers(data)
              document.getElementById('btn_procesar').disabled = "";
              document.getElementById('btn_procesar_icon').setAttribute('class', 'bx bx-cloud-download bx-ms');
              document.getElementById('btn_procesar_spinner').className = 'spinner-border d-none';
              setPending(false)
          }
          showData()
        }
    }
    const onClickExcel = async () => {
        const fileType = "xlsx"
        const nameFileType = slc_text_tipocons
        const showData = async () => {
        const response = await fetch(url0,opti_t);
        const datadtos = await response.json();

        if (datadtos !== '0' ) {
            const ws = XLSX.utils.json_to_sheet(datadtos);
            const wb = {Sheets:{data:ws}, SheetNames:["data"]};
            const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
            const data = new Blob([excelBuffer], {type:fileType});
            FileSaver.saveAs(data, `${nameFileType}.xlsx`);
        }else{
            notify_warning("No existen datos, verificar campos")
        }
        
        }
        showData();
    }
    const onClickReset = () => {
        setinp_text_nuncompr("")
        setInp_text_fecdesde(new Date())
        setInp_text_fechasta(new Date())
        setsl_t_cli([])
        setsl_mesas([])
        setsl_t_grupoventa([])
        setsl_t_ven([])
    }
    /** Nuevo Ordern */
    const [disabledG, setDisabledG] = useState(false)
    const [disabledGD, setDisabledGD] = useState(true)
    const [disabledSlcCliente, setDisabledSlcCliente] = useState(true)
    const [disabledSlcVendedo, setDisabledSlcVendedo] = useState(true)
    const selectRef = useRef(null);
    const hacerFocus = () => {
        if (selectRef.current) {
            selectRef.current.focus();
            setTimeout(() => {
                selectRef.current.onMenuOpen();
            }, 200);
        }
    }
    const onClickNew = async() => {
        handleOpenModal(); 
        setDisabledG(true)
        setDisabledSlcCliente(true)
        setDisabledSlcVendedo(false)
        notify_warning("Ingrese al vendedor")
        setTimeout(() => {
            hacerFocus()
        }, 600);
        //focusAsyncSelect()
        await fcListaGrupoArticulo()
        await fcListaDatosImpuestos()
        await fcListaCondicionPago()
        await fcListaCanal()
        //await fcListaUnidadMedida()


    }
    const onClickView = async(row) => {
        setDisabledG(true)
        setDisabledSlcCliente(true)
        handleOpenModal()

        const url ='/apiConsulDocumento?vcodEmpresa='+cook_idEmp+'&vtxtCodigo='+row.Numero_Unico;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const rspd = await fetch(url,opPe);
        const data = await rspd.json();
        await arrDatosDetalles(data)
        
    }
    
    //useCallback (
    //,[])
    const onClickCancelarOrden = async(row) =>{
        try {
          //console.log("onClickCancelarOrden");
          //console.log("row.original", row.Numero_Unico);
          const ordenCancelar = row.Numero_Unico;
          Swal.fire({
            title: 'Cancelar Orden N°: '+ordenCancelar,
            text: '¿Desea confirmar?',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: "SI",
            customClass: {
              container: 'my-swal'
            }
          }).then(async (result) => {
            if (result.isConfirmed) {
              const url = '/apiCancelOrder'
              const body = { vcodEmpresa: cook_idEmp, vcodSucursal: sl_t_suc, vcodOrder:ordenCancelar };
              const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
              const response = await fetch(url, opPe);
              const resdatos = await response.json();
              console.log("onClickCancelarOrden resdatos", resdatos);
              const lstNew = users.filter((item) => item.Numero_Unico !== ordenCancelar)
              setUsers(lstNew)
    
            }
          })
        } catch (error) {
          notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
        }
      }

    const onTeste = async() => {
        console.log("modalListDatos", modalListDatos);

    }
































    /** ======================================================== MODAL PRIMARIO ARMAR DATOS ======================================================== */

    const [disabledSave, setDisabledSave] = useState(false);
    const [openModal, setopenModal] = useState(false);
    const handleOpenModal = () => { 
        setopenModal(true); 
    };
    const handleCloseModal = () => { 
        setopenModal(false); 
        onClickClose()
    };
    /** Datos modal requeridos  */

    const arrDatosPrincipalesModalNecesarios = {
        txtSerie    : '',
        txtCodDocumento    : '',
        txtCodCliente      : [],
        //fileEscDirEntre    : 'DIRECCION ENTREGA 1', //Almacenanos el cod escogido de la direccion de entrega
        fileEscDirEntre    : '', //Almacenanos el cod escogido de la direccion de entrega
        fileEscCondPago    : '-1', //Almacenanos el cod escogido de la direccion de entrega
        arrfileEscDirEntre : [], //Almacenamos la fila con todos los datos requeridos por el usuario
        arrfileEscVendedor : null, //Almacenamos datos del vendor seleccionado
        arrfileEscCondPago : [], //Almacenamos datos del vendor seleccionado
        arrfileFlete       : null,
        txtFechaC          : new Date(),
        txtFechaE          : new Date(),
        txtFechaD          : new Date(),
        txtCodAlmacen      : '',
        txtcanal           : '',
        txtlprecio         : '',
        arrDatosDetall     : [],
        txtCodVendedor     : [],/**ddw1 */
        txtObservacion     : '',/**ddw1 */
        txtTotalAntDes     : '0.00', /** ddw2 */
        txtDescuento       : '0.00', /** ddw2 */
        txtGastosAdici     : '0.00', /** ddw2 */
        txtRedondeo        : '0.00', /** ddw2 */
        txtInpuesto        : '0.00', /** ddw2 */
        txtPTotal          : '0.00', /** ddw2 */
        txtTotalIgv        : '0.00', /** ddw2 */
        txtRecargo         : '0.00', /** ddw2 */
        arrGrupoArticulo   : [],
        arrCanal   : [],
        arrImpuestos       : [],
        arrUnidadMedida    : [],
        arrClientePuntos    : [],
        arrCondicionPago    : []
    }

    
    const [modalListDatos, setModalListDatos] = useState(arrDatosPrincipalesModalNecesarios);
    const handleFieldAsign = (fieldName,value) => {
        setModalListDatos((prev)  => ({
            ...prev,
            [fieldName]: value || null,
        }));
    };
    const handleFieldAsignList = (value) => {
        setModalListDatos((prev)  => ({
            ...prev,
            value,
        }));
    };
    const handleFieldAsignNew = (fieldName, value) => {
        setModalListDatos(prev => {
            const newState = { ...prev, [fieldName]: value || null };
            return newState;
        });
    };
    const handleFieldChange = (fieldName) => (event) => {
        const value = event.target.value;
        // setModalListDatos({
        //     ...modalListDatos,
        //     [fieldName]: value,
        // });
        setModalListDatos((prev) => ({
            ...prev,
            [fieldName]: value,
          }));

        if (fieldName === 'fileEscDirEntre') {
            //console.log("cod fileEscDirEntre", value);
            const itemSelecionado =  modalListDatos?.arrClientePuntos[0]?.Direcciones.filter((item) => item.Codigo === value)
            //console.log("itemSelecionado", itemSelecionado);
            handleFieldAsign('arrfileEscDirEntre',itemSelecionado)
            
        }
    };
    
    const handleFieldChangeArr = (fieldName) => (newValue) => {
        // setModalListDatos({
        //     ...modalListDatos,
        //     [fieldName]: newValue,
        // });
        setModalListDatos((prev) => ({
            ...prev,
            [fieldName]: newValue,
          }));
        fcExtraerDatos(fieldName, newValue)
    };
    const handleDateChange = (fieldName) => (date) => {
        setModalListDatos({
            ...modalListDatos,
            [fieldName]:moment(date).tz("America/Lima").format('YYYY-MM-DD'), // Actualiza el valor de la fecha seleccionada
        });
        
    };
    //** Funciones para extraer datos  */
    const fcExtraerDatos = async(tipiDatoExtraer, DatosLlegada) =>{
        //console.log(tipiDatoExtraer);
        if(tipiDatoExtraer === 'txtCodVendedor'){
           //return await fcDatosVendedor(DatosLlegada)
           const rspDatos = await fcDatosVendedor(DatosLlegada)
        //    console.log("rspDatosee", rspDatos);
        //    let resrre = [rspDatos]
        //    console.log("rspDatos", resrre);
           handleFieldAsign('arrfileEscVendedor', rspDatos)
            if (rspDatos) {
                //console.log("ingresa arrfileEscVendedor");
                setDisabledSlcCliente(false)
                handleFieldAsign('txtSerie', rspDatos.ModoRecOrden)
            }


        }
        if(tipiDatoExtraer === 'txtCodCliente'){
            return await fcDatosClientePuntos(DatosLlegada.Code)
        }
    }
    const funClientesXVendedor = useCallback( async (inputValue) => {
        //if (modalListDatos.txtCodVendedor.length) {
            // console.log("ingresa funClientesXVendedor", modalListDatos?.txtCodVendedor);
            const url ='/apiClientesPedidos?vcodEmpresa='+cook_idEmp+'&vtxtCodVen='+modalListDatos?.txtCodVendedor.Code+'&vtxtConsult='+inputValue;
            const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
            const response = await fetch(url,opPe);
            const data = await response.json();
            return data;
        //}
        
    },[cook_idEmp, modalListDatos?.txtCodVendedor])
    
    

    //** Extraer Datos */
    const fcDatosVendedor = async(DatosLlegada) => {
        const url ='/apiOrdObtenerDatReq?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+DatosLlegada.Code;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        return data
    }
    const fcDatosClientePuntos = async(DatosLlegada) => {
        const codVendedor = modalListDatos?.txtCodVendedor.Code
        const url ='/apiOrdObtenerDatosClientePuntos?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+codVendedor+'&vtxtCodeCli='+DatosLlegada;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        //console.log("fcDatosClientePuntos", data);
        if(data.length===0) 
            notify_warning("No se han encontrado direcciones para este vendedor")        //setTimeout(() => {
        handleFieldAsign('arrClientePuntos', data)
        setDisabledSlcCliente(false)
        setDisabledG(false)
        //}, 1000);

        //return data;
    }

    const fcDtDatosClientePuntos = useCallback( async(codCliente, codVendedor) => {
        const url ='/apiOrdObtenerDatosClientePuntos?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+codVendedor+'&vtxtCodeCli='+codCliente;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        return data;
        //return data;
    },[cook_idEmp])
    const fcListaGrupoArticulo = async() => {
        const url ='/apiOrdObtenerListaGrupoArticulo?vcodEmpresa='+cook_idEmp;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        //console.log("fcListaGrupoArticulo", data);
        handleFieldAsign('arrGrupoArticulo', data)
        //return data;
    }
    const fcListaDatosImpuestos = async() => {
        const url ='/apiOrdObtenerListaImpuestos?vcodEmpresa='+cook_idEmp;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        //console.log("fcListaDatosImpuestos", data);
        handleFieldAsign('arrImpuestos', data)
        return data;
    }
    const fcListaCondicionPago = async() => {
        const url ='/apiListarCondicionPago?vcodEmpresa='+cook_idEmp;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        //console.log("fcListaCondicionPago", data);
        handleFieldAsign('arrCondicionPago', data)
        //return data;
    }
    const fcListaCanal = async() => {
        const url ='/apiCanalPe?vcodEmpresa='+cook_idEmp;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        //console.log("fcListaCanal", data);
        handleFieldAsign('arrCanal', data)
        //return data;
    }
    /** Direccion de entrega */
    const funDirEntrega = useCallback( async () => {
        const arrData = [];
        if (modalListDatos?.arrClientePuntos.length) {
            modalListDatos?.arrClientePuntos[0]?.Direcciones?.map((key,index)=>{
                return arrData.push({
                     "Code": index +' - '+ key.Codigo,
                     "Name": key.Calle
                 })
                 
             })
        }
        return arrData
        
    },[modalListDatos?.arrClientePuntos])
    // const fcListaUnidadMedida = async() => {
    //     const url ='/apiOrdObtenerListaUnidadMedida?vcodEmpresa='+cook_idEmp;
    //     const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    //     const resp = await fetch(url,opPe);
    //     const data = await resp.json();
    //     console.log("fcListaUnidadMedida", data);
    //     handleFieldAsign('arrUnidadMedida', data)
    //     //return data;
    // }

    const totalAntesDescuento = useCallback(() => {
        if (modalListDatos?.arrDatosDetall.length) {
            //console.log("entra ");
            let tt = 0;
            let ttA = 0;
            let ttB = 0;
            modalListDatos?.arrDatosDetall.forEach((key) =>{
                //console.log("key.esBonificacion", key.esBonificacion);
                if (!key.esBonificacion ) {
                    tt += Number(key.txtTotal)
                    ttA += Number(key.txtTotalSinDesc)
                    ttB += Number(key.txtTotalSolDesc)
                }
               
            } )
            //if (ttB>0) {
            if (ttA>0) {
                // console.log("totalAntesDescuento=>1");
                // console.log("ttA");
                handleFieldAsign("txtTotalAntDes",  Number(ttA).toFixed(3))
                handleFieldAsign("txtDescuento",  Number(ttB).toFixed(3))

                let ttp = Number(tt) * 1.18;
                let tti = Number(ttp) - Number(tt);
                handleFieldAsign("txtInpuesto", tti.toFixed(3))
                handleFieldAsign("txtPTotal", tt.toFixed(3))
                handleFieldAsign("txtTotalIgv", ttp.toFixed(3))
            }else{
                //console.log("totalAntesDescuento=>2");
                handleFieldAsign("txtTotalAntDes", tt)
                let ttp = Number(tt) * 1.18;
                let tti = Number(ttp) - Number(tt);
                handleFieldAsign("txtInpuesto", tti.toFixed(3))
                handleFieldAsign("txtPTotal", tt.toFixed(3))
                handleFieldAsign("txtTotalIgv", ttp.toFixed(3))
            }
            
        }
    },[modalListDatos?.arrDatosDetall])

    useEffect(() => {
            funDirEntrega()
            totalAntesDescuento()
    }, [totalAntesDescuento, funDirEntrega]);





    const onClickClose = () => {
        setModalListDatos(arrDatosPrincipalesModalNecesarios)
        setDisabledG(false)
    }
    //const [valiInse, setValiInse] = useState(false)
    

    const onClickEliminarFile = (rowFileIndex) => {
        //alert("hola")
         Swal.fire({
        title: 'ADVERTENCIA',
        text: '¿Desea eliminar fila?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        customClass: { container: 'my-swal' }
        }).then(async (result) => {
            if (result.isConfirmed) {
                const myList = [...modalListDatos?.arrDatosDetall];
                console.log("entra myList "+myList);
                const data = modalListDatos?.arrDatosDetall.filter( (_, i) => i !== rowFileIndex);
                //myList.splice(data, 1);
                handleFieldAsign('arrDatosDetall', data)
                notify_valido("¡Se proceso correctamente.!")
                setDisabledGD(true)
                Swal.close()
            }
        })
        
    }

    const fuConsulStock = useCallback(async(codigo, almacen)  =>{
        const url ='/apiConsulStock?vcodEmpresa='+cook_idEmp+'&vtxtCodigo='+codigo+'&vtxtAlamcen='+almacen; 
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const response = await fetch(url,opPe);
        const data = await response.json();
        return data;
    },[cook_idEmp])

    // const apiDisminuirStock = async(grupo) => {
    //     grupo['idempresa'] = cook_idEmp;
    //     const url ='/apiDesminuirStock'; // cambiar la api ahora esta con api de MEsa
    //     const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(grupo) }
    //     const response = await fetch(url,opPe);
    //     const data = await response.json();
    //     console.log("DisminuirStock => ", response);
    //     return data;
    // }


    /** Inicio = Validaciones del modal1 */
    // useEffect(() => {
    //     if (modalListDatos.arrClientePuntos.length >=1) {
    //         //console.log("ingresa arrClientePuntos");
    //         // setDisabledSlcCliente(false)
    //         // setDisabledG(false)
    //     }
        
    // }, [modalListDatos.arrClientePuntos]);
    /** Fin = Validaciones del modal1 */
































    
    /** ======================================================== MODAL SECUNDARIO PRODUCTOS ======================================================== */
    /** Para abri modal de articulos */
    const [disabledBoton, setDisabledBoton] = useState(true);
    const [validarCantid, setValidarCantid] = useState(false);
    //const [disabledSecun, setDisabledSecun] = useState(false);
    const [openModalProd, setopenModalProd] = useState(false);
    const handleOpenModapProd = () => { 
        setopenModalProd(true); 
        setDisabledBoton(true)
    };
    const handleCloseModapProd = () => { setopenModalProd(false); };
    
    
    const arrDatosSecundariosModal = {
        txtCodArticulo    : [], //siva solo Code
        txtCodAlmacen     : '', //siva
        txtStock          : '0.00',
        txtPrecioUnitario : '0.00',
        txtTotal          : '0.00', //siva
        txtTotalSinDesc   : '0.00', 
        txtTotalSolDesc   : '0.00', 
        txtfullTotal      : '0.00', 
        txtUnidadMedida   : '', //siva
        arrUMSelecionad   : [],
        txtCantidad       : 1, //siva
        bonificaciones      : [],
        descuentos          : [],
        arrArticulo         : [], // siva CodigoImpuesto
        arrStock            : [],
        arrPrecioArticulo   : [],
        arrGrupoUnidadMedida: [],
        arrListaPrecios     : [],
        arrfileEscDPrecio   : [], // si va Nombre
        esBonificacion      : false,
        boniRelacionada     : false,
        esDescuento         : false,
        descuentoRelacionado: false,
        DiscountPercent     : 0,
        noStock       : false
    }
    const [modal2ListDatos, setModa2lListDatos] = useState(arrDatosSecundariosModal);

    
    /** Articulos */
    const funArticulos = useCallback( async (inputValue) => {
        const url ='/apiArticulosPedidos?vcodEmpresa='+cook_idEmp+'&vtxtCodVen='+modalListDatos?.txtCodVendedor.Code+'&vtxtConsult='+inputValue; // cambiar la api ahora esta con api de MEsa
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const response = await fetch(url,opPe);
        const data = await response.json();
        return data;
    },[cook_idEmp, modalListDatos?.txtCodVendedor])
    const handle2FieldAsign = (fieldName,value) => {
        
        
        setModa2lListDatos((prev)  => ({
            ...prev,
            [fieldName]: value,
        }));
    };
    const handle2FieldChange = (fieldName) => (event) => {
        const value = event.target.value;
        // setModa2lListDatos({
        //     ...modal2ListDatos,
        //     [fieldName]: value,
        // });
        setModa2lListDatos((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
        
        if (fieldName === 'txtUnidadMedida') {
            
            //console.log("Entraaaaaaaaaaa cod de unidad de medida", value);
            const itemSelecionado = modal2ListDatos.arrGrupoUnidadMedida.filter((item) => item.Codigo === value)
            //console.log("itemSelecionado", itemSelecionado);
            handle2FieldAsign('arrUMSelecionad',itemSelecionado)
            handle2FieldAsign('txtCantidad',1)

        }
        if (fieldName === 'txtCantidad') {
            let newValue = Number(value);
            //console.log("validar estado de btton ", disabledBoton);
            
            if (!disabledBoton) {
                if (newValue <= 0) {
                    setModa2lListDatos({
                        ...modal2ListDatos,
                        [fieldName]: 1,
                    });
                }
            }else{
                
                    //console.log("entra al trueeeeeeeeee");
                    //console.log("newValue", newValue);
                    const ultCan =  modal2ListDatos.txtCantidad
                    if (newValue>=modal2ListDatos.txtCantidad) {
                        setModa2lListDatos({
                            ...modal2ListDatos,
                            [fieldName]: ultCan,
                        });
                    }else{
                        setModa2lListDatos({
                            ...modal2ListDatos,
                            [fieldName]: ultCan-1,
                        });
                    }
                
            }
            
            


        }
    };


    
    const handle2FieldChangeArr = (fieldName) => (newValue) => {
       
        // setModa2lListDatos({
        //     ...modal2ListDatos,
        //     [fieldName]: newValue,
        // });

        setModa2lListDatos((prev) => ({
            ...prev,
            [fieldName]: newValue,
        }));
        
        fcExtraer2Datos(fieldName, newValue)
        
    };

    const fcExtraer2Datos = async(fieldName, DatosLlegada) =>{
        // console.log("entra a fieldName", fieldName);
        // console.log("entra a fcExtrae2rDatos", DatosLlegada);
        var vtxtCodeVendedor =modalListDatos?.txtCodVendedor.Code
        //console.log("vtxtCodeVendedor", vtxtCodeVendedor);
        if (fieldName==='txtCodArticulo') {
            // const url ='/apiOrdObtenerDatosAGrupoUnidadMedida?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+vtxtCodeVendedor+'&vtxtCodeArt='+DatosLlegada.Code;
            // const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
            // const resp = await fetch(url,opPe);
            // const data = await resp.json();
            // console.log("txtCodArticulo", data[0]?.Detalles);
            // // setTimeout(() => {
            //     handle2FieldAsign('arrUnidadMedida', data[0]?.Detalles)
            // // }, 1000);

            fcDatosRequeridosArticulo(vtxtCodeVendedor, DatosLlegada.Code)
            
        }
    }

    const fcDatosRequeridosArticulo = async(vtxtCodeVendedor,DatosLlegada) => {
        const r1 =  await fcDatosArticulo(vtxtCodeVendedor, DatosLlegada)
        const r2 =  await fcDatosStock(vtxtCodeVendedor, DatosLlegada)
        const r3 =  await fcDatosPrecioArticulo(vtxtCodeVendedor, DatosLlegada)
        const r4 =  await fcDatosGrupoUnidadMedida(vtxtCodeVendedor, DatosLlegada)
        const r5 =  await fcDatosListaPrecios(vtxtCodeVendedor, DatosLlegada)

        return await Promise.all([r1,r2,r3,r4,r5])

    }

    /** ==== inicio :> funciones para  Obteniendo datos ==== */
    const fcDatosArticulo = async(vtxtCodeVendedor, DatosLlegada) => {
        const url ='/apiOrdObtenerDatosArticulo?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+vtxtCodeVendedor+'&vtxtCodeArt='+DatosLlegada;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        if (data.length) {
            //console.log("entra");
            //setDisabledBoton(false)
            //console.log("fcDatosArticulo", data);
            handle2FieldAsign('arrArticulo', data)
        }else{
            notify_warning("Articulo no trae datos")
            //setDisabledBoton(true)
            setTimeout(() => {
                setModa2lListDatos(arrDatosSecundariosModal)
            }, 2000);
        }
        

        return data;
    }
    const fcDatosStock = async(vtxtCodeVendedor, DatosLlegada) => {
        const url ='/apiOrdObtenerStock?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+vtxtCodeVendedor+'&vtxtCodeArt='+DatosLlegada;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        //console.log("fcDatosStock", data);
        handle2FieldAsign('arrStock', data)
        //return data;
    }
    const fcDDatosStock = async(vtxtCodeVendedor, DatosLlegada) => {
        const url ='/apiOrdObtenerStock?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+vtxtCodeVendedor+'&vtxtCodeArt='+DatosLlegada;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        //console.log("fcDDatosStock", data);
        //handle2FieldAsign('arrStock', data)
        return data;
    }
    const fcDatosPrecioArticulo = async(vtxtCodeVendedor, DatosLlegada) => {
        const url ='/apiOrdObtenerPrecioArticulo?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+vtxtCodeVendedor+'&vtxtCodeArt='+DatosLlegada;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        //console.log("fcDatosPrecioArticulo", data);
        handle2FieldAsign('arrPrecioArticulo', data)
        return data;
    }
    const fcDatosGrupoUnidadMedida = async(vtxtCodeVendedor, DatosLlegada) => {
        const url ='/apiOrdObtenerDatosAGrupoUnidadMedida?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+vtxtCodeVendedor+'&vtxtCodeArt='+DatosLlegada;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        //console.log("fcDatosGrupoUnidadMedida", data);
        handle2FieldAsign('arrGrupoUnidadMedida', data[0]?.Detalles)
        //return data;
    }
    const fcDDatosGrupoUnidadMedida = async(vtxtCodeVendedor, DatosLlegada) => {
        const url ='/apiOrdObtenerDatosAGrupoUnidadMedida?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+vtxtCodeVendedor+'&vtxtCodeArt='+DatosLlegada;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        // console.log("fcDatosGrupoUnidadMedida", data);
        // handle2FieldAsign('arrGrupoUnidadMedida', data[0]?.Detalles)
        return data[0]?.Detalles;
    }
    const fcDatosListaPrecios = async(vtxtCodeVendedor, DatosLlegada) => {
        const url ='/apiOrdObtenerDatosListaPrecios?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+vtxtCodeVendedor+'&vtxtCodeArt='+DatosLlegada;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const resp = await fetch(url,opPe);
        const data = await resp.json();
        //console.log("fcDatosListaPrecios", data);
        handle2FieldAsign('arrListaPrecios', data)

        return data;
    }
    //** ==== fin :> funciones para  Obteniendo datos ==== *//
  
    /** Inicio = Validaciones del modal2 */
    useEffect(() => {
        //console.log("Number(modal2ListDatos.txtPrecioUnitario)", Number(modal2ListDatos.txtPrecioUnitario));
        if (Number(modal2ListDatos.txtPrecioUnitario)>0) {
            setDisabledBoton(false)
        }else{
            setDisabledBoton(true)
        }
        
        
    }, [modal2ListDatos.txtPrecioUnitario]);
    /** Fin = Validaciones del modal2 */
    const asigDatosDepenArticulo = useCallback(() => {
        //console.log("asigDatosDepenArticulo",asigDatosDepenArticulo);
        //if (arrGUM.length) {
        if (modal2ListDatos.arrUMSelecionad.length) {
            //console.log("entra asigDatosDepenArticulo");
            
            const codArticulo = modal2ListDatos.txtCodArticulo.Code;
            const obtArrRe = modal2ListDatos.arrStock.filter((item) => item.Articulo === codArticulo)
            //console.log("obtArrRe 11", obtArrRe);
            if (obtArrRe.length) {
                //console.log("ingresa al stock");
                //console.log("obtArrRe", obtArrRe);
                /** Stock */
                const obtStock = obtArrRe[0]?.Stock
                // console.log("obtStock => ", obtStock);
            
                /** Alamcen */
                const obtAlmac = obtArrRe[0]?.Almacen
                //console.log("obtAlmac", obtAlmac);
                handle2FieldAsign('txtCodAlmacen', obtAlmac ) 

                /** Consultar inpuesto */
                const codInpuesto =  modal2ListDatos.arrArticulo[0]?.CodigoImpuesto;
                //console.log("codInpuesto", codInpuesto);
                const obtTasaInpuesto = modalListDatos?.arrImpuestos.filter((item) => item.Codigo === codInpuesto)
                //console.log("obtTasaInpuesto", obtTasaInpuesto);

                const veTasa = obtTasaInpuesto[0]?.Tasa;
                /** Lista precio */
                const codListaprecio =  modalListDatos?.arrClientePuntos[0]?.ListaPrecio;
                //console.log("codListaprecio", codListaprecio);
                //console.log("modal2ListDatos.arrPrecioArticulo", modal2ListDatos.arrPrecioArticulo);
                const obtFilaListaPrecio  = modal2ListDatos.arrListaPrecios.filter((item) => Number(item.Codigo) === codListaprecio)
                handle2FieldAsign('arrfileEscDPrecio',obtFilaListaPrecio)
                const obtPrecioVenta  = modal2ListDatos.arrPrecioArticulo.filter((item) => Number(item.ListaPrecio) === codListaprecio)
                //console.log("obtPrecioVenta", obtPrecioVenta);
                const vePrecioVenta = obtPrecioVenta[0]?.PrecioVenta;
                /** Base  */
                const obtBase = modal2ListDatos.arrUMSelecionad[0]?.Base
                //console.log("obtBase", obtBase);
                const newObtStock = (Number(obtStock) / obtBase).toFixed(3)
                handle2FieldAsign('txtStock', newObtStock ) 

                /** Calcular Precio */
                const precio = Number(vePrecioVenta)
                const tasa = Number(veTasa)
                const base = Number(obtBase)

               

                //-const igv = (Number(precio) * Number(tasa)) / 100;
                //console.log("igv", igv);

                //-const valor = igv + precio;
                //console.log("valor", valor);
                //const total = Number(valor * base).toFixed(3)
                const total = Number(precio * base).toFixed(3)

                
                //console.log("total", total);
                //console.log("parseInt(total)", Number(total));
                handle2FieldAsign('txtPrecioUnitario', total ) 
                /** Calcular Total */
                const vCantidad =  modal2ListDatos.txtCantidad;
                //console.log("vCantidad",vCantidad);

                const obtTotal = (Number(vCantidad) * Number(total)).toFixed(3)
                //console.log("obtTotal",obtTotal);
                handle2FieldAsign('txtTotal', obtTotal )
                

                // const obtTotalSinIGV = Number(precio * base).toFixed(3)
                // console.log("txtTotalSIGV",obtTotalSinIGV);
                // handle2FieldAsign('txtTotalSIGV', obtTotalSinIGV )

            }//else{
            //    setModa2lListDatos(arrDatosSecundariosModal) 
            //}
        }
    },
    [
        //arrDatosSecundariosModal, 
        modal2ListDatos.arrArticulo, 
        modal2ListDatos.arrListaPrecios, 
        modal2ListDatos.arrPrecioArticulo, 
        modal2ListDatos.arrStock, 
        modal2ListDatos.arrUMSelecionad, 
        modal2ListDatos.txtCantidad, 
        modal2ListDatos.txtCodArticulo.Code, 
        modalListDatos?.arrClientePuntos, 
        modalListDatos?.arrImpuestos
    ])

    /** ====== Inicio :> Forma de utilizar el estado, verifica el cambiio de un campo */
    const sumarCantidaTotal = useCallback(() => {
        if (modal2ListDatos.txtCantidad >= 2) {
            //console.log("entra sumarCantidaTotal ");
            const vCantidad = modal2ListDatos.txtCantidad;
            const vPrecioUn = modal2ListDatos.txtPrecioUnitario;
            const obtTotal = (Number(vCantidad) * Number(vPrecioUn)).toFixed(3)
            handle2FieldAsign('txtTotal', obtTotal ) 
        }

    },[modal2ListDatos.txtCantidad,modal2ListDatos.txtPrecioUnitario ])
        //},[]) 
    useEffect(() => {
        asigDatosDepenArticulo()
    }, [asigDatosDepenArticulo]);
    useEffect(() => {
        sumarCantidaTotal()
    }, [sumarCantidaTotal]);
    /** ====== Fin    :> Forma de utilizar el estado, verifica el cambiio de un campo */
   

  
    const onClikCloseFile = () => {
        handleCloseModapProd()
        setModa2lListDatos(arrDatosSecundariosModal)
    }
    const onClikAddFile = () => {
        //console.log("modal2ListDatos.txtUnidadMedida", modal2ListDatos.txtUnidadMedida);
        if (modal2ListDatos.txtUnidadMedida!=="") {
            
            setDisabledBoton(true)
            
            //setDisabledBoton(false)
            //console.log("lastItem", lastItem);
            //console.log("newId", newId);
            //handle2FieldAsign('item',newId)
            //armarDatosGrupo()
            //arrGrupoUnidadMedida,
            const filtrarNoNecesarios2 = ({ 
                arrStock,
                ...modal2ListDatos 
            }) => modal2ListDatos
            const varrDatosLl2 = filtrarNoNecesarios2(modal2ListDatos);
            /** Autincremento */
            // const lastItem = modalListDatos?.arrDatosDetall[modalListDatos?.arrDatosDetall.length - 1]
            // const newId = (lastItem===undefined || lastItem==="" ) ? 1 : lastItem.item + 1;
            // varrDatosLl2['item'] = newId;
            //console.log("varrDatosLl2", varrDatosLl2);
            //handleFieldChangeArrDatos('arrDatosDetall', [...modalListDatos?.arrDatosDetall,varrDatosLl2])
            handleFieldAsign('arrDatosDetall', [...modalListDatos?.arrDatosDetall,varrDatosLl2])
            
            /** Limpiar datos */
            setModa2lListDatos(arrDatosSecundariosModal)
         }else{ notify_warning("Seleccione UNIDAD DE MEDIDA") }
        
        
    }
     

    /** Bonificaciones - analizar datos requeridos */
    /** validar la cantidad por la base */
    const valCantidadSeleccionada =  useCallback(() => {

        const obtBase = modal2ListDatos.arrUMSelecionad[0]?.Base
        const obtCant = modal2ListDatos.txtCantidad
        const obtStock = modal2ListDatos.txtStock
        // console.log("base Seleccionada w", obtBase);
        if (Number(modal2ListDatos.txtPrecioUnitario)>0) {
            if (obtBase) {
                const rspStock = Number(obtStock)*Number(obtBase)//3000
                const rspCantid = Number(obtCant)*Number(obtBase)
                // console.log("obtCant", obtCant);
                // console.log("obtBase", obtBase);
                // console.log("obtStock", obtStock);
                //const rspAsigna = Number(obtStock)Number(obtBase)
                if (rspStock>=rspCantid) {
                    //console.log("se puede asignar");
                    setValidarCantid(true)
                    setDisabledBoton(false)
                }else{
                    //console.log("La cantidad supera al stock");
                    notify_warning("La cantidad supera al stock")
                    setDisabledBoton(true)
                }
            }
            //setDisabledBoton(false)
        }else{
            setDisabledBoton(true)
        }


    },[modal2ListDatos.arrUMSelecionad, modal2ListDatos.txtCantidad, modal2ListDatos.txtStock, modal2ListDatos.txtPrecioUnitario])
    
    const armarDatosGrupo = useCallback(() => {
        const arrBonif = [];
        const arrDescu = []
        const grupos = ['1', '2', '3', '4', '5'];
        
        if (modalListDatos?.arrClientePuntos.length===1) {
            //console.log("=================entra=============");
            //console.log("Existen datos clientes  y su grupoSocio es",modalListDatos?.arrClientePuntos[0]["GrupoSocio"]);
            if (modal2ListDatos.arrArticulo.length === 1) {
                //console.log("se selecciono un articulo valido ");
                if (modalListDatos?.arrClientePuntos[0]["GrupoSocio"] === 110) {
                    for (const grupo of grupos) {
                    const grupoCob = modal2ListDatos.arrArticulo[0][`Grupo${grupo}Cob`];
                    const grupoDesCob = modal2ListDatos.arrArticulo[0][`Des${grupo}Cob`];
                    const bon = {};
                    bon["grupo"] = grupo;
                    bon["codigo"] = grupoCob;
                    const des = {};
                    des["grupo"] = grupo;
                    des["codigo"] = grupoDesCob;
                    arrBonif.push(bon);
                    arrDescu.push(des);
                    }
                }
                if (modalListDatos?.arrClientePuntos[0]["GrupoSocio"] === 107) {
                    for (const grupo of grupos) {
                        const grupoCob = modal2ListDatos.arrArticulo[0][`Grupo${grupo}Mer`];
                        const grupoDesCob = modal2ListDatos.arrArticulo[0][`Des${grupo}Mer`];
                        const bon = {};
                        bon["grupo"] = grupo;
                        bon["codigo"] = grupoCob;
                        const des = {};
                        des["grupo"] = grupo;
                        des["codigo"] = grupoDesCob;
                        arrBonif.push(bon);
                        arrDescu.push(des);
                    }
                }
                if (modalListDatos?.arrClientePuntos[0]["GrupoSocio"] === 100) {
                    for (const grupo of grupos) {
                        const grupoCob = modal2ListDatos.arrArticulo[0][`Grupo${grupo}May`];
                        const grupoDesCob = modal2ListDatos.arrArticulo[0][`Des${grupo}May`];
                        const bon = {};
                        bon["grupo"] = grupo;
                        bon["codigo"] = grupoCob;
                        const des = {};
                        des["grupo"] = grupo;
                        des["codigo"] = grupoDesCob;
                        arrBonif.push(bon);
                        arrDescu.push(des);
                    }
                }
                //console.log("arrBonif",arrBonif);
                //console.log("arrDescu",arrDescu);
                 
                handle2FieldAsign('bonificaciones', arrBonif)
                handle2FieldAsign('descuentos', arrDescu)
            }
        }
    },[modal2ListDatos.arrArticulo, modalListDatos?.arrClientePuntos])
    useEffect(() => {
        armarDatosGrupo()
        valCantidadSeleccionada()
    }, [armarDatosGrupo, valCantidadSeleccionada]);

    

























    /** ======================================================== MODAL TERCERO - ULIMOS 3 PEDIOS ANTERIORES ======================================================== */
    const [openModal3UltP, setopenModal3UltP] = useState(false);
    const handleOpenModap3UltP = () => { setopenModal3UltP(true); };
    const handleCloseModap3UltP = () => { setopenModal3UltP(false); };


    const [lstModal3UltP, setlstModal3UltP] = useState([]);

    const columnsUltimosPedidos = useMemo(() => [
        { header: 'Documento', accessorKey: 'Documento' },
        { header: 'Fecha', accessorKey: 'Fecha' },
        { header: 'Total', accessorKey: 'Total' },
        //{ header: 'Series', accessorKey: 'Series' },
        { header: 'Nombre', accessorKey: 'Nombre' },
        { header: 'TaxFecha', accessorKey: 'TaxFecha' },
    ], []);

    const onClick3UltP = () =>{
        handleOpenModap3UltP()
        if (modalListDatos?.arrClientePuntos.length) {
            setlstModal3UltP(modalListDatos?.arrClientePuntos[0]?.Documentos)
        }
    }

























    //const [entraBonif, setEntraBonig] = useState(false)
    /** Extars Bonificaciones y descuentos */
    const onClickMoldalPrinAplicarRegalo_v = async() => {
        console.log("modalListDatos?.arrClientePuntos", modalListDatos?.arrClientePuntos);
        console.log("modalListDatos?.arrfileEscVendedor", modalListDatos?.arrfileEscVendedor);
        console.log("modalListDatos?.arrfileEscVendedor.Canal", modalListDatos?.arrfileEscVendedor.Canal);
        console.log("modalListDatos?.arrCanal", modalListDatos?.arrCanal);
        
        var resZona     =   modalListDatos?.arrClientePuntos[0]?.Direcciones.filter(
                        dir => dir.Codigo.includes(modalListDatos?.fileEscDirEntre)
                        )[0].Zona;
        var resGrupo    =   modalListDatos?.arrClientePuntos[0]?.GrupoSocio;
        var rspCanal    =   modalListDatos?.arrCanal.filter(
                            item => item.Codigo === resGrupo
                            );
        var canal   = rspCanal[0].Codigo
        var categoria   = rspCanal[0].Descripcion
        console.log("resZona", resZona);
        console.log("resGrupo", resGrupo);
        console.log("canal", canal);
        console.log("canal2", canal);
        console.log("categoria", categoria);
    }
    const [valiArrDatosDetall, setValiArrDatosDetall] = useState(false)
    const onClickMoldalPrinAplicarRegalo = async(validarDesde) => {
        Swal.fire({
            title: 'Loading...',
            html: 'Se estan consultado bonificaciones.',
            timerProgressBar: true,
            didOpen: () => { Swal.showLoading() },
            customClass: { container: 'my-swal' },
            allowOutsideClick: false
        })
        //arrDatosSecundariosModal
        // console.log("modalListDatos", modalListDatos);


        const dtUsuario = modalListDatos?.arrfileEscVendedor;
        dtUsuario['idempresa'] = cook_idEmp;
        dtUsuario['idvendedor'] = dtUsuario['Codigo'];
        dtUsuario['Canal'] = modalListDatos?.arrfileEscVendedor.Canal;
        const filteredData = modalListDatos?.arrDatosDetall.filter(item => item.esBonificacion === false);
        
        
        // const filteredData = modalListDatos?.arrDatosDetall.filter(item => item.esBonificacion === false && item.arrArticulo[0].Campania === 'N');
        // console.log("filteredData", filteredData);
        // /** SOLO CAMPAÑA */
        // const filteredData2 = modalListDatos?.arrDatosDetall.filter(item => item.esBonificacion === false && item.arrArticulo[0].Campania === 'Y');
        // console.log("filteredData2", filteredData2);
        filteredData.map((key) => {
            key['ItemCode'] = key.txtCodArticulo.Code;
            key['total'] = key.txtTotal;
            key['U_AB_DMCB'] = '';
            key['U_AB_OVOB'] = '';
            key['cantidad'] = key.txtCantidad;
            key['tipoprecio'] = key.arrfileEscDPrecio[0].Nombre;
            key['esBonificacion'] = false;
            key['boniRelacionada'] = false;
            key['esDescuento'] = false;
            key['descuentoRelacionado'] = false;
            key['DiscountPercent'] = 0;
            key['tipoBonificacion'] = '';
            key['GrupoBonificacion'] = '';
            key['consultGrupoBonificacion'] = '';
            key['ListaDescuento'] = [];
            key['descuentosINString'] = '';
            key['consultGrupoDescuento'] = false;
            key['aplicaGrupoDescuento'] = '';

            key['UnidadNegocio'] = key.arrArticulo[0].UnidNeg;
            key['UnitPrice'] = key.txtPrecioUnitario;
            key['Quantity'] = key.txtCantidad;
            key['txtTotalSinDesc'] = '0.00';
            key['txtTotalSolDesc'] = '0.00';


        })
        //console.log("filteredData==>", filteredData);
        // console.log("productosEnv modalListDatos?.arrDatosDetall => ", modalListDatos?.arrDatosDetall);
        // console.log("productosEnv filteredData => ", filteredData);
        var resZona     =   modalListDatos?.arrClientePuntos[0]?.Direcciones.filter(
                                dir => dir.Codigo.includes(modalListDatos?.fileEscDirEntre)
                            )[0].Zona;
        var resGrupo    =   modalListDatos?.arrClientePuntos[0]?.GrupoSocio;
        var rspCanal    =   modalListDatos?.arrCanal.filter(
                                item => item.Codigo === resGrupo
                            );
        //var canal   = rspCanal[0].Descripcion
        var categoria   = rspCanal[0].Codigo;
        // console.log("resZona", resZona);
        // console.log("resGrupo", resGrupo);
        // console.log("rspCanal", rspCanal);
        // console.log("categoria", categoria);
        

        // console.log("filteredData",filteredData);
        // console.log("dtUsuario",dtUsuario);
        // console.log("modalListDatos?.arrClientePuntos[0].Codigo",modalListDatos?.arrClientePuntos[0].Codigo);
        // console.log("modalListDatos?.fileEscCondPago,",modalListDatos?.fileEscCondPago,);
        // console.log("categoria",categoria);
        // console.log("resZona",resZona);
        
        const { productos, TicketMinimo, totalFletes } =  await consultaBonificacionYDescuentos(
          filteredData,
          dtUsuario,
          modalListDatos?.arrClientePuntos[0].Codigo,
          modalListDatos?.fileEscCondPago,
          categoria,
          resZona
        )
        if(TicketMinimo !==''){
            notify_warning(TicketMinimo)
            setDisabledGD(true)

        }else{
            setDisabledGD(false)
        }
        // console.log("productos", productos);
        // console.log("TicketMinimo", TicketMinimo);
        // console.log("totalFletes", totalFletes);
        handleFieldAsign('arrfileFlete', totalFletes)
        handleFieldAsign('txtRecargo', Number(totalFletes.conIgv).toFixed(2))
        handleFieldAsignNew('arrDatosDetall', [...productos])
        if (validarDesde === 'save') {
            setValiArrDatosDetall(true)
        }
        
        Swal.close()
        
        
    }
    

    useEffect(() => {
        Swal.close()
        setValiArrDatosDetall(false)
      
        const handleValidarInsert = async(varrDatosLl) => { 
            // moment(date).format('YYYY-MM-DD HH:mm:ss');
            varrDatosLl['txtFechaC'] = moment(varrDatosLl['txtFechaC']).tz("America/Lima").format('YYYY-MM-DD'); 
            varrDatosLl['txtFechaE'] = moment(varrDatosLl['txtFechaE']).tz("America/Lima").format('YYYY-MM-DD'); 
            varrDatosLl['txtFechaD'] = moment(varrDatosLl['txtFechaD']).tz("America/Lima").format('YYYY-MM-DD');
            //console.log("varrDatosLl",varrDatosLl);
            const filteredData = varrDatosLl.arrDatosDetall.filter(item => item.esBonificacion === false && item.arrArticulo[0].Campania === 'N');
            console.log("filteredData", filteredData);
            /** SOLO CAMPAÑA */
            const filteredData2 = varrDatosLl.arrDatosDetall.filter(item => item.esBonificacion === false && item.arrArticulo[0].Campania === 'Y');
            console.log("filteredData2", filteredData2);
            let resultad = [];
            if (filteredData.length>=1) {
                const filteredDataN = {
                    ...varrDatosLl,
                    arrDatosDetall: filteredData
                };
                 
                const body = {  vcodEmpresa: cook_idEmp, varrDatosLl: filteredDataN };
                resultad = await apiInsertOrder(body)

            }
            if (filteredData2.length>=1) {
            //if (filteredData2 !== undefined) {
                console.log("entra a con campaña");
                const filteredDataY = {
                    ...varrDatosLl,
                    arrDatosDetall: filteredData2
                };
                
                const body = {  vcodEmpresa: cook_idEmp, varrDatosLl: filteredDataY };
                const response = await apiInsertOrder(body)
                // const resultad2 = await response.json();
                console.log("response2 => ", response);
            }
            
            return resultad;
            
        } 
        const saveOrder = async() => {
            if (modalListDatos?.fileEscDirEntre!=="" && modalListDatos?.txtObservacion !=="") {
                if (modalListDatos.arrDatosDetall.length >= 1) {
   
                    var resulValidate = false;
                    /** Inicio :> Validar stock */
                    await Promise.all(
                        modalListDatos?.arrDatosDetall.map( async(key) => {
                            const rsp = await fuConsulStock(key.txtCodArticulo.Code, key.txtCodAlmacen)
                            if ( Number(key['txtCantidad']) >  Number(rsp[0].Stock) ) {
                                key['noStock'] = true;
                                resulValidate = true
                                handleFieldAsign('arrDatosDetall', modalListDatos?.arrDatosDetall)
                                
                            }
                        })
                    )
                    /** Fin :> Validar stock */
                
                    if (resulValidate) {
                        notify_warning("No se puede registrar pedido por que no hay stock")
                        setTimeout(() => {
                            console.log("modalListDatos?.arrDatosDetall", modalListDatos?.arrDatosDetall);
                        }, 1000);
                    }else{
                        //notify_valido("cumple la condición")
                        Swal.fire({
                            title: 'Loading...',
                            html: 'Se estan procesando los datos.',
                            timerProgressBar: true,
                            didOpen: () => { Swal.showLoading() },
                            customClass: { container: 'my-swal' }
                        })
                        const filtrarNoNecesarios =  ({ 
                            arrGrupoArticulo,
                            arrImpuestos,
                            arrUnidadMedida,
                            //arrClientePuntos,
                            ...modalListDatos 
                        }) => modalListDatos
                        const varrDatosLl = filtrarNoNecesarios(modalListDatos);
                        //console.log("datos a enviar",varrDatosLl);
                        
                        // // const url = '/apiInsertOrder';
                        // const body = {  vcodEmpresa: cook_idEmp, varrDatosLl: varrDatosLl };
                        // // const opti = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
                        // // const response = await fetch(url,opti);
                        // // const resultad = await response.json();
                        // const response = await apiInsertOrder(body)
                        // const resultad = await response.json();
                        // console.log(" onClickSave resultad", resultad);



                        const resultad = await handleValidarInsert(varrDatosLl)
                        console.log("resultad => ", resultad);
                        if (resultad.vl_rspDocEntry) {
                            setValiArrDatosDetall(false)
                           // console.log("Se registro, se muestra el docentry en el inpu");
                            handleFieldAsign("txtCodDocumento", resultad?.vl_rspDocEntry);
                            /** Disminuir Stock  */
                            
                            await Promise.all(
                                modalListDatos?.arrDatosDetall.map( async(key) => {
                                    if (key.DiscountPercent === 100) {
                                        console.log("solo los que tienen descuento 10 key", key);
                                        key['idempresa'] = cook_idEmp;
                                        await apiDisminuirStock (key)
                                    }                            
                                })
                            )

                            
                        }
                        
                        
                        Swal.close()
                        handleCloseModal()
                    }
                }else{
                    notify_warning("Registre al menos un producto!")
                }
            }else{ 
                if(modalListDatos?.fileEscDirEntre==="") {  notify_warning("Seleccione Dirección de Entrega") }
                if(modalListDatos?.txtObservacion==="") {  notify_warning("Campo Comentario es Obrigatorio") }
            }
        }
        if (valiArrDatosDetall===true) {
           saveOrder()
        }
        
    }, [apiDisminuirStock, cook_idEmp, fuConsulStock, handleCloseModal, modalListDatos, valiArrDatosDetall]);

    
    const onClickSave = async() => {       
        await onClickMoldalPrinAplicarRegalo('save') 
        //setValiArrDatosDetall(true)
    }

    const onClickEdit = async(row) => {
        setDisabledG(false)
        setDisabledSlcCliente(true)
        setDisabledSlcVendedo(true)
        handleOpenModal()

        const url ='/apiConsulDocumento?vcodEmpresa='+cook_idEmp+'&vtxtCodigo='+row.Numero_Unico;
        const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
        const rspd = await fetch(url,opPe);
        const data = await rspd.json();
        console.log("data", data);

        data['txtCodDocumentoAnt'] = data['txtCodDocumento']
        data['txtCodDocumento'] = ''
        data['arrUnidadMedida'] = []

        await arrDatosDetallesEdit(data)
        
    }
    const arrDatosDetallesEdit = async(arrData) =>{
        const [year, month, day] = arrData['txtFechaC'].split('-');
        const date = new Date(year, month - 1, day)

        arrData['txtFechaC'] = date;
        arrData['txtFechaE'] = date;
        arrData['txtFechaD'] = date;
        const codCliente = arrData.txtCodCliente.Code;
        //console.log("codCliente", arrData.txtCodCliente.Code);
        const codVendedor = arrData.arrfileEscVendedor.Codigo;
        let arrDatosDir = await fcDtDatosClientePuntos(codCliente,codVendedor) 
       
        let datosSlVend = await funDtVendedores(codVendedor)
        arrData['txtCodVendedor'] = {"Code":datosSlVend[0].Code,"Name":datosSlVend[0].Name};
        
        
        const grupos = ['1', '2', '3', '4', '5'];
        console.log("Data de va quedando ", arrData);
        arrData['arrClientePuntos'] = arrDatosDir;
        const itemSelecionado =  arrData['arrClientePuntos'][0]?.Direcciones.filter((item) => item.Codigo === arrData['fileEscDirEntre'])
        arrData['arrfileEscDirEntre'] = itemSelecionado;
        if (arrData['arrDatosDetall']) {
            arrData['arrDatosDetall']?.map(async(key)  => {
                if (!key['esBonificacion']) {
                    console.log("key", key);
                    key['arrGrupoUnidadMedida'] = await fcDDatosGrupoUnidadMedida(codVendedor,key.txtCodArticulo.Code);
                    key['arrArticulo'] = await fcDatosArticulo(codVendedor,key.txtCodArticulo.Code)
                    // key['arrStock'] = await fcDDatosStock(codVendedor,key.txtCodArticulo.Code)
                    // key['arrImpuestos'] = await fcListaDatosImpuestos()
                    // key['arrListaPrecios'] = await fcListaDatosImpuestos(codVendedor,key.txtCodArticulo.Code)
                    // key['arrPrecioArticulo'] = await fcListaDatosImpuestos(codVendedor,key.txtCodArticulo.Code)
                    const arrBonif = [];
                    const arrDescu = []
                    if (arrData['arrClientePuntos'][0]["GrupoSocio"] === 110) {
                        for (const grupo of grupos) {
                            console.log("key['arrArticulo'][0]", key['arrArticulo'][0][`Grupo${grupo}Cob`]);
                            const grupoCob = key['arrArticulo'][0][`Grupo${grupo}Cob`];
                            const grupoDesCob = key['arrArticulo'][0][`Des${grupo}Cob`];
                            const bon = {};
                            bon["grupo"] = grupo;
                            bon["codigo"] = grupoCob;
                            const des = {};
                            des["grupo"] = grupo;
                            des["codigo"] = grupoDesCob;
                            arrBonif.push(bon);
                            arrDescu.push(des);
                        }
                    }
                    if (arrData['arrClientePuntos'][0]["GrupoSocio"] === 107) {
                        for (const grupo of grupos) {
                            console.log("key['arrArticulo'][0]", key['arrArticulo'][0][`Grupo${grupo}Cob`]);
                            const grupoCob = key['arrArticulo'][0][`Grupo${grupo}Cob`];
                            const grupoDesCob = key['arrArticulo'][0][`Des${grupo}Cob`];
                            const bon = {};
                            bon["grupo"] = grupo;
                            bon["codigo"] = grupoCob;
                            const des = {};
                            des["grupo"] = grupo;
                            des["codigo"] = grupoDesCob;
                            arrBonif.push(bon);
                            arrDescu.push(des);
                        }
                    }
                    if (arrData['arrClientePuntos'][0]["GrupoSocio"] === 100) {
                        for (const grupo of grupos) {
                            console.log("key['arrArticulo'][0]", key['arrArticulo'][0][`Grupo${grupo}Cob`]);
                            const grupoCob = key['arrArticulo'][0][`Grupo${grupo}Cob`];
                            const grupoDesCob = key['arrArticulo'][0][`Des${grupo}Cob`];
                            const bon = {};
                            bon["grupo"] = grupo;
                            bon["codigo"] = grupoCob;
                            const des = {};
                            des["grupo"] = grupo;
                            des["codigo"] = grupoDesCob;
                            arrBonif.push(bon);
                            arrDescu.push(des);
                        }
                    }
                    key['bonificaciones']=arrBonif
                    key['descuentos']=arrDescu
                }
            })
        }
        
        
        
        arrData['arrfileEscVendedor'] = await fcDatosVendedor(datosSlVend[0])
        















        
        setModalListDatos(arrData);
        
        //handleFieldAsignList(arrData)
        ///setModalListDatos({...modalListDatos,...arrData})
        


        setTimeout(async() => {
            
            await fcListaGrupoArticulo()
            await fcListaDatosImpuestos()
            await fcListaCondicionPago()
            await fcListaCanal()
           
        }, 1000);


    }
    const arrDatosDetalles = async(arrData) =>{
        const [year, month, day] = arrData['txtFechaC'].split('-');
        const date = new Date(year, month - 1, day)

        arrData['txtFechaC'] = date;
        arrData['txtFechaE'] = date;
        arrData['txtFechaD'] = date;
        console.log("Data de llegada", arrData);
        const codCliente = arrData.txtCodCliente.Code;
        //console.log("codCliente", arrData.txtCodCliente.Code);
        const codVendedor = arrData.arrfileEscVendedor.Codigo;
        let arrDatosDir = await fcDtDatosClientePuntos(codCliente,codVendedor) 
       
        
        let datosSlVend = await funDtVendedores(codVendedor)
        arrData['txtCodVendedor'] = {"Code":datosSlVend[0].Code,"Name":datosSlVend[0].Name};
        
        
        const grupos = ['1', '2', '3', '4', '5'];
        console.log("Data de va quedando ", arrData);
        arrData['arrClientePuntos'] = arrDatosDir;
        const itemSelecionado =  arrData['arrClientePuntos'][0]?.Direcciones.filter((item) => item.Codigo === arrData['fileEscDirEntre'])
        arrData['arrfileEscDirEntre'] = itemSelecionado;
        
        arrData['arrfileEscVendedor'] = await fcDatosVendedor(datosSlVend[0])
                
        setModalListDatos(arrData);
        
        //handleFieldAsignList(arrData)
        ///setModalListDatos({...modalListDatos,...arrData})
        


        setTimeout(async() => {
            
            await fcListaGrupoArticulo()
            await fcListaDatosImpuestos()
            await fcListaCondicionPago()
            await fcListaCanal()
           
        }, 1000);


    }


  return (
    <div>
        <h4 className="fw-bold py-3 mb-4 d-none"> Filtrar </h4>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Filtrar</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="#" className="card-expand d-none"><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                <div className="row ">
                    <div className="col-md-2">Sucursal:</div>
                    <div className="col-md-2">
                        <SelectSucursal id="veh_slc_suc" value={sl_t_suc} onChange={changeSl_t_suc} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-2">TipoCon:</div>
                    <div className="col-md-4">
                        <select 
                            className="form-select form-select-sm"  
                            value={slc_text_tipocons} 
                            onChange={changeSlc_text_tipocons} >
                                <option value="9999">--Seleccione--</option>
                                <option value="PFM">Pedidos por facturar manuales</option>
                                <option value="PPF">Pedidos Por facturar</option>
                                
                        </select>
                    </div>
                    
                </div> 
                <div className="row ">
                    <div className="col-md-2">NComprobante:</div>
                        <div className="col-md-2">
                            <input type="text" id="txt_numcomp" name="txt_filtro" className="form-control form-control-sm" value={inp_text_nuncompr} onChange={changeinp_text_nuncompr} ></input>
                        </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-2">Fecha Emision:</div>
                    <div className="col-md-2">
                        <Tooltip title="Desde" placement="left">
                            <Box>
                                <DatePicker 
                                    locale="es"
                                    className="form-control form-control-sm text-center"
                                    selected={inp_text_fecdesde} 
                                    onChange={date => setInp_text_fecdesde(date)} 
                                    dateFormat="yyyy-MM-dd"
                                />
                            </Box>
                        </Tooltip>
                        
                    </div>
                    <div className="col-md-2">
                        <Tooltip title="Hasta" placement="left" >
                            <Box >                   
                                <DatePicker 
                                    locale="es"
                                    className="form-control form-control-sm text-center"
                                    selected={inp_text_fechasta} 
                                    onChange={date => setInp_text_fechasta(date)} 
                                    dateFormat="yyyy-MM-dd"
                                />
                            </Box>
                        </Tooltip>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-md-2">Mesa:</div>
                    <div className="col-md-4">
                        <AsyncSelect 
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"  
                            value={sl_t_mesas}
                            defaultOptions
                            getOptionLabel={e => e.Code +' - '+ e.Name}
                            getOptionValue={e => e.Code }
                            loadOptions={funDArticulos}
                            onChange={changeSetsl_mesas}
                            maxMenuHeight={150}
                        />
                    </div>
                    <div className="col-md-2">GrupoVenta:</div>
                    <div className="col-md-4">
                        <AsyncSelect 
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"  
                            value={sl_t_grupoventa}
                            defaultOptions
                            getOptionLabel={e => e.Code +' - '+ e.Name}
                            getOptionValue={e => e.Code }
                            loadOptions={funGrupoVenta}
                            onChange={changeSl_t_grupoventa}
                            maxMenuHeight={150}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                  
                  <div className="col-md-2">Cliente:</div>
                  <div className="col-md-4 ">
                        <AsyncSelect 
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"  
                            value={sl_t_cli}
                            defaultOptions
                            getOptionLabel={e => e.Code +' - '+ e.Name}
                            getOptionValue={e => e.Code }
                            loadOptions={funClientes}
                            onChange={changeSetsl_t_cli}
                            maxMenuHeight={150}
                        />
                  </div>
                  <div className="col-md-2">C.Vendedor:</div>
                  <div className="col-md-4">
                    <AsyncSelect 
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"  
                        value={sl_t_ven}
                        defaultOptions
                        getOptionLabel={e => e.Code +' - '+ e.Name}
                        getOptionValue={e => e.Code }
                        loadOptions={funVendedores}
                        onChange={changeSetsl_t_ven}
                        maxMenuHeight={150}
                    />
                  </div>
                </div>
               

                <div className="row ">
                    <div className="col-md-4 "></div>
                    <div className="col-md-4 text-center ">
                        <Tooltip title="Consultar información" placement="top">
                            <button className="btn btn-primary btn-sm" id="btn_procesar" onClick={onClickProce} >
                                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                                &nbsp;Consultar
                            </button>
                        </Tooltip>
                        <Tooltip title="Exportar Excel" placement="top">
                            <button className="btn btn-success btn-sm" id="btn_procesar_excel" onClick={onClickExcel} >
                                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                                &nbsp;Exportar Excel
                            </button>
                        </Tooltip>
                        <Tooltip title="Resetar Campos" placement="top">
                            <button className="btn btn-dark btn-sm" id="btn_reset" onClick={onClickReset} >
                                <i className='bx bx-reset bx-ms' id="btn_procesar_icon"></i>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Lista Comprobantes de Ventas</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="#" className="card-expand d-none" ><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                <div className="card-datatable table-responsive">
                    <MaterialReactTable 
                        columns={columns}
                        data={users}
                        onSortingChange={setSorting}
                        state={{ isLoading: pending, sorting }}
                        initialState={{
                          density: 'compact',
                          expanded: true,
                          showColumnFilters: false,
                          showGlobalFilter: false
                        }}
                        enableGrouping enableStickyHeader
                        enablePagination={true}
                        enableBottomToolbar={true}
                        enableGlobalFilterModes
                        rowVirtualizerProps={{ overscan: 8 }}
                        enableRowActions
                        renderRowActions={({ row }) => (
                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                            {/* bt_funct={() => handleOpenPD(row.original)} */}
                            <AButton 
                                tp_tilde="Ver pedido" tp_posit="top" 
                                bt_class="btn btn-sm btn-icon btn-label-success" 
                                ic_class="bx bx-ms bxs-show" vl_tpbtn={1} 
                                bt_funct={ (e) => {onClickView(row.original)}} />
                                <AButton disabled="true"
                                tp_tilde="Editar pedido" tp_posit="top" 
                                bt_class="btn btn-sm btn-icon btn-label-warning" 
                                ic_class="bx bx-ms bxs-edit" vl_tpbtn={1} 
                                bt_funct={ (e) => {onClickEdit(row.original)}} />
                            <AButton tp_tilde="Cancelar Orden" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_vmodal={2}  bt_funct={() => onClickCancelarOrden(row.original)} ic_class="bx bx-ms bx-layer-minus" vl_tpbtn={1} />
                            {/* Otros botones o contenido según sea necesario */}
                            </Box>
                        )}
                        renderTopToolbarCustomActions={({ table, row }) => (
                            <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                                <AButton tp_tilde="Nuevo" tp_posit="top" bt_class="btn btn-sm btn-icon btn-success" disabled={true} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} 
                                bt_funct={onClickNew} />
                            </Box>
                        )}
                        muiCircularProgressProps={{ color: 'error' }}
                        muiSkeletonProps={{
                            animation: 'pulse',
                            height: 28,
                        }}
                      
                    />


                </div>
            </div>
        </div>

        {/** ======================================== Modal orden de ventas ======================================== */}
        <Modal open={openModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className="m-0 p-0"
        >
            <Box sx={{ ...style, width: '80%', height: '90%', overflow: 'auto' }}>
            
                    <div className="modal-header">
                        <h5 className="modal-title mb-2" >Detalle Pedido</h5>
                        <button
                            className='btn btn-label-dark btn-sm btn-icon mb-2'
                            onClick={handleCloseModal}
                        >
                            <i className='bx bx-x bx-ms'></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">Cliente:</label></div>
                            <div className="col-lg-7">
                                <div className="d-flex align-items-center justify-content-between">
                                    <AsyncSelect 
                                        styles={styles}
                                        placeholder="Busque por Cod./Desc."
                                        className="sele_pru from-control form-control-sm flex-grow-1 my-swal"
                                        value={modalListDatos?.txtCodCliente}
                                        defaultOptions
                                        getOptionLabel={e => e.Code +' - '+ e.Name}
                                        getOptionValue={e => e.Code }
                                        loadOptions={funClientesXVendedor}
                                        onChange={handleFieldChangeArr('txtCodCliente')}
                                        maxMenuHeight={100}
                                        isDisabled={disabledSlcCliente}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-lg-2"></div> */}
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">Serie:</label></div>
                            <div className="col-lg-1">
                                <input type="text" className="form-control form-control-sm" value={modalListDatos?.txtSerie} onChange={handleFieldChange('txtSerie')} disabled={true} />
                            </div>
                            <div className="col-lg-2">
                                <input type="text" className="form-control form-control-sm" value={modalListDatos?.txtCodDocumento} onChange={handleFieldChange('txtCodDocumento')} disabled={true} />
                            </div>
                        </div>
                        <div className="row ">
                            
                            
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">F.Conta:</label></div>
                            <div className="col-lg-3" style={{ position: 'relative', zIndex: '3' }}>
                                {/* <input type="text" className="form-control form-control-sm" value={txt_fecha} onChange={changeTxt_fecha} disabled={disabledG} /> */}
                                <DatePicker 
                                    locale="es"
                                    wrapperClassName='input_date'
                                    className="form-control form-control-sm text-center"
                                    selected={modalListDatos?.txtFechaC} 
                                    onChange={handleDateChange('txtFechaC')} 
                                    dateFormat="yyyy-MM-dd"
                                    disabled={true}
                                />
                            </div>
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">F.Entrega:</label></div>
                            <div className="col-lg-3" style={{ position: 'relative', zIndex: '2' }}>
                                {/* <input type="text" className="form-control form-control-sm" value={txt_fecEmision} onChange={changeTxt_fecEmision} disabled={disabledG} /> */}
                                <DatePicker 
                                    locale="es"
                                    wrapperClassName='input_date'
                                    className="form-control form-control-sm text-center"
                                    selected={modalListDatos?.txtFechaE} 
                                    onChange={handleDateChange('txtFechaE')} 
                                    dateFormat="yyyy-MM-dd"
                                    disabled={true}
                                />
                            </div>
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">F.Docume:</label></div>
                            <div className="col-lg-3" style={{ position: 'relative', zIndex: '2' }}>
                                {/* <input type="text" className="form-control form-control-sm" value={txt_fecEmision} onChange={changeTxt_fecEmision} disabled={disabledG} /> */}
                                <DatePicker 
                                    locale="es"
                                    wrapperClassName='input_date'
                                    className="form-control form-control-sm text-center"
                                    selected={modalListDatos?.txtFechaD} 
                                    onChange={handleDateChange('txtFechaD')} 
                                    dateFormat="yyyy-MM-dd"
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-lg-1 "><label htmlFor="text" className="form-label">Direccion:</label></div>
                            <div className="col-lg-7 ">
                                <select 
                                    className="form-select form-select-sm"  
                                    value={modalListDatos?.fileEscDirEntre} 
                                    disabled={disabledG}
                                    onChange={handleFieldChange('fileEscDirEntre')} >
                                        <option value="99991"  >--Seleccione--</option>
                                        {
                                            modalListDatos?.arrClientePuntos[0]?.Direcciones?.filter(dir => dir.Codigo.includes("ENTREGA") ).map((key, index) =>{
                                                return (<option value={key.Codigo} key={index} >{key.Codigo} - {key.Calle}</option>)
                                            })
                                        }
                                </select>
                            </div>
                            <div className="col-lg-1 "><label htmlFor="text" className="form-label">CondPago:</label></div>
                            <div className="col-lg-3 ">
                                <select 
                                    className="form-select form-select-sm"  
                                    value={modalListDatos?.fileEscCondPago} 
                                    disabled={disabledG}
                                    onChange={handleFieldChange('fileEscCondPago')} >
                                        <option value="99991"  >--Seleccione--</option>
                                        {
                                            modalListDatos?.arrCondicionPago?.map((key, index) =>{
                                                return (<option value={key.codigo} key={index} >{key.nombre}</option>)
                                            })
                                        }
                                </select>
                            </div>
                            
                        </div>
                        <div className="row mb-1">
                            
                            
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">Canal:</label></div>
                            <div className="col-lg-3" style={{ position: 'relative', zIndex: '3' }}>
                                <input type="text" className="form-control form-control-sm" value={modalListDatos.arrfileEscVendedor?.Grupo} disabled={true} /> 

                            </div>
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">ListaP:</label></div>
                            <div className="col-lg-3" style={{ position: 'relative', zIndex: '2' }}>
                                <input type="text" className="form-control form-control-sm" value={modalListDatos.arrClientePuntos[0]?.DListaPrecio}  disabled={true} /> 
                                
                            </div>
                            
                            <div className="col-lg-4 text-end">
                                <AButton tp_tilde="Ver 3 ultimas ventas" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-warning" 
                                    ic_class="bx bx-ms bx-right-indent" vl_tpbtn={1}
                                    bt_funct={onClick3UltP}
                                    disabled={disabledG}
                                />&nbsp;
                                <AButton tp_tilde="Aplicar Regalo" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success" 
                                    ic_class="bx bx-ms bx-gift" vl_tpbtn={1}
                                    bt_funct={onClickMoldalPrinAplicarRegalo}
                                    disabled={disabledG}
                                />&nbsp;
                                <AButton tp_tilde="Seleccionar articulo" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" 
                                    ic_class="bx bx-ms bxs-layer" vl_tpbtn={1}
                                    bt_funct={handleOpenModapProd} 
                                    disabled={disabledG}
                                />
                            </div>
                        </div>
                        

                        
                        <div className="table-responsive text-nowrap" 
                            style={{    position: 'relative', 
                                        width:'auto',
                                        zIndex: '1', 
                                        height: '200px', 
                                        overflowY: 'scroll', 
                                        overflowX: 'scroll'
                                    }}>
                            <table className="table table-sm" style={{fontSize:10, zIndex: '2' }}>
                                <thead style={{ position: 'sticky', top: 0, zIndex: 10}}>
                                    <tr className="table-danger" >
                                        <th>OP</th>
                                        {/* <th>Item</th> */}
                                        <th>Codigo</th>
                                        <th>Descripción</th>
                                        <th>Cantidad</th>
                                        <th>Unidad</th>
                                        <th>PrecioUnitario</th>
                                        <th>Desc.</th>
                                        <th>Total</th>
                                        <th>TotalSinDesc</th>
                                        <th>TotalSolDesc</th>
                                        <th>FTotal</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                
                                { 
                                    modalListDatos?.arrDatosDetall.map((item,index) => {
                                        return (<tr key={index} className={item.noStock ? "bg-linkedin" : ''}
                                                style={{'color': item.noStock ? 'white': ''}}
                                            >
                                            <td>
                                                <AButton tp_tilde="Eliminar"  
                                                    tp_posit="top" 
                                                    bt_class="btn btn-sm btn-icon btn-label-youtube" 
                                                    ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} 
                                                    bt_funct={() => {onClickEliminarFile(index)}} 
                                                />
                                            </td>
                                            {/* <td>{index+1}</td> */}
                                            <td>{item.txtCodArticulo.Code}</td>
                                            <td>{item.txtCodArticulo.Name}</td>
                                            <td>{item.txtCantidad}</td>
                                            {/* <td>{item.txtUnidadMedida?.Code}</td> */}
                                            <td>{item.arrUMSelecionad[0]?.Nombre}</td>
                                            <td>{item.txtPrecioUnitario}</td>
                                            <td>{item?.DiscountPercent}</td>
                                            <td>{item.txtTotal}</td>
                                            <td>{item.txtTotalSinDesc}</td>
                                            <td>{item.txtTotalSolDesc}</td>
                                            <td>{item.txtfullTotal}</td>
                                            
                                        </tr>)
                                    })
                                }

                                </tbody>
                            </table>
                        </div>

                                

                        <div className="row mb-1">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-2">
                                        <label htmlFor="text" className="form-label">Vendedorw:</label>
                                    </div>
                                    <div className="col-lg-10 px-1">
                                        <div className="d-flex align-items-center justify-content-between" >
                                            <AsyncSelect 
                                                ref={selectRef}
                                                styles={styles}
                                                placeholder="Busque por Cod./Desc."
                                                className="sl2MvendM from-control form-control-sm flex-grow-1 my-swal"  
                                                value={modalListDatos.txtCodVendedor || null}
                                                defaultOptions
                                                getOptionLabel={e => e.Code +' - '+ e.Name}
                                                getOptionValue={e => e.Code }
                                                loadOptions={funVendedores}
                                                onChange={handleFieldChangeArr('txtCodVendedor')}
                                                isDisabled={disabledSlcVendedo}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2"><label htmlFor="text" className="form-label">Comentario</label></div>
                                    <div className="col-lg-10">
                                        <textarea  type="text" className="form-control form-control-sm" rows={3} value={modalListDatos?.txtObservacion} onChange={handleFieldChange('txtObservacion')} disabled={disabledG} ></textarea >
                                    </div>
                                </div>

                            </div>
                            {/* <div className="col-lg-2 py-3"></div> */}
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6"><label htmlFor="text" className="form-label">Total Antes del descuento</label></div>
                                    <div className="col-lg-6"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" value={modalListDatos?.txtTotalAntDes} onChange={handleFieldChange('txtTotalAntDes')} disabled={true} /></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6"><label htmlFor="text" className="form-label">Descuento</label></div>
                                    <div className="col-lg-6"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" value={modalListDatos?.txtDescuento} onChange={handleFieldChange('txtDescuento')} disabled={true} /></div>
                                </div>
                                {/* <div className="row d-none">
                                    <div className="col-lg-6"><label htmlFor="text" className="form-label">Gastos adicionales</label></div>
                                    <div className="col-lg-6"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" value={modalListDatos?.txtGastosAdici} onChange={handleFieldChange('txtGastosAdici')}  disabled={disabledG} /></div>
                                </div>
                                <div className="row d-none">
                                    <div className="col-lg-6"><label htmlFor="text" className="form-label">Redondeo</label></div>
                                    <div className="col-lg-6"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" value={modalListDatos?.txtRedondeo} onChange={handleFieldChange('txtRedondeo')} disabled={disabledG} /></div>
                                </div>*/}
                                <div className="row">
                                    <div className="col-lg-6">
                                    <label htmlFor="text" className="form-label">
                                        Recargo:
                                    </label></div>
                                    <div className="col-lg-6">
                                        <input type="text" className="form-control form-control-sm text-end"  value={modalListDatos?.txtRecargo} onChange={handleFieldChange('txtRecargo')}  disabled={true} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                    <label htmlFor="text" className="form-label">
                                        impuesto
                                    </label></div>
                                    <div className="col-lg-6">
                                        <input type="text" className="form-control form-control-sm text-end" value={modalListDatos?.txtInpuesto} onChange={handleFieldChange('txtInpuesto')}  disabled={true} />
                                    </div>
                                </div> 
                                <div className="row">
                                    <div className="col-lg-6">
                                    <label htmlFor="text" className="form-label">
                                        SUBTOTAL
                                    </label></div>
                                    <div className="col-lg-6">
                                        <input type="text" className="form-control form-control-sm text-end" value={modalListDatos?.txtPTotal} onChange={handleFieldChange('txtTotal')} disabled={true} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                    <label htmlFor="text" className="form-label">
                                        Total
                                    </label></div>
                                    <div className="col-lg-6">
                                        <input type="text" className="form-control form-control-sm text-end" value={modalListDatos?.txtTotalIgv} onChange={handleFieldChange('txtTotalIgv')} disabled={true} />
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                    {/** [data_comp,data_docu] */}
                    <div className="row">
                        <div className="col-lg-6"></div>
                        <div className="col-lg-6 text-end">
                            <AButton 
                                tp_tilde="Close" 
                                bt_descr="Close" tp_posit="top" 
                                bt_class="btn btn-label-dark btn-sm" 
                                bt_funct={handleCloseModal} 
                                ic_class="bx bx-reset bx-ms" vl_tpbtn={1} 
                            />&nbsp;
                            {/** */
                                !disabledG &&  (
                                    <AButton 
                                        tp_tilde="Save changes" 
                                        bt_descr="Save changes" tp_posit="top" 
                                        bt_class="btn btn-label-youtube btn-sm" 
                                        bt_funct={onClickSave} 
                                        ic_class="bx bx-send bx-ms" vl_tpbtn={1}
                                        // disabled= {disabledGD}
                                    />
                                )
                            }
                            {/* <AButton 
                                        tp_tilde="Save changes" 
                                        bt_descr="Save changes" tp_posit="top" 
                                        bt_class="btn btn-label-youtube btn-sm" 
                                        bt_funct={onTeste} 
                                        ic_class="bx bx-send bx-ms" vl_tpbtn={1}
                                        // disabled= {disabledGD}
                                    /> */}
                        </div>
                        
                       
                    </div>
            </Box>
        </Modal>
        {/** ======================================== Modal Producto =============================================== */}
        <Modal open={openModalProd}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...style, width: '40%', height: '70%', overflow: 'auto' }}>
                <div className="modal-header">
                    <h5 className="modal-title" >Articulos</h5>
                    <button
                        className='btn btn-label-dark btn-sm btn-icon mb-4'
                        onClick={onClikCloseFile}
                    >
                        <i className='bx bx-x bx-ms'></i>
                    </button>
                </div>
                <div className="modal-body" >
                    <div className="row mb-2">
                        <div className="col-lg-12">
                            <label className="form-label mb-0 " >Selecione Articulo:</label>
                            <AsyncSelect 
                                styles={styles}
                                placeholder="Busque por Cod./Desc."
                                name="form-field-name"
                                className="sele_pru from-control form-control-sm"  
                                value={modal2ListDatos.txtCodArticulo}
                                defaultOptions
                                getOptionLabel={e => e.Code +' - '+ e.Name}
                                getOptionValue={e => e.Code }
                                loadOptions={funArticulos}
                                onChange={handle2FieldChangeArr('txtCodArticulo')}
                                //isDisabled={disabledSecun}
                                maxMenuHeight={150}
                            />
                        </div>
                    </div>
                    <label className="form-label mb-1 " >Detalles de articulo:</label>
                    <div className="row" style={{fontSize:11,}}>
                        <div className="col-lg-3">Stock:</div>
                        <div className="col-lg-9">{modal2ListDatos.txtStock}</div>
                    </div>
                    <div className="row mb-1" style={{fontSize:11,}}>
                        <div className="col-lg-3">Precio Unitario:</div>
                        <div className="col-lg-9">S/ {modal2ListDatos.txtPrecioUnitario}</div>
                    </div>
                    <div className="row mb-2" style={{fontSize:12, fontWeight:'bold'}}>
                        <div className="col-lg-3">Total:</div>
                        <div className="col-lg-9">S/ {modal2ListDatos.txtTotal}</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-6">
                            <label className="form-label mb-0 " >Unidad de Medida:</label>
                            {/* <SelectSucursal value={modal2ListDatos.txtUnidadMedida} onChange={handle2FieldChangeArr('txtUnidadMedida')} /> */}
                            <select 
                                className="form-select form-select-sm"  
                                value={modal2ListDatos.txtUnidadMedida} 
                                onChange={handle2FieldChange('txtUnidadMedida')} >
                                    <option value="99991"  >--Seleccione--</option>
                                    {
                                        modal2ListDatos.arrGrupoUnidadMedida?.map((key, index) =>{
                                            return (<option value={key.Codigo} key={index} >{key.Nombre}</option>)
                                        })
                                    }
                            </select>

                        </div>
                        <div className="col-lg-6">
                            <label className="form-label mb-0 " >cantidad:</label>
                            <input type="number" className="form-control form-control-sm text-center" value={modal2ListDatos.txtCantidad} onChange={handle2FieldChange('txtCantidad')} />
                        </div>
                    </div>
                    

                </div>
                <div className="text-center">
                    <div className="row">
                        <div className="col-lg-12">
                            <AButton tp_tilde="Cancelar" bt_descr="Cancelar" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClikCloseFile} ic_class="bx bx-reset bx-ms" vl_tpbtn={1} />&nbsp;
                            <AButton tp_tilde="Añadir Articulo" bt_descr="Añadir" tp_posit="top" bt_class="btn btn-label-youtube btn-sm" bt_funct={onClikAddFile} ic_class="bx bx-cart-add bx-ms" vl_tpbtn={1} disabled={disabledBoton} />
                            
                            {/* <button type="button" className="btn btn-sm btn-label-youtube" onClick={handleCloseModapProd}>Añadir</button> */}
                        </div>
                    </div>
                    
                </div>
            </Box>
        </Modal>
        {/** ======================================== Modal Pedidos Anteriores =============================================== */}
        <Modal open={openModal3UltP}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...style, width: '70%', height: '70%', overflow: 'auto' }}>
                <div className="modal-header">
                    <h5 className="modal-title" >3 Ultimos Pedios</h5>
                    <button
                        className='btn btn-label-dark btn-sm btn-icon mb-4'
                        onClick={handleCloseModap3UltP}
                    >
                        <i className='bx bx-x bx-ms'></i>
                    </button>
                </div>
                <div className="modal-body" >
                    <MaterialReactTable
                        columns={columnsUltimosPedidos}
                        data={lstModal3UltP}
                        //state={{ isLoading: pending }}
                        initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
                        enableGrouping
                        enableStickyHeader enableColumnResizing
                        muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                        localization={MRT_Localization_ES}
                        enablePagination={true}
                        muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                        enableGlobalFilterModes
                        rowVirtualizerProps={{ overscan: 8 }}
                        enableRowVirtualization
                        muiTableContainerProps={{ sx: { maxHeight: 300 } }}
                        muiCircularProgressProps={{ color: 'error' }}
                        // muiSkeletonProps={{
                        //     animation: 'pulse',
                        //     height: 28,
                        // }}
                    />
                    
                    

                </div>
                <div className="text-center">
                    <div className="row">
                        <div className="col-lg-12">
                            <AButton tp_tilde="Close" bt_descr="Close" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={handleCloseModap3UltP} ic_class="bx bx-reset bx-ms" vl_tpbtn={1} />&nbsp;
                            
                            {/* <button type="button" className="btn btn-sm btn-label-youtube" onClick={handleCloseModapProd}>Añadir</button> */}
                        </div>
                    </div>
                    
                </div>
                
            </Box>
        </Modal>

        <ToastContainer />
    </div>
  )
}

export default FACTPedidos