import { Box, Modal } from "@mui/material";
import { MaterialReactTable } from 'material-react-table'

import { useEffect, useMemo, useState } from "react";
import { AButton } from "../../components/Button";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { SelectSede, SelectSucursal } from "../../components/Selects/SelectsPrincipales";
import { notify_valido, notify_warning } from "../../components/utils";
import { ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider } from '@mui/material';
import Swal from 'sweetalert2';
import Cookies from "universal-cookie";
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.default',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const COMGrupoventas = () => {
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [dataForTable, setDataForTable] = useState('');
  const [dataForTable2, setDataForTable2] = useState([]);
  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }
  const listar = async () => {
    if (!emp_sl_t_suc || emp_setSl_t_suc === 9999) {
      notify_warning("¡Seleccione una sucursal!");
      return;
    }
    const url_lstClientes = `/getGrupoVentas?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    if (data.length === 0) {
      notify_warning('Sin clientes registrados');
    }
    setDataForTable(emp_sl_t_suc);
    setDataForTable2(data);
  }

  const returnData = () => {
    listar();
  }

  return (
    <div className="d-flex flex-column">
      <h6 className="text-muted">Grupo ventas</h6>
      <div className="d-flex">
        <div className="col-lg-1">Sucursal:</div>
        <div className="col-lg-2">
          <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
        </div>
        <div className="col-lg-2">
          <div className="text-center mb-3">
            <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={listar}>
              <i className='bx bx-list-ul' ></i>&nbsp;Consultar
            </button>
          </div>
        </div>
      </div>
      <div className="tab-content m-0 p-0" id="pills-tabContent">
        <div className="tab-pane fade show active" id="pills-general-content" role="tabpanel" aria-labelledby="pills-general-tab" tabindex="0">
          <TablaGrupoVentas codigoSucursal={dataForTable} dataTable={dataForTable2} returnData={returnData} />
        </div>
      </div>
      <ToastContainer />
    </div >
  );
}

export default COMGrupoventas;

export const TablaGrupoVentas = ({ codigoSucursal, dataTable, returnData }) => {
  const [lst, setlst] = useState([]);
  const [searchCode, setSearchCode] = useState();
  const [openFirstModal, setOpenFirstModal] = useState(false);
  const columns = useMemo(() => [
    {
      header: 'Codigo', accessorKey: 'Code'
    },
    {
      header: 'Nombre', accessorKey: 'Name'
    },
    // {
    //   header: 'FuerzaVentas', accessorKey: 'fuerzaVentas'
    // },
    // {
    //   header: 'Supervisor', accessorKey: 'overseer'
    // },
  ], []);

  const handleOpen = (e) => {
    setOpenFirstModal(true);
  };

  const addNewGrupoVentas = () => {
    handleOpen(true);
  };

  const handleClose = () => {
    setSearchCode();
    setOpenFirstModal(false);
  };

  const setInInfoTable = (event) => {
    returnData(true);
    handleClose();
  };

  const onClickEdit = async (row) => {
    if (!row)
      return;
    const fechtApi = async (CardCode) => {
      const url = `getGrupoVentasByCode?codigo=${CardCode}`;
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
      const response = await fetch(url, opPe);
      const data = await response.json();
      return data;
    };
    setSearchCode(await fechtApi(row.Code));
    handleOpen();
  }

  const setEditInfoTable = (event) => {
    const valor1 = lst.findIndex((rest) => rest.Code === event.Code);
    lst[valor1] = event;
    setlst([...lst]);
    handleClose();
  }

  useEffect(() => {
    if (!codigoSucursal) return;
    setlst(dataTable);
  }, [codigoSucursal, dataTable]);
  const globalTheme = window.templateCustomizer.settings.style;
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [globalTheme],
  );

  return (
    <>
      <ThemeProvider theme={tableTheme}>

        <div className="card-datatable table-responsive">
          <div className="col-12">
            <MaterialReactTable
              data={lst}
              columns={columns}
              initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false }}
              renderTopToolbarCustomActions={({ table }) => (
                codigoSucursal ?
                  <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                    <AButton tp_tilde="Agregar unidad de negocio" tp_posit="top" bt_class="btn btn-label-success btn-sm " ic_class="fa-solid fa-plus" vl_tpbtn={1}
                      bt_funct={addNewGrupoVentas}
                    />
                  </Box> :
                  <div></div>
              )}
              enablePagination={true}
              enableRowActions
              localization={MRT_Localization_ES}
              renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                  <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-label-warning btn-icon btn-sm" ic_class="bx bxs-edit bx-ms" bt_funct={() => onClickEdit(row.original)}
                    id={JSON.stringify(row.original)} vl_tpbtn={1} />
                </Box>
              )}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}
            />
          </div>

          <Modal
            open={openFirstModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: '35%', height: '49%', overflow: 'auto' }}>
              <button
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}
                className='btn btn-outline-dark btn-sm'
                onClick={handleClose}
              >
                <i className='fa fa-x'></i>
              </button>
              <ContentDialog dataJson={searchCode} returnData={setInInfoTable} returnDataEdit={setEditInfoTable} />
            </Box>
          </Modal>
        </div>
      </ThemeProvider>
    </>
  );
};

export const ContentDialog = ({ dataJson, returnData, returnDataEdit }) => {
  const [sede, setSede] = useState('');
  const [sucursal, setSucursal] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [observation, setObservation] = useState('');

  useEffect(() => {
    if (!dataJson) return;
    setCode(dataJson.codigo);
    setSede(dataJson.fuerzaVentas);
    setName(dataJson.name);
    setSucursal(dataJson.overseer);
    setObservation(dataJson.observation);
  }, [dataJson]);

  const saveInfo = async () => {
    const value = {
      vcodEmpresa: cook_idEmp,
      'sede': sede,
      'sucursal': sucursal,
      'codigo': code,
      'name': name,
      'observation': observation,
    };
    console.log(value);
    if (
      !value.name || value.name.trim() === '') {
      notify_warning('El codigo del grupo de ventas no puede estar vacio');
      return;
    }
    if (
      !value.observation || value.observation.trim() === '') {
      notify_warning('El nombre del grupo de ventas no puede estar vacio');
      return;
    }
    if (
      !value.sede) {
      notify_warning('La sede del grupo de ventas no puede estar vacio');
      return;
    }
    if (
      !value.sucursal) {
      notify_warning('La sucursal del grupo de ventas no puede estar vacio');
      return;
    }
    Swal.fire({
      title: "CONFIRMACION",
      text: '¿Desea guardar este grupo de ventas?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      customClass: {
        container: 'my-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        let urlPost = `/saveGrupoVentas?codigo=${value.codigo}`;
        let body = value;
        const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
        const response = await fetch(urlPost, opPe);
        await response.json();
        returnData(value);
        notify_valido("Guardado");
      }
    });
  }

  const editInfo = async () => {
    const value = {
      'sede': sede,
      'sucursal': sucursal,
      'codigo': code,
      'name': name,
      'observation': observation,
      vcodEmpresa: cook_idEmp,
    };
    console.log(value);
    if (
      value.name.trim() === '') {
      notify_warning('El codigo del grupo de ventas no puede estar vacio');
      return;
    }
    if (
      value.observation.trim() === '') {
      notify_warning('El nombre del grupo de ventas no puede estar vacio');
      return;
    }
    Swal.fire({
      title: "CONFIRMACION",
      text: '¿Desea guardar este grupo de ventas?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      customClass: {
        container: 'my-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        let urlPost = `/editGrupoVentas?codigo=${value.name}`;
        let body = value;
        const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
        const response = await fetch(urlPost, opPe);
        await response.json();
        returnData(value);
        notify_valido("Guardado");
      }
    });

  }

  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-center p-0 m-0">
          {
            !dataJson ?
              <h6>Nuevo Grupo de ventas</h6> :
              <h6>Editando Grupo de ventas</h6>
          }
        </div>
        <div className="d-flex flex-column p-0 m-0">
          <div className="d-flex flex-column">
            <div className="d-flex flex-column form-group mx-1">
              <div className="small cont-weight-normal">
                Codigo:
              </div>
              <input type="text" className="form-control form-control-sm" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
          </div>
          <div className="d-flex flex-column form-group mx-1">
            <div className="small cont-weight-normal">
              Nombre:
            </div>
            <textarea style={{ resize: "none" }} rows={2} type="text" className="form-control form-control-sm" value={observation} onChange={(e) => setObservation(e.target.value)} />
          </div>
          <div className="d-flex flex-row p-0 m-0">
            <div className="col form-group mx-1">
              <div className="small cont-weight-normal">
                Sede:
              </div>
              <SelectSede id="veh_slc_suc" value={sede} onChange={(e) => setSede(e.target.value)} />
              {/* <input type="text" className="form-control form-control-sm" value={fuerzaVentas} onChange={(e) => setFuerzaVentas(e.target.value)} /> */}
            </div>
            <div className="col form-group mx-1">
              <div className="small cont-weight-normal">
                Sucursal:
              </div>
              <SelectSucursal id="veh_slc_suc" value={sucursal} onChange={(e) => setSucursal(e.target.value)} />
            </div>
          </div>
          {/* <div className="d-flex flex-column form-group mx-1">
            <div className="small cont-weight-normal">
              Codigo:
            </div>
            <input type="text" className="form-control form-control-sm" disabled={disabled} value={code} onChange={(e) => setCode(e.target.value)} />
          </div> */}
        </div>
        <div className="d-flex justify-content-end ml-auto mt-2">
          <div className="d-flex m-1">
            {
              !dataJson ?
                <button className="btn btn-sm btn-outline-success form-control" onClick={saveInfo}>
                  Guardar
                </button> :
                <button className="btn btn-sm btn-outline-warning form-control" onClick={editInfo}>
                  Editar
                </button>
            }
          </div>
        </div>
      </div>
    </>
  );
};

