import React, { useState, useMemo } from 'react'
import Cookies from 'universal-cookie';
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales'
import { notify_warning, notify_valido, selectTabla, selectTablaMultiple, removeSelectedRows } from '../../components/utils';
import $ from 'jquery'
import DataTable, { defaultThemes } from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
import '../../App.css';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { AButton } from '../../components/Button';
import { Box, Modal, Tooltip } from '@mui/material';
import * as XLSX from 'xlsx';
import * as Apis from './funApis'
const moment = require('moment')
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const customStyles2 = {
  header: {
    style: { minHeight: '15px', },
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: defaultThemes.default.divider.default,
      minHeight: '15px',
      fontSize: '12px',
      fontWeight: '800',
    },
  },
  rows: {
    style: { minHeight: '15px', fontSize: '11px', },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        minHeight: '15px',
        fontSize: '11px',
      },
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        minHeight: '15px',
        fontSize: '11px',
      },
    },
  },
};
const COMICarga = () => {

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  const [numm, setNum] = useState(10);

  const [data, setdata] = useState([]);
  const [pending, setPending] = useState(false);
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [inp_text_fecha, setInp_text_fecha] = useState(new Date());
  const [selectedFile, setSelectedFile] = useState(null);
  // Estado para las filas de pedidos seleccionadas  
  /** Listar datos */
  const onClickListar = async () => {
    setPending(true)
    // const url_lstClientes = `/apiListarCuotas?vcodEmpresa=${cook_idEmp}&vperiodo=${moment(inp_text_fecha, 'Y-M-D').format('MM-YYYY')}&vcodSucursal=${emp_sl_t_suc}`;
    const url_lstClientes = `/apiListarCuotas?vcodEmpresa=${cook_idEmp}&vperiodo=${inp_text_fecha.toISOString().slice(0, 7)}&vcodSucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdata(data)
    setPending(false)
  };
  const [excelData, setExcelData] = useState([]);
  const arrSucursales = {
    '001': 'Piura',
    '002': 'Tumbes',
    '003': 'Trujillo',
    '004': 'Chimbote',
    '005': 'Chiclayo',
    '006': 'Cajamarca',
    '008': 'Huamachuco' 
  };
  const arrSucursalesRv = {
    'PIURA':'001',
    'TUMBES':'002',
    'TRUJILLO':'003',
    'CHIMBOTE':'004',
    'CHICLAYO':'005',
    'CAJAMARCA':'006',
    'HUAMACHUCO':'008' 
  };
  const fc_cargar_excel = async (event) => {
    setPending(true)
    const file = event.target.files[0];
    console.log("file", file);
    const reader = new FileReader();
    reader.onload = async(e) => {
      //console.log('FileReader.onload ejecutado.');
      const data = new Uint8Array(e.target.result);
      console.log('Data leída del archivo:', data);
      const jsonDataRes = fc_extructura_data_excel(data)
      console.log("jsonDataRes", jsonDataRes);
      //** AGRUPAR DATOS  */
      const uniqueSucursales = [...new Set(jsonDataRes.map(item => item.Sucursal))];
      console.log("uniqueSucursales", uniqueSucursales);
      
      const uniqueVendedores = [...new Set(jsonDataRes.map(item => item.Vendedor))];
      console.log("uniqueVendedores", uniqueVendedores);
      
      //const uniqueUnidadnego = [...new Set(data.map(item => item.linea))];
      
      const transformedRows = []
      for (const originalRow of jsonDataRes) {
        for (let i = 1; i <= 40; i++) {  
          const linea = `Linea${i}`
          const cuota = `Cuota${i}`
          
          transformedRows.push({
            Codigo: originalRow.ID,
            Periodo: originalRow.Periodo,
            //Sucursal: originalRow.Sucursal,//'Piura',
            Sucursal: arrSucursalesRv[originalRow.Sucursal.toUpperCase()],//'001',
            Vendedor: originalRow.Vendedor,//originalRow.Vendedor,//'PIUV-162',
            Cobertura: Number(originalRow.Cobertura),//'290 ',
            //Cobertura: parseFloat(originalRow.Cobertura.trim()),//'290 ',
            Pedidos: Number(originalRow.NroPedidos),//'700',
            //Pedidos: parseFloat(originalRow.NroPedidos.trim()),//'700',
            UnidaNegocio: originalRow[linea],
            Cuota: Number(originalRow[cuota])
          })
        }
      }

      let arrDatosEnviar  = transformedRows.filter(item => item.Periodo !== '' )
            
      const rspEnvio = fc_envio_datosV2(arrDatosEnviar)
      console.log("rspEnvio", rspEnvio);
      notify_valido("Se proceso correctamente.")
      
    };
    reader.onerror = (err) => {
      console.log('Error al leer el archivo.');
      console.error(err);
    };
    reader.readAsArrayBuffer(file);
    event.target.value = null;
    setPending(false)
    //console.log("excelData", excelData);
    

  }
  const fc_extructura_data_excel = (data) =>{
    const workbook = XLSX.read(data, { type: 'array' });
    //console.log('Workbook leído:', workbook);
    const sheet = workbook.Sheets['TD- Carga Plantilla'];
    //console.log("sheet => ", sheet);
    const ref = sheet['!ref'];
    //console.log("ref", ref);
    const range = XLSX.utils.decode_range(ref);
    //console.log("range", range);
    const headerColumns = [];
    for (let col = range.s.c + 0; col <= 87; col++) { // el +1 lleva a la columna B
        const columnName = XLSX.utils.encode_col(col); // Obtiene la letra de la columna
        // console.log("Letra de la columna :: ", columnName );
        const headerCell = sheet[XLSX.utils.encode_cell({ r: 9, c: col })]; // ejmplo C1 - sheet[C1] -> combre de la celda
        headerColumns.push(headerCell ? headerCell.v : columnName); // Usar el valor de la celda o la letra
    }
    //console.log("headerColumns", headerColumns);   
    
    // Rango de donde se empieza a recorrer los datos sin cabecera
    const dataRange = `A11:${XLSX.utils.encode_col(range.e.c)}${range.e.r + 1}`;
    //console.log("Rango :: ",dataRange );
    let jsonData = XLSX.utils.sheet_to_json(sheet, {
      range: dataRange,
      header: headerColumns,
      cellDates: true, // Para manejar fechas correctamente
      raw: false, // Para que aplique la función de limpiezas
      cellFormula: false, // Para que no se interpreten fórmulas
      cellHTML: false, // Para que no se interpreten HTML en las celdas
      cellText: false, // Para que no se interpreten datos de texto enriquecido
      cellNF: false, // Para que no se interpreten formatos de números
      cellStyles: false, // Para que no se interpreten estilos de celdas
      //cellDates: true, // Para manejar fechas correctamente
      defval: '' // Valor por defecto para celdas vacías
    });

      
    // const worksheet = workbook.Sheets[firstSheetName];
    // const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    //console.log("jsonData", jsonData);
    return jsonData
  }

  const fc_envio_datosV2 = async (arrData) => {
    const body = {
      vcodEmpresa : cook_idEmp,
      vlfecha : inp_text_fecha.toISOString().slice(0, 7),
      vArrDatosEn : arrData 
    }
    const data = await Apis.apiSubirArchivoDatosExcel(body)
    return data;
  }

  

  /** Cabaecera  */
  const columns = useMemo(() => [
    //{ header: 'Codigo', accessorKey: 'Codigo' },
    { header: 'Periodo', accessorKey: 'Periodo' },
    { header: 'Sucursal', accessorKey: 'Sucursal' },
    { header: 'Vendedor', accessorKey: 'Vendedor' },
    { header: 'Cober. Gral', accessorKey: 'Cobertura' },
    { header: 'Pedidos Gral', accessorKey: 'Pedidos' },
    //{ header: 'Cod. Prov ', accessorKey: 'Linea3' },
    { header: 'Nom. Prov', accessorKey: 'UnidaNegocio' },
    { header: 'Cuota', accessorKey: 'Cuota' }
  ], [])


  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }



  return (
    <div >
      <h6 className="text-muted">Comisiones carga</h6>
      <div className="card card-action">
        <div className="card-body ">
          <div className="row">
            <div className="col-lg-2">Sucursal:</div>
            <div className="col-lg-2">
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
            </div>
            <div className="col-lg-2">Periodo:</div>
            <div className="col-lg-2" style={{ zIndex: '3' }}>
              <DatePicker
                id="fecha"
                locale="es"
                className="form-control form-control-sm text-center"
                selected={inp_text_fecha}
                onChange={date => setInp_text_fecha(date)}
                dateFormat="yyyy-MM"  // Muestra solo el mes y el año
                showMonthYearPicker  // Permite seleccionar mes y año directamente
                autoComplete='off'
              />
            </div>

            <div className="col-lg-4 text-end ">
              <Tooltip title="Consultar información" placement="top">
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                  &nbsp;Consultar
                </button>
              </Tooltip>&nbsp;
              <label htmlFor="formFile" className="btn btn-sm btn-success">
                <i className='bx bx-cloud-upload bx-ms' id="btn_subirExcel_icon"></i> Migrar Formato
              </label>
              <input type="file" id="formFile" name="formFile" accept='.csv,.xlsx,.xlsm' className="form-control form-control-sm d-none" onChange={fc_cargar_excel} />
            </div>
            <div className="col-lg-2 text-center ">

              

            </div>
          </div>
          <div className="card-datatable table-responsive" style={{ zIndex: '1', }}>
            <MaterialReactTable
              columns={columns}
              data={data}
              state={{ isLoading: pending }}
              initialState={{
                density: 'compact', showColumnFilters: false,
                showGlobalFilter: false,
                columnVisibility: { clave: false, vlSema: false, },
              }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              localization={MRT_Localization_ES}
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 150 }}
              enableRowVirtualization
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 120, //make actions column wider
                },
              }}

              muiTableBodyRowProps={({ row }) => ({
                sx: { //row.getValue('Glotes') === "Y" &&
                  // backgroundColor: (row.getValue('Glotes') === "Y" && row.getValue('vlSema') === 0) ? 'rgba(255, 99, 71, 0.2)' : '',
                }
              })}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}


            />

          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  )
}

export default COMICarga