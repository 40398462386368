//import Api from './funApis'
import {
  apiConsulBonificaciones,
  apiConsulDescuentos,
  apiAgruparBonificaciones,
  apiAgruparDescuentos,
  apiConUnidadMedida,
  apiConsultarMontoMinimo,
  apiConsultarFleteZonaCanal,
  apiConsultarFleteProducto,
  apiConsultarFleteUnidadNegocio
} from './funApis'

export const consultaBonificacionYDescuentos = 
  async (productos, dtUsuario, Codigo, codigo, categoria, Zona) => {
    const { bonificacionMap, descuentoMap } =
      await obtenerBonificacionDescuento(
        productos,
        dtUsuario,
        Codigo,
        codigo
      );
    //  console.log("consultaBonificacionYDescuentos=>productos=>",productos);
    //   console.log("consultaBonificacionYDescuentos=>dtUsuario=>",dtUsuario);
    //   console.log("consultaBonificacionYDescuentos=>Codigo=>",Codigo);
    //   console.log("consultaBonificacionYDescuentos=>codigo=>",codigo);
    //obtiene grupodearticulos segun Bonificacion
    const gruposPorCodigobonificacion = 
      await agruparPorPropiedadBonificacionesAndDescuentos(
      bonificacionMap,
      'identification');
    // Array.from(gruposPorCodigobonificacion.entries()).map(([value, item]) => {
    //    console.log('gruposPorCodigobonificacion', JSON.stringify(item));
    // });
    //obtiene grupodearticulos segun Descuento
    const gruposPorCodigodescuento = 
      await agruparPorPropiedadBonificacionesAndDescuentos(
        descuentoMap,
        'identification'
      );
    // Array.from(gruposPorCodigodescuento.entries()).map(([value, item]) => {
    //    console.log('gruposPorCodigodescuento', JSON.stringify(item));
    // });
    //Obtiene lista de productos con bonificaciones
    console.log("gruposPorCodigobonificacion",gruposPorCodigobonificacion);
    const productosWithBonificaciones = 
      await cosultarBonificaciones(
        gruposPorCodigobonificacion,
        productos
      );
    //Actualiza la lista principal de productos con las bonificaciones
    productos = [...productosWithBonificaciones];
    console.log("gruposPorCodigodescuento",gruposPorCodigodescuento);
    //Obtiene lista de productos con descuentos
    const productosWithDescuentos = 
      await consultarDescuentos(
        gruposPorCodigodescuento,
        productos
      );
    //console.log('productosWithDescuentos',JSON.stringify(productosWithDescuentos));
    //actualiza lista principal de productos con descuentos
    productos = [...productosWithDescuentos];
    //console.log("productos", productos);
    const { agrupacionMap } = 
      await obtenerAgrupaciones(
        productos, 
        dtUsuario, 
        Codigo
      );
    //obtiene agrupacion de bonificaciones de bonificaciones
    //console.log("agrupacionMap0", agrupacionMap);
    const agrupacionesBonificaciones = 
      await agruparAgrupaciones(
        agrupacionMap,
        'identification'
      );
    //obtiene nueva lista de bonificaciones de bonificaciones
    const productoWithAgrupaciones = 
      await cosultarAgrupacionesBonificaciones(
        agrupacionesBonificaciones,
        productos
      );
    productos = [...productoWithAgrupaciones];
    //actualiza lista de productos con las nuevas bonificaciones de bonificaciones
    const { agrupacionDescuentoMap } = 
      await obtenerAgrupacionesDescuentos(
        productos,
        dtUsuario,
        Codigo
      );
    const gruposDeDescuentos = 
      await agruparAgrupacionesDescuentos(
        agrupacionDescuentoMap,
        'codigobonificacion'
      );
    //console.log("gruposDeDescuentos ======> ", gruposDeDescuentos);
    const productosWithAgrupacionesDescuentos = 
      await consultarAgrupacionDescuentos(
        gruposDeDescuentos, 
        productos
      );
    productos = [...productosWithAgrupacionesDescuentos];


    const {fulltotal, respDentroCond} = await validarTicketMinimo(productos, dtUsuario, categoria, Zona);
    const totalFlete = await consultarFletes(
      productos,
      dtUsuario,
      categoria,
      Zona
    );
    return {
      productos: productos,
      TicketMinimo: respDentroCond,
      totalFletes: totalFlete
    //     totalFletes + totalFletesproductos + totalFletesByUnidadNegocio,
    };
}


/** Ticket minimo */
const validarTicketMinimo = async(
  productos,
  dtUsuario,
  categoria,
  Zona,
) => {
  

  const consultaTotal = productos.map((producto) => {
    return Number(producto.total);
  });
  const respuesta = Promise.all(consultaTotal);
  const { idempresa, Sucursal, Canal} = dtUsuario;
  // console.log("idempresa", idempresa);
  // console.log("Sucursal", Sucursal);

  
  // console.log("Canal", Canal);


  
  const body = {
    idempresa: idempresa,
    sucursal: Sucursal,
    canal: Canal,
    categoria: categoria,
    zona: Zona,
  };
  //console.log("body", body);
  const [respuestaTicketMinimo] = await Promise.all([
    apiConsultarMontoMinimo(body),
  ]);
  var respDentroCond = ''
  const ticketMinimo = Number(respuestaTicketMinimo[0]?.tasa || 0);
  const fulltotal = (await respuesta).reduce((acc, curr) => acc + curr, 0);
  if (ticketMinimo > Number(fulltotal)) {
    
    console.log(
      `El valor mínimo de compra por compra es S/.${ticketMinimo}`,
    );

    respDentroCond = `El valor mínimo de compra por compra es S/.${ticketMinimo}`;

  }
  return {fulltotal, respDentroCond};
}


const consultarFletes = async(
  productos,
  dtUsuario,
  categoria,
  Zona
) => {

  const { idempresa, Sucursal, Canal } = dtUsuario;
  const consultas = productos.map(async (producto) => {
    const {
      UnidadNegocio,
      UnitPrice,
      Quantity,
      total,
      DiscountPercent,
      esDescuento,
      esBonificacion,
      ItemCode,
    } = producto;
    const body = {
      idempresa: idempresa,
      sucursal: Sucursal,
      canal: Canal,
      categoria: categoria,
      unidadnegocio: UnidadNegocio,
      zona: Zona,
      articulo: ItemCode,
    };
    //console.log('consultarFletes => body', JSON.stringify(body));

    if (esBonificacion) {
      return { sinIgv: 0, conIgv: 0 };
    }
    const [
      respuestaMontoMinimoUnidadNegocio,
      respuestaMontoMinimoZonaCanal,
      respuestaMontoMinimoProducto,
    ] = await Promise.all([
      apiConsultarFleteUnidadNegocio(body),
      apiConsultarFleteZonaCanal(body),
      apiConsultarFleteProducto(body),
    ]);
    const montoMinimoUnidadNegocio = Number(
      respuestaMontoMinimoUnidadNegocio[0]?.U_AB_FPFL || 0.001,
    );
    const montoMinimoZonaCanal = Number(
      respuestaMontoMinimoZonaCanal[0]?.Tasa || 0.001,
    );
    const montoMinimoProducto = Number(
      respuestaMontoMinimoProducto[0]?.U_AB_FPFL || 0.001,
    );
    // console.log(
    //   `montoMinimoUnidadNegocio  ${JSON.stringify(respuestaMontoMinimoUnidadNegocio)}`,
    //   montoMinimoUnidadNegocio,
    // );
    // console.log(
    //   `montoMinimoZonaCanal  ${JSON.stringify(respuestaMontoMinimoZonaCanal)}`,
    //   montoMinimoZonaCanal,
    // );
    // console.log(
    //   `montoMinimoProducto  ${JSON.stringify(respuestaMontoMinimoProducto)}`,
    //   montoMinimoProducto,
    // );
    const calculateFlete = async(flete) =>  {
      const total1 = Number(UnitPrice) * Number(Quantity);
      const discount = Number(DiscountPercent) / 100;
      const fullFlete = Number(flete) / 100;
      if (esDescuento) {
        const totalDescuento = (Number(total1) * (1 - discount)).toFixed(3);
        const dataReturnNoIgv = Number(totalDescuento) * fullFlete;
        const dataReturnIgv = Number(total) * fullFlete;
        return { sinIgv: dataReturnNoIgv, conIgv: dataReturnIgv };
      }
      const dataReturnNoIgv = Number(total1) * fullFlete;
      const dataReturnIgv = Number(total) * fullFlete;
      return { sinIgv: dataReturnNoIgv, conIgv: dataReturnIgv };
    }
    if (montoMinimoUnidadNegocio > 0.001) {
      if (montoMinimoUnidadNegocio === 0) {
        return 0;
      }
      return await calculateFlete(montoMinimoUnidadNegocio);
    }
    if (montoMinimoProducto > 0.001) {
      return await calculateFlete(montoMinimoProducto);
    }
    if (montoMinimoZonaCanal > 0.001) {
      return await calculateFlete(montoMinimoZonaCanal);
    }
    return { sinIgv: 0, conIgv: 0 };
  });
  const fletes = await Promise.all(consultas);
  // const fletesNoIgvFull = fletes.map(
  //   (flete: { sinIgv; conIgv: number }) => {
  //     return flete.sinIgv;
  //   },
  // );
  // const fletesIgvFull = fletes.map(
  //   (flete: { sinIgv: number; conIgv: number }) => {
  //     return flete.conIgv;
  //   },
  // );
  const fletesNoIgvFull = fletes.map(flete => {
    return flete.sinIgv;
  });
  
  const fletesIgvFull = fletes.map(flete => {
    return flete.conIgv;
  });
  const sinIgv = (await Promise.all(fletesNoIgvFull)).reduce(
    (acc, curr) => acc + curr,
    0,
  );
  const conIgv = (await Promise.all(fletesIgvFull)).reduce(
    (acc, curr) => acc + curr,
    0,
  );
  // totalFletes = (await respuesta).reduce((acc, curr) => acc + curr, 0);
  return { sinIgv, conIgv };
}











const arrNameBoni = {
  '1': 'AB_BUU',//Bonificación uno a uno 
  '2': 'AB_BCTC',//Bonificación ticket compra
  '3': 'AB_DCEH',//Bonificación ticket compra escala
  '4': 'AB_BOBE',//Bonificación basica escala
  '5': 'AB_DBARC',//Bonificación agrupación reglas 
};
const obtenerBonificacionDescuento = async (
  productos,
  dtUsuario,
  Codigo,
  codigo,
) => {
  console.log("productos", productos);
  const bonificacionMap = [];
  const descuentoMap = [];

  for (const producto of productos) {
    const { Base, unidadMedidaObjeto } =
      await obtenerUnidadMedidaObjetoAndValorBaseUnidadMedida(
        producto
      );
    // console.log("Base", Base);
    // console.log("unidadMedidaObjeto", unidadMedidaObjeto);
    if (
      !producto.esDescuento &&
      producto.descuentos?.length &&
      !producto.esBonificacion
    ) {
      //console.log("ingresa a descuentos");
      const descuentos = await obtenerDescuentosParaConsultar(
        producto,
        dtUsuario,
        unidadMedidaObjeto,
        Base,
        Codigo,
        codigo
      );
      descuentoMap.push(...descuentos);
    }

    // console.log("producto.bonificaciones", producto.bonificaciones);
    // console.log("producto.bonificaciones.length", producto.bonificaciones.length);
    // console.log("!producto.boniRelacionada", producto.boniRelacionada);

    // console.log("producto.bonificaciones && producto.bonificaciones.length && !producto.boniRelacionada", producto.bonificaciones &&
    //   producto.bonificaciones.length &&
    //   !producto.boniRelacionada);

    if (
      producto.bonificaciones &&
      producto.bonificaciones.length &&
      !producto.boniRelacionada
    ) {
      //console.log("ingresa a bonificaciones");
      const bonificaciones = await obtenerBonificacionesParaConsultar(
        producto,
        dtUsuario,
        unidadMedidaObjeto,
        Codigo,
        codigo
      );
      bonificacionMap.push(...bonificaciones);
    }
  }

  return { bonificacionMap, descuentoMap };
};
const obtenerBonificacionesParaConsultar = async (
  producto,
  dtUsuario,
  unidadMedidaObjeto,
  Codigo,
  codigo
) => {
  const { idempresa, idvendedor, Sucursal, Mesa, Grupo } = dtUsuario;
  //const { U_JA_CODPRECIO: tipoprecio, total, cantidad, ItemCode } = producto;
  const { tipoprecio, total, cantidad, ItemCode } = producto;
 

  const data = producto.bonificaciones.flatMap((bodyElement) => {
    const arrayBon = bodyElement.codigo.split(/[;, ]/);
    const { grupo } = bodyElement;

    return arrayBon
      .filter((arrayBonElement) => arrayBonElement !== 'null')
      .map((arrayBonElement) => { 
        // Number(cantidad) * Number(unidadMedidaObjeto.Base)
     
        return({
        identification: `${grupo}_${arrayBonElement}`,
        grupo,
        U_AB_DMCB: arrayBonElement,
        idempresa,
        idvendedor,
        tipoprecio,
        cantidad:
          grupo === '3' || grupo === '2'
            ? Number(total)
            : Number(cantidad) * Number(unidadMedidaObjeto[0].Base),
        codigobonificacion: arrayBonElement,
        cliente: Codigo,
        sucursal: Sucursal,
        Mesa: Mesa,
        Grupo: Grupo,
        cond: codigo,
        codigoProducto: ItemCode,
      })});
  });

  //console.log('bonificacion', JSON.stringify(data));
  return data;
};
const obtenerUnidadMedidaObjetoAndValorBaseUnidadMedida = async (producto) => {
  const UnidadesMedidaAlmacendado = 
    producto.arrGrupoUnidadMedida.filter((item) => item.Codigo === producto.txtUnidadMedida);
  console.log("UnidadesMedidaAlmacendado", UnidadesMedidaAlmacendado);
  //const UnidadesMedidaAlmacendado = 
  // arrGrupoUnidadMedida.filter((item) => Number(item.Codigo) === producto.ItemCode);

  if (!UnidadesMedidaAlmacendado) {
    throw new Error(`El producto: ${producto.ItemCode} no presenta stock`);
  }

  // const { Detalles } = UnidadesMedidaAlmacendado;
  // const UnidadesMedida = typeof Detalles === 'string' ? JSON.parse(Detalles) : Detalles;
  // const unidadMedidaObjeto = UnidadesMedida.find(
  //   (res) => res.Nombre === producto.unidadMedida
  // ) || { Base: '0' };

  const menorValor = UnidadesMedidaAlmacendado.reduce((menor, actual) =>
    parseFloat(menor.Base) < parseFloat(actual.Base) ? menor : actual
  );

  //console.log("menorValor", menorValor);
  return {
    Base: parseFloat('1'),
    Base2:
      parseFloat(UnidadesMedidaAlmacendado.Base) === parseFloat(menorValor.Base)
        ? parseFloat(UnidadesMedidaAlmacendado.Base)
        : parseFloat(menorValor.Base),
    unidadMedidaObjeto: UnidadesMedidaAlmacendado,
  };
};
const obtenerDescuentosParaConsultar = async (
  producto,
  dtUsuario,
  unidadMedidaObjeto,
  Base,
  Codigo,
  codigo
) => {
  //const { idempresa, Codigo: idvendedor, Sucursal, Mesa, Grupo } = dtUsuario;
  const { idempresa, idvendedor, Sucursal, Mesa, Grupo } = dtUsuario;
  //const { U_JA_CODPRECIO: tipoprecio, cantidad, total, ItemCode } = producto;
  const { tipoprecio, cantidad, total, ItemCode } = producto;
  const cantidadTotal = Number(cantidad) * Number(unidadMedidaObjeto[0].Base);
  const montoTotal = Number(total);
  const data = producto.descuentos.flatMap((bodyElement) => {
    //const arrayDes = fn.limpiandoArrays(bodyElement.codigo.split(/[;, ]/));
    const arrayDes = bodyElement.codigo.split(/[;, ]/);
    const { grupo } = bodyElement;

    return arrayDes
      .filter((arrayDesElement) => arrayDesElement !== 'null')
      .map((arrayDesElement) => {
        //console.log(`${ItemCode} => ${arrayDesElement}`);

        return {
          identification: `${grupo}_${arrayDesElement}`,
          grupo,
          idempresa,
          idvendedor,
          tipoprecio,
          cantidad: cantidadTotal,
          codigobonificacion: arrayDesElement,
          factor: Base,
          cliente: Codigo,
          sucursal: Sucursal,
          Mesa: Mesa,
          Grupo: Grupo,
          cond: codigo,
          monto: montoTotal,
          codigoProducto: ItemCode,
        };
      });
  });
  //console.log('descuento', JSON.stringify(data));
  return data;
};


const agruparPorPropiedadBonificacionesAndDescuentos = async (array, propiedad) => {
  const grupos = new Map();
   console.log("agruparPorPropiedadBonificacionesAndDescuentos - array", array);
   console.log("agruparPorPropiedadBonificacionesAndDescuentos - propiedad", propiedad);

  array.forEach((item) => {
    const valorPropiedad = item[propiedad];
    if (!grupos.has(valorPropiedad)) {
      grupos.set(valorPropiedad, { ...item });
    } else {
      const grupoExistente = grupos.get(valorPropiedad);
      grupoExistente.cantidad += item.cantidad;
      if (item.monto !== undefined && grupoExistente.monto !== undefined) {
        grupoExistente.monto += item.monto;
      }
      grupoExistente.codigoProducto += `,${item.codigoProducto}`;
    }
  });
  //console.log("agruparPorPropiedadBonificacionesAndDescuentos - grupos ",grupos);

  return grupos;
};


const cosultarBonificaciones = 
  async (
    gruposPorCodigobonificacion, 
    productos
  ) => {
  // Convertimos el objeto Map en un array de entradas
  const entradas = Array.from(gruposPorCodigobonificacion.entries());
  console.log("cosultarBonificaciones=>entradas=>", entradas);
    let datosValue = [];
    for (const [codigobonificacion, grupo] of entradas) {
      datosValue.push(grupo)
    }
    console.log("datosValue=>",datosValue);




  // Iteramos sobre las entradas con un bucle for
  for (const [codigobonificacion, grupo] of entradas) {
    try {
      // Consulta la bonificación
      const { bof, Codigo } = await consultaBonificacion(grupo);

      // Verifica los productos con bonificación
      if (bof.length) {
        const productosVerificados = await verificarProductosConBonificacion(grupo, productos);
        productos = [...productosVerificados];

        // Itera sobre cada bonificación y añade al array de productos
        for (const iterator of bof) {
          productos.push({
            ...(await obtenerBonficiacionJson(iterator, grupo, Codigo))
          });
        }
      }

    } catch (error) {
      // Maneja el error si es necesario
      console.error("Error en cosultarBonificaciones:", error);
    }
  }

  // Devuelve los productos actualizados
  return productos;
}


const consultaBonificacion = async(grupo) =>{
  //console.log("consultaBonificacion - grupo => ",grupo);
  const respuesta = await apiConsulBonificaciones(grupo)
  //console.log("consultaBonificacion => respuesta", respuesta);
  const Codigo = arrNameBoni[grupo.grupo]
  return {
    bof: respuesta,
    Codigo: Codigo,
  };

}
const verificarProductosConBonificacion = async(grupo, productosArray) =>{
  const setArticulosBonificacion = new Set(grupo.codigoProducto.split(','));
  await Promise.all(
    productosArray.map(async (producto) => {
      if (
        setArticulosBonificacion.has(producto.ItemCode) &&
        !producto.esBonificacion
      ) {
        producto.boniRelacionada = true;
        producto.U_AB_DMCB = producto.U_AB_DMCB
            ? `${producto.U_AB_DMCB},${grupo.U_AB_DMCB}`
            : `${grupo.U_AB_DMCB}`;
      }
    }),
  );
  return productosArray;
}
const obtenerBonficiacionJson = async( bof, grupo, Codigo) =>{
  //console.log("obtenerBonficiacionJson => bof", bof);
  //console.log("obtenerBonficiacionJson => grupo", grupo.idempresa);
  const resDUM = await apiConUnidadMedida(grupo.idempresa, bof.UnidadMedidaVenta)
  //
  
  const prod = {
    producto: bof.descripcion,
    cantidad: bof.CantidadBonif,
    preciounitario: Number(bof.Precio) === 0 ? '0.1' : Number(bof.Precio).toString(),
    total: '0',
    fullTotal: Number(bof.Precio) === 0 ? 0.1 : Number(bof.Precio),
    unidadMedida: bof.UnidadMedidaVenta,
    stock: '0',
    bonificaciones: [],
    esBonificacion: true,
    boniRelacionada: true,
    ItemCode: bof.codigo,
    Quantity: bof.CantidadBonif,
    UnitPrice: Number(bof.Precio) === 0 ? '0.1' : Number(bof.Precio).toString(),
    TaxCode: bof.CodigoImpuesto,
    WarehouseCode: bof.AlmacenDefecto,  
    UoMEntry: bof.UnidadMedidaVenta,
    U_JA_CODPRECIO: grupo.tipoprecio,
    DiscountPercent: '100',
    U_AB_DMCB: grupo.U_AB_DMCB,
    U_AB_OVOB: Codigo,
    tipoBonificacion: grupo.grupo,
    GrupoBonificacion: bof.CodGrupo,
    consultGrupoBonificacion: false,

    txtCodArticulo: { Code:bof.codigo,Name:bof.descripcion },
    arrArticulo: [{CodigoImpuesto:bof.CodigoImpuesto}],
    arrfileEscDPrecio: [{Nombre:grupo.tipoprecio}],
    txtCantidad: bof.CantidadBonif,
    //Stock: '0',
    //arrUMSelecionad: [{Nombre:'validar',}],
    arrUMSelecionad: [{Nombre:resDUM[0].Code,}],
    txtPrecioUnitario: Number(bof.Precio) === 0 ? '0.1' : Number(bof.Precio).toString(),
    txtTotal: Number(bof.Precio) === 0 ? 0.1 : Number(bof.Precio),
    txtTotalSinDesc: 0,
    txtTotalSolDesc: 0,
    txtfullTotal: Number(bof.Precio) === 0 ? 0.1 : Number(bof.Precio),
    txtUnidadMedida: bof.UnidadMedidaVenta,
    txtCodAlmacen: bof.AlmacenDefecto,
    tipoprecio: grupo.tipoprecio
  };
  return prod;
}






const consultarDescuentos = 
  async (
    gruposPorCodigodescuento, 
    productos
  ) => {
  const descuentosPorAplicar = [];
  
  // Convertimos el objeto Map en un array de entradas
  const entradas = Array.from(gruposPorCodigodescuento.entries());
  console.log("consultarDescuentos=>entradas=>", entradas);

  let datosValue = [];
  for (const [codigodescuento, grupo] of entradas) {
    datosValue.push(grupo)
  }
  console.log("datosValue=>",datosValue);
  
  // Iteramos sobre las entradas con un bucle for
  for (const [codigodescuento, grupo] of entradas) {
    try {
      const respuesta = await consultaDescuento(grupo);
      if (respuesta.length > 0) {
        const listaArticulosDescuento = 
          new Set(grupo.codigoProducto.split(','));

        for (const producto of productos) {
          if (listaArticulosDescuento.has(producto.ItemCode) && !producto.esBonificacion) {
            for (const iterator of respuesta) {
              const descuento = {
                ...iterator,
                Producto: producto.ItemCode,
                grupo: grupo.grupo,
              };
              descuentosPorAplicar.push(descuento);
            }
          }
        }
      }
    } catch (error) {
      // Maneja el error si es necesario
      console.error("Error en consultarDescuentos:", error);
    }
  }
  
  // Agrupa los descuentos aplicados a los productos
  const productosyacondescuentos = await agruparDescuentos(descuentosPorAplicar, productos);
  
  productos = [...productosyacondescuentos];
  return productos;
}


const consultaDescuento = async(grupo) => {
  //console.log("consultaDescuento - grupo => ", grupo);
  const respuesta = await apiConsulDescuentos(grupo);
  return respuesta;
}
const agruparDescuentos = async(listaDescuentos,productosArray) =>{
  //console.log("listaDescuentos => ", listaDescuentos);
  //console.log("productosArray => ", productosArray);
  for (const descuento of listaDescuentos) {
    const index = productosArray.findIndex(
      (producto) => producto.ItemCode === descuento.Producto,
    );
    if (index !== -1) {
      const producto = productosArray[index];
      if (!producto.esDescuento && !producto.esBonificacion) {
        // Código descuento
        producto.U_AB_DMCB = producto.U_AB_DMCB
          ? `${producto.U_AB_DMCB},${descuento.CodigoDesc}`
          : `${descuento.CodigoDesc}`;

        // Udo
        producto.U_AB_OVOB = producto.U_AB_OVOB
          ? `${producto.U_AB_OVOB},${descuento.Udo}`
          : `${descuento.Udo}`;

        // Porcentaje De descuento
        producto.descuentosINString = producto.descuentosINString
          ? `${producto.descuentosINString},${descuento.Descuento}`
          : `${descuento.Descuento}`;

        producto.DiscountPercent = producto.DiscountPercent
          ? (
            Number(producto.DiscountPercent) + Number(descuento.Descuento)
          ).toString()
          : Number(descuento.Descuento).toString();

        // Tipos de bonificacion
        producto.tipoBonificacion = producto.tipoBonificacion
          ? `${producto.tipoBonificacion},${descuento.grupo}`
          : `${descuento.grupo}`;

        const descuentoParaLista = {
          ...descuento,
          Producto: producto.ItemCode,
        };
        producto.ListaDescuento = producto?.ListaDescuento?.length
          ? [...producto?.ListaDescuento, descuentoParaLista]
          : [...[descuentoParaLista]];

        producto.aplicaGrupoDescuento = descuento.AplicaGrupo;
        //descuento en json para enviar a la api final
        productosArray[index] = { ...producto };
      }
    }

  }
  productosArray.forEach((producto) => {
    if (
      producto.DiscountPercent &&
      !producto.esDescuento &&
      !producto.esBonificacion
    ) {
      console.log("producto ========>> " , producto);
      console.log("producto.esDescuento ========>> " , producto.esDescuento);
      const antdTotal = producto.txtTotal;
      const discount = Number(producto.DiscountPercent) / 100;
      const dTotal = (Number(producto.total) * (1 - discount)).toFixed(3);
      producto.txtTotal = dTotal;
      producto.txtTotalSinDesc = antdTotal;
      producto.txtTotalSolDesc = producto.DiscountPercent;
      // Valida si es descuento
      producto.esDescuento = true;
    }
  });
  return productosArray;
}
const obtenerAgrupaciones = async(productos,dtUsuario,Codigo) => {
 // const { idempresa, Codigo: idvendedor, Sucursal } = dtUsuario;
  const { idempresa, idvendedor, Sucursal } = dtUsuario;
  const agrupacionMap = [];
  await Promise.all(
    productos.map(async (producto) => {
      //const { arrfileEscDPrecio, txtCantidad } = producto;
      //const { U_JA_CODPRECIO: tipoprecio, cantidad } = producto;
      //console.log("producto => ", producto);
      const { tipoprecio,  cantidad } = producto;
      //console.log("tipoprecio", tipoprecio);
      //console.log("cantidad", cantidad);
      //console.log("U_JA_CODPRECIO", U_JA_CODPRECIO);
      // const U_JA_CODPRECIO = arrfileEscDPrecio[0].Nombre
        if (producto.GrupoBonificacion && !producto.consultGrupoBonificacion) {
          producto.GrupoBonificacion.split(/[;, ]/).flatMap(
            (bodyElement, index) => {
              const agrupaciones = {
                identification: `${index}_${bodyElement}`,
                idempresa: idempresa,
                idvendedor: idvendedor,
                tipoprecio: tipoprecio,
                cantidad: cantidad,
                codigobonificacion: bodyElement,
                cliente: Codigo,
                codigoAgrupacion: producto.U_AB_DMCB,
                grupo: bodyElement,
                sucursal: Sucursal,
                codigoProducto: producto.ItemCode,
              };
              agrupacionMap.push(agrupaciones);
            },
          );
        }
    })
  )
  //console.log("agrupacionMap", agrupacionMap);
  return { agrupacionMap: agrupacionMap };
}
const agruparAgrupaciones = async(array,propiedad) => {
  //const grupos = [] <string, Item>
  const grupos = new Map();
  array.forEach((item) => {
    //console.log("item", item);
    item.cantidad = Number(item.cantidad);
    //console.log("propiedad", propiedad);
    const valorPropiedad= item[propiedad];
    //console.log("valorPropiedad", valorPropiedad);
    if (!grupos.has(valorPropiedad)) {
      grupos.set(valorPropiedad, { ...item });
    } else {
      const grupo = grupos.get(valorPropiedad);
      grupo.cantidad += item.cantidad;
      grupo.codigoAgrupacion += `,${item.codigoAgrupacion}`;
      grupo.codigoProducto += `,${item.codigoProducto}`;
      grupo.codigoAgrupacion = grupo
        .codigoAgrupacion.split(',')
        .map(Number)
        .sort((a, b) => a - b)
        .join('');
    }
  })
  //console.log("agruparAgrupaciones => grupos", grupos);
  return grupos;
}





const cosultarAgrupacionesBonificaciones = async(gruposPorCodigoAgrupacion,productos) => {
  await Promise.all(
    Array.from(gruposPorCodigoAgrupacion.entries()).map(
      async ([codigobonificacion, grupo]) => {
        try {
          //console.log("cosultarAgrupacionesBonificaciones => grupo", grupo);
          const bof = await apiAgruparBonificaciones(grupo);
          //console.log("cosultarAgrupacionesBonificaciones => bot", bof);
          if (bof?.length) {
            const productosVerificados =
              await verificarBonificacionConBonificacion(
                grupo,
                productos,
              );
            productos = [...productosVerificados];
            for (const iterator of bof) {
              productos.push({
                ...( obtenerBonficiacionGrupoJson(iterator, grupo)),
              });
            }
          } else {
            new Set();
            //console.log('grupo', JSON.stringify(grupo));
            const fueraBonificacion = productos.filter((productos) => {
              return (Number(productos.GrupoBonificacion) !== Number(grupo.grupo));
            });
            console.log( 'fueraBonificacion=> ', console.log(JSON.stringify(fueraBonificacion)), );

            productos = [...fueraBonificacion];
            await Promise.all(
              productos.map((producto) => {
                Object.assign(producto, {
                  boniRelacionada: false,
                  total: producto.fullTotal ? producto.fullTotal : producto.total,
                  U_AB_DMCB: '',
                  U_AB_OVOB: '',
                  DiscountPercent: '',
                  esDescuento: false,
                  tipoBonificacion: '',
                  GrupoBonificacion: '',
                  consultGrupoBonificacion: false,
                  ListaDescuento: [],
                  descuentosINString: '',
                  consultGrupoDescuento: false,
                  aplicaGrupoDescuento: '',
                });
              }),
            );
          }
        } catch (e) { return null }
      }
    )
  )
  return productos;
}
const verificarBonificacionConBonificacion = async(grupo,productosArray) => {
  const setArticulosBonificacion = new Set(grupo.codigoProducto.split(','));
  await Promise.all(
    productosArray.map(async (producto) => {
      if (
        setArticulosBonificacion.has(producto.ItemCode) &&
        !producto.consultGrupoBonificacion
      ) {
        producto.consultGrupoBonificacion = true;
      }
    }),
  );
  const fueraBonificacion = productosArray.filter((productos) => {
    return (
      // setArticulosBonificacion.has(productos.ItemCode) &&
      Number(productos.GrupoBonificacion) !== Number(grupo.grupo)
    );
  });
  productosArray = [...fueraBonificacion];
  return productosArray;
}
const obtenerBonficiacionGrupoJson = (bof, grupo) => {
  const prod = {
    producto: bof.descripcion,
    cantidad: bof.CantidadBonif,
    preciounitario: Number(bof.Precio) === 0 ? '0.1' : Number(bof.Precio).toString(),
    total: '0',
    fullTotal: Number(bof.Precio) === 0 ? 0.1 : Number(bof.Precio),
    unidadMedida: bof.UnidadMedidaVenta,
    stock: '0',
    bonificaciones: [],
    esBonificacion: true,
    boniRelacionada: true,
    ItemCode: bof.codigo,
    Quantity: bof.CantidadBonif,
    UnitPrice: Number(bof.Precio) === 0 ? '0.1' : Number(bof.Precio).toString(),
    TaxCode: bof.CodigoImpuesto,
    WarehouseCode: bof.AlmacenDefecto,
    UoMEntry: bof.UnidadMedidaVenta,
    U_JA_CODPRECIO: grupo.tipoprecio,
    DiscountPercent: '100',
    //U_AB_DMCB: grupo.U_AB_DMCB,
    U_AB_DMCB: grupo.codigobonificacion,
    U_AB_OVOB: 'AB_DBARC',
    //tipoBonificacion: grupo.grupo,
    tipoBonificacion: '6',
    //TODO
    // GrupoBonificacion: '100',
    GrupoBonificacion: bof.CodGrupo,
    consultGrupoBonificacion: true,
    
    txtCodArticulo: { Code:bof.codigo,Name:bof.descripcion },
    arrArticulo: [{CodigoImpuesto:bof.CodigoImpuesto}],
    arrfileEscDPrecio: [{Nombre:grupo.tipoprecio}],
    txtCantidad: bof.CantidadBonif,
    //Stock: '0',
    arrUMSelecionad: [{Nombre:'validar',}],
    txtPrecioUnitario: Number(bof.Precio) === 0 ? '0.1' : Number(bof.Precio).toString(),
    txtTotal: Number(bof.Precio) === 0 ? 0.1 : Number(bof.Precio),
    txtTotalSinDesc: 0,
    txtTotalSolDesc: 0,
    txtfullTotal: Number(bof.Precio) === 0 ? 0.1 : Number(bof.Precio),
    txtUnidadMedida: bof.UnidadMedidaVenta,
    txtCodAlmacen: bof.AlmacenDefecto,
    tipoprecio: grupo.tipoprecio
  };
  return prod;
}






const obtenerAgrupacionesDescuentos = async( productos, dtUsuario, Codigo ) => {
  // console.log("obtenerAgrupacionesDescuentos - productos => ", productos);
  // console.log("obtenerAgrupacionesDescuentos - dtUsuario => ", dtUsuario);
  // console.log("obtenerAgrupacionesDescuentos - Codigo => ", Codigo);
  //const { idempresa, Codigo: idvendedor, Sucursal } = dtUsuario;
  const { idempresa, idvendedor, Sucursal } = dtUsuario;
  
  const agrupacionMap = [];
  await Promise.all(
    productos.map(async (producto) => {
      const { tipoprecio, cantidad } = producto;
      const U_JA_CODPRECIO = tipoprecio;
      
      if (
        producto?.ListaDescuento?.length &&
        !producto.consultGrupoDescuento
      ) {
        producto?.ListaDescuento?.map((descuento) => {
          const item = {
            codigoAgrupacion: descuento.CodigoDesc,
            codigobonificacion: descuento.CodGrupo,
            tipoprecio: producto.U_JA_CODPRECIO,
            articulo: producto.ItemCode,
            idempresa: idempresa,
          };
          agrupacionMap.push(item);
        });
      }
    }),
  );
  return { agrupacionDescuentoMap: agrupacionMap };
}


const agruparAgrupacionesDescuentos = async(array, propiedad) => {
  // console.log("agruparAgrupacionesDescuentos - array =>>>> ",array);
  // console.log("agruparAgrupacionesDescuentos - propiedad =>>>> ",propiedad);
  const grupos = new Map();
  array.forEach((item) => {
    const valorPropiedad = item[propiedad];
    if (!grupos.has(valorPropiedad)) {
      grupos.set(valorPropiedad, { ...item });
    } else {
      const grupo = grupos.get(valorPropiedad);
      grupo.codigoAgrupacion += `,${item.codigoAgrupacion}`;
      grupo.articulo += `,${item.articulo}`;
      grupo.codigoAgrupacion = grupo
        .codigoAgrupacion.split(',')
        .map(Number)
        .sort((a, b) => a - b)
        .join('');
    }
  });
  return grupos;
}


const consultarAgrupacionDescuentos = async(gruposPorCodigoAgrupacion,productos) =>{
  Array.from(gruposPorCodigoAgrupacion.entries()).map(
    async ([codigobonificacion, grupo]) => {
      try {
        //console.log("consultarAgrupacionDescuentos - grupo => ", grupo);
        const { bof } = await consultaCallAPIGrupoDescuentos(grupo);
        if (bof?.length) {
          const productosVerificados = await verificarBonificacionConDescuentos(grupo,productos,bof[0],);
          productos = [...productosVerificados];
        } else {
          const setArticulosBonificacion = new Set(grupo.articulo.split(','));
          await Promise.all(
            productos.map((producto) => {
              if (
                setArticulosBonificacion.has(producto.ItemCode) &&
                !producto.consultGrupoDescuento &&
                producto.aplicaGrupoDescuento === 'Y'
              ) {
                console.log(
                  'NO HAY GRUPO DE BONIFICACION',
                  JSON.stringify(grupo),
                );
                Object.assign(producto, {
                  boniRelacionada: false,
                  total: producto.fullTotal
                    ? producto.fullTotal
                    : producto.total,
                  U_AB_DMCB: '',
                  U_AB_OVOB: '',
                  DiscountPercent: '',
                  esDescuento: false,
                  tipoBonificacion: '',
                  GrupoBonificacion: '',
                  consultGrupoBonificacion: false,
                  ListaDescuento: [],
                  descuentosINString: '',
                  consultGrupoDescuento: false,
                  aplicaGrupoDescuento: '',
                });
              }
            }),
          );
        }
      } catch (e) { }
    },
  );
  return productos;
}


const consultaCallAPIGrupoDescuentos = async(body) => {
  //console.log("consultaCallAPIGrupoDescuentos - body => ", body);
  const respuesta = await apiAgruparDescuentos(body);
  return { bof: respuesta };
}
const verificarBonificacionConDescuentos = async(grupo,productosArray,bonificacion) => {
  const setArticulosBonificacion = new Set(grupo.articulo.split(','));
  await Promise.all(
    productosArray.map(async (producto) => {
      if (
        setArticulosBonificacion.has(producto.ItemCode) &&
        !producto.consultGrupoDescuento
      ) {
        producto.total = producto.fullTotal.toFixed(3);
        producto.U_AB_DMCB = bonificacion.codigo;
        producto.U_AB_OVOB = bonificacion.UDO;
        producto.DiscountPercent = bonificacion.Descuento;
        producto.tipoBonificacion = '';
        producto.ListaDescuento = producto?.ListaDescuento?.filter(
          (descuentito) =>
            !setArticulosBonificacion.has(descuentito.Producto),
        );
        producto.total = (
          Number(producto.total) -
          Number(producto.total) * (Number(bonificacion.Descuento) / 100)
        ).toFixed(3);
        producto.consultGrupoDescuento = true;
      }
    }),
  );
  return productosArray;


}