import React from 'react'
//import {Page, Text, Document, StyleSheet, View } from "@react-pdf/renderer"
import { Page, Text, Image, Document, StyleSheet, View, Font } from "@react-pdf/renderer"
import logo_ab from '../../assets/img/logo/logo_ab.png';
import moment from 'moment';
// Font.register({ family: 'Roboto', src: source });
Font.registerEmojiSource({
    format: 'png',
    url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const styles = StyleSheet.create({

    page: { padding: 30 },
    title: { fontSize: 14, marginBottom: 10 },
    subtitle: { fontSize: 12, marginTop: 15, marginBottom: 5 },
    // 'Helvetica-Bold' Texto a utilizar 
    table: { width: '100%', fontSize: 6, border: '1px solid #000000', borderRadius: 3, borderStyle: 'solid', borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 },
    row: { flexDirection: 'row' },
    rowEven: { backgroundColor: '#f0f0f0' },
    // rowHeader: { backgroundColor: '#e0e0e0', fontWeight: 'bold' },
    rowHeader: { backgroundColor: '#ffffff', fontWeight: 'bold', borderStyle: 'double' },
    // rowFooter: { backgroundColor: '#c0c0c0', fontStyle: 'italic' },
    rowFooter: { backgroundColor: '#ffffff', fontStyle: 'italic' },
    column: { borderStyle: 'solid', borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    cell: { padding: 5, fontSize: 6 , textAlign: 'center'},
    //cell: { padding: 5, fontSize: 8, fontFamily: 'Helvetica-Bold' , textAlign: 'center'},
    cellHeader: { fontWeight: '900', fontFamily: 'Helvetica-Bold', fontSize: 7 },
    cellCenter: { textAlign: 'center' },
    cellRight: { textAlign: 'right' },
    cellLeft: { textAlign: 'left' },
    
    pageBreak: { flexBasis: '100%', height: 0 },
    break: {
        pageBreakBefore: 'always',
    },
    wrapText: { flexWrap: 'wrap', width: '100%' },
});
const insertSpaces = (text, chunkSize) => {
    const regex = new RegExp(`.{1,${chunkSize}}`, 'g');
    const matches = text.match(regex);
    return matches ? matches.join(' ') : text;
};

const TableCell = ({ children, style = {}, rowSpan = 1, colSpan = 1 }) => (
    <Text style={[styles.cell, style]} colSpan={colSpan}>{children}</Text>
);

const TableRow = ({ children, isHeader = false, isEven = false, isFooter = false }) => (
    <View style={[styles.row, isHeader && styles.rowHeader, isEven && styles.rowEven, isFooter && styles.rowFooter]}>{children}</View>
);

const TableColumn = ({ children, width = '25%' }) => (
    <View style={[styles.column, { width }]}>{children}</View>
);

const Table = ({ children }) => <View style={styles.table}>{children}</View>;

// copia de seguridad
const PdfAlmacenT00 = ({ datallegada, datallegadapri, datallegadadoc }) => {

    const groupBy = (data, key) => {
        return data.reduce((result, item) => {
            const groupKey = item[key];
            if (!result[groupKey]) {
                result[groupKey] = [];
            }
            result[groupKey].push(item);
            return result;
        }, {});
    };

    const groupedByCardName = groupBy(datallegada, 'CardName');

    //const  i = 1;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Text style={{ fontSize: 20, marginBottom: 10 }}> <Image styles={styles.image} src={logo_ab} />Hola mundo</Text>

                {/* <View styles={styles.div_1}>
                        <Text>Fecha emision: {datallegadapri.FechaEmision}</Text>
                    </View> */}
                <Table>
                    <TableRow isHeader>
                        <TableColumn><TableCell style={styles.cellHeader}>CODIGO</TableCell></TableColumn>
                        <TableColumn width="60%"><TableCell style={styles.cellHeader}>DESCIPCIÓN</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>LOTE</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>UNID.COM.MED.</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>CANTIDAD</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>UNID.INVE.UNIT</TableCell></TableColumn>
                        <TableColumn><TableCell style={styles.cellHeader}>CANTIDAD</TableCell></TableColumn>
                    </TableRow>
                    {Object.keys(groupedByCardName).map((cardName) => {
                        const groupedByItmsGrpNam = groupBy(groupedByCardName[cardName], 'ItmsGrpNam');
                        return (
                            <>
                                <TableRow>
                                    <TableColumn width="100%">
                                        <TableCell style={{ backgroundColor: '#f0f0f0', textAlign: 'LEFT', fontWeight: 'bold' }} >
                                            {cardName}
                                        </TableCell>
                                    </TableColumn>
                                </TableRow>
                                {Object.keys(groupedByItmsGrpNam).map((itmsGrpNam) => (
                                    <>
                                        <TableRow>
                                            <TableColumn width="100%">
                                                <TableCell style={{ backgroundColor: '#d0d0d0', textAlign: 'LEFT', fontWeight: 'bold' }} >
                                                    {itmsGrpNam}
                                                </TableCell>
                                            </TableColumn>
                                        </TableRow>
                                        {groupedByItmsGrpNam[itmsGrpNam].map((item, index) => (
                                            <TableRow key={index} >
                                                <TableColumn><TableCell>{item.ItemCode}</TableCell></TableColumn>
                                                <TableColumn width="60%"><TableCell>{item.ItemName}</TableCell></TableColumn>
                                                <TableColumn><TableCell>{item.Lote}</TableCell></TableColumn>
                                                <TableColumn><TableCell>{item.Unid1}</TableCell></TableColumn>
                                                <TableColumn><TableCell>{item.Q1}</TableCell></TableColumn>
                                                <TableColumn><TableCell>{item.InvntryUom}</TableCell></TableColumn>
                                                <TableColumn><TableCell>{item.Q2}</TableCell></TableColumn>
                                            </TableRow>
                                        ))}
                                    </>
                                ))}
                                <View style={styles.break} />
                            </>
                        );
                    })}
                </Table>

            </Page>

        </Document>
    )
};

const PdfAlmacenT001 = ({ datallegada, detalleRepotPDF, datallegadapri, datallegadadoc }) => {

    const groupBy = (data, key) => {
        return data.reduce((result, item) => {
            const groupKey = item[key];
            if (!result[groupKey]) {
                result[groupKey] = [];
            }
            result[groupKey].push(item);
            return result;
        }, {});
    };

    const groupedByCardName = groupBy(datallegada, 'CardName');

    //const  i = 1;
    return (
        <Document>
            {Object.keys(groupedByCardName).map((cardName, index) => {
                const groupedByItmsGrpNam = groupBy(groupedByCardName[cardName], 'ItmsGrpNam');

                return (
                    <>
                        <Page size="A4" style={styles.page}>
                            {(index === 0 &&
                                <Text style={{ fontSize: 12, marginBottom: 10, fontFamily: 'Helvetica-Bold' }}> <Image styles={styles.image} src={logo_ab} /> R001 - SALIDAD DE PRODUCTOS DE ALMACEN </Text>

                            )}

                            <View styles={styles.div_1}>
                                {/* <Text style={{ fontSize: 10, marginBottom: 10 }} >UNIDAD DE NEGOCIO: {cardName}</Text> */}
                                <Text style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start',  fontFamily: 'Helvetica-Bold' , fontSize: 7}}>
                                    <Text style={{ fontFamily: 'Helvetica-Bold' }}>UNIDAD DE NEGOCIO:</Text>
                                    <Text style={{ fontFamily: 'Helvetica' }} >  {cardName}</Text>
                                    

                                </Text>
                                <Text style={{ fontFamily: 'Helvetica-Bold',fontSize: 8, }}>ALMACEN: &nbsp;&nbsp;&nbsp;&nbsp;{detalleRepotPDF.sucursal}</Text>
                                    <Text style={{ fontFamily: 'Helvetica-Bold',fontSize: 8, }}>FECHA: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{moment(detalleRepotPDF.fecha).format('YYYY-MM-DD')} </Text>
                                    <Text style={{ fontFamily: 'Helvetica-Bold',fontSize: 8, }}>CONSOLIDADO :&nbsp;&nbsp;&nbsp;&nbsp;{detalleRepotPDF.consolidado}</Text>
                                    {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
                                    {/* <TableRow>
                                            <TableColumn width="100%">
                                                <TableCell style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start',  fontFamily: 'Helvetica-Bold' , fontSize: 7}} >
                                                ALMACEN:{detalleRepotPDF.sucursal}  &nbsp;&nbsp;&nbsp;&nbsp; FECHA:{moment(detalleRepotPDF.fecha).format('YYYY-MM-DD')} 
                                                </TableCell>
                                            </TableColumn>
                                        </TableRow> */}
                                {/* <Text style={{ fontSize: 8, marginBottom: 2 }}>Fecha emision: {moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}</Text> */}

                                {/* <Text style={{ fontSize: 10, marginBottom: 10 }}>
                                    <Text style={{ fontFamily: 'Courier-Bold' }}>UNIDAD DE NEGOCIO:</Text>
                                    <Text> {cardName}</Text>
                                </Text>
                                <Text style={{ fontSize: 10, marginBottom: 10 }}>
                                    <Text style={{ fontFamily: 'Times-Bold' }}>UNIDAD DE NEGOCIO:</Text>
                                    <Text> {cardName}</Text>
                                </Text> */}
                                {/* <Text style={{ fontSize: 5, marginBottom: 10 }}>Reporte de liquidacion </Text> */}

                                {/* <Text>UNIDAD DE NEGOCIO: {groupedByCardName}</Text> */}

                            </View>

                            <View>
                                <Text style={{ fontSize: 8, marginBottom: 10 }}>
                                    <Text style={{ fontFamily: 'Helvetica-Bold'}}>IMPRESO: {moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}</Text>
                                    {/* <Text> {moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}</Text> */}
                                </Text>
                            </View>                            <Table>
                                <TableRow isHeader>
                                    <TableColumn><TableCell style={styles.cellHeader}>CODIGO</TableCell></TableColumn>
                                    <TableColumn width="50%"><TableCell style={[styles.cellHeader]}>DESCIPCIÓN</TableCell></TableColumn>
                                    <TableColumn><TableCell style={styles.cellHeader}>LOTE</TableCell></TableColumn>
                                    <TableColumn><TableCell style={styles.cellHeader}>UNID.COM.MED.</TableCell></TableColumn>
                                    <TableColumn><TableCell style={styles.cellHeader}>CANTIDAD</TableCell></TableColumn>
                                    <TableColumn><TableCell style={styles.cellHeader}>UNID.INVE.UNIT</TableCell></TableColumn>
                                    <TableColumn><TableCell style={styles.cellHeader}>CANTIDAD</TableCell></TableColumn>
                                </TableRow>


                                <TableRow>
                                    <TableColumn width="100%">
                                        <TableCell style={{ backgroundColor: '#f9f9f9', textAlign: 'LEFT', fontFamily: 'Helvetica-Bold',fontSize: 7 }} >
                                            {cardName}
                                        </TableCell>
                                    </TableColumn>
                                </TableRow>
                                {Object.keys(groupedByItmsGrpNam).map((itmsGrpNam) => (
                                    <>
                                        <TableRow>
                                            <TableColumn width="100%">
                                                <TableCell style={{  textAlign: 'LEFT', fontWeight: 'bold' , fontSize: 7}} >
                                                    {itmsGrpNam}
                                                </TableCell>
                                            </TableColumn>
                                        </TableRow>
                                        {groupedByItmsGrpNam[itmsGrpNam].map((item, index) => (
                                            <TableRow key={index} >
                                                <TableColumn><TableCell>{item.ItemCode}</TableCell></TableColumn>
                                                <TableColumn width="50%"><TableCell style={styles.cellLeft}>{item.ItemName}</TableCell></TableColumn>
                                                <TableColumn><TableCell>{item.Lote}</TableCell></TableColumn>
                                                <TableColumn ><TableCell style={styles.cellLeft}>{insertSpaces(item.Unid1, 9)}</TableCell></TableColumn>
                                                <TableColumn><TableCell>{item.Q1}</TableCell></TableColumn>
                                                <TableColumn><TableCell>{item.InvntryUom}</TableCell></TableColumn>
                                                <TableColumn><TableCell>{item.Q2}</TableCell></TableColumn>
                                            </TableRow>
                                        ))}
                                    </>
                                ))}

                            </Table>

                        </Page>
                    </>
                )
            })}
        </Document>
    )
};
export default PdfAlmacenT001