
import React, { useMemo, useState } from 'react';
import 'react-data-table-component-extensions/dist/index.css';
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales';
import { Box, Modal } from '@mui/material';
import { ModalDataClientsAndLeads } from '../../components/Modals/ModalsDetVen';
import { notify_warning } from '../../components/utils';
import { ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';
import { MaterialReactTable } from 'material-react-table';
import { AButton } from '../../components/Button';
import { createTheme, ThemeProvider } from '@mui/material';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import Swal from 'sweetalert2';
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const globalTheme = window.templateCustomizer.settings.style;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.default',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const VistaPrincipalLeads = () => {
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [pending, setpending] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [code, setCode] = useState('');
  const handleOpen = async (data) => {
    const fechtApi = async (CardCode) => {
      const url = `apiGetLeadOrClientsDistribucionByCode?codigo=${CardCode}`;
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
      const response = await fetch(url, opPe);
      const data = await response.json();
      return data;
    };
    setCode(await fechtApi(data.CardCode));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };
  const columns = useMemo(() => [
    { header: "Codigo", accessorKey: "CardCode" },
    { header: "Nombre", accessorKey: "CardName" },
    { header: "Fecha de ingreso", accessorKey: "CreateDate", accessorFunction: formatDate },
    { header: "Condiciones de pago", accessorKey: "PymntGroup" },
    { header: "Categoria", accessorKey: "GroupName" },
  ], []);

  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }

  const listar = async () => {
    if (!emp_sl_t_suc || emp_sl_t_suc === '9999') {
      notify_warning('Seleccione una sucursal');
      return;
    }
    setpending(true);
    const url_lstClientes = `/apiLeadsDistribucion?vcodSucursal=${emp_sl_t_suc}&vcodEmpresa=${cook_idEmp}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    if (data.length === 0) {

      notify_warning("Sin leads registrados")
    }
    const formattedData = data.map(item => ({
      ...item,
      CreateDate: formatDate(item.CreateDate),
    }));
    setFilteredData(formattedData);
    setpending(false);
  }

  const setDataReturn = async (data) => {
    listar();
    handleClose();
  }
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [],
  );

  const deleteLead = async (data) => {
    'deleteClientes'
    const dataModal = await Swal.fire({
      title: 'ADVERTENCIA',
      text: '¿Desea elimnar este lead?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Si',
    })
    if (dataModal.isConfirmed) {
      console.log(data);
      const url_lstClientes = `/deleteClientes?vcodEmpresa=${cook_idEmp}&codigoCliente=${data.CardCode}`;
      const opPe = { method: "DELETE", headers: { 'Content-Type': 'application/json' } };
      const response = await fetch(url_lstClientes, opPe);
      const dataResponse = await response.json();
      if (dataResponse?.status === 400) {
        return notify_warning("No se puede eliminar lead");
      }
      listar();
    }
  }

  return (
    <>
      <ThemeProvider theme={tableTheme}>
        <div className="d-flex">
          <div className="col-lg-1">Sucursal:</div>
          <div className="col-lg-2">
            <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
          </div>
          <div className="col-lg-2">
            <div className="text-center mb-3">
              <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={listar}>
                <i className='bx bx-list-ul' ></i>&nbsp;Consultar
              </button>
            </div>
          </div>
        </div>
        <div className="card-body ">
          <MaterialReactTable
            columns={columns}
            data={filteredData}
            enablePagination={true}
            enableRowActions
            enableGrouping={true}
            state={{ isLoading: pending }}
            localization={MRT_Localization_ES}
            renderRowActions={({ row }) => (
              <>
                <div className='col'>
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '2px' }}>
                    <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-label-warning btn-icon btn-sm" ic_class="bx bxs-edit bx-ms" bt_funct={() => handleOpen(row.original)}
                      id={JSON.stringify(row.original)} vl_tpbtn={1} />

                    <AButton tp_tilde="Eliminar" tp_posit="top" bt_class="btn btn-label-danger btn-icon btn-sm" ic_class="bx bxs-trash bx-ms" bt_funct={() => deleteLead(row.original)}
                      id={JSON.stringify(row.original)} vl_tpbtn={1} />
                  </Box>
                </div>
              </>
            )}
            muiCircularProgressProps={{ color: 'error' }}
            muiSkeletonProps={{
              animation: 'pulse',
              height: 28,
            }}
          />
          <Modal
            open={open}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: '85%', height: '90%', overflow: 'auto' }}>
              <button
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}
                className='btn btn-outline-dark btn-sm'
                onClick={handleClose}
              >
                <i className='fa fa-x'></i>
              </button>
              <ModalDataClientsAndLeads tipo={'lead'} surcusal={emp_sl_t_suc} code={code} dataReturn={setDataReturn} />
            </Box>
          </Modal>
        </div>
      </ThemeProvider>
    </>
  );
}

const GPDistribucion = () => {
  const renderMap = () => {
    return (
      <div className='d-flex flex-column'>
        <h6 className="text-muted">Leads</h6>
        <VistaPrincipalLeads />
        <ToastContainer />
      </div >
    );
  };

  return renderMap();
}

export default GPDistribucion