import React, { useRef } from 'react';
import generatePDF, { Options } from "react-to-pdf";
import { useLocation } from 'react-router-dom';


// Devolucion 
export const RepViewAlmacen = (prosp) => {
  const options = {
    filename: "R002_DEVOLUCION DE MERCANCIAS POR PRODUCTO V2.pdf",
      page: {
        margin: {
          top: 5,
          bottom: 10,
          left: 4,
          right: 4
        }
      }
  };
  const targetRef = useRef();
  const downloadPdf = () => generatePDF(targetRef, options);
  // const location = useLocation();
  // const { data } = location.state || {};
  // console.log("dataRecvidad", data);
  console.log("prosp", prosp);
  console.log("prosp.data", prosp.data);
  // Función para agrupar datos por clave
  const groupBy = (data, key) => {
    return data.reduce((result, item) => {
        const groupKey = item[key];
        if (!result[groupKey]) {
            result[groupKey] = [];
        }
        result[groupKey].push(item);
        return result;
    }, {});
  };

  const groupedByCardName = groupBy(prosp.data, 'CardName');

  return (
    <div>
<div className="row ">
        <div className="col-lg-11 text-end">
          {/* Se ocualta el boton, relentiza el proceso en el navegador cuando son varias fials en la tabla  */}
          <button className="btn btn-label-youtube btn-sm" style={{ position: "fixed", }} onClick={downloadPdf}><i className="bx bx-xs bxs-file-pdf" ></i> Descargar PDF </button>
        </div>
      </div>
      <div ref={targetRef}>
      
        <h5 ><b>DISTRIBUIDORA COMERCIAL ALVAREZ BOHL</b></h5>
        <h6 ><b>DEVOLUCION DE MERCANCIAS POR PRODUCTO</b></h6>
        <hr/>
        <div className="row">
          <div className="col-4">
            <h6 ><b>N° Consolidado:</b> {prosp.Rconsolidado} </h6>
          </div>
          {/* <div className="col-4">
            <h6 ><b>Liquidador:</b> {prosp.RResponsable} </h6>
          </div> */}
          <div className="col-4">
            <h6 ><b>Almacen:</b> {prosp.Ralmacen} </h6>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h6 ><b>Dirección:</b> {prosp.Rdireccion} </h6>
          </div>
        </div>
        
        <div className="table-responsive text-nowrap">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Codigo</th>
                <th>Descripción</th>
                <th>Lote</th>
                <th>UnidComp</th>
                <th>Cantidad</th>
                <th>UnidInve</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody className="table-border-bottom-0">
            {Object.keys(groupedByCardName).map((cardName) => {
                        const groupedByItmsGrpNam = groupBy(groupedByCardName[cardName], 'ItmsGrpNam');
                        return (
                            <>
                                <tr key={cardName}>
                                    <td colSpan="13" style={{ backgroundColor: '#f0f0f0', textAlign: 'LEFT', fontWeight: 'bold' }}>
                                        {cardName}
                                    </td>
                                </tr>
                                {Object.keys(groupedByItmsGrpNam).map((itmsGrpNam) => (
                                    <>
                                        <tr key={itmsGrpNam}>
                                            <td colSpan="13" style={{ backgroundColor: '#d0d0d0', textAlign: 'LEFT', fontWeight: 'bold' }}>
                                                {itmsGrpNam}
                                            </td>
                                        </tr>
                                        {groupedByItmsGrpNam[itmsGrpNam].map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.ItemCode}</td>
                                                <td>{item.ItemName}</td>
                                                <td>{item.Lote}</td>
                                                <td>{item.Unid1}</td>
                                                <td>{item.Q1}</td>
                                                <td>{item.InvntryUom}</td>
                                                <td>{item.Q2}</td>
                                            </tr>
                                        ))}
                                    </>
                                ))}
                            </>
                        );
                    })}
              
            </tbody>
          </table>
        </div>
    </div>

    </div>
    
  )
}

export const RepViewSalidaAlamacen = (prosp) => {
  const options = {
    filename: "R001_SALIDA_DE_PRODUCTOS_DE_ALMACEN.pdf",
    page: {
      margin: {
        top: 7,
        bottom: 10,
        left: 5,
        right: 5
      }
    }
  };
  
  const targetRef = useRef();
  const downloadPdf = () => generatePDF(targetRef, options);
  // const location = useLocation();
  // const { data } = location.state || {};
  // console.log("dataRecvidad", data);
  console.log("prosp", prosp);
  console.log("prosp.data", prosp.data);
  // Función para agrupar datos por clave
  const groupBy = (data, key) => {
    return data.reduce((result, item) => {
        const groupKey = item[key];
        if (!result[groupKey]) {
            result[groupKey] = [];
        }
        result[groupKey].push(item);
        return result;
    }, {});
  };
  // cambio en la forma de agrupar ahora por UnidadNeg
  const groupedByCardName = groupBy(prosp.data, 'UnidadNeg');

  return (
    <div>
      <div className="row ">
        <div className="col-lg-11 text-end">
          {/* Se ocualta el boton, relentiza el proceso en el navegador cuando son varias fials en la tabla  */}
          <button className="btn btn-label-youtube btn-sm" style={{ position: "fixed", }} onClick={downloadPdf}><i className="bx bx-xs bxs-file-pdf" ></i> Descargar PDF </button>
        </div>
      </div>
      <div ref={targetRef}>
          <h5 ><b>DISTRIBUIDORA COMERCIAL ALVAREZ BOHL</b></h5>
          <h6 ><b>SALIDA DE PRODUCTOS DE ALMACEN CONSOLIDADOS</b></h6>
          <hr/>
          <div className="row">
            <div className="col-4">
              <h6 ><b>N° Consolidado:</b> {prosp.Rconsolidado} </h6>
            </div>
            <div className="col-4">
              <h6 ><b>Chofer:</b> {prosp.Chofer} </h6>
            </div>
            <div className="col-4">
              <h6 ><b>Almacen:</b> {prosp.Ralmacen} </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h6 ><b>Dirección:</b> {prosp.Rdireccion} </h6>
            </div>
          </div>
          
          <div className="table-responsive text-nowrap">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Codigo</th>
                  <th>Descripción</th>
                  <th>Lote</th>
                  <th>UnidComp</th>
                  <th>Cantidad</th>
                  <th>UnidInve</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
              {Object.keys(groupedByCardName).map((cardName) => {
                          const groupedByItmsGrpNam = groupBy(groupedByCardName[cardName], 'ItmsGrpNam');
                          return (
                              <>
                                  <tr key={cardName}>
                                      <td colSpan="13" style={{ backgroundColor: '#f0f0f0', textAlign: 'LEFT', fontWeight: 'bold' }}>
                                          {cardName}
                                      </td>
                                  </tr>
                                  {Object.keys(groupedByItmsGrpNam).map((itmsGrpNam) => (
                                      <>
                                          <tr key={itmsGrpNam}>
                                              <td colSpan="13" style={{ backgroundColor: '#d0d0d0', textAlign: 'LEFT', fontWeight: 'bold' }}>
                                                  {itmsGrpNam}
                                              </td>
                                          </tr>
                                          {groupedByItmsGrpNam[itmsGrpNam].map((item, index) => (
                                              <tr key={index}>
                                                  <td>{item.ItemCode}</td>
                                                  <td>{item.ItemName}</td>
                                                  <td>{item.Lote}</td>
                                                  <td>{item.Unid1}</td>
                                                  <td>{item.Q1}</td>
                                                  <td>{item.InvntryUom}</td>
                                                  <td>{item.Q2}</td>
                                              </tr>
                                          ))}
                                      </>
                                  ))}
                              </>
                          );
                      })}
                
              </tbody>
            </table>
          </div>
      </div>
      
    </div>
  )
} 
// export default RepViewAlmacen