import React from 'react'
//import {Page, Text, Document, StyleSheet, View } from "@react-pdf/renderer"
import {Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer"
import logo_ab from '../../assets/img/logo/logo_ab.png';

const style = StyleSheet.create({
    body:{ paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35,},
    tile:{ fontSize: 24, textAlign: 'center',     },
    text:{ margin: 12, fontSize: 14, textAlign: 'justify', fontFamily: 'Times-Roman' },
    image:{ marginVertical: 15, marginHorizontal: 100, width: 20 },
    header:{ fontSize: 12, marginBottom: 20, textAlign: 'center', color: '#000000', },
    pageNumber:{ position: 'absolute', fontSize: 8, bottom: 30, left: '50%', width: 50, textAlign: 'center', color: 'grey' },

    table: { width: '100%', fontSize: 6, border: '1px solid #000000', borderRadius: 3 },
    thead: { fontSize: 6, fontWeight: 'bolder', color: '#000000'},
    tbody: { fontSize: 4.5, },
    tfoot: { fontSize: 4.5, },
    row: { display: 'flex', flexDirection: 'row', },
    // So Declarative and unDRY borderTop: '10px solid #EEE', padding: 3, padding: 3 borderRight: '1px solid #000000',
    th0: { width: '2%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'center', padding: 2 },
    th1: { width: '7%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'center', padding: 2 },
    th2: { width: '40%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'center', padding: 2 },
    th3: { width: '10%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'center', padding: 2 },
    th4: { width: '10%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'center', padding: 2 },
    th5: { width: '12%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'center', padding: 2 },
    th6: { width: '12%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'center', padding: 2 },
    th7: { width: '12%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'center', padding: 2 },
    th8: { width: '12%', borderBottom: '1px solid #000000', textAlign: 'center', padding: 2 },
    
    td0: { width: '2%', borderRight: '1px solid #000000', textAlign: 'right', },
    td1: { width: '7%', borderRight: '1px solid #000000', },
    td2: { width: '40%', borderRight: '1px solid #000000', },
    td3: { width: '10%', borderRight: '1px solid #000000', },
    td4: { width: '10%', borderRight: '1px solid #000000', textAlign: 'center', },
    td5: { width: '12%', borderRight: '1px solid #000000', textAlign: 'right', },
    td6: { width: '12%', borderRight: '1px solid #000000', textAlign: 'right', },
    td7: { width: '12%', borderRight: '1px solid #000000', textAlign: 'right', },
    td8: { width: '12%', textAlign: 'right',  },


    div: { width: '100%', fontSize: 5.5, },
    div_1: { width: '35%', },
    div_2: { width: '35%', },
    div_3: { width: '30%', border: '1px solid #000000', borderRadius: 3},

    div_31: { width: '50%', },
    div_32: { width: '20%', textAlign: 'center', },
    div_33: { width: '30%', textAlign: 'right', },

});



const PdfEjemplo = ({datallegada,datallegadapri,datallegadadoc}) => {

    

    //const  i = 1;
    return (
        <Document>
            <Page style={style.body}>
                <Text style={style.header} fixed> <Image style={style.image} src={logo_ab} />Hola mundo</Text>
                {/* <View style={[style.div,style.row]}>
                    <View style={style.div_1}>
                        <Text>Fecha emision: {datallegadapri.FechaEmision}</Text>
                        <Text>Señor(es): {datallegadapri.Cliente}</Text>
                        <Text>DNI/RUC: {datallegadadoc.DocIdentidad}</Text>
                        <Text>Dirección: </Text>
                        <Text>Moneda: Soles</Text>
                    </View>
                    <View style={style.div_2}><Text></Text></View>
                    <View style={style.div_2}>
                        <Text>COMPROBANTE</Text>
                        <Text>{datallegadapri.Documento}</Text>
                    </View>
                </View>
                <View style={style.table}>
                    <View style={[style.thead,style.row]}>
                        <Text style={style.th0}>#</Text>
                        <Text style={style.th1}>CODIGO</Text>
                        <Text style={style.th2}>DESCRIPCIÓN</Text>
                        <Text style={style.th3}>U.MED.</Text>
                        <Text style={style.th4}>CANT</Text>
                        <Text style={style.th5}>PRE.UNIT</Text>
                        <Text style={style.th6}>DESC</Text>
                        <Text style={style.th7}>RECARGO</Text>
                        <Text style={style.th8}>IMPORTE TOTAL</Text>
                    </View>
                    {
                        datallegada?.map((item,index) => {
                          const b = index+1;
                          const preUnitario = (Number(item.Monto_Origen)/Number(item.Cantidad)).toFixed(2)
                          return(
                            <View key={index} style={[style.tbody,style.row]} wrap={false}>
                                <Text style={style.td0} >{b}&nbsp;</Text>
                                <Text style={style.td1} >&nbsp;{item.CodProducto} </Text>
                                <Text style={style.td2} >&nbsp;{item.Producto} </Text>
                                <Text style={style.td3} >&nbsp;{item.Unidad} </Text>
                                <Text style={style.td4} >{item.Cantidad} </Text>
                                <Text style={style.td5} >{preUnitario}&nbsp;</Text>
                                <Text style={style.td6} >0.0000&nbsp;</Text>
                                <Text style={style.td7} >0.0000&nbsp;</Text>
                                <Text style={style.td8} >{Number(item.VentaTotal).toFixed(2)}&nbsp;</Text>
                            </View>
                            )}
                        )
                    }
                    
                </View>
                <View style={[style.div,style.row]}>
                    <View style={style.div_1}>
                        <Text>Vendedor: {datallegadapri.CodVendedor}&nbsp;{datallegadapri.Vendedor}</Text>
                        <Text>Cod. Cliente: {datallegadadoc.CodCliente}</Text>
                        
                    </View>
                    <View style={style.div_2}><Text></Text></View>
                    <View style={style.div_3}>
                      <View style={style.row}><Text style={style.div_31}>&nbsp;OP. GRATUITAS</Text><Text style={style.div_32}>S/</Text><Text style={style.div_33}>0.00&nbsp;</Text></View>
                      <View style={style.row}><Text style={style.div_31}>&nbsp;OP. INAFECTADAS</Text><Text style={style.div_32}>S/</Text><Text style={style.div_33}>0.00&nbsp;</Text></View>
                      <View style={style.row}><Text style={style.div_31}>&nbsp;OP. AGRABADAS</Text><Text style={style.div_32}>S/</Text><Text style={style.div_33}>{datallegadadoc.VentaAfecta}&nbsp;</Text></View>
                      <View style={style.row}><Text style={style.div_31}>&nbsp;DESCUENTOS</Text><Text style={style.div_32}>S/</Text><Text style={style.div_33}>0.00&nbsp;</Text></View>
                      <View style={style.row}><Text style={style.div_31}>&nbsp;IGV</Text><Text style={style.div_32}>S/</Text><Text style={style.div_33}>{datallegadadoc.ValorIGV}&nbsp;</Text></View>
                      <View style={style.row}><Text style={style.div_31}>&nbsp;PERCEPCION</Text><Text style={style.div_32}>S/</Text><Text style={style.div_33}>0.00&nbsp;</Text></View>
                      <View style={style.row}><Text style={style.div_31}>&nbsp;IMPORTE TOTAL</Text><Text style={style.div_32}>S/</Text><Text style={style.div_33}>{datallegadadoc.VentaTotal}&nbsp;</Text></View>
                    </View>
                </View>
                <Text
                    style={style.pageNumber}
                    render={({pageNumber,totalPages}) => `${pageNumber} / ${totalPages}` }
                    fixed
                    /> */}
            </Page>
        </Document>
    )
};
export default PdfEjemplo
/**
 * return (

    <div>Pdf_ejemplo</div>
  )
 */