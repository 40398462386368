import React, {useEffect, useMemo, useState} from 'react'
import { useLocation } from 'react-router-dom';
import * as Utils from '../../../components/utils';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { AButton } from '../../../components/Button';
import { Box, Modal, Tooltip } from '@mui/material';
import logo_ab from '../../../assets/img/logo/logo_distribuidora_alvarez_bohl_home.png';
import * as Apis from './funApis'
const AppPedidos = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  
  const codEmpresa = searchParams.get('idEmpresa');
  const codDescVen = searchParams.get('cove');
  const [data, setData] = useState([]);
  const [dataDetalle, setDataDetalle] = useState([]);
  const [pending, setPending] = useState(false); 
  

  const columns = useMemo(() => [
    { header: 'Codigo', accessorKey: 'SocioNegocio', size: 20 },
    { header: 'Razon', accessorKey: 'Razon' },
    { header: 'Total', accessorKey: 'Total' },
  ],[])
  const columnsDetalle = useMemo(() => [
    { header: 'Articulo', accessorKey: 'Articulo', size: 80 },
    { header: 'Descrip', accessorKey: 'Descrip' },
    { header: 'Cantidad', accessorKey: 'Cantidad' },
    { header: 'PrecioUnitario', accessorKey: 'PrecioUnitario' },
  ],[])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleConsultarData = async() => {
    const jsDatos = { vcodEmpresa:codEmpresa, vcodVendedor:codDescVen}
     const rspApi = await Apis.apiConsultarPedidos(jsDatos)
     console.log("rspApi", rspApi);
     setData(rspApi)
  }
  const handleConsultarDataDetalle = async(row) => {
     try {

      const newLineDatoa = row?.original?.Lineas
      setDataDetalle(newLineDatoa)

    } catch (error) {
      Utils.notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  
  }

   useEffect(()=>{
    handleConsultarData()
   },[])


  return (
    <>
    <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
    <div className="layout-container">
    <div className="layout-page">
      <div className="content-wrapper ">
        <div className="container-xxl flex-grow-1 container-p-y "> 
        
          <div className="card">
            <div className="card-body  ">
              <div className="user-avatar-section mb-3">
                <div className=" d-flex align-items-center flex-column">
                  <img className="img-fluid rounded mb-4" src={logo_ab} height="120" width="120" alt="User avatar" />
                  <div className="user-info text-center">
                    <h5 className="mb-4">Bienvenido,<span className="h4"> {codDescVen} 👋🏻</span></h5>
                    <span className="badge bg-label-secondary">Vendedor: {codDescVen}</span>
                  </div>
                </div>
              </div>
          
              <div className="row mb-2 ">
                <div className="col-4 ">
                  <span className="badge rounded-pill bg-danger ">
                    <i className='bx bx-check'></i>
                    danger 
                  </span>
                </div>
                <div className="col-4">
                  <span className="badge rounded-pill bg-primary">
                    <i className='bx bx-check'></i>
                    Primary 
                  </span>
                </div>
                <div className="col-4">
                  <span className="badge rounded-pill bg-warning">
                    <i className='bx bx-check'></i>
                    Warning 
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-2">Sucursal:</div>
              </div>
              <div className="card-datatable table-responsive">
                <MaterialReactTable
                  columns={columns}
                  data={data}
                  //onSortingChange={setSorting}
                  state={{ isLoading: pending }}
                  initialState={{
                    density: 'compact',
                    expanded: false,
                  }}
                  enableKeyboardShortcuts= {false}
                  enableColumnActions= {false}
                  enableColumnFilters= {false}
                  enableSorting= {false}
                  enableGrouping= {false}
                  enableFullScreenToggle= {false}
                  enableStickyHeader
                  enablePagination={true}
                  enableBottomToolbar={false}
                  enableGlobalFilterModes
                  rowVirtualizerProps={{ overscan: 8 }}
                  enableRowActions
                  renderRowActions={({ row }) => (
                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                      {/* Botón al final */}
                      <AButton
                        tp_tilde="Mas Detalle" tp_posit="top"
                        bt_class="btn btn-sm btn-icon btn-label-info"
                        ic_class="bx bx-xs bx-detail" vl_tpbtn={1} 
                        bt_vmodal={1} bt_nmodal="#modalLotes2"
                        bt_funct={() => handleConsultarDataDetalle(row)}
                      />
                      {/* Otros botones o contenido según sea necesario */}
                    </Box>
                  )}
                  muiCircularProgressProps={{ color: 'error' }}
                 

                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    </div>
    </div>
        <div className="modal fade" id="modalLotes2" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog  modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel4">Detalle de Pedido</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body" >

                <MaterialReactTable
                  columns={columnsDetalle}
                  data={dataDetalle}
                  initialState={{ 
                    density: 'compact', 
                    showColumnFilters: false, 
                    showGlobalFilter: false, 
                    }}
                  enableGrouping
                  enableStickyHeader enableColumnResizing
                  muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                  localization={MRT_Localization_ES}
                  enablePagination={true}
                  muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                  enableGlobalFilterModes
                  rowVirtualizerProps={{ overscan: 8 }}
                  enableRowVirtualization
                  muiTableContainerProps={{ sx: { maxHeight: 300 } }}
                  
                  
                  
                />

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-label-secondary" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default AppPedidos